// @flow
/* eslint-disable react/display-name */

import React, { memo, useRef, useMemo, useCallback, useEffect } from 'react'
import { flushSync } from 'react-dom'
import { findIndex, propEq, multiply, equals, and } from 'ramda'
import { useTranslation } from 'react-i18next'
import { BUTTON_KEY_NAMES } from '@alphaott/smart-tv-platforms'
import { debounce, throttle } from 'throttle-debounce'

import { scale } from '@alphaott/smart-tv-utils'

import { ListComponent } from '@alphaott/smart-tv-components'
import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'
import { Container, Heading, Title, CHANNEL_LIST_CONTROL_WIDTH } from './elements'

import { ChannelCardHorizontal } from '../../../ChannelCardHorizontal'
import { switchChannelCallback, findNextChannel, findPreviousChannel } from './utils'

export type ChannelsListControlProps = {
  items: Array<any>,
  onSelectChannelsListItem: Function,
  onCloseChannelsList: Function,
  isShowSubtitlesSettings: boolean,
  onOpenChannelsList: Function,
  isShowListControl: boolean,
  isAvailableChannelsList: boolean,
  channelId: string,
  channelCardPlaceholder?: string,
}

const SWITCH_CHANNEL_DELAY = 1000
const SWITCH_CHANNEL_PRELOAD_DELAY = 800
const CLOSE_TV_LIST_CONTROL_DELAY = 5000
const CHANNEL_CARD_HEIGHT = 111
const HEADER_HEIGHT = 150

export const ChannelListControlFocusable = ({ items, activeChannelId, renderItem }) => {
  const { ref, setFocus } = useFocusable()
  const { t } = useTranslation()

  const getHeight = useCallback((height) => height - scale(window.innerWidth, HEADER_HEIGHT), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const height = useMemo(() => getHeight(window.innerHeight), [])
  const getItemSize = useCallback(() => scale(window.innerWidth, CHANNEL_CARD_HEIGHT), [])
  const initialScrollOffset = useMemo(() => {
    const offset = multiply(getItemSize(), findIndex(propEq('id', activeChannelId))(items))
    return offset > height ? offset : 0
  }, [activeChannelId, items, height, getItemSize])

  useEffect(() => {
    if (setFocus) {
      setFocus(activeChannelId)
    }
  }, [setFocus]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container ref={ref}>
      <Heading>
        <Title>{t('channels')}</Title>
      </Heading>
      <div style={{ height }}>
        <ListComponent
          items={items}
          initialScrollOffset={initialScrollOffset}
          listWidth={CHANNEL_LIST_CONTROL_WIDTH}
          itemSize={CHANNEL_CARD_HEIGHT}
          renderItem={renderItem}
          layout="vertical"
        />
      </div>
    </Container>
  )
}

// $FlowFixMe
export const ChannelListControlPure = ({
  items,
  onSelectChannelsListItem,
  onCloseChannelsList,
  isShowSubtitlesSettings,
  onOpenChannelsList,
  isAvailableChannelsList,
  isShowListControl,
  channelId: activeChannelId,
  channelCardPlaceholder,
}: ChannelsListControlProps) => {
  const { ref, setFocus } = useFocusable({ focusable: false })
  const timeoutRef = useRef(null)

  const clearCloseTVListInterval = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }, [])

  const startCloseListTimeout = useCallback(() => {
    clearCloseTVListInterval()
    timeoutRef.current = setTimeout(onCloseChannelsList, CLOSE_TV_LIST_CONTROL_DELAY)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCloseChannelsList])

  const handleSelectTVListItem = useCallback(
    (item) => () => {
      if (!item.isActive) {
        onSelectChannelsListItem(item)
      }
      if (!isShowSubtitlesSettings) {
        onCloseChannelsList()
      }
    },
    [isShowSubtitlesSettings, onCloseChannelsList, onSelectChannelsListItem],
  )

  const updateChannelIdDebounce = useMemo(
    () => debounce(SWITCH_CHANNEL_DELAY, (id, onSwitchChannel) => onSwitchChannel({ id })),
    [],
  )

  const updateChannelIdThrottle = useMemo(
    () =>
      throttle(SWITCH_CHANNEL_PRELOAD_DELAY, (preloadId, onSwitchChannel) =>
        onSwitchChannel({ preloadId }),
      ),
    [],
  )

  const onArrowPress = useCallback(
    (arrow) => {
      if (arrow === 'right') {
        onCloseChannelsList()
      }
    },
    [onCloseChannelsList],
  )

  const handleArrowUpDown = useCallback(() => {
    if (isShowListControl) {
      return
    }
    if (and(!isShowSubtitlesSettings, isAvailableChannelsList)) {
      flushSync(() => onOpenChannelsList())
    }
  }, [onOpenChannelsList, isShowSubtitlesSettings, isShowListControl, isAvailableChannelsList])

  const handleChannelUp = useCallback(() => {
    const nextChannel = switchChannelCallback(activeChannelId, items)(findNextChannel)

    updateChannelIdThrottle(nextChannel?.id, onSelectChannelsListItem)
    updateChannelIdDebounce(nextChannel?.id, onSelectChannelsListItem)
  }, [
    activeChannelId,
    items,
    onSelectChannelsListItem,
    updateChannelIdThrottle,
    updateChannelIdDebounce,
  ])

  const handleChannelDown = useCallback(() => {
    const prevChannel = switchChannelCallback(activeChannelId, items)(findPreviousChannel)

    updateChannelIdThrottle(prevChannel?.id, onSelectChannelsListItem)
    updateChannelIdDebounce(prevChannel?.id, onSelectChannelsListItem)
  }, [
    activeChannelId,
    items,
    onSelectChannelsListItem,
    updateChannelIdThrottle,
    updateChannelIdDebounce,
  ])

  const handleBecameFocused = useCallback(
    (item) =>
      (props, { id, isRestricted, onSwitchChannel }) => {
        updateChannelIdDebounce(id, onSwitchChannel())
        startCloseListTimeout()
        if (isRestricted) clearCloseTVListInterval()
        item.onBecameFocused(props)
      },
    [clearCloseTVListInterval, startCloseListTimeout, updateChannelIdDebounce],
  )

  const handleBackPress = useCallback(
    (event) => {
      if (isShowListControl) {
        onCloseChannelsList()
        event.stopPropagation()
      }
    },
    [isShowListControl, onCloseChannelsList],
  )

  useEffect(() => {
    document?.addEventListener(BUTTON_KEY_NAMES.BACK, handleBackPress)
    document?.addEventListener(BUTTON_KEY_NAMES.ARROW_UP, handleArrowUpDown)
    document?.addEventListener(BUTTON_KEY_NAMES.ARROW_DOWN, handleArrowUpDown)
    return () => {
      document?.removeEventListener(BUTTON_KEY_NAMES.BACK, handleBackPress)
      document?.removeEventListener(BUTTON_KEY_NAMES.ARROW_UP, handleArrowUpDown)
      document?.removeEventListener(BUTTON_KEY_NAMES.ARROW_DOWN, handleArrowUpDown)
    }
  }, [handleBackPress, handleArrowUpDown])

  useEffect(() => {
    document?.addEventListener(BUTTON_KEY_NAMES.CHANNEL_UP, handleChannelUp)
    document?.addEventListener(BUTTON_KEY_NAMES.CHANNEL_DOWN, handleChannelDown)
    return () => {
      document?.removeEventListener(BUTTON_KEY_NAMES.CHANNEL_UP, handleChannelUp)
      document?.removeEventListener(BUTTON_KEY_NAMES.CHANNEL_DOWN, handleChannelDown)
    }
  }, [handleChannelUp, handleChannelDown])

  useEffect(() => {
    if (!isShowListControl) {
      clearCloseTVListInterval()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowListControl])

  const renderItem = useCallback(
    ({ item }) => (
      <ChannelCardHorizontal
        {...item}
        focusKey={item.id}
        isCardHorizontal
        placeholder={channelCardPlaceholder}
        onFocus={handleBecameFocused(item)}
        onPress={handleSelectTVListItem(item)}
        isActive={equals(activeChannelId, item.id)}
        onArrowPress={onArrowPress}
        onSwitchChannel={onSelectChannelsListItem}
        startCloseListTimeout={startCloseListTimeout}
        onCloseChannelsList={onCloseChannelsList}
        setFocus={setFocus}
      />
    ),
    [activeChannelId], // eslint-disable-line react-hooks/exhaustive-deps
  )

  if (!isShowListControl) return null

  return (
    <div ref={ref}>
      <ChannelListControlFocusable
        items={items}
        activeChannelId={activeChannelId}
        renderItem={renderItem}
      />
    </div>
  )
}
export const ChannelListControl = memo<ChannelsListControlProps>(ChannelListControlPure)

export default ChannelListControl
