// @flow
import { map } from 'ramda'
import typeToReducer from 'type-to-reducer'

import { prepareMovieImages } from '@alphaott/common-utils/utils/schemas/movies'
import { isValidTTL, calculateExpires } from '@alphaott/app-main/selectors'

import type { Movies } from '@alphaott/api-client/types/movies'

import { FETCH_MOVIES_TYPE, FILTER_OPEN_MOVIE } from '../actions'
import type { FilterOpenMovieAction } from '../actions'

export type MoviesListState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +hasMore: boolean,
  +data: Movies,
  +isOpenFilter: boolean,
  +expires: ?number,
}

const initialState: MoviesListState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
  hasMore: true,
  isOpenFilter: false,
  expires: null,
}

const moviesList = typeToReducer(
  {
    [FETCH_MOVIES_TYPE]: {
      LOADING: (state): MoviesListState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): MoviesListState => {
        const {
          payload: { data = [] },
          meta: { contentApi, resetData, ttl },
        } = action
        const preparedMovies = map(movie => prepareMovieImages(movie, contentApi))(data)
        const movies = resetData ? preparedMovies : [...state.data, ...preparedMovies]

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          hasMore: data.length > 0,
          data: movies,
          ...(isValidTTL(ttl) && { expires: calculateExpires(ttl) }),
        }
      },

      ERROR: (state, action): MoviesListState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        expires: null,
        errors: action.payload.error,
      }),
    },
    [FILTER_OPEN_MOVIE]: (state: MoviesListState, { isOpenFilter }: FilterOpenMovieAction): MoviesListState => ({
      ...state,
      isOpenFilter,
    }),
  },
  initialState,
)

export { moviesList }
export * from './categories'
export * from './countries'
export * from './genres'
export * from './languages'
export * from './movie'
export * from './popular'
export * from './recent'
export * from './favorites'
export * from './limits'
export * from './searchList'
export * from './filters'
