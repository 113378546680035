// @flow
import type { MoviesListQuery } from '@alphaott/api-client/movies'

export const ENTITY_TYPE = {
  LIVE_TV: 'LIVE_TV',
  RADIO: 'RADIO',
  MOVIE: 'MOVIE',
  TV_SHOW: 'TV_SHOW',
}

export const LIMIT_QUERY_VALUE: number = 20

export const NEW_QUERY_PARAM: MoviesListQuery = {
  sort: '-year',
  limit: LIMIT_QUERY_VALUE,
}

export const POPULAR_QUERY_PARAM: MoviesListQuery = {
  sort: '-rating',
  limit: LIMIT_QUERY_VALUE,
}

export const LATEST_ADDITIONS_QUERY_PARAM: MoviesListQuery = {
  sort: '-created',
  limit: LIMIT_QUERY_VALUE,
}

export const getCategoryQueryParams = (id: string): MoviesListQuery => ({
  categories: id,
  limit: LIMIT_QUERY_VALUE,
})

export const getGenreQueryParams = (id: string): MoviesListQuery => ({
  genres: id,
  limit: LIMIT_QUERY_VALUE,
})
