// @flow
import typeToReducer from 'type-to-reducer'
import { take, map, propOr, pathOr, propEq, descend, sortWith, prop, pipe, reject, prepend } from 'ramda'

import { RECENT_RADIO_CHANNEL_MAX_COUNT } from '@alphaott/app-config'
import { UPDATE_RECENT_RADIO_CHANNEL, GET_RECENT_RADIO_CHANNELS } from '../../actions'

export type RadioChannelRecentPlayed = {
  id: string,
  total: number,
}

export type RadioChannelRecentPlayedState = {|
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Array<RadioChannelRecentPlayed>,
|}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
}

const radioChannelRecentPlayed = typeToReducer(
  {
    [GET_RECENT_RADIO_CHANNELS]: {
      LOADING: (state): RadioChannelRecentPlayedState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): RadioChannelRecentPlayedState => {
        const {
          payload: { data: response },
        } = action

        const radioChannels = pipe(
          sortWith([descend(prop('ts'))]),
          map(elem => ({
            id: propOr('', 'item', elem),
            total: pathOr(0, ['data', 'total'], elem),
          })),
        )(response)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: take(RECENT_RADIO_CHANNEL_MAX_COUNT, radioChannels),
        }
      },

      ERROR: (state, action): RadioChannelRecentPlayedState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: [],
        errors: action.payload.error,
      }),
    },
    [UPDATE_RECENT_RADIO_CHANNEL]: {
      LOADING: (state): RadioChannelRecentPlayedState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): RadioChannelRecentPlayedState => {
        const {
          payload: { data: response },
        } = action

        const newRadioChannel = {
          id: propOr('', 'item', response),
          total: pathOr(0, ['data', 'total'], response),
        }

        const radioChannels = pipe(
          reject(propEq('id', newRadioChannel.id)),
          prepend(newRadioChannel),
        )(state.data)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: radioChannels,
        }
      },

      ERROR: (state, action): RadioChannelRecentPlayedState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: [],
        errors: action.payload.error,
      }),
    },
  },
  initialState,
)

export { radioChannelRecentPlayed }
