// @flow

import typeToReducer from 'type-to-reducer'

import { RESET_PASSWORD_TYPE, RESET_PASSWORD_STATE_TYPE } from '../../actions/reset'

export type ResetState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
}

const initialState: ResetState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
}

const reset = typeToReducer(
  {
    [RESET_PASSWORD_TYPE]: {
      LOADING: (): ResetState => ({ ...initialState, isLoading: true }),

      SUCCESS: (state): ResetState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        errors: [],
      }),

      ERROR: (state, action): ResetState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
    [RESET_PASSWORD_STATE_TYPE]: () => initialState,
  },
  initialState,
)

export { reset }
