// @flow
import { prop, propSatisfies, equals } from 'ramda'

import { isNotEmpty } from '@alphaott/common-utils/utils/help'

export type LimitError = {
  code: string,
  message: string,
  limits: {
    devices: {
      STB: number,
      MOBILE: number,
    },
    total: number,
  },
}

const EXCEED_CODE = 'ExceededDeviceLimit'

export const isExceedLimit = propSatisfies(equals(EXCEED_CODE), 'code')
export const isExist = (value: any) => value && isNotEmpty(value)

// TODO: Improve error message
const prepareSubTitle = (limits: Object = {}) =>
  `Limits total is ${limits.total},
       limits devices: STB ${prop('STB', limits.devices)}, MOBILE ${prop('MOBILE', limits.devices)}`

export const getLimitsError = (limitError: LimitError): ?Object => {
  if (isExist(limitError) && isExceedLimit(limitError)) {
    return {
      ...limitError,
      error: 'ERROR 403',
      subTitle: prepareSubTitle(limitError.limits),
    }
  }
  return null
}
