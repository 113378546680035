// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'

import { AccountIcon } from '../../assets'
import { Container, Label } from './AccountButton.styles'

type AccountButtonProps = {
  onClick: Function,
}

export const AccountButton = ({ onClick }: AccountButtonProps) => {
  const { t } = useTranslation()
  const { ref, focused, focusSelf } = useFocusable({ onEnterPress: onClick })
  const { handleMouseOver } = useMouseOver(focusSelf)

  return (
    <Container ref={ref} focused={focused} onMouseOver={handleMouseOver} onClick={onClick}>
      <AccountIcon />
      {focused && <Label>{t('mwstv_account_account_details')}</Label>}
    </Container>
  )
}
