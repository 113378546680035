// @flow
import { getLanguages } from '@alphaott/api-client/languages'
import { getPublicDomain } from '@alphaott/app-main/selectors'

export const FETCH_LANGUAGES_TYPE = 'languages/FETCH'

export const fetchAppLanguages = (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_LANGUAGES_TYPE,
    payload: getLanguages(getPublicDomain(getState())),
  })
