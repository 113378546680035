// @flow
import { join, map } from 'ramda'

import supportedAcceptMediaStream from './getSupportedAcceptMediaStream'
import getSupportedDRM from './drm/getSupportedDRM'
import { MEDIA_STREAM_TYPE_FOR_DRM } from './const'

import type { AcceptMediaStreamList } from './const'

const commaJoin = join(',')

export const prepareAcceptMediaStreamHeaders = (
  acceptMediaStream: AcceptMediaStreamList = supportedAcceptMediaStream,
): string => commaJoin(acceptMediaStream)

/**
 * 1. Gets supported DRM
 * 3. Detects pair {media_stream}/{DRM}
 */
export const prepareAcceptMediaStreamEncryptedHeaders = (): string => {
  const acceptMediaStreamEncryptedHeaders = map(drm => MEDIA_STREAM_TYPE_FOR_DRM[drm], getSupportedDRM())
  return commaJoin(acceptMediaStreamEncryptedHeaders)
}
