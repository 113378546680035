// @flow

import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useExitApp } from '@alphaott/smart-tv-platforms'
import { openExitAppModal } from '@alphaott/smart-tv-notifications/actions'
import { getAppExitModalOpened } from '@alphaott/smart-tv-notifications/selectors'

const OPEN_APP_EXIT_MODAL = true
const CLOSE_APP_EXIT_MODAL = false

export const useExitAppModal = () => {
  const dispatch = useDispatch()
  const onExitApp = useExitApp()

  const isAppExitModalOpened = useSelector(getAppExitModalOpened)

  const handleOpenExitAppModal = useCallback(() => {
    dispatch(openExitAppModal(OPEN_APP_EXIT_MODAL))
  }, [dispatch])

  const handleCloseExitAppModal = useCallback(() => {
    dispatch(openExitAppModal(CLOSE_APP_EXIT_MODAL))
  }, [dispatch])

  const handleApproveExitAppModal = useCallback(() => {
    dispatch(openExitAppModal(CLOSE_APP_EXIT_MODAL))
    onExitApp()
  }, [dispatch, onExitApp])

  return {
    isAppExitModalOpened,
    handleOpenExitAppModal,
    handleCloseExitAppModal,
    handleApproveExitAppModal,
  }
}
