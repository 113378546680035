// @flow

import React, {
  memo,
  forwardRef,
  useEffect,
  useRef,
  useMemo,
  useImperativeHandle,
  useCallback,
} from 'react'
import videojs from 'video.js'
import { isNotNilOrEmpty } from 'ramda-adjunct'

import { useVersionValue } from '@alphaott/smart-tv-common'

import type { VideojsSource } from '@alphaott/drm-web'

import { Container, Player } from './VideoJSPlayer.style'
import { addListeners, removeListeners, getPlayerEvents, getActions } from '../utils'

type EventType = [string, (value?: string | number | Object) => void]

const initPlayer = (
  videoJS: Object,
  config: Object,
  {
    videoRef,
    playerEvents,
  }: {
    videoRef: any,
    playerEvents: EventType[],
  },
) => {
  const player = videoJS(videoRef.current, config)

  addListeners(player, playerEvents)

  return player
}

type VideoJSPlayerProps = {
  className?: string,
  config: Object,
  sources: VideojsSource[],
  defaultTimePosition?: number,
  // Event callbacks
  onLoading: () => void,
  onLoaded: () => void,
  onCanPlay: () => void,
  onPlay: () => void,
  onSeeking: () => void,
  onSeeked: () => void,
  onPause: () => void,
  onEnd: () => void,
  onTimeUpdate: () => void,
  onError: (Object) => void,
  onChangedAudioTrack: () => void,
  onChangedSubtitleTrack: () => void,
}

export const VideoJSPlayerPure = (
  { className, config, sources, defaultTimePosition, onError, ...otherProps }: VideoJSPlayerProps,
  ref: any,
) => {
  const playerEvents = useMemo(
    () => getPlayerEvents({ ...otherProps, onError }),
    [otherProps, onError],
  )

  const videoRef = useRef(null)
  const playerRef = useRef(null)

  const sourcesVersion = useVersionValue<VideojsSource[]>(sources)

  const actions = getActions(videoRef, playerRef, {
    sources,
  })
  useImperativeHandle(ref, () => actions, [actions]) // eslint-disable-line react-hooks/exhaustive-deps

  /* eslint-disable */
  const handleLoadSrc = useCallback(
    async (player: any, sourceList: VideojsSource[]) => {
      if (player && isNotNilOrEmpty(sourceList)) {
        for (let i = 0; i < sources.length; i++) {
          const source = sources[i]

          try {
            player.src(source)

            await new Promise((resolve, reject) => {
              const onError = () => {
                player.off('error', onError)
                reject(new Error('Error loading stream'))
              }

              const onLoadedData = () => {
                player.off('loadeddata', onLoadedData)
                resolve()
              }

              player.one('error', onError)
              player.one('loadeddata', onLoadedData)
            })

            return player.on('error', onError)
          } catch (error) {
            console.warn('Failed to load stream:', source, error)

            if (i === sources.length - 1) {
              player.on('error', onError)
              player.trigger('error')
            }
          }
        }
      }
    },
    [onError],
  )
  /* eslint-enable */

  useEffect(() => {
    try {
      playerRef.current = initPlayer(videojs, config, {
        videoRef,
        playerEvents,
      })

      handleLoadSrc(playerRef.current, sources)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error initializing player:', error)

      onError(error)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (sourcesVersion.isNew && sourcesVersion.value && sourcesVersion.prevValue) {
      handleLoadSrc(playerRef.current, sourcesVersion.value)
    }
  }, [sourcesVersion.isNew]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => () => {
      if (playerRef.current) {
        removeListeners(playerRef.current, playerEvents)
        playerRef.current.dispose()
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <Container data-vjs-player className={className}>
      <Player className="video-js" ref={videoRef} />
    </Container>
  )
}

export const VideoJSPlayer = memo(forwardRef(VideoJSPlayerPure))

export default VideoJSPlayer
