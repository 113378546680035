// @flow

export const PLAY_BUTTON_FOCUS_KEY = 'PlayButton'
export const PROGRAM_GUIDE_FOCUS_KEY = 'ProgramGuide'
export const ADD_TO_MY_LIST_FOCUS_KEY = 'AddToMyList'
export const AUDIO_SUBS_FOCUS_KEY = 'AudioSubs'
export const LIST_CONTROL_FOCUS_KEY = 'ListControl'
export const OPEN_CHANNELS_LIST_FOCUS_KEY = 'OpenChannelsList'
export const MULTI_SCREEN_FOCUS_KEY = 'MultiScreen'
export const RECORD_FOCUS_KEY = 'Record'
