// @flow weak
import { createSelector } from 'reselect'
import { path, map, flatten } from 'ramda'
import { format, eachDay } from 'date-fns'

import type { Program } from '@alphaott/api-client/types/programs'
import type { Channel } from '@alphaott/api-client/types/channels'

import { parse, stringify } from '@alphaott/common-utils/utils/urlSearchParams'
import { isNotNil } from '@alphaott/common-utils/utils/help'
import { getRoutingPathname, getRoutingSearch } from '@alphaott/app-main/selectors'

import { DATE_FORMAT } from '../../const'

import type { ProgramsState } from './reducers/programGrid'
import type { PushQueryParam } from './actions'
import type { ProgramsStore } from '../types'

export const getProgramGrid = (state: ProgramsStore): ProgramsState => state.programGrid.data
export const getProgramGuideSelectedChannel = (state: ProgramsStore): Channel => state.programGuideConfig.channel
export const getProgramGuideSelectedProgram = (state: ProgramsStore): Program => state.programGuideConfig.program

export const getProgramGridPath = (params: PushQueryParam) =>
  createSelector(
    [getRoutingSearch, getRoutingPathname],
    (search: string, pathname: string): string => {
      const searchQuery = {
        ...parse(search),
        ...params,
      }
      return `${pathname}${stringify(searchQuery)}`
    },
  )

export const isExistPrograms = (channelId: string, date: Date) =>
  createSelector(
    [getProgramGrid],
    (programGrid: ProgramsState) => isNotNil(path([channelId, format(date, DATE_FORMAT)], programGrid)),
  )

export const getChannelProgramsForDate = (channelId: string, date: Date) =>
  createSelector(
    [getProgramGrid],
    (programGrid: ProgramsState) => path([channelId, format(date, DATE_FORMAT)], programGrid),
  )

export const getChannelProgramsForRangeDate = (channelId: string, start: Date, stop: Date) =>
  createSelector(
    [getProgramGrid],
    (programGrid: ProgramsState) => {
      const days = eachDay(start, stop)
      const programs = map(day => path([channelId, format(day, DATE_FORMAT)], programGrid), days)
      return flatten(programs)
    },
  )
