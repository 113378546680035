// @flow

import styled from 'styled-components'
import { Image, Text } from '@alphaott/smart-tv-components'
import {
  getTextColorSecondary,
  getColorPrimary,
  getColorSlightPrimary,
} from '@alphaott/smart-tv-themes'

export const IMAGE_WIDTH: number = 102
export const IMAGE_HEIGHT: number = 68

const getCardBackgroundColor = (props) => {
  if (props.isActive) {
    return getColorPrimary(props)
  }
  if (props.focused) {
    return getColorSlightPrimary(props)
  }
  return 'transparent'
}

export const ChannelCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${getCardBackgroundColor};
`

export const Placeholder = styled.img`
  width: ${IMAGE_WIDTH}rem;
  height: ${IMAGE_HEIGHT}rem;
`

export const ChannelCardImage = styled(Image)`
  width: ${IMAGE_WIDTH}rem;
  height: ${IMAGE_HEIGHT}rem;
  min-width: ${IMAGE_WIDTH}rem;
  margin-left: 32rem;
`

export const ChannelCardTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16rem;
  max-width: 250rem;
`

export const ChannelCardTitle = styled(Text)`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 33rem;
  line-height: 40rem;
`

export const Description = styled(Text)`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${getTextColorSecondary};
  line-height: 29rem;
`

export const ChannelNumber = styled(Text)`
  margin-right: 24rem;
  font-weight: bold;
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
