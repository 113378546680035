// @flow
import type { ParentalSettings } from '../reducers'

export const UPDATE_PARENTAL_PAGE_SETTINGS = 'settings_parent/UPDATE_SETTINGS'

export const updateParentSettings = (newSettings: ParentalSettings) => (dispatch: Function) =>
  dispatch({
    type: UPDATE_PARENTAL_PAGE_SETTINGS,
    payload: newSettings,
  })
