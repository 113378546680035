// @flow

import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { map, includes, not } from 'ramda'

import { favoriteChannel } from '@alphaott/app-channels/actions'
import { getFavoriteList } from '@alphaott/app-channels/selectors'
import { useLastFocusHistory } from '@alphaott/smart-tv-spatial-navigation'

type UseChannelOptions = {
  defaultFocusKey?: string,
  onPlayCallback?: (channelId: string) => void,
  onFavoriteCallback?: (channelId: string, { isFavorite: boolean, setFocus: Function }) => void,
}

export const useChannelOptions = ({
  defaultFocusKey,
  onPlayCallback,
  onFavoriteCallback,
}: UseChannelOptions = {}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { lastFocusedKey, onRemoveFocusItem } = useLastFocusHistory()

  const favoriteList = useSelector(getFavoriteList)
  const favoriteIds = useMemo(() => map(({ _id }) => _id, favoriteList), [favoriteList])

  const handlePlay = useCallback(
    (channelId, { isFavoriteList, items } = {}) => {
      if (onPlayCallback) {
        onPlayCallback(channelId)
      }

      navigate(`/tv/${channelId}`, { state: { items, isFavoriteList } })
    },
    [navigate, onPlayCallback],
  )

  const handleFavorite = useCallback(
    // eslint-disable-next-line complexity
    async (channelId, { isFavoriteList, setFocus }) => {
      const isFavorite = includes(channelId, favoriteIds)

      await dispatch(favoriteChannel(channelId))

      if (isFavorite && isFavoriteList) {
        onRemoveFocusItem(lastFocusedKey)

        if (defaultFocusKey) {
          setFocus(defaultFocusKey)
        }
      }

      if (onFavoriteCallback) {
        onFavoriteCallback(channelId, {
          isFavorite: not(isFavorite),
          setFocus,
        })
      }
    },
    [favoriteIds, lastFocusedKey, defaultFocusKey, dispatch, onRemoveFocusItem, onFavoriteCallback],
  )

  const handlePrepareOptions = useCallback(
    (id, { isFavoriteList, items } = {}) => {
      const handleCallback = (callback: Function) => (setFocus?: Function) =>
        callback(id, { isFavoriteList, items, setFocus })

      const isFavorite = includes(id, favoriteIds)

      return [
        {
          focusKey: 'PLAY_CHANNEL_BUTTON',
          label: t('play_channel'),
          onClick: handleCallback(handlePlay),
        },
        {
          focusKey: 'ADD_TO_MY_LIST_BUTTON',
          label: isFavorite ? t('remove_from_favorites') : t('add_to_favorites'),
          onClick: handleCallback(handleFavorite),
        },
      ]
    },
    [favoriteIds, t, handlePlay, handleFavorite],
  )

  return {
    onPlayChannel: handlePlay,
    onPrepareChannelOptions: handlePrepareOptions,
  }
}

export default useChannelOptions
