// @flow

import { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getServiceProviderName, shouldOpenDemoWelcomeScreen } from '@alphaott/app-core/selectors'
import { openDemoWelcomeScreen } from '@alphaott/app-core/actions'
import { getWebAppBaseUrl } from '@alphaott/smart-tv-notifications'

import { getWebAppSubscriptionUrl } from './utils'

const CLOSE_DEMO_WELCOME_SCREEN = false

export const useDemoModal = () => {
  const dispatch = useDispatch()
  const companyName = useSelector(getServiceProviderName)

  const isOpenDemoWelcomeModal = useSelector(shouldOpenDemoWelcomeScreen)

  const webAppBaseUrl = useSelector(getWebAppBaseUrl)

  const subscriptionLink = useMemo(() => getWebAppSubscriptionUrl(webAppBaseUrl), [webAppBaseUrl])

  const handleCloseDemoWelcomeScreen = useCallback(() => {
    dispatch(openDemoWelcomeScreen(CLOSE_DEMO_WELCOME_SCREEN))
  }, [dispatch])

  return {
    companyName,
    isOpenDemoWelcomeModal,
    subscriptionLink,
    handleCloseDemoWelcomeScreen,
  }
}
