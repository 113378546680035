// @flow
import styled from 'styled-components'

import { Text } from '@alphaott/smart-tv-components'

import { CHANNEL_CARD_ITEM_HEIGHT } from '../ChannelCard'

export const CHANNEL_LIST_HEADING_HEIGHT = 48
export const CHANNEL_LIST_HEIGHT = CHANNEL_CARD_ITEM_HEIGHT
export const CHANNEL_LIST_MARGIN_BOTTOM = 40
// eslint-disable-next-line operator-linebreak
export const CHANNEL_LIST_SECTION_HEIGHT = CHANNEL_LIST_HEADING_HEIGHT + CHANNEL_LIST_HEIGHT

export const ChannelsScrollContainer = styled.div`
  min-height: ${CHANNEL_LIST_SECTION_HEIGHT}rem;
  width: 100%;
`

export const HeaderContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${CHANNEL_LIST_HEADING_HEIGHT}rem;
  margin-left: 40rem;
`

export const HeaderTitle = styled(Text)`
  font-size: 28rem;
  letter-spacing: 0.28rem;
  text-transform: uppercase;
`
