// @flow

import { createSelector } from 'reselect'
import { path, propOr } from 'ramda'

import { getConfig, isEpgEnabled as getEnabledEpg } from '@alphaott/app-main/selectors'

export const getSmartTVEpgSettings = createSelector([getConfig], (config: Object): Object =>
  path(['platform', 'smarttv', 'ui', 'epg'], config),
)

export const getEnabledSmartTVEpg = createSelector(
  [getSmartTVEpgSettings, getEnabledEpg],
  (smartTVEpgSettings: Object, isEnabledEpg): boolean => {
    const isEnabledSmartTVEpg = propOr(true, 'enabled', smartTVEpgSettings)

    return isEnabledEpg && isEnabledSmartTVEpg
  },
)
