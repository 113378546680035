// @flow
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'

import { ViewAllIcon } from '../assets'

import {
  ChannelCardImage,
  ChannelCardTitleBox,
  ChannelCardTitle,
  ChannelCardContainer,
  ChannelCardImageContainer,
} from './ChannelViewAllCard.style'

type ChannelViewAllCardProps = {
  style?: any,
  onFocus?: Function,
  onPress: Function,
  onArrowPress?: Function,
}

export const ChannelViewAllCardPure = ({
  focusKey,
  style,
  onFocus,
  onPress,
  onArrowPress,
}: ChannelViewAllCardProps) => {
  const { ref, focused } = useFocusable({ focusKey, onFocus, onEnterPress: onPress, onArrowPress })

  const { t } = useTranslation()

  return (
    <ChannelCardContainer ref={ref} style={style} onClick={onPress}>
      <ChannelCardImageContainer focused={focused}>
        <ChannelCardImage as={ViewAllIcon} />
      </ChannelCardImageContainer>
      <ChannelCardTitleBox>
        <ChannelCardTitle>{t('mwstv_catalog_view_all')}</ChannelCardTitle>
      </ChannelCardTitleBox>
    </ChannelCardContainer>
  )
}

export const ChannelViewAllCard = memo<ChannelViewAllCardProps>(ChannelViewAllCardPure)

export default ChannelViewAllCard
