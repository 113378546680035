// @flow
import { createSelector } from 'reselect'
import {
  both,
  defaultTo,
  filter,
  has,
  isEmpty,
  join,
  or,
  pipe,
  pluck,
  prop,
  propEq,
  sortBy,
  uniq,
  allPass,
  propOr,
  cond,
  always,
  not,
  T,
} from 'ramda'

import type {
  Customer,
  CustomerSettings,
  Service,
  CustomerStatus,
  CustomerEmail,
  CustomerId,
} from '@alphaott/api-client/types/customer'
import { isInitialLoading, isNotEmpty } from '@alphaott/common-utils/utils/help'

import type { LoginState, LoginData } from '@alphaott/app-auth/models/reducers/login'

import type { CustomerState, CustomerData } from '../reducers'

import { ACTIVE } from './const'

// ToDo: fixme
type Store = any
// type Store = {|
//   // +customer: CustomerState,
//   // +subscriptions: SubscriptionsState,
//   // +app: AppState,
//   // +countries: CountriesState,

//   // ToDo: refactor me
//   // +app: any,
//   // +auth: any,
//   // +authMethods: any,
//   // +devices: any,
//   // +brandConfig: any,
//   // +login: any,
//   // +config: any,
//   // +init: any,
//   // +reset: any,
//   // +token: any,
//   // +routing: any,
// |}

export const SERVICE_TYPE = {
  VOD: 'VOD',
  LIVE_TV: 'LIVE_TV',
  APPS: 'APPS',
  CATCHUP_TV: 'CATCHUP_TV',
  RADIO: 'RADIO',
}

export const CONTENT_TYPE = {
  TV: 'TV',
  RADIO: 'RADIO',
  MOVIE: 'MOVIE',
  APP: 'APP',
  TV_SHOW: 'TV_SHOW',
}

export type ServiceType = $Keys<typeof SERVICE_TYPE>
export type ContentType = $Keys<typeof CONTENT_TYPE>

export const getServiceIds = (services: Array<Service>) =>
  pipe(
    filter(has('serviceSpec')),
    sortBy(prop('serviceSpec')),
    pluck('serviceSpec'),
    uniq,
    join(','),
    // $FlowFixMe
  )(services)

const getLoginState = (state: Store): LoginState => state.login
const getCustomerState = (state: Store): CustomerState => state.customer
export const selectQRCodeUri = (state: Store) => state.customerAuthorize.data.uri
export const selectQRCodeExpiresIn = (state: Store) => state.customerAuthorize.data.expires_in

export const getLoginData = createSelector(
  [getLoginState],
  (state: LoginState): LoginData => state.data,
)

export const getLoginMethod = createSelector(
  [getLoginData],
  (data: LoginData): LoginData => prop('loginMethod', data),
)

export const getLoginError = createSelector(
  [getLoginState],
  (state: LoginState): any => prop('errors', state),
)

export const getNotActivatedCustomerEmail = createSelector(
  [getLoginData],
  (data: LoginData): string => prop('notActivatedCustomerEmail', data),
)

export const getCustomer = createSelector(
  [getCustomerState],
  (state: CustomerState): Customer => state.data,
)

export const getCustomerEmail = createSelector(
  [getCustomer],
  (customer: Customer): CustomerEmail => prop('email', customer),
)

export const getCustomerId = createSelector(
  [getCustomer],
  (customer: Customer): CustomerId => prop('_id', customer),
)

export const getCustomerStatus = createSelector(
  [getCustomer],
  (customer: Customer): CustomerStatus => prop('status', customer),
)

export const getCustomerServices = createSelector(
  [getCustomer],
  (customer: Customer): Array<Service> => defaultTo([], customer.services),
)

export const getCustomerError = createSelector(
  [getCustomerState],
  (state: CustomerState): any => prop('errors', state),
)

export const getServiceType = (serviceType: ServiceType, contentType?: ContentType) =>
  createSelector(
    [getCustomerServices],
    // eslint-disable-next-line no-confusing-arrow
    (services: Array<Service>): Array<Service> => {
      const conditions = contentType
        ? allPass([propEq('status', ACTIVE), propEq('serviceType', serviceType), propEq('contentType', contentType)])
        : both(propEq('status', ACTIVE), propEq('serviceType', serviceType))

      return filter(conditions)(services)
    },
  )

export const getActiveServiceIds = createSelector(
  [getCustomerServices],
  (services: Array<Service>): string => {
    const list = filter(propEq('status', ACTIVE))(services)
    return getServiceIds(list)
  },
)

export const getServiceVodIds = createSelector(
  [getServiceType(SERVICE_TYPE.VOD, CONTENT_TYPE.MOVIE)],
  (services: Array<Service>): string => getServiceIds(services),
)

export const getServiceTVShowsVodIds = createSelector(
  [getServiceType(SERVICE_TYPE.VOD, CONTENT_TYPE.TV_SHOW)],
  (services: Array<Service>): string => getServiceIds(services),
)

export const getServiceLiveTvIds = createSelector(
  [getServiceType(SERVICE_TYPE.LIVE_TV, CONTENT_TYPE.TV)],
  (services: Array<Service>): string => getServiceIds(services),
)

export const getServiceAppsIds = createSelector(
  [getServiceType(SERVICE_TYPE.APPS)],
  (services: Array<Service>): string => getServiceIds(services),
)

export const getServiceCatchupIds = createSelector(
  [getServiceType(SERVICE_TYPE.CATCHUP_TV, CONTENT_TYPE.TV)],
  (services: Array<Service>): string => getServiceIds(services),
)

export const isAvailableCatchup = createSelector(
  [getServiceCatchupIds, getCustomerState],
  (catchupServiceIds: string, customerState: CustomerState): boolean =>
    or(customerState.isLoading, isNotEmpty(catchupServiceIds)),
)

export const getServiceRadioIds = createSelector(
  [getServiceType(SERVICE_TYPE.RADIO, CONTENT_TYPE.RADIO)],
  (services: Array<Service>): string => getServiceIds(services),
)

export const isCustomerInitLoading = createSelector(
  [getCustomerState],
  (customer: CustomerState): boolean => isInitialLoading(customer),
)

export const isCustomerLoading = createSelector(
  [getCustomerState],
  (customer: CustomerState): boolean => or(isEmpty(customer.data), customer.isLoading),
)

export const getCustomerMissedAccountFields = createSelector(
  [getCustomer],
  (customer: CustomerData): string[] => prop('missedAccountFields', customer),
)

export const getCustomerGuestMode = createSelector(
  [getCustomer],
  (customer: CustomerData): ?boolean =>
    cond([[isNotEmpty, propOr(false, 'guestMode')], [T, always(undefined)]])(customer),
)

export const getCustomerSettings = createSelector(
  [getCustomer],
  (customer: CustomerData): CustomerSettings => propOr({}, 'settings', customer),
)

export const getCustomerTracksSettings = createSelector(
  [getCustomerSettings],
  (customerSettings: CustomerSettings): CustomerSettings => propOr({}, 'tracks', customerSettings),
)

export const getIsAvailablePersonalLists = (store: Store) => not(getCustomerGuestMode(store))

export const getIsAvailableLiveTv = (store: Store) => isNotEmpty(getServiceType(SERVICE_TYPE.LIVE_TV)(store))
export const getIsAvailableRadio = (store: Store) => isNotEmpty(getServiceType(SERVICE_TYPE.RADIO)(store))
export const getIsAvailableTvShows = (store: Store) =>
  isNotEmpty(getServiceType(SERVICE_TYPE.VOD, CONTENT_TYPE.TV_SHOW)(store))
export const getIsAvailableMovies = (store: Store) =>
  isNotEmpty(getServiceType(SERVICE_TYPE.VOD, CONTENT_TYPE.MOVIE)(store))
