// @flow

// This is temporary solution. When we will make profiles, we will need to remake it.

import { getProfileLoadListsIntervalId } from '../../selectors'

export const CHANGE_PROFILE_LOAD_LISTS_INTERVAL_ID = 'app/CHANGE_PROFILE_LOAD_LISTS_INTERVAL_ID'

export const changeProfileLoadListsIntervalId = (intervalId: ?IntervalID) => (dispatch: Function) =>
  dispatch({
    type: CHANGE_PROFILE_LOAD_LISTS_INTERVAL_ID,
    payload: {
      intervalId,
    },
  })

export const resetProfileLoadListsInterval = (dispatch: Function, getState: Function) => {
  const state = getState()
  const intervalId = getProfileLoadListsIntervalId(state)

  if (intervalId) {
    clearInterval(intervalId)
    dispatch(changeProfileLoadListsIntervalId(null))
  }
}
