// @flow

import { map, path, values } from 'ramda'

export const tvshowSeasonsToItems = (seasons) =>
  seasons
    ? map(
        ({ title, posters, backgrounds, runtime, current, total, _id: id }) => ({
          id,
          title,
          src: path([0, 'path'], posters),
          backgroundSrc: path([0, 'path'], backgrounds),
          runtime,
          currentTime: current,
          totalTime: total,
          seasonId: id,
        }),
        values(seasons),
      )
    : []
