// @flow weak
import { createSelector } from 'reselect'
import {
  values,
  pipe,
  prop,
  pick,
  head,
  map,
  filter,
  isNil,
  propEq,
  propSatisfies,
  find,
  not,
  path,
  pathOr,
} from 'ramda'

import { getRouting } from '@alphaott/app-main/selectors'
import { getRestrictedLevel } from '@alphaott/app-settings/selectors'
import {
  getFilteredChannels,
  getFilteredChannelsPure,
  getSearchedChannels,
  calculatePopular,
  calculateMostWatched,
  calculateRecent,
  calculateSuggested,
  calculateRelated,
  isRestrictedChannel,
} from '@alphaott/app-channels/selectors'

import { prepareChannel, prepareChannelList } from '@alphaott/common-utils/utils/schemas/channels'
import { defaultToEmptyArray, defaultToEmptyObject } from '@alphaott/common-utils/utils/help'

import type { Channels as RadioChannels } from '@alphaott/common-utils/utils/filterChannels'
import type { RadioChannelMediaStreams, RadioChannelMediaStream } from '@alphaott/api-client/types/radioChannels'
import type { PreparedChannel } from '@alphaott/common-utils/utils/schemas/channels'
import type { Genre } from '@alphaott/api-client/types/genres'
import type { Category } from '@alphaott/api-client/types/categories'
import type { Language } from '@alphaott/api-client/types/languages'
import type { Country } from '@alphaott/api-client/types/countries'
import type { Favorites } from '@alphaott/api-client/types/favorites'
import type { Routing } from '@alphaott/common-types'

import type { RadioChannelsStore } from '../types'
import type { RadioChannelRecentPlayed, RadioChannelMostPLayed } from '../reducers'

export type RadioChannelsRecentPlayed = Array<RadioChannelRecentPlayed>
export type RadioChannelRecentPlayedIds = Array<string>
export type RadioChannelsFavoritesIds = Array<string>
export type AspectRatio = {
  aspectRatio?: string,
}

export const getRadioChannels = (state: RadioChannelsStore): RadioChannels => state.radioChannels.entities
export const getRadioLoading = (state: RadioChannelsStore): any => state.radioChannels.isLoading
export const isRadioLoadedSuccess = (state: RadioChannelsStore): boolean => state.radioChannels.isSuccess
export const isOpenRadioChannelFilter = (state: RadioChannelsStore): boolean => state.radioChannels.isOpenFilter
export const getRadioFavorites = (state: RadioChannelsStore): Favorites => state.radioChannelFavorites.data
export const getRadioRecentPlayed = (state: RadioChannelsStore): RadioChannelsRecentPlayed =>
  state.radioChannelRecentPlayed.data
export const isRadioRecentLoading = (state: RadioChannelsStore): boolean => state.radioChannelRecentPlayed.isLoading
export const isRadioRecentLoadedSuccess = (state: RadioChannelsStore): boolean =>
  state.radioChannelRecentPlayed.isSuccess
export const getRadioMostPlayed = (state: RadioChannelsStore): RadioChannelMostPLayed =>
  state.radioChannelMostPlayed.data
export const getRadioMediaStreams = (state: RadioChannelsStore): RadioChannelMediaStreams =>
  state.radioChannelSources.data
export const getRadioMediaStreamsLoading = (state: RadioChannelsStore): boolean => state.radioChannelSources.isLoading
export const getRadioGenres = (state: RadioChannelsStore): Array<Genre> => state.radioChannelGenres.data
export const getRadioCategories = (state: RadioChannelsStore): Array<Category> => state.radioChannelCategories.data
export const getRadioLanguages = (state: RadioChannelsStore): Array<Language> => state.radioChannelLanguages.data
export const getRadioCountries = (state: RadioChannelsStore): Array<Country> => state.radioChannelCountries.data
export const getRadioLimitError = (state: RadioChannelsStore): any => state.radioChannelLimits.errors

// TODO: Get appropriate mediaStream type
export const getRadioMediaStream = createSelector(
  [getRadioMediaStreams],
  (radioChannelMediaStreams: RadioChannelMediaStreams): RadioChannelMediaStream | {} =>
    pipe(
      defaultToEmptyArray,
      head,
      defaultToEmptyObject,
    )(radioChannelMediaStreams),
)

export const isFavoriteRadioChannel = (radioChannelId: string) =>
  createSelector(
    [getRadioFavorites],
    (favorites: Favorites): boolean =>
      pipe(
        find(propEq('item', radioChannelId)),
        isNil,
        not,
      )(favorites),
  )

export const getPreparedRadioFavoriteList = createSelector(
  [getRadioFavorites, getRadioChannels, getRadioGenres, getRadioCategories],
  (
    favorites: Favorites,
    radioChannels: RadioChannels,
    genres: Array<Genre>,
    categories: Array<Category>,
  ): Array<PreparedChannel> =>
    prepareChannelList(values(pick(map(prop('item'), favorites), radioChannels)), favorites, genres, categories),
)

export const getRadioRecentPlayedIds = createSelector(
  [getRadioRecentPlayed],
  (radioChannelRecentPlayed: RadioChannelRecentPlayed): RadioChannelRecentPlayedIds =>
    map(item => prop('id', item), radioChannelRecentPlayed),
)

export const getNotRestrictedRadioChannels = createSelector(
  [getRadioChannels, getRestrictedLevel],
  (radioChannels: RadioChannels = {}, parentControlLevel: ?number): RadioChannels =>
    filter(
      propSatisfies(val => isNil(val) || isNil(parentControlLevel) || val < parentControlLevel, 'pgRating'),
      radioChannels,
    ),
)

export const isRestrictedRadioChannel = isRestrictedChannel

export const getMostPlayedRadioChannelList = createSelector(
  [getRadioMostPlayed, getRadioChannels, getRadioFavorites, getRadioGenres, getRadioCategories],
  (
    mostPlayed: RadioChannelMostPLayed,
    radioChannels: RadioChannels,
    favorites: Favorites,
    genres: Array<Genre>,
    categories: Array<Category>,
  ): Array<PreparedChannel> =>
    prepareChannelList(calculateMostWatched(radioChannels, mostPlayed), favorites, genres, categories),
)

export const getRecentRadioChannelList = createSelector(
  [getRadioRecentPlayedIds, getRadioChannels, getRadioFavorites, getRadioGenres, getRadioCategories],
  (
    recentIds: RadioChannelRecentPlayedIds,
    radioChannels: RadioChannels,
    favorites: Favorites,
    genres: Array<Genre>,
    categories: Array<Category>,
  ): Array<PreparedChannel> =>
    prepareChannelList(calculateRecent(radioChannels, recentIds), favorites, genres, categories),
)

export const getPopularRadioChannelList = createSelector(
  [getRadioChannels, getRadioFavorites, getRadioGenres, getRadioCategories],
  (
    radioChannels: RadioChannels,
    favorites: Favorites,
    genres: Array<Genre>,
    categories: Array<Category>,
  ): Array<PreparedChannel> => prepareChannelList(calculatePopular(radioChannels), favorites, genres, categories),
)

export const getSuggestedRadioChannelList = createSelector(
  [
    getRadioRecentPlayedIds,
    getRadioMostPlayed,
    getRadioChannels,
    getRadioFavorites,
    getRadioGenres,
    getRadioCategories,
  ],
  (
    recentIds: RadioChannelRecentPlayedIds,
    mostPlayed: RadioChannelMostPLayed,
    radioChannels: RadioChannels,
    favorites: Favorites,
    genres: Array<Genre>,
    categories: Array<Category>,
  ): Array<PreparedChannel> =>
    prepareChannelList(calculateSuggested(recentIds, mostPlayed, radioChannels), favorites, genres, categories),
)

export const getRestrictedSuggestedRadioChannelList = createSelector(
  [
    getRadioRecentPlayedIds,
    getRadioMostPlayed,
    getNotRestrictedRadioChannels,
    getRadioFavorites,
    getRadioGenres,
    getRadioCategories,
  ],
  (
    recentIds: RadioChannelRecentPlayedIds,
    mostPlayed: RadioChannelMostPLayed,
    radioChannels: RadioChannels,
    favorites: Favorites,
    genres: Array<Genre>,
    categories: Array<Category>,
  ): Array<PreparedChannel> =>
    prepareChannelList(calculateSuggested(recentIds, mostPlayed, radioChannels), favorites, genres, categories),
)

export const getRelatedRadioChannelList = (radioChannelId: string) =>
  createSelector(
    [getRadioFavorites, getRadioChannels, getRadioGenres, getRadioCategories],
    (
      favorites: Favorites,
      radioChannels: RadioChannels,
      genres: Array<Genre>,
      categories: Array<Category>,
    ): Array<PreparedChannel> => {
      const radioChannel = prop(radioChannelId)(radioChannels)
      return prepareChannelList(calculateRelated(values(radioChannels), radioChannel), favorites, genres, categories)
    },
  )

export const getRestrictedRelatedRadioChannelList = (radioChannelId: string) =>
  createSelector(
    [
      getRadioFavorites,
      getRadioChannels,
      getNotRestrictedRadioChannels,
      getRadioGenres,
      getRadioCategories,
      getRestrictedLevel,
    ],
    (
      favorites: Favorites,
      radioChannels: RadioChannels,
      restrictedRadioChannels: RadioChannels,
      genres: Array<Genre>,
      categories: Array<Category>,
      parentControlLevel: number,
    ): Array<PreparedChannel> => {
      const radioChannel = prop(radioChannelId)(radioChannels)
      const allRadioChannels = isRestrictedRadioChannel(radioChannel, parentControlLevel)
        ? radioChannels
        : restrictedRadioChannels
      return prepareChannelList(calculateRelated(values(allRadioChannels), radioChannel), favorites, genres, categories)
    },
  )

export const getRadioChannelList = createSelector(
  [
    getRadioFavorites,
    getRadioChannels,
    getRadioGenres,
    getRadioCategories,
    getRadioLanguages,
    getRadioCountries,
    getRouting,
  ],
  (
    favorites: Favorites,
    radioChannels: RadioChannels,
    genres: Array<Genre>,
    categories: Array<Category>,
    languages: Array<Language>,
    countries: Array<Country>,
    routing: Routing,
  ): Array<PreparedChannel> =>
    prepareChannelList(
      pipe(
        values,
        getFilteredChannels({
          search: path(['location', 'search'], routing),
          genres,
          categories,
          languages,
          countries,
        }),
      )(radioChannels),
      favorites,
      genres,
      categories,
    ),
)

/*
  ToDo: Refactor me for react-native app
  START
*/

export const getRadioChannelsFilteredList = (filters = {}, search = '') =>
  createSelector(
    [
      getRadioFavorites,
      getRadioChannels,
      getRadioGenres,
      getRadioCategories,
      getRadioLanguages,
      getRadioCountries,
      getRouting,
    ],
    (
      favorites: Favorites,
      radioChannels: RadioChannels,
      genres: Array<Genre>,
      categories: Array<Category>,
      /* eslint-disable no-unused-vars */
      languages: Array<Language>,
      countries: Array<Country>,
      routing: Routing,
      /* eslint-enable no-unused-vars */
    ): Array<PreparedChannel> =>
      prepareChannelList(
        pipe(
          values,
          getFilteredChannelsPure({ filters, search }),
        )(radioChannels),
        favorites,
        genres,
        categories,
      ),
  )

export const getSearchRadioChannelListPure = (search = '') =>
  createSelector(
    [
      getRadioFavorites,
      getRadioChannels,
      getRadioGenres,
      getRadioCategories,
      getRadioLanguages,
      getRadioCountries,
      getRouting,
      getRouting,
    ],
    (
      favorites: Favorites,
      radioChannels: RadioChannels,
      genres: Array<Genre>,
      categories: Array<Category>,
      /* eslint-disable no-unused-vars */
      languages: Array<Language>,
      countries: Array<Country>,
      routing: Routing,
      /* eslint-enable no-unused-vars */
    ): Array<PreparedChannel> =>
      prepareChannelList(
        // eslint-disable-next-line no-use-before-define
        getSearchedChannels({
          search,
          channels: radioChannels,
        }),
        favorites,
        genres,
        categories,
      ),
  )

/*
  ToDo: Refactor me for react-native app
  END
*/

export const getSearchRadioChannelList = createSelector(
  [
    getRadioFavorites,
    getRadioChannels,
    getRadioGenres,
    getRadioCategories,
    getRadioLanguages,
    getRadioCountries,
    getRouting,
  ],
  (
    favorites: Favorites,
    radioChannels: RadioChannels,
    genres: Array<Genre>,
    categories: Array<Category>,
    languages: Array<Language>,
    countries: Array<Country>,
    routing: Routing,
  ): Array<PreparedChannel> =>
    prepareChannelList(
      getSearchedChannels({
        search: path(['location', 'search'], routing),
        channels: radioChannels,
      }),
      favorites,
      genres,
      categories,
    ),
)

export const getPreparedRadioChannel = (id: string) =>
  createSelector(
    [getRadioChannels, getRadioFavorites, getRadioGenres, getRadioCategories],
    (
      radioChannels: RadioChannels,
      favorites: Favorites,
      genres: Array<Genre>,
      categories: Array<Category>,
    ): PreparedChannel | any => {
      const radioChannel = prop(id)(radioChannels)
      if (radioChannel) {
        // $FlowFixMe
        return prepareChannel(radioChannel, favorites, genres, categories)
      }
      return {}
    },
  )

export const getRadioChannelVastTag = createSelector(
  [getRadioMediaStream],
  // $FlowFixMe
  (mediaStream: RadioChannelMediaStream): string => pathOr('', ['ads', 'vastUrl'], mediaStream),
)

export const getRadioChannelAdProvider = createSelector(
  [getRadioMediaStream],
  // $FlowFixMe
  (mediaStream: RadioChannelMediaStream): string => pathOr('', ['ads', 'provider'], mediaStream),
)

export const getRadioChannelAspectRatio = createSelector(
  [getRadioMediaStream],
  (mediaStream: RadioChannelMediaStream): AspectRatio => {
    const ratio = prop('screenRatio', mediaStream)
    return ratio ? { aspectRatio: `${Math.round(1000 * ratio)}:${1000}` } : {}
  },
)

export const getRadioChannelBackground = (id: string) =>
  createSelector(
    [getPreparedRadioChannel(id)],
    (radioChannel: PreparedChannel | any): ?string => prop('backgroundImage', radioChannel),
  )
