// @flow

import { useMemo, useCallback, useState, useEffect } from 'react'
import { path, or } from 'ramda'
import { notEqual } from 'ramda-adjunct'

import { preparePlayerSources, prepareShakaPlayerSources } from '@alphaott/drm-web'
import { usePrevious } from '@alphaott/common-utils'

type VideoPlayerSources = {
  isVideoJs?: boolean,
  isShakaPlayer?: boolean,
  mediaStream: Object,
}

export const useVideoPlayerSources = ({ isVideoJs, isShakaPlayer, mediaStream }: VideoPlayerSources) => {
  const preparedDefaultSources = useMemo(() => {
    if (isShakaPlayer) {
      return {
        src: null,
        drm: null,
      }
    }

    return []
  }, [isShakaPlayer])

  const [sources, setSources] = useState(preparedDefaultSources)

  const source = useMemo(() => or(path(['source', 'src'], sources), path([0, 'src'], sources)), [sources])

  const prevMediaStream = usePrevious(mediaStream)

  const getVideoJsSources = useCallback(async () => {
    const videoJsSources = await preparePlayerSources(mediaStream)
    setSources({ sources: videoJsSources })
  }, [mediaStream])

  const getShakaPlayerSources = useCallback(() => {
    const shakaplayerSources = prepareShakaPlayerSources(mediaStream)
    setSources(shakaplayerSources)
  }, [mediaStream])

  const getSources = useCallback(() => {
    if (isShakaPlayer) getShakaPlayerSources()
    if (isVideoJs) getVideoJsSources()
  }, [isVideoJs, isShakaPlayer, getVideoJsSources, getShakaPlayerSources])

  useEffect(() => {
    if (notEqual(mediaStream, prevMediaStream)) {
      getSources()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaStream])

  return {
    sources,
    source,
  }
}

export default useVideoPlayerSources
