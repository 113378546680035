// @flow
import typeToReducer from 'type-to-reducer'

import { FETCH_CONFIG_TYPE } from '../../actions/config'

export type ConfigState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: any,
}

export const initialState: ConfigState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: {},
}

const config = typeToReducer(
  {
    [FETCH_CONFIG_TYPE]: {
      LOADING: (state): ConfigState => ({ ...state, isLoading: true, isError: false, errors: [], data: {} }),

      SUCCESS: (state, action): ConfigState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        errors: [],
        data: action.payload.data,
      }),

      ERROR: (state, action): ConfigState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload,
      }),
    },
  },
  initialState,
)

export { config }
