// @flow
import { and, prop, equals, or } from 'ramda'

import { isTizen, isWebOS, isWeb } from './getDeviceType'
import { BUTTON_KEY_CODES } from '../const'

export const KEYBOARD_ENTER_KEY_CODES = {
  TIZEN_DONE: 65376,
  TIZEN_ENTER: 13,
  WEBOS: 13,
  WEB: 13,
}

// eslint-disable-next-line complexity
export const isKeyboardEnterKeyCode = (handler: any) => (event) => {
  const keyCode = prop('keyCode', event)

  const isTizenEnterKey = and(
    isTizen,
    or(
      equals(keyCode, KEYBOARD_ENTER_KEY_CODES.TIZEN_ENTER),
      equals(keyCode, KEYBOARD_ENTER_KEY_CODES.TIZEN_DONE),
    ),
  )
  const isWebOSEnterKey = and(isWebOS, equals(keyCode, KEYBOARD_ENTER_KEY_CODES.WEBOS))
  const isWebEnterKey = and(isWeb, equals(keyCode, KEYBOARD_ENTER_KEY_CODES.WEB))

  if (isWebEnterKey || isTizenEnterKey || isWebOSEnterKey) {
    and(handler, handler(event))
  }
}

export const isKeyboardBackKeyCode = (handler: any) => (event) => {
  const keyCode = prop('keyCode', event)

  const isBackButton = equals(keyCode, BUTTON_KEY_CODES.BACK)

  if (isBackButton) {
    and(handler, handler(event))
  }
}
