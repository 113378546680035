// @flow
import { is, gt } from 'ramda'

const isNumber = is(Number)
const MILLISECONDS = 1000

export const isValidTTL = isNumber

export const calculateExpires = (ttl: number = 0): ?number => Date.now() + ttl * MILLISECONDS

export const isCacheValid = (expires: number): boolean => {
  const currentTime: number = Date.now()

  return gt(expires, currentTime)
}
