// @flow
import { path } from 'ramda'
import { getChannelsBrief } from '@alphaott/api-client'
import { getPublicDomain, getContentDomain, getCacheChannels, isCacheValid } from '@alphaott/app-main/selectors'

export const FETCH_CHANNELS_TYPE = 'channels/FETCH_CHANNELS'
export const FILTER_OPEN_CHANNEL = 'channels/FILTER_OPEN'

export type FilterOpenChannelAction = {|
  type: 'channels/FILTER_OPEN',
  isOpenFilter: boolean,
|}

export const fetchChannelsAction = (serviceSpec: string) => (dispatch: Function, getState: Function) => {
  const state = getState()
  const publicApi = getPublicDomain(state)
  const contentApi = getContentDomain(state)
  const ttl = getCacheChannels(state)

  return dispatch({
    type: FETCH_CHANNELS_TYPE,
    payload: getChannelsBrief(publicApi, serviceSpec),
    meta: {
      contentApi,
      ttl,
    },
  })
}

// eslint-disable-next-line complexity
export const fetchChannels = (serviceSpec: string, force?: boolean = false) => (
  dispatch: Function,
  getState: Function,
) => {
  if (force) return dispatch(fetchChannelsAction(serviceSpec))

  const state = getState()
  const expires = path(['channels', 'expires'], state)

  if (expires && isCacheValid(expires)) return null

  return dispatch(fetchChannelsAction(serviceSpec))
}

export const filterOpenChannel = (isOpenFilter: boolean): FilterOpenChannelAction => ({
  type: FILTER_OPEN_CHANNEL,
  isOpenFilter,
})
