// @flow
import { useInitInput } from './keyboard'
import { useInitDeviceInfo } from './deviceInfo'

export const useInit = () => {
  useInitInput()
  useInitDeviceInfo()
}

/* eslint-disable no-undef */
export const useExit = () => {
  webOS.platformBack()
}
