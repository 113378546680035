// @flow

import styled from 'styled-components'
import { Scrollbar } from 'react-scrollbars-custom'

export const ScrollbarComponent = styled(Scrollbar)`
  .ScrollbarsCustom-Wrapper {
    right: 0 !important;
    height: 100%;
  }

  .ScrollbarsCustom-Scroller {
    padding-right: unset;
    margin-right: unset;
    margin-bottom: -16rem !important;
    padding-bottom: 0 !important;
  }

  .ScrollbarsCustom-Track {
    display: none;
  }

  .ScrollbarsCustom-Content {
    height: 100%;
  }
`
