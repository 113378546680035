// @flow

import { push } from 'redux-first-history'

import { resetPassword as reset } from '@alphaott/api-client/authorization'
import { getPrivateDomain } from '@alphaott/app-main/selectors'

export const RESET_PASSWORD_TYPE = 'password/RESET'
export const RESET_PASSWORD_STATE_TYPE = 'password/RESET_STATE'

export const resetPasswordState = (dispatch: Function) => {
  dispatch({
    // https://github.com/pitzcarraldo/reduxible/issues/8
    type: RESET_PASSWORD_STATE_TYPE,
  })
}

export const resetPassword = (email: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    // https://github.com/pitzcarraldo/reduxible/issues/8
    type: RESET_PASSWORD_TYPE,
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    payload: async () => {
      try {
        const result = await reset(getPrivateDomain(getState()), { email })
        dispatch(push('/auth/password/forgot/success'))
        return result
      } catch (err) {
        return Promise.reject(err)
      }
    },
  })

export const setNewPassword = (token: string, password: string, confirmPassword: string) => (
  dispatch: Function,
  getState: Function,
) =>
  dispatch({
    type: RESET_PASSWORD_TYPE,
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    payload: async () => {
      try {
        const result = await reset(getPrivateDomain(getState()), { password, confirmPassword }, token)
        dispatch(push('/auth/password/reset/success'))
        return result
      } catch (err) {
        dispatch(push('/auth/password/reset/error'))
        return Promise.reject(err)
      }
    },
  })
