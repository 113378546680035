// @flow
import { path } from 'ramda'

import type { MediaStreamType } from '@alphaott/api-client/types/mediaStream'
import type { Sources } from '@alphaott/api-client/types/source'
import type { DRM } from '@alphaott/api-client/types/drm'
import type { DRMProps } from '@alphaott/video-player'

import { getVideoType } from '../preparePlayerSources'
import preparedDRM from './preparedDRM'

type BaseMediaStream = {
  type: MediaStreamType,
  sources: Sources,
  drm: ?DRM,
}

type PreparedSource = {
  source: {
    src: ?string,
    type: ?string,
    drm: ?DRMProps,
  },
}

export const prepareShakaPlayerSources = (mediaStream: BaseMediaStream): PreparedSource => {
  const { sources = [], type, drm } = mediaStream
  const src = path(['0', 'src'], sources)

  return {
    source: {
      src,
      type: getVideoType(type),
      drm: preparedDRM(drm, src),
    },
  }
}

export default prepareShakaPlayerSources
