// @flow
/* eslint quote-props: ["error", "as-needed", { "numbers": true }] */
/* eslint-disable max-len */

/**
 * Color tool https://material.io/tools/color/
 * Theme editor https://in-your-saas.github.io/material-ui-theme-editor/
 * Create MUI Theme https://react-theming.github.io/create-mui-theme/
 * https://material.io/design/color/#color-theme-creation
 *
 * https://material.io/tools/color/#!/?view.left=0&view.right=0&primary.color=f26522&secondary.color=cc4b35&primary.text.color=222222&secondary.text.color=f8f4f4
 * https://material.io/tools/color/#!/?view.left=0&view.right=1&primary.color=f26522&secondary.color=cc4b35&primary.text.color=fafafa&secondary.text.color=ffffff
 */
import wtvplusLogoSvg from './assets/logo.svg'
import wtvplusAuthBg from './assets/authBg.png'
import wtvplusBrandConfig from './brand_config.json'

export const themeWtvplus = {
  name: 'wtvplus',
  font: {
    fontSize: 24,
    fontWeightMedium: 700,
  },
  palette: {
    background: { default: '#0F0F0F', main: '#0F0F0F', secondary: '#1d1d1d' },
    grey: {
      '50': '#fafafa',
      '100': '#f8f4f4',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#737373',
      '700': '#616161',
      '800': '#4d4c48',
      '900': '#171717',
      A700: '#616161',
      A100: '#d5d5d5',
      A400: '#303030',
      A200: '#aaaaaa',
    },
    text: {
      primary: 'rgb(255,255,255)',
      secondary: 'rgba(255,255,255,0.54)',
      disabled: 'rgba(255, 255, 255, 0.6)',
      hint: '#B2B2B2',
    },
    secondary: {
      main: '#cc4b35',
      light: 'rgb(214, 111, 93)',
      dark: 'rgb(142, 52, 37)',
      contrastText: '#ffffff',
    },
    common: { black: '#000', white: '#fff' },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    primary: {
      main: '#0C898A',
      slight: 'rgba(12, 137, 138, 0.5)',
      light: 'rgb(244, 131, 78)',
      dark: 'rgb(169, 70, 23)',
      contrastText: '#fafafa',
    },
    playback: {
      background: 'linear-gradient(0, rgba(0, 0, 0, 0.8) 43.32%, rgba(0, 0, 0, 0.0001) 100%)',
    },
  },
}

export const WtvPlusTheme = {
  name: 'wtvplus',
  styledTheme: themeWtvplus,
  logo: wtvplusLogoSvg,
  authBg: wtvplusAuthBg,
  brandConfig: wtvplusBrandConfig,
  extra: {
    auth: {
      logoHeight: 90,
    },
    logoHeight: 56,
  },
}
