// @flow

import { ACTIVATE_VOUCHER_CUSTOMER_TYPE } from '../../actions/appCustomer'

import type { CustomerState } from '.'

const voucherReducer = {
  [ACTIVATE_VOUCHER_CUSTOMER_TYPE]: {
    LOADING: (state: CustomerState): CustomerState => ({
      ...state,
      errors: [],
      isLoading: true,
    }),

    SUCCESS: (state: CustomerState): CustomerState => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      errors: [],
    }),

    ERROR: (state: CustomerState): CustomerState => ({
      ...state,
      isLoading: false,
    }),
  },
}
export { voucherReducer }
