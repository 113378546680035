// @flow
import URI from 'urijs'
import { map, isNil, isEmpty, prop } from 'ramda'

export const prepareLogos = (logos: any, domain: string) => {
  if (isNil(logos) || isEmpty(logos)) return []

  return map(logo => {
    const uri = new URI(prop('path', logo))
    const path = uri && uri.is('absolute') ? logo.path : `${domain}${logo.path}`

    return {
      ...logo,
      path,
    }
  }, logos)
}
