/* eslint-disable no-undef */
// @flow
import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { equals, head, or, prop, propOr, path } from 'ramda'
import '@procot/webostv/webOSTV'

import {
  prepareCurrentNetworkStatus,
  CONNECTED,
} from '@alphaott/smart-tv-common/src/hooks/useNetworkStatus'
import { setDeviceInfo } from '@alphaott/smart-tv-current-device/actions'

export const useInitDeviceInfo = () => {
  const dispatch = useDispatch()

  const setDeviceSystemInfo = useCallback(
    (response) => {
      const isSuccess = prop('returnValue', response)
      const model = prop('modelName', response)
      const osVersion = prop('sdkVersion', response)
      if (isSuccess) {
        dispatch(
          setDeviceInfo({
            brand: 'LG',
            model,
            os: 'WebOS',
            osVersion,
            visibilityStatus: document.hidden ? 'hidden' : 'visible',
          }),
        )
      }
    },
    [dispatch],
  )

  const setDeviceId = useCallback(
    (response) => {
      const isSuccess = prop('returnValue', response)
      const idList = propOr([], 'idList', response)
      const id = prop('idValue', head(idList))
      if (isSuccess) {
        dispatch(
          setDeviceInfo({
            _id: id,
            serial: id,
          }),
        )
      }
    },
    [dispatch],
  )

  const setNetworkInfo = useCallback(
    (response) => {
      const wifiState = path(['wifi', 'state'], response)
      const wiredState = path(['wired', 'state'], response)
      const isConnectedWifi = equals(wifiState, CONNECTED)
      const isConnectedWired = equals(wiredState, CONNECTED)
      const networkStatus = prepareCurrentNetworkStatus(or(isConnectedWifi, isConnectedWired))

      const isSuccess = prop('returnValue', response)
      if (isSuccess) {
        dispatch(
          setDeviceInfo({
            networkStatus,
          }),
        )
      }
    },
    [dispatch],
  )

  const setVisibilityStatus = useCallback(() => {
    dispatch(
      setDeviceInfo({
        visibilityStatus: document.hidden ? 'hidden' : 'visible',
      }),
    )
  }, [dispatch])

  useEffect(() => {
    webOS.service.request('luna://com.webos.service.tv.systemproperty', {
      method: 'getSystemInfo',
      parameters: {
        keys: ['modelName', 'firmwareVersion', 'sdkVersion'],
      },
      onComplete: setDeviceSystemInfo,
    })
    webOS.service.request('luna://com.webos.service.sm', {
      method: 'deviceid/getIDs',
      parameters: {
        idType: ['LGUDID'],
      },
      onSuccess: setDeviceId,
    })
    webOS.service.request('luna://com.webos.service.connectionmanager', {
      method: 'getStatus',
      parameters: {
        subscribe: true,
      },
      onSuccess: setNetworkInfo,
    })
    document.addEventListener('visibilitychange', setVisibilityStatus)

    return () => {
      document.removeEventListener('visibilitychange', setVisibilityStatus)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
