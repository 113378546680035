// @flow
import { map } from 'ramda'

import { DRM_KEY_SYSTEM_NAMES } from './const'

const isKeySystemSupported = async (keySystem, configuration) => {
  const config = configuration || [
    {
      initDataTypes: ['cenc'],
      audioCapabilities: [
        {
          contentType: 'audio/mp4;codecs="mp4a.40.2"',
        },
      ],
      videoCapabilities: [
        {
          contentType: 'video/mp4;codecs="avc1.42E01E"',
        },
      ],
    },
  ]
  try {
    await window.navigator.requestMediaKeySystemAccess(keySystem, config)
    return keySystem
  } catch (err) {
    return null
  }
}

// Returns an array of supported keySystems
const getSupportedKeySystems = (drmType: string) => {
  if (DRM_KEY_SYSTEM_NAMES[drmType]) {
    // TODO: Add configuration prop from response with source
    return Promise.all(map(drmSystem => isKeySystemSupported(drmSystem), DRM_KEY_SYSTEM_NAMES[drmType]))
  }
  return []
}

export default getSupportedKeySystems
