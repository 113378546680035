import rtlcss from 'rtlcss'

// eslint-disable-next-line consistent-return
const stylisRTLCSS = (context, content) => {
  if (context === -1) {
    try {
      return rtlcss.process(content)
    } catch (err) {
      console.error(err) // eslint-disable-line no-console
    }
  }
}

/**
 * Object.defineProperty(function, ...)
   https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/name
*/

/**
 * > please make sure each plugin is uniquely-named
 * https://blog.logrocket.com/using-the-stylesheetmanager-component-in-styled-components-5-0/
 */
// Object.defineProperty(stylisRTLCSS, 'name', { value: 'stylisRTLCSS' })

export default stylisRTLCSS
