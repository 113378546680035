// @flow

import styled from 'styled-components'

import {
  getBackgroundSecondaryColor,
  getColorPrimary,
  getTextColorPrimary,
} from '@alphaott/smart-tv-themes'

// eslint-disable-next-line complexity
const getBackgroundColor = ({ focused, primaryColor, secondaryColor }) => {
  const focusedColor = primaryColor || getColorPrimary
  const unfocusedColor = secondaryColor || getBackgroundSecondaryColor

  return focused ? focusedColor : unfocusedColor
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${getTextColorPrimary};
  background: ${getBackgroundColor};
  min-width: 40rem;
  height: 40rem;
  border-radius: 20rem;

  & svg {
    width: 16rem;
    height: 16rem;
    margin: 12rem;
  }
`

export const Label = styled.div`
  margin-right: 12rem;
  cursor: default;
  font-size: 18rem;
`
