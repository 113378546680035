// @flow
import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { UserSettingsProvider } from '@alphaott/user-settings/models/components'

import store, { basename, history, persistor } from './redux/store'
import { RootContainer } from './containers/RootContainer'
import { GlobalError } from './components/GlobalError'
import Sentry from './core/sentry' // eslint-disable-line no-unused-vars

export const App = () => (
  // eslint-disable-next-line react/no-unstable-nested-components
  <Sentry.ErrorBoundary fallback={(event) => <GlobalError error={event.error} />}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <UserSettingsProvider>
          <RootContainer basename={basename} history={history} />
        </UserSettingsProvider>
      </PersistGate>
    </Provider>
  </Sentry.ErrorBoundary>
)

export default App
