// @flow

import { useMemo, useCallback } from 'react'
import { cond, equals, and, not } from 'ramda'

import { useLastFocusHistory } from './useLastFocusHistory'
import {
  getGridListScrollOffset,
  getHorizontalListScrollOffset,
  getVerticalListScrollOffset,
} from '@alphaott/smart-tv-utils'

export type ListType = 'GRID' | 'HORIZONTAL_LIST' | 'VERTICAL_LIST'

export const LIST_TYPES = {
  GRID: 'GRID',
  HORIZONTAL_LIST: 'HORIZONTAL_LIST',
  VERTICAL_LIST: 'VERTICAL_LIST',
}

export const useLastFocusScroll = (type: ListType, listRef: any) => {
  const { onGetItemOption } = useLastFocusHistory()

  // columnIndex
  const itemIndex = useMemo(() => onGetItemOption(['itemIndex']), [onGetItemOption])
  // rowIndex
  const itemSectionIndex = useMemo(() => onGetItemOption(['sectionIndex']), [onGetItemOption])

  const handleGridScrollToItem = useCallback(
    (rowIndex) => {
      if (listRef) {
        const scrollTop = getGridListScrollOffset(listRef, rowIndex)
        listRef.scrollTo({ scrollTop })
      }
    },
    [listRef],
  )

  const handleListScrollToItem = useCallback(
    (index, getOffset, isBlackedSection) => {
      if (and(listRef, not(isBlackedSection))) {
        listRef.scrollTo(getOffset(listRef, index))
      }
    },
    [listRef],
  )

  const handleScrollToItem = useCallback(
    (isBlackedSection: boolean) =>
      cond([
        [equals(LIST_TYPES.GRID), () => handleGridScrollToItem(itemSectionIndex)],
        [
          equals(LIST_TYPES.HORIZONTAL_LIST),
          () => handleListScrollToItem(itemIndex, getHorizontalListScrollOffset, isBlackedSection),
        ],
        [
          equals(LIST_TYPES.VERTICAL_LIST),
          () => handleListScrollToItem(itemSectionIndex, getVerticalListScrollOffset),
        ],
      ])(type),
    [type, handleGridScrollToItem, itemIndex, handleListScrollToItem, itemSectionIndex],
  )

  return handleScrollToItem
}
