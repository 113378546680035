// @flow

import React, { memo, useCallback, useMemo } from 'react'
import { map } from 'ramda'

import { SubMenuItem } from './SubMenuItem'
import { SubMenuContainer } from './SubMenu.styles'
import { useMenuItems } from '../../../hooks'

import type SubMenuItems from '../SideMenu'

type SubMenuPureProps = {
  isOpen: boolean,
  items: SubMenuItems,
  onClickItem: Function,
}

export const SubMenuPure = ({ isOpen, items, onClickItem }: SubMenuPureProps) => {
  const preparedItems = useMenuItems(items)

  const handleClickItem = useCallback((route) => () => onClickItem(route), [onClickItem])

  const availableItems = useMemo(
    () =>
      map(
        ({ icon, label, route, focusKey }) => (
          <SubMenuItem
            focusKey={focusKey}
            key={label}
            icon={icon}
            label={label}
            route={route}
            isShowLabel={isOpen}
            onClick={handleClickItem(route)}
          />
        ),
        preparedItems,
      ),
    [isOpen, handleClickItem, preparedItems],
  )

  return <SubMenuContainer>{availableItems}</SubMenuContainer>
}

export const SubMenu = memo(SubMenuPure)
