// @flow
import typeToReducer from 'type-to-reducer'
import type { Program } from '@alphaott/api-client/types/programs'

import { getFilteredPrograms } from '../../../utils'
import { FETCH_PROGRAMS_BY_DATE_TYPE } from '../../tabs/actions'
import { FETCH_PROGRAMS_GUIDE } from '../actions'

export type ProgramsPerChannel = { [string]: Program }
export type ProgramsState = { [string]: ProgramsPerChannel }

export type ProgramGridState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: ProgramsState,
}

const initialState: ProgramGridState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: {},
}

const programGrid = typeToReducer(
  {
    [FETCH_PROGRAMS_GUIDE]: {
      LOADING: (state): ProgramGridState => ({
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
      }),

      SUCCESS: (state): ProgramGridState => ({
        ...state,
        isSuccess: true,
        isLoading: false,
        isError: false,
      }),

      ERROR: (state, action): ProgramGridState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
    [FETCH_PROGRAMS_BY_DATE_TYPE]: {
      SUCCESS: (state, action): ProgramGridState => {
        const { payload, meta } = action
        const filteredPrograms = getFilteredPrograms(payload.data)
        return {
          ...state,
          data: {
            ...state.data,
            [meta.id]: {
              ...state.data[meta.id],
              [meta.start]: filteredPrograms,
            },
          },
        }
      },
    },
  },
  initialState,
)

export { programGrid }
