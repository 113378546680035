// @flow

import styled from 'styled-components'

export const Container = styled.div``

export const Player = styled.video`
  .vjs-tech {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: fill;
  }

  .vjs-text-track-display {
    position: absolute;
    bottom: 3em;
    left: 0;
    right: 0;
    top: 0;
    pointer-events: none;
    background-color: #000;

    & div {
      & div {
        z-index: 1;
      }
    }
  }
`
