// @flow

import React, { memo, useMemo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { map, path, equals } from 'ramda'

import {
  useFocusable,
  useLastFocusedItem,
  useLastFocusHistory,
  FocusContext,
} from '@alphaott/smart-tv-spatial-navigation'
import { BUTTON_KEY_NAMES } from '@alphaott/smart-tv-platforms'

import { MenuItem } from '../MenuItem'
import { Container, Title, List } from './OptionsMenu.styles'

type Options = {
  focusKey: string,
  label: string,
  onClick: Function,
}

type OptionsMenuProps = {
  options: Options[],
  onHideMenu: Function,
}

// eslint-disable-next-line complexity
const OptionsMenuPure = ({ options, onHideMenu }: OptionsMenuProps) => {
  const { ref, focusKey, setFocus } = useFocusable({
    trackChildren: true,
    isFocusBoundary: true,
  })

  const { t } = useTranslation()
  const itemFocusKey = useLastFocusedItem()
  const { onRemoveLastFocus } = useLastFocusHistory()

  const firstFocusKey = useMemo(() => path([0, 'focusKey'], options), [options])

  const handleCallback = useCallback(
    (callback) => (menuItemFocusKey: string) => {
      onHideMenu()
      setFocus(itemFocusKey)

      if (equals('ADD_TO_MY_LIST_BUTTON', menuItemFocusKey)) {
        onRemoveLastFocus()
      }

      callback && callback(setFocus)
    },
    [itemFocusKey, onHideMenu, onRemoveLastFocus, setFocus],
  )

  const renderedOptions = useMemo(
    () =>
      map(
        ({ focusKey: key, label, onClick }) => (
          <MenuItem key={key} focusKey={key} onPress={handleCallback(onClick)}>
            {label}
          </MenuItem>
        ),
        options,
      ),
    [options, handleCallback],
  )

  useEffect(() => {
    if (firstFocusKey) {
      setFocus(firstFocusKey)
    }
  }, [firstFocusKey, setFocus])

  const handleBackPress = useCallback(
    (event) => {
      setFocus(itemFocusKey)
      onHideMenu()
      onRemoveLastFocus()
      event.stopPropagation()
    },
    [itemFocusKey, setFocus, onHideMenu, onRemoveLastFocus],
  )

  useEffect(() => {
    window?.addEventListener(BUTTON_KEY_NAMES.BACK, handleBackPress, true)
    return () => window?.removeEventListener(BUTTON_KEY_NAMES.BACK, handleBackPress, true)
  }, [handleBackPress])

  return (
    <FocusContext.Provider value={focusKey}>
      <Container>
        <Title>{t('mwstv_options')}</Title>

        <List ref={ref}>{renderedOptions}</List>
      </Container>
    </FocusContext.Provider>
  )
}

export const OptionsMenu = memo<OptionsMenuProps>(OptionsMenuPure)

export default OptionsMenu
