// @flow

import { SubmissionError } from 'redux-form'
import { push } from 'redux-first-history'
import { defaultTo, and, not, isNil, is } from 'ramda'

import type { LocationShape } from 'react-router-dom'

import { is20x, isApiError, is403, is409, is451 } from '@alphaott/api-client/utils/makeResponse'
import { isNotActiveCustomerError, isGuestExpiredError } from '@alphaott/api-client/errors'
import privateRequest from '@alphaott/api-client/utils/privateRequest'
import { logout, saveToken } from '@alphaott/app-auth/actions'
import { resetGuestAccessTTL } from '@alphaott/app-core/models/actions/appGuestAccess'
import { CLIENT_ID, CLIENT_SECRET } from '@alphaott/app-config'

import { geoBlockedError } from '../../actions'
import { getPrivateDomain } from '../../selectors'

type Location = string | LocationShape

type WrapperConfig = {
  redirect?: Location,
  isForm?: boolean,
  useLogout?: boolean,
}

const defaultConfig = {
  redirect: null,
  isForm: false,
  useLogout: true,
}

const isTruth = defaultTo(false)

const toDefaultConfig = defaultTo(defaultConfig)
// eslint-disable-next-line complexity
export const logoutRequestWrapper = (request: Function, config?: WrapperConfig) => (...data: any) => async (
  dispatch: Function,
  getState: Function,
) => {
  const { redirect, isForm, useLogout } = toDefaultConfig(config)
  const withRequest = privateRequest(request)

  const state = getState()

  const { refresh, expires, access } = state.token

  const privateParam = {
    domain: getPrivateDomain(state),
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    refresh_token: refresh,
    token_expires: expires,
    access_token: access,
    onRefreshToken: newToken => dispatch(saveToken(newToken)),
  }
  try {
    const resp: Object = await withRequest(privateParam)(getPrivateDomain(state), ...data)

    if (and(not(isNil(redirect)), is20x(resp))) {
      dispatch(push(redirect))
    }
    return resp
  } catch (err) {
    if (isNotActiveCustomerError(err)) {
      dispatch(push('/auth/verify-your-email'))
      throw err
    }
    if (isGuestExpiredError(err)) {
      dispatch(resetGuestAccessTTL)
    }
    if (is409(err)) {
      dispatch(logout)
    }
    if (and(isTruth(useLogout), is403(err)) && not(isGuestExpiredError(err))) {
      dispatch(logout)
    }
    if (and(isTruth(isForm), isApiError(err))) {
      const error = is(String, err.error) ? JSON.parse(err.error) : err.error
      throw new SubmissionError({ ...err.error, _error: error.message || error.statusText })
    }
    // Checks Geoblocked Error
    if (is451(err)) {
      dispatch(geoBlockedError(err))
    }

    throw err
  }
}

export const withoutDataLogoutWrapper = (request: Function, config?: WrapperConfig) =>
  logoutRequestWrapper(request, config)()

export const formLogoutWrapper = (request: Function, redirect?: Location) =>
  logoutRequestWrapper(request, {
    redirect,
    isForm: true,
  })

export const limitLogoutWrapper = (request: Function) => logoutRequestWrapper(request, { useLogout: false })

export default logoutRequestWrapper
