import styled from 'styled-components'
import { Text } from '@alphaott/smart-tv-components'

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${(props) => props.headerHeight}rem);
  padding: 0 40rem;
  box-sizing: border-box;
`

export const Heading = styled(Text)`
  font-size: 36rem;
  font-weight: bold;
  text-transform: uppercase;
`
