/* eslint-disable complexity */
// @flow
import styled from 'styled-components'

import { Text } from '@alphaott/smart-tv-components'
import { Progress } from '../ProgressLine'
import { PROGRAM_ITEM_WIDTH } from '../ProgramItem'

const getTitleMargin = (props) => {
  if (props.isLive && props.isPlayable) {
    return '12rem'
  }
  if (props.isPlayable) {
    return '3rem'
  }
  return 0
}

const getFontWeight = (props) => {
  if (props.isLive || (props.isLive && props.isPlayable)) {
    return 'normal'
  }
  if (props.isPlayable) {
    return 'bold'
  }
  return 'normal'
}

export const Container = styled.div`
  width: ${PROGRAM_ITEM_WIDTH}rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 205rem;
  margin-bottom: 12rem;
`

export const Label = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 16rem;
  line-height: 19rem;
  letter-spacing: 0.468572rem;
  text-transform: uppercase;
`

export const Title = styled(Text)`
  font-style: normal;
  font-weight: ${getFontWeight};
  font-size: ${(props) => (props.isPlayable && !props.isLive ? '42rem' : '32rem')};
  line-height: ${(props) => (props.isPlayable && !props.isLive ? '47rem' : '37rem')};
  min-height: ${(props) => (!props.isPlayable && !props.isLive ? '48rem' : 0)};
  margin-top: ${getTitleMargin};
  max-width: ${PROGRAM_ITEM_WIDTH}rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const TimeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Time = styled(Text)`
  font-style: normal;
  font-weight: ${getFontWeight};
  font-size: ${(props) => (props.isPlayable && !props.isLive ? '25rem' : '21rem')};
  line-height: 25rem;
  margin: 8rem 0;
  ::first-letter {
    text-transform: uppercase;
  }
`

export const ProgressBox = styled.div`
  position: relative;
  width: 100%;
  width: 1054rem;
`

export const StyledProgress = styled(Progress)`
  background: rgba(255, 255, 255, 0.24);
`

export const Paragraph = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 24rem;
  line-height: 32rem;
  max-height: 96rem;
  overflow: hidden;
  text-overflow: ellipsis;
`
