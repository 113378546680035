// @flow

import { createSelector } from 'reselect'
import { isEmpty } from 'ramda'

import {
  getSupportEmail as getBrandEmail,
  getSupportWww as getBrandWww,
  getSupportPhone as getBrandPhone,
  getBrandTitle,
  getBrandAddress,
} from '@alphaott/app-main/selectors'

import type { Address } from '@alphaott/common-types'

import {
  getServiceProviderSupportEmail,
  getServiceProviderWww,
  getServiceProviderPhone,
  getServiceProviderAddress,
  getServiceProviderTitle,
} from './serviceProvider'

export const getSupportTitle = createSelector(
  [getServiceProviderTitle, getBrandTitle],
  (serviceProviderName: string, brandName: string): string => serviceProviderName || brandName,
)

export const getSupportEmail = createSelector(
  [getServiceProviderSupportEmail, getBrandEmail],
  (serviceProviderEmail: string, brandEmail: string): string => serviceProviderEmail || brandEmail,
)

export const getSupportWww = createSelector(
  [getServiceProviderWww, getBrandWww],
  (serviceProviderWeb: string, brandWeb: string): string => serviceProviderWeb || brandWeb,
)

export const getSupportPhone = createSelector(
  [getServiceProviderPhone, getBrandPhone],
  (serviceProviderPhone: string, brandPhone): string => serviceProviderPhone || brandPhone,
)

// prettier-ignore
export const getSupportAddress = createSelector(
  [getServiceProviderAddress, getBrandAddress],
  (serviceProviderAddress, brandAddress): Address =>
    (isEmpty(serviceProviderAddress) ? brandAddress : serviceProviderAddress),
)
