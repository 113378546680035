// @flow
import { makePostRequest } from '../utils'

import type { ResponseAPI } from '../types/api'
import type { Token } from '../types/token'

export const login = (domain: string, data: any): Promise<ResponseAPI<Token>> =>
  makePostRequest(`${domain}/client/auth/token`, { data })

export const signUp = (domain: string, data: any): Promise<ResponseAPI<Token>> =>
  makePostRequest(`${domain}/client/auth/signup`, { data })

export const signUpV2 = (domain: string, { token, ...data }: any): Promise<ResponseAPI<any>> =>
  makePostRequest(`${domain}/client/auth/v2/signup`, { headers: { token }, data })

export const authorize = (domain: string, { data, token }: any): Promise<ResponseAPI<any>> =>
  makePostRequest(`${domain}/client/auth/authorize`, { headers: { token }, data })

type resetProps = {
  email?: string,
  password?: string,
  confirmPassword?: string,
}

export const resetPassword = (domain: string, data: resetProps, token?: string): Promise<ResponseAPI<any>> =>
  makePostRequest(`${domain}/client/auth/password/reset${token ? `/${token}` : ''}`, {
    data,
  })

export const resendVerificationEmail = (domain: string, data: resetProps): Promise<ResponseAPI<any>> =>
  makePostRequest(`${domain}/client/auth/activate`, {
    data,
  })

export const signUpActivate = (domain: string, activateToken: string): Promise<ResponseAPI<Token>> =>
  makePostRequest(`${domain}/client/auth/activate/${activateToken}`)

export const sendOtp = (domain: string, data: any): Promise<ResponseAPI<any>> =>
  makePostRequest(`${domain}/client/auth/otp`, { data })
