// @flow
import { createSelector } from 'reselect'
import {
  path,
  pathOr,
  prop,
  pipe,
  defaultTo,
  multiply,
  propOr,
  has,
  mergeDeepRight,
  mergeRight,
  equals,
  not,
} from 'ramda'

import { getCustomerGuestMode } from '@alphaott/app-core/models/selectors/customer'

import type {
  Config,
  Limits,
  Voucher,
  Billing,
  UI,
  Ad,
  Epg,
  UIPlayer,
  UIMovies,
  UITVShows,
  UICatalog,
  UISignupFields,
  UICatalogContentComposition,
  MobileOptions,
} from '@alphaott/api-client/types/config'

import { defaultToEmptyObject } from '@alphaott/common-utils/utils/help'
import {
  LIMITS_INTERVAL,
  VOUCHER_ENABLE,
  BILLING_ENABLED,
  PLAY_IN_BACKGROUND,
  BILLING_SIGN_UP_PLAN_STEP_ENABLED,
  BILLING_HOSTED_PAGE_IFRAME_ENABLED,
  CHECK_ADBLOCK_ENABLED,
  DEFAULT_CATALOG_CONFIG,
  DEFAULT_MAX_RATING,
  NO_ACTIVE_SUBSCRIPTION,
  LOGOUT_ON_EMPTY_SUBSCRIPTIONS,
  DEMO_USER_AND_NO_SUBSCRIPTIONS,
  DEFAULT_SIGNUP_ENABLED,
} from '@alphaott/app-config'

import type { AuthStore } from '@alphaott/app-auth/types'

import { isEnabled, isEnabledOption } from '../utils/config'

import type { MainStore } from '../types'

type Store = MainStore & AuthStore

export const getConfig = (state: Store): Config => state.config.data
export const getIsErrorConfig = (state: Store): boolean => state.config.isError

export const getConfigUI = createSelector(
  [getConfig],
  (config: Config): UI => defaultToEmptyObject(config.ui),
)

export const getLimits = createSelector(
  [getConfig],
  (config: Config): Limits | {} => defaultToEmptyObject(config.limits),
)

export const getAd = createSelector(
  [getConfig],
  (config: Config): Ad | {} => defaultToEmptyObject(config.ad),
)

export const getEpg = createSelector(
  [getConfig],
  (config: Config): Epg | {} => defaultToEmptyObject(config.epg),
)

export const getUIPlayer = createSelector(
  [getConfig],
  (config: Config): UIPlayer | {} => defaultToEmptyObject(config.ui.player),
)

export const getUISignupFields = createSelector(
  [getConfigUI],
  (ui: UI): UISignupFields | {} => pathOr({}, ['signup', 'fields'], ui),
)

export const getUISignupSecondStepEnabled = createSelector(
  [getConfigUI],
  (ui: UI): UISignupFields | {} => pathOr(true, ['signup', 'secondStepEnabled'], ui),
)

export const getUISignupOrder = createSelector(
  [getConfig],
  (config: Config): ?number => {
    const order = path(['ui', 'signup', 'order'], config)

    if (order) return parseInt(order, 10)

    return null
  },
)

export const getMoviesMaxRating = createSelector(
  [getConfigUI],
  (ui: UI): number => path(['movies', 'rating', 'max'], ui) || path(['rating', 'max'], ui) || DEFAULT_MAX_RATING,
)

export const getTVShowsMaxRating = createSelector(
  [getConfigUI],
  (ui: UI): number => path(['tvshows', 'rating', 'max'], ui) || path(['rating', 'max'], ui) || DEFAULT_MAX_RATING,
)

export const isSignupEnabled = createSelector(
  [getConfigUI],
  (ui: UI): boolean => {
    const hasEnabled = path(['signup', 'enabled'], ui)

    // hasEnabled === false
    if (equals(hasEnabled, false)) return hasEnabled

    return DEFAULT_SIGNUP_ENABLED
  },
)

export const isMobileSignupEnabled = createSelector(
  [getConfig],
  (config: Config): boolean => pathOr(false, ['platform', 'mobile', 'signup', 'enabled'], config),
)

export const isSmartTVSignupEnabled = createSelector(
  [getConfig],
  (config: Config): boolean => pathOr(false, ['platform', 'smarttv', 'ui', 'signup', 'enabled'], config),
)

export const isServicesEnabledForWeb = createSelector(
  [getConfig],
  (config: Config): boolean => {
    if (localStorage.getItem('dev')) {
      return true
    }

    return pathOr(true, ['platform', 'web', 'services', 'enabled'], config)
  },
)

export const getUIMovies = createSelector(
  [getConfigUI],
  (ui: UI): ?UIMovies => prop('movies', ui),
)

export const getUIMoviesSort = (defaultSort: string = '-rating') =>
  createSelector(
    [getUIMovies],
    // $FlowFixMe
    (uiMoviesConfig: ?UIMovies = {}): string => pathOr(defaultSort, ['catalog', 'sort'], uiMoviesConfig),
  )

export const getUITVShows = createSelector(
  [getConfigUI],
  (ui: UI): ?UITVShows => prop('tvshows', ui),
)

export const getUITVShowsSort = (defaultSort: string = '-rating') =>
  createSelector(
    [getUITVShows],
    // $FlowFixMe
    (uiTVShowsConfig: ?UITVShows = {}): string => pathOr(defaultSort, ['catalog', 'sort'], uiTVShowsConfig),
  )

/**
 * propOr(LIMITS_INTERVAL, 'interval') => 60 seconds
 * multiply(1000) => 60000 milliseconds
 */

const getNumberValue = (limits: Limits) => parseInt(prop('interval', limits), 10)

export const getLimitsInterval = createSelector(
  [getLimits],
  (limits: Limits): number =>
    pipe(
      getNumberValue,
      defaultTo(LIMITS_INTERVAL),
      multiply(1000),
    )(limits),
)

/**
 * limitsCheckEnabled = false by default
 */
export const getLimitsEnabled = createSelector(
  [getLimits, getCustomerGuestMode],
  (limits: Limits, isGuestMode: boolean): boolean => not(isGuestMode) && isEnabled(limits),
)

/**
 * adCheckEnabled = false by default
 */
export const isAdEnabled = createSelector(
  [getAd],
  (ad: Ad): boolean => isEnabled(ad),
)

/**
 * blockAdBlockEnabled = true by default
 */
export const isBlockAdBlockEnabled = createSelector(
  [getAd],
  (ad: Ad): boolean => isEnabled(ad) && propOr(CHECK_ADBLOCK_ENABLED, 'block', ad),
)

export const isEpgEnabled = createSelector(
  [getEpg],
  (epg: Epg): boolean => isEnabled(epg),
)

export const isPlayInBackground = createSelector(
  [getUIPlayer],
  // eslint-disable-next-line no-confusing-arrow
  (player: UIPlayer): boolean =>
    has('playInBackground', player) ? prop('playInBackground', player) : PLAY_IN_BACKGROUND,
)

export const isAirplayEnabled = createSelector(
  [getUIPlayer],
  (player: UIPlayer): boolean => {
    const airplay = path(['screencast', 'airplay'], player)

    return isEnabled(airplay)
  },
)

export const isChromecastEnabled = createSelector(
  [getUIPlayer],
  (player: UIPlayer): boolean => {
    const chromecast = path(['screencast', 'chromecast'], player)

    return isEnabled(chromecast)
  },
)

export const getSortValueMovieList = createSelector(
  [getUIMovies],
  (movies: UIMovies): ?string => path(['catalog', 'sort'], movies),
)

export const getSortValueTVShowList = createSelector(
  [getUITVShows],
  (tvshows: UITVShows): ?string => path(['catalog', 'sort'], tvshows),
)

export const getPrivateDomain = createSelector(
  [getConfig],
  (config: Object): string => path(['api', 'private', 'url'], config),
)

export const getPublicDomain = createSelector(
  [getConfig],
  (config: Object): string => path(['api', 'public', 'url'], config),
)

export const getContentDomain = createSelector(
  [getConfig],
  (config: Object): string => path(['content', 'url'], config),
)

/**
 * Billing
 */

export const getVoucherConfig = createSelector(
  [getConfig],
  (config: Config): Object => defaultToEmptyObject(prop('voucher', config)),
)

export const isVoucherEnabled = createSelector(
  [getVoucherConfig],
  (config: Voucher): boolean => propOr(VOUCHER_ENABLE, 'enabled', config),
)

export const getBillingConfig = createSelector(
  [getConfig],
  (config: Config): Object => defaultToEmptyObject(prop('billing', config)),
)

export const isBillingEnabled = createSelector(
  [getBillingConfig],
  (config: Billing): boolean => propOr(BILLING_ENABLED, 'enabled', config),
)

export const isDisallowMultipleSubscriptions = createSelector(
  [getBillingConfig],
  (config: Billing): boolean => propOr(false, 'disallowMultipleSubscriptions', config),
)

export const isBillingSignupPlanStepEnabled = createSelector(
  [getBillingConfig],
  (config: Billing): boolean => propOr(BILLING_SIGN_UP_PLAN_STEP_ENABLED, 'isSignupPlanStep', config),
)

export const isBillingHostedPageIframeEnabled = createSelector(
  [getBillingConfig],
  (config: Billing): boolean => propOr(BILLING_HOSTED_PAGE_IFRAME_ENABLED, 'iframe', config),
)

export const getUICatalogConfig = createSelector(
  [getConfigUI],
  (ui: UI): UICatalog => mergeDeepRight(DEFAULT_CATALOG_CONFIG, propOr({}, 'catalog', ui)),
)

export const getUIContentComposition = (content: 'discover' | 'tv' | 'movie' | 'tvshow') =>
  createSelector(
    [getUICatalogConfig],
    (uiCatalog: UICatalog): UICatalogContentComposition =>
      mergeRight(propOr({}, 'contentComposition', uiCatalog), pathOr({}, [content, 'contentComposition'], uiCatalog)),
  )

/**
 * Accessibility
 */

export const getAllowFontScaling = (defaultValue: boolean = true) =>
  createSelector(
    [getConfig],
    (config: Config): boolean => pathOr(defaultValue, ['accessibility', 'allowFontScaling'], config),
  )

export const getAnalytics = createSelector(
  [getConfig],
  (config: Object): ?Object => config.analytics,
)

export const isAnalyticsEnabled = createSelector(
  [getAnalytics],
  (analytics: Object): boolean => {
    const config = path(['video', 'mux-data'], analytics)

    return isEnabled(config)
  },
)

export const isAppAnalyticsEnabled = createSelector(
  [getAnalytics],
  (analytics: Object): boolean => {
    const config = path(['onboarding'], analytics)

    return isEnabled(config)
  },
)

export const getAppAnalyticsName = createSelector(
  [getAnalytics],
  (analytics: Object): ?string => path(['onboarding', 'name'], analytics),
)

export const getAppAnalyticsConfig = createSelector(
  [getAnalytics],
  (analytics: Object): ?Object => path(['onboarding', 'config'], analytics),
)

export const getAnalyticsKey = createSelector(
  [getAnalytics],
  (analytics: Object): ?string => path(['video', 'mux-data', 'config', 'env_key'], analytics),
)

export const getMobileOptions = createSelector(
  [getConfig],
  (config: Config): ?MobileOptions => pathOr({}, ['platform', 'mobile'], config),
)

export const getLogoutOnEmptySubscriptions = createSelector(
  [getMobileOptions],
  (config: Config): boolean => {
    const logoutOnEmptySubscriptions = propOr(LOGOUT_ON_EMPTY_SUBSCRIPTIONS, 'logoutOnEmptySubscriptions', config)

    return isEnabledOption(logoutOnEmptySubscriptions)
  },
)

export const getDemoUserAndNoSubscriptions = createSelector(
  [getMobileOptions],
  (config: Config): boolean => {
    const demoUserAndNoSubscriptions = pathOr(
      DEMO_USER_AND_NO_SUBSCRIPTIONS,
      ['notifications', 'demoUserAndNoSubscriptions'],
      config,
    )

    return isEnabledOption(demoUserAndNoSubscriptions)
  },
)

export const getNoActiveSubscriptions = createSelector(
  [getMobileOptions],
  (config: Config): boolean => {
    const noActiveSubscriptions = pathOr(NO_ACTIVE_SUBSCRIPTION, ['notifications', 'noActiveSubscriptions'], config)

    return isEnabledOption(noActiveSubscriptions)
  },
)

export const getTermsUrl = createSelector(
  [getConfig],
  (config: Config) => path(['site', 'terms', 'url'], config),
)

export const getPrivacyUrl = createSelector(
  [getConfig],
  (config: Config) => path(['site', 'privacy', 'url'], config),
)

export const isDeactivationEnable = createSelector(
  [getConfigUI],
  (ui: UI): boolean => path(['customer', 'remove', 'enabled'], ui),
)
