// @flow
import { createSelector } from 'reselect'
import { prop, pathOr } from 'ramda'

import { DEFAULT_CACHE } from '@alphaott/app-config'

import type { Config, Cache } from '@alphaott/api-client/types/config'
import type { AuthStore } from '@alphaott/app-auth/types'
import type { MainStore } from '../../types'

type Store = MainStore & AuthStore

const getConfig = (state: Store): Config => state.config.data

export const getCache = createSelector(
  [getConfig],
  (config: Config): ?Cache => prop('cache', config),
)

export const getCacheAppInit = createSelector(
  [getCache],
  (cache: ?Cache): ?number => pathOr(DEFAULT_CACHE.account, ['ttl', 'app'], cache),
)

export const getCacheAccount = createSelector(
  [getCache],
  (cache: ?Cache): ?number => pathOr(DEFAULT_CACHE.account, ['ttl', 'account'], cache),
)

export const getCacheGroups = createSelector(
  [getCache],
  (cache: ?Cache): ?number => pathOr(DEFAULT_CACHE.account, ['ttl', 'groups'], cache),
)

export const getCacheChannels = createSelector(
  [getCache],
  (cache: ?Cache): ?number => pathOr(DEFAULT_CACHE.account, ['ttl', 'channels'], cache),
)

export const getCacheVOD = createSelector(
  [getCache],
  (cache: ?Cache): ?number => pathOr(DEFAULT_CACHE.account, ['ttl', 'vod'], cache),
)

export const getCacheSources = createSelector(
  [getCache],
  (cache: ?Cache): ?number => pathOr(DEFAULT_CACHE.account, ['ttl', 'soucses'], cache),
)

export const getCacheConfig = createSelector(
  [getCache],
  (cache: ?Cache): ?number => pathOr(DEFAULT_CACHE.account, ['ttl', 'config'], cache),
)
