// @flow

import { CHANGE_PASSWORD_CUSTOMER_TYPE } from '../../actions/appCustomer'

import type { CustomerState } from '.'

const passwordReducer = {
  [CHANGE_PASSWORD_CUSTOMER_TYPE]: {
    LOADING: (state: CustomerState): CustomerState => ({
      ...state,
      errors: [],
      isLoading: true,
    }),

    SUCCESS: (state: CustomerState): CustomerState => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      errors: [],
    }),

    ERROR: (state: CustomerState): CustomerState => ({
      ...state,
      isLoading: false,
    }),
  },
}
export { passwordReducer }
