// @flow
import { isNil, either, isEmpty } from 'ramda'

import { makeGetRequest } from '../utils'

import type { ResponseAPI } from '../types/api'
import type { Language, LanguageType } from '../types/languages'

const isNotExist = either(isNil, isEmpty)

// eslint-disable-next-line no-confusing-arrow
const createPath = (url: string, languageType?: LanguageType): string =>
  isNotExist(languageType) ? url : `${url}?${languageType || ''}`

export const getMoviesLanguages = (
  domain: string,
  serviceId: string,
  languageType?: LanguageType,
): Promise<ResponseAPI<Language>> =>
  makeGetRequest(createPath(`${domain}/client/api/servicespec/${serviceId}/movie/languages`, languageType))
