// @flow
import { schema } from 'normalizr'
import { map, prop, reject, isEmpty } from 'ramda'

import type { LanguageNormalize } from '@alphaott/common-types/normalized'

import { defaultToEmptyString } from '../../help'

const idAttribute = '_id'

export const languagesSchema = new schema.Entity('languages', {}, { idAttribute })
export const languagesListSchema = new schema.Array(languagesSchema)

export const prepareLanguagesById = (arrIds: Array<string>, arrValues: LanguageNormalize): Array<string> =>
  reject(isEmpty, map(id => defaultToEmptyString(prop('name', arrValues[id])))(arrIds))

export default languagesListSchema
