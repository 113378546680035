// @flow
/* eslint-disable complexity, no-underscore-dangle */

import * as Sentry from '@sentry/browser'
import { equals, partial } from 'ramda'
import { stringify } from 'qs'

import makeResponse from './makeResponse'
import makeHeaders from './makeHeaders'

import type { Options, ResponseAPI } from '../types/api'

const POST = 'POST'
const GET = 'GET'
const PATCH = 'PATCH'
const DELETE = 'DELETE'
const PUT = 'PUT'

const isGET = equals(GET)

export const isAllowBodyMethod = (method: string) =>
  equals(method, POST) || equals(method, PATCH) || equals(method, PUT)

// eslint-disable-next-line no-confusing-arrow
export const makeUrl = (method: string, url: string, data?: any, opts?: Object = { addQueryPrefix: true }): string =>
  isGET(method) && data ? `${url}${stringify(data, opts)}` : url

export const makeRequestOptions = (method: string = GET, { headers, data }: Options = {}): Object => {
  const options = {
    method,
    headers: makeHeaders(headers),
  }

  if (isAllowBodyMethod(method) && data) {
    const body = JSON.stringify(data)
    return {
      ...options,
      body,
    }
  }

  return options
}

export const makeRequest = async (
  method: string,
  url: string,
  { headers, data }: Options = {},
): Promise<ResponseAPI<any>> => {
  const options = makeRequestOptions(method, { headers, data })

  try {
    const response = await fetch(makeUrl(method, url, data), options)

    try {
      return await makeResponse(response)
    } catch (err) {
      return Promise.reject(err)
    }
  } catch (err) {
    const error = {
      code: 'NetworkError',
      message: 'Network Error. Please check your network connection and try again.',
    }

    Sentry.captureException(err, {
      extra: error,
    })

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      ok: false,
      status: -1,
      error,
    })
  }
}

export const prepareGetParam = (data: Object): string => stringify(data)

// ToDo: remove workarount for flowtype & curring
export const makeGetRequest = (partial(makeRequest, [GET]): (string, ?Options) => Promise<ResponseAPI<any>>)
export const makePostRequest = (partial(makeRequest, [POST]): (string, ?Options) => Promise<ResponseAPI<any>>)
export const makePatchRequest = (partial(makeRequest, [PATCH]): (string, ?Options) => Promise<ResponseAPI<any>>)
export const makeDeleteRequest = (partial(makeRequest, [DELETE]): (string, ?Options) => Promise<ResponseAPI<any>>)
export const makePutRequest = (partial(makeRequest, [PUT]): (string, ?Options) => Promise<ResponseAPI<any>>)
