// @flow

import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

import { getBackgroundColor, getTextColorPrimary, getColorPrimary } from '@alphaott/smart-tv-themes'
import { PageView as PageViewComponent, Button } from '@alphaott/smart-tv-components'

export const PageView = styled(PageViewComponent)`
  height: 100vh;
  width: 100vw;
  background-color: ${getBackgroundColor};
  flex-direction: column;
  padding: 0 107rem 107rem 107rem;
  box-sizing: border-box;
`

export const Row = styled(Flex)`
  display: inline-flex;
  width: 100%;
  margin-top: 33rem;
  justify-content: space-between;
`
export const LeftColumn = styled(Box)`
  width: 1200rem;
`

export const RightColumn = styled(Box)`
  width: 400rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const Header = styled.div`
  padding: 64rem 0 64rem 0;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

export const Title = styled.div`
  font-weight: 300;
  font-size: 66rem;
  line-height: 79rem;
  color: ${getTextColorPrimary};
  opacity: 0.87;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Description = styled.div`
  font-weight: 400;
  font-size: 36rem;
  line-height: 60rem;
  letter-spacing: 1rem;
  margin-top: 15rem;
  color: ${getTextColorPrimary};
`

export const Logo = styled.img`
  height: ${({ height }) => height || 52}rem;
`

export const QRHeading = styled.div`
  font-weight: 300;
  font-size: 38rem;
  line-height: 46rem;
  color: rgba(255, 255, 255, 0.87);
  margin-top: 27rem;
  width: 370rem;
  text-align: center;
  overflow: hidden;
`

export const QRCodeContainer = styled.div`
  background-color: white;
  padding: 10rem;
  margin-top: 20rem;
`

export const StyledButton = styled(Button)``

export const ButtonSeparator = styled.div`
  width: 24rem;
  display: inline-block;
`

export const LinkText = styled.div`
  font-weight: bold;
  font-size: 38rem;
  line-height: 46rem;
  color: ${getColorPrimary};
  margin-top: 24rem;
`

export const StyledIcon = styled.div`
  width: 70rem;
  height: 70rem;
  margin-right: 16rem;
`
