// @flow
import { LARGE_VIEWPORT, MEDIUM_VIEWPORT, SMALL_VIEWPORT } from '@alphaott/app-config'
import { rgba } from 'polished'

import { colorPalette } from '../colorPalettes'
import type { ColorType } from '../colorPalettes'

export const softvColors: ColorType = {
  ...colorPalette,
  grayText: '#B0B0B0',
  grayLabel: '#717171',
  grayBorder: 'rgba(255, 255, 255, 0.38)',
  primary: '#0084D4',
  secondary: '#0084D4',
}

export const darkTheme = {
  colors: softvColors,
  background: {
    primary: '#181818',
    secondary: 'transparent',
    highlight: softvColors.dark,
    select: '#2e2e2e',
    footerTop: '#333',
  },
  text: {
    primary: softvColors.primaryGray,
    secondary: softvColors.secondaryGray,
    disabled: softvColors.grayText,
    link: softvColors.secondaryGray,
    hint: softvColors.white,
    highlight: softvColors.secondary,
    gray: softvColors.primaryGray,
    seeAll: softvColors.secondaryGray,
    primaryContrast: '#ffffff',
  },
  border: {
    primary: softvColors.primaryGray,
    gray: softvColors.secondaryGray,
  },
  filter: {
    primary: softvColors.primaryGray,
    secondary: softvColors.secondaryGray,
    disabled: softvColors.secondaryGray,
    highlight: '#111111',
  },
  tabs: {
    bg: '#181818',
    containerBg: softvColors.dark,
    color: softvColors.white,
    border: softvColors.primary,
    scrollbarThumb: '#bdbdbd',
    programTitle: softvColors.white,
  },
  programGuide: {
    programBox: {
      background: {
        main: '#2e2e2e',
        hover: softvColors.dark,
        selected: softvColors.dark,
        progress: rgba(softvColors.primary, 0.3),
        past: rgba(softvColors.primary, 0.3),
        pastSelected: softvColors.dark,
        pastHover: softvColors.dark,
        empty: '#555555',
        emptyProgress: rgba(softvColors.primary, 0.1),
      },
      text: {
        empty: softvColors.secondaryGray,
      },
    },
    programGuidePictureOverlay: {
      horizontal:
        // eslint-disable-next-line max-len
        `linear-gradient(90deg, #181818 0%,${rgba('#181818', 0.97)} 13.03%,${rgba('#181818', 0.69)} 43.24%,${rgba(
          '#181818',
          0,
        )} 100%);`,
      vertical: `linear-gradient(0, #181818 0%,${rgba('#181818', 0.73)} 34.97%,${rgba('#181818', 0)} 100%);`,
    },
  },
  card: {
    title: softvColors.white,
    subTitle: softvColors.secondaryGray,
    bg: softvColors.grayText,
    number: '#DFDFDF',
    boxShadow: '#2e2e2e',
    loaderBackgroundImage: 'linear-gradient(to right, #222 0%, #111 20%, #222 40%, #222 100%)',
  },
  dimension: {
    fullBGImage: true,
    fullBGImageSize: 'cover',
    signLogoSize: 221,
  },
  size: 'default',
  breakpoints: [`${SMALL_VIEWPORT}px`, `${MEDIUM_VIEWPORT}px`, `${LARGE_VIEWPORT}px`],
  signIn: {
    clipPath: false,
  },
}
