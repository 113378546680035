// @flow
import {
  pipe,
  prop,
  toLower,
  includes,
  map,
  split,
  last,
  head,
  isEmpty,
  complement,
  without,
  and,
  pathOr,
  equals,
  cond,
  or,
  T,
} from 'ramda'
import { createSelector } from 'reselect'

import type { AuthMethods } from '@alphaott/api-client/types/authMethods'
import type { AuthOtp } from '@alphaott/api-client/types/authOtp'
import { isNotNil } from '@alphaott/common-utils/utils/help'

import { getParam } from '@alphaott/common-utils/utils/urlSearchParams'
import { parseBoolean } from '@alphaott/common-utils/utils/parser'
import { BILLING_SIGN_UP_PLAN_STEP_ENABLED } from '@alphaott/app-config'
import {
  isVoucherEnabled,
  isBillingEnabled,
  isBillingSignupPlanStepEnabled,
  getRoutingPathname,
  getRoutingSearch,
  selectFirebaseOptions,
  getGuestAccessTrialConfig,
  isSignupEnabled,
  getUISignupOrder,
} from '@alphaott/app-main/selectors'
import { isEnabled } from '@alphaott/app-main/models/utils'

import type { FirebaseOptions } from '@alphaott/common-types'
import type { GuestAccessTrial } from '@alphaott/api-client/types/config'

import { mapRouteWithStep, shouldShowPlanStep } from './utils'
import {
  URL_PLAN_ID_KEY,
  URL_BILLING_STEP_FLAG_KEY,
  PASSWORD,
  VOUCHER,
  FIREBASE,
  QR_CODE,
  GUEST,
  OTP_EMAIL,
  OTP_SMS,
} from './constants'

import type { AuthStore } from './types/AuthStore'
import type { LoginState } from './reducers/login'

export const getAuthMethods = (state: AuthStore): AuthMethods => state.authMethods.data
export const getIsLoadingAuthMethods = (state: AuthStore): boolean => state.authMethods.isLoading
export const selectIsAuthInProgress = (state: AuthStore): boolean => state.login.isLoading
export const selectIsAuthSuccess = (state: AuthStore): boolean => state.login.isSuccess
export const selectAuthErrors = (state: AuthStore): LoginState => state.login.errors
export const getAuthOtpState = (state: AuthStore): AuthOtp => state.authOtp.data

export const getPreparedAuthMethods = createSelector(
  [getAuthMethods, isVoucherEnabled],
  (authMethodsStore: AuthMethods, isVoucherAuthMethod: boolean): Array<string> => {
    const { authMethods } = authMethodsStore
    return isVoucherAuthMethod ? authMethods : without([VOUCHER], authMethods)
  },
)

const hasMethod = (method: string) =>
  createSelector(
    [getAuthMethods],
    (authMethods: AuthMethods): boolean => {
      const methods = pipe(
        prop('authMethods'),
        map(toLower),
      )(authMethods)
      return includes(toLower(method), methods)
    },
  )

export const hasPasswordMethod = hasMethod(PASSWORD)

export const hasVoucherMethod = hasMethod(VOUCHER)

export const hasGuestMethod = hasMethod(GUEST)

export const hasFirebaseMethod = createSelector(
  [hasMethod(FIREBASE), selectFirebaseOptions],
  (isFirebaseMethod: boolean, firebaseOptions: ?FirebaseOptions): boolean =>
    and(isFirebaseMethod, isNotNil(firebaseOptions)),
)

export const hasQRCodeMethod = hasMethod(QR_CODE)

export const isOTPEmailMethod = hasMethod(OTP_EMAIL)

export const isOTPSmsMethod = hasMethod(OTP_SMS)

export const hasOTPMethod = createSelector(
  [isOTPEmailMethod, isOTPSmsMethod],
  (isEmailMethod: boolean, isSmsMethod: boolean): boolean => or(isEmailMethod, isSmsMethod),
)

export const getGuestAccessTrialEnabled = createSelector(
  [hasGuestMethod, getGuestAccessTrialConfig],
  (isGuestAccessEnabled: boolean, guestAccessTrialConfig: GuestAccessTrial): boolean => {
    const isGuestAccessTrialEnabled = isEnabled(guestAccessTrialConfig)
    return and(isGuestAccessEnabled, isGuestAccessTrialEnabled)
  },
)

export const parseRouteForStep = createSelector(
  [getRoutingPathname],
  (pathname: string): number =>
    pipe(
      split('/'),
      last,
      mapRouteWithStep,
    )(pathname),
)

export const isPlanStepEnabledFromUrl = createSelector(
  [getRoutingSearch],
  (search: string) => {
    const params = getParam(search, URL_BILLING_STEP_FLAG_KEY)

    if (isEmpty(params)) return BILLING_SIGN_UP_PLAN_STEP_ENABLED

    return pipe(
      head,
      parseBoolean,
    )(params)
  },
)

export const getPlanIdFromUrl = createSelector(
  [getRoutingSearch],
  (search: string) =>
    pipe(
      val => getParam(val, URL_PLAN_ID_KEY),
      head,
    )(search),
)

export const shouldShowBillingStep = createSelector(
  [isBillingEnabled, isBillingSignupPlanStepEnabled, isPlanStepEnabledFromUrl],
  // eslint-disable-next-line no-shadow
  (isBillingEnabled: boolean, isPlanStepEnabled: boolean, isPlanStepEnabledFromUrl: boolean) =>
    shouldShowPlanStep({
      isBillingEnabled,
      isPlanStepEnabled,
      isPlanStepEnabledFromUrl,
    }),
)

export const skipBillingStep = complement(shouldShowBillingStep)

export const getAuthRedirectPath = createSelector(
  [getPreparedAuthMethods, isSignupEnabled, getUISignupOrder],
  (preparedAuthMethods, isSignUp, signupOrder) => {
    const firstAuthMethod = pathOr(PASSWORD, [0], preparedAuthMethods)

    const isSignupFirst = isSignUp && equals(signupOrder, 1)

    const authOrder = cond([
      [equals(PASSWORD), () => '/auth/login'],
      [equals(VOUCHER), () => '/auth/login/voucher'],
      [equals(OTP_EMAIL), () => '/auth/login/otp'],
      [equals(OTP_SMS), () => '/auth/login/otp'],
      [T, () => '/auth/login'],
    ])(firstAuthMethod)

    return isSignupFirst ? '/auth/signup' : authOrder
  },
)

export const getAuthRedirectPathAfterGuestLoginError = createSelector(
  [isSignupEnabled, getAuthRedirectPath],
  (isSignUp, preferredAuthRedirectPath) => (isSignUp ? '/auth/signup' : preferredAuthRedirectPath),
)
