// @flow
import { divide, multiply, subtract, or, equals, gt } from 'ramda'

import { setPluralForm } from '../pluralForm'

// eslint-disable-next-line complexity
export const toHrsMin = (minuteCount: number): string => {
  if (minuteCount <= 0) return '0 sec'
  const hours = Math.floor(divide(minuteCount, 60))
  const minutes = Math.floor(subtract(minuteCount, multiply(hours, 60)))
  let duration = ''
  if (gt(hours, 0)) {
    duration += `${hours} ${setPluralForm(hours, 'hr')} `
  }
  if (or(gt(minutes, 0), equals(hours, 0))) {
    duration += `${minutes} ${setPluralForm(minutes, 'min')}`
  }
  return duration
}

export default toHrsMin
