// @flow
import { makeGetRequest } from '../utils'

import type { ResponseAPI } from '../types/api'
import type { RadioChannelMediaStreams } from '../types/radioChannels'
import type { Options } from '../utils/getStreamEncryptHeader'

import { getStreamHeader } from '../utils/getStreamEncryptHeader'

type SourceOptions = Options & { id: string }

export const getRadioChannelSources = (
  domain: string,
  radioId: string,
  options: SourceOptions,
): Promise<ResponseAPI<RadioChannelMediaStreams>> => {
  const headers = getStreamHeader(options)

  return makeGetRequest(`${domain}/client/api/v3/radio/channel/${radioId}/sources`, {
    headers,
  })
}

export default getRadioChannelSources
