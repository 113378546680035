// @flow
import typeToReducer from 'type-to-reducer'

import { preparePromos } from '@alphaott/common-utils/utils/schemas/promos'
import { FETCH_PROMOS_TYPE } from '../actions'

export type PromoState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: any,
}

const initialState: PromoState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
}

const promo = typeToReducer(
  {
    [FETCH_PROMOS_TYPE]: {
      LOADING: (state): PromoState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): PromoState => {
        const {
          meta: { contentApi, themeName, themeVariation },
          payload,
        } = action
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: preparePromos(contentApi, payload.data, themeName, themeVariation),
        }
      },

      ERROR: (state, action): PromoState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
  },
  initialState,
)

export { promo }
