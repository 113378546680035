// @flow

import { useEffect, useState, useCallback } from 'react'
import format from 'date-fns/format'

export const useCurrentTime = () => {
  const getCurrentTime = useCallback(() => format(new Date(), 'hh:mm a'), [])

  const [currentTime, setCurrentTime] = useState(getCurrentTime())

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(getCurrentTime())
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [getCurrentTime])

  return currentTime
}

export default useCurrentTime
