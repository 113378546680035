// @flow
import { divide } from 'ramda'

import { toHrsMin } from '../hrsToMinutes'

// eslint-disable-next-line complexity
export const toHrsSec = (secondsCount: number): string => {
  if (secondsCount <= 0) return '0 sec'
  const hours = Math.floor(divide(secondsCount, 60))

  return toHrsMin(hours)
}

export default toHrsSec
