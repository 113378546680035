// @flow
import { find, propEq, propOr, reject, and, or } from 'ramda'

import { getPersonalList, putPersonalList } from '@alphaott/api-client'
import { getCustomerId, getServiceRadioIds } from '@alphaott/app-core/models/selectors'
import { logoutRequestWrapper } from '@alphaott/app-main/utils'
import { defaultToEmptyObject, isNotEmpty } from '@alphaott/common-utils'

import { UPDATE_CHANNEL_PROGRESS_INTERVAL } from '@alphaott/app-config'

import { fetchRadioChannels } from '../radioChannels'
import { getRadioRecentPlayed } from '../../selectors'

export const UPDATE_RECENT_RADIO_CHANNEL = 'radioChannels/UPDATE_RECENT_RADIO_CHANNEL'
export const GET_RECENT_RADIO_CHANNELS = 'radioChannels/GET_RECENT_RADIO_CHANNELS'

// eslint-disable-next-line consistent-return
export const fetchRadioChannelById = (dispatch: Function, getState: Function) => {
  const state = getState()
  const serviceRadioIds = getServiceRadioIds(state)
  const recentList = getRadioRecentPlayed(state)
  const uniqRecentList = reject(({ id }) => find(propEq('_id', id), recentList), state.radioChannels.entities)

  // Todo: Change this when will add endpoint for getting channel by id
  if (and(isNotEmpty(serviceRadioIds), isNotEmpty(uniqRecentList))) {
    return dispatch(fetchRadioChannels(serviceRadioIds, true))
  }
}

export const updateRecentPlayedRadioChannel = (channelId: string, watchTime: number) => async (
  dispatch: Function,
  getState: Function,
) => {
  const customerId = getCustomerId(getState())
  const channels = getRadioRecentPlayed(getState())

  const currentChannel = defaultToEmptyObject(find(propEq('id', channelId))(channels))
  const total = propOr(0, 'total', currentChannel)
  const preparedTotalWatchTime = total + or(watchTime, UPDATE_CHANNEL_PROGRESS_INTERVAL)

  const data = {
    total: preparedTotalWatchTime,
  }

  // eslint-disable-next-line no-useless-catch
  try {
    await dispatch({
      type: UPDATE_RECENT_RADIO_CHANNEL,
      payload: logoutRequestWrapper(putPersonalList)(customerId, 'radio', 'history', channelId, data)(
        dispatch,
        getState,
      ),
    })
    dispatch(fetchRadioChannelById)
  } catch (err) {
    throw err
  }
}

export const fetchRecentPlayedRadioChannels = async (dispatch: Function, getState: Function) => {
  const customerId = getCustomerId(getState())

  if (customerId) {
    await dispatch({
      type: GET_RECENT_RADIO_CHANNELS,
      payload: logoutRequestWrapper(getPersonalList)(customerId, 'radio', 'history')(dispatch, getState),
    })

    dispatch(fetchRadioChannelById)
  }
}
