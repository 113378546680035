// @flow

import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Loader } from '@alphaott/smart-tv-components'

import { ErrorAlert } from '../../ErrorAlert'
import { Container, CentralContainer, ErrorAlertContainer } from './BaseControls.style'
import { usePlayerContext } from '../../../store'

type BaseControlsInnerProps = {
  className?: string,
  isLoading?: boolean,
  errorMessage?: string,
}

// eslint-disable-next-line complexity
const BaseControlsInnerPure = ({ className, isLoading, errorMessage }: BaseControlsInnerProps) => {
  const { t } = useTranslation()

  return (
    <Container className={className}>
      {isLoading && (
        <CentralContainer>
          <Loader />
        </CentralContainer>
      )}

      {errorMessage && (
        <ErrorAlertContainer>
          <ErrorAlert title={t('mwstv_tv_playback_error')} description={errorMessage} />
        </ErrorAlertContainer>
      )}
    </Container>
  )
}

const BaseControlsInner = memo(BaseControlsInnerPure)

type BaseControlsProps = {
  className?: string,
}

const BaseControlsPure = ({ className }: BaseControlsProps) => {
  const [state] = usePlayerContext()

  return (
    <BaseControlsInner
      className={className}
      isLoading={state.loading}
      errorMessage={state.error?.message}
    />
  )
}

export const BaseControls = memo(BaseControlsPure)

export default BaseControls
