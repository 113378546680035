// @flow

import React, { memo, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { and, equals, startsWith } from 'ramda'

import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'

import { Container, Indicator, Icon, Label } from './SubMenu.styles'

type SubMenuItemProps = {
  className?: string,
  focusKey?: string,
  icon: any,
  label: string,
  route?: String,
  isShowLabel: boolean,
  onClick: Function,
}

const SubMenuItemPure = ({
  className,
  focusKey,
  icon,
  label,
  route = '',
  isShowLabel,
  onClick,
}: SubMenuItemProps) => {
  const { ref, focused, focusSelf } = useFocusable({ focusKey, onEnterPress: onClick })
  const { handleMouseOver } = useMouseOver(focusSelf)
  const { pathname } = useLocation()

  // eslint-disable-next-line complexity
  const isCurrent = useMemo(() => {
    if (equals(route, '/settings')) return startsWith(route, pathname)
    if (equals(route, '/search')) return startsWith(route, pathname)
    if (equals(route, '/movies')) return startsWith(route, pathname)
    if (equals(route, '/tvshows')) return startsWith(route, pathname)
    if (equals(route, '/channels')) return startsWith(route, pathname)
    if (equals(route, '/radio-channels')) return startsWith(route, pathname)
    if (equals(route, '/my-list')) return startsWith(route, pathname)
    return equals(route, pathname)
  }, [pathname, route])

  const isShowCurrentIndicator = useMemo(
    () => and(isShowLabel, isCurrent),
    [isCurrent, isShowLabel],
  )

  useEffect(() => {
    and(and(isCurrent, isShowLabel), focusSelf) && focusSelf()
  }, [isCurrent, focusSelf, isShowLabel])

  return (
    <Container
      className={className}
      ref={ref}
      isShowLabel={isShowLabel}
      onClick={onClick}
      onMouseOver={handleMouseOver}
    >
      {isShowCurrentIndicator && <Indicator />}

      <Icon isCurrent={isCurrent} isShowLabel={isShowLabel} focused={focused}>
        {icon}
      </Icon>

      {isShowLabel && <Label focused={focused}>{label}</Label>}
    </Container>
  )
}

export const SubMenuItem = memo<SubMenuItemProps>(SubMenuItemPure)

export default SubMenuItem
