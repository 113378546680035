// @flow

import React, { memo, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { prop, isEmpty } from 'ramda'

import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'

import {
  Container,
  Header,
  Title,
  Content,
  SmallText,
  List,
  LogoutButton,
} from './WhoIsWatchingView.style'

export type WhoIsWatchingViewProps = {
  logoSrc: string,
  logoHeight: number,
  profiles: Object[],
  onAddProfile?: Function,
  onChangeProfile?: Function,
  onEditProfile?: Function,
  onLogout: Function,
}

const WhoIsWatchingViewPure = ({
  logoSrc,
  logoHeight,
  profiles,
  onAddProfile,
  onChangeProfile,
  onEditProfile,
  onLogout,
}) => {
  const { ref, setFocus } = useFocusable()
  const { t } = useTranslation()

  const preparedProfiles = useMemo(
    () => [...profiles, { id: 'add-new-profile-card', isAddNewProfileCard: true }],
    [profiles],
  )

  useEffect(() => {
    const firstElement = prop(0, preparedProfiles)
    const firstElementId = prop('id', firstElement)

    if (setFocus) setFocus(firstElementId)
  }, [setFocus]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container ref={ref}>
      <Header logoSrc={logoSrc} logoHeight={logoHeight} />
      <Content>
        <Title>{t('mwstv_who_s_watching')}</Title>
        <List
          profiles={preparedProfiles}
          onAddProfile={onAddProfile}
          onChangeProfile={onChangeProfile}
          onEditProfile={onEditProfile}
        />

        {isEmpty(profiles) && (
          <SmallText>{t('mwstv_add_your_first_profile_to_continue')}</SmallText>
        )}

        <LogoutButton onClick={onLogout} onEnterPress={onLogout}>
          {t('log_out')}
        </LogoutButton>
      </Content>
    </Container>
  )
}

export const WhoIsWatchingView = memo<WhoIsWatchingViewProps>(WhoIsWatchingViewPure)

export default WhoIsWatchingView
