// @flow

import React from 'react'
import { not } from 'ramda'
import { useTranslation } from 'react-i18next'

import { prepareTimezone } from '@alphaott/smart-tv-common'

import timezone from '../assets/timezone.svg'
import { TimezoneContainer, Icon, TimezoneText } from './ChannelCard.style'

type TimeZoneProps = {
  offset: number,
  focused: boolean,
}

export const TimeZone = ({ offset, focused }: TimeZoneProps) => {
  const { t } = useTranslation()

  return (
    <TimezoneContainer>
      {prepareTimezone(offset)}
      {focused && <TimezoneText>{t('hrs')}</TimezoneText>}
      {not(focused) && <Icon src={timezone} alt="timezone" />}
    </TimezoneContainer>
  )
}
