// @flow
import { omit, prop } from 'ramda'

type Store = any

const getContext = (state: Store): Object => ({
  brandConfig: prop('data', state.brandConfig),
  config: JSON.stringify(prop('data', state.config)),
  serviceProvider: JSON.stringify(prop('data', state.serviceProvider)),
})

const prepareToken = (state: Store): Object => omit(['access', 'refresh'], state.token)

/**
 *  getContext(state) => remove all data from each state
 *  prepareToken(state) => remove sensitive data
 */
export const transformState = (state: Store): Object => ({
  ...getContext(state),
  token: prepareToken(state),
})
