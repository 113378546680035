import styled from 'styled-components'

import { getColorPrimary, getColorPrimaryContrastText } from '@alphaott/smart-tv-themes'

import { PlayIconComponent } from '../assets'

const PLAY_ICON_SIZE = 32

// eslint-disable-next-line complexity
const getIconColor = (props) => {
  if (props.$isLive && !props.$focused && !props.$isSelected) {
    return getColorPrimary(props)
  }
  return getColorPrimaryContrastText(props)
}

export const PlayIcon = styled(PlayIconComponent)`
  width: ${PLAY_ICON_SIZE}rem;
  height: ${PLAY_ICON_SIZE}rem;
  fill: ${getIconColor};
`

export const LinkText = styled.div`
  font-weight: normal;
  font-size: 26rem;
  line-height: 30rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  text-transform: uppercase;
  span {
    padding-right: 12rem;
  }
`
