// @flow
import typeToReducer from 'type-to-reducer'
import { map } from 'ramda'
import { normalize } from 'normalizr'
import { defaultToEmptyObject } from '@alphaott/common-utils/utils/help'

import tvshowListSchema, { prepareTVShowImages } from '@alphaott/common-utils/utils/schemas/tvshows'
import { type TVShowNormalized } from '../tvshowFavorites'
import { FETCH_SUGGESTED_TVSHOW } from '../../actions'

export type SuggestedTVShowsState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +entities: TVShowNormalized,
}

const initialState: SuggestedTVShowsState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  entities: {},
}

const tvshowSuggesteds = typeToReducer(
  {
    [FETCH_SUGGESTED_TVSHOW]: {
      LOADING: (state): SuggestedTVShowsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): SuggestedTVShowsState => {
        const {
          payload: { data },
          meta,
        } = action
        const preparedData = map(response => prepareTVShowImages(response, meta.contentApi), data)
        const { entities } = normalize(preparedData, tvshowListSchema)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          entities: defaultToEmptyObject(entities.tvshows),
        }
      },

      ERROR: (state, action): SuggestedTVShowsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
  },
  initialState,
)

export { tvshowSuggesteds }
