// @flow

import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'

import { AddProfileCardContainer, Avatar, Name, AddNewProfileAvatar } from './ProfileCard.style'
import addIcon from '../assets/addIcon.svg'

export type AddProfileCardProps = {
  focusKey?: string,
  style?: Object,
  onFocus?: Function,
  onAddProfile?: Function,
}

const AddProfileCardPure = ({
  focusKey: parentFocusKey,
  style,
  onFocus,
  onAddProfile,
}: AddProfileCardProps) => {
  const { ref, focusKey, hasFocusedChild } = useFocusable({
    focusKey: parentFocusKey,
    trackChildren: true,
    onFocus,
    onEnterPress: onAddProfile,
  })
  const { t } = useTranslation()

  return (
    <FocusContext.Provider value={focusKey}>
      <AddProfileCardContainer ref={ref} style={style}>
        <Avatar
          image={addIcon}
          highlighted={hasFocusedChild}
          onClick={onAddProfile}
          imageStyles={AddNewProfileAvatar}
        />
        <Name highlighted={hasFocusedChild}>{t('mwstv_add_profile')}</Name>
      </AddProfileCardContainer>
    </FocusContext.Provider>
  )
}

export const AddProfileCard = memo<AddProfileCardProps>(AddProfileCardPure)

export default AddProfileCard
