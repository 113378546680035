// @flow

import styled from 'styled-components'
import { Box, Flex } from '@rebass/grid'

import { Button } from '@alphaott/smart-tv-components'
import {
  getGrey800,
  getGrey600,
  getColorSecondaryContrastText,
  getTextColorPrimary,
  getBackgroundColor,
} from '@alphaott/smart-tv-themes'

export const AVATAR_CARD_WIDTH = 200

export const Row = styled(Flex)`
  display: inline-flex;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  margin-top: 33rem;
`

export const RowBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  margin-top: 54rem;
`

export const EditHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50rem 0;
`

export const AvatarImage = styled.img`
  width: auto;
  height: 110%;
`

export const LeftColumn = styled(Box)`
  width: 200rem;
  margin-right: 60rem;
`

export const RightColumn = styled(Box)`
  width: 577rem;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
`

export const EditAvatar = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${AVATAR_CARD_WIDTH}rem;
  height: ${AVATAR_CARD_WIDTH}rem;
  margin-top: 0;
  background-color: ${getGrey600};
  border: solid 8rem ${getGrey800};
  border-radius: 50%;
  overflow: hidden;
  outline: none;
`

export const ButtonWrapper = styled.div`
  align-self: flex-end;
`

export const EditButton = styled(Button)`
  min-width: 577rem;
  min-height: 72rem;
  margin-top: 30rem;
  text-align: left;
  text-transform: none;
  font-size: 40rem;
  font-weight: 400;
`

export const EditFocusableCheckBox = styled(EditButton)`
  display: flex;
  align-items: center;
  background-color: transparent;
`

export const EditSmallButton = styled(Button)`
  min-width: 276rem;
  min-height: 54rem;
  margin-top: 15rem;
  text-align: left;
  text-transform: uppercase;
  font-size: 24rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.87);
`

export const ProfileContainer = styled(Flex)`
  flex-direction: column;
  min-height: 900rem;
  height: 100vh;
  width: 100vw;
  background-color: ${getBackgroundColor};
  padding: 0 107rem 107rem;
`

export const LargeText = styled.div`
  font-weight: 300;
  font-size: 42rem;
  line-height: 50rem;
  margin-top: 30rem;
  text-align: center;
`

export const AvatarNameLargeText = styled(LargeText)`
  color: ${getColorSecondaryContrastText};
`

export const SmallText = styled.div`
  font-weight: 400;
  font-size: 32rem;
  line-height: 38rem;
  margin-top: 60rem;
  color: ${getTextColorPrimary};
`

export const EditTitle = styled.div`
  font-weight: 300;
  font-size: 66rem;
  line-height: 79rem;
  margin-top: 0;
  color: ${getTextColorPrimary};
  opacity: 0.87;
`

export const EditSmallText = styled(SmallText)`
  margin-top: 15rem;
  opacity: 0.7;
`
