// @flow
import { makeGetRequest } from '../utils'

import type { ResponseAPI } from '../types/api'
import type { Movie } from '../types/movies'

export const getMovie = (domain: string, movieId: string): Promise<ResponseAPI<Movie>> =>
  makeGetRequest(`${domain}/client/api/vod/movie/${movieId}`)

export const getMoviesByIds = (domain: string, idList: string): Promise<ResponseAPI<Array<Movie>>> =>
  makeGetRequest(`${domain}/client/api/v2/vod/movie/${idList}`)
