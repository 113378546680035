// @flow

export const MENU_ITEMS = {
  SEARCH: 'SEARCH',
  HOME: 'HOME',
  LIVE_TV: 'LIVE_TV',
  TV_GUIDE: 'TV_GUIDE',
  MOVIES: 'MOVIES',
  TV_SHOWS: 'TV_SHOWS',
  RADIO: 'RADIO',
  APPS: 'APPS',
  RECORDINGS: 'RECORDINGS',
  MY_LIST: 'MY_LIST',
  ACCOUNT: 'ACCOUNT',
  SETTINGS: 'SETTINGS',
}
