/* eslint-disable jest/expect-expect */
/* eslint-disable jest/no-export */
/* eslint-disable jest/no-disabled-tests */
/* eslint-disable jest/valid-title */
// @flow
import { ifElse, split, inc, always, dec, equals, replace, test, pipe, anyPass } from 'ramda'

export const MAX_NUMBER = 9
export const MIN_NUMBER = 0

export const DIRECTION = {
  UP: 'up',
  DOWN: 'down',
}

export const isUpArrow = equals(DIRECTION.UP)
export const isDownArrow = equals(DIRECTION.DOWN)
export const isDownOrUpArrow = anyPass([isUpArrow, isDownArrow])

export const preparedCodeValues = ifElse(
  ({ value, inputNumber }) => equals(value.length, inputNumber),
  ({ value }) =>
    ifElse(test(/^\d+$/), split(''), pipe(replace(/\D/g, String(MIN_NUMBER)), split('')))(value),
  ({ inputNumber }) => Array.from({ length: inputNumber }, () => '0'),
)

export const incrementValue = ifElse(
  (value) => value < MAX_NUMBER,
  (value) => String(inc(value)),
  always(String(MIN_NUMBER)),
)

export const decrementValue = ifElse(
  (value) => value > MIN_NUMBER,
  (value) => String(dec(value)),
  always(String(MAX_NUMBER)),
)

export const SINGLE_CODE_INPUT_FOCUS_KEY = 'SINGLE_CODE_INPUT_FOCUS_KEY'
