// @flow

import { createSelector } from 'reselect'
import { pipe, curry, map, equals, reduce, concat, prop, find, propEq } from 'ramda'

import { isWithinRange, addSeconds, format } from 'date-fns'
import { VISIBLE_TIME_FORMAT } from '@alphaott/app-programs/const'

import type { PreparedPrograms } from '@alphaott/app-programs/models/tvGrid/selectors'
import type { ProgramSection, ProgramItem } from '@alphaott/app-programs/types'
import type { Programs } from '@alphaott/api-client/types/programs'
import type { ProgramsState } from './programsReducer'

type Options = {
  now: Date,
  hasCatchUp?: boolean,
  catchUpWindow?: number,
}

const MONTH_FORMAT = 'MMMM'
const DAY_OF_MONTH_FORMAT = 'D'
const DAY_OF_WEEK_FORMAT = 'dddd'

export const prepareProgramItem = curry(
  (options, item: Programs): ProgramItem =>
    pipe(
      // Set `isPlayable` flag
      i => ({
        ...i,
        isPlayable:
          options.hasCatchUp && isWithinRange(i.start, addSeconds(options.now, -options.catchUpWindow), options.now),
      }),
      // Set `isLive` flag
      i => ({ ...i, isLive: isWithinRange(options.now, i.start, i.stop) }),
      // Set `isCurrent` flag
      i => ({ ...i, isCurrent: equals(options.id, i._id) }),
      // Set `time`, `month`, `dayOfWeek`, `dayOfMonth` fields
      i => ({
        ...i,
        time: `${format(i.start, VISIBLE_TIME_FORMAT)} - ${format(i.stop, VISIBLE_TIME_FORMAT)}`,
        month: format(i.start, MONTH_FORMAT),
        dayOfWeek: format(i.start, DAY_OF_WEEK_FORMAT),
        dayOfMonth: format(i.start, DAY_OF_MONTH_FORMAT),
      }),
    )(item),
)

const prepareDaySection = (options: Options, data: Array<PreparedPrograms>): Array<ProgramSection> =>
  map(
    ({ date, items }): ProgramSection => ({
      title: date,
      data: map(prepareProgramItem(options), items),
      date,
    }),
    data,
  )

export const getRawPrograms = (state: ProgramsState) => state.data

export const getPrograms = (options: Options) =>
  createSelector(
    [getRawPrograms],
    (rawPrograms): Array<ProgramSection> => prepareDaySection(options, rawPrograms),
  )

export const getLiveProgram = (options: Options) =>
  createSelector(
    [getPrograms(options)],
    (programs = []) => {
      const programList = reduce((prev, item) => concat(prev, prop('data', item)), [], programs)
      return find(propEq('isLive', true), programList) || {}
    },
  )

export default getPrograms
