// @flow

import { createSelector } from 'reselect'
import { propOr } from 'ramda'

import type { PlayerStore } from '../types'
import type { PlayerProgressState } from '../reducers'

export const getPlayerProgressState = (state: PlayerStore): PlayerProgressState =>
  state.playerProgress

export const getContentDuration = createSelector(
  [getPlayerProgressState],
  (playerProgress: PlayerProgressState): number => propOr(1, 'duration', playerProgress),
)

export const getCurrentTime = createSelector(
  [getPlayerProgressState],
  (playerProgress: PlayerProgressState): number => propOr(0, 'currentTime', playerProgress),
)
