// @flow
import { createSelector } from 'reselect'
import { or } from 'ramda'

import { isIncludesRestrictedTag } from '../../utils'

import type { SettingsStore } from '../types'

export const getParentalControlLevel = (state: SettingsStore): number => state.settings.parental.parentalControlLevel
export const getEnableParentalControls = (state: SettingsStore): boolean =>
  state.settings.parental.enableParentalControls
export const getPinCode = (state: SettingsStore): string => state.settings.parental.pinCode

export const getRestrictedLevel = createSelector(
  [getEnableParentalControls, getParentalControlLevel],
  // eslint-disable-next-line consistent-return
  (enableParentalControls: boolean, parentalControlLevel: number): ?number => {
    if (enableParentalControls) {
      return parentalControlLevel
    }
  },
)

export const isRestrictedContentByPGRating = (pgRating: number) =>
  createSelector(
    [getRestrictedLevel],
    (restrictedLevel: number): boolean => Boolean(restrictedLevel && restrictedLevel * 100 < pgRating),
  )

export const isRestrictedContent = (pgRating: number, tags: string[] = []) =>
  createSelector(
    [isRestrictedContentByPGRating(pgRating)],
    (isRestrictedByRating: boolean): boolean => {
      const isRestrictedByTag = isIncludesRestrictedTag(tags)

      return or(isRestrictedByRating, isRestrictedByTag)
    },
  )
