// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const CentralContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const ErrorAlertContainer = styled(Flex)`
  justify-content: center;
  position: absolute;
  top: 40rem;
  left: 0;
  width: 100%;
  z-index: 10;
`
