// @flow
/* eslint-disable react/display-name */
import React, { memo, useCallback } from 'react'

import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'

import format from 'date-fns/format'

import { DateContainer, DateElement, WeekDay } from './DatePickerItem.style'

type DatePickerItemProps = {
  focusKey?: string,
  date: Date,
  isSelected: boolean,
  onFocus?: Function,
  onPress: Function,
  style?: any,
}

// $FlowFixMe
const DatePickerItemPure = ({
  focusKey,
  date,
  isSelected,
  style,
  onFocus,
  onPress,
}: DatePickerItemProps) => {
  const handleFocus = useCallback((event) => onFocus(event, { date }), [date, onFocus])
  const { ref, focused } = useFocusable({ focusKey, onFocus: handleFocus, onEnterPress: onPress })

  const preparedWeekday = format(date, 'ddd')
  const preparedDate = format(date, 'DD/MM/YYYY')

  return (
    <DateContainer
      ref={ref}
      style={style}
      isSelected={isSelected}
      focused={focused}
      onClick={onPress}
    >
      <WeekDay>{preparedWeekday}</WeekDay>
      <DateElement>{preparedDate}</DateElement>
    </DateContainer>
  )
}

export const DatePickerItem = memo<DatePickerItemProps>(DatePickerItemPure)

export default DatePickerItem
