// @flow

import React, { memo, useMemo, useEffect } from 'react'
import QRCodeGenerator from 'qrcode.react'
import { useTranslation } from 'react-i18next'

import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'

import {
  StyledModal,
  ModalContainer,
  Title,
  Divider,
  TextTitle,
  TextMessage,
  StyledButton,
  LinkText,
  Row,
  LeftColumn,
  RightColumn,
  QRHeading,
  QRCodeContainer,
} from './elements'
import { CLOSE_BUTTON_FOCUS_KEY } from '../const'

export type WelcomeModalProps = {
  onCloseModal: () => void,
  name: string,
  link: string,
}

const QRCodeStyles = { width: '200rem', height: '200rem' }

const WelcomeModalPure = ({ name, onCloseModal, link }: WelcomeModalProps) => {
  const { ref, setFocus } = useFocusable()
  const { t } = useTranslation()

  const uri = useMemo(() => link || '', [link])

  useEffect(() => {
    if (setFocus) setFocus(CLOSE_BUTTON_FOCUS_KEY)
  }, [setFocus])

  return (
    <StyledModal ref={ref}>
      <ModalContainer>
        <Title>{t('welcome_to_name', { name })}</Title>
        <Divider />
        <Row>
          <LeftColumn>
            <TextTitle>{t('thank_you_for_signing_up')}</TextTitle>
            <TextMessage>{t('mwstv_notifications_welcome_message')}</TextMessage>
            <LinkText>{link}</LinkText>
            <TextMessage>{t('feel_free_to_keep_enjoying_your_trial_subscription')}</TextMessage>
          </LeftColumn>
          {uri && (
            <RightColumn>
              <QRHeading>{t('mwstv_notifications_qr_code_heading')}</QRHeading>
              <QRCodeContainer>
                <QRCodeGenerator style={QRCodeStyles} value={uri} />
              </QRCodeContainer>
            </RightColumn>
          )}
        </Row>
        <StyledButton onClick={onCloseModal} focusKey={CLOSE_BUTTON_FOCUS_KEY}>
          {t('close')}
        </StyledButton>
      </ModalContainer>
    </StyledModal>
  )
}

export const WelcomeModal = memo<WelcomeModalProps>(WelcomeModalPure)

export default WelcomeModal
