// @flow

import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'

import {
  Container,
  Header,
  Logo,
  Content,
  CustomerSupport,
  SupportDetails,
  ButtonsContainer,
  Button,
} from './HorizontalSupportScreen.styles'

import type { CustomerSupportProps } from '../CustomerSupport'
import type { SupportDetailsProps } from '../SupportDetails'

type HorizontalSupportScreenProps = CustomerSupportProps &
  SupportDetailsProps & {
    className?: string,
    logoSrc: string,
    logoHeight: number,
    supportAddress: string,
    deviceInfo: Object,
    date: string,
    onBack: Function,
  }

const HorizontalSupportScreenPure = ({
  className,
  logoSrc,
  logoHeight,
  supportEmail,
  infoEmail,
  companyWww,
  companyName,
  supportAddress,
  deviceInfo,
  date,
  onBack,
}: HorizontalSupportScreenProps) => {
  const { ref, setFocus } = useFocusable()
  const { t } = useTranslation()

  useEffect(() => {
    setFocus('BACK_BUTTON')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className={className} ref={ref}>
      <Header>
        <Logo src={logoSrc} height={logoHeight} alt="logo" />
      </Header>

      <Content>
        <CustomerSupport
          companyName={companyName}
          companyWww={companyWww}
          supportAddress={supportAddress}
          infoEmail={infoEmail}
          supportEmail={supportEmail}
          focusable={false}
        />

        <SupportDetails deviceInfo={deviceInfo} date={date} focusable={false} />
      </Content>

      <ButtonsContainer logoHeight={logoHeight}>
        <Button focusKey="BACK_BUTTON" onClick={onBack}>
          {t('back')}
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

export const HorizontalSupportScreen = memo<HorizontalSupportScreenProps>(
  HorizontalSupportScreenPure,
)

export default HorizontalSupportScreen
