// @flow

import typeToReducer from 'type-to-reducer'

import type { Customer } from '@alphaott/api-client/types/customer'

import { prepareMissedAccountFields } from '../../utils'
import { FETCH_CUSTOMER_TYPE, UPDATE_CUSTOMER_TYPE, UPDATE_CUSTOMER_SETTINGS_TYPE } from '../../actions/appCustomer'
import { passwordReducer } from './password'
import { voucherReducer } from './voucher'
import { deactivateReducer } from './deactivate'

export type CustomerData =
  | ({
      missedAccountFields: string[],
    } & Customer)
  | any

export type CustomerState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: CustomerData,
}

const initialState: CustomerState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: {},
}

const customer = typeToReducer(
  {
    [FETCH_CUSTOMER_TYPE]: {
      LOADING: (state): CustomerState => ({
        ...state,
        errors: [],
        data: {},
        isLoading: true,
        isSuccess: false,
        isError: false,
      }),

      SUCCESS: (state, action): CustomerState => {
        const {
          meta: { fields },
          payload: { data },
        } = action
        const missedAccountFields = prepareMissedAccountFields(fields, data)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isError: false,
          errors: [],
          data: {
            ...data,
            missedAccountFields,
          },
        }
      },

      ERROR: (state, action): CustomerState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        data: {},
      }),
    },
    [UPDATE_CUSTOMER_TYPE]: {
      LOADING: (state): CustomerState => ({
        ...state,
        errors: [],
        isLoading: true,
        data: {
          ...state.data,
          missedAccountFields: [],
        },
      }),

      SUCCESS: (state, action): CustomerState => {
        const {
          meta: { fields },
          payload: { data },
        } = action
        const missedAccountFields = prepareMissedAccountFields(fields, data)
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          errors: [],
          data: {
            ...data,
            _id: state.data._id,
            missedAccountFields,
          },
        }
      },

      ERROR: (state): CustomerState => ({
        ...state,
        isLoading: false,
      }),
    },
    [UPDATE_CUSTOMER_SETTINGS_TYPE]: {
      LOADING: (state): CustomerState => ({
        ...state,
        errors: [],
        isLoading: true,
        isError: false,
      }),

      SUCCESS: (state, action): CustomerState => {
        const {
          payload: { data },
        } = action
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isError: false,
          errors: [],
          data: {
            ...state.data,
            settings: data.settings,
          },
        }
      },

      ERROR: (state, action): CustomerState => ({
        ...state,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
    ...passwordReducer,
    ...voucherReducer,
    ...deactivateReducer,
  },
  initialState,
)
export { customer }
