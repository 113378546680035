// @flow
import type { DRM } from '@alphaott/api-client/types/drm'

import { DRM_TYPES } from '../const'

// TODO: Need be refactoring

const arrayToString = a => {
  const b = new Uint16Array(a.buffer)
  return String.fromCharCode.apply(null, b)
}

const base64DecodeUint8Array = a => {
  // $FlowFixMe
  const b = atob(a)
  const c = b.length
  const d = new Uint8Array(new ArrayBuffer(c))
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < c; ++i) d[i] = b.charCodeAt(i)
  return d
}

/* eslint-disable complexity, curly,
nonblock-statement-body-position, no-plusplus, indent, no-bitwise,
no-restricted-globals, no-multi-assign, no-sequences */
const base64EncodeUint8Array = a => {
  let c = ''
  let k = 0
  //  eslint-disable-next-line space-in-parens
  for (let d, e, f, g, h, i, j, b = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='; k < a.length; )
    (d = a[k++]),
      (e = k < a.length ? a[k++] : Number.NaN),
      (f = k < a.length ? a[k++] : Number.NaN),
      (g = d >> 2),
      (h = ((3 & d) << 4) | (e >> 4)),
      (i = ((15 & e) << 2) | (f >> 6)),
      (j = 63 & f),
      isNaN(e) ? (i = j = 64) : isNaN(f) && (j = 64),
      (c += b.charAt(g) + b.charAt(h) + b.charAt(i) + b.charAt(j))
  return c
}

const prepareFairPlayKeySystems = (drm: DRM) => {
  if (drm[DRM_TYPES.FAIRPLAY]) {
    return {
      certificateUri: drm[DRM_TYPES.FAIRPLAY].certificateUri,
      certificateHeaders: drm[DRM_TYPES.FAIRPLAY].certificateHeaders,
      licenseHeaders: drm[DRM_TYPES.FAIRPLAY].licenseHeaders,
      getContentId: (emeOptions: any, initData: any) => {
        const contentId = arrayToString(initData)

        if (contentId.indexOf('skd://') > -1) return contentId.split('skd://')[1].substr(0, 32)

        // eslint-disable-next-line no-throw-literal
        throw 'Invalid Content ID.'
      },

      /* eslint-disable promise/prefer-await-to-callbacks, max-len  */
      getLicense: async (emeOptions: any, contentId: any, keyMessage: any, callback: any) => {
        const data = `spc=${base64EncodeUint8Array(keyMessage)}&assetId=${contentId}`
        let response
        try {
          response = await fetch(drm[DRM_TYPES.FAIRPLAY].licenseUri, {
            method: 'POST',
            headers: {
              ...drm[DRM_TYPES.FAIRPLAY].licenseHeaders,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: data,
          })
        } catch (err) {
          callback(err)
        }

        // $FlowFixMe
        const result = await response.text()

        callback(null, base64DecodeUint8Array(result))
      },
      /*  eslint-enable */
    }
  }
  return {}
}

export default prepareFairPlayKeySystems
