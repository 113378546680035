/* eslint-disable function-paren-newline */
// @flow
import { createSelector } from 'reselect'
import { propOr } from 'ramda'

import type { Device } from '@alphaott/api-client/types/devices'

import type { CurrentDeviceState } from '../reducers'
import type { DeviceStore } from './types'

export const getDeviceInfo = (state: DeviceStore): CurrentDeviceState => state.currentDevice

export const getCurrentDevice: any = createSelector(
  [getDeviceInfo],
  (deviceInfo: DeviceStore): Device => ({
    type: propOr('', 'type', deviceInfo),
    platform: propOr('', 'platform', deviceInfo),
    serial: propOr('', 'serial', deviceInfo),
    _id: propOr('', '_id', deviceInfo),
    model: propOr('', 'model', deviceInfo),
    brand: propOr('', 'brand', deviceInfo),
    mac: propOr('', 'mac', deviceInfo),
  }),
)

export const getManufacturer: any = createSelector(
  [getDeviceInfo],
  (deviceInfo: DeviceStore): string => propOr('', 'brand', deviceInfo),
)

export const getDeviceModel: any = createSelector(
  [getDeviceInfo],
  (deviceInfo: DeviceStore): string => propOr('', 'model', deviceInfo),
)

export const getDeviceType: any = createSelector(
  [getDeviceInfo],
  (deviceInfo: DeviceStore): string => propOr('', 'type', deviceInfo),
)

export const getDevicePlatform: any = createSelector(
  [getDeviceInfo],
  (deviceInfo: DeviceStore): string => propOr('', 'platform', deviceInfo),
)

export const getOs: any = createSelector([getDeviceInfo], (deviceInfo: DeviceStore): string =>
  propOr('', 'os', deviceInfo),
)

export const getOsVersion: any = createSelector(
  [getDeviceInfo],
  (deviceInfo: DeviceStore): string => propOr('', 'osVersion', deviceInfo),
)

export const getDeviceId: any = createSelector([getDeviceInfo], (deviceInfo: DeviceStore): string =>
  propOr('', '_id', deviceInfo),
)

export const getEthernetMac: any = createSelector(
  [getDeviceInfo],
  (deviceInfo: DeviceStore): string => propOr('', 'ethernetMac', deviceInfo),
)

export const getWifiMac: any = createSelector([getDeviceInfo], (deviceInfo: DeviceStore): string =>
  propOr('', 'wifiMac', deviceInfo),
)

export const getVisibilityStatus: any = createSelector(
  [getDeviceInfo],
  (deviceInfo: DeviceStore): 'hidden' | 'visible' => propOr('', 'visibilityStatus', deviceInfo),
)

export const getNetworkStatus: any = createSelector(
  [getDeviceInfo],
  (deviceInfo: DeviceStore): string => propOr('', 'networkStatus', deviceInfo),
)
