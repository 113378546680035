// @flow
import { makeGetRequest } from '../utils'

import type { ResponseAPI } from '../types/api'
import type { RadioChannels } from '../types/radioChannels'

export type RadioChannelQuery = {|
  limit?: number,
  skip?: number,
  title?: string,
  plot?: string,
  year?: string,
  languages?: string,
  countries?: string,
  genres?: string,
  categories?: string,
  studios?: string,
  sort?: 'rating' | '-rating' | 'year' | '-year' | 'created' | '-created',
|}

export const getRadioChannels = (
  domain: string,
  serviceId: string,
  query?: RadioChannelQuery,
): Promise<ResponseAPI<RadioChannels>> =>
  makeGetRequest(`${domain}/client/api/servicespec/${serviceId}/radio/channels`, { data: query })

export const getRadioChannelsBrief = (
  domain: string,
  serviceId: string,
  query?: RadioChannelQuery,
): Promise<ResponseAPI<RadioChannels>> =>
  makeGetRequest(`${domain}/client/api/servicespec/${serviceId}/radio/channels?brief`, { data: query })
