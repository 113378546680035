// @flow
import React, { memo } from 'react'

import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'

import * as S from './CodeInput.style'

type SingleCodeInputProps = {
  focusKey?: string,
  value: number,
  onArrowPress?: Function,
}

const SingleCodeInputPure = ({ focusKey, value = 0, onArrowPress }: SingleCodeInputProps) => {
  const { ref, focused, focusSelf } = useFocusable({ focusKey, onArrowPress })
  const { handleMouseOver } = useMouseOver(focusSelf)

  return (
    <S.SingleCodeInputWrapper ref={ref}>
      <S.ArrowWrapper visible={focused} topDirection />
      <S.SingleCodeInput value={value} focused={focused} onMouseOver={handleMouseOver} />
      <S.ArrowWrapper visible={focused} />
    </S.SingleCodeInputWrapper>
  )
}

export const SingleCodeInput = memo<SingleCodeInputProps>(SingleCodeInputPure)

export default SingleCodeInput
