// @flow
import { LARGE_VIEWPORT, MEDIUM_VIEWPORT, SMALL_VIEWPORT } from '@alphaott/app-config'
import { rgba } from 'polished'

import { colorPalette } from '../colorPalettes'
import type { ColorType } from '../colorPalettes'

export const streamnxtColors: ColorType = {
  ...colorPalette,
  primary: '#DD157B',
  secondary: '#DD157B',
  grayText: '#B0B0B0',
  grayLabel: '#717171',
  grayBorder: 'rgba(255, 255, 255, 0.38)',
}

export const darkTheme = {
  colors: streamnxtColors,
  background: {
    primary: '#181818',
    secondary: '#262626',
    highlight: streamnxtColors.dark,
    select: '#2e2e2e',
    footerTop: '#333',
  },
  text: {
    primary: streamnxtColors.primaryGray,
    secondary: streamnxtColors.secondaryGray,
    disabled: streamnxtColors.grayText,
    link: streamnxtColors.secondaryGray,
    hint: streamnxtColors.white,
    highlight: streamnxtColors.secondary,
    gray: streamnxtColors.primaryGray,
    seeAll: streamnxtColors.secondaryGray,
    primaryContrast: '#ffffff',
  },
  border: {
    primary: streamnxtColors.primaryGray,
    gray: streamnxtColors.secondaryGray,
  },
  filter: {
    primary: streamnxtColors.primaryGray,
    secondary: streamnxtColors.secondaryGray,
    disabled: streamnxtColors.secondaryGray,
    highlight: '#111111',
  },
  tabs: {
    bg: '#181818',
    containerBg: streamnxtColors.dark,
    color: streamnxtColors.white,
    border: streamnxtColors.primary,
    scrollbarThumb: '#bdbdbd',
    programTitle: streamnxtColors.white,
  },
  programGuide: {
    programBox: {
      background: {
        main: '#2e2e2e',
        hover: streamnxtColors.dark,
        selected: streamnxtColors.dark,
        progress: rgba(streamnxtColors.primary, 0.3),
        past: rgba(streamnxtColors.primary, 0.3),
        pastSelected: streamnxtColors.dark,
        pastHover: streamnxtColors.dark,
        empty: '#555555',
        emptyProgress: rgba(streamnxtColors.primary, 0.2),
      },
      text: {
        empty: streamnxtColors.secondaryGray,
      },
    },
    programGuidePictureOverlay: {
      horizontal:
        // eslint-disable-next-line max-len
        `linear-gradient(90deg, #181818 0%,${rgba('#181818', 0.97)} 13.03%,${rgba('#181818', 0.69)} 43.24%,${rgba(
          '#181818',
          0,
        )} 100%);`,
      vertical: `linear-gradient(0, #181818 0%,${rgba('#181818', 0.73)} 34.97%,${rgba('#181818', 0)} 100%);`,
    },
  },
  card: {
    title: streamnxtColors.white,
    subTitle: streamnxtColors.secondaryGray,
    bg: streamnxtColors.grayText,
    number: '#DFDFDF',
    boxShadow: '#2e2e2e',
    loaderBackgroundImage: 'linear-gradient(to right, #222 0%, #111 20%, #222 40%, #222 100%)',
  },
  dimension: {},
  size: 'default',
  breakpoints: [`${SMALL_VIEWPORT}px`, `${MEDIUM_VIEWPORT}px`, `${LARGE_VIEWPORT}px`],
  signIn: {
    clipPath: false,
  },
}
