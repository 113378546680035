// @flow

import React, { memo, useCallback, useEffect } from 'react'
import { not, and } from 'ramda'
import { notEqual } from 'ramda-adjunct'

import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'
import { isKeyboardEnterKeyCode } from '@alphaott/smart-tv-platforms'
import { usePrevious } from '@alphaott/common-utils'

import { Container, Label } from './MenuItem.styles'

type MenuItemProps = {
  focusKey?: string,
  children: any,
  onPress: Function,
}

const MenuItemPure = ({ focusKey, children, onPress }: MenuItemProps) => {
  const { ref, focused, focusSelf } = useFocusable({ focusKey })
  const { handleMouseOver } = useMouseOver(focusSelf)
  const prevFocused = usePrevious(focused)

  const handleEnterPress = useCallback(
    (event) => {
      if (not(event.repeat)) {
        onPress && onPress(focusKey)
      }

      event.stopPropagation()
    },
    [focusKey, onPress],
  )

  const handleKeyPress = useCallback(
    (event) => isKeyboardEnterKeyCode(handleEnterPress)(event),
    [handleEnterPress],
  )

  useEffect(() => {
    if (and(focused, notEqual(focused, prevFocused))) {
      window?.addEventListener('keydown', handleKeyPress)
    }

    if (and(not(focused), notEqual(focused, prevFocused))) {
      window?.removeEventListener('keydown', handleKeyPress)
    }

    return () => window.removeEventListener('keydown', handleKeyPress)
  }, [focused]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container ref={ref} focused={focused} onClick={onPress} onMouseOver={handleMouseOver}>
      <Label focused={focused}>{children}</Label>
    </Container>
  )
}

export const MenuItem = memo<MenuItemProps>(MenuItemPure)

export default MenuItem
