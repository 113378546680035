// @flow

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'ramda'

import { getCatchupMediaStream } from '@alphaott/app-catchup/selectors'

export const useCatchupMediaStream = (programId: string) => {
  const mediaStream = useSelector(getCatchupMediaStream(programId))

  const cachedMediaStream = useMemo(() => mediaStream, [programId]) // eslint-disable-line react-hooks/exhaustive-deps

  return useMemo(
    () => (isEmpty(cachedMediaStream) ? mediaStream : cachedMediaStream),
    [cachedMediaStream, mediaStream],
  )
}

export default useCatchupMediaStream
