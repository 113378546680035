// @flow

import { pathOr } from 'ramda'
import * as R from 'ramda'

export const getHorizontalListScrollOffset = (listRef: any, index: number): number => {
  const listWidth = pathOr(0, ['props', 'width'], listRef)
  // eslint-disable-next-line no-underscore-dangle
  const itemWidth = listRef._getItemStyle(index).width
  const listCenter = listWidth / 2
  const itemCenter = itemWidth / 2

  // eslint-disable-next-line no-underscore-dangle
  const leftItemOffset = listRef._getItemStyle(index).left + itemCenter
  const scrollOffset = leftItemOffset - listCenter

  return scrollOffset
}

export const getVerticalListScrollOffset = (listRef: any, index: number): number => {
  const listHeight = pathOr(0, ['props', 'height'], listRef)
  // eslint-disable-next-line no-underscore-dangle
  const itemHeight = listRef._getItemStyle(index).height
  const listCenter = listHeight / 2
  const itemCenter = itemHeight / 2

  // eslint-disable-next-line no-underscore-dangle
  const topItemOffset = listRef._getItemStyle(index).top + itemCenter
  const scrollOffset = topItemOffset - listCenter

  return scrollOffset
}

export const getGridListScrollOffset = (listRef: any, index: number): number => {
  const listHeight = pathOr(0, ['props', 'height'], listRef)
  const rowHeight = listRef.props.rowHeight()
  const listCenter = listHeight / 2
  const rowCenter = rowHeight / 2

  // eslint-disable-next-line no-underscore-dangle
  const topRowOffset = listRef._getItemStyle(index, 0).top + rowCenter
  const scrollOffset = topRowOffset - listCenter

  return scrollOffset
}

// For Grid or Horizontal lists
export const getListScrollOffset = (listSize: number, itemSize: number, index: number): number => {
  const listCenter = listSize / 2
  const rowCenter = itemSize / 2

  const topRowOffset = itemSize * index + rowCenter
  const scrollOffset = topRowOffset - listCenter

  return scrollOffset
}

export const getVerticalListScrollOffsetV2 = (height, itemIndex, sizes): number => {
  const untilKey = String(itemIndex)

  const keys = R.keys(sizes)
  const untilIndex = R.indexOf(untilKey, keys)
  const takenKeys = R.takeWhile((key) => {
    const index = R.indexOf(key, keys)
    return index <= untilIndex
  }, keys)
  const values = R.values(R.pick(takenKeys, sizes))
  const sum = R.reduce(R.add, 0, values)

  const rowHeight = sizes[itemIndex]
  const listCenter = height / 2
  const rowCenter = rowHeight / 2

  const topRowOffset = sum + rowCenter - rowHeight
  const scrollOffset = topRowOffset - listCenter

  return scrollOffset
}
