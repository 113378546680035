// @flow

import React, { memo, useMemo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { goBack } from 'redux-first-history'
import { equals, includes, concat, length, replace, without } from 'ramda'

import { PopupDialog } from '@alphaott/smart-tv-components'

import { getRoutingPathname } from '@alphaott/app-main/selectors'
import { getPreparedAuthMethods } from '@alphaott/app-auth/selectors'
import { getShowListControl } from '@alphaott/smart-tv-video-player'

import { useExitAppModal } from '@alphaott/smart-tv-notifications'
import { prepareBasename } from '@alphaott/smart-tv-common'
import { isAppLoading } from '@alphaott/app-core/selectors'
import { isWebOS, BUTTON_KEY_NAMES } from '@alphaott/smart-tv-platforms'
import { BLACK_LIST_AUTH_METHODS } from '@alphaott/smart-tv-config'

export type ExitModalContainerProps = {
  basename?: string,
  children: any,
}

const SAMSUNG_DEFAULT_PATHNAME = '/index.html'
// eslint-disable-next-line no-undef
const LG_DEFAULT_PATHNAME = `/media/developer/apps/usr/palm/applications/${webOS?.fetchAppId()}/index.html`

const ExitModalContainerPure = ({ basename, children }: ExitModalContainerProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const pathname = useSelector(getRoutingPathname)
  const availableMethods = useSelector(getPreparedAuthMethods)
  const isLoadingApp = useSelector(isAppLoading)
  const isShowListControl = useSelector(getShowListControl)

  const authMethods = without(BLACK_LIST_AUTH_METHODS, availableMethods)

  const preparedBasename = useMemo(
    () => replace(`${prepareBasename(basename)}/`, '/', pathname),
    [basename, pathname],
  )

  const {
    isAppExitModalOpened,
    handleOpenExitAppModal,
    handleCloseExitAppModal,
    handleApproveExitAppModal,
  } = useExitAppModal()

  const preparedPathnameList = useMemo(() => {
    const pathnameList = [
      '/',
      '/auth/methods',
      '/error',
      '/welcome',
      SAMSUNG_DEFAULT_PATHNAME,
      LG_DEFAULT_PATHNAME,
    ]

    if (equals(length(authMethods), 1)) {
      return concat(['/auth/login', '/auth/login/otp/sms', '/auth/login/otp/email'], pathnameList)
    }

    return pathnameList
  }, [authMethods])

  const handleCallback = useCallback((event, handler) => {
    handler()
    event.stopPropagation()
  }, [])

  const handleBackPress = useCallback(
    // eslint-disable-next-line consistent-return,complexity
    (event) => {
      if (isAppExitModalOpened) {
        return handleCallback(event, handleCloseExitAppModal)
      }

      if (includes(preparedBasename, preparedPathnameList)) {
        if (isWebOS) {
          return handleCallback(event, handleApproveExitAppModal)
        }

        return handleCallback(event, handleOpenExitAppModal)
      }

      if (isShowListControl) {
        return null
      }

      return dispatch(goBack())
    },
    [
      isAppExitModalOpened,
      preparedBasename,
      preparedPathnameList,
      isShowListControl,
      dispatch,
      handleCallback,
      handleCloseExitAppModal,
      handleOpenExitAppModal,
      handleApproveExitAppModal,
    ],
  )

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    document?.addEventListener(BUTTON_KEY_NAMES.BACK, handleBackPress, true)
    return () => document?.removeEventListener(BUTTON_KEY_NAMES.BACK, handleBackPress, true)
  }, [handleBackPress])

  if (isLoadingApp) {
    return children
  }

  return (
    <>
      {children}

      {isAppExitModalOpened && (
        <PopupDialog
          title={t('mwstv_exit_the_application')}
          description={t('are_you_sure')}
          isOpen={isAppExitModalOpened}
          focusable={isAppExitModalOpened}
          onClose={handleCloseExitAppModal}
          onApprove={handleApproveExitAppModal}
          approveButtonTitle={t('mwstv_exit')}
          cancelButtonTitle={t('cancel')}
        />
      )}
    </>
  )
}

export const ExitModalContainer = memo<ExitModalContainerProps>(ExitModalContainerPure)

export default ExitModalContainer
