// @flow
import React, { memo, forwardRef } from 'react'
import { equals } from 'ramda'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'
import { scale } from '@alphaott/smart-tv-utils'

import Header from './Header'
import { ChannelListScrollable } from './ChannelList'
import {
  ChannelsScrollContainer,
  CHANNEL_LIST_SECTION_HEIGHT,
  CHANNEL_LIST_HEADING_HEIGHT,
  CHANNEL_LIST_MARGIN_BOTTOM,
} from './ChannelsScrollView.style'

export type ChannelsScrollViewProps = {
  loading: boolean,
  items: Array<any>,
  sectionIndex: number,
  sectionType: string,
  heading: string,
  cardPlaceholder?: string,
  onPressItem: (string) => void,
  onLongPressItem: (string) => void,
  onPressViewAll: () => void,
}

// temporary solution until banner is implemented
export const getChannelSectionHeight = (
  index: number,
  headerHeight: number,
  updatedSize?: number,
): number => {
  const preparedSize = equals(updatedSize, 0)
    ? updatedSize
    : CHANNEL_LIST_SECTION_HEIGHT + CHANNEL_LIST_MARGIN_BOTTOM
  // eslint-disable-next-line max-len
  const height = // eslint-disable-line operator-linebreak
    index === 0 ? preparedSize + headerHeight + CHANNEL_LIST_HEADING_HEIGHT : preparedSize
  return scale(window.innerWidth, height)
}

export const ChannelsScrollViewPure = (
  {
    v2,
    loading,
    items,
    sectionIndex,
    sectionType,
    heading,
    cardPlaceholder,
    onPressItem,
    onLongPressItem,
    onPressViewAll,
    onBecameFocused: onFocus,
    onChangeCurrentFocusedItem,
  }: ChannelsScrollViewProps,
  ref: any,
) => {
  const { ref: focusRef, focusKey, setFocus } = useFocusable({ autoRestoreFocus: false, onFocus })

  return (
    <FocusContext.Provider value={focusKey}>
      <ChannelsScrollContainer ref={focusRef}>
        <Header heading={heading} />
        {/* TODO: Add loading component */}
        {loading ? null : (
          <ChannelListScrollable
            v2={v2}
            ref={ref}
            items={items}
            sectionIndex={sectionIndex}
            sectionType={sectionType}
            heading={heading}
            cardPlaceholder={cardPlaceholder}
            focusKey={focusKey}
            setFocus={setFocus}
            onPressItem={onPressItem}
            onLongPressItem={onLongPressItem}
            onPressViewAll={onPressViewAll}
            onChangeCurrentFocusedItem={onChangeCurrentFocusedItem}
          />
        )}
      </ChannelsScrollContainer>
    </FocusContext.Provider>
  )
}

export const ChannelsScrollView = memo<ChannelsScrollViewProps>(forwardRef(ChannelsScrollViewPure))

export default ChannelsScrollView
