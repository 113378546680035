// @flow

import typeToReducer from 'type-to-reducer'

import {
  CHANGE_CONTENT_META_DATA_TYPE,
  CHANGE_PLAYER_PROGRESS_TYPE,
  RESET_PLAYER_STATE_TYPE,
} from '../actions/constants'

export type PlayerProgressState = {
  +currentTime: number,
  +duration: number,
}

const initialState: PlayerProgressState = {
  currentTime: 0,
  duration: 1,
}

const playerProgress = typeToReducer(
  {
    [CHANGE_CONTENT_META_DATA_TYPE]: (state, action): PlayerProgressState => ({
      ...state,
      ...action.payload,
    }),
    [CHANGE_PLAYER_PROGRESS_TYPE]: (state, action): PlayerProgressState => ({
      ...state,
      currentTime: action.payload.currentTime,
    }),
    [RESET_PLAYER_STATE_TYPE]: () => initialState,
  },
  initialState,
)

export { playerProgress }
