// @flow
/* eslint-disable complexity */
/* eslint-disable react/display-name */
import React, { memo, useCallback } from 'react'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { pipe } from 'ramda'

import { withParentalControl } from '@alphaott/smart-tv-common'
import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'

import { PlayBtn } from '../PlayButton'
import {
  ProgramItemContainer,
  ProgramItemBase,
  Block,
  ProgramItemTitle,
  Time,
  PlayCurrentIcon,
} from './ProgramItem.style'

type ProgramItemProps = {
  focusKey?: string,
  title: string,
  start: number,
  stop: number,
  isLive?: boolean, // if online playing
  isSelected: boolean,
  isPlayable?: boolean,
  isCurrent: boolean, // if catchup playing
  isListFocused: boolean,
  onFocus?: Function,
  onPress: Function,
  style?: any,
}

const ProgramItemPure = ({
  focusKey,
  _id,
  isLive,
  isSelected,
  isCurrent,
  title,
  start,
  stop,
  isPlayable,
  isListFocused,
  style,
  onPress,
  onFocus,
}: ProgramItemProps) => {
  const handleFocus = useCallback((event) => onFocus(event, { _id }), [_id, onFocus])

  const { ref, focused } = useFocusable({ focusKey, onFocus: handleFocus, onEnterPress: onPress })
  const { t } = useTranslation()

  const showCatchUpBtn = Boolean(isPlayable && !isLive)

  return (
    <ProgramItemContainer
      ref={ref}
      isCurrent={isCurrent}
      isLive={isLive}
      isSelected={isSelected}
      isListFocused={isListFocused}
      style={style}
      focused={focused}
      onClick={onPress}
    >
      <ProgramItemBase>
        <Block>
          <Time>{`${format(start, 'HH:mm')} - ${format(stop, 'HH:mm')} `}</Time>
          {isCurrent && <PlayCurrentIcon />}
          <ProgramItemTitle isLive={isLive} isCurrent={isCurrent} showCatchUpBtn={showCatchUpBtn}>
            {title}
          </ProgramItemTitle>
        </Block>
        {isLive && (
          <PlayBtn isLive={isLive} isSelected={isSelected} focused={focused} onClick={onPress}>
            <span>{t('mwstv_live')}</span>
          </PlayBtn>
        )}
        {showCatchUpBtn && (
          <PlayBtn isLive={isLive} isSelected={isSelected} focused={focused} onClick={onPress}>
            <span>{t('mwstv_catchup')}</span>
          </PlayBtn>
        )}
      </ProgramItemBase>
    </ProgramItemContainer>
  )
}

export const ProgramItem = pipe(memo, withParentalControl)<ProgramItemProps>(ProgramItemPure)

export default ProgramItem
