// @flow
import React, { memo, useCallback, useMemo, useState } from 'react'
import { not, or } from 'ramda'

import { Container, Wrapper, Loader, StyledImage } from './Image.style'

type ImagePureProps = {
  className?: string,
  imgStyle?: Object,
  src: string,
  placeholder: string,
  unloader: any,
  alt: string,
}

type ImageInnerProps = {
  src: string,
  style: Object,
  alt: string,
  onError: Function,
}

// eslint-disable-next-line react/prefer-exact-props
const ImageInnerPure = (props: ImageInnerProps) => {
  const [isLoading, setIsLoading] = useState(true)

  const handleOnLoad = useCallback(() => {
    setIsLoading(false)
  }, [])

  return (
    <>
      {isLoading && <Loader />}

      <StyledImage isLoading={isLoading} onLoad={handleOnLoad} {...props} />
    </>
  )
}

const ImageInner = memo(ImageInnerPure)

const ImagePure = ({ className, imgStyle, src, placeholder, unloader, alt }: ImagePureProps) => {
  const [isError, setIsError] = useState(false)

  const source = useMemo(() => or(src, placeholder), [placeholder, src])

  const handleOnError = useCallback(() => {
    setIsError(true)
  }, [])

  return (
    <Container className={className}>
      <Wrapper>
        {isError && unloader}

        {not(isError) && (
          <ImageInner src={source} style={imgStyle} alt={alt} onError={handleOnError} />
        )}
      </Wrapper>
    </Container>
  )
}

ImagePure.defaultProps = {
  imgStyle: {
    width: '100%',
  },
}

export const Image = memo(ImagePure)

export default Image
