// @flow
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect'
import { replace } from 'redux-first-history'
import { pipe, not, length, equals, cond, always, reject, head, or, gt } from 'ramda'
import { isNotEmpty, isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct'

import {
  isNewCustomerAndWithoutServices,
  isExpiredSubscriptionAndWithoutServices,
  getAppErrors,
} from '@alphaott/app-core/selectors'
import { getPreparedAuthMethods } from '@alphaott/app-auth/selectors'

export const prepareAppErrors = (state) => {
  const customerErrors = getAppErrors(state)

  return cond([
    [(store) => isNotNilOrEmpty(store.init.errors), always(state.init.errors)],
    [(store) => isNotNilOrEmpty(store.config.errors), always(state.config.errors)],
    [() => isNotNilOrEmpty(customerErrors), always(customerErrors)],
  ])(state)
}

export const hasNoError = connectedReduxRedirect({
  redirectPath: '/error',
  authenticatedSelector: (state) => {
    const errors = prepareAppErrors(state)

    return isNilOrEmpty(errors)
  },
  allowRedirectBack: false,
  wrapperDisplayName: 'IsNotError',
  redirectAction: replace,
})

export const hasError = connectedReduxRedirect({
  redirectPath: '/',
  authenticatedSelector: (state) => {
    const errors = prepareAppErrors(state)

    return isNotNilOrEmpty(errors)
  },
  allowRedirectBack: false,
  wrapperDisplayName: 'IsError',
  redirectAction: replace,
})

export const userIsAuthenticated = connectedReduxRedirect({
  redirectPath: '/auth/methods',
  authenticatedSelector: (state) => isNotEmpty(state.token),
  wrapperDisplayName: 'UserIsAuthenticated',
  redirectAction: replace,
})

export const hasSeveralAuthMethods = connectedReduxRedirect({
  redirectPath: (state) => {
    const routesMethods = {
      OTP_EMAIL: '/auth/login/otp/email',
      PASSWORD: '/auth/login',
      // VOUCHER: '/voucher',  // TODO: uncomment when method will be implemented
      // DEVICE_CODE: '/device-code', // TODO: uncomment when method will be implemented
      OTP_SMS: '/auth/login/otp/sms',
    }
    const authMethods = getPreparedAuthMethods(state)
    const preparedAuthMethods = reject(
      (item) => or(equals('VOUCHER', item), equals('GUEST', item)),
      authMethods,
    )

    const availableMethod = head(preparedAuthMethods)

    return or(routesMethods[availableMethod], routesMethods.PASSWORD)
  },
  authenticatedSelector: (state) => {
    const authMethods = getPreparedAuthMethods(state)
    const preparedAuthMethods = reject(
      (item) => or(equals('VOUCHER', item), equals('GUEST', item)),
      authMethods,
    )

    return gt(length(preparedAuthMethods), 1)
  },
  wrapperDisplayName: 'hasSeveralAuthMethods',
  redirectAction: replace,
})

export const userWithActiveSubscriptions = connectedReduxRedirect({
  redirectPath: '/expired',
  authenticatedSelector: (state) => not(isExpiredSubscriptionAndWithoutServices(state)),
  wrapperDisplayName: 'ExpiredSubscriptions',
  redirectAction: replace,
})

export const userIsNotNew = connectedReduxRedirect({
  redirectPath: '/welcome',
  authenticatedSelector: (state) => not(isNewCustomerAndWithoutServices(state)),
  wrapperDisplayName: 'NewUser',
  redirectAction: replace,
})

export const userWithSubscriptions = pipe(userWithActiveSubscriptions, userIsNotNew)
