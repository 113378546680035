// @flow

import { useSelector } from 'react-redux'
import { equals } from 'ramda'

import { getNetworkStatus } from '@alphaott/smart-tv-current-device/selectors'
import { usePrevious } from '@alphaott/common-utils'
import { CONNECTED, DISCONNECTED } from '@alphaott/smart-tv-common'

interface Status {
  networkStatus: string;
  isConnectedNetwork: boolean;
  isDisconnectedNetwork: boolean;
}

interface UseNetworkStatusResult extends Status {
  networkStatus: string;
  isDisconnectedNetwork: boolean;
  prevStatus?: Status;
}

export const useNetworkStatus = (): UseNetworkStatusResult => {
  const networkStatus = useSelector(getNetworkStatus)
  const isConnectedNetwork = equals(networkStatus, CONNECTED)
  const isDisconnectedNetwork = equals(networkStatus, DISCONNECTED)

  const currentStatus: Status = {
    networkStatus,
    isConnectedNetwork,
    isDisconnectedNetwork,
  }
  const prevStatus = usePrevious(currentStatus)

  return {
    ...currentStatus,
    prevStatus,
  }
}

export default useNetworkStatus
