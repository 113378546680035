// @flow

import { map, path } from 'ramda'

import { ENTITY_TYPE } from '@alphaott/smart-tv-utils'

export const moviesToItems = (movies = []) =>
  map(
    ({ title, posters, _id: id, year, current, total, pgRating, tags }) => ({
      id,
      title,
      src: path([0, 'path'], posters),
      date: year,
      currentTime: current,
      totalTime: total,
      type: ENTITY_TYPE.MOVIE,
      pgRating,
      tags,
    }),
    movies,
  )
