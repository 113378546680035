// @flow

import { normalize } from 'normalizr'
import typeToReducer from 'type-to-reducer'
import tvshowListSchema, { parseTVShowList } from '@alphaott/common-utils/utils/schemas/tvshows'
import { isValidTTL, calculateExpires } from '@alphaott/app-main/selectors'

import type { TVShow } from '@alphaott/api-client/types/tvshows'

import { FETCH_TVSHOWS, FETCH_TOP_TVSHOWS, FILTER_OPEN_TVSHOW } from '../actions'
import type { FilterOpenTVShowAction } from '../actions'

export type TVShowsState = {|
  isLoading: boolean,
  isSuccess: boolean,
  isError: boolean,
  isOpenFilter: boolean,
  hasMore: boolean,
  errors: any,
  created: ?Date,
  entities: { [string]: TVShow },
  expires: ?number,
|}

const initialState: TVShowsState = {
  created: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  isOpenFilter: false,
  hasMore: true,
  errors: [],
  entities: {},
  expires: null,
}

const tvshowsList = typeToReducer(
  {
    [FETCH_TVSHOWS]: {
      LOADING: (state): TVShowsState => ({ ...state, isLoading: true }),

      // eslint-disable-next-line complexity
      SUCCESS: (state, action): TVShowsState => {
        const {
          payload = {},
          meta: { contentApi, resetData, ttl },
        } = action
        const { entities, result } = normalize(payload.data, tvshowListSchema)
        const tvshows = resetData
          ? parseTVShowList(entities.tvshows, contentApi)
          : { ...state.entities, ...parseTVShowList(entities.tvshows, contentApi) }

        return {
          ...state,
          created: new Date().getTime(),
          isLoading: false,
          isSuccess: true,
          hasMore: result && result.length > 0,
          entities: tvshows,
          ...(isValidTTL(ttl) && { expires: calculateExpires(ttl) }),
        }
      },

      ERROR: (state, action): TVShowsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        expires: null,
      }),
    },
    [FILTER_OPEN_TVSHOW]: (state: TVShowsState, { isOpenFilter }: FilterOpenTVShowAction): TVShowsState => ({
      ...state,
      isOpenFilter,
    }),
  },
  initialState,
)

const topTVShowsList = typeToReducer(
  {
    [FETCH_TOP_TVSHOWS]: {
      LOADING: (state): TVShowsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): TVShowsState => {
        const {
          payload,
          meta: { contentApi, ttl },
        } = action
        const { entities, result } = normalize(payload.data, tvshowListSchema)
        const tvshows = { ...state.entities, ...parseTVShowList(entities.tvshows, contentApi) }

        return {
          ...state,
          created: new Date().getTime(),
          isLoading: false,
          isSuccess: true,
          hasMore: result && result.length > 0,
          entities: tvshows,
          ...(isValidTTL(ttl) && { expires: calculateExpires(ttl) }),
        }
      },

      ERROR: (state, action): TVShowsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        expires: null,
      }),
    },
  },
  initialState,
)

export { tvshowsList, topTVShowsList }

export * from './tvshow'
export * from './tvshowSeasons'
export * from './tvshowEpisode'
export * from './tvshowCategories'
export * from './tvshowCountries'
export * from './tvshowGenres'
export * from './tvshowLanguages'
export * from './tvshowLimits'
export * from './tvshowSources'
export * from './tvshowFavorites'
export * from './searchList'
export * from './currentMedia'
export * from './recent'
export * from './tvshowSuggested'
export * from './tvshowFilters'
