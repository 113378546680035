// flow

import type { Headers } from '../types/api'

export type Options = { token: string, acceptMediaStream?: string, acceptMediaStreamEncrypted?: string }

export const getStreamHeader = ({ token, acceptMediaStream, acceptMediaStreamEncrypted }: Options): Headers => {
  const headers: Headers = { token }
  if (acceptMediaStream) headers['accept-media-stream'] = acceptMediaStream
  if (acceptMediaStreamEncrypted) headers['accept-media-stream-encrypted'] = acceptMediaStreamEncrypted
  return headers
}
