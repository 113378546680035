// @flow
import { makePostRequest } from './utils'

import type { Headers, ResponseAPI } from './types/api'
import type { Limits } from './types/limits'

export const getLimits = (
  domain: string,
  contentId: string,
  contentType: 'tv' | 'vod' | 'tvShow',
  { token }: Headers,
): Promise<ResponseAPI<Limits>> =>
  makePostRequest(`${domain}/client/api/customer/device/online?contentId=${contentId}&contentType=${contentType}`, {
    headers: { token },
  })
