// @flow
export const EXIT_APP_MODAL = 'app/EXIT_APP_MODAL'

export type ExitModalAction = {|
  type: 'app/EXIT_APP_MODAL',
  isAppExitModalOpened: boolean,
|}

export const openExitAppModal = (isAppExitModalOpened: boolean = false): ExitModalAction => ({
  type: EXIT_APP_MODAL,
  isAppExitModalOpened,
})
