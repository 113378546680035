// @flow

import { useMemo, useCallback, useRef } from 'react'
import { or } from 'ramda'

const DELAY = 500

export const useLongEnterPress = (onPress, onLongPress, { delay } = {}) => {
  const preparedDelay = useMemo(() => or(delay, DELAY), [delay])

  const timeout = useRef(null)

  const handleClearTimeout = useCallback(() => {
    clearTimeout(timeout.current)
    timeout.current = null
  }, [])

  // eslint-disable-next-line consistent-return
  const handleEnterPress = useCallback(() => {
    if (timeout.current) return null

    timeout.current = setTimeout(() => {
      handleClearTimeout()
      onLongPress && onLongPress()
    }, preparedDelay)
  }, [preparedDelay, handleClearTimeout, onLongPress])

  const handleEnterRelease = useCallback(() => {
    if (timeout.current) {
      handleClearTimeout()
      onPress && onPress()
    }
  }, [handleClearTimeout, onPress])

  return {
    onEnterPress: handleEnterPress,
    onEnterRelease: handleEnterRelease,
  }
}

export default useLongEnterPress
