// @flow
import typeToReducer from 'type-to-reducer'

import type { Device } from '@alphaott/api-client/types/devices'

import {
  getDeviceId,
  getDeviceType,
  getDeviceModel,
  getDevicePlatform,
  getDeviceBrand,
  getDeviceSerialNumber,
  getDeviceMacAddress,
} from '../utils'

export type CurrentDeviceState = Device

const initialState: CurrentDeviceState = {
  _id: getDeviceId(),
  type: getDeviceType(),
  model: getDeviceModel(),
  platform: getDevicePlatform(),
  brand: getDeviceBrand(),
  serial: getDeviceSerialNumber(),
  mac: getDeviceMacAddress(),
}

const currentDevice = typeToReducer({}, initialState)

export { currentDevice }
