// @flow

import styled from 'styled-components'

import { getBackgroundColor } from '@alphaott/smart-tv-themes'

export const HEADER_PADDING_TOP_BOTTOM = 32
export const HEADER_PADDING_LEFT_RIGHT = 50

export const Container = styled.div`
  display: flex;
  min-height: 52rem;
  justify-content: space-between;
  align-items: center;
  padding: ${HEADER_PADDING_TOP_BOTTOM}rem ${HEADER_PADDING_LEFT_RIGHT}rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: ${(props) =>
    props.withBackground ? getBackgroundColor(props) : 'transparent'};
`

export const LogoContainer = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: -${HEADER_PADDING_LEFT_RIGHT}rem;
    top: ${-HEADER_PADDING_TOP_BOTTOM}rem;
    width: ${({ logoWidth }) => logoWidth * 1.6}px; // 160%
    height: ${({ logoHeight }) => logoHeight * 2.7}px; // 270%
    background-image: ${({ image }) => `url(${image})`};
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
  }
`

export const Logo = styled.img`
  height: ${({ height }) => height || 52}rem;
`
