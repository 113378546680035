// @flow

export const URL_PLAN_ID_KEY = 'plan_id'
export const URL_BILLING_STEP_FLAG_KEY = 'step_plan'

export const PASSWORD = 'PASSWORD'
export const VOUCHER = 'VOUCHER'
export const FIREBASE = 'FIREBASE'
export const QR_CODE = 'QR_CODE'
export const DEVICE_CODE = 'DEVICE_CODE'
export const GUEST = 'GUEST'
export const OTP_EMAIL = 'OTP_EMAIL'
export const OTP_SMS = 'OTP_SMS'
