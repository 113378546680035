// @flow

import { useCallback } from 'react'

import { usePlayerContext } from './hook'
import * as constants from './constants'

import type { PlayerErrorType } from './types'

export const useActions = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, dispatch] = usePlayerContext()

  const handleLoadMeta = useCallback(
    ({ time, audioTracks, subtitleTracks }) =>
      dispatch({
        type: constants.LOAD_META,
        payload: {
          time,
          audioTracks,
          subtitleTracks,
        },
      }),
    [dispatch],
  )

  const handleLoading = useCallback(
    () =>
      dispatch({
        type: constants.LOADING,
        payload: {
          loading: true,
        },
      }),
    [dispatch],
  )

  const handleCanPlay = useCallback(
    () =>
      dispatch({
        type: constants.CAN_PLAY,
      }),
    [dispatch],
  )

  const handleSeeking = useCallback(
    () =>
      dispatch({
        type: constants.SEEKING,
        payload: {
          loading: true,
          seeking: true,
        },
      }),
    [dispatch],
  )

  const handleSeeked = useCallback(
    () =>
      dispatch({
        type: constants.SEEKING,
        payload: {
          loading: false,
          seeking: false,
        },
      }),
    [dispatch],
  )

  const handlePlay = useCallback(
    () =>
      dispatch({
        type: constants.PLAY,
      }),
    [dispatch],
  )

  const handlePause = useCallback(
    () =>
      dispatch({
        type: constants.PAUSE,
      }),
    [dispatch],
  )

  const handleEnd = useCallback(
    () =>
      dispatch({
        type: constants.END,
      }),
    [dispatch],
  )

  const handleError = useCallback(
    (error: PlayerErrorType) =>
      dispatch({
        type: constants.ERROR,
        payload: {
          error: {
            message: error.message,
            critical: error.critical,
          },
        },
      }),
    [dispatch],
  )

  const handleChangeAudioTracks = useCallback(
    (audioTracks) =>
      dispatch({
        type: constants.CHANGE_AUDIO_TRACKS,
        payload: {
          audioTracks,
        },
      }),
    [dispatch],
  )

  const handleChangeSubtitleTracks = useCallback(
    (subtitleTracks) =>
      dispatch({
        type: constants.CHANGE_SUBTITLE_TRACKS,
        payload: {
          subtitleTracks,
        },
      }),
    [dispatch],
  )

  const handleResetError = useCallback(() => dispatch({ type: constants.RESET_ERROR }), [dispatch])

  const handleReset = useCallback(
    () =>
      dispatch({
        type: constants.RESET,
      }),
    [dispatch],
  )

  return {
    onLoadMeta: handleLoadMeta,
    onCanPlay: handleCanPlay,
    onLoading: handleLoading,
    onSeeking: handleSeeking,
    onSeeked: handleSeeked,
    onPlay: handlePlay,
    onPause: handlePause,
    onEnd: handleEnd,
    onError: handleError,
    onResetError: handleResetError,
    onReset: handleReset,
    onChangeAudioTracks: handleChangeAudioTracks,
    onChangeSubtitleTracks: handleChangeSubtitleTracks,
  }
}

export default useActions
