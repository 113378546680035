// @flow
import { normalize } from 'normalizr'
import typeToReducer from 'type-to-reducer'

import { isValidTTL, calculateExpires } from '@alphaott/app-main/selectors'
import categoriesListSchema from '@alphaott/common-utils/utils/schemas/categories'

import type { Category } from '@alphaott/api-client/types/categories'

import { FETCH_RADIO_CHANNEL_CATEGORIES } from '../../actions/radioChannelCategories'

export type RadioChannelCategoriesState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Array<Category>,
  +result: Array<string>,
  +expires: ?number,
}

const initialState: RadioChannelCategoriesState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
  result: [],
  expires: null,
}

const radioChannelCategories = typeToReducer(
  {
    [FETCH_RADIO_CHANNEL_CATEGORIES]: {
      LOADING: (state): RadioChannelCategoriesState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): RadioChannelCategoriesState => {
        const {
          meta: { ttl },
        } = action
        const { result } = normalize(action.payload.data, categoriesListSchema)
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
          result,
          ...(isValidTTL(ttl) && { expires: calculateExpires(ttl) }),
        }
      },

      ERROR: (state, action): RadioChannelCategoriesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        expires: null,
      }),
    },
  },
  initialState,
)

export { radioChannelCategories }
