// @flow

export const languages = [
  {
    key: 'ach',
    nativeName: 'Lwo',
  },
  {
    key: 'ady',
    nativeName: 'Адыгэбзэ',
  },
  {
    key: 'af',
    nativeName: 'Afrikaans',
  },
  {
    key: 'af-NA',
    nativeName: 'Afrikaans (Namibia)',
  },
  {
    key: 'af-ZA',
    nativeName: 'Afrikaans (South Africa)',
  },
  {
    key: 'ak',
    nativeName: 'Tɕɥi',
  },
  {
    key: 'ar',
    nativeName: 'العربية',
  },
  {
    key: 'ar-AR',
    nativeName: 'العربية',
  },
  {
    key: 'ar-MA',
    nativeName: 'العربية',
  },
  {
    key: 'ar-SA',
    nativeName: 'العربية (السعودية)',
  },
  {
    key: 'ay-BO',
    nativeName: 'Aymar aru',
  },
  {
    key: 'az',
    nativeName: 'Azərbaycan dili',
  },
  {
    key: 'az-AZ',
    nativeName: 'Azərbaycan dili',
  },
  {
    key: 'be-BY',
    nativeName: 'Беларуская',
  },
  {
    key: 'bg',
    nativeName: 'Български',
  },
  {
    key: 'bg-BG',
    nativeName: 'Български',
  },
  {
    key: 'bn',
    nativeName: 'বাংলা',
  },
  {
    key: 'bn-IN',
    nativeName: 'বাংলা (ভারত)',
  },
  {
    key: 'bn-BD',
    nativeName: 'বাংলা(বাংলাদেশ)',
  },
  {
    key: 'bs-BA',
    nativeName: 'Bosanski',
  },
  {
    key: 'ca',
    nativeName: 'Català',
  },
  {
    key: 'ca-ES',
    nativeName: 'Català',
  },
  {
    key: 'cak',
    nativeName: 'Maya Kaqchikel',
  },
  {
    key: 'ck-US',
    nativeName: 'ᏣᎳᎩ (tsalagi)',
  },
  {
    key: 'cs',
    nativeName: 'Čeština',
  },
  {
    key: 'cs-CZ',
    nativeName: 'Čeština',
  },
  {
    key: 'cy',
    nativeName: 'Cymraeg',
  },
  {
    key: 'cy-GB',
    nativeName: 'Cymraeg',
  },
  {
    key: 'da',
    nativeName: 'Dansk',
  },
  {
    key: 'da-DK',
    nativeName: 'Dansk',
  },
  {
    key: 'de',
    nativeName: 'Deutsch',
  },
  {
    key: 'de-AT',
    nativeName: 'Deutsch (Österreich)',
  },
  {
    key: 'de-DE',
    nativeName: 'Deutsch (Deutschland)',
  },
  {
    key: 'de-CH',
    nativeName: 'Deutsch (Schweiz)',
  },
  {
    key: 'dsb',
    nativeName: 'Dolnoserbšćina',
  },
  {
    key: 'el',
    nativeName: 'Ελληνικά',
  },
  {
    key: 'el-GR',
    nativeName: 'Ελληνικά',
  },
  {
    key: 'en',
    nativeName: 'English',
  },
  {
    key: 'en-GB',
    nativeName: 'English (UK)',
  },
  {
    key: 'en-AU',
    nativeName: 'English (Australia)',
  },
  {
    key: 'en-CA',
    nativeName: 'English (Canada)',
  },
  {
    key: 'en-IE',
    nativeName: 'English (Ireland)',
  },
  {
    key: 'en-IN',
    nativeName: 'English (India)',
  },
  {
    key: 'en-PI',
    nativeName: 'English (Pirate)',
  },
  {
    key: 'en-UD',
    nativeName: 'English (Upside Down)',
  },
  {
    key: 'en-US',
    nativeName: 'English (US)',
  },
  {
    key: 'en-ZA',
    nativeName: 'English (South Africa)',
  },
  {
    key: 'en@pirate',
    nativeName: 'English (Pirate)',
  },
  {
    key: 'eo',
    nativeName: 'Esperanto',
  },
  {
    key: 'eo-EO',
    nativeName: 'Esperanto',
  },
  {
    key: 'es',
    nativeName: 'Español',
  },
  {
    key: 'es-AR',
    nativeName: 'Español (Argentine)',
  },
  {
    key: 'es-419',
    nativeName: 'Español (Latinoamérica)',
  },
  {
    key: 'es-CL',
    nativeName: 'Español (Chile)',
  },
  {
    key: 'es-CO',
    nativeName: 'Español (Colombia)',
  },
  {
    key: 'es-EC',
    nativeName: 'Español (Ecuador)',
  },
  {
    key: 'es-ES',
    nativeName: 'Español (España)',
  },
  {
    key: 'es-LA',
    nativeName: 'Español (Latinoamérica)',
  },
  {
    key: 'es-NI',
    nativeName: 'Español (Nicaragua)',
  },
  {
    key: 'es-MX',
    nativeName: 'Español (México)',
  },
  {
    key: 'es-US',
    nativeName: 'Español (Estados Unidos)',
  },
  {
    key: 'es-VE',
    nativeName: 'Español (Venezuela)',
  },
  {
    key: 'et',
    nativeName: 'eesti keel',
  },
  {
    key: 'et-EE',
    nativeName: 'Eesti (Estonia)',
  },
  {
    key: 'eu',
    nativeName: 'Euskara',
  },
  {
    key: 'eu-ES',
    nativeName: 'Euskara',
  },
  {
    key: 'fa',
    nativeName: 'فارسی',
  },
  {
    key: 'fa-IR',
    nativeName: 'فارسی',
  },
  {
    key: 'fb-LT',
    nativeName: 'Leet Speak',
  },
  {
    key: 'ff',
    nativeName: 'Fulah',
  },
  {
    key: 'fi',
    nativeName: 'Suomi',
  },
  {
    key: 'fi-FI',
    nativeName: 'Suomi',
  },
  {
    key: 'fo-FO',
    nativeName: 'Føroyskt',
  },
  {
    key: 'fr',
    nativeName: 'Français',
  },
  {
    key: 'fr-CA',
    nativeName: 'Français (Canada)',
  },
  {
    key: 'fr-FR',
    nativeName: 'Français (France)',
  },
  {
    key: 'fr-BE',
    nativeName: 'Français (Belgique)',
  },
  {
    key: 'fr-CH',
    nativeName: 'Français (Suisse)',
  },
  {
    key: 'fy-NL',
    nativeName: 'Frysk',
  },
  {
    key: 'ga',
    nativeName: 'Gaeilge',
  },
  {
    key: 'ga-IE',
    nativeName: 'Gaeilge (Gaelic)',
  },
  {
    key: 'gl',
    nativeName: 'Galego',
  },
  {
    key: 'gl-ES',
    nativeName: 'Galego',
  },
  {
    key: 'gn-PY',
    nativeName: "Avañe'ẽ",
  },
  {
    key: 'gu-IN',
    nativeName: 'ગુજરાતી',
  },
  {
    key: 'gx-GR',
    nativeName: 'Ἑλληνική ἀρχαία',
  },
  {
    key: 'he',
    nativeName: 'עברית‏',
  },
  {
    key: 'he-IL',
    nativeName: 'עברית‏',
  },
  {
    key: 'hi',
    nativeName: 'हिन्दी',
  },
  {
    key: 'hi-IN',
    nativeName: 'हिन्दी',
  },
  {
    key: 'hr',
    nativeName: 'Hrvatski',
  },
  {
    key: 'hr-HR',
    nativeName: 'Hrvatski',
  },
  {
    key: 'hsb',
    nativeName: 'Hornjoserbšćina',
  },
  {
    key: 'ht',
    nativeName: 'Kreyòl',
  },
  {
    key: 'hu',
    nativeName: 'Magyar',
  },
  {
    key: 'hu-HU',
    nativeName: 'Magyar',
  },
  {
    key: 'hy-AM',
    nativeName: 'Հայերեն',
  },
  {
    key: 'id',
    nativeName: 'Bahasa Indonesia',
  },
  {
    key: 'id-ID',
    nativeName: 'Bahasa Indonesia',
  },
  {
    key: 'is',
    nativeName: 'Íslenska',
  },
  {
    key: 'is-IS',
    nativeName: 'Íslenska (Iceland)',
  },
  {
    key: 'it',
    nativeName: 'Italiano',
  },
  {
    key: 'it-IT',
    nativeName: 'Italiano',
  },
  {
    key: 'ja',
    nativeName: '日本語',
  },
  {
    key: 'ja-JP',
    nativeName: '日本語',
  },
  {
    key: 'jv-ID',
    nativeName: 'Basa Jawa',
  },
  {
    key: 'ka-GE',
    nativeName: 'ქართული',
  },
  {
    key: 'kk-KZ',
    nativeName: 'Қазақша',
  },
  {
    key: 'km',
    nativeName: 'ភាសាខ្មែរ',
  },
  {
    key: 'km-KH',
    nativeName: 'ភាសាខ្មែរ',
  },
  {
    key: 'kab',
    nativeName: 'Taqbaylit',
  },
  {
    key: 'kn',
    nativeName: 'ಕನ್ನಡ',
  },
  {
    key: 'kn-IN',
    nativeName: 'ಕನ್ನಡ (India)',
  },
  {
    key: 'ko',
    nativeName: '한국어',
  },
  {
    key: 'ko-KR',
    nativeName: '한국어 (韩国)',
  },
  {
    key: 'ku-TR',
    nativeName: 'Kurdî',
  },
  {
    key: 'la',
    nativeName: 'Latin',
  },
  {
    key: 'la-VA',
    nativeName: 'Latin',
  },
  {
    key: 'lb',
    nativeName: 'Lëtzebuergesch',
  },
  {
    key: 'li-NL',
    nativeName: 'Lèmbörgs',
  },
  {
    key: 'lt',
    nativeName: 'Lietuvių',
  },
  {
    key: 'lt-LT',
    nativeName: 'Lietuvių',
  },
  {
    key: 'lv',
    nativeName: 'Latviešu',
  },
  {
    key: 'lv-LV',
    nativeName: 'Latviešu',
  },
  {
    key: 'mai',
    nativeName: 'मैथिली, মৈথিলী',
  },
  {
    key: 'mg-MG',
    nativeName: 'Malagasy',
  },
  {
    key: 'mk',
    nativeName: 'Македонски',
  },
  {
    key: 'mk-MK',
    nativeName: 'Македонски (Македонски)',
  },
  {
    key: 'ml',
    nativeName: 'മലയാളം',
  },
  {
    key: 'ml-IN',
    nativeName: 'മലയാളം',
  },
  {
    key: 'mn-MN',
    nativeName: 'Монгол',
  },
  {
    key: 'mr',
    nativeName: 'मराठी',
  },
  {
    key: 'mr-IN',
    nativeName: 'मराठी',
  },
  {
    key: 'ms',
    nativeName: 'Bahasa Melayu',
  },
  {
    key: 'ms-MY',
    nativeName: 'Bahasa Melayu',
  },
  {
    key: 'mt',
    nativeName: 'Malti',
  },
  {
    key: 'mt-MT',
    nativeName: 'Malti',
  },
  {
    key: 'my',
    nativeName: 'ဗမာစကာ',
  },
  {
    key: 'no',
    nativeName: 'Norsk',
  },
  {
    key: 'nb',
    nativeName: 'Norsk (bokmål)',
  },
  {
    key: 'nb-NO',
    nativeName: 'Norsk (bokmål)',
  },
  {
    key: 'ne',
    nativeName: 'नेपाली',
  },
  {
    key: 'ne-NP',
    nativeName: 'नेपाली',
  },
  {
    key: 'nl',
    nativeName: 'Nederlands',
  },
  {
    key: 'nl-BE',
    nativeName: 'Nederlands (België)',
  },
  {
    key: 'nl-NL',
    nativeName: 'Nederlands (Nederland)',
  },
  {
    key: 'nn-NO',
    nativeName: 'Norsk (nynorsk)',
  },
  {
    key: 'oc',
    nativeName: 'Occitan',
  },
  {
    key: 'or-IN',
    nativeName: 'ଓଡ଼ିଆ',
  },
  {
    key: 'pa',
    nativeName: 'ਪੰਜਾਬੀ',
  },
  {
    key: 'pa-IN',
    nativeName: 'ਪੰਜਾਬੀ (ਭਾਰਤ ਨੂੰ)',
  },
  {
    key: 'pl',
    nativeName: 'Polski',
  },
  {
    key: 'pl-PL',
    nativeName: 'Polski',
  },
  {
    key: 'ps-AF',
    nativeName: 'پښتو',
  },
  {
    key: 'pt',
    nativeName: 'Português',
  },
  {
    key: 'pt-BR',
    nativeName: 'Português (Brasil)',
  },
  {
    key: 'pt-PT',
    nativeName: 'Português (Portugal)',
  },
  {
    key: 'qu-PE',
    nativeName: 'Qhichwa',
  },
  {
    key: 'rm-CH',
    nativeName: 'Rumantsch',
  },
  {
    key: 'ro',
    nativeName: 'Română',
  },
  {
    key: 'ro-RO',
    nativeName: 'Română',
  },
  {
    key: 'ru',
    nativeName: 'Русский',
  },
  {
    key: 'ru-RU',
    nativeName: 'Русский',
  },
  {
    key: 'sa-IN',
    nativeName: 'संस्कृतम्',
  },
  {
    key: 'se-NO',
    nativeName: 'Davvisámegiella',
  },
  {
    key: 'si-LK',
    nativeName: 'පළාත',
  },
  {
    key: 'sk',
    nativeName: 'Slovenčina',
  },
  {
    key: 'sk-SK',
    nativeName: 'Slovenčina (Slovakia)',
  },
  {
    key: 'sl',
    nativeName: 'Slovenščina',
  },
  {
    key: 'sl-SI',
    nativeName: 'Slovenščina',
  },
  {
    key: 'so-SO',
    nativeName: 'Soomaaliga',
  },
  {
    key: 'sq',
    nativeName: 'Shqip',
  },
  {
    key: 'sq-AL',
    nativeName: 'Shqip',
  },
  {
    key: 'sr',
    nativeName: 'Српски',
  },
  {
    key: 'sr-RS',
    nativeName: 'Српски (Serbia)',
  },
  {
    key: 'su',
    nativeName: 'Basa Sunda',
  },
  {
    key: 'sv',
    nativeName: 'Svenska',
  },
  {
    key: 'sv-SE',
    nativeName: 'Svenska',
  },
  {
    key: 'sw',
    nativeName: 'Kiswahili',
  },
  {
    key: 'sw-KE',
    nativeName: 'Kiswahili',
  },
  {
    key: 'ta',
    nativeName: 'தமிழ்',
  },
  {
    key: 'ta-IN',
    nativeName: 'தமிழ்',
  },
  {
    key: 'te',
    nativeName: 'తెలుగు',
  },
  {
    key: 'te-IN',
    nativeName: 'తెలుగు',
  },
  {
    key: 'tg',
    nativeName: 'забо́ни тоҷикӣ́',
  },
  {
    key: 'tg-TJ',
    nativeName: 'тоҷикӣ',
  },
  {
    key: 'th',
    nativeName: 'ภาษาไทย',
  },
  {
    key: 'th-TH',
    nativeName: 'ภาษาไทย (ประเทศไทย)',
  },
  {
    key: 'tl',
    nativeName: 'Filipino',
  },
  {
    key: 'tl-PH',
    nativeName: 'Filipino',
  },
  {
    key: 'tlh',
    nativeName: 'tlhIngan-Hol',
  },
  {
    key: 'tr',
    nativeName: 'Türkçe',
  },
  {
    key: 'tr-TR',
    nativeName: 'Türkçe',
  },
  {
    key: 'tt-RU',
    nativeName: 'татарча',
  },
  {
    key: 'uk',
    nativeName: 'Українська',
  },
  {
    key: 'uk-UA',
    nativeName: 'Українська',
  },
  {
    key: 'ur',
    nativeName: 'اردو',
  },
  {
    key: 'ur-PK',
    nativeName: 'اردو',
  },
  {
    key: 'uz',
    nativeName: "O'zbek",
  },
  {
    key: 'uz-UZ',
    nativeName: "O'zbek",
  },
  {
    key: 'vi',
    nativeName: 'Tiếng Việt',
  },
  {
    key: 'vi-VN',
    nativeName: 'Tiếng Việt',
  },
  {
    key: 'xh-ZA',
    nativeName: 'isiXhosa',
  },
  {
    key: 'yi',
    nativeName: 'ייִדיש',
  },
  {
    key: 'yi-DE',
    nativeName: 'ייִדיש (German)',
  },
  {
    key: 'zh',
    nativeName: '中文',
  },
  {
    key: 'zh-Hans',
    nativeName: '中文简体',
  },
  {
    key: 'zh-Hant',
    nativeName: '中文繁體',
  },
  {
    key: 'zh-CN',
    nativeName: '中文（中国）',
  },
  {
    key: 'zh-HK',
    nativeName: '中文（香港）',
  },
  {
    key: 'zh-SG',
    nativeName: '中文（新加坡）',
  },
  {
    key: 'zh-TW',
    nativeName: '中文（台灣）',
  },
  {
    key: 'zu-ZA',
    nativeName: 'isiZulu',
  },
]
