// @flow
import React, {
  memo,
  forwardRef,
  useMemo,
  useCallback,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useTranslation } from 'react-i18next'
import { equals, isEmpty, or, head, findIndex, propEq, inc } from 'ramda'
import { isNotEmpty, notEqual, ceil } from 'ramda-adjunct'

import {
  useFocusable,
  FocusContext,
  useLastFocus,
  useLastFocusHistory,
  LIST_TYPES,
} from '@alphaott/smart-tv-spatial-navigation'
import { getGridListScrollOffset, scale } from '@alphaott/smart-tv-utils'
import { Header, InnerElementType, EmptyPlaceholder, Grid } from '@alphaott/smart-tv-components'

import ChannelCell, { GUTTER_SIZE } from './Cell'
import { Container, Heading } from './ChannelsGrid.style'
import { CHANNEL_CARD_ITEM_WIDTH, CHANNEL_CARD_ITEM_HEIGHT } from '../ChannelCard'

const listStyle = { overflow: 'hidden' }
const DEFAULT_MARGIN_TOP_FOR_LIST = 64

const rowIndex = (parentHeight: number, itemHeight: number): number => {
  if (equals(parentHeight, 0)) {
    return 0
  }
  return Math.round(parentHeight / itemHeight)
}

export type ChannelsGridInnerProps = {
  withSideMenu?: boolean,
  headerHeight?: number,
  cardPlaceholder?: string,
  items: Array<any>,
  setFocus: Function,
  onPressItem: Function,
  onLongPressItem: Function,
  onChangeCurrentFocusedItem?: Function,
}

const ChannelsGridInnerPure = (
  {
    v2,
    withSideMenu,
    headerHeight,
    cardPlaceholder,
    items,
    setFocus,
    onPressItem,
    onLongPressItem,
    onChangeCurrentFocusedItem,
  }: ChannelsGridInnerProps,
  ref: any,
) => {
  const { itemFocusKey } = useLastFocusHistory({}, 'channels_grid')
  const [listRef, setListRef] = useState(null)

  const firstItem = useMemo(() => head(items), [items])

  useLastFocus(LIST_TYPES.GRID, {
    listRef,
    setFocus,
    defaultFocusKey: firstItem?.id,
    isEnabledScroll: !v2,
  })

  // For the case when switching to other screens and then returning via back
  useEffect(() => {
    if (itemFocusKey) {
      setFocus(itemFocusKey)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleScroll = useCallback(
    // eslint-disable-next-line consistent-return,complexity
    (index) => {
      const scrollTop = getGridListScrollOffset(listRef, index)
      listRef.scrollTo({ scrollTop })
    },
    [listRef],
  )

  const onBecameFocused = useCallback(
    // eslint-disable-next-line complexity
    ({ y, height, itemId }) => {
      const index = rowIndex(y, height)

      if (listRef) {
        handleScroll(index)
      }

      if (onChangeCurrentFocusedItem) {
        onChangeCurrentFocusedItem(itemId)
      }
    },
    [handleScroll, listRef, onChangeCurrentFocusedItem],
  )

  const handleFocus = useCallback(
    (itemId) => {
      const index = findIndex(propEq('id', itemId), items)

      if (notEqual(index, -1)) {
        const { columnCount } = listRef.props

        const indexRow = ceil(inc(index) / columnCount)

        handleScroll(indexRow)
        setFocus(itemId)
      }
    },
    [listRef, items, handleScroll, setFocus],
  )

  useImperativeHandle(
    ref,
    () => ({
      onFocus: handleFocus,
    }),
    [handleFocus],
  )

  const getRowHeight = useCallback(
    () => scale(window.innerWidth, CHANNEL_CARD_ITEM_HEIGHT) + GUTTER_SIZE,
    [],
  )

  const cardItemWidth = useMemo(() => scale(window.innerWidth, CHANNEL_CARD_ITEM_WIDTH), [])

  return (
    <AutoSizer>
      {({ height, width }) => {
        const itemsPerRow = Math.floor(width / cardItemWidth)
        const columnWidth = width / itemsPerRow
        const rowCount = Math.ceil(items.length / itemsPerRow)
        return (
          <Grid
            postfix="channels_grid"
            withLastFocus={v2}
            ref={setListRef}
            columnCount={itemsPerRow}
            rowCount={rowCount}
            columnWidth={() => columnWidth}
            rowHeight={getRowHeight}
            height={height}
            width={width}
            innerElementType={InnerElementType}
            itemData={{
              setFocus,
              items,
              cardPlaceholder,
              onBecameFocused,
              onPressItem,
              onLongPressItem,
              itemsPerRow,
              withSideMenu,
              listRef,
              itemHeight: CHANNEL_CARD_ITEM_HEIGHT,
              getRowIndex: rowIndex,
            }}
            style={{ ...listStyle, marginTop: `${headerHeight}rem` }}
            overscanRowCount={3}
          >
            {ChannelCell}
          </Grid>
        )
      }}
    </AutoSizer>
  )
}

const areEquals = (
  { items: prevItems, onLongPressItem: prevOnLongPressItem },
  { items: nextItems, onLongPressItem: nextOnLongPressItem },
) => equals(prevItems, nextItems) && equals(prevOnLongPressItem, nextOnLongPressItem)

const ChannelsGridInner = memo<ChannelsGridInnerProps>(forwardRef(ChannelsGridInnerPure), areEquals)

export type ChannelsGridProps = {
  withHeader?: boolean,
  withSideMenu?: boolean,
  items: Array<any>,
  heading?: string,
  logoSrc?: string,
  logoHeight?: number,
  cardPlaceholder?: string,
  emptyPlaceholderTitle?: string,
  marginTopList?: number,
  onPressItem: Function,
  onLongPressItem: Function,
  onChangeCurrentFocusedItem?: Function,
}

// eslint-disable-next-line complexity
const ChannelsGridPure = (
  {
    v2,
    withHeader = true,
    withSideMenu = false,
    items = [],
    heading,
    logoSrc,
    logoHeight,
    cardPlaceholder,
    emptyPlaceholderTitle,
    marginTopList = DEFAULT_MARGIN_TOP_FOR_LIST,
    onPressItem,
    onLongPressItem,
    onChangeCurrentFocusedItem,
  }: ChannelsGridProps,
  ref: any,
) => {
  const {
    ref: focusRef,
    setFocus,
    focusKey,
  } = useFocusable({
    autoRestoreFocus: false,
  })
  const { t } = useTranslation()

  const headerHeight = useMemo(() => logoHeight + marginTopList, [logoHeight, marginTopList])

  const preparedEmptyPlaceholderTitle = or(
    emptyPlaceholderTitle,
    t('mwstv_the_channel_list_is_empty'),
  )

  return (
    <FocusContext.Provider value={focusKey}>
      <Container ref={focusRef} headerHeight={headerHeight}>
        {withHeader && (
          <Header logoSrc={logoSrc} logoHeight={logoHeight}>
            <Heading>{heading}</Heading>
          </Header>
        )}

        {isEmpty(items) && <EmptyPlaceholder>{preparedEmptyPlaceholderTitle}</EmptyPlaceholder>}

        {isNotEmpty(items) && (
          <ChannelsGridInner
            v2={v2}
            ref={ref}
            withSideMenu={withSideMenu}
            headerHeight={headerHeight}
            cardPlaceholder={cardPlaceholder}
            items={items}
            setFocus={setFocus}
            onPressItem={onPressItem}
            onLongPressItem={onLongPressItem}
            onChangeCurrentFocusedItem={onChangeCurrentFocusedItem}
          />
        )}
      </Container>
    </FocusContext.Provider>
  )
}

export const ChannelsGrid = memo<ChannelsGridProps>(forwardRef(ChannelsGridPure), areEquals)

export default ChannelsGrid
