// @flow
import typeToReducer from 'type-to-reducer'
import { pathOr } from 'ramda'

import { LOGIN_TYPE, RESET_LOGIN_DATA_TYPE } from '../../actions/login'

export type LoginData = {
  loginMethod?: string,
  notActivatedCustomerEmail?: string,
}

export type LoginState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: LoginData,
}

const initialState: LoginState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: {},
}

const login = typeToReducer(
  {
    [LOGIN_TYPE]: {
      LOADING: (state, action): LoginState => {
        const { loginMethod } = pathOr({}, ['meta', 'data'], action)
        return { ...initialState, data: { loginMethod }, isLoading: true }
      },

      SUCCESS: (state, action): LoginState => {
        const { username, email } = pathOr({}, ['meta', 'data'], action)
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isError: false,
          errors: [],
          data: {
            notActivatedCustomerEmail: username || email,
          },
        }
      },

      ERROR: (state, action): LoginState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error || action.payload.errors, // TODO Refactor
      }),
    },
    [RESET_LOGIN_DATA_TYPE]: () => initialState,
  },
  initialState,
)

export { login }
