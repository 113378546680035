// @flow

import React, { memo, useMemo, useState, useContext, createContext } from 'react'

export type LastFocusContextProps = {
  historyKeys: string[],
  lastFocusHistory: Object,
  actionHistory: Object,
  onChangeHistoryKeys: Function,
  onChangeLastFocusHistory: Function,
  onChangeActionHistory: Function,
}

const defaultValues = {}

export const LastFocusContext = createContext<LastFocusContextProps>(defaultValues)

export const useLastFocusContext = (): LastFocusContextProps =>
  useContext<LastFocusContextProps>(LastFocusContext)

export type LastFocusProviderProps = {}

const LastFocusProviderPure = ({ children }: LastFocusProviderProps) => {
  const [historyKeys, setHistoryKeys] = useState([])
  const [lastFocusHistory, setLastFocusHistory] = useState({})
  // Currently only the last action is saved
  const [actionHistory, setActionHistory] = useState({})

  const value = useMemo(
    () => ({
      historyKeys,
      lastFocusHistory,
      actionHistory,
      onChangeHistoryKeys: setHistoryKeys,
      onChangeLastFocusHistory: setLastFocusHistory,
      onChangeActionHistory: setActionHistory,
    }),
    [
      historyKeys,
      lastFocusHistory,
      actionHistory,
      setHistoryKeys,
      setLastFocusHistory,
      setActionHistory,
    ],
  )

  return <LastFocusContext.Provider value={value}>{children}</LastFocusContext.Provider>
}

export const LastFocusProvider = memo<LastFocusProviderProps>(LastFocusProviderPure)

export default LastFocusProvider
