/* eslint-disable no-use-before-define */
// @flow
import { schema } from 'normalizr'
import { always, cond, map, isEmpty, find, prop, pathOr, pipe, not, isNil, T, join, propEq } from 'ramda'

import type { TVShowDetail, TVShow, TVShowSeasonDetail, TVShowEpisodeDetail } from '@alphaott/api-client/types/tvshows'
import type {
  CategoriesNormalize,
  CountriesNormalize,
  GenresNormalize,
  LanguageNormalize,
} from '@alphaott/common-types/normalized'

import { prepareGenresById } from '../genres'
import { prepareCategoriesById } from '../categories'
import { prepareLanguagesById } from '../languages'
import { prepareCountriesById } from '../countries'
import { defaultToEmptyArray } from '../../help'
import { prepareLogos } from '../../prepareLogos'
import { prepareTrailers } from '../../prepareTrailers'
import { prepareRating } from '../../prepareRating'

export type PreparedTVShow = TVShowDetail & {
  isFavorite?: boolean,
  subtitle?: string,
  maxRating?: number,
}

export type PreparedTVShowSeason = TVShowSeasonDetail & {
  description?: string,
  showTitle?: string,
}

export type PreparedTVShowEpisode = TVShowEpisodeDetail & {
  description?: string,
  originDescription?: string,
}

const idAttribute = '_id'

export const tvshowSchema = new schema.Entity('tvshows', {}, { idAttribute })
export const tvshowListSchema = new schema.Array(tvshowSchema)

const prepareSubtitle = (genres: Array<string>, categories: Array<string>): string =>
  join(', ', [...genres, ...categories])

export const prepareTVShow = (
  tvshow: TVShowDetail,
  normalGenres: GenresNormalize,
  normalCategories: CategoriesNormalize,
  normalLanguages: LanguageNormalize,
  normalCountries: CountriesNormalize,
  favorite: Array<string>,
  maxRating: number,
): PreparedTVShow | {} => {
  if (isEmpty(tvshow)) return {}

  const tvshowId = prop(idAttribute, tvshow)
  const genres = prepareGenresById(tvshow.genres, normalGenres)
  const categories = prepareCategoriesById(tvshow.categories, normalCategories)
  const languages = prepareLanguagesById(tvshow.languages, normalLanguages)
  const countries = prepareCountriesById(tvshow.countries, normalCountries)
  const subtitle = prepareSubtitle(genres, categories)
  const rating = prepareRating(tvshow.rating, maxRating)

  // $FlowFixMe
  return ({
    ...tvshow,
    genres,
    categories,
    languages,
    countries,
    subtitle,
    rating,
    isFavorite: pipe(
      find(propEq('item', tvshowId)),
      isNil,
      not,
    )(favorite),
  }: PreparedTVShow)
}

export const prepareTVShowSeason = (
  tvseason: TVShowSeasonDetail,
  normalGenres: GenresNormalize,
  tvshow: TVShow,
): PreparedTVShowSeason | {} => {
  if (isEmpty(tvseason)) return {}

  const genres = prepareGenresById(tvseason.genres, normalGenres)
  const title = cond([
    [
      (showTitle, seasonTitle) => showTitle.length > 0 && seasonTitle.length > 0,
      (showTitle, seasonTitle) => `${showTitle}. ${seasonTitle}`,
    ],
    [T, (showTitle, seasonTitle) => showTitle || seasonTitle],
  ])(pathOr('', ['title'], tvshow), pathOr('', ['title'], tvseason))

  // $FlowFixMe
  return ({
    ...tvseason,
    backgrounds: cond([
      [seasonBgs => seasonBgs.length > 0, seasonBgs => seasonBgs],
      [(seasonBgs, seasonPosters) => seasonPosters.length > 0, (seasonBgs, seasonPosters) => seasonPosters],
      [T, (seasonBgs, seasonPosters, showPosters) => showPosters],
    ])(pathOr([], ['backgrounds'], tvseason), pathOr([], ['posters'], tvseason), pathOr([], ['posters'], tvshow)),
    genres,
    description: genres.join(', '),
    title,
    episodes: setDefaultBgImageToEpisodes(tvseason, tvshow),
  }: PreparedTVShowSeason)
}

export const prepareTVShowEpisode = (
  episode: TVShowEpisodeDetail,
  normalGenres: GenresNormalize,
): PreparedTVShowEpisode | {} => {
  if (isEmpty(episode)) return {}

  const genres = prepareGenresById(episode.genres, normalGenres)
  const year = pathOr('', ['year'], episode)
  const showAndSeasonTitles = cond([
    [
      (showTitle, seasonTitle) => showTitle.length > 0 && seasonTitle.length > 0,
      (showTitle, seasonTitle) => `${showTitle}: ${seasonTitle}`,
    ],
    [
      (showTitle, seasonTitle) => showTitle.length > 0 || seasonTitle.length > 0,
      (showTitle, seasonTitle) => `${showTitle || seasonTitle}`,
    ],
    [T, () => ''],
  ])(pathOr('', ['parent', 'parent', 'title'], episode), pathOr('', ['parent', 'title'], episode))

  return ({
    ...episode,
    genres,
    description: genres.join(', '),
    originDescription: `${year} ${showAndSeasonTitles}`,
  }: PreparedTVShowEpisode)
}

export const setDefaultBgImageToEpisodes = (season: TVShowSeasonDetail, tvshow: TVShow) =>
  map(
    episode => ({
      ...episode,
      bgImage: pathOr(
        '',
        ['0', 'path'],
        cond([
          [({ epBgs }) => epBgs.length > 0, ({ epBgs }) => epBgs],
          [({ epPosters }) => epPosters.length > 0, ({ epPosters }) => epPosters],
          [({ seasonPosters }) => seasonPosters.length > 0, ({ seasonPosters }) => seasonPosters],
          [({ showPosters }) => showPosters.length > 0, ({ showPosters }) => showPosters],
          [T, always([])],
        ])({
          epBgs: defaultToEmptyArray(episode.backgrounds),
          epPosters: defaultToEmptyArray(episode.posters),
          seasonPosters: defaultToEmptyArray(season.posters),
          showPosters: defaultToEmptyArray(tvshow.posters),
        }),
      ),
    }),
    defaultToEmptyArray(season.episodes),
  )

export const prepareTVShowImages = (tvshow: TVShow | TVShowDetail, domain: string): TVShow => {
  const prepSeasons =
    /* eslint-disable indent */
    // $FlowFixMe
    tvshow.seasons && tvshow.seasons.length > 0
      ? map(
          season => ({
            ...season,
            posters: prepareLogos(season.posters, domain),
            backgrounds: prepareLogos(season.backgrounds, domain),
            screenshots: prepareLogos(season.screenshots, domain),
            trailers: prepareTrailers(season.trailers, domain),
          }),
          tvshow.seasons,
        )
      : undefined

  /* eslint-enable indent */
  return {
    ...tvshow,
    posters: prepareLogos(tvshow.posters, domain),
    backgrounds: prepareLogos(tvshow.backgrounds, domain),
    screenshots: prepareLogos(tvshow.screenshots, domain),
    trailers: prepareTrailers(tvshow.trailers, domain),
    seasons: prepSeasons,
  }
}

export const parseTVShowSeason = (tvseason: TVShowSeasonDetail, domain: string) => ({
  ...tvseason,
  posters: prepareLogos(tvseason.posters, domain),
  backgrounds: prepareLogos(tvseason.backgrounds, domain),
  screenshots: prepareLogos(tvseason.screenshots, domain),
  trailers: prepareTrailers(tvseason.trailers, domain),
  episodes: map(episode => {
    const posters = prepareLogos(episode.posters, domain)
    const backgrounds = prepareLogos(episode.backgrounds, domain)
    const screenshots = prepareLogos(episode.screenshots, domain)
    const trailers = prepareTrailers(episode.trailers, domain)

    return {
      ...episode,
      id: episode._id,
      posters,
      backgrounds,
      screenshots,
      trailers,
      seasonId: tvseason._id,
    }
  }, tvseason.episodes),
})

export const parseTVShowEpisode = (episode: TVShowEpisodeDetail, domain: string) => ({
  ...episode,
  posters: prepareLogos(episode.posters, domain),
  backgrounds: prepareLogos(episode.backgrounds, domain),
  screenshots: prepareLogos(episode.screenshots, domain),
  trailers: prepareTrailers(episode.trailers, domain),
})

export const parseTVShowList = (
  tvshows: { [id: string]: TVShow | TVShowDetail },
  domain: string,
): { [id: string]: TVShow } => {
  if (isNil(tvshows)) return {}
  return map(tvshow => prepareTVShowImages(tvshow, domain))(tvshows)
}

export default tvshowListSchema
