// @flow

import React, { memo } from 'react'

import type { ComponentType } from 'react'

import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'

import { Container, CheckMarkContainer, CheckMark, Label } from './Track.styles'

type TrackProps = {
  focusKey?: string,
  style: any,
  isActive?: boolean,
  label: string,
  onFocus?: Function,
  onClick: Function,
}

const TrackPure = ({ focusKey, style, isActive, label, onFocus, onClick }: TrackProps) => {
  const { ref, focused, focusSelf } = useFocusable({ focusKey, onFocus, onEnterPress: onClick })
  const { handleMouseOver } = useMouseOver(focusSelf)

  return (
    <Container
      ref={ref}
      style={style}
      focused={focused}
      onClick={onClick}
      onMouseOver={handleMouseOver}
    >
      <CheckMarkContainer>{isActive && <CheckMark />}</CheckMarkContainer>

      <Label>{label}</Label>
    </Container>
  )
}

export const Track: ComponentType<TrackProps> = memo<TrackProps>(TrackPure)

export default Track
