// @flow

import React, { memo, useCallback } from 'react'

import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'

import { Container } from './Tab.styles'

type TabProps = {
  className?: string,
  focusKey?: string,
  to: string,
  children: string,
  onFocus: Function,
  onClick: Function,
}

const TabPure = ({ className, focusKey, to, children, onFocus, onClick }: TabProps) => {
  const handleClick = useCallback(() => onClick(to, { replace: true }), [to, onClick])

  const { ref, focused, focusSelf } = useFocusable({ focusKey, onFocus, onEnterPress: handleClick })
  const { handleMouseOver } = useMouseOver(focusSelf)

  return (
    <Container
      end
      className={className}
      ref={ref}
      to={to}
      $focused={focused}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
    >
      {children}
    </Container>
  )
}

export const Tab = memo<TabProps>(TabPure)

export default Tab
