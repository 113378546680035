// @flow

import styled from 'styled-components'

import { TrackList as TrackListBase } from './TrackList'

export const Container = styled.div`
  width: 500rem;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  font-size: 38rem;
  font-weight: 400;
  letter-spacing: 1.05rem;
  color: #fff;
  opacity: 0.8;
  margin: 71rem 0 24rem 32rem;
`

export const TrackList = styled(TrackListBase)``
