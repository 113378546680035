// @flow

import typeToReducer from 'type-to-reducer'
import { normalize } from 'normalizr'

import { isValidTTL, calculateExpires } from '@alphaott/app-main/selectors'
import channelListSchema, { prepareChannelImages } from '@alphaott/common-utils/utils/schemas/channels'
import type { Channel } from '@alphaott/api-client/types/channels'

import { FETCH_CHANNELS_TYPE, FILTER_OPEN_CHANNEL } from '../../actions/channels'

import type { FilterOpenChannelAction } from '../../actions/channels'

export type ChannelsState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +created: ?Date,
  +result: Array<string>,
  +entities: { [string]: Channel },
  +isOpenFilter: boolean,
  +expires: ?number,
}

const initialState: ChannelsState = {
  created: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  result: [],
  entities: {},
  isOpenFilter: false,
  expires: null,
}

const channels = typeToReducer(
  {
    [FETCH_CHANNELS_TYPE]: {
      LOADING: (state): ChannelsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): ChannelsState => {
        const {
          payload,
          meta: { contentApi, ttl },
        } = action
        const { entities, result } = normalize(payload.data, channelListSchema)
        return {
          ...state,
          created: new Date().getTime(),
          isLoading: false,
          isSuccess: true,
          entities: prepareChannelImages(entities.channels, contentApi),
          result,
          ...(isValidTTL(ttl) && { expires: calculateExpires(ttl) }),
        }
      },

      ERROR: (state, action): ChannelsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        expires: null,
      }),
    },
    [FILTER_OPEN_CHANNEL]: (state: ChannelsState, { isOpenFilter }: FilterOpenChannelAction): ChannelsState => ({
      ...state,
      isOpenFilter,
    }),
  },
  initialState,
)

export { channels }
