/* eslint-disable no-undef */
// @flow

import { useCallback, useEffect } from 'react'
import { BUTTON_KEY_NAMES } from '../../const'

// https://developer.samsung.com/smarttv/develop/guides/user-interaction/remote-control.html
export const keyCodes = {
  10009: BUTTON_KEY_NAMES.BACK,
  427: BUTTON_KEY_NAMES.CHANNEL_UP,
  428: BUTTON_KEY_NAMES.CHANNEL_DOWN,
  13: BUTTON_KEY_NAMES.ENTER,
  [`${13}Up`]: BUTTON_KEY_NAMES.ENTER_UP,
  37: BUTTON_KEY_NAMES.ARROW_LEFT,
  38: BUTTON_KEY_NAMES.ARROW_UP,
  39: BUTTON_KEY_NAMES.ARROW_RIGHT,
  40: BUTTON_KEY_NAMES.ARROW_DOWN,
}

export const useInitInput = () => {
  const onKeyDown = useCallback(({ keyCode }) => {
    const handlerName = keyCodes[keyCode]
    if (handlerName) document.dispatchEvent(new CustomEvent(handlerName))
  }, [])

  const onKeyUp = useCallback(({ keyCode }) => {
    const handlerName = keyCodes[`${keyCode}Up`]
    if (handlerName) document.dispatchEvent(new CustomEvent(handlerName))
  }, [])

  const registerKeys = useCallback(() => {
    tizen?.tvinputdevice?.registerKey('ChannelUp')
    tizen?.tvinputdevice?.registerKey('ChannelDown')
  }, [])

  useEffect(() => {
    registerKeys()
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [onKeyDown, registerKeys])

  useEffect(() => {
    registerKeys()
    document.addEventListener('keyup', onKeyUp)
    return () => document.removeEventListener('keyup', onKeyUp)
  }, [onKeyUp, registerKeys])
}
