// @flow
import { makeGetRequest, makePostRequest } from './utils'

import type { ResponseAPI, Headers } from './types/api'
import type { Subscription } from './types/subscriptions'

export const getSubscription = (domain: string, { token }: Headers): Promise<ResponseAPI<Subscription>> =>
  makeGetRequest(`${domain}/client/api/subscriptions`, { headers: { token } })

export const cancelSubscription = (
  domain: string,
  subscriptionId: string,
  cancelAtEnd: boolean,
  { token }: Headers,
): Promise<ResponseAPI<Subscription>> =>
  makePostRequest(`${domain}/omf/api/subscription/${subscriptionId}/cancel?cancelAtEnd=${cancelAtEnd.toString()}`, {
    headers: { token },
    data: {},
  })
