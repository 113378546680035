// @flow
import { normalize } from 'normalizr'
import typeToReducer from 'type-to-reducer'

import { isValidTTL, calculateExpires } from '@alphaott/app-main/selectors'
import categoriesListSchema from '@alphaott/common-utils/utils/schemas/categories'

import type { Genre } from '@alphaott/api-client/types/genres'

import { FETCH_CHANNEL_GENRES } from '../../actions/channelGenres'

export type ChannelGenresState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +result: Array<string>,
  +data: Array<Genre>,
  +expires: ?number,
}

const initialState: ChannelGenresState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
  result: [],
  expires: null,
}

const channelGenres = typeToReducer(
  {
    [FETCH_CHANNEL_GENRES]: {
      LOADING: (state): ChannelGenresState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): ChannelGenresState => {
        const {
          meta: { ttl },
        } = action
        // TODO: think about normalize data
        const { result } = normalize(action.payload.data, categoriesListSchema)
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
          result,
          ...(isValidTTL(ttl) && { expires: calculateExpires(ttl) }),
        }
      },

      ERROR: (state, action): ChannelGenresState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        expires: null,
      }),
    },
  },
  initialState,
)

export { channelGenres }
