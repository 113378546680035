// @flow

import React, { memo } from 'react'

import { LoaderContainer } from './elements'
import Loader from '../Loader'

type PageLoaderInnerProps = {
  isFlexBehavior?: boolean,
}

export const PageLoaderInner = ({ isFlexBehavior }: PageLoaderInnerProps) => (
  <LoaderContainer isFlexBehavior={isFlexBehavior}>
    <Loader />
  </LoaderContainer>
)

type PageLoaderProps = PageLoaderInnerProps & {
  isLoading: boolean,
  children: any,
}

const PageLoaderPure = ({ isLoading, isFlexBehavior = false, children }: PageLoaderProps) => {
  if (isLoading) {
    return <PageLoaderInner isFlexBehavior={isFlexBehavior} />
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export const PageLoader = memo(PageLoaderPure)

export default PageLoader
