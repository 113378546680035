// @flow
import styled from 'styled-components'
import { Field } from 'redux-form'
import { Flex } from '@rebass/grid'

import { Button } from '@alphaott/smart-tv-components'
import {
  getBackgroundColor,
  getErrorColorMain,
  getTextColorPrimary,
} from '@alphaott/smart-tv-themes'

export const EditNameProfileContainer = styled(Flex)`
  flex-direction: column;
  min-height: 900rem;
  height: 100vh;
  width: 100vw;
  background-color: ${getBackgroundColor};
  padding: 148rem 107rem 107rem;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
`

export const Title = styled.div`
  font-weight: 300;
  font-size: 66rem;
  line-height: 79rem;
  margin-top: 66rem;
  color: ${getTextColorPrimary};
  opacity: 0.87;
`

export const EditNameSubtitle = styled.div`
  font-weight: 400;
  font-size: 32rem;
  line-height: 38rem;
  margin-top: 44rem;
  color: ${getTextColorPrimary};
  opacity: 0.7;
`

export const EditNameSmallButton = styled(Button)`
  min-width: 276rem;
  min-height: 54rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 24rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.87);
`

export const EditNameRowBottom = styled.div`
  margin-top: 230rem;
`

export const EditNameField = styled(Field)`
  &::placeholder {
    opacity: 0.6;
  }
`
export const ErrorMessage = styled.div`
  color: ${getErrorColorMain};
  font-size: 20rem;
`

export const FieldContainer = styled.div`
  position: relative;
  margin: 1rem;
`

export const FormContainer = styled(Flex)`
  flex-direction: column;
  margin-top: 40rem;
  min-width: 577rem;
`
