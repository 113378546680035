// @flow
import { useEffect } from 'react'
import { useAnalytics } from 'use-analytics'
import { useSelector } from 'react-redux'
import { and } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'

import { isAppAnalyticsEnabled } from '@alphaott/app-main/selectors'

import { isNotEqual } from '@alphaott/common-utils'

export const useIdentify = (userId: string, traits?: Object = {}) => {
  const analytics = useAnalytics()

  const isAnalyticsEnabled = useSelector(isAppAnalyticsEnabled)

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (isAnalyticsEnabled) {
      if (and(isNotNilOrEmpty(analytics), isNotNilOrEmpty(userId))) {
        const currentId = analytics.user('userId')

        if (isNotEqual(currentId, userId)) {
          analytics.reset()
          analytics.identify(userId, traits)
        }
      }
    }
  }, [analytics, userId]) // eslint-disable-line react-hooks/exhaustive-deps
}
