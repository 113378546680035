// @flow weak
import { createSelector } from 'reselect'
import { path, values, map, keys, mergeAll, prop, pick, pathOr } from 'ramda'
import { format, compareAsc, min, max, eachDay } from 'date-fns'

import { isNotNil } from '@alphaott/common-utils/utils/help'
import { prepareProgramItem } from '@alphaott/epg-utils/usePrograms/programsSelector'
import type { Program, Programs } from '@alphaott/api-client/types/programs'

import { DATE_FORMAT } from '../../const'
import type { ChannelProgramsListState } from './reducers'
import type { TVGridStore } from './types'

export type PreparedPrograms = { items: Programs, date: Date, meta?: any }

export const getTVGrid = (state: TVGridStore): ChannelProgramsListState => state.tvGrid.data
export const getIsLoadingPrograms = (state: TVGridStore): boolean => state.tvGrid.isLoading

const dateFormat = date => format(date, DATE_FORMAT)
const getPrograms = (channelId, date, grid) => path([channelId, dateFormat(date), 'data'], grid)
const getMetaPrograms = (channelId, date, grid) =>
  pick(['isLoading', 'isSuccess', 'isError'], pathOr({}, [channelId, dateFormat(date)], grid))

export const arePrograms = (channelId: string, date: Date) =>
  createSelector(
    [getTVGrid],
    (tvGrid: ChannelProgramsListState) => isNotNil(path([channelId, dateFormat(date)], tvGrid)),
  )

export const getProgramsPerDate = (channelId: string, date: Date) =>
  createSelector(
    [getTVGrid],
    (programGrid: ChannelProgramsListState): PreparedPrograms => {
      const programs = getPrograms(channelId, date, programGrid)

      return {
        date: dateFormat(date),
        items: values(programs),
      }
    },
  )

export const getProgramsPerChannel = (channelId: string) =>
  createSelector(
    [getTVGrid],
    (programGrid: ChannelProgramsListState): Array<PreparedPrograms> => {
      const dates = keys(programGrid[channelId])

      return map(date => {
        const programs = getPrograms(channelId, date, programGrid)
        return {
          date: dateFormat(date),
          items: values(programs),
        }
      }, dates.sort(compareAsc))
    },
  )

export const getProgramsPerChannelByRange = (channelId: string, start: Date | string, stop: Date | string) =>
  createSelector(
    [getTVGrid],
    (programGrid: ChannelProgramsListState): Array<PreparedPrograms> => {
      const dates = eachDay(new Date(start), new Date(stop))

      return map(date => {
        const programs = getPrograms(channelId, date, programGrid)
        return {
          date: dateFormat(date),
          items: values(programs),
          meta: getMetaPrograms(channelId, date, programGrid),
        }
      }, dates.sort(compareAsc))
    },
  )

export const getStartDatePerChannel = (channelId: string) =>
  createSelector(
    [getTVGrid],
    (programGrid: ChannelProgramsListState) => {
      const dates = keys(programGrid[channelId])
      const dateList = map(date => new Date(date), dates)

      return dateFormat(min(...dateList))
    },
  )

export const getEndDatePerChannel = (channelId: string) =>
  createSelector(
    [getTVGrid],
    (programGrid: ChannelProgramsListState) => {
      const dates = keys(programGrid[channelId])
      const dateList = map(date => new Date(date), dates)

      return dateFormat(max(...dateList))
    },
  )

export const getProgram = (channelId: string, programId: string) =>
  createSelector(
    [getTVGrid],
    (programGrid: ChannelProgramsListState): ?Program => {
      // TODO: Refactoring
      const programsState: Array<any> = values(programGrid[channelId])
      const allPrograms = mergeAll(map(prop('data'), programsState))
      const program = allPrograms[programId]
      return program ? prepareProgramItem({}, program) : null
    },
  )
