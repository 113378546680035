// @flow
import { SubmissionError } from 'redux-form'
import { push } from 'redux-first-history'
import { cond, pathOr, T, equals, path } from 'ramda'

import type { LocationShape } from 'react-router-dom'

import { login as signIn, signUp as registration, signUpV2 } from '@alphaott/api-client'
import { is20x, is403, is404, is429, isApiError } from '@alphaott/api-client/utils/makeResponse'
import { CLIENT_ID, CLIENT_SECRET } from '@alphaott/app-config'
import { getPrivateDomain } from '@alphaott/app-main/selectors'
import { getCurrentDevice } from '@alphaott/app-devices/selectors'
import { getLoginError } from '@alphaott/app-core/selectors'
import { GUEST } from '../../constants'

import { getAuthRedirectPathAfterGuestLoginError } from '../../selectors'
import { saveToken } from '../token'

export const LOGIN_TYPE = 'authorization/LOGIN'
export const RESET_LOGIN_DATA_TYPE = 'authorization/RESET_LOGIN_DATA'

type Location = string | LocationShape

// TODO: think about callback and firebaseCallback

// eslint-disable-next-line complexity
const loginPasswordCallback = (request: Function) => async (
  data: any,
  redirect: Location,
  device,
  dispatch: Function,
  privateApi: string,
  grandType: ?string,
  getState: Function,
) => {
  try {
    const opts = {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      device,
      ...data,
    }
    grandType && (opts.grant_type = grandType)
    // TODO: https://github.com/facebook/flow/issues/3461
    // const resp: ResponseAPI<Token>
    const resp: Object = await request(privateApi, opts)

    if (is20x(resp)) {
      await dispatch(saveToken(resp.data))

      if (redirect) {
        await dispatch(push(redirect))
      }
    }

    return resp
  } catch (err) {
    if (equals(grandType, 'guest')) {
      // prettier-ignore
      cond([
        [is403, () => {
          const authRedirectPath = getAuthRedirectPathAfterGuestLoginError(getState())
          dispatch(push(authRedirectPath))
        }],
        [T, e => { throw e }],
      ])(err)
    }

    // Todo: Need to refactor this
    if (equals(grandType, 'otp')) {
      // prettier-ignore
      cond([
        [is404, e => {
          dispatch(push('/auth/login/otp/signup'))
          throw e
        }],
        [is429, e => {
          dispatch(push('/auth/login/otp'))
          throw e
        }],
      ])(err)
    }

    if (err.status === -1) {
      throw new SubmissionError({ _error: err.error })
    }

    if (isApiError(err)) {
      const message = pathOr('Server error', ['error', 'message'], err)
      throw new SubmissionError({ ...err.error, _error: message })
    }

    return err
  }
}

const firebaseCallback = async (data: any, redirect: string, device, dispatch: Function, privateApi: string) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const opts = {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: 'firebase',
      device,
      ...data,
    }

    // TODO: https://github.com/facebook/flow/issues/3461
    // const resp: ResponseAPI<Token>
    const resp: Object = await signIn(privateApi, opts)

    if (is20x(resp)) {
      dispatch(saveToken(resp.data))
      dispatch(push(redirect))
    }
    return resp
  } catch (err) {
    throw err
  }
}

export const login = (data: any, redirect?: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: LOGIN_TYPE,
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    payload: loginPasswordCallback(signIn)(
      data,
      redirect,
      getCurrentDevice(getState()),
      dispatch,
      getPrivateDomain(getState()),
      'password',
    ),
    meta: { data, redirect },
  })

export const signUp = (data: any, redirect: Location) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: LOGIN_TYPE,
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    payload: loginPasswordCallback(registration)(
      data,
      redirect,
      getCurrentDevice(getState()),
      dispatch,
      getPrivateDomain(getState()),
    ),
    meta: { data, redirect },
  })

export const signUpOtp = (data: any, redirect: Location) => (dispatch: Function, getState: Function) => {
  const loginError = getLoginError(getState())
  const tokenType = path(['details', 'token_type'], loginError)
  const accessToken = path(['details', 'access_token'], loginError)
  const token = `${tokenType} ${accessToken}`
  const preparedData = { ...data, token }

  dispatch({
    type: LOGIN_TYPE,
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    payload: loginPasswordCallback(signUpV2)(
      preparedData,
      redirect,
      getCurrentDevice(getState()),
      dispatch,
      getPrivateDomain(getState()),
    ),
    meta: { data, redirect },
  })
}

export const voucherLogin = (data: any, redirect: Location) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: LOGIN_TYPE,
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    payload: loginPasswordCallback(signIn)(
      {
        user: {
          // TODO: Need more research
          email: '',
          phone: '',
        },
        ...data,
      },
      redirect,
      getCurrentDevice(getState()),
      dispatch,
      getPrivateDomain(getState()),
      'voucher',
    ),
    meta: { data, redirect },
  })

export const firebaseLogin = (data: any, redirect: string) => (dispatch: Function, getState: Function) => {
  dispatch({
    // https://github.com/pitzcarraldo/reduxible/issues/8
    type: LOGIN_TYPE,
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    payload: firebaseCallback(data, redirect, getCurrentDevice(getState()), dispatch, getPrivateDomain(getState())),
    meta: { data, redirect },
  })
}

export const guestLogin = (redirect: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: LOGIN_TYPE,
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    payload: loginPasswordCallback(signIn)(
      {},
      redirect,
      getCurrentDevice(getState()),
      dispatch,
      getPrivateDomain(getState()),
      'guest',
      getState,
    ),
    meta: { data: { username: '', loginMethod: GUEST }, redirect },
  })

export const resetLoginData = (dispatch: Function) =>
  dispatch({
    type: RESET_LOGIN_DATA_TYPE,
  })

export const loginOTP = (data: any, redirect: Location) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: LOGIN_TYPE,
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    payload: loginPasswordCallback(signIn)(
      data,
      redirect,
      getCurrentDevice(getState()),
      dispatch,
      getPrivateDomain(getState()),
      'otp',
    ),
    meta: { data, redirect },
  })

export default login
