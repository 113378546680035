// @flow

import { cond, T, not, reject, isEmpty, path } from 'ramda'
import { push } from 'redux-first-history'

import { is40x } from '@alphaott/api-client/utils/makeResponse'
import { isNotActiveCustomerError, isGuestExpiredError } from '@alphaott/api-client/errors'
import { isNotEmpty } from '@alphaott/common-utils/utils/help'
import { logout, resetLoginData } from '@alphaott/app-auth/actions'
import { GET_CONTENT_INTERVAL } from '@alphaott/app-config'
import { getCacheAppInit, isCacheValid } from '@alphaott/app-main/models/selectors'

import {
  fetchChannels,
  fetchChannelCategories,
  fetchChannelLanguages,
  fetchChannelGenres,
  fetchChannelCountries,
  fetchFavoriteChannels,
  getRecentWatchedChannel,
} from '@alphaott/app-channels/actions'

import {
  fetchRadioChannels,
  fetchRadioChannelCategories,
  fetchRadioChannelLanguages,
  fetchRadioChannelGenres,
  fetchRadioChannelCountries,
  fetchFavoriteRadioChannels,
  fetchRecentPlayedRadioChannels,
} from '@alphaott/app-radio-channels/actions'

import {
  fetchMovieGenres,
  fetchMovieCategories,
  fetchMovieLanguages,
  fetchMovieCountries,
  fetchPopularMovies,
  fetchFavoriteMovies,
  fetchRecentMovie,
} from '@alphaott/app-movies/actions'

import {
  fetchTVShowGenres,
  fetchTVShowCategories,
  fetchTVShowLanguages,
  fetchTVShowCountries,
  fetchTopTVShows,
  fetchRecentTVShows,
  fetchFavoriteTVShows,
} from '@alphaott/app-tvshows/actions'

import { fetchCatchupChannels } from '@alphaott/app-catchup/actions'
import { fetchPromos } from '@alphaott/app-promo/actions'

import { fetchAppCountries } from '../appCountries'
import { fetchAppLanguages } from '../appLanguages'
import { fetchCustomer } from '../appCustomer'
import { fetchSubscription } from '../appSubscriptions'

import { resetGuestAccessTTL } from '../appGuestAccess'

import {
  getServiceLiveTvIds,
  getServiceRadioIds,
  getServiceVodIds,
  getActiveServiceIds,
  isAvailableCatchup,
  getServiceTVShowsVodIds,
  getCustomerGuestMode,
} from '../../selectors'

import { changeProfileLoadListsIntervalId } from './loadPersonalContentInterval'
import { prepareServicesIds } from '../../utils'

export const INIT_APP_TYPE = 'app/INIT_APP'

/*
2. Fetch customer & serviceProvider
3. Fetch content by customer.services
*/

// eslint-disable-next-line consistent-return, complexity
const init = async (dispatch, getState) => {
  try {
    await Promise.all([dispatch(fetchCustomer), dispatch(fetchAppCountries), dispatch(fetchAppLanguages)])

    const state = getState()
    const isCustomerGuestMode = getCustomerGuestMode(state)

    if (not(isCustomerGuestMode)) {
      await dispatch(fetchSubscription)
    }

    const activeServiceIds = getActiveServiceIds(state)
    const serviceIdLiveTv = prepareServicesIds(getServiceLiveTvIds)(state)
    const serviceIdLiveRadio = prepareServicesIds(getServiceRadioIds)(state)
    const serviceIdLiveVod = prepareServicesIds(getServiceVodIds)(state)
    const serviceIdLiveTVShow = prepareServicesIds(getServiceTVShowsVodIds)(state)
    const promises = []
    activeServiceIds && promises.push(dispatch(fetchPromos(activeServiceIds)))

    if (isNotEmpty(serviceIdLiveTv)) {
      promises.push(
        dispatch(fetchChannels(serviceIdLiveTv)),
        dispatch(fetchChannelCategories(serviceIdLiveTv)),
        dispatch(fetchChannelLanguages(serviceIdLiveTv)),
        dispatch(fetchChannelGenres(serviceIdLiveTv)),
        dispatch(fetchChannelCountries(serviceIdLiveTv)),
      )

      if (isAvailableCatchup(state)) {
        promises.push(dispatch(fetchCatchupChannels))
      }

      if (not(isCustomerGuestMode)) {
        promises.push(dispatch(fetchFavoriteChannels), dispatch(getRecentWatchedChannel))
      }
    }

    if (isNotEmpty(serviceIdLiveRadio)) {
      promises.push(
        dispatch(fetchRadioChannels(serviceIdLiveRadio)),
        dispatch(fetchRadioChannelCategories(serviceIdLiveRadio)),
        dispatch(fetchRadioChannelLanguages(serviceIdLiveRadio)),
        dispatch(fetchRadioChannelGenres(serviceIdLiveRadio)),
        dispatch(fetchRadioChannelCountries(serviceIdLiveRadio)),
      )

      if (not(isCustomerGuestMode)) {
        promises.push(dispatch(fetchFavoriteRadioChannels), dispatch(fetchRecentPlayedRadioChannels))
      }
    }

    if (isNotEmpty(serviceIdLiveVod)) {
      promises.push(
        dispatch(fetchMovieGenres(serviceIdLiveVod)),
        dispatch(fetchMovieCategories(serviceIdLiveVod)),
        dispatch(fetchMovieLanguages(serviceIdLiveVod)),
        dispatch(fetchMovieCountries(serviceIdLiveVod)),
        dispatch(fetchPopularMovies(serviceIdLiveVod)),
      )

      if (not(isCustomerGuestMode)) {
        promises.push(dispatch(fetchFavoriteMovies), dispatch(fetchRecentMovie()))
      }
    }

    if (isNotEmpty(serviceIdLiveTVShow)) {
      promises.push(
        dispatch(fetchTVShowGenres(serviceIdLiveTVShow)),
        dispatch(fetchTVShowCategories(serviceIdLiveTVShow)),
        dispatch(fetchTVShowLanguages(serviceIdLiveTVShow)),
        dispatch(fetchTVShowCountries(serviceIdLiveTVShow)),
        dispatch(fetchTopTVShows()),
      )

      if (not(isCustomerGuestMode)) {
        promises.push(dispatch(fetchFavoriteTVShows), dispatch(fetchRecentTVShows()))
      }
    }

    const isNotEmptyServices = isNotEmpty(
      reject(isEmpty, [serviceIdLiveTv, serviceIdLiveRadio, serviceIdLiveVod, serviceIdLiveTVShow]),
    )

    if (isNotEmptyServices) {
      // eslint-disable-next-line complexity
      const loadPersonalContent = () => {
        if (not(isCustomerGuestMode)) {
          if (isNotEmpty(serviceIdLiveTv)) {
            promises.push(dispatch(fetchFavoriteChannels), dispatch(getRecentWatchedChannel))
          }

          if (isNotEmpty(serviceIdLiveRadio)) {
            promises.push(dispatch(fetchFavoriteRadioChannels), dispatch(fetchRecentPlayedRadioChannels))
          }

          if (isNotEmpty(serviceIdLiveVod)) {
            promises.push(dispatch(fetchFavoriteMovies), dispatch(fetchRecentMovie()))
          }

          if (isNotEmpty(serviceIdLiveTVShow)) {
            promises.push(dispatch(fetchFavoriteTVShows), dispatch(fetchRecentTVShows()))
          }
        }
      }

      const intervalId = setInterval(loadPersonalContent, GET_CONTENT_INTERVAL)
      dispatch(changeProfileLoadListsIntervalId(intervalId))
    }

    return Promise.all(promises)
  } catch (err) {
    // prettier-ignore
    cond([
      [isGuestExpiredError, () => dispatch(resetGuestAccessTTL)],
      [isNotActiveCustomerError, () => dispatch(push('/auth/verify-your-email'))],
      [is40x, () => dispatch(logout)],
      [T, e => { throw e }],
    ])(err)
  }
}

export const initAppAction = () => (dispatch: Function, getState: Function) => {
  const state = getState()
  const ttl = getCacheAppInit(state)

  dispatch(resetLoginData)

  return dispatch({
    type: INIT_APP_TYPE,
    payload: Promise.all([init(dispatch, getState)]),
    meta: {
      ttl,
    },
  })
}

// eslint-disable-next-line complexity
export const initApp = (force?: boolean = false) => (dispatch: Function, getState: Function) => {
  if (force) return dispatch(initAppAction())

  const state = getState()
  const expires = path(['app', 'expires'], state)

  if (expires && isCacheValid(expires)) return null

  return dispatch(initAppAction())
}
