// @flow
import { getLimits } from '@alphaott/api-client'
import { limitLogoutWrapper } from '@alphaott/app-main/utils'

export const GET_MOVIE_LIMITS = 'movies/GET_LIMITS'
export const RESET_MOVIE_LIMITS = 'movies/RESET_LIMITS'

export const getMovieLimits = (contentId: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: GET_MOVIE_LIMITS,
    payload: limitLogoutWrapper(getLimits)(contentId, 'vod')(dispatch, getState),
  })

export const resetMovieLimits = {
  type: RESET_MOVIE_LIMITS,
}
