// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { or } from 'ramda'

import { getColorPrimary } from '@alphaott/smart-tv-themes'

export const ITEM_HEIGHT = 88

const getButtonBackgroundColor = ({ focused, isActive }) =>
  or(focused, isActive) ? getColorPrimary : '#1D1D1D'

export const Container = styled.div`
  position: relative;
`

export const Label = styled.div`
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translate(0, -50%);
  color: #fff;
  font-size: 38rem;
  font-weight: 400;
  letter-spacing: 1.05rem;
  margin: 0 16rem;
`

export const Button = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${ITEM_HEIGHT}rem;
  height: ${ITEM_HEIGHT}rem;
  border-radius: 50%;
  margin-top: 16rem;
  background-color: ${getButtonBackgroundColor};
  opacity: ${({ focused }) => (focused ? 1 : 0.8)};
  cursor: pointer;
`
