// @flow
import { startOfYesterday, startOfTomorrow, format } from 'date-fns'

import { getChannelsPrograms } from '@alphaott/api-client/channels/programs'
import { getPublicDomain } from '@alphaott/app-main/selectors'

import { DATE_FORMAT } from '../../../const'

export const FETCH_PROGRAMS_BY_RANGE_TYPE = 'program/FETCH_PROGRAM_BY_RANGE'
export const FETCH_PROGRAMS_BY_DATE_TYPE = 'program/FETCH_PROGRAM_BY_DATE'

export const SHOW_PROGRAM_MODAL_TYPE = 'program/SHOW_MODAL'
export const HIDE_PROGRAM_MODAL_TYPE = 'program/HIDE_MODAL'

export const SWITCH_PROGRAM_SEGMENT_TYPE = 'program/SWITCH_SEGMENT'

export const UPDATE_PROGRAM_CURRENT_TYPE = 'program/CURRENT_PROGRAM'

export const fetchProgramsByRange = (
  id: string,
  offset?: number,
  startDate?: number | string = startOfYesterday(),
  endDate?: number | string = startOfTomorrow(),
  include?: boolean,
) => (dispatch: Function, getState: Function) => {
  const start = format(startDate, DATE_FORMAT)
  const end = format(endDate, DATE_FORMAT)
  return dispatch({
    type: FETCH_PROGRAMS_BY_RANGE_TYPE,
    payload: getChannelsPrograms(getPublicDomain(getState()), id, start, end, offset, include),
    meta: { id },
  })
}

export const showModal = (index: number) => ({
  type: SHOW_PROGRAM_MODAL_TYPE,
  index,
})

export const hideModal = {
  type: HIDE_PROGRAM_MODAL_TYPE,
}

export const switchSegment = {
  type: SWITCH_PROGRAM_SEGMENT_TYPE,
}

export const updateCurrentEpg = (id: string) => ({
  type: UPDATE_PROGRAM_CURRENT_TYPE,
  id,
})
