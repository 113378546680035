// @flow
import { stringify } from 'qs'
import {
  filter,
  sort,
  lte,
  map,
  addIndex,
  cond,
  T,
  equals,
  indexOf,
  prop,
  intersection,
  and,
  length,
  or,
  gt,
  propEq,
} from 'ramda'
import { isEmptyArray, defaultWhen, isNotArray, isNotPlainObj } from 'ramda-adjunct'

import type { MoviesListQuery } from '@alphaott/api-client/movies'
import type { UICatalogContentComposition } from '@alphaott/api-client/types/config'
import { CATALOG_CONTENT_MERGE_RULE, CATALOG_SECTION_TYPE, CATALOG_CONTENT_GROUP_TYPE } from '@alphaott/app-config'

import { ENTITY_TYPE } from '../const'

export const mapIndexed = addIndex(map)

export const defaultToEmptyObject = (value: any) => defaultWhen(isNotPlainObj, {}, value)
export const defaultToEmptyArray = (value: any) => defaultWhen(isNotArray, [], value)

export const generateStoreKey = (param: MoviesListQuery | {} = {}): string => {
  const storeKeyObj = {
    ...param,
    skip: undefined,
    limit: undefined,
  }
  return stringify(storeKeyObj, { sort: (a, b) => a.localeCompare(b) })
}

export const getTopByTags = (items: Object = [], limit: number, tags: Array<string> = []) => {
  const hasCommonTags = item =>
    or(
      isEmptyArray(tags),
      gt(length(intersection(defaultToEmptyArray(prop('tags', item)), defaultToEmptyArray(tags))), 0),
    )
  const isOrderWithinLimit = item => lte(prop('order', item), or(limit, Infinity))

  const isAvailableItem = item => and(isOrderWithinLimit(item), hasCommonTags(item))

  const availableItems = filter(isAvailableItem, items)

  return sort((a, b) => a.order - b.order, availableItems)
}

const getSortAlternate = (sortByIndex, sortByType, sortByTypeOrder = [], sections = []) =>
  sort(
    (a, b) =>
      prop(sortByIndex, a) - prop(sortByIndex, b) ||
      indexOf(prop(sortByType, a), sortByTypeOrder) - indexOf(prop(sortByType, b), sortByTypeOrder),
    sections,
  )

const getSortConcatenate = (sortByIndex, sortByType, sortByTypeOrder = [], sections = []) =>
  sort(
    (a, b) =>
      indexOf(prop(sortByType, a), sortByTypeOrder) - indexOf(prop(sortByType, b), sortByTypeOrder) ||
      prop(sortByIndex, a) - prop(sortByIndex, b),
    sections,
  )

export const getSortedByGroupTypeSections = (sections: any, contentComposition: UICatalogContentComposition) => {
  const { mergeRule, groupType } = contentComposition
  const sortedSections = cond([
    [
      equals(CATALOG_CONTENT_MERGE_RULE.ALTERNATE),
      () => getSortAlternate('index', 'contentGroupType', groupType, sections),
    ],
    [
      equals(CATALOG_CONTENT_MERGE_RULE.CONCATENATE),
      () => getSortConcatenate('index', 'contentGroupType', groupType, sections),
    ],
    [T, () => getSortAlternate('index', 'contentGroupType', groupType, sections)],
  ])(mergeRule)

  return mapIndexed((section, index) => ({ ...section, contentTypeIndex: index }), sortedSections)
}

export const getSortedSections = (
  channelSections: any,
  radioSections: any,
  movieSections: any,
  tvshowSections: any,
  contentComposition: UICatalogContentComposition,
) => {
  const { contentType } = contentComposition
  const sortedChannelSections = getSortedByGroupTypeSections(channelSections, contentComposition)
  const sortedRadioSections = getSortedByGroupTypeSections(radioSections, contentComposition)
  const sortedMovieSections = getSortedByGroupTypeSections(movieSections, contentComposition)
  const sortedTVShowSections = getSortedByGroupTypeSections(tvshowSections, contentComposition)
  return getSortAlternate('contentTypeIndex', 'contentType', contentType, [
    ...sortedChannelSections,
    ...sortedRadioSections,
    ...sortedMovieSections,
    ...sortedTVShowSections,
  ])
}

export const prepareSectionLiveTVItem = ({
  heading,
  params,
  contentType,
  contentGroupType,
  index,
  sectionType,
  id,
}: any) => ({
  id: id || `${ENTITY_TYPE.LIVE_TV}:${sectionType}`,
  type: ENTITY_TYPE.LIVE_TV,
  sectionType,
  heading,
  params,
  contentType,
  contentGroupType,
  index,
})

export const prepareSectionRadioItem = ({
  heading,
  params,
  contentType,
  contentGroupType,
  index,
  sectionType,
  id,
}: any) => ({
  id: id || `${ENTITY_TYPE.RADIO}:${sectionType}`,
  type: ENTITY_TYPE.RADIO,
  sectionType,
  heading,
  params,
  contentType,
  contentGroupType,
  index,
})

export const prepareMovieItem = ({
  heading,
  storeKey,
  params,
  contentType,
  contentGroupType,
  index,
  sectionType,
  id,
}: any) => ({
  id: id || `${ENTITY_TYPE.MOVIE}:${sectionType}`,
  type: ENTITY_TYPE.MOVIE,
  sectionType,
  heading,
  storeKey,
  params,
  contentType,
  contentGroupType,
  index,
})

export const prepareTVShowItem = ({
  heading,
  storeKey,
  params,
  contentType,
  contentGroupType,
  index,
  sectionType,
  id,
}: any) => ({
  id: id || `${ENTITY_TYPE.TV_SHOW}:${sectionType}`,
  type: ENTITY_TYPE.TV_SHOW,
  sectionType,
  heading,
  storeKey,
  params,
  contentType,
  contentGroupType,
  index,
})

export const isLiveTV = propEq('type', ENTITY_TYPE.LIVE_TV)
export const isRadio = propEq('type', ENTITY_TYPE.RADIO)
export const isMovie = propEq('type', ENTITY_TYPE.MOVIE)
export const isTVshow = propEq('type', ENTITY_TYPE.TV_SHOW)

export const isCategory = propEq('contentGroupType', CATALOG_CONTENT_GROUP_TYPE.CATEGORY)
export const isGenre = propEq('contentGroupType', CATALOG_CONTENT_GROUP_TYPE.GENRE)

export const isPopular = propEq('sectionType', CATALOG_SECTION_TYPE.POPULAR)
export const isMostWatched = propEq('sectionType', CATALOG_SECTION_TYPE.MOST_WATCHED)
export const isMostPlayed = propEq('sectionType', CATALOG_SECTION_TYPE.MOST_PLAYED)
export const isSuggested = propEq('sectionType', CATALOG_SECTION_TYPE.SUGGESTED)
export const isFavorite = propEq('sectionType', CATALOG_SECTION_TYPE.FAVORITE)
export const isRecent = propEq('sectionType', CATALOG_SECTION_TYPE.RECENT)
export const isAll = propEq('sectionType', CATALOG_SECTION_TYPE.ALL)
export const isNew = propEq('sectionType', CATALOG_SECTION_TYPE.NEW)
export const isGroups = propEq('sectionType', CATALOG_SECTION_TYPE.GROUPS)
export const isLatestAdditions = propEq('sectionType', CATALOG_SECTION_TYPE.LATEST_ADDITIONS)
