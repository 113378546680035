// @flow

import { useCallback, useMemo } from 'react'
import { useThrottledCallback } from 'use-debounce'

import { makeGetRequest } from '@alphaott/api-client/utils'
import { is403 } from '@alphaott/api-client/utils/makeResponse'

const ERROR_HANDLING_TIMEOUT = 10000

export const useVideoJSPlayerErrors = (sources, onError) => {
  const source = useMemo(() => sources?.[0]?.src, [sources])

  const handleSendRequest = useCallback(async (url) => {
    try {
      return await makeGetRequest(url)
    } catch (err) {
      return err
    }
  }, [])

  const handleError = useCallback(
    // eslint-disable-next-line consistent-return,complexity
    async (error) => {
      if (source) {
        const response = await handleSendRequest(source)

        if (onError) {
          onError({
            ...error,
            ...(is403(response) && {
              status: {
                code: 403,
              },
            }),
            sentry: {
              ...error,
              source,
              player: 'VideoJS',
            },
          })
        }
      }
    },
    [source, handleSendRequest, onError],
  )

  const handleThrottledError = useThrottledCallback(handleError, ERROR_HANDLING_TIMEOUT, {
    trailing: true,
  })

  return handleThrottledError
}

export default useVideoJSPlayerErrors
