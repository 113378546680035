// @flow

import typeToReducer from 'type-to-reducer'

import {
  CHANGE_PLAYER_STATE_TYPE,
  CLEAR_PLAYER_ERRORS_TYPE,
  RESET_PLAYER_STATE_TYPE,
} from '../actions/constants'

export type PlayerState = {
  +isPlayerReady: boolean,
  +isLoading: boolean,
  +isPaused: boolean,
  +isSeeking: boolean,
  +errorMessage: any,
  +criticalErrorMessage: any,
}

const initialState: PlayerState = {
  isPlayerReady: false,
  isLoading: false,
  isPaused: true,
  isSeeking: false,
  errorMessage: null,
  criticalErrorMessage: null,
}

const playerState = typeToReducer(
  {
    [CHANGE_PLAYER_STATE_TYPE]: (state, action): PlayerState => ({
      ...state,
      ...action.payload,
    }),
    [CLEAR_PLAYER_ERRORS_TYPE]: (state): PlayerState => ({
      ...state,
      errorMessage: null,
      criticalErrorMessage: null,
    }),
    [RESET_PLAYER_STATE_TYPE]: () => initialState,
  },
  initialState,
)

export { playerState }
