// @flow
import typeToReducer from 'type-to-reducer'
import type { CatchupChannels } from '@alphaott/api-client/types/catchup/channels'

import { FETCH_CATCHUP_CHANNELS_TYPE } from '../actions'

export type CatchupChannelsState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: CatchupChannels,
}

export const catchupChannelInitState: CatchupChannelsState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: {},
}

export const catchupChannels = typeToReducer(
  {
    [FETCH_CATCHUP_CHANNELS_TYPE]: {
      LOADING: (state): CatchupChannelsState => ({
        ...state,
        isLoading: true,
        isError: false,
        errors: {},
      }),

      SUCCESS: (state, action): CatchupChannelsState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: { ...state.data, ...action.payload.data },
        errors: {},
        isError: false,
      }),

      ERROR: (state, action): CatchupChannelsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
  },
  catchupChannelInitState,
)

export default catchupChannels
