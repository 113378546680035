// @flow
import { LARGE_VIEWPORT, MEDIUM_VIEWPORT, SMALL_VIEWPORT } from '@alphaott/app-config'
import { rgba } from 'polished'

import { colorPalette } from '../colorPalettes'
import type { ColorType } from '../colorPalettes'

export const kapangusColors: ColorType = { ...colorPalette, primary: '#cccccc', secondary: '#FFFFFF' }

export const lightTheme = {
  colors: kapangusColors,
  background: {
    primary: '#fafafa',
    secondary: '#fafafa',
    highlight: kapangusColors.light,
    select: '#fafafa',
    footerTop: '#eee',
  },
  text: {
    primary: kapangusColors.dark,
    secondary: kapangusColors.grayText,
    disabled: kapangusColors.dark,
    link: kapangusColors.dark,
    hint: kapangusColors.dark,
    highlight: kapangusColors.secondary,
    gray: kapangusColors.grayText,
    seeAll: 'rgba(52, 73, 94, 0.8)',
    primaryContrast: '#000000',
  },
  border: {
    primary: '#dddddd',
    gray: '#ddd',
  },
  filter: {
    primary: '#43484d',
    secondary: '#43484d',
    disabled: kapangusColors.grayLabel,
    highlight: '#fff',
  },
  tabs: {
    bg: '#fafafa',
    containerBg: kapangusColors.dark,
    color: kapangusColors.dark,
    border: kapangusColors.primary,
    scrollbarThumb: kapangusColors.primary,
    programTitle: kapangusColors.white,
  },
  programGuide: {
    programBox: {
      background: {
        main: 'rgba(221, 221, 221, 0.8)',
        hover: kapangusColors.dark,
        selected: kapangusColors.dark,
        progress: rgba(kapangusColors.primary, 0.3),
        past: rgba(kapangusColors.primary, 0.3),
        pastSelected: kapangusColors.dark,
        pastHover: kapangusColors.dark,
        empty: 'rgba(221, 221, 221, 0.3)',
        emptyProgress: rgba(kapangusColors.primary, 0.1),
      },
      text: {
        empty: kapangusColors.grayLabel,
      },
    },
    programGuidePictureOverlay: {
      horizontal:
        // eslint-disable-next-line max-len
        `linear-gradient(90deg, #fafafa 0%,${rgba('#fafafa', 0.97)} 13.03%,${rgba('#fafafa', 0.69)} 43.24%,${rgba(
          '#fafafa',
          0,
        )} 100%);`,
      vertical: `linear-gradient(0,#fafafa 0%,${rgba('#fafafa', 0.73)} 34.97%,${rgba('#fafafa', 0)} 100%);`,
    },
  },
  card: {
    title: kapangusColors.dark,
    subTitle: kapangusColors.grayText,
    bg: kapangusColors.dark,
    number: kapangusColors.grayBorder,
    boxShadow: 'rgba(221, 221, 221, 0.8)',
    loaderBackgroundImage: 'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
  },
  dimension: {},
  size: 'default',
  breakpoints: [`${SMALL_VIEWPORT}px`, `${MEDIUM_VIEWPORT}px`, `${LARGE_VIEWPORT}px`],
  signIn: {
    clipPath: false,
  },
}
