// @flow
import { isNil } from 'ramda'

import { defaultToEmptyString } from '@alphaott/common-utils'

import { makeGetRequest } from '../utils'

import type { ResponseAPI } from '../types/api'
import type { Country, CountryType } from '../types/countries'

// eslint-disable-next-line no-confusing-arrow
const createPath = (url: string, countryType?: CountryType): string =>
  isNil(countryType) ? url : `${url}?${defaultToEmptyString(countryType)}`

export const getMoviesCountries = (
  domain: string,
  serviceId: string,
  countryType?: CountryType,
): Promise<ResponseAPI<Country>> =>
  makeGetRequest(createPath(`${domain}/client/api/servicespec/${serviceId}/movie/countries`, countryType))
