import React from 'react'
import { DEV_ENV } from '@alphaott/smart-tv-config'

const importWhyDidYouRender = () => {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render')

  if (whyDidYouRender) whyDidYouRender(React)
}

if (DEV_ENV) {
  importWhyDidYouRender()
}
