// @flow

import React, { forwardRef, useCallback, useMemo } from 'react'
import { VariableSizeList } from 'react-window'
import * as R from 'ramda'

import { useLastFocusHistory } from '@alphaott/smart-tv-spatial-navigation'
import { getVerticalListScrollOffsetV2 } from '@alphaott/smart-tv-utils'

// eslint-disable-next-line react/display-name
const LastFocusedVerticalList = forwardRef(
  ({ onItemsRendered, itemCount, ...otherProps }: any, ref) => {
    const { onBecameFocused } = otherProps.itemData

    const { lastFocusedItem, onSaveLastFocus } = useLastFocusHistory({}, 'vertical')

    const initialScrollOffset = useMemo(() => {
      if (lastFocusedItem?.itemIndex === undefined || lastFocusedItem?.itemCount !== itemCount) {
        return undefined
      }

      const { height } = otherProps
      const { itemIndex, sizes } = lastFocusedItem

      return getVerticalListScrollOffsetV2(height, itemIndex, sizes)
    }, [itemCount, lastFocusedItem, otherProps])

    const handleSaveData = useCallback(
      ({ itemIndex }: Object) => {
        if (itemIndex !== undefined) {
          let focusedItem = {
            ...lastFocusedItem,
          }

          if (R.not(R.hasPath(['sizes'], lastFocusedItem))) {
            focusedItem = {
              ...focusedItem,
              sizes: undefined,
            }
          }

          focusedItem = {
            ...focusedItem,
            sizes: {
              ...focusedItem.sizes,
              [itemIndex]: otherProps.itemSize(itemIndex),
            },
          }

          onSaveLastFocus({
            ...focusedItem,
            itemCount,
            itemIndex,
          })
        }
      },
      [itemCount, lastFocusedItem, onSaveLastFocus, otherProps],
    )

    const handleBecameFocused = useCallback(
      (itemIndex) => {
        if (onBecameFocused) {
          onBecameFocused(itemIndex)
        }

        handleSaveData({ itemIndex })
      },
      [handleSaveData, onBecameFocused],
    )

    return (
      <VariableSizeList
        {...otherProps}
        ref={ref}
        initialScrollOffset={initialScrollOffset}
        itemCount={itemCount}
        itemData={{
          ...otherProps.itemData,
          onBecameFocused: handleBecameFocused,
        }}
      />
    )
  },
)

const VerticalListInner = ({ withLastFocus, ...props }: any, ref) => {
  if (withLastFocus) {
    return <LastFocusedVerticalList {...props} ref={ref} />
  }

  return <VariableSizeList {...props} ref={ref} />
}

export const VerticalList = forwardRef(VerticalListInner)

export default VerticalList
