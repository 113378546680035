// @flow
import typeToReducer from 'type-to-reducer'
import { take, map, propOr, propEq, prop, reject, pipe, prepend, uniqBy } from 'ramda'

import type { Movie } from '@alphaott/api-client/types/movies'

import { prepareMovieImages } from '@alphaott/common-utils/utils/schemas/movies'
import { RECENT_MOVIE_MAX_COUNT } from '@alphaott/app-config'
import {
  UPDATE_RECENT_MOVIE_TYPE,
  FETCH_RECENT_MOVIE_TYPE,
  FETCH_RECENT_MOVIE_BY_IDS_TYPE,
  FETCH_CURRENT_RECENT_MOVIE_TYPE,
  GET_RECENT_MOVIES_TYPE,
} from '../../actions/recent'

export type MovieProgress = {
  total: number,
  current: number,
  id: string,
  completed?: boolean,
}

export type RecentMovieProgressState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Array<MovieProgress>,
}

const initialProgressState: RecentMovieProgressState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
}

const movieRecentProgress = typeToReducer(
  {
    [UPDATE_RECENT_MOVIE_TYPE]: {
      LOADING: (state): RecentMovieProgressState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): RecentMovieProgressState => {
        const {
          payload: { data: response },
        } = action

        const newMovie = {
          id: response.item,
          ...response.data,
        }

        const movies = pipe(
          reject(propEq('id', response.item)),
          prepend(newMovie),
        )(state.data)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: movies,
        }
      },

      ERROR: (state, action): RecentMovieProgressState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: [],
        errors: action.payload.error,
      }),
    },

    [GET_RECENT_MOVIES_TYPE]: {
      LOADING: (state): RecentMovieProgressState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): RecentMovieProgressState => {
        const {
          payload: { data },
        } = action

        const movies = map(elem => {
          const progress = propOr({}, 'data', elem)
          return {
            id: propOr('', 'item', elem),
            current: propOr(0, 'current', progress),
            total: propOr(0, 'total', progress),
            completed: propOr(false, 'completed', progress),
          }
        }, data)

        const uniqMovies = uniqBy(item => prop('id', item), movies)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: take(RECENT_MOVIE_MAX_COUNT, uniqMovies),
        }
      },

      ERROR: (state, action): RecentMovieProgressState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: [],
        errors: action.payload.error,
      }),
    },
  },
  initialProgressState,
)

export type RecentMovieState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Array<Movie>,
}

const initialState: RecentMovieState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
}

const movieRecent = typeToReducer(
  {
    [FETCH_RECENT_MOVIE_TYPE]: {
      LOADING: (state): RecentMovieState => ({ ...state, isLoading: true }),

      SUCCESS: (state): RecentMovieState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
      }),

      ERROR: (state, action): RecentMovieState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: [],
        errors: action.payload.error,
      }),
    },

    [FETCH_CURRENT_RECENT_MOVIE_TYPE]: {
      LOADING: (state): RecentMovieState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): RecentMovieState => {
        const {
          payload: { data },
          meta,
        } = action

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: [prepareMovieImages(data, meta.contentApi), ...state.data],
        }
      },

      ERROR: (state, action): RecentMovieState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: [],
        errors: action.payload.error,
      }),
    },

    ERROR: (state, action): RecentMovieState => ({
      ...state,
      isLoading: false,
      isSuccess: false,
      isError: true,
      data: [],
      errors: action.payload.error,
    }),

    [FETCH_RECENT_MOVIE_BY_IDS_TYPE]: {
      LOADING: (state): RecentMovieState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): RecentMovieState => {
        const {
          payload: { data },
          meta,
        } = action
        const newFetch = map(load => prepareMovieImages(load, meta.contentApi), data)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: [...newFetch, ...state.data],
        }
      },

      ERROR: (state, action): RecentMovieState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: [],
        errors: action.payload.error,
      }),
    },
  },
  initialState,
)

export { movieRecent }
export { movieRecentProgress }
