import { createSelector } from 'reselect'
import type { App, BrandConfig } from '@alphaott/common-types'
import type { Config } from '@alphaott/api-client/types/config'
import { path } from 'ramda'
import { getBrand, getConfig } from '../selectors'

export const getApps = createSelector(
  [getBrand, getConfig],
  (brand: BrandConfig, config: Config): App[] => path(['apps'], config) || path(['apps'], brand) || [],
)
