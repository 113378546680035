// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { TabBar as TabBarBase } from './TabBar'
import { Scene as SceneComponent } from './Scene'

import {
  CloseIcon as CloseIconComponent,
  AudioIcon as AudioIconComponent,
  SubtitlesIcon as SubtitlesIconComponent,
} from '../assets'

export const Container = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`

export const Menu = styled(Flex)`
  justify-content: space-between;
  width: 612rem;
  height: 100%;
  z-index: 1;
`

export const TabBar = styled(TabBarBase)`
  margin-top: 42rem;
`

export const Scene = styled(SceneComponent)``

export const CloseIcon = styled(CloseIconComponent)`
  width: 28rem;
  height: 28rem;
`

export const AudioIcon = styled(AudioIconComponent)`
  width: 36rem;
  height: 35rem;
`

export const SubtitlesIcon = styled(SubtitlesIconComponent)`
  width: 40rem;
  height: 32rem;
`
