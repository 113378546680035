// @flow
import googleAnalytics from '@analytics/google-analytics'

const GOOGLE_ANALYTICS_SHORT_NAME: string = 'GA'

export const ANALYTICS_PLUGINS = {
  [GOOGLE_ANALYTICS_SHORT_NAME]: (config: Object) =>
    googleAnalytics({
      ...config,
      tasks: {
        ...config.tasks,
        checkProtocolTask: null,
        checkStorageTask: null,
      },
      customDimensions: {
        deviceBrand: 'dimension1',
        deviceModel: 'dimension2',
        deviceOS: 'dimension3',
        deviceOSVersion: 'dimension4',
      },
    }),
}
