// @flow

import { multiply } from 'ramda'

import { defaultToZero } from './help'

export const prepareRating = (rating: number, maxRating: number): number => {
  const preparedRating = defaultToZero(rating)
  const preparedMaxRating = maxRating || 10

  const calculatedRating = Math.round(multiply(preparedRating, 100)) / preparedMaxRating

  return defaultToZero(calculatedRating)
}
