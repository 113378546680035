// @flow
import type { onFullyFilledProps } from '../usePinCodeModal'

// eslint-disable-next-line complexity
export function pinCodeHandler(
  handler: Function,
  enabledParentalControl: boolean,
  handleOpenPinCodeModal: (newHandler: onFullyFilledProps, newTitle?: string) => void,
  currentPinCode: string,
  incorrectPinCodeTitle: string,
  isUnlocked?: boolean,
  handleUnlock?: Function,
  pinCodeTitle?: string,
) {
  if (!enabledParentalControl || isUnlocked) {
    handler()

    return
  }

  if (enabledParentalControl) {
    handleOpenPinCodeModal(
      // eslint-disable-next-line complexity
      (pincode: string, clear?: () => void, close?: () => void, setTitle?: (newTitle: string) => void) => {
        if (pincode !== currentPinCode) {
          clear && clear()
          setTitle && setTitle(incorrectPinCodeTitle)

          return
        }

        clear && clear()
        close && close()

        handleUnlock && handleUnlock()
        handler()
      },
      pinCodeTitle,
    )
  }
}
