// @flow

import React, { memo } from 'react'

import type { ComponentType } from 'react'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'
import { getActiveTrack } from '@alphaott/video-player/src/getActiveTrack'

import { Container, Title, TrackList } from './Scene.styles'

export type SceneProps = {
  className?: string,
  title: string,
  items: Array<Object>,
  onClick: Function,
}

const ScenePure = ({ className, title, items = [], onClick }: SceneProps) => {
  const activeTrack = getActiveTrack('active')(items)
  const { ref, focusKey } = useFocusable({ preferredChildFocusKey: activeTrack?.language })

  return (
    <FocusContext.Provider value={focusKey}>
      <Container className={className} ref={ref}>
        <Title>{title}</Title>
        <TrackList focusKey={focusKey} items={items} onClick={onClick} />
      </Container>
    </FocusContext.Provider>
  )
}

export const Scene: ComponentType<SceneProps> = memo<SceneProps>(ScenePure)

export default Scene
