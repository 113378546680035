// @flow
import typeToReducer from 'type-to-reducer'
import { propEq, reject, map, prop, sortWith, descend } from 'ramda'
import { normalize } from 'normalizr'

import type { TVShow } from '@alphaott/api-client/types/tvshows'

import tvshowListSchema, { prepareTVShowImages } from '@alphaott/common-utils/utils/schemas/tvshows'
import { defaultToEmptyObject } from '@alphaott/common-utils/utils/help'

import {
  FETCH_FAVORITE_TV_SHOWS_TYPE,
  FETCH_FAVORITE_TV_SHOWS_IDS_TYPE,
  FETCH_FAVORITE_TV_SHOWS_BY_IDS_TYPE,
  FETCH_FAVORITE_TV_SHOW_BY_ID_TYPE,
  ADD_TV_SHOW_ID_TO_FAVORITE_IDS_TYPE,
  REMOVE_TV_SHOW_ID_FROM_FAVORITE_IDS_TYPE,
  REMOVE_TV_SHOW_FROM_FAVORITES_TYPE,
} from '../../actions'

export type FavoriteTVShowsIdsState = {|
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Array<Object>,
|}

const initialIdsState: FavoriteTVShowsIdsState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
}

const tvshowFavoriteIds = typeToReducer(
  {
    [FETCH_FAVORITE_TV_SHOWS_IDS_TYPE]: {
      LOADING: (state): FavoriteTVShowsIdsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): FavoriteTVShowsIdsState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: sortWith([descend(prop('ts'))], action.payload.data),
      }),

      ERROR: (state, action): FavoriteTVShowsIdsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },

    [ADD_TV_SHOW_ID_TO_FAVORITE_IDS_TYPE]: {
      LOADING: (state): FavoriteTVShowsIdsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): FavoriteTVShowsIdsState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: [action.payload.data, ...state.data],
      }),

      ERROR: (state, action): FavoriteTVShowsIdsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },

    [REMOVE_TV_SHOW_ID_FROM_FAVORITE_IDS_TYPE]: {
      LOADING: (state): FavoriteTVShowsIdsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): FavoriteTVShowsIdsState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: reject(propEq('_id', action.meta.itemId), state.data),
      }),

      ERROR: (state, action): FavoriteTVShowsIdsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
  },
  initialIdsState,
)

export { tvshowFavoriteIds }

export type TVShowNormalized = { [string]: TVShow }

export type FavoriteTVShowsState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +entities: TVShowNormalized,
}

const initialState: FavoriteTVShowsState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  entities: {},
}

const tvshowFavorites = typeToReducer(
  {
    [FETCH_FAVORITE_TV_SHOWS_TYPE]: {
      LOADING: (state): FavoriteTVShowsState => ({
        ...state,
        isLoading: true,
        isSuccess: false,
      }),

      SUCCESS: (state): FavoriteTVShowsState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
      }),

      ERROR: (state, action): FavoriteTVShowsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },

    [FETCH_FAVORITE_TV_SHOWS_BY_IDS_TYPE]: {
      LOADING: (state): FavoriteTVShowsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): FavoriteTVShowsState => {
        const {
          payload: { data },
          meta,
        } = action
        const preparedData = map(load => prepareTVShowImages(load, meta.contentApi), data)
        const { entities } = normalize(preparedData, tvshowListSchema)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          entities: defaultToEmptyObject({ ...entities.tvshows, ...state.entities }),
        }
      },

      ERROR: (state, action): FavoriteTVShowsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },

    [FETCH_FAVORITE_TV_SHOW_BY_ID_TYPE]: {
      LOADING: (state): FavoriteTVShowsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): FavoriteTVShowsState => {
        const { payload, meta } = action
        const preparedData = prepareTVShowImages(payload.data, meta.contentApi)
        const { entities } = normalize([preparedData], tvshowListSchema)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          entities: defaultToEmptyObject({ ...entities.tvshows, ...state.entities }),
        }
      },

      ERROR: (state, action): FavoriteTVShowsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },

    [REMOVE_TV_SHOW_FROM_FAVORITES_TYPE]: (state, action) => ({
      ...state,
      entities: reject(propEq('_id', action.payload.tvshowId), state.entities),
    }),
  },
  initialState,
)

export { tvshowFavorites }
