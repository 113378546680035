// @flow
import styled from 'styled-components'

import { getTextColorPrimary } from '@alphaott/smart-tv-themes'

const Text = styled.div`
  color: ${getTextColorPrimary};
  font-style: normal;
  font-weight: normal;
  font-size: 38rem;
  line-height: 45rem;
`

export const HeaderTitle = styled(Text)`
  font-weight: 300;
  font-size: 66rem;
  line-height: 77rem;
`

export const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const HeaderDate = styled(Text)`
  font-weight: 500;
  font-size: 27rem;
  line-height: 32rem;
  text-align: right;
  margin-top: 5rem;
`

export const HeaderContainer = styled.div`
  width: calc(100vw - 168rem);
  margin-top: 50rem;
`
