// @flow
import styled, { css } from 'styled-components'

import {
  getTextColorPrimary,
  getBackgroundSecondaryColor,
  getErrorColorMain,
} from '@alphaott/smart-tv-themes'

const baseInputStyle = css`
  box-sizing: border-box;
  border: none;
  height: 72rem;
  width: 100%;
  margin: 0 auto;
  margin-top: 12rem;
  display: flex;
  font-size: 40rem;
  line-height: 48rem;
  padding: 0 19rem;
  color: ${getTextColorPrimary};
  outline: none;
  background-color: ${getBackgroundSecondaryColor};
  font-weight: normal;
  font-style: normal;
  ::placeholder {
    color: ${getTextColorPrimary};
  }
  &:focus {
    border: 6rem solid #dedede;
  }
  &:hover {
  }
`

export const StyledInput = styled.input`
  ${baseInputStyle}
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`

export const WarningLabel = styled.div`
  color: ${getErrorColorMain};
  font-size: 20rem;
`
