// @flow
import { normalize } from 'normalizr'
import typeToReducer from 'type-to-reducer'

import type { Language } from '@alphaott/api-client/types/languages'
import type { LanguageNormalize } from '@alphaott/common-types/normalized'

import { isValidTTL, calculateExpires } from '@alphaott/app-main/selectors'
import { languagesListSchema } from '@alphaott/common-utils/utils/schemas/languages'
import { defaultToEmptyObject } from '@alphaott/common-utils/utils/help'
import { FETCH_TVSHOW_LANGUAGES } from '../../actions/tvshowLanguages'

export type TVShowLanguagesState = {|
  isLoading: boolean,
  isSuccess: boolean,
  isError: boolean,
  errors: any,
  data: Array<Language>,
  entities: LanguageNormalize,
  expires: ?number,
|}

const initialState: TVShowLanguagesState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
  entities: {},
  expires: null,
}

const tvshowLanguages = typeToReducer(
  {
    [FETCH_TVSHOW_LANGUAGES]: {
      LOADING: (state): TVShowLanguagesState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): TVShowLanguagesState => {
        const {
          payload,
          meta: { ttl },
        } = action
        const { entities } = normalize(payload.data, languagesListSchema)
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
          entities: defaultToEmptyObject(entities.languages),
          ...(isValidTTL(ttl) && { expires: calculateExpires(ttl) }),
        }
      },

      ERROR: (state, action): TVShowLanguagesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        expires: null,
      }),
    },
  },
  initialState,
)

export { tvshowLanguages }
