// @flow

export const switchChannelCallback =
  // eslint-disable-next-line complexity
  (activeId: string, items: Object[]) => (callback: Function) => {
    if (!items || items?.length === 0) {
      return null
    }

    const activeIndex = items.findIndex((item) => item.id === activeId)

    if (activeIndex === -1) {
      return null
    }

    return callback(activeIndex, items)
  }

export const findNextChannel = (activeIndex: number, items: Object[]) => {
  if (activeIndex >= items.length - 1) {
    return items[0]
  }

  return items[activeIndex + 1]
}

export const findPreviousChannel = (activeIndex: number, items: Object[]) => {
  if (activeIndex === 0) {
    return items[items.length - 1]
  }

  return items[activeIndex - 1]
}
