// @flow
import { path } from 'ramda'

import { getTvShowCategories } from '@alphaott/api-client'
import { getPublicDomain, getCacheChannels, isCacheValid } from '@alphaott/app-main/selectors'

export const FETCH_TVSHOW_CATEGORIES = 'tvshows/FETCH_CATEGORIES'

const fetchTVShowCategoriesAction = (serviceSpec: string) => (dispatch: Function, getState: Function) => {
  const state = getState()
  const publicApi = getPublicDomain(state)
  const ttl = getCacheChannels(state)

  return dispatch({
    type: FETCH_TVSHOW_CATEGORIES,
    payload: getTvShowCategories(publicApi, serviceSpec),
    meta: { ttl },
  })
}

// eslint-disable-next-line complexity
export const fetchTVShowCategories = (serviceSpec: string, force: ?boolean = false) => (
  dispatch: Function,
  getState: Function,
) => {
  if (force) return dispatch(fetchTVShowCategoriesAction(serviceSpec))

  const state = getState()
  const expires = path(['tvshowCategories', 'expires'], state)

  if (expires && isCacheValid(expires)) return null

  return dispatch(fetchTVShowCategoriesAction(serviceSpec))
}
