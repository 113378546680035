// @flow

import { find, prop, propEq, and, reject } from 'ramda'

import { getCustomerId, getServiceLiveTvIds } from '@alphaott/app-core/models/selectors'
import logoutRequestWrapper from '@alphaott/app-main/core/logoutWrapper'
import { getPersonalList, putPersonalList, removeItemFromPersonalList } from '@alphaott/api-client'
import { isNotEmpty, isNilOrEmpty } from '@alphaott/common-utils'

import type { Dispatch } from '@alphaott/common-types'

import { isFavoriteChannel, getFavorite, getFavoriteList } from '../../selectors'
import { fetchChannels } from '../channels'

export const FETCH_FAVORITE_CHANNELS_TYPE = 'channels/FETCH_FAVORITE_CHANNELS_TYPE'
// This need for future functionality
// export const FETCH_FAVORITE_CHANNEL_BY_ID_TYPE = 'channels/FETCH_FAVORITE_CHANNEL_BY_ID_TYPE'
export const FETCH_FAVORITE_CHANNELS_IDS_TYPE = 'channels/FETCH_FAVORITE_CHANNELS_IDS_TYPE'
export const ADD_CHANNEL_ID_TO_FAVORITE_IDS_TYPE = 'channels/ADD_CHANNEL_ID_TO_FAVORITE_IDS_TYPE'
export const REMOVE_CHANNEL_ID_FROM_FAVORITE_IDS_TYPE = 'channels/REMOVE_CHANNEL_ID_FROM_FAVORITE_IDS_TYPE'

// eslint-disable-next-line consistent-return
export const fetchFavoriteChannelsByIds = (dispatch: Dispatch, getState: Function) => {
  const state = getState()
  const serviceIdLiveTv = getServiceLiveTvIds(state)
  const favoriteList = getFavoriteList(state)
  const uniqFavoriteIds = reject(({ item }) => find(propEq('_id', item), favoriteList), state.channelFavorite.data)

  if (and(isNotEmpty(serviceIdLiveTv), isNotEmpty(uniqFavoriteIds))) {
    return dispatch(fetchChannels(serviceIdLiveTv, true))
  }
}

export const addChannelIdToFavoriteIds = (channelId: string, customerId: string) => (
  dispatch: Dispatch,
  getState: Function,
) =>
  dispatch({
    type: ADD_CHANNEL_ID_TO_FAVORITE_IDS_TYPE,
    payload: logoutRequestWrapper(putPersonalList)(customerId, 'tv', 'favorites', channelId, {})(dispatch, getState),
  })

// Todo: Change this when will add endpoint for getting channel by id
export const fetchFavoriteChannelById = (dispatch: Dispatch) => dispatch(fetchFavoriteChannelsByIds)

export const addChannelToFavorite = (channelId: string, customerId: string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line no-useless-catch
  try {
    await dispatch(addChannelIdToFavoriteIds(channelId, customerId))
    dispatch(fetchFavoriteChannelById)
  } catch (err) {
    throw err
  }
}

export const removeChannelIdFromFavoritesIds = (channelId: string, customerId: string) => (
  dispatch: Dispatch,
  getState: Function,
) => {
  const favoriteChannelsIds = getFavorite(getState())
  const channel = find(propEq('item', channelId), favoriteChannelsIds)
  const itemId = prop('_id', channel)

  return dispatch({
    type: REMOVE_CHANNEL_ID_FROM_FAVORITE_IDS_TYPE,
    payload: logoutRequestWrapper(removeItemFromPersonalList)(customerId, 'tv', 'favorites', itemId)(
      dispatch,
      getState,
    ),
    meta: {
      itemId,
    },
  })
}

export const favoriteChannel = (channelId: string) => (dispatch: Dispatch, getState: Function) => {
  const customerId = getCustomerId(getState())
  const isFavorite = isFavoriteChannel(channelId)(getState())

  if (isFavorite) {
    return dispatch(removeChannelIdFromFavoritesIds(channelId, customerId))
  }

  return dispatch(addChannelIdToFavoriteIds(channelId, customerId))
}

export const fetchFavoriteChannelsIds = (dispatch: Dispatch, getState: Function) => {
  const customerId = getCustomerId(getState())

  return dispatch({
    type: FETCH_FAVORITE_CHANNELS_IDS_TYPE,
    payload: logoutRequestWrapper(getPersonalList)(customerId, 'tv', 'favorites')(dispatch, getState),
  })
}

export const fetchFavoriteChannels = async (dispatch: Dispatch, getState: Function) => {
  const customerId = getCustomerId(getState())

  if (isNilOrEmpty(customerId)) return null

  return dispatch({
    type: FETCH_FAVORITE_CHANNELS_TYPE,
    payload: Promise.all([await dispatch(fetchFavoriteChannelsIds), dispatch(fetchFavoriteChannelsByIds)]),
  })
}
