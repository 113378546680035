// @flow

import { createSelector } from 'reselect'
import { prop } from 'ramda'

import type { PlayerStore } from '../types'
import type { PlayerState } from '../reducers'

export const getPlayerState = (state: PlayerStore): PlayerState => state.playerState

export const getReadyPlayer = createSelector(
  [getPlayerState],
  (playerState: PlayerState): boolean => prop('isPlayerReady', playerState),
)

export const getLoadingPlayer = createSelector(
  [getPlayerState],
  (playerState: PlayerState): boolean => prop('isLoading', playerState),
)

export const getPausedPlayer = createSelector(
  [getPlayerState],
  (playerState: PlayerState): boolean => prop('isPaused', playerState),
)

export const getSeekingPlayer = createSelector(
  [getPlayerState],
  (playerState: PlayerState): boolean => prop('isSeeking', playerState),
)

export const getErrorMessagePlayer = createSelector(
  [getPlayerState],
  (playerState: PlayerState): any => prop('errorMessage', playerState),
)

export const getCriticalErrorMessagePlayer = createSelector(
  [getPlayerState],
  (playerState: PlayerState): any => prop('criticalErrorMessage', playerState),
)
