// @flow

import React, { memo, useMemo, useState, useContext, createContext } from 'react'

export type ReactWindowContextProps = {
  historyKeys: string[],
  listSizeHistory: Object,
  onChangeHistoryKeys: Function,
  onChangeListSizeHistory: Function,
}

const defaultValues = {}

export const ReactWindowContext = createContext<ReactWindowContextProps>(defaultValues)

export const useReactWindowContext = (): ReactWindowContextProps =>
  useContext<ReactWindowContextProps>(ReactWindowContext)

export type ReactWindowProviderProps = {}

const ReactWindowProviderPure = ({ children }: ReactWindowProviderProps) => {
  const [historyKeys, setHistoryKeys] = useState([])
  const [listSizeHistory, setListSizeHistory] = useState({})

  const value = useMemo(
    () => ({
      historyKeys,
      listSizeHistory,
      onChangeHistoryKeys: setHistoryKeys,
      onChangeListSizeHistory: setListSizeHistory,
    }),
    [historyKeys, listSizeHistory, setHistoryKeys, setListSizeHistory],
  )

  return <ReactWindowContext.Provider value={value}>{children}</ReactWindowContext.Provider>
}

export const ReactWindowProvider = memo<ReactWindowProviderProps>(ReactWindowProviderPure)

export default ReactWindowProvider
