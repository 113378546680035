// @flow

import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { isNotNilOrEmpty } from 'ramda-adjunct'

import { toHrsMin } from '@alphaott/common-utils'

import { Container, Item, Capitalize } from './DetailLine.style'

export type DetailLineProps = {
  className?: any,
  year?: number,
  runtime?: number,
  seasons?: number,
  episodes?: number,
  country?: string,
  isShowRuntime?: boolean,
}

// eslint-disable-next-line complexity
const DetailLinePure = ({
  className,
  year,
  runtime,
  seasons,
  episodes,
  country,
  isShowRuntime = true,
}: DetailLineProps) => {
  const { t } = useTranslation()

  return (
    <Container className={className}>
      {isNotNilOrEmpty(year) && <Item>{year}</Item>}
      {isShowRuntime && isNotNilOrEmpty(runtime) && <Item>{toHrsMin(runtime)}</Item>}
      {isNotNilOrEmpty(seasons) && (
        <Item>
          {seasons} <Capitalize>{t('mwstv_seasons')}</Capitalize>
        </Item>
      )}
      {isNotNilOrEmpty(episodes) && (
        <Item>
          {episodes} <Capitalize>{t('mwstv_episodes')}</Capitalize>
        </Item>
      )}
      {isNotNilOrEmpty(country) && <Item>{country}</Item>}
    </Container>
  )
}

export const DetailLine = memo<DetailLineProps>(DetailLinePure)

export default DetailLine
