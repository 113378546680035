// @flow
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { equals, map, or } from 'ramda'

import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'
import { DEFAULT_ACCESS_RATINGS } from '@alphaott/app-config'

import { AccessRatingButton } from '../AccessRatingButton'
import {
  Row,
  RowBottom,
  ButtonWrapper,
  EditSmallText,
  EditTitle,
  Content,
  EditHeader,
  RightColumn,
  ProfileContainer,
  EditSmallButton,
  ParentControlLargeText,
} from './EditParentalControlView.styles'
import { DONE_BUTTON_FOCUS_KEY } from './const'

type EditParentalControlViewProps = {
  accessRatingList: Array,
  accessRating: number,
  onChangeAccessRating: Function,
}

// eslint-disable-next-line complexity
const EditParentalControlViewPure = ({
  accessRatingList,
  accessRating,
  onChangeAccessRating,
}: EditParentalControlViewProps) => {
  const { ref, setFocus } = useFocusable()
  const { t } = useTranslation()

  const [selectedAccessRating, setSelectedAccessRating] = useState(accessRating)

  useEffect(() => {
    if (setFocus) setFocus(accessRating)
  }, [setFocus]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeAccessRating = useCallback(
    () => {
      const isDisabledParentalRestrictions = equals(
        selectedAccessRating,
        t('mwstv_parental_restrictions_off'),
      )
      const preparedAccessRating = isDisabledParentalRestrictions ? null : selectedAccessRating
      onChangeAccessRating(preparedAccessRating)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeAccessRating, selectedAccessRating],
  )

  const preparedAccessRatingList = useMemo(
    () => [t('mwstv_parental_restrictions_off'), ...or(accessRatingList, DEFAULT_ACCESS_RATINGS)],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessRatingList],
  )

  const handleChangeSelectedAccessRating = useCallback(
    (ratingItem) => () => {
      setSelectedAccessRating(ratingItem)
    },
    [setSelectedAccessRating],
  )

  const isActive = useCallback((item) => equals(selectedAccessRating, item), [selectedAccessRating])

  const accessRatingsList = useMemo(
    () =>
      map(
        (ratingItem) => (
          <AccessRatingButton
            key={ratingItem}
            label={ratingItem}
            isActive={isActive(ratingItem)}
            onClick={handleChangeSelectedAccessRating(ratingItem)}
          />
        ),
        preparedAccessRatingList,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isActive, selectedAccessRating, accessRatingList, preparedAccessRatingList],
  )

  return (
    <ProfileContainer ref={ref}>
      <EditHeader>
        <EditTitle>{t('mwstv_edit_parental_restrictions')}</EditTitle>
        <EditSmallText>{t('mwstv_select_the_age_restriction_of your profile')}</EditSmallText>
      </EditHeader>
      <Content>
        <Row>
          <RightColumn>
            <ParentControlLargeText>{t('mwstv_parental_age_restrictions')}</ParentControlLargeText>
            {accessRatingsList}
          </RightColumn>
        </Row>
        <RowBottom>
          <ButtonWrapper>
            <EditSmallButton focusKey={DONE_BUTTON_FOCUS_KEY} onClick={handleChangeAccessRating}>
              {t('mwstv_button_done')}
            </EditSmallButton>
          </ButtonWrapper>
        </RowBottom>
      </Content>
    </ProfileContainer>
  )
}

export const EditParentalControlView = memo<EditParentalControlViewProps>(
  EditParentalControlViewPure,
)

export default EditParentalControlView
