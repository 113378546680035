// @flow
import typeToReducer from 'type-to-reducer'

import { CHANGE_GUEST_ACCESS_TTL_TYPE, RESET_GUEST_ACCESS_DATA_TYPE } from '../../actions/appGuestAccess'

export type GuestAccessState = {
  expires?: number,
}

const initialState: GuestAccessState = {}

const guestAccess = typeToReducer(
  {
    [CHANGE_GUEST_ACCESS_TTL_TYPE]: (state, action) => ({
      ...state,
      expires: action.payload.expires,
    }),
    [RESET_GUEST_ACCESS_DATA_TYPE]: () => initialState,
  },
  initialState,
)

export { guestAccess }
