// @flow

import { useTranslation } from 'react-i18next'
import { prop, toLower } from 'ramda'

import type { LanguageOption } from '@alphaott/common-types'

export const useTranslateDefaultLanguageOption = (option: LanguageOption) => {
  const { t } = useTranslation()

  return {
    ...option,
    nativeName: t(toLower(prop('nativeName', option))),
  }
}
