// @flow

import React, { memo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { initApp, openDemoWelcomeScreen } from '@alphaott/app-core/actions'
import {
  isDemoCustomerAndWithoutSubscriptions,
  isAppReadySelector,
} from '@alphaott/app-core/selectors'
import { getBrand } from '@alphaott/app-main/selectors'

import { includeDemoUserNotification } from '@alphaott/app-main/utils'

import { PageView } from '@alphaott/smart-tv-components'

type AppContainerProps = {
  children: any,
}

const AppContainerPure = ({ children }: AppContainerProps) => {
  const dispatch = useDispatch()

  const isAppInitSuccess = useSelector((store: Store) => store.init.isSuccess)

  const brand = useSelector(getBrand)
  const isDemoCustomer = useSelector(isDemoCustomerAndWithoutSubscriptions)
  const isAppReady = useSelector(isAppReadySelector)
  const isAppError = useSelector((store: Store) => store.app.isError)

  const handleOpenDemoWelcomeScreen = useCallback(() => {
    dispatch(openDemoWelcomeScreen())
  }, [dispatch])

  useEffect(() => {
    if (isAppInitSuccess) {
      dispatch(initApp(true))
    }
  }, [isAppInitSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (isAppReady && isDemoCustomer && includeDemoUserNotification(brand)) {
      handleOpenDemoWelcomeScreen()
    }
  }, [brand, isDemoCustomer, handleOpenDemoWelcomeScreen, isAppReady])

  return (
    <PageView withFocusable={false} loading={!isAppReady && !isAppError}>
      {children}
    </PageView>
  )
}

export const AppContainer = memo<AppContainerProps>(AppContainerPure)

export default AppContainer
