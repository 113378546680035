// @flow
import React, { memo, useCallback } from 'react'
import { pipe } from 'ramda'

import { withParentalControl } from '@alphaott/smart-tv-common'
import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'
import defaultChannelLogo from '../assets/defaultChannelLogo.png'

import {
  ChannelCardImage,
  ChannelCardTitleBox,
  ChannelCardTitle,
  ChannelCardContainer,
  Placeholder,
  Description,
  ChannelNumber,
  LeftContainer,
} from './ChannelCardHorizontal.style'

type ChannelCardHorizontalProps = {
  focusKey?: string,
  title: string,
  description: string,
  number: number,
  src: string,
  placeholder?: string,
  isActive: boolean,
  style?: any,
  onFocus?: Function,
  onPress: Function,
  onArrowPress?: Function,
}

const ChannelCardHorizontalPure = ({
  id,
  focusKey,
  title,
  description,
  number,
  src,
  placeholder = defaultChannelLogo,
  isActive,
  isRestricted,
  style,
  onFocus,
  onPress,
  onArrowPress,
  onSwitchChannel,
}: ChannelCardHorizontalProps) => {
  const handleFocus = useCallback(
    (event) => onFocus(event, { id, isRestricted, onSwitchChannel }),
    [id, isRestricted, onFocus, onSwitchChannel],
  )

  const { ref, focused, focusSelf } = useFocusable({
    focusKey,
    onFocus: handleFocus,
    onEnterPress: onPress,
    onArrowPress,
  })
  const { handleMouseOver } = useMouseOver(focusSelf)

  const placeholderComponent = <Placeholder src={placeholder} alt="placeholder" />

  return (
    <ChannelCardContainer
      ref={ref}
      style={style}
      focused={focused}
      isActive={isActive}
      onClick={onPress}
      onMouseOver={handleMouseOver}
    >
      {/* TODO: Add loader */}
      <LeftContainer>
        <ChannelCardImage
          src={src}
          placeholder={placeholder}
          unloader={placeholderComponent}
          alt="placeholder"
        />
        <ChannelCardTitleBox>
          <ChannelCardTitle>{title}</ChannelCardTitle>
          <Description>{description}</Description>
        </ChannelCardTitleBox>
      </LeftContainer>
      <ChannelNumber>{number}</ChannelNumber>
    </ChannelCardContainer>
  )
}

export const ChannelCardHorizontal = pipe(
  memo,
  withParentalControl,
)<ChannelCardHorizontalProps>(ChannelCardHorizontalPure)

export default ChannelCardHorizontal
