// @flow
import { path } from 'ramda'
import { getMoviesList } from '@alphaott/api-client'
import { getContentDomain, getPublicDomain, getCacheVOD, isCacheValid } from '@alphaott/app-main/selectors'
import { getServiceVodIds } from '@alphaott/app-core/selectors'
import type { MoviesListQuery } from '@alphaott/api-client/movies'

export const FETCH_FILTERED_MOVIES = 'movies/FETCH_FILTERED_MOVIES'

export const fetchFilteredMoviesAction = (param: MoviesListQuery, storeKey: string, resetData?: boolean) => (
  dispatch: Function,
  getState: Function,
) => {
  const state = getState()
  const serviceSpec = getServiceVodIds(state)
  const publicApi = getPublicDomain(state)
  const contentApi = getContentDomain(state)
  const ttl = getCacheVOD(state)

  return dispatch({
    type: FETCH_FILTERED_MOVIES,
    payload: getMoviesList(publicApi, serviceSpec, param),
    meta: {
      contentApi,
      resetData,
      storeKey,
      ttl,
    },
  })
}

export const fetchFilteredMovies = (
  param: MoviesListQuery,
  storeKey: string,
  resetData?: boolean,
  force?: boolean = false,
  // eslint-disable-next-line complexity
) => (dispatch: Function, getState: Function) => {
  /**
   * Force Fetch Movies
   * For example, for MWTC `pull to refresh` feature
   *  */
  if (force) return dispatch(fetchFilteredMoviesAction(param, storeKey, resetData))

  const state = getState()
  const expires = path(['movieFilters', 'data', storeKey, 'expires'], state)

  if (expires && isCacheValid(expires)) return null

  return dispatch(fetchFilteredMoviesAction(param, storeKey, resetData))
}
