// @flow

import React, { memo, useCallback, useEffect, forwardRef } from 'react'
import { throttle } from 'throttle-debounce'
import * as Sentry from '@sentry/browser'

import { useUserSettings } from '@alphaott/user-settings'
import { useNetworkStatus, useVisibilityStatus } from '@alphaott/smart-tv-current-device/hooks'
import { PLAYER_AUDIO_PARAM, PLAYER_SUBTITLES_PARAM } from '@alphaott/app-config'

import { VideoPlayer } from './VideoPlayer'
import { useActions } from '../../store'

import type { MediaStream } from '../../types'

export const UPDATE_RECENT_MOVIE_PERCENT = 600000

export const is403 = (status) => status?.code === 403
export const is502 = (status) => status?.code === 502

type VideoPlayerContainerProps = {
  videoId: string,
  mediaStream: MediaStream,
  autoplay?: boolean,
  defaultTimePosition?: number,
  onTimeUpdate: (currentTime: number, duration: number) => void,
  onThrottledTimeUpdate: (currentTime: number, duration: number) => void,
  onShowLimitError: () => void,
}

const VideoPlayerContainerPure = (
  {
    videoId,
    mediaStream,
    autoplay,
    defaultTimePosition,
    onTimeUpdate,
    onThrottledTimeUpdate,
    onShowLimitError,
  }: VideoPlayerContainerProps,
  playerRef: any,
) => {
  const { networkStatus, isConnectedNetwork, prevStatus: prevNetworkStatus } = useNetworkStatus()
  const { visibilityStatus, isVisible, prevStatus: prevVisibilityStatus } = useVisibilityStatus()
  const { changePreferredTrack } = useUserSettings()
  const {
    onLoadMeta,
    onCanPlay,
    onLoading,
    onSeeking,
    onSeeked,
    onPlay,
    onPause,
    onEnd,
    onError,
    onResetError,
    onReset,
    onChangeAudioTracks,
    onChangeSubtitleTracks,
  } = useActions()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleThrottledTimeUpdate = useCallback(
    throttle(UPDATE_RECENT_MOVIE_PERCENT, onThrottledTimeUpdate, { noTrailing: false }),
    [onThrottledTimeUpdate],
  )

  const handleTimeUpdate = useCallback(
    (time) => {
      if (onTimeUpdate) {
        onTimeUpdate(time)
      }

      handleThrottledTimeUpdate(time)
    },
    [onTimeUpdate, handleThrottledTimeUpdate],
  )

  const handleChangeAudioTracks = useCallback(
    (
      audioTrack: Object,
      audioTracks: Object[],
      options: {
        preferred?: boolean,
      },
      // eslint-disable-next-line consistent-return
    ) => {
      if (options?.preferred) {
        return changePreferredTrack({
          videoId,
          option: PLAYER_AUDIO_PARAM,
          track: audioTrack.language,
        })
      }

      onChangeAudioTracks(audioTracks)
    },
    [videoId, onChangeAudioTracks, changePreferredTrack],
  )

  const handleChangeSubtitleTracks = useCallback(
    (
      subtitleTrack: Object,
      subtitleTracks: Object[],
      options: {
        preferred?: boolean,
      },
      // eslint-disable-next-line consistent-return
    ) => {
      if (options?.preferred) {
        return changePreferredTrack({
          videoId,
          option: PLAYER_SUBTITLES_PARAM,
          track: subtitleTrack?.language,
        })
      }

      onChangeSubtitleTracks(subtitleTracks)
    },
    [videoId, onChangeSubtitleTracks, changePreferredTrack],
  )

  const handleSendErrorToSentry = useCallback((exception: string, error: Object) => {
    Sentry.captureException(new Error(exception), {
      extra: {
        ...error,
        drm: JSON.stringify(error?.drm?.servers || error?.drm?.type),
      },
    })
  }, [])

  const handleError = useCallback(
    // eslint-disable-next-line complexity,consistent-return
    (error) => {
      if (is403(error?.status)) {
        return onShowLimitError()
      }

      // Не уверен что нужен этот костыль, будем тестировать;
      if (is502(error?.status) || error?.message?.includes('502 Bad Gateway')) {
        return null

        // setTimeout(() => {
        //   if (not(isPaused)) {
        //     onClearErrors()
        //   }
        // }, 10000)
      }

      if (error?.status?.message && !error?.status?.message?.toLowerCase()?.includes('<html>')) {
        if (error?.sentry) {
          const exception = `${error?.sentry?.player} - ${error?.code}`

          handleSendErrorToSentry(exception, { ...error?.sentry, videoId })
        }

        return onError({ message: error.status.message })
      }

      const code = error?.message || error?.code
      const errorMessage = code
        ? `Stream error occurred. Error code: ${code}. Please try again later.`
        : 'Stream error occurred. Please try again later.'
      const exception = code || errorMessage

      handleSendErrorToSentry(exception, {
        ...error?.sentry,
        videoId,
        error: {
          code,
          message: errorMessage,
        },
      })

      onError({ message: errorMessage })
    },
    [videoId, onError, onShowLimitError, handleSendErrorToSentry],
  )

  const handleDestroy = useCallback(
    (time) => {
      onThrottledTimeUpdate(time)
    },
    [onThrottledTimeUpdate],
  )

  useEffect(() => {
    if (isConnectedNetwork && prevNetworkStatus?.isDisconnectedNetwork) {
      playerRef?.current?.onRetryStreaming()
      onResetError()
    }
  }, [networkStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (isVisible && prevVisibilityStatus?.isHidden && playerRef?.current?.onRetryStreaming) {
      playerRef?.current?.onRetryStreaming()
      onResetError()
    }
  }, [visibilityStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => () => {
      onReset()
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <VideoPlayer
      videoId={videoId}
      ref={playerRef}
      mediaStream={mediaStream}
      autoplay={autoplay}
      defaultTimePosition={defaultTimePosition}
      onLoadMeta={onLoadMeta}
      onCanPlay={onCanPlay}
      onLoading={onLoading}
      onSeeking={onSeeking}
      onSeeked={onSeeked}
      onPlay={onPlay}
      onPause={onPause}
      onEnd={onEnd}
      onTimeUpdate={handleTimeUpdate}
      onError={handleError}
      onChangeAudioTracks={handleChangeAudioTracks}
      onChangeSubtitleTracks={handleChangeSubtitleTracks}
      onDestroy={handleDestroy}
    />
  )
}

export const VideoPlayerContainer = memo<VideoPlayerContainerProps>(
  forwardRef(VideoPlayerContainerPure),
)

export default VideoPlayerContainer
