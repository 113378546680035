import styled from 'styled-components'

import { getColorPrimary, getTextColorPrimary } from '@alphaott/smart-tv-themes'

const getColor = (condition) => (condition ? getColorPrimary : getTextColorPrimary)

export const SubMenuContainer = styled.div`
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  justify-content: ${({ isShowLabel }) => (isShowLabel ? 'flex-start' : 'center')};
  align-items: center;
  height: 66rem;
  width: 100%;
  margin-top: 6rem;
  opacity: ${({ isShowLabel }) => (isShowLabel ? 1 : 0.6)};
  position: relative;
`

export const Indicator = styled.div`
  width: 17rem;
  height: 100%;
  background-color: ${getColorPrimary};
  position: absolute;
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44rem;
  height: 36rem;
  margin-left: ${({ isShowLabel }) => (isShowLabel ? 34 : 0)}rem;

  & svg {
    width: 100%;
    fill: ${({ focused, isCurrent, isShowLabel }) =>
      isShowLabel ? getColor(focused) : getColor(isCurrent)};
  }
`

export const Label = styled.div`
  font-size: 24rem;
  font-weight: 700;
  letter-spacing: 1rem;
  text-transform: uppercase;
  margin-left: 18rem;
  color: ${({ focused }) => getColor(focused)};
  cursor: default;
`
