// @flow
import { createSelector } from 'reselect'

import { getBrandTermsUrl, getBrandPrivacyUrl } from '@alphaott/app-main/models/selectors'

import * as configSelectors from '@alphaott/app-main/selectors'

export const getTermsUrl = createSelector(
  [configSelectors.getTermsUrl, getBrandTermsUrl],
  (serviceProviderTermsUrl: string, brandTermsUrl: string): string => serviceProviderTermsUrl || brandTermsUrl,
)

export const getPrivacyUrl = createSelector(
  [configSelectors.getPrivacyUrl, getBrandPrivacyUrl],
  (serviceProviderPrivacyUrl: string, brandPrivacyUrl: string): string => serviceProviderPrivacyUrl || brandPrivacyUrl,
)
