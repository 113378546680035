// @flow

import { useMemo } from 'react'
import { filter, propEq } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'

import { CATALOG_CONTENT_GROUP_TYPE } from '@alphaott/app-config'
import {
  isPopular,
  isSuggested,
  isFavorite,
  isRecent,
  isAll,
  isLatestAdditions,
  isNew,
  isMostWatched,
  isMostPlayed,
} from '@alphaott/app-server-driven-ui/utils'

export const useMenuCustomization = (contentCatalog: Object[]) => {
  const categories = useMemo(
    () => filter(propEq('contentGroupType', CATALOG_CONTENT_GROUP_TYPE.CATEGORY), contentCatalog),
    [contentCatalog],
  )

  const genres = useMemo(() => filter(propEq('contentGroupType', CATALOG_CONTENT_GROUP_TYPE.GENRE), contentCatalog), [
    contentCatalog,
  ])

  const isAllEnabled = useMemo(() => isNotEmpty(filter(item => isAll(item), contentCatalog)), [contentCatalog])
  const isFavoriteEnabled = useMemo(() => isNotEmpty(filter(item => isFavorite(item), contentCatalog)), [
    contentCatalog,
  ])
  const isRecentEnabled = useMemo(() => isNotEmpty(filter(item => isRecent(item), contentCatalog)), [contentCatalog])
  const isPopularEnabled = useMemo(() => isNotEmpty(filter(item => isPopular(item), contentCatalog)), [contentCatalog])
  const isSuggestedEnabled = useMemo(() => isNotEmpty(filter(item => isSuggested(item), contentCatalog)), [
    contentCatalog,
  ])
  const isLatestAdditionsEnabled = useMemo(() => isNotEmpty(filter(item => isLatestAdditions(item), contentCatalog)), [
    contentCatalog,
  ])
  const isNewEnabled = useMemo(() => isNotEmpty(filter(item => isNew(item), contentCatalog)), [contentCatalog])
  const isMostWatchedEnabled = useMemo(() => isNotEmpty(filter(item => isMostWatched(item), contentCatalog)), [
    contentCatalog,
  ])
  const isMostPlayedEnabled = useMemo(() => isNotEmpty(filter(item => isMostPlayed(item), contentCatalog)), [
    contentCatalog,
  ])

  const isCategoriesEnabled = useMemo(() => isNotEmpty(categories), [categories])
  const isGenresEnabled = useMemo(() => isNotEmpty(genres), [genres])

  return {
    isAllEnabled,
    isFavoriteEnabled,
    isRecentEnabled,
    isPopularEnabled,
    isSuggestedEnabled,
    isLatestAdditionsEnabled,
    isNewEnabled,
    isMostWatchedEnabled,
    isMostPlayedEnabled,
    isCategoriesEnabled,
    isGenresEnabled,
    genres,
    categories,
  }
}
