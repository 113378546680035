// @flow

import { getTextColorPrimary } from '@alphaott/smart-tv-themes'
import styled from 'styled-components'

export const Container = styled.div`
  width: 427rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 5;
`

export const Title = styled.h2`
  font-weight: 400;
  font-size: 48rem;
  color: ${getTextColorPrimary};
  margin: 315rem 0 20rem 63rem;
`

export const List = styled.div``
