// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { SeekBarRef } from '@alphaott/smart-tv-video-player'

export const Container = styled(Flex)`
  align-items: center;
`

export const Time = styled.span`
  font-size: 24rem;
  line-height: 29rem;
  letter-spacing: 0.758333rem;
  color: ${(props) => props.theme.palette.grey[100]};
`

export const SeekBar = styled(SeekBarRef)`
  max-width: 1000rem;
  margin-left: 16rem;
`
