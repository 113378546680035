// @flow

import React, { memo, useEffect, useMemo } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { or } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'

import { TracksMenu, usePlayerContext } from '@alphaott/smart-tv-video-player'

import { useControls, useUiControls } from '@alphaott/smart-tv-video-player/hooks'
import { getDeviceType, PLATFORMS } from '@alphaott/smart-tv-platforms'

import {
  Container,
  CentralContainer,
  PlayButton,
  PlayCircleIcon,
  InfoContainer,
  LeftContainer,
  ChannelImage,
  ChannelTitle,
  RightContainer,
  DetailInfo,
  PosterContainer,
  Poster,
} from './elements'

import { PLAY_BUTTON_FOCUS_KEY } from './const'

import defaultChannelLogo from '../../assets/defaultChannelLogo.png'

import { ChannelListControl } from './ChannelListControl'

import type { Program } from './DetailInfo'
import type { ControlsProps } from './DetailInfo/Controls'

const devicePlatform = getDeviceType()

export type Channel = {
  title: string,
  image?: string,
  isFavorite?: boolean,
}

export type ChannelVideoPlayerControlsProps = {
  className?: string,
  channel: Channel,
  channelList: Array<Channel>,
  program: Program,
  channelId: string,
  isAvailablePlayButton?: boolean,
  setFocus?: (key: string) => void,
  onSelectTVListItem: Function,
} & ControlsProps &
  Object

/* eslint-disable complexity */

// $FlowFixMe
const CentralControlsPure = ({
  onPlay,
  isLoading,
  isPaused,
  error,
  isAvailablePlayButton,
  isShowControls,
}: any) => {
  const isShowPlayButton = useMemo(
    () => isAvailablePlayButton && isShowControls && isPaused && !isLoading && !error,
    [isAvailablePlayButton, isShowControls, isPaused, isLoading, error],
  )

  return (
    <CentralContainer>
      {isShowPlayButton && (
        <PlayButton
          icon={PlayCircleIcon}
          key={PLAY_BUTTON_FOCUS_KEY}
          item={PLAY_BUTTON_FOCUS_KEY}
          focusKey={PLAY_BUTTON_FOCUS_KEY}
          onClick={onPlay}
          onEnterPress={onPlay}
        />
      )}
    </CentralContainer>
  )
}
const CentralControls = memo(CentralControlsPure)

// $FlowFixMe
const BottomControlsPure = ({
  isShowControls,
  onOpenSubtitleSettings,
  channel,
  program,
  isAvailableProgramGuide,
  isAvailableMyList,
  isAvailableMultiScreen,
  isAvailableChannelsList,
  isAvailableAudioSubs,
  isAvailableRecord,
  channelCardPlaceholder = defaultChannelLogo,
  openChannelsListButtonTitle,
  onOpenProgramGuide,
  onFavorite,
  onMultiScreen,
  onOpenChannelsList,
  onRecord,
}: any) => {
  const placeholder = <img src={channelCardPlaceholder} alt="placeholder" />

  if (!isShowControls) return null

  return (
    <InfoContainer>
      <LeftContainer>
        <ChannelImage
          src={channel.image}
          placeholder={channelCardPlaceholder}
          unloader={placeholder}
          alt="placeholder"
        />
        <Dotdotdot clamp={4}>
          <ChannelTitle>{channel.title}</ChannelTitle>
        </Dotdotdot>
      </LeftContainer>
      <RightContainer>
        <DetailInfo
          isFavorite={channel.isFavorite}
          isAvailableProgramGuide={isAvailableProgramGuide}
          isAvailableMyList={isAvailableMyList}
          isAvailableChannelsList={isAvailableChannelsList}
          isAvailableAudioSubs={isAvailableAudioSubs}
          isAvailableMultiScreen={isAvailableMultiScreen}
          isAvailableRecord={isAvailableRecord}
          openChannelsListButtonTitle={openChannelsListButtonTitle}
          onOpenProgramGuide={onOpenProgramGuide}
          onFavorite={onFavorite}
          onCaptions={onOpenSubtitleSettings}
          onOpenChannelsList={onOpenChannelsList}
          onMultiScreen={onMultiScreen}
          onRecord={onRecord}
          {...program}
        />
      </RightContainer>
    </InfoContainer>
  )
}

const BottomControls = memo(BottomControlsPure)

export const ChannelVideoPlayerControlsPure = ({
  className,
  isAvailablePlayButton,
  channel,
  program,
  isAvailableProgramGuide,
  isAvailableRecord,
  isAvailableMyList,
  isAvailableMultiScreen,
  isAvailableChannelsList,
  channelCardPlaceholder,
  openChannelsListButtonTitle,
  onOpenProgramGuide,
  onFavorite,
  onMultiScreen,
  onRecord,
  channelList,
  onSelectChannelsListItem,
  channelId,
  poster,
  onChangeAudioTrack,
  onChangeSubtitleTrack,
}: any) => {
  const [playerState] = usePlayerContext()

  const { onPlay } = useControls()
  const {
    isShowControls,
    isShowSubtitlesSettings,
    isShowListControl,
    onShowControls,
    onOpenSubtitleSettings,
    onCloseSubtitleSettings,
    onOpenChannelsList,
    onCloseChannelsList,
    onForceShowControls,
  } = useUiControls()

  const isAvailableAudioSubs = useMemo(
    () => or(isNotNilOrEmpty(playerState.audioTracks), isNotNilOrEmpty(playerState.subtitleTracks)),
    [playerState.audioTracks, playerState.subtitleTracks],
  )

  useEffect(() => {
    onForceShowControls(playerState.paused)
  }, [playerState.paused, onForceShowControls])

  return (
    <>
      {poster && (
        <PosterContainer>
          <Poster src={poster} />
        </PosterContainer>
      )}

      <Container className={className} onMouseMove={onShowControls} onMouseDown={onShowControls}>
        <CentralControls
          onPlay={onPlay}
          isLoading={playerState.loading}
          isPaused={playerState.paused}
          error={playerState.error}
          isAvailablePlayButton={isAvailablePlayButton}
          isShowControls={isShowControls}
        />
        <ChannelListControl
          channelId={channelId}
          items={channelList}
          channelCardPlaceholder={channelCardPlaceholder}
          isShowListControl={isShowListControl}
          onSelectChannelsListItem={onSelectChannelsListItem}
          onCloseChannelsList={onCloseChannelsList}
          isShowSubtitlesSettings={isShowSubtitlesSettings}
          onOpenChannelsList={onOpenChannelsList}
          isAvailableChannelsList={isAvailableChannelsList}
        />
        {isShowSubtitlesSettings && (
          <TracksMenu
            videoId={channelId}
            onChangeAudioTrack={onChangeAudioTrack}
            onChangeSubtitleTrack={onChangeSubtitleTrack}
            onClose={onCloseSubtitleSettings}
          />
        )}
        <BottomControls
          isShowControls={isShowControls}
          onOpenSubtitleSettings={onOpenSubtitleSettings}
          channel={channel}
          program={program}
          isAvailableProgramGuide={isAvailableProgramGuide}
          isAvailableMyList={isAvailableMyList}
          isAvailableMultiScreen={isAvailableMultiScreen}
          isAvailableChannelsList={isAvailableChannelsList}
          isAvailableAudioSubs={isAvailableAudioSubs}
          isAvailableRecord={isAvailableRecord}
          channelCardPlaceholder={channelCardPlaceholder}
          openChannelsListButtonTitle={openChannelsListButtonTitle}
          onOpenProgramGuide={onOpenProgramGuide}
          onFavorite={onFavorite}
          onMultiScreen={onMultiScreen}
          onOpenChannelsList={onOpenChannelsList}
          onRecord={onRecord}
        />
      </Container>
    </>
  )
}

ChannelVideoPlayerControlsPure.defaultProps = {
  isAvailablePlayButton: devicePlatform === PLATFORMS.WEB,
}

export const ChannelVideoPlayerControls = memo(ChannelVideoPlayerControlsPure)

export default ChannelVideoPlayerControls
