// @flow

import { equals, filter, head, propEq, forEach, prop } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'

const getRolesString = track => track.audioRoles && track.audioRoles.join(', ')

const getCombination = (language, rolesString) => `${language}: ${rolesString}`

export const removeDuplicateTracks = (tracks: Object[]) => {
  const selectedTrack = head(filter(propEq('active', true), tracks))

  const combinationsMade = new Set()
  const preparedTrack = []

  const selectedCombination = selectedTrack ? getCombination(selectedTrack.language, getRolesString(selectedTrack)) : ''

  // eslint-disable-next-line consistent-return
  forEach(track => {
    if (isNotNilOrEmpty(prop('language', track))) {
      const rolesString = getRolesString(track)
      const combinationName = getCombination(track.language, rolesString)

      if (combinationsMade.has(combinationName)) {
        return null
      }

      combinationsMade.add(combinationName)

      const active = equals(combinationName, selectedCombination)

      preparedTrack.push({ ...track, active })
    }
  }, tracks)

  return preparedTrack
}

export default removeDuplicateTracks
