// @flow

import { repeat, splitEvery, pipe, join } from 'ramda'
import { v4 as uuidv4 } from 'uuid'

export const uid = (length: number = 16): string =>
  repeat(null, length)
    .map(() => '0123456789ABCDEF'.charAt(Math.floor(Math.random() * 16)))
    .join('')
    .toUpperCase()

// ToDo: use https://github.com/babel/babel/tree/master/packages/babel-plugin-proposal-pipeline-operator
export const generateMacAddress = (): string =>
  pipe(
    splitEvery(2),
    join(':'),
  )(uid(16))

export const generateSerialNumber = (): string => uid(16)
export const getDeviceBrand = (): string => navigator.vendor || 'N/A'
export const getDevicePlatform = (): string => 'BROWSER'
export const getDeviceModel = (): string => navigator.product || 'N/A'
export const getDeviceType = (): string => 'PC'
export const getDeviceMacAddress = (): string => generateMacAddress()
export const getDeviceSerialNumber = (): string => generateSerialNumber()
export const getDeviceId = (): string => uuidv4()
