// @flow

export class NotSupportedDRMError extends Error {
  drmType: string

  constructor(drmType: string) {
    super(`DRM system ${drmType} is not supported`)
    this.name = 'NotSupportedDRMError'
    this.drmType = drmType
  }
}
