// @flow
import React, { lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { AppContainer } from '../containers/AppContainer'
import AppNavigationWithMenu from './AppNavigationWithMenu'

import { userWithSubscriptions } from './hocs'

const AllMoviesContainer = lazy(() => import('../containers/Movies/AllMovies'))
const AllChannelsContainer = lazy(() => import('../containers/Channels/AllChannels'))
const CatchupChannelContainer = lazy(() => import('../containers/CatchupChannel'))
const AllTVShowsContainer = lazy(() => import('../containers/TVShows/AllTVShows'))
const AllRadioChannelsContainer = lazy(() => import('../containers/RadioChannels/AllRadioChannels'))
const MovieDetailContainer = lazy(() => import('../containers/MovieDetail'))
const TVShowDetailContainer = lazy(() => import('../containers/TVShowDetail'))
const MovieContainer = lazy(() => import('../containers/Movie'))
const TVShowContainer = lazy(() => import('../containers/TVShow'))
const ChannelContainer = lazy(() => import('../containers/Channel'))
const RadioChannelContainer = lazy(() => import('../containers/RadioChannel'))
const SearchAllResultContainer = lazy(() => import('../containers/SearchAllResult'))

// Fix unnecessary re-render -
// https://github.com/mjrussell/redux-auth-wrapper/issues/224#issuecomment-375837028
const ChannelView = userWithSubscriptions(ChannelContainer)
const CatchupChannelView = userWithSubscriptions(CatchupChannelContainer)
const RadioChannelView = userWithSubscriptions(RadioChannelContainer)
const MovieView = userWithSubscriptions(MovieContainer)
const TVShowView = userWithSubscriptions(TVShowContainer)
const AllChannelsView = userWithSubscriptions(AllChannelsContainer)
const AllMoviesView = userWithSubscriptions(AllMoviesContainer)
const AllTVShowsView = userWithSubscriptions(AllTVShowsContainer)
const AllRadioView = userWithSubscriptions(AllRadioChannelsContainer)
const MovieDetailView = userWithSubscriptions(MovieDetailContainer)
const TVShowDetailView = userWithSubscriptions(TVShowDetailContainer)
const SearchAllResultView = userWithSubscriptions(SearchAllResultContainer)

const AppNavigation = () => (
  <AppContainer>
    <Routes>
      <Route path="tvshow-detail">
        <Route path=":tvshowId" element={<TVShowDetailView />} />
        <Route path=":tvshowId/season/:seasonId" element={<TVShowDetailView />} />
      </Route>
      <Route path="tvshow/:tvshowId/season/:seasonId/episode/:episodeId" element={<TVShowView />} />
      <Route path="tvshow/:tvshowId/trailer/:trailerId" element={<TVShowView />} />
      <Route path="tvshow-all" element={<AllTVShowsView />} />

      <Route path="movie/:id" element={<MovieView />} />
      <Route path="movie-detail/:id" element={<MovieDetailView />} />
      <Route path="movie-all" element={<AllMoviesView />} />

      <Route path="tv/:id" element={<ChannelView />} />
      <Route path="tv/:id/catchup/:programId" element={<CatchupChannelView />} />
      <Route path="tv-all" element={<AllChannelsView />} />

      <Route path="radio/:id" element={<RadioChannelView />} />
      <Route path="radio-all" element={<AllRadioView />} />

      <Route path="search/all-result" element={<SearchAllResultView />} />

      <Route path="/*" element={<AppNavigationWithMenu />} />

      <Route path="*" element={<Navigate to="/notfound" />} />
    </Routes>
  </AppContainer>
)

export default AppNavigation
