// @flow
import { themeStyle } from '../colorPalettes'

import { lightTheme } from './lightTheme'
import { darkTheme } from './darkTheme'

import type { ThemeType } from '..'

const kapangThemeName = 'kapang'

export const themeKapang: ThemeType = {
  name: kapangThemeName,
  getThemeVariation: (themeVariation: string) => {
    switch (themeVariation) {
      case themeStyle.light:
        return lightTheme
      case themeStyle.dark:
        return darkTheme
      default:
        return lightTheme
    }
  },
}
