// @flow
import * as Sentry from '@sentry/react'
import { PROD_ENV, SENTRY_DSN, VERSION, GIT_REVISION } from '@alphaott/smart-tv-config'

if (PROD_ENV) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: `${VERSION}-${GIT_REVISION}`,
    autoSessionTracking: false,
    sampleRate: 0.01,
    allowUrls: [/playground/],
    ignoreErrors: [
      'Non-Error promise rejection captured with keys: error, ok, status',
      'Error: Non-Error exception captured with keys: error, ok, status',
      'Object captured as exception with keys: error, ok, status',
      'Object captured as promise rejection with keys: error, ok, status',
      'Object captured as promise rejection with keys: error, ok, status, statusText',
      'Object captured as promise rejection with keys: error, ok, status, statusText',
      'Object captured as promise rejection with keys: category, code, data, handled, message',
      'ShakaPlayer - 1001',
      'ShakaPlayer - 1002',
      'ShakaPlayer - 1003',
      'TypeError: network error',
      'Network request failed',
      'Shaka Error 1001',
      'Shaka Error 1002',
      'Shaka Error 1003',
      'Shaka Error 7001',
      '1001',
      '1002',
      '1003',
      'AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22',
    ],
  })
}

export default Sentry
