// @flow
import { createSelector } from 'reselect'
import { and, prop, propEq } from 'ramda'

import { getGuestAccessTrialEnabled } from '@alphaott/app-auth/models/selectors'
import type { GuestAccessState } from '../reducers'

type Store = any

const getGuestAccessState = (state: Store): GuestAccessState => state.guestAccess

export const getGuestAccessTrialExpires = createSelector(
  [getGuestAccessState],
  (data: GuestAccessState): any => prop('expires', data),
)

export const shouldOpenTrialExpiredScreen = createSelector(
  [getGuestAccessTrialEnabled, getGuestAccessState],
  (isGuestAccessTrialEnabled: boolean, data: GuestAccessState): boolean =>
    and(isGuestAccessTrialEnabled, propEq('expires', 0, data)),
)
