// @flow

import styled from 'styled-components'
import { rgba } from 'polished'

import { getBackgroundColor } from '@alphaott/smart-tv-themes'

import { SubMenuItem } from './SubMenu/SubMenuItem'

const getGradient = (theme) =>
  `linear-gradient(
    90deg, ${getBackgroundColor({ theme })} 25%, 
    ${rgba(getBackgroundColor({ theme }), 0.9)} 46%, 
    ${rgba(getBackgroundColor({ theme }), 0.7)} 70%, 
    ${rgba(getBackgroundColor({ theme }), 0.6)} 77%,
    ${rgba(getBackgroundColor({ theme }), 0.37)} 88%, 
    rgba(0,0,0,0) 100%);`

export const Container = styled.div`
  width: 110rem;
  border-right: 2rem solid;
  border-image: radial-gradient(white, black) 2;
  z-index: 5;
`

export const Curtain = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.5);
`

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ isOpenMenu }) => (isOpenMenu ? 414 : 110)}rem;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  left: 0;
  background: ${({ isOpenMenu, theme }) => (isOpenMenu ? getGradient(theme) : getBackgroundColor)};
  z-index: 10;
`

export const ExitButton = styled(SubMenuItem)`
  position: absolute;
  bottom: 40rem;
`
