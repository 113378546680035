// @flow
import styled from 'styled-components'

import {
  getBackgroundSecondaryColor,
  getTextColorPrimary,
  getColorPrimary,
} from '@alphaott/smart-tv-themes'

// eslint-disable-next-line complexity
const getBackgroundColor = ({ focused, primaryColor, secondaryColor }) => {
  const focusedColor = primaryColor || getColorPrimary
  const unfocusedColor = secondaryColor || getBackgroundSecondaryColor

  return focused ? focusedColor : unfocusedColor
}

export const Button = styled.button`
  color: ${getTextColorPrimary};
  background: ${getBackgroundColor};
  min-width: 300rem;
  min-height: 60rem;
  padding: 20rem;
  border: none;
  border-radius: 3rem;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 24rem;
  font-weight: 900;
  line-height: 19rem;
  max-width: 400rem;
  display: inline-block;
  text-align: center;
  outline: none;
  position: relative;

  &:active {
  }
  &:focus {
    color: ${getTextColorPrimary};
  }
  &:active {
  }
`
