// @flow

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'ramda'

import {
  SearchIcon,
  HomeIcon,
  LiveTvIcon,
  TvGuideIcon,
  MoviesIcon,
  TvShowsIcon,
  RadioIcon,
  AppsIcon,
  RecordingsIcon,
  MyListIcon,
  AccountIcon,
  SettingsIcon,
} from '../components/assets'
import { MENU_ITEMS } from '../constants'

export const useMenuItems = (items: Array<string>) => {
  const { t } = useTranslation()

  const menuItems = useMemo(
    () => ({
      [MENU_ITEMS.SEARCH]: {
        label: t('mwstv_search'),
        icon: <SearchIcon />,
        route: '/search',
        focusKey: MENU_ITEMS.SEARCH,
      },
      [MENU_ITEMS.HOME]: {
        label: t('mwstv_home'),
        icon: <HomeIcon />,
        route: '/',
        focusKey: MENU_ITEMS.HOME,
      },
      [MENU_ITEMS.LIVE_TV]: {
        label: t('mwstv_live_tv'),
        icon: <LiveTvIcon />,
        route: '/channels',
        focusKey: MENU_ITEMS.LIVE_TV,
      },
      [MENU_ITEMS.TV_GUIDE]: {
        label: t('mwstv_tv_guide'),
        icon: <TvGuideIcon />,
        route: '/epg-simple',
        focusKey: MENU_ITEMS.TV_GUIDE,
      },
      [MENU_ITEMS.MOVIES]: {
        label: t('mwstv_movies'),
        icon: <MoviesIcon />,
        route: '/movies',
        focusKey: MENU_ITEMS.MOVIES,
      },
      [MENU_ITEMS.TV_SHOWS]: {
        label: t('mwstv_tv_shows'),
        icon: <TvShowsIcon />,
        route: '/tvshows',
        focusKey: MENU_ITEMS.TV_SHOWS,
      },
      [MENU_ITEMS.RADIO]: {
        label: t('radio_uppercase'),
        icon: <RadioIcon />,
        route: '/radio-channels',
        focusKey: MENU_ITEMS.RADIO,
      },
      [MENU_ITEMS.APPS]: {
        label: t('mwstv_apps'),
        icon: <AppsIcon />,
        route: '/apps',
        focusKey: MENU_ITEMS.APPS,
      },
      [MENU_ITEMS.RECORDINGS]: {
        label: t('mwstv_recordings'),
        icon: <RecordingsIcon />,
        route: '/recordings',
        focusKey: MENU_ITEMS.RECORDINGS,
      },
      [MENU_ITEMS.MY_LIST]: {
        label: t('mwstv_my_list'),
        icon: <MyListIcon />,
        route: '/my-list',
        focusKey: MENU_ITEMS.MY_LIST,
      },
      [MENU_ITEMS.ACCOUNT]: {
        label: t('mwstv_account'),
        icon: <AccountIcon />,
        route: '/account',
        focusKey: MENU_ITEMS.ACCOUNT,
      },
      [MENU_ITEMS.SETTINGS]: {
        label: t('mwstv_settings'),
        icon: <SettingsIcon />,
        route: '/settings',
        focusKey: MENU_ITEMS.SETTINGS,
      },
    }),
    [t],
  )

  const preparedItems = useMemo(() => map((elem) => menuItems[elem], items), [items, menuItems])

  return preparedItems
}
