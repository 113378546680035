// @flow
import React, { useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { pathOr } from 'ramda'

import { HorizontalSupportScreen as SupportScreen } from '@alphaott/smart-tv-support'
import { ThemeProvider } from '@alphaott/smart-tv-themes'

import { useDeviceInfo, useBrandDetail } from './hooks'

export const Support = () => {
  const navigate = useNavigate()

  const deviceInfo = useDeviceInfo()
  const { supportEmail, infoEmail, supportWww, companyName, supportAddress } = useBrandDetail()

  const { logo: logoSrc, extra } = useContext(ThemeProvider)

  const logoHeight = useMemo(() => pathOr(52, ['auth', 'logoHeight'], extra), [extra])
  const currentDate = useMemo(() => format(new Date(), 'HH:mm - MM/DD/YYYY'), [])

  const handleBack = useCallback(() => navigate(-1), [navigate])

  return (
    <SupportScreen
      logoSrc={logoSrc}
      logoHeight={logoHeight}
      supportEmail={supportEmail}
      infoEmail={infoEmail}
      companyWww={supportWww}
      companyName={companyName}
      supportAddress={supportAddress}
      deviceInfo={deviceInfo}
      date={currentDate}
      onBack={handleBack}
    />
  )
}

export default Support
