// @flow

import { useCallback } from 'react'
import { isNilOrEmpty } from 'ramda-adjunct'

import { prepareTracks, removeDuplicateTracks } from '@alphaott/common-utils'
import { useTracks as usePreferredTracks } from '@alphaott/video-player-utils'
import { getActiveTrack } from '@alphaott/video-player/src/getActiveTrack'
import { useTranslateDefaultLanguageOption } from '@alphaott/common-utils/settings'
import { DISABLED_LANGUAGE_OPTION } from '@alphaott/app-config'

export const useShakaPlayerTracks = (
  videoId: string,
  playerRef: any,
  {
    onChangeAudioTracks,
    onChangeSubtitleTracks,
  }: {
    onChangeAudioTracks: (activeTrack: Object, audioTracks: Object[]) => void,
    onChangeSubtitleTracks: (activeTrack: Object, subtitleTracks: Object[]) => void,
  },
) => {
  const defaultLanguageOption = useTranslateDefaultLanguageOption(DISABLED_LANGUAGE_OPTION)

  const {
    preferredAudioLanguage,
    preferredSubtitleLanguage,
    getAudioTrack: getPreferredAudioTrack,
    getSubtitlesTrack: getPreferredSubtitleTrack,
  } = usePreferredTracks(videoId)

  const handlePrepareTracks = useCallback((tracks) => {
    const uniqueTracks = removeDuplicateTracks(tracks)
    const preparedTracks = prepareTracks(uniqueTracks)

    return preparedTracks
  }, [])

  const handleGetAudioTracks = useCallback(() => {
    const audioTracks = playerRef.current?.getAudioTracks()

    if (isNilOrEmpty(audioTracks)) {
      return []
    }

    return handlePrepareTracks(audioTracks)
  }, [playerRef, handlePrepareTracks])

  const handleGetSubtitleTracks = useCallback(() => {
    const subtitleTracks = playerRef.current?.getSubtitleTracks()

    if (isNilOrEmpty(subtitleTracks)) {
      return []
    }

    const preparedSubtitleTracks = [
      {
        label: defaultLanguageOption.nativeName,
        language: defaultLanguageOption.key,
        active: subtitleTracks.findIndex((item) => item.active) === -1,
      },
      ...subtitleTracks,
    ]

    return handlePrepareTracks(preparedSubtitleTracks)
  }, [playerRef, defaultLanguageOption.nativeName, defaultLanguageOption.key, handlePrepareTracks])

  const handleChangeDefaultAudioTrack = useCallback(
    (audioTracks) => {
      const preferredAudioTrack = getPreferredAudioTrack(audioTracks)
      const activePlayerTrack = getActiveTrack('active')(audioTracks)

      if (activePlayerTrack.language !== preferredAudioTrack.language) {
        playerRef.current?.onChangeAudioTrack(preferredAudioTrack)
      }
    },
    [playerRef, getPreferredAudioTrack],
  )

  const handleChangeDefaultSubtitleTrack = useCallback(
    (subtitleTracks) => {
      const preferredSubtitleTrack = getPreferredSubtitleTrack(subtitleTracks)
      const activePlayerTrack = getActiveTrack('active')(subtitleTracks)

      if (
        preferredSubtitleTrack.language !== 'none' &&
        activePlayerTrack?.language !== preferredSubtitleTrack?.language
      ) {
        playerRef.current?.onChangeSubtitleTrack(preferredSubtitleTrack)
        playerRef.current?.onChangedSubtitleTrackVisibility(true)
      }
    },
    [playerRef, getPreferredSubtitleTrack],
  )

  const handleInitTracks = useCallback(() => {
    const audioTracks = handleGetAudioTracks()
    const subtitleTracks = handleGetSubtitleTracks()

    handleChangeDefaultAudioTrack(audioTracks)
    handleChangeDefaultSubtitleTrack(subtitleTracks)

    return {
      audioTracks,
      subtitleTracks,
    }
  }, [
    handleGetAudioTracks,
    handleGetSubtitleTracks,
    handleChangeDefaultAudioTrack,
    handleChangeDefaultSubtitleTrack,
  ])

  const handleChangeAudioTrack = useCallback(
    (audioTrack) => {
      onChangeAudioTracks(audioTrack, [], { preferred: true })

      return playerRef.current?.onChangeAudioTrack(audioTrack)
    },
    [playerRef, onChangeAudioTracks],
  )

  const handleChangeSubtitleTrack = useCallback(
    (subtitleTrack) => {
      onChangeSubtitleTracks(subtitleTrack, [], { preferred: true })

      if (subtitleTrack.language === 'none') {
        return playerRef.current?.onChangedSubtitleTrackVisibility(false)
      }

      playerRef.current?.onChangedSubtitleTrackVisibility(true)
      return playerRef.current?.onChangeSubtitleTrack(subtitleTrack)
    },
    [playerRef, onChangeSubtitleTracks],
  )

  const handleChangedAudioTrack = useCallback(() => {
    const audioTracks = handleGetAudioTracks()
    const activeTrack = getActiveTrack('active')(audioTracks)

    onChangeAudioTracks(activeTrack, audioTracks)
  }, [handleGetAudioTracks, onChangeAudioTracks])

  const handleChangedSubtitleTrack = useCallback(() => {
    const subtitleTracks = handleGetSubtitleTracks()
    const activeTrack = getActiveTrack('active')(subtitleTracks)

    onChangeSubtitleTracks(activeTrack, subtitleTracks)
  }, [handleGetSubtitleTracks, onChangeSubtitleTracks])

  const handleChangedSubtitleTrackVisibility = useCallback(() => {
    const subtitleTracks = handleGetSubtitleTracks()
    const isSubtitleTrackVisible = playerRef.current?.isSubtitleTrackVisible()

    if (!isSubtitleTrackVisible) {
      onChangeSubtitleTracks(
        null,
        subtitleTracks.map((item) => ({
          ...item,
          active: item.language === 'none',
        })),
      )
    }
  }, [playerRef, handleGetSubtitleTracks, onChangeSubtitleTracks])

  return {
    preferredAudioLanguage,
    preferredSubtitleLanguage,
    onInitTracks: handleInitTracks,
    onChangeAudioTrack: handleChangeAudioTrack,
    onChangedAudioTrack: handleChangedAudioTrack,
    onChangeSubtitleTrack: handleChangeSubtitleTrack,
    onChangedSubtitleTrack: handleChangedSubtitleTrack,
    onChangedSubtitleTrackVisibility: handleChangedSubtitleTrackVisibility,
  }
}

export default useShakaPlayerTracks
