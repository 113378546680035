// @flow

import { normalize } from 'normalizr'
import typeToReducer from 'type-to-reducer'
import { propOr } from 'ramda'
import tvshowListSchema, { prepareTVShowImages, parseTVShowList } from '@alphaott/common-utils/utils/schemas/tvshows'
import type { TVShow, TVShowMediaStream } from '@alphaott/api-client/types/tvshows'

import {
  FETCH_TVSHOW_DETAIL,
  FETCH_TVSHOW_TRAILER_SOURCE,
  FETCH_TVSHOW_RELATED,
  RESET_TVSHOW_DETAIL,
} from '../../actions'
import { sortSeasons } from '../utils'

type BaseStateType = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
}
const baseState: BaseStateType = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: null,
}

type TrailerState = BaseStateType & {
  +data: ?TVShowMediaStream,
}
type TVShowListState = BaseStateType & {
  +entities: { [id: string]: TVShow },
}

export type NormalizedTrailers = { [id: string]: TrailerState }

export type TVShowDetailState = BaseStateType & {
  +created: ?Date,
  +result: TVShow | {},
  +trailers: NormalizedTrailers,
  +related: TVShowListState,
}

const initialState: TVShowDetailState = {
  ...baseState,
  created: null,
  result: {},
  trailers: {},
  related: {
    ...baseState,
    entities: {},
  },
}

const prepareResult = (data, domain) => {
  const result = prepareTVShowImages(data, domain)
  return {
    ...result,
    // $FlowFixMe
    seasons: result && sortSeasons(propOr([], 'seasons', result)),
  }
}

const tvshowDetail = typeToReducer(
  {
    [RESET_TVSHOW_DETAIL]: () => ({ ...initialState }),
    [FETCH_TVSHOW_DETAIL]: {
      LOADING: (): TVShowDetailState => ({
        ...initialState,
        trailers: {},
        related: { ...baseState, entities: {} },
        isLoading: true,
      }),

      SUCCESS: (state, action): TVShowDetailState => {
        const { payload, meta } = action

        return {
          ...state,
          created: new Date().getTime(),
          isLoading: false,
          isSuccess: true,
          result: prepareResult(payload.data, meta.contentApi),
        }
      },

      ERROR: (state, action): TVShowDetailState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
    [FETCH_TVSHOW_TRAILER_SOURCE]: {
      LOADING: (state, { meta }): TVShowDetailState => ({
        ...state,
        trailers: {
          ...state.trailers,
          [meta.trailerId]: {
            ...baseState,
            isLoading: true,
          },
        },
      }),

      SUCCESS: (state, { payload, meta }): TVShowDetailState => ({
        ...state,
        trailers: {
          ...state.trailers,
          [meta.trailerId]: {
            ...baseState,
            isLoading: false,
            isSuccess: true,
            data: payload.data,
          },
        },
      }),

      ERROR: (state, { payload, meta }): TVShowDetailState => ({
        ...state,
        trailers: {
          ...state.trailers,
          [meta.trailerId]: {
            isLoading: false,
            isSuccess: false,
            isError: true,
            data: {},
            errors: payload.error,
          },
        },
      }),
    },
    [FETCH_TVSHOW_RELATED]: {
      LOADING: (state): TVShowDetailState => ({
        ...state,
        related: {
          ...state.related,
          isLoading: true,
        },
      }),

      SUCCESS: (state, action): TVShowDetailState => {
        const { payload, meta } = action
        const { entities, result } = normalize(payload.data, tvshowListSchema)

        return {
          ...state,
          related: {
            ...state.related,
            created: new Date().getTime(),
            isLoading: false,
            isSuccess: true,
            hasMore: result && result.length > 0,
            entities: { ...state.entities, ...parseTVShowList(entities.tvshows, meta.contentApi) },
          },
        }
      },

      ERROR: (state, action): TVShowDetailState => ({
        ...state,
        related: {
          ...state.related,
          isLoading: false,
          isSuccess: false,
          isError: true,
          errors: action.payload.error,
        },
      }),
    },
  },
  initialState,
)

export { tvshowDetail }
