// @flow

import { useMemo } from 'react'
import { prop, last } from 'ramda'

import { useLastFocusContext } from '../components'

export const useLastFocusedItem = () => {
  const { historyKeys, lastFocusHistory } = useLastFocusContext()

  const lastHistoryKey = useMemo(() => last(historyKeys), [historyKeys])
  const lastFocusedItem = useMemo(
    () => prop(lastHistoryKey, lastFocusHistory),
    [lastHistoryKey, lastFocusHistory],
  )
  const itemFocusKey = useMemo(() => prop('focusKey', lastFocusedItem), [lastFocusedItem])

  return itemFocusKey
}

export default useLastFocusedItem
