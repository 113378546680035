// @flow

export const LOAD_META = 'player/LOAD_META'
export const LOADING = 'player/LOADING'
export const SEEKING = 'player/SEEKING'
export const CAN_PLAY = 'player/CAN_PLAY'
export const PLAY = 'player/PLAY'
export const END = 'player/END'
export const PAUSE = 'player/PAUSE'
export const ERROR = 'player/ERROR'
export const CHANGE_AUDIO_TRACKS = 'player/CHANGE_AUDIO_TRACKS'
export const CHANGE_SUBTITLE_TRACKS = 'player/CHANGE_SUBTITLE_TRACKS'
export const RESET_ERROR = 'player/RESET_ERROR'
export const RESET = 'player/RESET'
