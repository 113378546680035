// @flow
import React, { memo, useCallback, useState } from 'react'
import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'
import { isNotNilOrEmpty } from 'ramda-adjunct'

import { Container, CheckBoxWrapper, CheckIcon, Text } from './CheckBox.styles'

type CheckBoxProps = {
  label: string,
  isChecked?: boolean,
  onChange: Function,
}

const CheckBoxPure = ({
  focusKey,
  label,
  isChecked = false,
  onChange,
  ...props
}: CheckBoxProps) => {
  const [checked, setChecked] = useState(isChecked)

  const handleChangeCheckBox = useCallback(() => {
    setChecked(!checked)
    onChange(checked)
  }, [checked, onChange])

  const { ref, focused, focusSelf } = useFocusable({ focusKey, onEnterPress: handleChangeCheckBox })
  const { handleMouseOver } = useMouseOver(focusSelf)

  return (
    <Container
      {...props}
      ref={ref}
      focused={focused}
      onClick={handleChangeCheckBox}
      onMouseOver={handleMouseOver}
    >
      <CheckBoxWrapper>{checked && <CheckIcon />}</CheckBoxWrapper>
      {isNotNilOrEmpty(label) && <Text>{label}</Text>}
    </Container>
  )
}

export const CheckBox = memo(CheckBoxPure)

export default CheckBox
