// @flow
import { getMovie, getMovieSources, getTrailer, getRelatedMovies } from '@alphaott/api-client'
import { getContentDomain, getPublicDomain } from '@alphaott/app-main/selectors'
import { logoutRequestWrapper } from '@alphaott/app-main/utils'
import { prepareAcceptMediaStreamHeaders, prepareAcceptMediaStreamEncryptedHeaders } from '@alphaott/drm-web'
import { getServiceVodIds } from '@alphaott/app-core/selectors'

import type { Dispatch } from '@alphaott/common-types'

const acceptMediaStream = prepareAcceptMediaStreamHeaders()
const acceptMediaStreamEncrypted = prepareAcceptMediaStreamEncryptedHeaders()

export const INIT_MOVIE_BY_ID_TYPE = 'movies/INIT_BY_ID'
export const FETCH_MOVIE_BY_ID_TYPE = 'movies/FETCH_BY_ID'
export const FETCH_MOVIE_SOURCE_ID_TYPE = 'movies/FETCH_SOURCE_ID'
export const FETCH_MOVIE_TRAILER_ID_TYPE = 'movies/FETCH_TRAILER_ID'
export const FETCH_MOVIE_RELATED_ID_TYPE = 'movies/FETCH_RELATED'
export const RESET_MOVIE_TYPE = 'movies/RESET_MOVIE'

export const fetchMovieById = (id: string) => (dispatch: Dispatch, getState: Function) =>
  dispatch({
    type: FETCH_MOVIE_BY_ID_TYPE,
    payload: getMovie(getPublicDomain(getState()), id),
    meta: {
      contentApi: getContentDomain(getState()),
    },
  })

export const fetchMovieSource = (movieId: string) => (dispatch: Dispatch, getState: Function) =>
  dispatch({
    type: FETCH_MOVIE_SOURCE_ID_TYPE,
    payload: logoutRequestWrapper(getMovieSources)(movieId, { acceptMediaStream, acceptMediaStreamEncrypted })(
      dispatch,
      getState,
    ),
  })

export const fetchMovieTrailer = (movieId: string, trailerId?: number = 0) => (
  dispatch: Function,
  getState: Function,
) =>
  dispatch({
    type: FETCH_MOVIE_TRAILER_ID_TYPE,
    payload: logoutRequestWrapper(getTrailer)(movieId, trailerId)(dispatch, getState),
  })

export const fetchRelatedMovies = (id: string, serviceId: string) => (dispatch: Dispatch, getState: Function) =>
  dispatch({
    type: FETCH_MOVIE_RELATED_ID_TYPE,
    payload: getRelatedMovies(getPublicDomain(getState()), id, serviceId),
    meta: {
      contentApi: getContentDomain(getState()),
      id,
    },
  })

export const initMovie = (id: string) => (dispatch: Dispatch, getState: Function) => {
  const serviceSpec = getServiceVodIds(getState())
  dispatch({
    type: INIT_MOVIE_BY_ID_TYPE,
    payload: Promise.all([
      fetchMovieById(id)(dispatch, getState),
      fetchMovieSource(id)(dispatch, getState),
      fetchMovieTrailer(id)(dispatch, getState),
      fetchRelatedMovies(id, serviceSpec)(dispatch, getState),
    ]),
  })
}

export const resetMovie = {
  type: RESET_MOVIE_TYPE,
}
