// @flow

import React, { memo, forwardRef } from 'react'
import shaka from 'shaka-player-3'

import { defaultConfig } from '../config'

import ShakaPlayerContainer from './ShakaPlayerContainer'

import type { ShakaPlayerContainerProps } from './ShakaPlayerContainer'

type ShakaPlayer3ContainerProps = ShakaPlayerContainerProps & {}

const ShakaPlayer3ContainerPure = (
  {
    config = defaultConfig,
    videoId,
    mediaStream,
    autoplay,
    defaultTimePosition,
    onLoadMeta,
    onCanPlay,
    onLoading,
    onSeeking,
    onSeeked,
    onPlay,
    onPause,
    onEnd,
    onTimeUpdate,
    onError,
    onChangeAudioTracks,
    onChangeSubtitleTracks,
    onDestroy,
  }: ShakaPlayer3ContainerProps,
  playerRef: any,
) => {
  const shakaConfig = {
    ...config,
    streaming: {
      ...config.streaming,
      jumpLargeGaps: true, // https://alpha-ott.atlassian.net/browse/MWSTV-211
    },
  }

  return (
    <ShakaPlayerContainer
      ref={playerRef}
      shaka={shaka}
      config={shakaConfig}
      videoId={videoId}
      mediaStream={mediaStream}
      autoplay={autoplay}
      defaultTimePosition={defaultTimePosition}
      onLoadMeta={onLoadMeta}
      onCanPlay={onCanPlay}
      onLoading={onLoading}
      onSeeking={onSeeking}
      onSeeked={onSeeked}
      onPlay={onPlay}
      onPause={onPause}
      onEnd={onEnd}
      onTimeUpdate={onTimeUpdate}
      onError={onError}
      onChangeAudioTracks={onChangeAudioTracks}
      onChangeSubtitleTracks={onChangeSubtitleTracks}
      onDestroy={onDestroy}
    />
  )
}

export const ShakaPlayer3Container = memo<ShakaPlayer3ContainerProps>(
  forwardRef(ShakaPlayer3ContainerPure),
)

export default ShakaPlayer3Container
