// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { Text } from '@alphaott/smart-tv-components'
import { Button as ButtonBase } from '@alphaott/smart-tv-video-player'

import { ReactComponent as SortIconComponent } from '../../../../assets/sort.svg'
import { ReactComponent as AddCircleIconComponent } from '../../../../assets/addCircleIcon.svg'
import { ReactComponent as RemoveCircleIconComponent } from '../../../../assets/removeCircleIcon.svg'
import { ReactComponent as ViewListIconComponent } from '../../../../assets/viewListIcon.svg'
import { ReactComponent as ClosedCaptionIconComponent } from '../../../../assets/closedCaptionIcon.svg'
import { ReactComponent as ListIconComponent } from '../../../../assets/listIcon.svg'
import { ReactComponent as FeaturedVideoIconComponent } from '../../../../assets/featuredVideoIcon.svg'
import { ReactComponent as RadioButtonCheckedIconComponent } from '../../../../assets/radioButtonCheckedIcon.svg'

export const Container = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin-top: 24rem;

  button:not(:first-child) {
    margin-left: 24rem;
  }
`

export const ButtonsContainer = styled(Flex)``

export const Button = styled(ButtonBase)``

export const Sort = styled(Flex)``

export const SortIcon = styled(SortIconComponent)`
  width: 23rem;
  height: 41rem;
`

export const SortLabel = styled(Text)`
  font-weight: bold;
  font-size: 28rem;
  line-height: 34rem;
  letter-spacing: 1rem;
  color: ${(props) => props.theme.palette.grey[100]};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 350rem;
  margin-left: 8rem;
`

export const ViewListIcon = styled(ViewListIconComponent)`
  width: 49rem;
  height: 40rem;
`

export const AddCircleIcon = styled(AddCircleIconComponent)`
  width: 40rem;
  height: 40rem;
`

export const RemoveCircleIcon = styled(RemoveCircleIconComponent)`
  width: 40rem;
  height: 40rem;
`

export const ClosedCaptionIcon = styled(ClosedCaptionIconComponent)`
  width: 45rem;
  height: 40rem;
`

export const ListIcon = styled(ListIconComponent)`
  width: 50rem;
  height: 39rem;
`

export const FeaturedVideoIcon = styled(FeaturedVideoIconComponent)`
  width: 49rem;
  height: 40rem;
`

export const RadioButtonCheckedIcon = styled(RadioButtonCheckedIconComponent)`
  width: 40rem;
  height: 40rem;
`
