// @flow

export const PLATFORMS = {
  WEB: 'WEB',
  TIZEN: 'TIZEN',
  WEBOS: 'WEBOS',
}

export const BUTTON_KEY_NAMES = {
  BACK: 'BACK',
  CHANNEL_UP: 'CHANNEL_UP',
  CHANNEL_DOWN: 'CHANNEL_DOWN',
  ENTER: 'ENTER',
  ENTER_UP: 'ENTER_UP',
  ARROW_LEFT: 'ARROW_LEFT',
  ARROW_UP: 'ARROW_UP',
  ARROW_RIGHT: 'ARROW_RIGHT',
  ARROW_DOWN: 'ARROW_DOWN',
}

export const BUTTON_KEY_CODES = {
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  BACK: 27,
}
