// @flow

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorComponent } from '@alphaott/smart-tv-components'

type NotFoundProps = {
  onClickButton?: () => void,
}

export const NotFound = ({ onClickButton }: NotFoundProps) => {
  const { t } = useTranslation()

  const SubTitle = useMemo(
    () => (
      <>
        {t('sorry_this_page_does_not_exist')}
        <br />
        {t('mwstv_error_not_found_page_return_back')}
      </>
    ),
    [t],
  )

  return (
    <ErrorComponent
      title={t('page_not_found')}
      subTitle={SubTitle}
      buttonLabel={t('home_lowercase')}
      onClickActionButton={onClickButton}
    />
  )
}

export default NotFound
