// @flow
import { find, join, not, propEq, reject, isEmpty } from 'ramda'

import { getMovie, getMoviesByIds, getPersonalList, putPersonalList } from '@alphaott/api-client'
import { getCustomerId } from '@alphaott/app-core/models/selectors'
import { getContentDomain, getPublicDomain } from '@alphaott/app-main/selectors'

import { isNilOrEmpty } from '@alphaott/common-utils/utils/help'
import { logoutRequestWrapper } from '@alphaott/app-main/utils'
import { PERCENTAGE_TO_COMPLETED } from '@alphaott/app-config'

import type { Dispatch } from '@alphaott/common-types'

import { getRecentIds, getRecentMovieState, isRecentMovie } from '../selectors'
import type { MovieProgress } from '../reducers'

export const UPDATE_RECENT_MOVIE_TYPE = 'movie/UPDATE_RECENT'
export const FETCH_RECENT_MOVIE_TYPE = 'movie/FETCH_RECENT'
export const FETCH_RECENT_MOVIE_BY_IDS_TYPE = 'movie/FETCH_RECENT_MOVIE_BY_IDS_TYPE'
export const FETCH_CURRENT_RECENT_MOVIE_TYPE = 'movie/FETCH_CURRENT_RECENT'
export const GET_RECENT_MOVIES_TYPE = 'movie/GET_RECENT'

export const fetchCurrentMovie = (id: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_CURRENT_RECENT_MOVIE_TYPE,
    payload: getMovie(getPublicDomain(getState()), id),
    meta: {
      contentApi: getContentDomain(getState()),
    },
  })

export const updateMovieRecentWatched = (progress: MovieProgress) => async (dispatch: Function, getState: Function) => {
  const customerId = getCustomerId(getState())
  const { id, total, current } = progress
  const completed = current > total * PERCENTAGE_TO_COMPLETED

  const isNotRecent = not(isRecentMovie(id)(getState()))

  const data = {
    ...progress,
    completed,
  }
  // eslint-disable-next-line no-useless-catch
  try {
    await dispatch({
      type: UPDATE_RECENT_MOVIE_TYPE,
      payload: logoutRequestWrapper(putPersonalList)(customerId, 'movie', 'history', id, data)(dispatch, getState),
    })
    if (isNotRecent) dispatch(fetchCurrentMovie(id))
  } catch (err) {
    throw err
  }
}

export const getRecentMovies = async (dispatch: Dispatch, getState: Function) => {
  const customerId = getCustomerId(getState())

  return dispatch({
    type: GET_RECENT_MOVIES_TYPE,
    payload: logoutRequestWrapper(getPersonalList)(customerId, 'movie', 'history')(dispatch, getState),
  })
}

export const fetchRecentMoviesByIds = (dispatch: Dispatch, getState: Function) => {
  const recentMovies = getRecentMovieState(getState())
  const idList = reject(id => find(propEq('_id', id))(recentMovies.data), getRecentIds(getState()))

  if (isEmpty(idList)) return null

  return dispatch({
    type: FETCH_RECENT_MOVIE_BY_IDS_TYPE,
    payload: getMoviesByIds(getPublicDomain(getState()), join(',', idList)),
    meta: {
      contentApi: getContentDomain(getState()),
    },
  })
}

export const fetchRecentMovie = () => async (dispatch: Dispatch, getState: Function) => {
  const customerId = getCustomerId(getState())

  if (isNilOrEmpty(customerId)) return null

  return dispatch({
    type: FETCH_RECENT_MOVIE_TYPE,
    payload: Promise.all([await dispatch(getRecentMovies), dispatch(fetchRecentMoviesByIds)]),
  })
}
