// @flow weak
import { schema } from 'normalizr'
import { find, propEq, map, pipe, prop, ifElse, isEmpty, join, head, or, isNil, propOr } from 'ramda'

import type { Channel } from '@alphaott/api-client/types/channels'
import type { Genre } from '@alphaott/api-client/types/genres'
import type { Category } from '@alphaott/api-client/types/categories'
import type { CatchupChannelOption, CatchupChannels } from '@alphaott/api-client/types/catchup/channels'

import { defaultToEmptyPath, isNotEmpty } from '../../help'
import { prepareLogos } from '../../prepareLogos'

type FavoriteChannels = Array<Object>

export type PreparedChannel = Channel & {
  image: ?string,
  backgrounds: ?string,
  description: string,
  isFavorite: boolean,
  hasCatchup: boolean,
  catchupOptions?: CatchupChannelOption,
  subtitle?: string,
}

const idAttribute = '_id'

export const channelSchema = new schema.Entity('channels', {}, { idAttribute })
export const channelListSchema = new schema.Array(channelSchema)

export default channelListSchema

export const prepareChannelImages = (channels: Array<Channel>, domain: string): Array<Channel> => {
  if (isNil(channels) || isEmpty(channels)) return []

  return map(channel => ({
    ...channel,
    logos: prepareLogos(channel.logos, domain),
    backgrounds: prepareLogos(channel.backgrounds, domain),
  }))(channels)
}

// TODO: Improve!
export const mergeGenresCategories = (item: Channel, genres: Array<Genre>, categories: Array<Category>): string => {
  if (isNil(item) || isEmpty(item)) return ''

  const findElem = list => elem => find(propEq('_id', elem), list)
  const findTitles = list =>
    map(elem =>
      pipe(
        findElem(list),
        prop('title'),
      )(elem),
    )

  const description = ifElse(
    pipe(
      prop('genres'),
      isEmpty,
    ),
    pipe(
      prop('categories'),
      findTitles(categories),
    ),
    pipe(
      prop('genres'),
      findTitles(genres),
    ),
  )
  return join(', ', description(item))
}

/**
 *  arg = [{ path: 'url' }]
 *  head => { path: 'url' }
 *  defaultToEmptyPath => ''
 *  prop => 'url'
 */
const prepareImage = pipe(
  head,
  defaultToEmptyPath,
  prop('path'),
)

// eslint-disable-next-line arrow-body-style
const prepareSubtitle = (item: Channel, genres: Array<Genre>, categories: Array<Category>): string => {
  return or(isNotEmpty(genres), isNotEmpty(categories)) ? mergeGenresCategories(item, genres, categories) : ''
}

/* eslint-disable no-param-reassign */
export const prepareChannel = (
  item: Channel,
  favorite: FavoriteChannels,
  genres: Array<Genre>,
  categories: Array<Category>,
  catchupOptions?: CatchupChannelOption,
): PreparedChannel =>
  // $FlowFixMe
  ({
    ...item,
    image: isNil(item.logos) ? null : prepareImage(item.logos),
    backgroundImage: isNil(item.backgrounds) ? null : prepareImage(item.backgrounds),
    subtitle: prepareSubtitle(item, genres, categories),
    isFavorite: !!find(propEq('item', item._id))(favorite),
    hasCatchup: Boolean(propOr(0, 'window', catchupOptions)),
    catchupOptions,
  })

export const prepareChannelList = (
  items: Array<Channel>,
  favorite: FavoriteChannels,
  genres: Array<Genre>,
  categories: Array<Category>,
  catchupChannels?: CatchupChannels = {},
): Array<PreparedChannel> =>
  items.map(item => prepareChannel(item, favorite, genres, categories, catchupChannels[item._id]))
