// @flow

import React, { memo, useMemo } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { map } from 'ramda'

import { Container, ItemContainer, ItemLabel, ItemValue } from './Crew.style'

type ItemProps = {
  label: string,
  value: string,
}

const Item = ({ label, value }: ItemProps) => (
  <ItemContainer key={label}>
    <ItemLabel>{label}:</ItemLabel>
    <ItemValue>
      <Dotdotdot clamp={5}>{value}</Dotdotdot>
    </ItemValue>
  </ItemContainer>
)

export type CrewProps = {
  className?: any,
  items: ItemProps[],
}

const CrewPure = ({ className, items }: CrewProps) => {
  const list = useMemo(() => map(Item, items), [items])

  return <Container className={className}>{list}</Container>
}

export const Crew = memo<CrewProps>(CrewPure)

export default Crew
