import styled from 'styled-components'

import { getColorPrimary, getColorSecondaryContrastText } from '@alphaott/smart-tv-themes'

import { ReactComponent as CheckIconPure } from '../../assets/checkIcon.svg'

const getColor = ({ focused }) => (focused ? getColorPrimary : getColorSecondaryContrastText)

export const Container = styled.button`
  display: flex;
  align-items: center;
  margin-top: 30rem;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  & > * {
    color: ${getColor};
    border-color: ${getColor};
    fill: ${getColor};
  }
`

export const CheckBoxWrapper = styled.div`
  width: 38rem;
  height: 38rem;
  margin-right: 20rem;

  border: 4rem solid;
  border-radius: 4rem;
`

export const CheckIcon = styled(CheckIconPure)`
  width: 38rem;
  height: 38rem;
`

export const Text = styled.div`
  font-weight: 300;
  font-size: 42rem;
  line-height: 50rem;
  margin-top: 0;
  text-align: center;
`
