// @flow

import React, { memo, useCallback, useMemo, useRef } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList as List } from 'react-window'
import { prop, and, equals } from 'ramda'

import { scale } from '@alphaott/smart-tv-utils'

import { ProfileCard, AddProfileCard } from '../../ProfileCard'
import {
  PROFILE_CARD_WIDTH,
  PROFILE_CARD_HEIGHT,
  ADD_PROFILE_CARD_HEIGHT,
  PROFILE_CARD_PADDING,
} from '../../ProfileCard/ProfileCard.style'

export type ProfilesListProps = {
  className?: string,
  profiles: Object[],
  onAddProfile?: Function,
  onChangeProfile?: Function,
  onEditProfile?: Function,
}

export type ProfileItem = {
  id: string,
  name: string,
  avatar: any,
  isAddNewProfileCard?: boolean,
}

export type ProfileCardItemProps = {
  index: number,
  style?: any,
  data: {
    items: ProfileItem[],
    onAddProfile?: Function,
    onChangeProfile?: Function,
    onEditProfile?: Function,
    onBecameFocused: Function,
  },
}

const listStyle = { overflow: 'hidden' }

// eslint-disable-next-line react/display-name
const ProfileCardItem = memo(({ index, style, data }: ProfileCardItemProps) => {
  const items = useMemo(() => prop('items', data), [data])
  const onAddProfile = useMemo(() => prop('onAddProfile', data), [data])
  const onChangeProfile = useMemo(() => prop('onChangeProfile', data), [data])
  const onEditProfile = useMemo(() => prop('onEditProfile', data), [data])
  const onBecameFocused = useMemo(() => prop('onBecameFocused', data), [data])

  const item = useMemo(() => prop(index, items), [index, items])

  const id = useMemo(() => prop('id', item), [item])
  const name = useMemo(() => prop('name', item), [item])
  const avatar = useMemo(() => prop('avatar', item), [item])
  const isAddNewProfileCard = useMemo(() => prop('isAddNewProfileCard', item), [item])

  const handleBecameFocused = useCallback(
    (arg) => onBecameFocused(index, arg),
    [index, onBecameFocused],
  )

  const handleChangeProfile = useCallback(() => {
    and(onChangeProfile, onChangeProfile(id))
  }, [id, onChangeProfile])

  const handleEditProfile = useCallback(() => {
    and(onEditProfile, onEditProfile(id))
  }, [id, onEditProfile])

  const handleAddProfile = useCallback(() => {
    and(onAddProfile, onAddProfile())
  }, [onAddProfile])

  if (isAddNewProfileCard) {
    return (
      <AddProfileCard
        key={id}
        focusKey={id}
        style={style}
        onAddProfile={handleAddProfile}
        onFocus={handleBecameFocused}
      />
    )
  }

  return (
    <ProfileCard
      key={id}
      focusKey={id}
      style={style}
      name={name}
      avatar={avatar}
      onChange={handleChangeProfile}
      onEdit={handleEditProfile}
      onFocus={handleBecameFocused}
    />
  )
})

const ProfileListPure = ({
  className,
  profiles,
  onAddProfile,
  onChangeProfile,
  onEditProfile,
}: ProfilesListProps) => {
  const listRef = useRef()

  const profilesLength = useMemo(() => prop('length', profiles), [profiles])

  const getItemSize = useCallback(
    () => scale(window.innerWidth, PROFILE_CARD_WIDTH + PROFILE_CARD_PADDING),
    [],
  )
  const listHeight = useMemo(
    () =>
      scale(
        window.innerWidth,
        equals(profilesLength, 1) ? ADD_PROFILE_CARD_HEIGHT : PROFILE_CARD_HEIGHT,
      ),
    [profilesLength],
  )
  const listWidth = useMemo(
    () => Math.min(profilesLength * getItemSize(), window.innerWidth),
    [profilesLength, getItemSize],
  )

  const getAutoSize = useCallback((width) => (listWidth > width ? width : listWidth), [listWidth])

  const onBecameFocused = useCallback(
    (index) => {
      if (listRef.current) listRef.current.scrollToItem(index, 'center')
    },
    [listRef],
  )

  return (
    <AutoSizer className={className} style={{ width: listWidth, height: listHeight }}>
      {({ width }) => (
        <List
          ref={listRef}
          layout="horizontal"
          items={profiles}
          height={listHeight}
          width={getAutoSize(width)}
          itemCount={profilesLength}
          itemSize={getItemSize}
          itemData={{
            items: profiles,
            onAddProfile,
            onChangeProfile,
            onEditProfile,
            onBecameFocused,
          }}
          style={listStyle}
        >
          {ProfileCardItem}
        </List>
      )}
    </AutoSizer>
  )
}

export const ProfileList = memo(ProfileListPure)

export default ProfileList
