// @flow
import typeToReducer from 'type-to-reducer'
import { take, map, always, T, cond, uniq, reduce, propOr } from 'ramda'

import type { TVShow } from '@alphaott/api-client/types/tvshows'

import { prepareTVShowImages } from '@alphaott/common-utils/utils/schemas/tvshows'
import {
  RECENT_TVSHOW_MAX_COUNT,
  TVSHOW_EPISODE_COMPLETED_MIN_TIME,
  TVSHOW_EPISODE_COMPLETED_MIN_PERCENTAGE,
} from '@alphaott/app-config'
import {
  GET_RECENT_WATCHED_TVSHOW,
  FETCH_RECENT_TVSHOW,
  FETCH_RECENT_TVSHOW_BY_IDS,
  FETCH_RECENT_CURRENT_TVSHOW,
  UPDATE_RECENT_TVSHOW_LIST,
} from '../../actions/tvshowRecentWatched'

export const PROGRESS_STATUS = {
  notWatched: 'notWatched', // if the Show is not in the Recently Watched List
  completed: 'completed', // if (total – current ≤ Tmin) AND (current / total ≥ Pmin)
  unfinished: 'unfinished',
}

export type ProgressStatusValues = $Keys<typeof PROGRESS_STATUS>

export type EpisodeProgress = {
  status: ProgressStatusValues,
  total: number, // in min
  current: number, // in min
  lastUpdated: number, // in ms
}
export type SeasonProgress = {
  // status: ProgressStatusValues,
  // episodesInSeason: number,
  episodes: {
    [episodeId: string]: EpisodeProgress,
  },
}

export type TVShowProgress = {
  // status: ProgressStatusValues,
  lastUpdated: number,
  lastEpisodeId?: string,
  lastSeasonId?: string,
  // seasonsTotal?: number,
  seasons: { [seasonId: string]: SeasonProgress },
}

export type NormalizedTVShowProgress = { [showId: string]: TVShowProgress }
export type RecentTVShowProgressState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: {
    idList: string[],
    entities: NormalizedTVShowProgress,
  },
}

const initialProgressState: RecentTVShowProgressState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: {
    idList: [],
    entities: {},
  },
}

export const countProgressStatus = cond([
  [
    ({ current, total }) =>
      total - current <= TVSHOW_EPISODE_COMPLETED_MIN_TIME &&
      current / total >= TVSHOW_EPISODE_COMPLETED_MIN_PERCENTAGE,
    always(PROGRESS_STATUS.completed),
  ],
  [T, always(PROGRESS_STATUS.unfinished)],
])

const tvshowRecentProgress = typeToReducer(
  {
    [UPDATE_RECENT_TVSHOW_LIST]: {
      LOADING: (state): RecentTVShowProgressState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): RecentTVShowProgressState => {
        const {
          payload: { data: response },
        } = action
        const item = propOr('', 'item', response)
        const show = propOr({}, 'data', response)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: {
            idList: uniq([...state.data.idList, item]),
            entities: {
              ...state.data.entities,
              [item]: show,
            },
          },
        }
      },

      ERROR: (state, action): RecentTVShowProgressState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: {
          idList: [],
          entities: {},
        },
        errors: action.payload.error,
      }),
    },

    [GET_RECENT_WATCHED_TVSHOW]: {
      LOADING: (state): RecentTVShowProgressState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): RecentTVShowProgressState => {
        const {
          payload: { data },
        } = action

        const maxCountTVShows = take(RECENT_TVSHOW_MAX_COUNT, data)

        const idList = uniq(map(elem => elem.item, maxCountTVShows))
        const entities = reduce(
          (acc, elem) => {
            acc[elem.item] = propOr({}, 'data', elem)
            return acc
          },
          {},
          maxCountTVShows,
        )

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: {
            idList,
            entities,
          },
        }
      },

      ERROR: (state, action): RecentTVShowProgressState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: {
          idList: [],
          entities: {},
        },
        errors: action.payload.error,
      }),
    },
  },
  initialProgressState,
)

export type RecentTVShowState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Array<TVShow>,
}

const initialState: RecentTVShowState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
}

const tvshowRecent = typeToReducer(
  {
    [FETCH_RECENT_TVSHOW]: {
      LOADING: (state): RecentTVShowState => ({ ...state, isLoading: true }),

      SUCCESS: (state): RecentTVShowState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
      }),

      ERROR: (state, action): RecentTVShowState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: [],
        errors: action.payload.error,
      }),
    },
    [FETCH_RECENT_CURRENT_TVSHOW]: {
      LOADING: (state): RecentTVShowState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): RecentTVShowState => {
        const {
          payload: { data: response },
          meta,
        } = action

        const preparedTVShow = prepareTVShowImages(response, meta.contentApi)
        const data = [...state.data, preparedTVShow]

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data,
        }
      },

      ERROR: (state, action): RecentTVShowState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: [],
        errors: action.payload.error,
      }),
    },
    [FETCH_RECENT_TVSHOW_BY_IDS]: {
      LOADING: (state): RecentTVShowState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): RecentTVShowState => {
        const {
          payload: { data },
          meta,
        } = action
        const newData = map(load => prepareTVShowImages(load, meta.contentApi), data)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: [...state.data, ...newData],
        }
      },

      ERROR: (state, action): RecentTVShowState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: [],
        errors: action.payload.error,
      }),
    },
  },
  initialState,
)

export { tvshowRecentProgress }
export { tvshowRecent }
