// @flow weak

import { isEmpty } from 'ramda'
import { createSelector } from 'reselect'

import {
  defaultToEmptyArray,
  defaultToEmptyObject,
  defaultToZero,
  isInitialLoading,
} from '@alphaott/common-utils/utils/help'

import type { Program } from '@alphaott/api-client/types/programs'

import { getProgramState, getProgramById } from '../tabs/selectors'
import type { CurrentEpg, CurrentEpgIndexes, PreparedPrograms } from '../tabs/selectors'

import { preparePrograms } from '../../utils'
import type { ProgramState } from '../../reducers'

export const getProgramList = (id: string) =>
  createSelector(
    [getProgramState],
    (programState: ProgramState): PreparedPrograms => preparePrograms(defaultToEmptyArray(programState.data[id])),
  )

export const isLoadingProgram = createSelector(
  [getProgramState],
  (programState: ProgramState): boolean => isInitialLoading(programState),
)

export const isEmptyProgram = (id: string) =>
  createSelector(
    [getProgramList(id)],
    (list: PreparedPrograms): boolean => isEmpty(list),
  )

export const getCurrentIndexes = (id: string) =>
  createSelector(
    [getProgramState],
    (state: ProgramState): CurrentEpgIndexes => defaultToEmptyObject(state.current[id]),
  )

export const getCurrentEpgById = (id: string) =>
  createSelector(
    [getCurrentIndexes(id), getProgramList(id)],
    (indexes: CurrentEpgIndexes, list: PreparedPrograms): CurrentEpg => {
      if (isEmpty(indexes)) return {}
      const { current, next } = indexes
      return {
        current: list[defaultToZero(current)],
        next: list[defaultToZero(next)],
      }
    },
  )

export const getEpgById = (channelId: string, programId: string) =>
  createSelector(
    [getProgramById(channelId, programId)],
    // $FlowFixMe
    (program: ?Program): CurrentEpg => (program ? { current: program } : {}),
  )
