// @flow
import { makeGetRequest } from '../../utils'

import type { ResponseAPI } from '../../types/api'
import type { MovieMediaStreams } from '../../types/movies'
import type { Options } from '../../utils/getStreamEncryptHeader'

import { getStreamHeader } from '../../utils/getStreamEncryptHeader'

export const getTvShowSources = (
  domain: string,
  tvShowId: string,
  seasonId: string,
  episodeId: string,
  options: Options,
): Promise<ResponseAPI<MovieMediaStreams>> => {
  const headers = getStreamHeader(options)

  return makeGetRequest(
    `${domain}/client/api/v3/vod/tv-show/${tvShowId}/season/${seasonId}/episode/${episodeId}/sources`,
    {
      headers,
    },
  )
}
