// @flow
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import i18NextDomainDetector from '@alphaott/i18next-domain-detector'

// ToDo: load locales from CDN https://github.com/i18next/i18next-xhr-backend
import { ru, en, he, es, pt, fr, el, ro } from '@alphaott/locales'

const isDev: boolean = process.env.NODE_ENV === 'development'

const languageDetector = new LanguageDetector()
languageDetector.addDetector(i18NextDomainDetector)

/**
 * Detector Options
 * https://github.com/i18next/i18next-browser-languageDetector#detector-options
 */
const detection = {
  caches: ['localStorage'],
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'domain',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],
}

/**
 * ISO 639
 * https://www.iso.org/iso-639-language-codes.html
 * http://www.loc.gov/standards/iso639-2/php/code_list.php
 */
// eslint-disable-next-line complexity
export const configureI18n = (config: Object) =>
  i18n
    // .use(Locize)
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(languageDetector)
    .init({
      ns: ['common'],
      defaultNS: 'common',

      resources: { en, ru, he, es, pt, fr, el, ro },

      lng: localStorage.getItem('i18nextLng') ? null : 'en',
      fallbackLng: 'en',

      nsSeparator: '::',
      keySeparator: '.',

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },

      debug: isDev,
      saveMissing: isDev, // calls save missing key function on backend if key not found

      detection,

      ...(config.lng && {
        lng: config.lng,
      }),
      ...(config.supportedLngs && {
        supportedLngs: config.supportedLngs,
      }),
    })

export default configureI18n
