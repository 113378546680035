// @flow

import { mapIndexed } from 'ramda-adjunct'
import { prop, propOr, split, toUpper } from 'ramda'

import getBase from './getBase'
import getLanguageName from './getLanguageName'

export const prepareLanguageKeyWithDialect = (language: ?string) => {
  if (language) {
    const [languageKey, dialect] = split('-', language)

    if (dialect) {
      return `${languageKey}-${toUpper(dialect)}`
    }

    return language
  }

  return null
}

const prepareLanguage = (language: ?string) => {
  if (language) {
    const splitAt = language.indexOf('-')
    // If splitAt >= 0, then we use all variants, or two-character after preparation getBase
    return splitAt >= 0 ? language : getBase(language)
  }

  return null
}

export const prepareTracks = (tracks: Object[]) =>
  mapIndexed((track, index) => {
    const number = index + 1
    const languageTrack = prepareLanguageKeyWithDialect(prop('language', track))
    const language = prepareLanguage(languageTrack)
    const trackName = getLanguageName(number, language, track)
    const label = `${number}. ${trackName}`
    const active = propOr(propOr(false, 'enabled', track), 'active', track)

    return {
      ...track,
      ...(language && { language }),
      label,
      active,
    }
  }, tracks)

export default prepareTracks
