// @flow
import { ifElse, isNil, always } from 'ramda'

import type { Headers } from '../types/api'

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const makeHeaders = ({ token, ...args }: Headers = {}) =>
  ifElse(isNil, always({ ...defaultHeaders, ...args }), always({ ...defaultHeaders, Authorization: token, ...args }))(
    token,
  )

export default makeHeaders
