// @flow
import { LARGE_VIEWPORT, MEDIUM_VIEWPORT, SMALL_VIEWPORT } from '@alphaott/app-config'
import { rgba } from 'polished'

import { colorPalette } from '../colorPalettes'
import type { ColorType } from '../colorPalettes'

export const streamnxtColors: ColorType = {
  ...colorPalette,
  primary: '#DD157B',
  secondary: '#DD157B',
}

export const lightTheme = {
  colors: streamnxtColors,
  background: {
    primary: '#fafafa',
    secondary: '#fafafa',
    highlight: streamnxtColors.light,
    select: '#fafafa',
    footerTop: '#eee',
  },
  text: {
    primary: streamnxtColors.dark,
    secondary: streamnxtColors.grayText,
    disabled: streamnxtColors.dark,
    link: streamnxtColors.dark,
    hint: streamnxtColors.dark,
    highlight: streamnxtColors.primary,
    gray: streamnxtColors.grayText,
    seeAll: 'rgba(52, 73, 94, 0.8)',
    primaryContrast: '#ffffff',
  },
  border: {
    primary: '#dddddd',
    gray: '#ddd',
  },
  filter: {
    primary: '#43484d',
    secondary: '#43484d',
    disabled: streamnxtColors.grayLabel,
    highlight: '#fff',
  },
  tabs: {
    bg: '#fafafa',
    containerBg: streamnxtColors.dark,
    color: streamnxtColors.dark,
    border: streamnxtColors.primary,
    scrollbarThumb: streamnxtColors.primary,
    programTitle: streamnxtColors.white,
  },
  programGuide: {
    programBox: {
      background: {
        main: 'rgba(221, 221, 221, 0.8)',
        hover: streamnxtColors.dark,
        selected: streamnxtColors.dark,
        progress: rgba(streamnxtColors.primary, 0.3),
        past: rgba(streamnxtColors.primary, 0.3),
        pastSelected: streamnxtColors.dark,
        pastHover: streamnxtColors.dark,
        empty: 'rgba(221, 221, 221, 0.3)',
        emptyProgress: rgba(streamnxtColors.primary, 0.1),
      },
      text: {
        empty: streamnxtColors.grayLabel,
      },
    },
    programGuidePictureOverlay: {
      horizontal:
        // eslint-disable-next-line max-len
        `linear-gradient(90deg, #fafafa 0%,${rgba('#fafafa', 0.97)} 13.03%,${rgba('#fafafa', 0.69)} 43.24%,${rgba(
          '#fafafa',
          0,
        )} 100%);`,
      vertical: `linear-gradient(0,#fafafa 0%,${rgba('#fafafa', 0.73)} 34.97%,${rgba('#fafafa', 0)} 100%);`,
    },
  },
  card: {
    title: streamnxtColors.dark,
    subTitle: streamnxtColors.grayText,
    bg: streamnxtColors.dark,
    number: streamnxtColors.grayBorder,
    boxShadow: 'rgba(221, 221, 221, 0.8)',
    loaderBackgroundImage: 'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
  },
  dimension: {},
  size: 'default',
  breakpoints: [`${SMALL_VIEWPORT}px`, `${MEDIUM_VIEWPORT}px`, `${LARGE_VIEWPORT}px`],
  signIn: {
    clipPath: false,
  },
}
