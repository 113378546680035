// @flow
import { makeGetRequest } from '@alphaott/api-client/utils'
import type { BrandConfig } from '@alphaott/common-types'

import { FALLBACK_BRAND_CONFIG_PATH } from '../../const'

export const FETCH_BRAND_CONFIG_TYPE = 'brand_config/FETCH_BRAND_CONFIG'
export const SET_BRAND_CONFIG_TYPE = 'brand_config/SET_BRAND_CONFIG'

export const setBrandConfig = (brandConfig: BrandConfig) => ({
  type: SET_BRAND_CONFIG_TYPE,
  payload: brandConfig,
})

export const fetchBrandConfig = (url: string = FALLBACK_BRAND_CONFIG_PATH) => ({
  type: FETCH_BRAND_CONFIG_TYPE,
  payload: makeGetRequest(url),
})
