// @flow
import { map, not } from 'ramda'
import { push } from 'redux-first-history'
import { format, startOfToday } from 'date-fns'

import { getProgramsByDate } from '@alphaott/api-client/channels/programs'
import { getPublicDomain } from '@alphaott/app-main/selectors'

import { FETCH_PROGRAMS_BY_DATE_TYPE } from '../../tabs/actions'
import { isExistPrograms, getProgramGridPath } from '../selectors'
import { DATE_FORMAT } from '../../../const'

export const SELECT_PROGRAM_GUIDE = 'programs/SELECT_PROGRAM_GUIDE'
export const FETCH_PROGRAMS_GUIDE = 'programs/FILTER_PROGRAM_GUIDE'

export type FilterType = 'all' | 'favorite' | 'most'

export type PushQueryParam = {
  [string]: any,
}

type FetchChannelData = {
  channelIds: Array<string>,
  date: Date,
  offset?: number,
  include?: boolean,
}

export const selectedProgramProgramGuideChannel = (channel: any, program: any): any => ({
  type: SELECT_PROGRAM_GUIDE,
  channel,
  program,
})

export const fetchProgramsByDate = (
  id: string,
  offset?: number,
  include?: boolean,
  date?: number | Date = startOfToday(),
) => (dispatch: Function, getState: Function) => {
  const start = format(date, DATE_FORMAT)
  return dispatch({
    type: FETCH_PROGRAMS_BY_DATE_TYPE,
    payload: getProgramsByDate(getPublicDomain(getState()), id, start, offset, include),
    meta: { id, start },
  })
}

const fetchAllChannels = (
  { channelIds, date, offset, include }: FetchChannelData,
  dispatch: Function,
  getState: Function,
) => {
  const state = getState()

  const shouldFetchPrograms = channelId => not(isExistPrograms(channelId, date)(state))
  const promises = []
  map(channelId => {
    if (shouldFetchPrograms(channelId)) {
      promises.push(dispatch(fetchProgramsByDate(channelId, offset, include, date)))
    }
  }, channelIds)

  return Promise.all(promises)
}

export const fetchProgramsByDateForChannels = (
  channelIds: Array<string>,
  date: Date,
  offset?: number,
  include?: boolean,
) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_PROGRAMS_GUIDE,
    payload: fetchAllChannels({ channelIds, date, offset, include }, dispatch, getState),
  })

export const pushParams = (params: PushQueryParam) => (dispatch: Function, getState: Function) => {
  const path = getProgramGridPath(params)(getState())
  return dispatch(push(path))
}
