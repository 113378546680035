// @flow

import React from 'react'
import { useSelector } from 'react-redux'
import { and, has, isEmpty, not } from 'ramda'

import { getAppAnalyticsConfig, getAppAnalyticsName, isAppAnalyticsEnabled } from '@alphaott/app-main/selectors'

import { AppAnalyticsProvider } from './AppAnalyticsProvider'

import { ANALYTICS_PLUGINS } from '../const'

export type AppAnalyticsProps = {
  children: any,
}

export type AnalyticsInitProps = AppAnalyticsProps & {}

const AnalyticsInit = ({ children }: AnalyticsInitProps) => {
  const analyticsName = useSelector(getAppAnalyticsName)
  const analyticsConfig = useSelector(getAppAnalyticsConfig)

  const isAvailableConfig = and(not(isEmpty(analyticsConfig)), has(analyticsName)(ANALYTICS_PLUGINS))

  if (isAvailableConfig) {
    return <AppAnalyticsProvider>{children}</AppAnalyticsProvider>
  }

  return children
}

export const AppAnalytics = ({ children }: AppAnalyticsProps) => {
  const isAnalyticsEnabled = useSelector(isAppAnalyticsEnabled)

  if (isAnalyticsEnabled) {
    return <AnalyticsInit>{children}</AnalyticsInit>
  }

  return children
}

export default AppAnalytics
