// @flow

import React, { forwardRef, useEffect } from 'react'

import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'

import { DotContainer, SeekTime, Dot as DotComponent } from './elements'

const FocusableDot = ({ onFocusedDot }) => {
  const { ref, focused, focusSelf } = useFocusable()
  const { handleMouseOver } = useMouseOver(focusSelf)

  useEffect(() => {
    onFocusedDot(focused)
  }, [focused, onFocusedDot])

  return <DotComponent ref={ref} focused={focused} onMouseOver={handleMouseOver} />
}

// eslint-disable-next-line react/display-name
export const Dot = forwardRef(
  ({ seekTime = 0, isVisible, isSeeking, isFocused, onFocusedDot }, ref) => (
    <DotContainer ref={ref} isVisible={isVisible} isSeeking={isSeeking}>
      {isFocused && <SeekTime>{seekTime}</SeekTime>}
      <FocusableDot onFocusedDot={onFocusedDot} />
    </DotContainer>
  ),
)
