// @flow
import { LARGE_VIEWPORT, MEDIUM_VIEWPORT, SMALL_VIEWPORT } from '@alphaott/app-config'
import { rgba } from 'polished'

import { colorPalette } from '../colorPalettes'
import type { ColorType } from '../colorPalettes'

const colors: ColorType = {
  ...colorPalette,
  grayText: colorPalette.grayLabel,
  grayLabel: '#717171',
  grayBorder: 'rgba(255, 255, 255, 0.38)',
  primary: '#7F00EC',
  secondary: colorPalette.white,
}

export const darkTheme = {
  colors,
  background: {
    primary: '#111111',
    secondary: '#262626',
    highlight: colors.dark,
    select: '#2e2e2e',
    footerTop: '#333',
  },
  text: {
    primary: colors.primaryGray,
    secondary: colors.secondaryGray,
    disabled: colors.grayText,
    link: colors.secondaryGray,
    hint: colors.white,
    highlight: colors.secondary,
    gray: colors.primaryGray,
    seeAll: colors.secondaryGray,
    primaryContrast: colors.white,
  },
  border: {
    primary: colors.primaryGray,
    gray: colors.secondaryGray,
  },
  filter: {
    primary: colors.primaryGray,
    secondary: colors.secondaryGray,
    disabled: colors.secondaryGray,
    highlight: '#111111',
  },
  tabs: {
    bg: '#181818',
    containerBg: colors.dark,
    color: colors.white,
    border: colors.primary,
    scrollbarThumb: '#bdbdbd',
    programTitle: colors.white,
  },
  programGuide: {
    programBox: {
      background: {
        main: '#2e2e2e',
        hover: colors.dark,
        selected: colors.dark,
        progress: rgba(colors.primary, 0.3),
        past: rgba(colors.primary, 0.3),
        pastSelected: colors.dark,
        pastHover: colors.dark,
        empty: '#555555',
        emptyProgress: rgba(colors.primary, 0.2),
      },
      text: {
        empty: colors.secondaryGray,
      },
    },
    programGuidePictureOverlay: {
      horizontal:
        // eslint-disable-next-line max-len
        `linear-gradient(90deg, #181818 0%,${rgba('#181818', 0.97)} 13.03%,${rgba('#181818', 0.69)} 43.24%,${rgba(
          '#181818',
          0,
        )} 100%);`,
      vertical: `linear-gradient(0, #181818 0%,${rgba('#181818', 0.73)} 34.97%,${rgba('#181818', 0)} 100%);`,
    },
  },
  card: {
    title: colors.white,
    subTitle: colors.secondaryGray,
    bg: colors.grayText,
    number: '#DFDFDF',
    boxShadow: '#2e2e2e',
    loaderBackgroundImage: 'linear-gradient(to right, #222 0%, #111 20%, #222 40%, #222 100%)',
  },
  dimension: {},
  size: 'default',
  breakpoints: [`${SMALL_VIEWPORT}px`, `${MEDIUM_VIEWPORT}px`, `${LARGE_VIEWPORT}px`],
  signIn: {
    clipPath: false,
  },
}
