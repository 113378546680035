// @flow
import escapeRegexp from 'escape-string-regexp'
import { propOr } from 'ramda'

import { getMoviesList } from '@alphaott/api-client'
import { getContentDomain, getPublicDomain, getCacheVOD, isCacheValid } from '@alphaott/app-main/selectors'
import { getServiceVodIds } from '@alphaott/app-core/selectors'
import { defaultToEmptyString } from '@alphaott/common-utils/utils/help'

import type { MoviesListQuery } from '@alphaott/api-client/movies'

export const FETCH_MOVIES_TYPE = 'movies/FETCH_LIST'
export const FILTER_OPEN_MOVIE = 'movies/FILTER_OPEN'
export const FETCH_SEARCH_MOVIES_TYPE = 'movies/FETCH_SEARCH_MOVIES'

export type FilterOpenMovieAction = {|
  type: 'movies/FILTER_OPEN',
  isOpenFilter: boolean,
|}

export const fetchMoviesAction = (param: MoviesListQuery, resetData?: boolean) => (
  dispatch: Function,
  getState: Function,
) => {
  const state = getState()
  const serviceSpec = getServiceVodIds(state)
  const publicApi = getPublicDomain(state)
  const contentApi = getContentDomain(state)
  const ttl = getCacheVOD(state)

  return dispatch({
    type: FETCH_MOVIES_TYPE,
    payload: getMoviesList(publicApi, serviceSpec, param),
    meta: {
      contentApi,
      resetData,
      ttl,
    },
  })
}

// eslint-disable-next-line complexity
export const fetchMovies = (param: MoviesListQuery, resetData?: boolean, force?: boolean = false) => (
  dispatch: Function,
  getState: Function,
) => {
  if (force) return dispatch(fetchMoviesAction(param, resetData))

  const state = getState()
  const { expires } = state.moviesList

  if (expires && isCacheValid(expires)) return null

  return dispatch(fetchMoviesAction(param, resetData))
}

export const searchFetchMovies = ({
  search,
  params,
  resetData = false,
}: {
  search: string,
  params?: MoviesListQuery,
  resetData?: boolean,
}) => (dispatch: Function, getState: Function) => {
  const searchStr = defaultToEmptyString(search)
  const searchRegexp = `/${escapeRegexp(searchStr)}/i`
  const serviceSpec = getServiceVodIds(getState())
  const paramsQuery = {
    title: searchRegexp,
    plot: searchRegexp,
    limit: propOr(50, 'limit', params),
    skip: propOr(0, 'skip', params),
    ...params,
  }
  return dispatch({
    type: FETCH_SEARCH_MOVIES_TYPE,
    payload: getMoviesList(getPublicDomain(getState()), serviceSpec, paramsQuery),
    meta: {
      contentApi: getContentDomain(getState()),
      resetData,
    },
  })
}

export const filterOpenMovie = (isOpenFilter: boolean): FilterOpenMovieAction => ({
  type: FILTER_OPEN_MOVIE,
  isOpenFilter,
})

export * from './categories'
export * from './countries'
export * from './genres'
export * from './languages'
export * from './movie'
export * from './popular'
export * from './recent'
export * from './favorites'
export * from './limits'
export * from './filters'
