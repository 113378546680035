// @flow

import React, { memo, useCallback, useMemo } from 'react'
import type { ComponentType } from 'react'
import { equals, keys, map, prop } from 'ramda'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'

import { Container, Tab } from './TabBar.styles'

import type { TabProps } from './Tab'

export type TabBarProps = {
  className?: string,
  activeItem: string,
  items: $Exact<TabProps>[],
}

const TabBarPure = ({ className, activeItem, items = [], onClick }: TabBarProps) => {
  const { ref, focusKey } = useFocusable({
    preferredChildFocusKey: activeItem,
  })

  const isActive = useCallback((value) => equals(activeItem, value), [activeItem])

  const handleClick = useCallback(
    (value) => () => {
      if (onClick) onClick(value)
    },
    [onClick],
  )

  const menuItems = useMemo(
    () =>
      map((key) => {
        const { label, icon } = prop(key, items)

        return (
          <Tab
            key={key}
            icon={icon}
            label={label}
            focusKey={key}
            isActive={isActive(key)}
            onClick={handleClick(key)}
          />
        )
      }, keys(items)),
    [isActive, items, handleClick],
  )

  return (
    <FocusContext.Provider value={focusKey}>
      <Container ref={ref} className={className}>
        {menuItems}
      </Container>
    </FocusContext.Provider>
  )
}

export const TabBar: ComponentType<TabBarProps> = memo<TabBarProps>(TabBarPure)

export default TabBar
