// @flow
import styled from 'styled-components'

import {
  getColorPrimary,
  getColorSlightPrimary,
  getBackgroundSecondaryColor,
  getColorPrimaryContrastText,
} from '@alphaott/smart-tv-themes'

import { PlayCurrentIconComponent } from '../assets'

export const PROGRAM_ITEM_WIDTH = 1193
export const PROGRAM_ITEM_HEIGHT = 78
const BACKGROUND_COLOR = 'rgba(221, 221, 221, 0.3)'

/* eslint-disable complexity */
const getTitleWidth = (props) => {
  if (props.isLive && props.isCurrent) {
    return 750
  }
  if (props.isLive) {
    return 790
  }
  if (props.showCatchUpBtn && props.isCurrent) {
    return 680
  }
  if (props.showCatchUpBtn) {
    return 720
  }
  if (props.isCurrent) {
    return 860
  }
  return 900
}

// eslint-disable-next-line complexity
const getBackgroundColor = (props) => {
  if (props.isListFocused) {
    return BACKGROUND_COLOR
  }
  if (props.isSelected && !props.focused) {
    return getColorSlightPrimary(props)
  }
  if (props.focused) {
    return getColorPrimary(props)
  }
  return getBackgroundSecondaryColor(props)
}

// eslint-disable-next-line complexity
const getTextColor = (props) => {
  if (props.isLive && !props.focused && !props.isSelected) {
    return getColorPrimary(props)
  }
  return getColorPrimaryContrastText(props)
}

const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 32rem;
  line-height: 37rem;
`

export const ProgramItemContainer = styled.div`
  background: ${getBackgroundColor};
  color: ${getTextColor};
  width: ${PROGRAM_ITEM_WIDTH}rem;
  height: ${PROGRAM_ITEM_HEIGHT}rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.palette.grey[700]};
  }
`

export const ProgramItemBase = styled.div`
  width: calc(100% - 50rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ProgramItemTitle = styled(Text)`
  max-width: ${getTitleWidth}rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Time = styled(Text)`
  padding-right: 50rem;
`

export const Block = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const PlayCurrentIcon = styled(PlayCurrentIconComponent)`
  width: 28rem;
  height: 37rem;
  fill: ${getColorPrimaryContrastText};
  margin-right: 12rem;
`
