// @flow

import { find, prop, propEq, and, reject } from 'ramda'

import { getCustomerId, getServiceRadioIds } from '@alphaott/app-core/selectors'
import logoutRequestWrapper from '@alphaott/app-main/core/logoutWrapper'
import { getPersonalList, putPersonalList, removeItemFromPersonalList } from '@alphaott/api-client'
import { isNotEmpty, isNilOrEmpty } from '@alphaott/common-utils'

import type { Dispatch } from '@alphaott/common-types'

import { isFavoriteRadioChannel, getRadioFavorites, getPreparedRadioFavoriteList } from '../../selectors'
import { fetchRadioChannels } from '../radioChannels'

export const FETCH_FAVORITE_RADIO_CHANNELS_TYPE = 'radioChannels/FETCH_FAVORITE_RADIO_CHANNELS_TYPE'
// This need for future functionality
// export const FETCH_FAVORITE_RADIO_CHANNEL_BY_ID_TYPE = 'radioChannels/FETCH_FAVORITE_RADIO_CHANNEL_BY_ID_TYPE'
export const FETCH_FAVORITE_RADIO_CHANNELS_IDS_TYPE = 'radioChannels/FETCH_FAVORITE_RADIO_CHANNELS_IDS_TYPE'
export const ADD_RADIO_CHANNEL_ID_TO_FAVORITE_IDS_TYPE = 'radioChannels/ADD_RADIO_CHANNEL_ID_TO_FAVORITE_IDS_TYPE'
export const REMOVE_RADIO_CHANNEL_ID_FROM_FAVORITE_IDS_TYPE =
  'radioChannels/REMOVE_RADIO_CHANNEL_ID_FROM_FAVORITE_IDS_TYPE'

// eslint-disable-next-line consistent-return
export const fetchFavoriteRadioChannelsByIds = (dispatch: Dispatch, getState: Function) => {
  const state = getState()
  const serviceRadioIds = getServiceRadioIds(state)
  const favoriteList = getPreparedRadioFavoriteList(state)
  const uniqFavoriteIds = reject(
    ({ item }) => find(propEq('_id', item), favoriteList),
    state.radioChannelFavorites.data,
  )

  if (and(isNotEmpty(serviceRadioIds), isNotEmpty(uniqFavoriteIds))) {
    return dispatch(fetchRadioChannels(serviceRadioIds, true))
  }
}

export const addRadioChannelIdToFavoriteIds = (radioChannelId: string, customerId: string) => (
  dispatch: Dispatch,
  getState: Function,
) =>
  dispatch({
    type: ADD_RADIO_CHANNEL_ID_TO_FAVORITE_IDS_TYPE,
    payload: logoutRequestWrapper(putPersonalList)(customerId, 'radio', 'favorites', radioChannelId, {})(
      dispatch,
      getState,
    ),
  })

// Todo: Change this when will add endpoint for getting radio channel by id
export const fetchRadioFavoriteChannelById = (dispatch: Dispatch) => dispatch(fetchFavoriteRadioChannelsByIds)

export const addRadioChannelToFavorite = (radioChannelId: string, customerId: string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line no-useless-catch
  try {
    await dispatch(addRadioChannelIdToFavoriteIds(radioChannelId, customerId))
    dispatch(fetchRadioFavoriteChannelById)
  } catch (err) {
    throw err
  }
}

export const removeRadioChannelIdFromFavoritesIds = (radioChannelId: string, customerId: string) => (
  dispatch: Dispatch,
  getState: Function,
) => {
  const favoriteRadioChannelsIds = getRadioFavorites(getState())
  const radioChannel = find(propEq('item', radioChannelId), favoriteRadioChannelsIds)
  const itemId = prop('_id', radioChannel)

  return dispatch({
    type: REMOVE_RADIO_CHANNEL_ID_FROM_FAVORITE_IDS_TYPE,
    payload: logoutRequestWrapper(removeItemFromPersonalList)(customerId, 'radio', 'favorites', itemId)(
      dispatch,
      getState,
    ),
    meta: {
      itemId,
    },
  })
}

export const favoriteRadioChannel = (radioChannelId: string) => (dispatch: Dispatch, getState: Function) => {
  const customerId = getCustomerId(getState())
  const isFavorite = isFavoriteRadioChannel(radioChannelId)(getState())

  if (isFavorite) {
    return dispatch(removeRadioChannelIdFromFavoritesIds(radioChannelId, customerId))
  }

  return dispatch(addRadioChannelIdToFavoriteIds(radioChannelId, customerId))
}

export const fetchFavoriteRadioChannelsIds = (dispatch: Dispatch, getState: Function) => {
  const customerId = getCustomerId(getState())

  return dispatch({
    type: FETCH_FAVORITE_RADIO_CHANNELS_IDS_TYPE,
    payload: logoutRequestWrapper(getPersonalList)(customerId, 'radio', 'favorites')(dispatch, getState),
  })
}

export const fetchFavoriteRadioChannels = async (dispatch: Dispatch, getState: Function) => {
  const customerId = getCustomerId(getState())

  if (isNilOrEmpty(customerId)) return null

  return dispatch({
    type: FETCH_FAVORITE_RADIO_CHANNELS_TYPE,
    payload: Promise.all([await dispatch(fetchFavoriteRadioChannelsIds), dispatch(fetchFavoriteRadioChannelsByIds)]),
  })
}
