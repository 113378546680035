// @flow
import type { Theme } from './types'

type Props = { theme: Theme }

export const getBackgroundColor = ({ theme }: Props): string => theme?.palette?.background?.default
export const getBackgroundMainColor = ({ theme }: Props): string => theme?.palette?.background?.main
export const getBackgroundSecondaryColor = ({ theme }: Props): string =>
  theme?.palette?.background?.secondary

export const getErrorColorMain = ({ theme }: Props): string => theme.palette.error.main

export const getColorPrimary = ({ theme }: Props): string => theme.palette.primary.main
export const getColorSlightPrimary = ({ theme }: Props): string => theme.palette.primary.slight
export const getColorPrimaryContrastText = ({ theme }: Props): string =>
  theme.palette.primary.contrastText
export const getColorSecondaryContrastText = ({ theme }: Props): string =>
  theme.palette.secondary.contrastText

export const getColorDisabled = ({ theme }: Props): string => theme.palette.text.disabled
export const getTextColorPrimary = ({ theme }: Props): string => theme.palette.text.primary
export const getTextColorSecondary = ({ theme }: Props): string => theme.palette.text.secondary

export const getFontSize = ({ theme }: Props): number => theme.font.fontSize

export const getPlaybackBackground = ({ theme }: Props): string => theme.palette.playback.background

export const getGrey600 = ({ theme }: Props): string => theme.palette.grey['600']
export const getGrey800 = ({ theme }: Props): string => theme.palette.grey['800']
