// @flow
import { makeGetRequest } from '../utils'

import type { TrailerMediaStream } from '../types/movies'
import type { Headers, ResponseAPI } from '../types/api'

export const getTrailer = (
  domain: string,
  movieId: string,
  trailerId: number,
  { token }: Headers,
): Promise<ResponseAPI<TrailerMediaStream>> =>
  makeGetRequest(`${domain}/client/api/v3/vod/movie/${movieId}/trailer/${trailerId}`, { headers: { token } })
