// @flow

import { useCallback } from 'react'
import { includes, not, path, prop, propEq, pathEq, propOr, pick, or } from 'ramda'

import { DEFAULT_STREAM_ERROR_MESSAGE } from '@alphaott/app-config'

// const SEVERITY = {
//   RECOVERABLE: 1,
//   CRITICAL: 2,
// }

// ShakaPlayer error codes
const BAD_HTTP_STATUS = 1001
const HTTP_ERROR = 1002
const TIMEOUT = 1003
const LOAD_INTERRUPTED = 7000

// HTTP error codes
const FORBIDDEN = 403
const BAD_GATEWAY = 502

const IGNORED_ERROR_CODES = [LOAD_INTERRUPTED]

const SENTRY_IGNORED_LIST_CODES = [BAD_HTTP_STATUS, HTTP_ERROR, TIMEOUT]

const ERROR_FIELDS = ['category', 'code', 'data', 'message', 'severity']

export const isBadHttpStatus = propEq('code', BAD_HTTP_STATUS)
export const is403 = pathEq(['data', 1], FORBIDDEN)
export const is502 = pathEq(['data', 1], BAD_GATEWAY)

export const useShakaPlayerError = (onError: Function, onCriticalError: Function) => {
  const isIgnoredError = useCallback((error: Object) => includes(prop('code', error), IGNORED_ERROR_CODES), [])

  const handlePrepareError = useCallback((error: Object) => propOr(error, 'detail', error), [])

  const handlePrepareSentryError = useCallback((error: Object) => pick(ERROR_FIELDS, error), [])

  const isNeedToCaptureException = useCallback((error: Object) => {
    const isExcludedError = includes(prop('code', error), SENTRY_IGNORED_LIST_CODES)

    // if (isExcludedError) {
    //   const isCriticalError = propEq('severity', SEVERITY.CRITICAL, error)
    //
    //   if (not(isCriticalError)) return false
    // }

    return not(isExcludedError)
  }, [])

  const handleShowBadHttpStatusError = useCallback(
    // eslint-disable-next-line consistent-return
    (error: Object = {}) => {
      const code = path(['data', 1], error)
      const text = path(['data', 2], error)
      const isStreamError = is403(code)

      if (isStreamError) {
        // eslint-disable-next-line promise/prefer-await-to-callbacks
        return onCriticalError(DEFAULT_STREAM_ERROR_MESSAGE)
      }

      // Temporary solution
      if (not(or(includes('<HTML>', text), includes('<html>', text)))) {
        onError(text)
      }
    },
    [onError, onCriticalError],
  )

  const handleShowError = useCallback(
    (error: Object) => {
      const code = or(prop('message', error), prop('code', error))
      const errorMessage = code
        ? `Stream error occurred. Error code: ${code}. Please try again later.`
        : 'Stream error occurred. Please try again later.'

      onError(errorMessage)
    },
    [onError],
  )

  return {
    isIgnoredError,
    isNeedToCaptureException,
    isBadHttpStatus,
    onPrepareError: handlePrepareError,
    onPrepareSentryError: handlePrepareSentryError,
    onShowError: handleShowError,
    onShowBadHttpStatusError: handleShowBadHttpStatusError,
  }
}

export default useShakaPlayerError
