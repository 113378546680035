// @flow

import { useSelector } from 'react-redux'

import { getManufacturer, getDeviceModel, getOs, getOsVersion } from '../models/selectors'

export const useDeviceDataForAnalytics = () => {
  const deviceBrand = useSelector(getManufacturer)
  const deviceModel = useSelector(getDeviceModel)
  const deviceOS = useSelector(getOs)
  const deviceOSVersion = useSelector(getOsVersion)

  return {
    deviceBrand,
    deviceModel,
    deviceOS,
    deviceOSVersion,
  }
}

export default useDeviceDataForAnalytics
