// @flow

import { cond, equals, T } from 'ramda'

import { isServicesEnabledForWeb as getServicesEnabledForWeb } from '@alphaott/app-main/models/selectors'
import { getDeviceType } from '@alphaott/app-devices/models/selectors'
import { devices } from '@alphaott/api-client/types/devices'

export const prepareServicesIds = (selector: Function) => (state: any) => {
  const isServicesEnabledForWeb = getServicesEnabledForWeb(state)

  const deviceType = getDeviceType(state)

  const selectServicesForWeb = () => (isServicesEnabledForWeb ? selector(state) : '')

  return cond([[equals(devices.pc), selectServicesForWeb], [T, () => selector(state)]])(deviceType)
}
