import { createGlobalStyle } from 'styled-components'
import { getBackgroundColor } from '@alphaott/smart-tv-themes'

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 0.052vw;
  }
  body {
    font-family: 'Lato', Arial, sans-serif;
    font-size: 16px;
    background-color: ${getBackgroundColor};
  }
`
