// @flow
import * as Sentry from '@sentry/browser'

import { fetchServiceProvider } from '@alphaott/app-core/models/actions/appServiceProvider'
import { authMethods as fetchAuthMethods } from '@alphaott/app-auth/actions'

import { fetchBrandConfig } from '../brand'
import { fetchConfig } from '../config'
import { BRAND_CONFIG_PATH } from '../../const'

export const INIT_MAIN_TYPE = 'main/INIT'
export const GEOBLOCKED_ERROR_TYPE = 'main/GEOBLOCKED_ERROR_TYPE'

type InitOptions = {|
  fetchBrandConfig: boolean,
  brandConfigPath: string,
|}

const DefaultOptions: InitOptions = {
  fetchBrandConfig: true,
  brandConfigPath: '',
}

// eslint-disable-next-line complexity
const init = async (dispatch, options?: InitOptions = DefaultOptions) => {
  if (options.fetchBrandConfig) {
    try {
      await dispatch(fetchBrandConfig(BRAND_CONFIG_PATH))
    } catch (err) {
      Sentry.captureException(err, { extra: { error_msg: "Brand_config couldn't be fetched" } })
      await dispatch(fetchBrandConfig())
    }
  }

  try {
    await dispatch(fetchConfig(options.brandConfigPath))
  } catch (err) {
    Sentry.captureException(err, { extra: { error_msg: "Config with API endpoint couldn't be fetched" } })
    throw err
  }

  try {
    await dispatch(fetchServiceProvider())
  } catch (err) {
    Sentry.captureException(err, { extra: { error_msg: "Service Provider couldn't be fetched" } })
    throw err
  }

  try {
    await dispatch(fetchAuthMethods())
  } catch (err) {
    Sentry.captureException(err, { extra: { error_msg: "Auth Methods couldn't be fetched" } })
  }
}

export const initMain = (options?: InitOptions) => (dispatch: Function) =>
  dispatch({
    type: INIT_MAIN_TYPE,
    payload: init(dispatch, options),
  })

export const geoBlockedError = (error: Object) => ({
  type: GEOBLOCKED_ERROR_TYPE,
  error,
})
