// @flow
import { getSubscription, cancelSubscription as cancel } from '@alphaott/api-client'
import { getContentDomain } from '@alphaott/app-main/selectors'
import { withoutDataLogoutWrapper, logoutRequestWrapper } from '@alphaott/app-main/utils'

export const FETCH_SUBSCRIPTION_TYPE = 'subscription/FETCH_SUBSCRIPTION'
export const CANCEL_SUBSCRIPTION_TYPE = 'subscription/CANCEL_SUBSCRIPTION'
export const CLEAR_CANCEL_SUBSCRIPTION_ERROR_TYPE = 'subscription/CLEAR_CANCEL_ERROR'

export const fetchSubscription = (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_SUBSCRIPTION_TYPE,
    payload: withoutDataLogoutWrapper(getSubscription)(dispatch, getState),
    meta: {
      contentApi: getContentDomain(getState()),
    },
  })

export const cancelSubscription = (subscriptionId: string, cancelAtEnd?: boolean = true) => (
  dispatch: Function,
  getState: Function,
) =>
  dispatch({
    type: CANCEL_SUBSCRIPTION_TYPE,
    payload: logoutRequestWrapper(cancel, { redirect: '/account/subscriptions' })(subscriptionId, cancelAtEnd)(
      dispatch,
      getState,
    ),
    meta: {
      id: subscriptionId,
    },
  })

export const clearCancelErrors = {
  type: CLEAR_CANCEL_SUBSCRIPTION_ERROR_TYPE,
}
