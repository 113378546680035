// @flow

import { useMemo, useState, useLayoutEffect } from 'react'
import { and, not, isNil, equals, includes, pathOr } from 'ramda'
import { notEqual, isNotNil } from 'ramda-adjunct'

import { usePrevious } from '@alphaott/common-utils'

import { useLastFocusHistory } from './useLastFocusHistory'
import { useLastFocusScroll } from './useLastFocusScroll'

import type { ListType } from './useLastFocusScroll'

const ENABLED_SCROLL_DEFAULT = true
const ENABLED_FOCUS_DEFAULT = true
const ENABLED_INITIAL_FOCUS_DEFAULT = true
const CURRENT_SECTION_DEFAULT = true

export type UseLastFocusProps = {
  isEnabledFocus?: boolean,
  isEnabledScroll?: boolean,
  isEnabledInitialFocus?: boolean,
  defaultFocusKey?: string,
  sectionIndex?: number,
  listRef?: any,
  setFocus?: Function,
}

const BLACK_LIST = ['favorites', 'suggested', 'recent']

export const useLastFocus = (
  listType: ListType,
  {
    isEnabledFocus = ENABLED_FOCUS_DEFAULT,
    isEnabledScroll = ENABLED_SCROLL_DEFAULT,
    enableInitialFocus = ENABLED_INITIAL_FOCUS_DEFAULT,
    sectionIndex,
    defaultFocusKey,
    listRef,
    setFocus,
  }: UseLastFocusProps,
) => {
  const { pathKey, itemFocusKey, prevFocusedItem, onRemoveLastFocus, onGetItemOption } =
    useLastFocusHistory()
  const [isEnabledInitialFocus, setEnabledInitialFocus] = useState(enableInitialFocus)

  const [isScrolled, setScrolled] = useState(false)

  const prevListRef = usePrevious(listRef)
  const prevPathKey = usePrevious(pathKey)

  const itemSectionId = useMemo(() => onGetItemOption(['id']), [onGetItemOption])
  const itemSectionIndex = useMemo(() => onGetItemOption(['sectionIndex']), [onGetItemOption])
  const itemSectionType = useMemo(() => onGetItemOption(['sectionType']), [onGetItemOption])
  const prepareItemFocusKey = useMemo(
    () => onGetItemOption(['prepareItemFocusKey']),
    [onGetItemOption],
  )
  const isBlackedSection = useMemo(() => includes(itemSectionType, BLACK_LIST), [itemSectionType])
  const isChangedListRef = useMemo(() => and(listRef, not(prevListRef)), [listRef, prevListRef])
  const isCurrentSection = useMemo(
    () =>
      isNotNil(sectionIndex) ? equals(sectionIndex, itemSectionIndex) : CURRENT_SECTION_DEFAULT,
    [sectionIndex, itemSectionIndex],
  )

  const onScrollToItem = useLastFocusScroll(listType, listRef)

  // Scroll
  // eslint-disable-next-line complexity
  useLayoutEffect(() => {
    // For new functional
    if (!isEnabledScroll) {
      setScrolled(true)
    }

    if (isChangedListRef && itemFocusKey && isCurrentSection && isEnabledScroll) {
      onScrollToItem(isBlackedSection)

      if (isEnabledFocus) {
        setScrolled(true)
      }
    }
  }, [pathKey, isChangedListRef]) // eslint-disable-line react-hooks/exhaustive-deps

  // Works once as did mount
  // eslint-disable-next-line complexity
  useLayoutEffect(() => {
    if (
      isChangedListRef &&
      not(itemFocusKey) &&
      setFocus &&
      isEnabledInitialFocus &&
      defaultFocusKey &&
      not(prevFocusedItem)
    ) {
      setFocus(defaultFocusKey)
    }
  }, [isChangedListRef]) // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line complexity,consistent-return
  useLayoutEffect(() => {
    if (itemFocusKey && setFocus && isScrolled) {
      if (isBlackedSection) {
        const firstItemId = pathOr(itemSectionId, ['props', 'itemData', 'items', 0, 'id'], listRef)
        const preparedFocusKey = prepareItemFocusKey(firstItemId)

        setFocus(preparedFocusKey)
        setEnabledInitialFocus(false)
        return onRemoveLastFocus()
      }

      setFocus(itemFocusKey)
      setEnabledInitialFocus(false)
      onRemoveLastFocus()
    }
  }, [isScrolled]) // eslint-disable-line react-hooks/exhaustive-deps

  // For case where there is no list
  // eslint-disable-next-line complexity
  useLayoutEffect(() => {
    if (isNil(listType) && not(itemFocusKey)) {
      setFocus(defaultFocusKey)

      if (pathKey) {
        onRemoveLastFocus()
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // For case where there is no list
  // eslint-disable-next-line complexity
  useLayoutEffect(() => {
    if (isNil(listType) && itemFocusKey && notEqual(pathKey, prevPathKey)) {
      setFocus(itemFocusKey)
      onRemoveLastFocus()
    }
  }, [itemFocusKey]) // eslint-disable-line react-hooks/exhaustive-deps
}
