/* eslint-disable react/jsx-no-useless-fragment */
// @flow

import React, { memo, useMemo, useEffect, forwardRef } from 'react'
import { not, and } from 'ramda'

import {
  Helper,
  OptionsMenuContainer,
  useOptionsMenuContext,
} from '@alphaott/smart-tv-options-menu'
import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'
import { useExitAppModal } from '@alphaott/smart-tv-notifications/hooks'
import { usePrevious } from '@alphaott/common-utils'

import { PageLoader } from './PageLoader'
import { Container, FocusableContainer } from './elements'

type FocusablePageViewProps = {
  focusKey: string,
  children: any,
  isFlexBehavior?: boolean,
  withActionHelper?: boolean,
}

const FocusablePageViewPure = ({
  focusKey: parentFocusKey,
  children,
  isFlexBehavior,
  withActionHelper,
}: FocusablePageViewProps) => {
  const { ref, focusKey, setFocus } = useFocusable({ focusKey: parentFocusKey })
  const { isAppExitModalOpened } = useExitAppModal()
  const prevIsAppExitModalOpened = usePrevious(isAppExitModalOpened)
  const { isShowMenu } = useOptionsMenuContext()

  useEffect(() => {
    if (and(not(isAppExitModalOpened), prevIsAppExitModalOpened)) {
      setFocus && setFocus(parentFocusKey)
    }
  }, [isAppExitModalOpened]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FocusContext.Provider value={focusKey}>
      <FocusableContainer ref={ref} isFlexBehavior={isFlexBehavior}>
        {withActionHelper && not(isShowMenu) && <Helper />}

        <>{children}</>
      </FocusableContainer>
    </FocusContext.Provider>
  )
}

const FocusablePageView = memo<FocusablePageViewProps>(FocusablePageViewPure)

type PageViewProps = {
  loading?: boolean,
  isFlexBehavior?: boolean,
  withFocusable?: boolean,
  withActionHelper?: boolean,
}

// eslint-disable-next-line react/prefer-exact-props
const PageViewPure = (props: PageViewProps, ref: any) => {
  const {
    focusKey,
    children,
    loading = false,
    isFlexBehavior = false,
    withFocusable = true,
    withActionHelper = false,
    ...otherProps
  } = props

  const preparedChildren = useMemo(() => {
    if (withFocusable && focusKey) {
      return (
        <FocusablePageView
          focusKey={focusKey}
          isFlexBehavior={isFlexBehavior}
          withActionHelper={withActionHelper}
        >
          {children}
        </FocusablePageView>
      )
    }

    return <OptionsMenuContainer>{children}</OptionsMenuContainer>
  }, [withFocusable, withActionHelper, focusKey, children, isFlexBehavior])

  return (
    <PageLoader isLoading={loading}>
      <Container ref={ref} isFlexBehavior={isFlexBehavior} {...otherProps}>
        {preparedChildren}
      </Container>
    </PageLoader>
  )
}

export const PageView = memo<PageViewProps>(forwardRef(PageViewPure))

export default PageView
