// @flow
import type { DRM } from '@alphaott/api-client/types/drm'

import { DRM_TYPES } from '../const'

const prepareWidevineKeySystems = (drm: DRM) => {
  if (drm[DRM_TYPES.WIDEVINE]) {
    return {
      url: drm[DRM_TYPES.WIDEVINE].licenseUri,
      licenseHeaders: drm[DRM_TYPES.WIDEVINE].licenseHeaders,
    }
  }
  return {}
}

export default prepareWidevineKeySystems
