// @flow
import {
  filter,
  compose,
  propOr,
  map,
  mergeRight,
  dissoc,
  reduce,
  concat,
  pipe,
  uniq,
  isEmpty,
  intersection,
  curry,
} from 'ramda'

import type { Channel } from '@alphaott/api-client/types/channels'

import { isNotEmpty } from '../help'

export type Channels = { [string]: Channel }

export type FilterObject = {|
  genre: Array<string>,
  country: Array<string>,
  category: Array<string>,
  language: Array<string>,
|}

export const FILTER_NAMES = {
  category: 'categories',
  genre: 'genres',
  country: 'countries',
  language: 'languages',
}

export const FILTER_PRIORITY = {
  categories: 1,
  genres: 2,
  countries: 3,
  languages: 4,
}

export const filterChannelsByGenre = curry(
  (genres: Array<string>, channels: Array<Channel>): Array<Channel> => {
    if (isEmpty(genres)) return channels
    return filter(channel => isNotEmpty(intersection(genres, channel.genres)), channels)
  },
)

export const filterChannelsByCountry = curry(
  (countries: Array<string>, channels: Array<Channel>): Array<Channel> => {
    if (isEmpty(countries)) return channels
    return filter(channel => isNotEmpty(intersection(countries, channel.countries)), channels)
  },
)

export const filterChannelsByCategory = curry(
  (categories: Array<string>, channels: Array<Channel>): Array<Channel> => {
    if (isEmpty(categories)) return channels
    return filter(channel => isNotEmpty(intersection(categories, channel.categories)), channels)
  },
)

export const filterChannelsByLanguage = curry(
  (languages: Array<string>, channels: Array<Channel>): Array<Channel> => {
    if (isEmpty(languages)) return channels
    return filter(channel => isNotEmpty(intersection(languages, channel.languages)), channels)
  },
)

export const filterChannels = (filterObj: FilterObject): Function =>
  compose(
    filterChannelsByGenre(propOr([], 'genre', filterObj)),
    filterChannelsByCountry(propOr([], 'country', filterObj)),
    // TODO: Fix flow error
    // $FlowFixMe
    filterChannelsByCategory(propOr([], 'category', filterObj)),
    filterChannelsByLanguage(propOr([], 'language', filterObj)),
  )

export const getAllCategoriesFromChannels = pipe(
  reduce((prev: Array<string>, channel: Channel): Array<string> => concat(prev, channel.categories), []),
  uniq,
)

export const getAllGenresFromChannels = pipe(
  reduce((prev: Array<string>, channel: Channel): Array<string> => concat(prev, channel.genres), []),
  uniq,
)

export const getAllLanguagesFromChannels = pipe(
  reduce((prev: Array<string>, channel: Channel): Array<string> => concat(prev, channel.languages), []),
  uniq,
)

export const getAllContriesFromChannels = pipe(
  reduce((prev: Array<string>, channel: Channel): Array<string> => concat(prev, channel.countries), []),
  uniq,
)

export const prepareRelativeChannelsByCategories = (channel: Channel, channels: Channels): Channels => {
  const { categories } = channel
  const id = channel._id

  let relativeChannels = {}
  map(category => {
    // $FlowFixMe
    relativeChannels = mergeRight(relativeChannels, filterChannelsByCategory(category)(channels))
  })(categories)
  return dissoc(id, relativeChannels)
}
