// @flow
import React, { memo, useRef, useCallback, useMemo, forwardRef } from 'react'
import { VariableSizeList as List, areEqual } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { equals, path, and } from 'ramda'
import { isNumber, isNotNaN } from 'ramda-adjunct'

import { scale } from '@alphaott/smart-tv-utils'

type ListProps = {
  items: Array<any>,
  listWidth: number,
  listHeight: number,
  itemSize: number,
  renderItem: Function,
  layout: string,
  initialScrollOffset?: number,
  renderDivider?: Function,
  dividerSize?: number,
  onFocusItem: Function,
  scrollStrategy: string,
  ...
}

type ItemProps = {
  index: number,
  style: any,
  data: any,
}

const columnIndex = (parentWidth: number, itemWidth: number, dividerSize?: number): number => {
  if (dividerSize) {
    const itemWithDivider = itemWidth + scale(window.innerWidth, dividerSize)
    return Math.ceil(parentWidth / itemWithDivider) * 2
  }
  return Math.ceil(parentWidth / itemWidth)
}

// eslint-disable-next-line react/display-name
const ListItem = memo(({ index, style, data }: ItemProps) => {
  const { renderItem, items, renderDivider, onBecameFocused } = data
  const item = items[index]

  if (item.dividerSize) return renderDivider({ style })

  return renderItem({ item: { ...item, style, onBecameFocused }, index })
}, areEqual)

const ListComponentPure = (
  {
    items,
    listWidth,
    listHeight,
    itemSize,
    renderItem,
    layout = 'horizontal',
    initialScrollOffset = 0,
    renderDivider,
    dividerSize,
    onFocusItem,
    scrollStrategy = 'center',
    ...props
  }: ListProps,
  ref: any,
) => {
  const listRef = useRef()

  if (renderDivider && dividerSize) {
    const dividerItem = {
      dividerSize,
    }
    // eslint-disable-next-line no-param-reassign
    items = items.reduce((r, a) => r.concat(a, dividerItem), [0]).slice(1, -1)
  }

  const getListWidth = useCallback(
    (width) => (listWidth ? scale(window.innerWidth, listWidth) : width),
    [listWidth],
  )
  const getListHeight = useCallback(
    (height) => (listHeight ? scale(window.innerWidth, listHeight) : height),
    [listHeight],
  )
  const getItemSize = useCallback(
    (index) => {
      const divSize = path([index, 'dividerSize'], items)
      if (divSize) return scale(window.innerWidth, divSize)

      return scale(window.innerWidth, itemSize)
    },
    [itemSize, items],
  )

  const onBecameFocused = useCallback(
    // eslint-disable-next-line complexity
    ({ x, y, width, height }, itemProps) => {
      const itemIndex = equals(layout, 'horizontal')
        ? columnIndex(x, width, dividerSize)
        : columnIndex(y, height, dividerSize)

      if (listRef.current && and(isNumber(itemIndex), isNotNaN(itemIndex))) {
        listRef.current.scrollToItem(itemIndex, scrollStrategy)
      }
      onFocusItem && onFocusItem(itemProps)
    },
    [listRef, layout, dividerSize, onFocusItem, scrollStrategy],
  )

  const itemData = useMemo(
    () => ({ renderItem, items, renderDivider, onBecameFocused }),
    [renderItem, items, renderDivider, onBecameFocused],
  )

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          {...props}
          ref={(val) => {
            listRef.current = val
            if (ref) {
              // eslint-disable-next-line no-param-reassign
              ref.current = val
            }
          }}
          layout={layout}
          initialScrollOffset={initialScrollOffset}
          height={getListHeight(height)}
          width={getListWidth(width)}
          itemCount={items.length}
          itemSize={getItemSize}
          itemData={itemData}
        >
          {ListItem}
        </List>
      )}
    </AutoSizer>
  )
}

export const ListComponent = memo(forwardRef(ListComponentPure))

export default ListComponent
