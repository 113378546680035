// @flow
import { getDevices, removeDevice as remove } from '@alphaott/api-client/devices'
import { logoutRequestWrapper, withoutDataLogoutWrapper } from '@alphaott/app-main/utils'

export const FETCH_DEVICES_TYPE = 'devices/FETCH'
export const REMOVE_DEVICE_TYPE = 'devices/REMOVE_BY_ID'

export const fetchDevices = (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_DEVICES_TYPE,
    payload: withoutDataLogoutWrapper(getDevices)(dispatch, getState),
  })

export const removeDevice = (id: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: REMOVE_DEVICE_TYPE,
    payload: logoutRequestWrapper(remove)({ id })(dispatch, getState),
  })
