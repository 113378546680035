// @flow

import typeToReducer from 'type-to-reducer'
import { pathOr, map } from 'ramda'

import { prepareTVShowImages } from '@alphaott/common-utils/utils/schemas/tvshows'
import { isValidTTL, calculateExpires } from '@alphaott/app-main/selectors'

import type { TVShow } from '@alphaott/api-client/types/tvshows'

import { FETCH_FILTERED_TVSHOWS } from '../../actions'

export type FilteredTVShowsState = {|
  isLoading: boolean,
  isSuccess: boolean,
  isError: boolean,
  hasMore: boolean,
  errors: any,
  data: Array<TVShow>,
  expires: ?number,
|}

const initialState: FilteredTVShowsState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  hasMore: true,
  errors: [],
  data: [],
  expires: null,
}

export type TVShowFiltersState = {
  +data: {
    [key: string]: FilteredTVShowsState,
  },
}

const initialMovieFiltersState: TVShowFiltersState = {
  data: {},
}

const tvshowFilters = typeToReducer(
  {
    [FETCH_FILTERED_TVSHOWS]: {
      LOADING: (state, action): FilteredTVShowsState => {
        const {
          meta: { storeKey },
        } = action
        return {
          ...state,
          data: {
            ...state.data,
            [storeKey]: {
              ...(state.data[storeKey] || initialState),
              isLoading: true,
            },
          },
        }
      },

      // eslint-disable-next-line complexity
      SUCCESS: (state, action): FilteredTVShowsState => {
        const {
          payload: { data = [] },
          meta: { resetData, contentApi, storeKey, ttl },
        } = action
        const previousData = pathOr([], ['data', storeKey, 'data'], state)
        const preparedTvShows = map(tvshow => prepareTVShowImages(tvshow, contentApi))(data)
        const tvshows = resetData ? preparedTvShows : [...previousData, ...preparedTvShows]

        return {
          ...state,
          data: {
            ...state.data,
            [storeKey]: {
              ...(state.data[storeKey] || initialState),
              isLoading: false,
              isSuccess: true,
              hasMore: data.length > 0,
              data: tvshows,
              ...(isValidTTL(ttl) && { expires: calculateExpires(ttl) }),
            },
          },
        }
      },

      ERROR: (state, action): FilteredTVShowsState => {
        const {
          payload: { error },
          meta: { storeKey },
        } = action
        return {
          ...state,
          data: {
            ...state.data,
            [storeKey]: {
              ...(state.data[storeKey] || initialState),
              isLoading: false,
              isSuccess: false,
              isError: true,
              errors: error,
              expires: null,
            },
          },
        }
      },
    },
  },
  initialMovieFiltersState,
)

export { tvshowFilters }
