// @flow
import typeToReducer from 'type-to-reducer'
import type { CatchupMediaStreams } from '@alphaott/api-client/types/catchup/sources'

import { FETCH_CATCHUP_PROGRAM_SOURCES_TYPE } from '../actions'

export type CatchupMediaStreamsData = { [string]: CatchupMediaStreams }

export type CatchupSourcesState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: CatchupMediaStreamsData,
}

export const catchupSourcesInitState: CatchupSourcesState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: {},
}

export const catchupSources = typeToReducer(
  {
    [FETCH_CATCHUP_PROGRAM_SOURCES_TYPE]: {
      LOADING: (state): CatchupSourcesState => ({
        ...state,
        isLoading: true,
        isError: false,
        errors: {},
      }),

      SUCCESS: (state, action): CatchupSourcesState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        errors: {},
        isError: false,
        data: {
          ...state.data,
          [action.meta.programId]: action.payload.data,
        },
      }),

      ERROR: (state, action): CatchupSourcesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
  },
  catchupSourcesInitState,
)

export default catchupSources
