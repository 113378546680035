// @flow

import React, { memo, useRef, useCallback, useMemo } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { areEqual, FixedSizeList } from 'react-window'
import { prop } from 'ramda'

import type { ComponentType } from 'react'

import { getVerticalListScrollOffset, scale } from '@alphaott/smart-tv-utils'

import { Container } from './TrackLisk.styles'
import { Track } from './Track'

const itemSize = scale(window.innerWidth, 88)
const listStyle = { overflow: 'hidden' }

export type Data = {
  items: Array<Object>,
  onBecameFocused: Function,
  onClick: Function,
}

export type TrackItemProps = {
  index: number,
  data: Data,
  style: Object,
}

export const TrackItemPure = ({ index, data, style }: TrackItemProps) => {
  const items = useMemo(() => prop('items', data), [data])
  const item = useMemo(() => prop(index, items), [index, items])
  const label = useMemo(() => prop('label', item), [item])
  const language = useMemo(() => prop('language', item), [item])
  const isActive = useMemo(() => prop('active', item), [item])

  const onBecameFocused = useMemo(() => prop('onBecameFocused', data), [data])
  const onClick = useMemo(() => prop('onClick', data), [data])

  const handleClick = useCallback(() => {
    if (onClick) onClick(item)
  }, [onClick, item])

  const handleBecameFocused = useCallback(
    (arg) => onBecameFocused(index, arg),
    [index, onBecameFocused],
  )

  return (
    <Track
      key={language}
      focusKey={language}
      isActive={isActive}
      label={label}
      style={style}
      onClick={handleClick}
      onFocus={handleBecameFocused}
    />
  )
}

export const TrackItem: ComponentType<TrackItemProps> = memo<TrackItemProps>(
  TrackItemPure,
  areEqual,
)

export type TrackListProps = $Shape<TrackItemProps> & {
  className?: string,
}

const TrackListPure = ({ className, items = [], onClick }: TrackListProps) => {
  const listRef = useRef()

  const onBecameFocused = useCallback(
    (rowIndex) => {
      if (listRef.current)
        listRef.current.scrollTo(getVerticalListScrollOffset(listRef.current, rowIndex))
    },
    [listRef],
  )

  return (
    <Container className={className}>
      <AutoSizer>
        {({ width, height }) => (
          <FixedSizeList
            ref={listRef}
            width={width}
            height={height}
            itemCount={items.length}
            itemSize={itemSize}
            itemData={{
              items,
              onClick,
              onBecameFocused,
            }}
            style={listStyle}
            overscanRowCount={5}
          >
            {TrackItem}
          </FixedSizeList>
        )}
      </AutoSizer>
    </Container>
  )
}

export const TrackList: ComponentType<TrackListProps> = memo<TrackListProps>(TrackListPure)

export default TrackList
