// @flow

import { useMemo, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { prop, reject, equals, dissoc, append, dropLast } from 'ramda'
import { notEqual } from 'ramda-adjunct'

import { useReactWindowContext } from '../components'

type Options = {
  [string]: string | number,
}

export const useListSizeHistory = (options: Options = {}) => {
  const location = useLocation()
  const { historyKeys, listSizeHistory, onChangeHistoryKeys, onChangeListSizeHistory } =
    useReactWindowContext()

  // Unique key for page from location
  const pathKey = useMemo(() => prop('key', location), [location])

  const lastListSize = useMemo(() => prop(pathKey, listSizeHistory), [pathKey, listSizeHistory])

  const handlePrepareOptions = useCallback((values = {}) => reject(equals(''))(values), [])

  const handleChangeOptions = useCallback(
    (localOptions: Options) => (prevOptions: Options) => ({
      ...prevOptions,
      [pathKey]: {
        ...handlePrepareOptions(options),
        ...handlePrepareOptions(localOptions),
      },
    }),
    [pathKey, options, handlePrepareOptions],
  )

  const handleSaveLastKey = useCallback(
    () => onChangeHistoryKeys(append(pathKey, historyKeys)),
    [pathKey, historyKeys, onChangeHistoryKeys],
  )

  const handleRemoveLastKey = useCallback(
    () => onChangeHistoryKeys(dropLast(1, historyKeys)),
    [historyKeys, onChangeHistoryKeys],
  )

  const handleSaveListSize = useCallback(
    (localOptions: Options) => {
      const preparedOptions = handleChangeOptions(localOptions)

      if (notEqual(lastListSize, preparedOptions)) {
        handleSaveLastKey()
        onChangeListSizeHistory(handleChangeOptions(localOptions))
      }
    },
    [lastListSize, handleChangeOptions, handleSaveLastKey, onChangeListSizeHistory],
  )

  const handleRemoveListSizeItem = useCallback(
    (key: string) => {
      const preparedOptions = dissoc(key, lastListSize)

      if (notEqual(lastListSize, preparedOptions)) {
        handleRemoveLastKey()
        onChangeListSizeHistory(preparedOptions)
      }
    },
    [lastListSize, handleRemoveLastKey, onChangeListSizeHistory],
  )

  const handleRemoveLastListSize = useCallback(
    () => handleRemoveListSizeItem(pathKey),
    [pathKey, handleRemoveListSizeItem],
  )

  return {
    lastListSize,
    onSaveListSize: handleSaveListSize,
    onRemoveListSizeItem: handleRemoveListSizeItem,
    onRemoveLastListSize: handleRemoveLastListSize,
  }
}
