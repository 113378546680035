// @flow

import typeToReducer from 'type-to-reducer'
import { normalize } from 'normalizr'

import { isValidTTL, calculateExpires } from '@alphaott/app-main/selectors'
import channelListSchema, { prepareChannelImages } from '@alphaott/common-utils/utils/schemas/channels'
import type { RadioChannel } from '@alphaott/api-client/types/radioChannels'

import { FETCH_RADIO_CHANNELS_TYPE, FILTER_OPEN_RADIO_CHANNEL } from '../../actions/radioChannels'

import type { FilterOpenRadioChannelAction } from '../../actions/radioChannels'

export type RadioChannelsState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +created: ?Date,
  +result: Array<string>,
  +entities: { [string]: RadioChannel },
  +isOpenFilter: boolean,
  +expires: ?number,
}

const initialState: RadioChannelsState = {
  created: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  result: [],
  entities: {},
  isOpenFilter: false,
  expires: null,
}

const radioChannels = typeToReducer(
  {
    [FETCH_RADIO_CHANNELS_TYPE]: {
      LOADING: (state): RadioChannelsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): RadioChannelsState => {
        const {
          payload,
          meta: { contentApi, ttl },
        } = action
        const { entities, result } = normalize(payload.data, channelListSchema)
        return {
          ...state,
          created: new Date().getTime(),
          isLoading: false,
          isSuccess: true,
          entities: prepareChannelImages(entities.channels, contentApi),
          result,
          ...(isValidTTL(ttl) && { expires: calculateExpires(ttl) }),
        }
      },

      ERROR: (state, action): RadioChannelsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        expires: null,
      }),
    },
    [FILTER_OPEN_RADIO_CHANNEL]: (
      state: RadioChannelsState,
      { isOpenFilter }: FilterOpenRadioChannelAction,
    ): RadioChannelsState => ({
      ...state,
      isOpenFilter,
    }),
  },
  initialState,
)

export { radioChannels }
