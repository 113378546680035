// @flow

export const DRMType = Object.freeze({
  WIDEVINE: 'widevine',
  PLAYREADY: 'playready',
  CLEARKEY: 'clearkey',
  FAIRPLAY: 'fairplay',
})

export default DRMType
