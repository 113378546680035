import styled from 'styled-components'

import { getBackgroundColor } from '@alphaott/smart-tv-themes'

export const listStyle = {
  overflow: 'hidden',
}

export const BackgroundContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${getBackgroundColor};
  overflow: hidden;
`

export const Wrapper = styled.div``

export const Title = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 66rem;
  line-height: 79rem;
  color: rgba(255, 255, 255, 0.87);
  padding: 50rem 0 0 107rem;
`

export const Prompt = styled.p`
  margin: 10rem 0 60rem 0;
  font-style: normal;
  font-weight: normal;
  font-size: 32rem;
  line-height: 38rem;
  color: rgba(255, 255, 255, 0.7);
  padding-left: 107rem;
`

export const SubTitle = styled.h3`
  margin: 0;
  margin-bottom: 29rem;
  font-style: normal;
  font-weight: normal;
  font-size: 40rem;
  line-height: 48rem;
  color: #ffffff;
  padding-left: 107rem;
`

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 107rem;
`
