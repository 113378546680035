// @flow
import React, { memo, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// $FlowFixMe styled-components beta
import { ThemeProvider as StyledThemeProvider, StyleSheetManager } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { equals, propOr } from 'ramda'
import { isTruthy } from 'ramda-adjunct'

import { init, LastFocusProvider, ReactWindowProvider } from '@alphaott/smart-tv-spatial-navigation'
import {
  ThemeProvider,
  AlphaTheme,
  AlphaTestTheme,
  MiplayTheme,
  IttvTheme,
  IlinkstvTheme,
  FuturetvTheme,
  CloodtvTheme,
  EllastvmaxTheme,
  EllastvmaxauTheme,
  TvanetplayTheme,
  ZaaptvTheme,
  ProfoxyTheme,
  WtvPlusTheme,
  AsitvTheme,
} from '@alphaott/smart-tv-themes'
import { NetworkErrorPopup, PageLoader } from '@alphaott/smart-tv-components'
import { AppAnalytics } from '@alphaott/app-analytics'
import { initMain, setBrandConfig } from '@alphaott/app-main/actions'
import stylisRTL from '@alphaott/styled-rtl'
import { useInitPlatform } from '@alphaott/smart-tv-platforms'

import { useNetworkStatus } from '@alphaott/smart-tv-current-device/hooks'

import { RootNavigation } from '../../navigations'
import { GlobalStyle } from '../../globalStyles'
import { ExitModalContainer } from '../ExitModalContainer'
import { withI18Next } from '../../hocs'
import { brandName } from './const'

type Store = any

init({
  throttle: 600,
})

export const themes = {
  alphaott: AlphaTheme,
  'alphaott-test': AlphaTestTheme,
  miplay: MiplayTheme,
  ittv: IttvTheme,
  ilinkstv: IlinkstvTheme,
  futuretv: FuturetvTheme,
  cloodtv: CloodtvTheme,
  ellastvmax: EllastvmaxTheme,
  ellastvmaxau: EllastvmaxauTheme,
  tvanetplay: TvanetplayTheme,
  zaaptv: ZaaptvTheme,
  profoxy: ProfoxyTheme,
  wtvplus: WtvPlusTheme,
  asitv: AsitvTheme,
}

/* eslint-disable complexity */
export const RootContainerInner = withI18Next(({ basename, history, theme }: any) => {
  const { i18n } = useTranslation()
  const { isDisconnectedNetwork } = useNetworkStatus()

  // TODO: Refactor getStylisPlugins, dir
  const isRtl = equals('rtl')
  const getStylisPlugins = (dir: 'rtl' | 'ltr') => (isRtl(dir) ? [stylisRTL] : [])

  const dir: 'ltr' | 'rtl' = i18n.dir() || 'ltr'
  const stylisPlugins = getStylisPlugins(dir)

  const isLoading = useSelector(
    (store: Store) => isTruthy(store.config.isLoading) || isTruthy(store.init.isLoading),
  )

  return (
    <AppAnalytics>
      <StyleSheetManager stylisPlugins={stylisPlugins}>
        <ThemeProvider.Provider value={theme}>
          <ThemeProvider.Consumer>
            {({ styledTheme }) => (
              <StyledThemeProvider theme={{ ...styledTheme, dir }}>
                <GlobalStyle />
                <ExitModalContainer basename={basename}>
                  <ReactWindowProvider>
                    <LastFocusProvider>
                      <PageLoader isLoading={isLoading}>
                        <RootNavigation basename={basename} history={history} />

                        {isDisconnectedNetwork && <NetworkErrorPopup />}
                      </PageLoader>
                    </LastFocusProvider>
                  </ReactWindowProvider>
                </ExitModalContainer>
              </StyledThemeProvider>
            )}
          </ThemeProvider.Consumer>
        </ThemeProvider.Provider>
      </StyleSheetManager>
    </AppAnalytics>
  )
})

export const RootContainer = ({ basename, history, children }: any) => {
  const dispatch = useDispatch()

  const theme = useMemo(() => propOr(themes['alphaott-test'], brandName, themes), [])
  const isAppInitSuccess = useSelector((store: Store) => store.init.isSuccess)

  useInitPlatform()

  useEffect(() => {
    if (!isAppInitSuccess) {
      dispatch(setBrandConfig(theme.brandConfig))
      dispatch(initMain({ fetchBrandConfig: false }))
    }
  }, [dispatch, isAppInitSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RootContainerInner basename={basename} history={history} theme={theme}>
      {children}
    </RootContainerInner>
  )
}

export default memo(RootContainer)
