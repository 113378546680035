// @flow
import typeToReducer from 'type-to-reducer'

import { AUTH_METHODS_TYPE } from '../../actions/authMethods'

export type authMethodState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +isNew: boolean,
  +errors: any,
  +data: any,
}

const initialState: authMethodState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  isNew: false,
  errors: [],
  data: {
    isNew: false,
    authMethods: [],
  },
}

const authMethods = typeToReducer(
  {
    [AUTH_METHODS_TYPE]: {
      LOADING: (): authMethodState => ({ ...initialState, isLoading: true }),

      SUCCESS: (state, action): authMethodState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        errors: [],
        data: action.payload.data,
      }),

      ERROR: (state, action): authMethodState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.errors,
      }),
    },
  },
  initialState,
)

export { authMethods }
