// @flow
import { map, find, propEq, prop, reject, join } from 'ramda'

import { isNilOrEmpty, isNotEmpty } from '@alphaott/common-utils/utils/help'
import { getContentDomain, getPublicDomain } from '@alphaott/app-main/selectors'
import { getCustomerId } from '@alphaott/app-core/models/selectors'
import {
  getMovie,
  getMoviesByIds,
  getPersonalList,
  putPersonalList,
  removeItemFromPersonalList,
} from '@alphaott/api-client'
import logoutRequestWrapper from '@alphaott/app-main/core/logoutWrapper'

import type { Dispatch } from '@alphaott/common-types'

import { getFavoriteMoviesIds, getMoviesFavoriteList, isFavoriteMovie } from '../selectors'

export const FETCH_FAVORITE_MOVIES_TYPE = 'movie/FETCH_FAVORITE_MOVIES_TYPE'
export const FETCH_FAVORITE_MOVIES_IDS_TYPE = 'movie/FETCH_FAVORITE_MOVIES_IDS_TYPE'
export const FETCH_FAVORITE_MOVIES_BY_IDS_TYPE = 'movie/FETCH_FAVORITE_MOVIES_BY_IDS_TYPE'
export const FETCH_FAVORITE_MOVIE_BY_ID_TYPE = 'movie/FETCH_FAVORITE_MOVIE_BY_ID_TYPE'
export const ADD_MOVIE_ID_TO_FAVORITE_IDS_TYPE = 'movie/ADD_MOVIE_ID_TO_FAVORITE_IDS_TYPE'
export const REMOVE_MOVIE_ID_FROM_FAVORITE_IDS_TYPE = 'movie/REMOVE_MOVIE_ID_FROM_FAVORITE_IDS_TYPE'
export const REMOVE_MOVIE_FROM_FAVORITES_TYPE = 'movie/REMOVE_MOVIE_FROM_FAVORITES_TYPE'

export const addMovieIdToFavoriteIds = (movieId: string, customerId: string) => (
  dispatch: Dispatch,
  getState: Function,
) =>
  dispatch({
    type: ADD_MOVIE_ID_TO_FAVORITE_IDS_TYPE,
    payload: logoutRequestWrapper(putPersonalList)(customerId, 'movie', 'favorites', movieId, {})(dispatch, getState),
  })

export const fetchFavoriteMovieById = (movieId: string) => (dispatch: Dispatch, getState: Function) =>
  dispatch({
    type: FETCH_FAVORITE_MOVIE_BY_ID_TYPE,
    payload: getMovie(getPublicDomain(getState()), movieId),
    meta: {
      contentApi: getContentDomain(getState()),
    },
  })

export const addMovieToFavorite = (movieId: string, customerId: string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line no-useless-catch
  try {
    await dispatch(addMovieIdToFavoriteIds(movieId, customerId))
    return dispatch(fetchFavoriteMovieById(movieId))
  } catch (err) {
    throw err
  }
}

export const removeMovieIdFromFavoritesIds = (movieId: string, customerId: string) => (
  dispatch: Dispatch,
  getState: Function,
) => {
  const favoriteMoviesIds = getFavoriteMoviesIds(getState())
  const movie = find(propEq('item', movieId), favoriteMoviesIds)
  const itemId = prop('_id', movie)

  return dispatch({
    type: REMOVE_MOVIE_ID_FROM_FAVORITE_IDS_TYPE,
    payload: logoutRequestWrapper(removeItemFromPersonalList)(customerId, 'movie', 'favorites', itemId)(
      dispatch,
      getState,
    ),
    meta: {
      itemId,
    },
  })
}

export const removeMovieFromFavorites = (movieId: string, customerId: string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line no-useless-catch
  try {
    await dispatch(removeMovieIdFromFavoritesIds(movieId, customerId))

    return dispatch({
      type: REMOVE_MOVIE_FROM_FAVORITES_TYPE,
      payload: {
        movieId,
      },
    })
  } catch (err) {
    throw err
  }
}

export const favoriteMovie = (movieId: string) => (dispatch: Dispatch, getState: Function) => {
  const customerId = getCustomerId(getState())
  const isFavorite = isFavoriteMovie(movieId)(getState())

  if (isFavorite) {
    return dispatch(removeMovieFromFavorites(movieId, customerId))
  }

  return dispatch(addMovieToFavorite(movieId, customerId))
}

export const fetchFavoriteMoviesIds = (dispatch: Dispatch, getState: Function) => {
  const customerId = getCustomerId(getState())

  return dispatch({
    type: FETCH_FAVORITE_MOVIES_IDS_TYPE,
    payload: logoutRequestWrapper(getPersonalList)(customerId, 'movie', 'favorites')(dispatch, getState),
  })
}

// eslint-disable-next-line consistent-return
export const fetchFavoriteMoviesByIds = (dispatch: Dispatch, getState: Function) => {
  const state = getState()
  const favoriteList = getMoviesFavoriteList(state)
  const uniqFavoriteIds = reject(({ item }) => find(propEq('_id', item), favoriteList), state.movieFavoriteIds.data)
  const idList = map(({ item }) => item, uniqFavoriteIds)

  if (isNotEmpty(idList)) {
    return dispatch({
      type: FETCH_FAVORITE_MOVIES_BY_IDS_TYPE,
      payload: getMoviesByIds(getPublicDomain(state), join(',', idList)),
      meta: {
        contentApi: getContentDomain(getState()),
      },
    })
  }
}

export const fetchFavoriteMovies = async (dispatch: Dispatch, getState: Function) => {
  const customerId = getCustomerId(getState())

  if (isNilOrEmpty(customerId)) return null

  return dispatch({
    type: FETCH_FAVORITE_MOVIES_TYPE,
    payload: Promise.all([await dispatch(fetchFavoriteMoviesIds), dispatch(fetchFavoriteMoviesByIds)]),
  })
}
