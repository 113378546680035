// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import Dotdotdot from 'react-dotdotdot'

import { Text } from '@alphaott/smart-tv-components'

import ProgressBarComponent from './ProgressBar'
import { Controls as ControlsBase } from './Controls'

export const Container = styled.div``

export const TitleContainer = styled(Flex)`
  justify-content: space-between;
`

export const Title = styled(Text)`
  font-weight: bold;
  font-size: 48rem;
  line-height: 58rem;
  letter-spacing: 1.05rem;
  color: ${(props) => props.theme.palette.common.white};
`

export const CurrentTime = styled(Text)`
  font-size: 46rem;
  letter-spacing: 1.18286rem;
  color: ${(props) => props.theme.palette.grey[200]};
`

export const DescriptionContainer = styled(Dotdotdot)`
  width: 100%;
  max-width: 1100rem;
  margin-top: 4rem;
`

export const Description = styled(Text)`
  display: block;
  font-size: 28rem;
  line-height: 36rem;
  color: ${(props) => props.theme.palette.common.white};
`

export const ProgressBar = styled(ProgressBarComponent)`
  margin-top: 8rem;
`

export const Controls = styled(ControlsBase)``
