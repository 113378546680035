// @flow
import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { PageView, Button, AlertMessage } from '@alphaott/smart-tv-components'

import { getTextColorPrimary } from '@alphaott/smart-tv-themes'

export const PageContainer = styled(PageView)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 214rem;
  box-sizing: border-box;
  z-index: 6;
`

export const Header = styled.div`
  text-align: center;
`

export const Subtitle = styled.div`
  font-weight: 400;
  font-size: 32rem;
  line-height: 38rem;
  color: ${getTextColorPrimary};
  opacity: 0.7;
  margin-top: 44rem;
`

export const Title = styled.div`
  font-weight: 300;
  font-size: 66rem;
  line-height: 79rem;
  margin-top: 0;
  color: ${getTextColorPrimary};
  opacity: 0.87;
`

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 62rem;
`

export const Error = styled(AlertMessage).attrs({
  type: AlertMessage.types.danger,
})``

export const FormContent = styled(Flex)`
  align-items: center;
  justify-content: center;
`

export const StyledButton = styled(Button).attrs((props) => ({
  type: props.submitting ? 'submit' : 'button',
}))`
  min-width: 172rem;
  letter-spacing: 2rem;
  margin: 0 30rem;
`
