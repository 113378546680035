// @flow

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { cond, always, hasPath, prop, path, T } from 'ramda'

import { ErrorComponent } from '@alphaott/smart-tv-components'

import { prepareAppErrors } from '../../navigations/hocs'

export const Error = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const error = useSelector(prepareAppErrors)

  const { code, message } = cond([
    [
      hasPath(['code']),
      always({
        code: prop('code', error),
        message: prop('message', error),
      }),
    ],
    [
      hasPath(['error', 'code']),
      always({
        code: path(['error', 'code'], error),
        message: path(['error', 'message'], error),
      }),
    ],
    [
      T,
      always({
        code: null,
        message: t('there_has_been_an_error'),
      }),
    ],
  ])(error)

  const handleClickSupportPage = useCallback(() => navigate('/support'), [navigate])

  return <ErrorComponent code={code} title={message} onClickActionButton={handleClickSupportPage} />
}

export default Error
