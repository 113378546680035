// @flow

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { filter, propOr, toPairs, or } from 'ramda'
import { isTruthy } from 'ramda-adjunct'

import { VERSION, GIT_REVISION } from '@alphaott/smart-tv-config'
import {
  getManufacturer,
  getDeviceModel,
  getOs,
  getOsVersion,
  getDeviceId,
  getEthernetMac,
  getWifiMac,
} from '@alphaott/smart-tv-current-device/selectors'

export const useDeviceInfo = () => {
  const { t } = useTranslation()

  const appVersion = useMemo(() => `${VERSION}-${GIT_REVISION}`, [])
  const bootloaderVersion = useMemo(() => propOr('', 'bootloaderVersion', window), [])

  const manufacturer = useSelector(getManufacturer)
  const deviceModel = useSelector(getDeviceModel)
  const os = useSelector(getOs)
  const osVersion = useSelector(getOsVersion)
  const deviceId = useSelector(getDeviceId)
  const ethernetMac = useSelector(getEthernetMac)
  const wifiMac = useSelector(getWifiMac)

  const deviceInfo = useMemo(
    // eslint-disable-next-line complexity
    () => ({
      // [t('mwstv_support_bootloader_version')]: bootloaderVersion,
      [t('mwstv_support_app_version')]: `${bootloaderVersion} - (${appVersion})`,
      [t('mwstv_support_device_brand')]:
        or(manufacturer, deviceModel) && `${manufacturer} ${deviceModel && `- ${deviceModel}`}`,
      [t('mwstv_support_platform')]: or(os, osVersion) && `${os} - ${osVersion}`,
      [t('mwstv_support_device_id')]: deviceId,
      [t('mwstv_support_wifi_mac')]: wifiMac,
      [t('mwstv_support_ethernet_mac')]: ethernetMac,
    }),
    [
      t,
      manufacturer,
      deviceModel,
      deviceId,
      os,
      osVersion,
      wifiMac,
      ethernetMac,
      appVersion,
      bootloaderVersion,
    ],
  )

  const preparedDeviceInfo = useMemo(() => toPairs(filter(isTruthy, deviceInfo)), [deviceInfo])

  return preparedDeviceInfo
}
