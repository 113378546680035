// @flow
export const DRM_TYPES = {
  WIDEVINE: 'WIDEVINE',
  PLAYREADY: 'PLAYREADY',
  FAIRPLAY: 'FAIRPLAY',
  CLEARKEY: 'CLEARKEY',
}

export const DRM_KEY_SYSTEM_NAMES = {
  WIDEVINE: ['com.widevine.alpha'],
  PLAYREADY: ['com.microsoft.playready', 'com.youtube.playready'],
  PRIMETIME: ['com.adobe.primetime', 'com.adobe.access'],
  FAIRPLAY: ['com.apple.fairplay', 'com.apple.fps.1_0', 'com.apple.fps', 'com.apple.fps_2_0'],
  CLEARKEY: ['webkit-org.w3.clearkey', 'org.w3.clearkey'],
}

export const BROWSER = {
  CHROME: 'CHROME',
  FIREFOX: 'FIREFOX',
  OPERA: 'OPERA',
  IE: 'IE',
  EDGE: 'EDGE',
  SAFARI: 'SAFARI',
  IOS: 'ios',
  ANDROID: 'android',
}

// Returns mapping for browser -> DRM
// TODO: Add other DRM
export const DRM_FOR_BROWSER = {
  [BROWSER.CHROME]: [DRM_TYPES.WIDEVINE],
  [BROWSER.FIREFOX]: [DRM_TYPES.WIDEVINE],
  [BROWSER.OPERA]: [DRM_TYPES.WIDEVINE],
  [BROWSER.IE]: [DRM_TYPES.PLAYREADY],
  [BROWSER.EDGE]: [DRM_TYPES.PLAYREADY],
  [BROWSER.SAFARI]: [DRM_TYPES.FAIRPLAY],
  [BROWSER.IOS]: [DRM_TYPES.FAIRPLAY],
  [BROWSER.ANDROID]: [DRM_TYPES.WIDEVINE],
}
