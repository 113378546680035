// @flow
import { equals, map, keys, includes } from 'ramda'
import { RESET_STORE_AFTER_LOGOUT_TYPE } from '@alphaott/app-auth/actions'

import {
  CUSTOMER_LIST_STORES,
  DEVICE_STORE,
  BRAND_CONFIG_STORE,
  CONFIG_STORE,
  AUTH_METHODS_STORE,
  ROUTING_STORE,
  CURRENT_DEVICE_STORE,
} from '../constants'

type Store = any

const resetWhiteList = [
  ...CUSTOMER_LIST_STORES,
  DEVICE_STORE,
  BRAND_CONFIG_STORE,
  CONFIG_STORE,
  AUTH_METHODS_STORE,
  ROUTING_STORE,
  CURRENT_DEVICE_STORE,
]

export const rootReducer = (reducers: Function) => (state: Store, action: Object) => {
  if (equals(action.type, RESET_STORE_AFTER_LOGOUT_TYPE)) {
    const resetState = state
    map((key) => {
      if (!includes(key, resetWhiteList)) {
        resetState[key] = undefined
      }
    }, keys(state))
    return reducers(resetState, action)
  }
  return reducers(state, action)
}
