// @flow

import React, { memo } from 'react'

import { Container, Divider } from './DetailLineItem.style'

type DetailLineItemProps = {
  className?: any,
  children: string,
}

const DetailLineItemPure = ({ className, children }: DetailLineItemProps) => (
  <Container className={className}>
    <Divider>•</Divider>
    {children}
  </Container>
)

export const DetailLineItem = memo<DetailLineItemProps>(DetailLineItemPure)

export default DetailLineItem
