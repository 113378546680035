// @flow
import { getCatchupChannels } from '@alphaott/api-client/catchup'
import { getPublicDomain } from '@alphaott/app-main/selectors'
import { getServiceCatchupIds } from '@alphaott/app-core/selectors'

export const FETCH_CATCHUP_CHANNELS_TYPE = 'catchup/FETCH_CHANNELS'

export const fetchCatchupChannels = (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_CATCHUP_CHANNELS_TYPE,
    payload: getCatchupChannels(getPublicDomain(getState()), getServiceCatchupIds(getState())),
  })

export default fetchCatchupChannels
