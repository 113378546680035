import { cond, is, T, always, toLower, equals, pipe, complement } from 'ramda'

const isNotString = complement(is(String))

// prettier-ignore
export const parseBoolean = (value: string | boolean) => cond([
  [is(Boolean), i => i],
  [isNotString, always(false)],
  [pipe(toLower, equals('true')), always(true)],
  [pipe(toLower, equals('false')), always(false)],
  [T, always(false)],
])(value)
