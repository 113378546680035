// @flow

export const defaultConfig = {
  autoHeight: true,
  controlBar: false,
  bigPlayButton: false,
  controls: false,
  preload: 'metadata',
  children: ['mediaLoader', 'TextTrackDisplay'],
  html5: {
    vhs: {
      overrideNative: true,
      experimentalLLHLS: true,
    },
  },
}
