// @flow

import styled from 'styled-components'

import { EditIcon as EditIconComponent } from '../../assets'

export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  opacity: ${(props) => (props.focused ? '1' : '0.6')};
  width: 76rem;
  height: 76rem;
  background-color: transparent;
  cursor: pointer;
`

export const EditIcon = styled(EditIconComponent)`
  width: inherit;
  height: inherit;
`
