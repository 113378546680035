// @flow
import { isLicenseRequest, toUTF8 } from '../utils'

export const getFairplayEzdrmLicense = (licenseHeaders: Object, opts: { assetId: string }) => (
  type: string,
  request: Object,
) => {
  if (isLicenseRequest(type)) {
    const uri = request?.uris?.[0]
    const newUri = new URL(uri)
    newUri.pathname += opts?.assetId
    request.uris = [newUri]
    request.headers['Content-Type'] = 'application/octet-stream'
  }
}

/* eslint-disable complexity, curly,
nonblock-statement-body-position, no-plusplus, indent, no-bitwise,
no-restricted-globals, no-multi-assign, no-sequences */
const base64EncodeUint8Array = a => {
  let c = ''
  let k = 0
  //  eslint-disable-next-line space-in-parens
  for (let d, e, f, g, h, i, j, b = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='; k < a.length; )
    (d = a[k++]),
      (e = k < a.length ? a[k++] : Number.NaN),
      (f = k < a.length ? a[k++] : Number.NaN),
      (g = d >> 2),
      (h = ((3 & d) << 4) | (e >> 4)),
      (i = ((15 & e) << 2) | (f >> 6)),
      (j = 63 & f),
      isNaN(e) ? (i = j = 64) : isNaN(f) && (j = 64),
      (c += b.charAt(g) + b.charAt(h) + b.charAt(i) + b.charAt(j))
  return c
}

export const getFairplayLicense = (licenseHeaders: Object, opts: { assetId: string }) => (
  type: string,
  request: Object,
) => {
  if (isLicenseRequest(type)) {
    const payload = new Uint8Array(request.body)

    /** TODO:
     * Original encoding from shaka-player
     * const base64Payload = shaka.util.Uint8ArrayUtils.toStandardBase64(payload)
     *
     * can't be parsed by BuyDrm
     */
    const base64Payload = base64EncodeUint8Array(payload)
    const { assetId } = opts

    const params = `spc=${base64Payload}&assetId=${assetId}`

    request.headers = {
      ...request.headers,
      ...licenseHeaders,
    }
    request.body = toUTF8(params)
  }
}

export default getFairplayLicense
