// @flow
import styled from 'styled-components'

import {
  getColorPrimaryContrastText,
  getColorPrimary,
  getColorSlightPrimary,
} from '@alphaott/smart-tv-themes'

export const DATE_PICKER_ITEM_WIDTH: number = 180
export const DATE_PICKER_ITEM_HEIGHT: number = 78

export const BACKGROUND_COLOR_HOVER = 'rgba(221, 221, 221, 0.5)'

// eslint-disable-next-line complexity
const getBackgroundColor = (props) => {
  if (props.isSelected && !props.focused) {
    return getColorSlightPrimary(props)
  }
  if (props.focused) {
    return getColorPrimary(props)
  }
  return 'transparent'
}

const Text = styled.div`
  font-style: normal;
  font-weight: normal;
`

export const DateElement = styled(Text)`
  font-weight: 500;
  font-size: 24rem;
  line-height: 24rem;
`

export const WeekDay = styled(Text)`
  font-size: 32rem;
  line-height: 37rem;
  margin-bottom: 5rem;
`

export const DateContainer = styled.div`
  background: ${getBackgroundColor};
  color: ${getColorPrimaryContrastText};
  width: ${DATE_PICKER_ITEM_WIDTH}rem;
  height: ${DATE_PICKER_ITEM_HEIGHT}rem;
  border-radius: 4rem;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.palette.grey[700]};
  }
`
