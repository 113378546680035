// @flow

import { last } from 'ramda'

export const getBaseName = () => {
  if (window.smartTvPathname) {
    return window.smartTvPathname
  }

  const lastElement = last(window.location.pathname.split('/'))
  const pathname = process.env.REACT_APP_API_PATHNAME

  if (lastElement === 'index.html') {
    return `${pathname}/${lastElement}`
  }

  return pathname
}
