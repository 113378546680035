// @flow

import { useCallback } from 'react'
import { and, equals, or, pathOr, prop, propOr } from 'ramda'

import { BUTTON_KEY_CODES } from '@alphaott/smart-tv-platforms'

export const useHorizontalArrows = ({ switchFocusKey, ref, setFocus, onKeyDown }) => {
  const isHorizontalArrow = useCallback(({ keyCode }) => {
    const isLeft = equals(BUTTON_KEY_CODES.ARROW_LEFT, keyCode)
    const isRight = equals(BUTTON_KEY_CODES.ARROW_RIGHT, keyCode)

    return or(isLeft, isRight)
  }, [])

  const handlePressHorizontalArrow = useCallback(
    (key, { keyCode }) => {
      const currentInputRef = prop('current', ref)
      const valueLength = pathOr(0, ['value', 'length'], currentInputRef)
      const cursorPosition = propOr(0, 'selectionStart', currentInputRef)
      const isRightArrow = equals(BUTTON_KEY_CODES.ARROW_RIGHT, keyCode)
      const isLastPosition = equals(cursorPosition, valueLength)

      if (and(isRightArrow, isLastPosition)) {
        setFocus && setFocus(key)
      }
    },
    [ref, setFocus],
  )

  const handleOnKeyDown = useCallback(
    // eslint-disable-next-line complexity
    (event) => {
      if (isHorizontalArrow(event)) {
        if (switchFocusKey) {
          handlePressHorizontalArrow(switchFocusKey, event)
        }

        event.stopPropagation()
      }

      onKeyDown && onKeyDown(event)
    },
    [handlePressHorizontalArrow, switchFocusKey, isHorizontalArrow, onKeyDown],
  )

  return handleOnKeyDown
}

export default useHorizontalArrows
