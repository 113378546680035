// @flow
import typeToReducer from 'type-to-reducer'
import { map, propEq, reject } from 'ramda'

import type { MovieMediaStreams, Movie, Movies, TrailerMediaStream } from '@alphaott/api-client/types/movies'

import { prepareMovieImages } from '@alphaott/common-utils/utils/schemas/movies'
import {
  FETCH_MOVIE_BY_ID_TYPE,
  FETCH_MOVIE_SOURCE_ID_TYPE,
  FETCH_MOVIE_TRAILER_ID_TYPE,
  FETCH_MOVIE_RELATED_ID_TYPE,
  RESET_MOVIE_TYPE,
} from '../../actions/movie'

type BaseStateType = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
}

type SourcesState = BaseStateType & {
  +data: MovieMediaStreams,
}

type TrailerState = BaseStateType & {
  +data: ?TrailerMediaStream,
}

type RelatedState = BaseStateType & {
  +data: Movies,
}

export type MovieState = BaseStateType & {
  +data: Movie,
  +sources: SourcesState,
  +trailer: TrailerState,
  +related: RelatedState,
}

const baseState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
}

const initialState: MovieState = {
  data: {},
  sources: {
    ...baseState,
    data: [],
  },
  trailer: {
    ...baseState,
    data: null,
  },
  related: {
    ...baseState,
    data: [],
  },
  ...baseState,
}

const movie = typeToReducer(
  {
    [FETCH_MOVIE_BY_ID_TYPE]: {
      LOADING: (state): MovieState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): MovieState => {
        const { payload, meta } = action
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: prepareMovieImages(payload.data, meta.contentApi),
        }
      },

      ERROR: (state, action): MovieState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: {},
        errors: action.payload.error,
      }),
    },
    [FETCH_MOVIE_SOURCE_ID_TYPE]: {
      LOADING: (state): MovieState => ({ ...state, sources: { ...state.sources, isLoading: true } }),

      SUCCESS: (state, action): MovieState => {
        const { payload } = action
        return {
          ...state,
          sources: {
            ...state.sources,
            isLoading: false,
            isSuccess: true,
            data: payload.data,
          },
        }
      },

      ERROR: (state, action): MovieState => ({
        ...state,
        sources: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          data: [],
          errors: action.payload.error,
        },
      }),
    },

    [FETCH_MOVIE_TRAILER_ID_TYPE]: {
      LOADING: (state): MovieState => ({ ...state, trailer: { ...state.trailer, isLoading: true } }),

      SUCCESS: (state, action): MovieState => {
        const { payload } = action
        return {
          ...state,
          trailer: {
            ...state.trailer,
            isLoading: false,
            isSuccess: true,
            data: payload.data,
          },
        }
      },

      ERROR: (state, action): MovieState => ({
        ...state,
        trailer: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          data: {},
          errors: action.payload.error,
        },
      }),
    },

    [FETCH_MOVIE_RELATED_ID_TYPE]: {
      LOADING: (state): MovieState => ({ ...state, related: { ...state.related, isLoading: true } }),

      SUCCESS: (state, action): MovieState => {
        const { payload, meta } = action
        const preparedData = reject(
          propEq('_id', meta.id),
          map(item => prepareMovieImages(item, meta.contentApi), payload.data),
        )
        return {
          ...state,
          related: {
            ...state.related,
            isLoading: false,
            isSuccess: true,
            data: preparedData,
          },
        }
      },

      ERROR: (state, action): MovieState => ({
        ...state,
        related: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          data: [],
          errors: action.payload.error,
        },
      }),
    },
    [RESET_MOVIE_TYPE]: () => initialState,
  },
  initialState,
)

export { movie }
