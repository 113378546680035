// @flow

import styled, { css } from 'styled-components'

import { getColorPrimary } from '@alphaott/smart-tv-themes'

export const Container = styled.div`
  width: 100%;
`

export const ProgressLineContainer = styled.div`
  position: relative;
  width: 100%;
  height: 6rem;
  background: rgba(255, 255, 255, 0.24);
`

export const ProgressLine = styled.div`
  width: ${(props) => props.progress}px;
  height: 100%;
  background-color: ${getColorPrimary};
`

const transition = css`
  transition-property: left;
  transition-duration: 0.1s;
`

export const DotContainer = styled.div`
  position: absolute;
  width: 26rem;
  height: 26rem;
  top: -10rem;
  margin-left: -13rem;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  ${(props) => props.isSeeking && transition}
`

export const SeekTime = styled.div`
  position: absolute;
  top: -60rem;
  left: -44rem;
  font-size: 24rem;
  color: #f8f4f4;
  padding: 10rem;
  border-radius: 5rem;
  background-color: ${getColorPrimary};

  &::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10rem;
    border-width: 10rem;
    border-style: solid;
    border-color: ${getColorPrimary} transparent transparent transparent;
  }
`

export const Dot = styled.div`
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-color: ${(props) => (props.focused ? 'white' : getColorPrimary(props))};
`
