// @flow
import { isNotEmpty } from '@alphaott/common-utils'
import { init, concat } from 'ramda'
// $FlowFixMe
import { useCallback, useEffect, useRef, useState } from 'react'

export type onFullyFilledProps = (
  value: string,
  clear?: () => void,
  close?: () => void,
  error?: (err: string) => void,
) => void

export const usePinCodeModal = () => {
  const pinSize = 4
  const [isVisibleModal, setVisibleModal] = useState(false)
  const [currentPinCode, setCurrentPinCode] = useState('')
  const [title, setTitle] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [description, setDescription] = useState('')
  const [headerTitle, setHeaderTitle] = useState('')

  const handler = useRef(() => {})

  const handleOpen = useCallback(
    // eslint-disable-next-line complexity
    (newHandler: onFullyFilledProps, newTitle?: string, newDescription?: string, newHeaderTitle?: string) => {
      newTitle && setTitle(newTitle)
      newDescription && setDescription(newDescription)
      newHeaderTitle && setHeaderTitle(newHeaderTitle)
      setVisibleModal(true)
      handler.current = newHandler
    },
    [setVisibleModal, handler, setTitle],
  )

  const handleChangeTitle = useCallback(
    (newTitle: string) => {
      setTitle(newTitle)
    },
    [setTitle],
  )

  const handleChangeErrorMessage = useCallback(
    (newErrorMessage: string) => {
      setErrorMessage(newErrorMessage)
    },
    [setErrorMessage],
  )

  const currentSize = currentPinCode.length

  const handleAddChar = useCallback(
    (char: string) => {
      if (currentPinCode.length < pinSize) {
        const newPinCode = concat(currentPinCode, char)
        setCurrentPinCode(newPinCode)
      }
    },
    [currentPinCode, setCurrentPinCode],
  )

  const handleInput = useCallback(
    (chars: string) => {
      if (currentPinCode.length < pinSize) {
        const newPinCode = chars
        setCurrentPinCode(newPinCode)
      }
    },
    [currentPinCode, setCurrentPinCode],
  )

  const handleClear = useCallback(() => {
    setCurrentPinCode('')
    setTitle('')
    setDescription('')
    setErrorMessage('')
  }, [setCurrentPinCode, setTitle])

  const handleClose = useCallback(() => {
    setVisibleModal(false)
    handleClear()
  }, [setVisibleModal, handleClear])

  useEffect(() => {
    if (currentPinCode.length === pinSize) {
      // $FlowFixMe
      handler.current(currentPinCode, handleClear, handleClose, handleChangeErrorMessage)
    }
  }, [currentPinCode, handler, handleClose, handleClear, handleChangeTitle, handleChangeErrorMessage])

  const handleDelete = useCallback(() => {
    if (isNotEmpty(currentPinCode)) {
      setCurrentPinCode(init(currentPinCode))
    }
  }, [setCurrentPinCode, currentPinCode])

  return {
    isVisiblePinCodeModal: isVisibleModal,
    titlePinCodeModal: title,
    errorMessagePinCodeModal: errorMessage,
    descriptionPinCodeModal: description,
    headerTitlePinCodeModal: headerTitle,
    handleOpenPinCodeModal: handleOpen,
    handleClosePinCodeModal: handleClose,
    pinCodeModalSize: pinSize,
    currentPinCodeModalSize: currentSize,
    currentPinCodeModal: currentPinCode,
    handleAddCharPinCodeModal: handleAddChar,
    handleInputPinCodeModal: handleInput,
    handleDeleteCharPinCodeModal: handleDelete,
    handleChangeTitlePinCodeModal: handleChangeTitle,
  }
}

export default usePinCodeModal
