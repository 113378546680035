// @flow
import { path } from 'ramda'

import { getMoviesList } from '@alphaott/api-client'
import { POPULAR_MOVIE_MAX_COUNT } from '@alphaott/app-config'
import {
  getContentDomain,
  getPublicDomain,
  getUIMoviesSort,
  getCacheVOD,
  isCacheValid,
} from '@alphaott/app-main/selectors'

export const FETCH_POPULAR_MOVIES_TYPE = 'movies/FETCH_POPULAR_LIST'

export const fetchPopularMoviesAction = (serviceId: string) => (dispatch: Function, getState: Function) => {
  const state = getState()
  const sort = getUIMoviesSort()(state)
  const publicApi = getPublicDomain(state)
  const contentApi = getContentDomain(state)
  const ttl = getCacheVOD(state)

  const popularParam = {
    sort,
    limit: POPULAR_MOVIE_MAX_COUNT,
  }

  return dispatch({
    type: FETCH_POPULAR_MOVIES_TYPE,
    payload: getMoviesList(publicApi, serviceId, popularParam),
    meta: {
      contentApi,
      ttl,
    },
  })
}

// eslint-disable-next-line complexity
export const fetchPopularMovies = (serviceId: string, force?: boolean = false) => (
  dispatch: Function,
  getState: Function,
) => {
  if (force) return dispatch(fetchPopularMoviesAction(serviceId))

  const state = getState()
  const expires = path(['moviePopular', 'expires'], state)

  if (expires && isCacheValid(expires)) return null

  return dispatch(fetchPopularMoviesAction(serviceId))
}
