// @flow

import { useEffect, useState, useCallback } from 'react'
import format from 'date-fns/format'

export const useCurrentDate = () => {
  const getCurrentDate = useCallback(() => format(new Date(), 'dd MMM yyyy'), [])

  const [currentDate, setCurrentDate] = useState(getCurrentDate())

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(getCurrentDate())
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [getCurrentDate])

  return currentDate
}

export default useCurrentDate
