// @flow
import React, { memo } from 'react'
import type { ComponentType } from 'react'

import { Container, Title, Description } from './elements'

type Props = {
  title: string,
  description?: string,
  className?: string,
}

const ErrorAlertPure = ({ title, description, className }: Props) => (
  <Container className={className}>
    <Title>{title}</Title>
    {description && <Description>{description}</Description>}
  </Container>
)

export const ErrorAlert: ComponentType<Props> = memo<Props>(ErrorAlertPure)

export default ErrorAlert
