/* eslint-disable no-undef */
// @flow
import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
// import { equals } from 'ramda'

import { prepareCurrentNetworkStatus } from '@alphaott/smart-tv-common/src/hooks/useNetworkStatus'
import { setDeviceInfo } from '@alphaott/smart-tv-current-device/actions'

export const useInitDeviceInfo = () => {
  const dispatch = useDispatch()
  const setDeviceSystemInfo = useCallback(() => {
    // eslint-disable-next-line max-len
    // https://developer.samsung.com/smarttv/develop/api-references/tizen-web-device-api-references/systeminfo-api/getting-device-capabilities-using-systeminfo-api.html
    const brand = tizen?.systeminfo?.getCapability('http://tizen.org/system/manufacturer')
    const model = tizen?.systeminfo?.getCapability('http://tizen.org/system/model_name')
    const id = tizen?.systeminfo?.getCapability('http://tizen.org/system/tizenid')
    const os = tizen?.systeminfo?.getCapability('http://tizen.org/system/platform.name')
    const osVersion = tizen?.systeminfo?.getCapability('http://tizen.org/feature/platform.version')
    // const networkStatus = webapis.network.isConnectedToGateway()
    dispatch(
      setDeviceInfo({
        _id: id,
        serial: id,
        brand,
        model,
        os,
        osVersion,
        networkStatus: prepareCurrentNetworkStatus(navigator.onLine),
        visibilityStatus: document.hidden ? 'hidden' : 'visible',
      }),
    )
  }, [dispatch])

  const setWifiInfo = useCallback(() => {
    tizen?.systeminfo?.getPropertyValue('WIFI_NETWORK', (wifi) => {
      dispatch(
        setDeviceInfo({
          wifiMac: wifi.macAddress,
        }),
      )
    })
  }, [dispatch])

  const setEthernetInfo = useCallback(() => {
    tizen?.systeminfo?.getPropertyValue('ETHERNET_NETWORK', (ethernet) => {
      dispatch(
        setDeviceInfo({
          ethernetMac: ethernet.macAddress,
        }),
      )
    })
  }, [dispatch])

  // const setNetworkInfo = useCallback(
  //   (value) => {
  //     const isConnected = equals(webapis.network.NetworkState.GATEWAY_CONNECTED, value)
  //
  //     dispatch(
  //       setDeviceInfo({
  //         networkStatus: prepareCurrentNetworkStatus(isConnected),
  //       }),
  //     )
  //   },
  //   [dispatch],
  // )
  const setNetworkInfo = useCallback(
    (value) => () => {
      dispatch(
        setDeviceInfo({
          networkStatus: prepareCurrentNetworkStatus(value),
        }),
      )
    },
    [dispatch],
  )

  const setVisibilityStatus = useCallback(() => {
    dispatch(
      setDeviceInfo({
        visibilityStatus: document.hidden ? 'hidden' : 'visible',
      }),
    )
  }, [dispatch])

  useEffect(() => {
    setDeviceSystemInfo()
    setWifiInfo()
    setEthernetInfo()
    const wifiListenerId = tizen?.systeminfo?.addPropertyValueChangeListener(
      'WIFI_NETWORK',
      setWifiInfo,
    )
    const ethernetListenerId = tizen?.systeminfo?.addPropertyValueChangeListener(
      'ETHERNET_NETWORK',
      setEthernetInfo,
    )
    // const networkStateListenerId = webapis.network.addNetworkStateChangeListener(setNetworkInfo)
    window.addEventListener('online', setNetworkInfo(true))
    window.addEventListener('offline', setNetworkInfo(false))
    document.addEventListener('visibilitychange', setVisibilityStatus)
    return () => {
      tizen?.systeminfo?.removePropertyValueChangeListener(wifiListenerId)
      tizen?.systeminfo?.removePropertyValueChangeListener(ethernetListenerId)
      // webapis.network.removeNetworkStateChangeListener(networkStateListenerId)
      window.removeEventListener('online', setNetworkInfo(true))
      window.removeEventListener('offline', setNetworkInfo(false))
      document.removeEventListener('visibilitychange', setVisibilityStatus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
