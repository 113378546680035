// @flow
export type ColorType = {
  white: string,
  primary: string,
  secondary: string,
  dark: string,
  light: string,
  gray: string,
  grayText: string,
  grayLabel: string,
  grayBorder: string,
  errorRed: string,
  redFavorite: string,
  greenSuccess: string,
  greenFavorite: string,
  yellowWarning: string,
  blue: string,
  signColor: string,
  primaryGray: string,
  secondaryGray: string,
}

export const colorPalette: ColorType = {
  white: '#fff',
  primary: '#f26522',
  secondary: '#cc4b35',
  dark: '#222',
  light: '#f6f6f6',
  gray: '#ddd',
  grayText: '#858585',
  grayLabel: '#B0B0B0',
  grayBorder: '#D8D8D8',
  errorRed: '#f54b5e',
  redFavorite: '#fc1b28',
  greenSuccess: '#48d2a0',
  greenFavorite: '#37cc71',
  yellowWarning: '#f8c51c',
  blue: '#2196f3',
  wildSand: '#F6F6F6',
  signColor: '#fafafa',
  primaryGray: 'rgba(255, 255, 255, 0.87)',
  secondaryGray: 'rgba(255, 255, 255, 0.6)',
}

export const themeStyle = {
  light: 'light',
  dark: 'dark',
}
