// @flow

import typeToReducer from 'type-to-reducer'
import { normalize } from 'normalizr'

import type { Genres } from '@alphaott/api-client/types/genres'
import type { GenresNormalize } from '@alphaott/common-types/normalized'

import { isValidTTL, calculateExpires } from '@alphaott/app-main/selectors'
import { genresListSchema } from '@alphaott/common-utils/utils/schemas/genres'
import { defaultToEmptyObject } from '@alphaott/common-utils/utils/help'
import { FETCH_MOVIE_GENRES_TYPE } from '../../actions/genres'

export type MovieGenresState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Genres,
  +entities: GenresNormalize,
  +expires: ?number,
}

const initialState: MovieGenresState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
  entities: {},
  expires: null,
}

const movieGenres = typeToReducer(
  {
    [FETCH_MOVIE_GENRES_TYPE]: {
      LOADING: (state): MovieGenresState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): MovieGenresState => {
        const {
          payload,
          meta: { ttl },
        } = action
        const { entities } = normalize(payload.data, genresListSchema)
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          entities: defaultToEmptyObject(entities.genres),
          data: payload.data,
          ...(isValidTTL(ttl) && { expires: calculateExpires(ttl) }),
        }
      },

      ERROR: (state, action): MovieGenresState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        expires: null,
      }),
    },
  },
  initialState,
)

export { movieGenres }
