// @flow

import React, { memo, useMemo, useCallback } from 'react'
import { or } from 'ramda'
import { isFalse } from 'ramda-adjunct'

import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'

import { Image } from './Avatar.style'

export type AvatarProps = {
  focusKey?: string,
  className?: string,
  image: any,
  imageStyles?: string,
  focused?: boolean,
  highlighted?: boolean,
  onFocus?: Function,
  onClick?: Function,
}

const AvatarPure = ({
  focusKey,
  className,
  highlighted,
  image,
  imageStyles,
  onFocus,
  onClick,
  withMouseOver = true,
  ...props
}: AvatarProps) => {
  const { ref, focused, focusSelf } = useFocusable({ focusKey, onFocus, onEnterPress: onClick })
  const { handleMouseOver: onMouseOver } = useMouseOver(focusSelf)

  const isFocused = useMemo(() => or(focused, highlighted), [focused, highlighted])

  const handleMouseOver = useCallback(
    // eslint-disable-next-line consistent-return
    (event) => {
      if (isFalse(withMouseOver)) return null
      onMouseOver(event)
    },
    [withMouseOver, onMouseOver],
  )

  return (
    <Image
      {...props}
      ref={ref}
      className={className}
      focused={isFocused}
      src={image}
      imageStyles={imageStyles}
      onClick={onClick}
      onMouseOver={handleMouseOver}
    />
  )
}

export const Avatar = memo<AvatarProps>(AvatarPure)

export default Avatar
