// @flow

import {
  defaultTo,
  reduce,
  concat,
  pipe,
  not,
  isEmpty,
  equals,
  map,
  prop,
  isNil,
  and,
  or,
  complement,
  find,
  propEq,
  chain,
  toPairs,
  fromPairs,
  type,
  anyPass,
} from 'ramda'

export const defaultToZero = defaultTo(0)

export const defaultToEmptyString = defaultTo('')

export const defaultToEmptyPath = defaultTo({ path: '' })

export const defaultToEmptyObject = defaultTo({})

export const defaultToEmptyArray = defaultTo([])

export const concatAll = reduce(concat, [])

export const isNilOrEmpty = anyPass([isNil, isEmpty])

export const isNotNilOrEmpty = complement(isNilOrEmpty)

export const isNotEmpty = pipe(
  isEmpty,
  not,
)

export const isNotNil = pipe(
  isNil,
  not,
)

export const isNotZero = pipe(
  equals(0),
  not,
)

export const isNotEqual = complement(equals)

export const mapId = map(prop('_id'))

export const isTrue = equals(true)

export const findById = (id: string, list: Array<any>) => find(propEq('_id', id), list)

export const notEqual = (props: Object, nextProps: Object): Function => field =>
  not(equals(nextProps[field], props[field]))

type InitialState = {
  +isError: boolean,
  +isSuccess: boolean,
}

export const isInitialLoading = (state: InitialState, dataField: string = 'data'): boolean =>
  and(or(isEmpty(state[dataField]), isNil(state[dataField])), and(not(state.isError), not(state.isSuccess)))

// eslint-disable-next-line complexity
export const deepCompareStringArray = (a: string[], b: string[]): boolean => {
  if (!(Array.isArray(a) && Array.isArray(b))) return false
  if (a.length !== b.length) return false
  const sortedA = a.sort()
  const sortedB = b.sort()
  return sortedA.every((value, index) => value === sortedB[index])
}

// https://github.com/ramda/ramda/wiki/Cookbook#flatten-a-nested-object-into-dot-separated-key--value-pairs
export const flattenObj = (obj: Object) => {
  const go = obj_ =>
    chain(([k, v]) => {
      if (type(v) === 'Object' || type(v) === 'Array') {
        return map(([k_, v_]) => [`${k}.${k_}`, v_], go(v))
      }
      return [[k, v]]
    }, toPairs(obj_))

  return fromPairs(go(obj))
}
