// @flow

import React, { useCallback, useEffect, useMemo, useRef, memo } from 'react'
import { length, keys, values, prop } from 'ramda'
import { useTranslation } from 'react-i18next'
import { VariableSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'
import { scale } from '@alphaott/smart-tv-utils'

import { AvatarItem } from './AvatarItem'
import { SubTitle, Wrapper, listStyle } from './SelectIconView.style'
import { AVATAR_CARD_WIDTH as AVATAR_WIDTH } from '../Avatar/Avatar.style'

import type { IconsPack } from './types'

export const NUMBER_OF_CARDS = 5
export const AVATAR_SPACING = 50
export const AVATAR_CARD_WIDTH = AVATAR_WIDTH + AVATAR_SPACING

type SelectIconRowItemData = {
  icons: Array<IconsPack>,
  onBecameFocusedRow: Function,
  onSelectAvatar: Function,
}

type SelectIconRowItemProps = {
  index: number,
  style: any,
  data: SelectIconRowItemData,
}

const SelectIconRowItemPure = ({ index, style, data }: SelectIconRowItemProps) => {
  const { ref, focusKey, hasFocusedChild } = useFocusable({ trackChildren: true })
  const { t } = useTranslation()

  const listRef = useRef()

  const { icons, onBecameFocusedRow, onSelectAvatar } = useMemo(() => data, [data])
  const item = useMemo(() => icons[index], [icons, index])
  const title = useMemo(() => prop('title', item), [item])
  const avatarsKeys = useMemo(() => keys(item.icons), [item.icons])
  const itemCount = useMemo(() => length(avatarsKeys), [avatarsKeys])
  const images = useMemo(() => values(item.icons), [item.icons])

  const getItemSize = useCallback(() => scale(window.innerWidth, AVATAR_CARD_WIDTH), [])

  const onBecameFocusedAvatar = useCallback(
    (leftPosition, widthCard) => {
      if (listRef.current) listRef.current.scrollTo(leftPosition - widthCard * NUMBER_OF_CARDS)
    },
    [listRef],
  )

  useEffect(() => {
    hasFocusedChild && onBecameFocusedRow(index)
  }, [hasFocusedChild, index, onBecameFocusedRow])

  return (
    <FocusContext.Provider value={focusKey}>
      <Wrapper ref={ref} style={style}>
        <SubTitle>{t(title)}</SubTitle>
        <AutoSizer>
          {({ width }) => (
            <List
              ref={listRef}
              layout="horizontal"
              width={width}
              height={getItemSize()}
              itemCount={itemCount}
              itemSize={getItemSize}
              itemData={{
                images,
                avatarsKeys,
                onBecameFocusedAvatar,
                onSelectAvatar,
              }}
              style={listStyle}
            >
              {AvatarItem}
            </List>
          )}
        </AutoSizer>
      </Wrapper>
    </FocusContext.Provider>
  )
}

export const SelectIconRowItem = memo<SelectIconRowItemProps>(SelectIconRowItemPure)

export default SelectIconRowItem
