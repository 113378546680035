// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { PageView as PageViewComponent } from '../../PageView'
import { Button as ButtonBase } from '../../Button'

export const PageView = styled(PageViewComponent)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100%;
`

export const Container = styled(Flex)`
  align-items: center;
  flex-direction: column;
`

export const Title = styled.span`
  text-align: center;
  font-size: 55rem;
  color: rgba(255, 255, 255, 0.87);
  max-width: 70%;
`

export const SubTitle = styled.span`
  text-align: center;
  font-size: 45rem;
  color: rgba(255, 255, 255, 0.87);
  max-width: 70%;
  margin-top: 10rem;
`

export const Code = styled.span`
  position: absolute;
  right: 50rem;
  bottom: 70rem;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 28rem;
  line-height: 34rem;
  letter-spacing: 0.8rem;
`

export const ButtonsContainer = styled(Flex)`
  margin-top: 44rem;
`

export const Button = styled(ButtonBase)`
  min-width: 260rem;
  padding-top: 22rem;
`

export const LogoutButton = styled(Button)`
  margin-left: 24rem;
`
