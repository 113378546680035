// @flow
import React, { memo, useCallback } from 'react'
import { pipe } from 'ramda'

import { withParentalControl } from '@alphaott/smart-tv-common'
import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'
// eslint-disable-next-line max-len
import defaultChannelLogo from '@alphaott/smart-tv-channels/src/components/assets/defaultChannelLogo.png'

import { ItemContainer, Item, Logo, Number, Title, Placeholder } from './ChannelItem.style'

type ChannelItemProps = {
  focusKey?: string,
  number: number,
  title: string,
  src: string,
  style?: any,
  isSelected: boolean,
  onFocus?: Function,
  onPress: Function,
}

const ChannelItemPure = ({
  focusKey,
  id,
  number,
  title,
  src,
  style,
  onPress,
  isSelected,
  onFocus,
}: ChannelItemProps) => {
  const handleFocus = useCallback((event) => onFocus(event, { id }), [id, onFocus])

  const { ref, focused } = useFocusable({ focusKey, onFocus: handleFocus, onEnterPress: onPress })

  const placeholder = <Placeholder src={defaultChannelLogo} alt="placeholder" />

  return (
    <ItemContainer
      ref={ref}
      style={style}
      onClick={onPress}
      focused={focused}
      isSelected={isSelected}
    >
      <Item>
        <Logo src={src} placeholder={defaultChannelLogo} unloader={placeholder} alt="placeholder" />
        <Number>{number}</Number>
        <Title>{title}</Title>
      </Item>
    </ItemContainer>
  )
}

export const ChannelItem = pipe(memo, withParentalControl)<ChannelItemProps>(ChannelItemPure)

export default ChannelItem
