// @flow

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { filter, join, pathOr, values } from 'ramda'
import { isTruthy } from 'ramda-adjunct'

import { getBrand } from '@alphaott/app-main/selectors'
import {
  getSupportEmail,
  getSupportWww,
  getSupportAddress,
  getSupportTitle,
} from '@alphaott/app-core/selectors'

const joinWithComa = (items) => join(', ', items)

export const useBrandDetail = () => {
  const brand = useSelector(getBrand)
  const supportEmail = useSelector(getSupportEmail)
  const supportWww = useSelector(getSupportWww)
  const supportAddress = useSelector(getSupportAddress)
  const companyName = useSelector(getSupportTitle)

  const infoEmail = useMemo(() => pathOr('', ['company', 'email'], brand), [brand])
  const address = useMemo(
    () => joinWithComa(filter(isTruthy, values(supportAddress))),
    [supportAddress],
  )

  return {
    supportEmail,
    supportWww,
    companyName,
    infoEmail,
    supportAddress: address,
  }
}
