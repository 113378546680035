// @flow

import { find, propEq } from 'ramda'

import { themeAlphaott } from './alphaott'
import { themeZaaptv } from './zaaptv'
import { themeMiplay } from './miplay'
import { themeKetura } from './ketura'
import { themeOnlinearabia } from './onlinearabia'
import { themeIttv } from './ittv'
import { themeSignisplay } from './signisplay'
import { themeITplay } from './itplay'
import { themeHabalitv } from './habalitv'
import { themeKapang } from './kapang'
import { themeIlinkstv } from './ilinkstv'
import { themeKapangus } from './kapangus'
import { themeTugotv } from './tugotv'
import { themeEztv } from './eztv'
import { themeStreamnxt } from './streamnxt'
import { themeOnlymotors } from './onlymotors'
import { themeCloodtv } from './cloodtv'
import { themeLoviztv } from './loviztv'
import { themeEllastvmax } from './ellastvmax'
import { themeFuturetv } from './futuretv'
import { themeTvanetplay } from './tvanetplay'
import { themeSoftv } from './softv'
import { themeProFoxy } from './profoxy'
import { themeUmaxx } from './umaxx'

import { themeStyle } from './colorPalettes'

export const themesNames = {
  alphaott: themeAlphaott.name,
  zaaptv: themeZaaptv.name,
  miplay: themeMiplay.name,
  ketura: themeKetura.name,
  onlinearabia: themeOnlinearabia,
  ittv: themeIttv,
  signisplay: themeSignisplay,
  itplay: themeITplay,
  habalitv: themeHabalitv,
  kapang: themeKapang,
  ilinkstv: themeIlinkstv,
  kapangus: themeKapangus,
  tugotv: themeTugotv,
  eztv: themeEztv,
  streamnxt: themeStreamnxt,
  onlymotors: themeOnlymotors,
  cloodtv: themeCloodtv,
  loviztv: themeLoviztv,
  ellastvmax: themeEllastvmax,
  futuretv: themeFuturetv,
  tvanetplay: themeTvanetplay,
  softv: themeSoftv,
  profoxy: themeProFoxy,
  umaxx: themeUmaxx,
}

export type ThemeType = {|
  name: string,
  getThemeVariation: Function,
|}

export type ThemeVariationType = {|
  colors: Object,
  background: Object,
  text: Object,
  border: Object,
  filter: Object,
  tabs: Object,
  card: Object,
  dimension: Object,
  size: string,
  breakpoints: Array<string>,
  signIn: Object,
|}

const themes = [
  themeAlphaott,
  themeZaaptv,
  themeMiplay,
  themeKetura,
  themeOnlinearabia,
  themeIttv,
  themeSignisplay,
  themeITplay,
  themeHabalitv,
  themeKapang,
  themeIlinkstv,
  themeKapangus,
  themeTugotv,
  themeEztv,
  themeStreamnxt,
  themeOnlymotors,
  themeCloodtv,
  themeLoviztv,
  themeEllastvmax,
  themeFuturetv,
  themeTvanetplay,
  themeSoftv,
  themeProFoxy,
  themeUmaxx,
]

export const getAppTheme = (
  themeName: string = themesNames.alphaott,
  themeType: string = themeStyle.light,
): ThemeVariationType => {
  const matchedTheme = find(propEq('name', themeName))(themes)
  return matchedTheme ? matchedTheme.getThemeVariation(themeType) : themeAlphaott.getThemeVariation(themeType)
}
