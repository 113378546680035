// @flow

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_STREAM_ERROR_MESSAGE } from '@alphaott/app-config'

import { ErrorComponent } from '../Error'

export const withLimitError =
  (WrappedComponent) =>
  // eslint-disable-next-line react/display-name
  (props: any) => {
    const { t } = useTranslation()

    const [isShowLimitError, setShowLimitError] = useState(false)

    const handleShowLimitError = useCallback(() => setShowLimitError(true), [])

    const handleHideLimitError = useCallback(() => setShowLimitError(false), [])

    if (isShowLimitError) {
      return (
        <ErrorComponent
          code="403"
          title={DEFAULT_STREAM_ERROR_MESSAGE}
          isAvailableLogoutButton={false}
          buttonLabel={t('reload')}
          onClickActionButton={handleHideLimitError}
        />
      )
    }

    return (
      <WrappedComponent
        {...props}
        onShowLimitError={handleShowLimitError}
        onHideLimitError={handleHideLimitError}
      />
    )
  }

export default withLimitError
