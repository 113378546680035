// @flow
import { themeStyle } from '../colorPalettes'
import { darkTheme } from './darkTheme'
import type { ThemeType } from '..'

const name = 'cloodtv'
export const themeCloodtv: ThemeType = {
  name,
  getThemeVariation: (themeVariation: string) => {
    switch (themeVariation) {
      case themeStyle.dark:
        return darkTheme
      default:
        return darkTheme
    }
  },
}
