// @flow

export const devices = {
  unknown: 'UNKNOWN',
  stb: 'STB',
  pc: 'PC',
  tv: 'TV',
  mobile: 'MOBILE',
  tablet: 'TABLET',
}

export type DeviceType = $Values<typeof devices>

export type Device = {|
  _id: string,
  type: DeviceType,
  model: string,
  platform: string,
  brand: string,
  serial: string,
  mac: string,
  created?: string,
  status?: string,
|}

export type Devices = Array<Device>
