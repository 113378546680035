// @flow

import React, { memo, useMemo, useCallback, useRef } from 'react'
import { prop, not, toUpper } from 'ramda'
import { mapIndexed } from 'ramda-adjunct'
import { useLocation } from 'react-router-dom'

import { FocusContext, useFocusable } from '@alphaott/smart-tv-spatial-navigation'

import { Tabs, Tab, FocusableContainer } from './TabBar.styles'

type TabBarProps = {
  items: Object[],
  onNavigate: Function,
}

const TabBarPure = ({ items = [], onNavigate }: TabBarProps) => {
  const { pathname } = useLocation()
  const ref = useRef(null)

  const { focusKey, ref: focusRef } = useFocusable({
    preferredChildFocusKey: pathname,
  })

  const handleFocus = useCallback(
    // eslint-disable-next-line consistent-return
    (values) => {
      if (values) {
        return ref?.current?.centerAt(values.x, 0)
      }
    },
    [ref],
  )

  const preparedItems = useMemo(
    () =>
      mapIndexed((item, index) => {
        const isEnabled = prop('isEnabled', item)

        if (not(isEnabled)) return null

        const label = prop('label', item)
        const to = prop('to', item)

        return (
          <Tab key={index} focusKey={to} to={to} onFocus={handleFocus} onClick={onNavigate}>
            {toUpper(label)}
          </Tab>
        )
      }, items),
    [items, handleFocus, onNavigate],
  )

  return (
    <FocusContext.Provider value={focusKey}>
      <Tabs ref={ref}>
        <FocusableContainer ref={focusRef}>{preparedItems}</FocusableContainer>
      </Tabs>
    </FocusContext.Provider>
  )
}

export const TabBar = memo<TabBarProps>(TabBarPure)

export default TabBar
