// @flow

import typeToReducer from 'type-to-reducer'
import { map } from 'ramda'

import { prepareMovieImages } from '@alphaott/common-utils/utils/schemas/movies'
import { isValidTTL, calculateExpires } from '@alphaott/app-main/selectors'
import type { Movies } from '@alphaott/api-client/types/movies'

import { FETCH_POPULAR_MOVIES_TYPE } from '../../actions/popular'

export type PopularMoviesState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Movies,
  +expires: ?number,
}

const initialState: PopularMoviesState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
  expires: null,
}

const moviePopular = typeToReducer(
  {
    [FETCH_POPULAR_MOVIES_TYPE]: {
      LOADING: (state): PopularMoviesState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): PopularMoviesState => {
        const {
          payload: { data },
          meta: { contentApi, ttl },
        } = action
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: map(item => prepareMovieImages(item, contentApi), data),
          ...(isValidTTL(ttl) && { expires: calculateExpires(ttl) }),
        }
      },

      ERROR: (state, action): PopularMoviesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        data: [],
        expires: null,
      }),
    },
  },
  initialState,
)

export { moviePopular }
