// @flow

import styled from 'styled-components'

import { getColorPrimary, getTextColorPrimary } from '@alphaott/smart-tv-themes'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 74rem;
  background-color: ${({ focused }) => (focused ? getColorPrimary : 'transparent')};
  cursor: default;
  margin-top: 1rem;
`

export const Label = styled.div`
  margin-left: 63rem;
  color: ${({ focused }) => (focused ? getTextColorPrimary : '#F8F4F4')};
  font-weight: 700;
  font-size: 28rem;
  letter-spacing: 1rem;
`
