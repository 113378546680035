// @flow
import { MEDIA_STREAM_TYPE } from './const'

import type { AcceptMediaStreamList } from './const'

// TODO: Сheck for which browser MSE API is available &&
// for which browsers HLS is native

// Returns static array with Media Streams
export const supportedAcceptMediaStream: AcceptMediaStreamList = [
  MEDIA_STREAM_TYPE.MPEG_DASH,
  MEDIA_STREAM_TYPE.HLS,
  MEDIA_STREAM_TYPE.MP3,
  MEDIA_STREAM_TYPE.MP4,
  MEDIA_STREAM_TYPE.AAC,
  MEDIA_STREAM_TYPE.OGG,
  MEDIA_STREAM_TYPE.FLAC,
]

export default supportedAcceptMediaStream
