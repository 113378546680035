// @flow
import React, { useState, useEffect } from 'react'

import { diffInSeconds, preparePercent } from '@alphaott/app-programs/utils'

import type { DateType } from '@alphaott/app-programs/utils'

import { LineWrapper, Colored } from './ProgressLine.style'

type ProgressProps = {
  from: DateType,
  to: DateType,
}

const UPDATE_LINE_INTERVAL = 1000

export const Progress = ({ from, to, ...other }: ProgressProps) => {
  const [current, setCurrent] = useState(Date.now())
  const percent = preparePercent(diffInSeconds(from, current), diffInSeconds(from, to))

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(Date.now())
    }, UPDATE_LINE_INTERVAL)

    return () => {
      clearInterval(interval)
    }
  })

  return (
    <LineWrapper {...other}>
      <Colored width={percent} style={{ width: `${percent}%` }} />
    </LineWrapper>
  )
}
