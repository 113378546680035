// @flow

import { push } from 'redux-first-history'

import type { GuestAccessState } from '../../reducers'

export const CHANGE_GUEST_ACCESS_TTL_TYPE = 'appGuestAccess/CHANGE_GUEST_ACCESS_TTL'
export const RESET_GUEST_ACCESS_DATA_TYPE = 'appGuestAccess/RESET_GUEST_ACCESS_DATA'

export const changeGuestAccessTTL = (data: GuestAccessState) => ({
  type: CHANGE_GUEST_ACCESS_TTL_TYPE,
  payload: data,
})

export const resetGuestAccessData = () => ({
  type: RESET_GUEST_ACCESS_DATA_TYPE,
})

export const resetGuestAccessTTL = (dispatch: Function) => {
  dispatch(changeGuestAccessTTL({ expires: 0 }))
  dispatch(push('/auth/expired-guest-access'))
}
