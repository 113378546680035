// @flow

import { useCallback } from 'react'
import { prop, uniqBy, filter, propEq } from 'ramda'
import { isNilOrEmpty } from 'ramda-adjunct'

import { prepareTracks } from '@alphaott/common-utils'
import { useTracks as usePreferredTracks } from '@alphaott/video-player-utils'
import { getActiveTrack } from '@alphaott/video-player/src/getActiveTrack'
import { useTranslateDefaultLanguageOption } from '@alphaott/common-utils/settings'
import { DISABLED_LANGUAGE_OPTION } from '@alphaott/app-config'

export const useVideoJSPlayerTracks = (
  videoId: string,
  playerRef: any,
  {
    onChangeAudioTracks,
    onChangeSubtitleTracks,
  }: {
    onChangeAudioTracks: (activeTrack: Object, audioTracks: Object[]) => void,
    onChangeSubtitleTracks: (activeTrack: Object, subtitleTracks: Object[]) => void,
  },
) => {
  const defaultLanguageOption = useTranslateDefaultLanguageOption(DISABLED_LANGUAGE_OPTION)

  const { getAudioTrack: getPreferredAudioTrack, getSubtitlesTrack: getPreferredSubtitleTrack } =
    usePreferredTracks(videoId)

  const handleGetTrackByLanguage = useCallback((language: string, tracksObj) => {
    // eslint-disable-next-line no-underscore-dangle
    const tracks = tracksObj?.tracks_

    return {
      track: tracks.find((item) => item.language?.toLowerCase() === language?.toLowerCase()),
      tracks,
    }
  }, [])

  const handlePrepareTracks = useCallback((tracks) => {
    const uniqTracks = uniqBy(prop('language'), tracks)
    const preparedTracks = prepareTracks(uniqTracks)

    return preparedTracks
  }, [])

  const handleGetAudioTracks = useCallback(
    (audioTracks) => {
      // eslint-disable-next-line no-underscore-dangle
      const tracks = audioTracks.tracks_

      if (isNilOrEmpty(tracks) || tracks?.length <= 1) {
        return []
      }

      return handlePrepareTracks(tracks)
    },
    [handlePrepareTracks],
  )

  const handleGetSubtitleTracks = useCallback(
    (subtitleTracks) => {
      // eslint-disable-next-line no-underscore-dangle
      const tracks = subtitleTracks.tracks_

      if (isNilOrEmpty(tracks) || tracks?.length <= 1) {
        return []
      }

      const preparedSubtitleTracks = [
        {
          label: defaultLanguageOption.nativeName,
          language: defaultLanguageOption.key,
          active: tracks.findIndex((item) => item.active) === -1,
        },
        ...filter(propEq('kind', 'subtitles'), tracks),
      ]

      return handlePrepareTracks(preparedSubtitleTracks)
    },
    [defaultLanguageOption.nativeName, defaultLanguageOption.key, handlePrepareTracks],
  )

  const handleChangeDefaultAudioTrack = useCallback(
    // eslint-disable-next-line complexity
    (audioTracks) => {
      // eslint-disable-next-line no-underscore-dangle
      const activePlayerTrack = getActiveTrack('enabled')(audioTracks?.tracks_)
      const preparedTracks = handleGetAudioTracks(audioTracks)
      const preferredAudioTrack = getPreferredAudioTrack(preparedTracks)

      if (
        activePlayerTrack?.language !== preferredAudioTrack?.language &&
        audioTracks?.length > 1
      ) {
        const { track } = handleGetTrackByLanguage(preferredAudioTrack?.language, audioTracks)

        if (track) {
          playerRef.current?.onChangeAudioTrack(track)
        }
      }
    },
    [handleGetAudioTracks, getPreferredAudioTrack, handleGetTrackByLanguage, playerRef],
  )

  const handleChangeDefaultSubtitleTrack = useCallback(
    // eslint-disable-next-line complexity
    (subtitleTracks) => {
      // eslint-disable-next-line no-underscore-dangle
      const activePlayerTrack = getActiveTrack('showing')(subtitleTracks?.tracks_)
      const preparedTracks = handleGetSubtitleTracks(subtitleTracks)
      const preferredSubtitleTrack = getPreferredSubtitleTrack(preparedTracks)

      if (
        activePlayerTrack?.language !== preferredSubtitleTrack?.language &&
        subtitleTracks?.length > 1
      ) {
        const { track, tracks } = handleGetTrackByLanguage(
          preferredSubtitleTrack?.language,
          subtitleTracks,
        )

        playerRef.current?.onChangeSubtitleTrack(track, tracks)
      }
    },
    [handleGetSubtitleTracks, getPreferredSubtitleTrack, handleGetTrackByLanguage, playerRef],
  )

  const handleVariantChanged = useCallback(() => {
    playerRef.current?.trigger('variantchanged')
  }, [playerRef])

  const handleTextChanged = useCallback(() => {
    playerRef.current?.trigger('textchanged')
  }, [playerRef])

  const handleInitTracks = useCallback(() => {
    const audioTracks = playerRef.current?.getAudioTracks()
    const subtitleTracks = playerRef.current?.getSubtitleTracks()

    audioTracks?.addEventListener('change', handleVariantChanged)
    subtitleTracks?.addEventListener('change', handleTextChanged)

    playerRef.current?.on('dispose', () => {
      audioTracks.removeEventListener('change', handleVariantChanged)
      subtitleTracks.removeEventListener('change', handleTextChanged)
    })

    setTimeout(() => {
      handleChangeDefaultAudioTrack(audioTracks)
      handleChangeDefaultSubtitleTrack(subtitleTracks)
    }, 0)

    return {
      audioTracks: handleGetAudioTracks(audioTracks),
      subtitleTracks: handleGetSubtitleTracks(subtitleTracks),
    }
  }, [
    playerRef,
    handleVariantChanged,
    handleTextChanged,
    handleGetAudioTracks,
    handleGetSubtitleTracks,
    handleChangeDefaultAudioTrack,
    handleChangeDefaultSubtitleTrack,
  ])

  const handleChangeAudioTrack = useCallback(
    (selectedTrack) => {
      const audioTracks = playerRef.current.getAudioTracks()
      const { track } = handleGetTrackByLanguage(selectedTrack.language, audioTracks)

      if (track) {
        onChangeAudioTracks(track, [], { preferred: true })
      }

      return playerRef.current.onChangeAudioTrack(track)
    },
    [playerRef, handleGetTrackByLanguage, onChangeAudioTracks],
  )

  const handleChangeSubtitleTrack = useCallback(
    (selectedTrack) => {
      const subtitleTracks = playerRef.current.getSubtitleTracks()
      const { track, tracks } = handleGetTrackByLanguage(selectedTrack.language, subtitleTracks)

      if (track) {
        onChangeSubtitleTracks(track, [], { preferred: true })
      }

      return playerRef.current.onChangeSubtitleTrack(track, tracks)
    },
    [playerRef, handleGetTrackByLanguage, onChangeSubtitleTracks],
  )

  const handleChangedAudioTrack = useCallback(() => {
    const audioTracks = handleGetAudioTracks(playerRef.current.getAudioTracks())
    const activeTrack = getActiveTrack('active')(audioTracks)

    onChangeAudioTracks(activeTrack, audioTracks)
  }, [playerRef, handleGetAudioTracks, onChangeAudioTracks])

  // eslint-disable-next-line consistent-return
  const handleChangedSubtitleTrack = useCallback(() => {
    const subtitleTracks = playerRef.current.getSubtitleTracks()
    const tracks = handleGetSubtitleTracks(subtitleTracks)
    // eslint-disable-next-line no-underscore-dangle
    const activeTrack = subtitleTracks.tracks_.find((item) => item.mode === 'showing')

    if (activeTrack) {
      return onChangeSubtitleTracks(
        activeTrack,
        tracks.map((item) => ({
          ...item,
          active: item.language?.toLowerCase() === activeTrack.language?.toLowerCase(),
        })),
      )
    }

    onChangeSubtitleTracks(
      tracks[0],
      tracks.map((item) => ({
        ...item,
        active: item.language === 'none',
      })),
    )
  }, [playerRef, handleGetSubtitleTracks, onChangeSubtitleTracks])

  return {
    onInitTracks: handleInitTracks,
    onChangeAudioTrack: handleChangeAudioTrack,
    onChangeSubtitleTrack: handleChangeSubtitleTrack,
    onChangedAudioTrack: handleChangedAudioTrack,
    onChangedSubtitleTrack: handleChangedSubtitleTrack,
  }
}

export default useVideoJSPlayerTracks
