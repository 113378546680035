// @flow
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { findIndex, propEq, multiply, equals, prop } from 'ramda'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'
import { scale } from '@alphaott/smart-tv-utils'

import { ChannelItem, CHANNEL_ITEM_HEIGHT } from '../ChannelItem'
import {
  Container,
  ListContainer,
  StyledList,
  ChevronUpIcon,
  ChevronDownIcon,
  ChannelsListDivider,
} from './ChannelsList.style'

type ChannelsListProps = {
  focusKey?: string,
  items: Array<any>,
  selectedItemId: string,
  onPressItem: Function,
  onFocusItem: Function,
}

const DIVIDER_HEIGHT: number = 3

export const ChannelsListPure = ({
  focusKey: parentFocusKey,
  items,
  selectedItemId,
  onPressItem,
  onFocusItem,
}: ChannelsListProps) => {
  const { ref, focusKey, setFocus } = useFocusable({ focusKey: parentFocusKey })

  const focusedItemIndex = useMemo(
    () => findIndex(propEq('id', selectedItemId))(items),
    [items, selectedItemId],
  )

  const SCALED_CHANNEL_ITEM_HEIGHT = useMemo(
    () => scale(window.innerWidth, CHANNEL_ITEM_HEIGHT + DIVIDER_HEIGHT),
    [],
  )

  const initialScrollOffset = useMemo(
    () => multiply(SCALED_CHANNEL_ITEM_HEIGHT, focusedItemIndex),
    [SCALED_CHANNEL_ITEM_HEIGHT, focusedItemIndex],
  )

  const renderItem = useCallback(
    ({ item }) => {
      const id = prop('id', item)
      const onFocus = prop('onBecameFocused', item)
      const isSelected = equals(selectedItemId, id)

      const handlePressItem = () => onPressItem(id)

      return (
        <ChannelItem
          {...item}
          key={id}
          focusKey={id}
          isSelected={isSelected}
          onFocus={onFocus}
          onPress={handlePressItem}
        />
      )
    },
    [onPressItem, selectedItemId],
  )

  const renderDivider = useCallback(({ style }) => <ChannelsListDivider style={style} />, [])

  useEffect(() => {
    setFocus(selectedItemId)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FocusContext.Provider value={focusKey}>
      <Container ref={ref}>
        <ChevronUpIcon />
        <ListContainer>
          <StyledList
            scrollStrategy="auto"
            initialScrollOffset={initialScrollOffset}
            items={items}
            dividerSize={DIVIDER_HEIGHT}
            renderItem={renderItem}
            renderDivider={renderDivider}
            onFocusItem={onFocusItem}
          />
        </ListContainer>
        <ChevronDownIcon />
      </Container>
    </FocusContext.Provider>
  )
}

export const ChannelsList = memo<ChannelsListProps>(ChannelsListPure)

export default ChannelsList
