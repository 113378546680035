// @flow
import styled from 'styled-components'

import { getColorPrimary, getTextColorSecondary } from '@alphaott/smart-tv-themes'

export const LineWrapper = styled.div`
  width: 100%;
  height: 6rem;
  background: ${getTextColorSecondary};
  position: relative;
`
export const Colored = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 6rem;
  background: ${getColorPrimary};
  transition: all 0.5s linear;
`
