// eslint-disable-next-line import/no-extraneous-dependencies
import { mergeDeepRight } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'

export const arrive = (selector, callback, options = {}) => {
  const observer = new MutationObserver((mutations, obs) => {
    const element = document.getElementById(selector)

    if (element) {
      callback()
      obs.disconnect()
    }
  })

  const defaultOptions = {
    childList: true,
    subtree: true,
  }

  const preparedOptions = isNotNilOrEmpty(options)
    ? mergeDeepRight(defaultOptions, options)
    : defaultOptions

  observer.observe(document, preparedOptions)
}

export default arrive
