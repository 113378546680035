// @flow

import React, { memo, useMemo, useCallback, useEffect } from 'react'
import { prop } from 'ramda'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'

import { Avatar } from '../Avatar'
import { AvatarContainer } from './SelectIconView.style'

type AvatarItemData = {
  images: Array<string>,
  avatarsKeys: Array<string>,
  onBecameFocusedAvatar: Function,
  onSelectAvatar: Function,
}

type AvatarItemProps = {
  index: number,
  style: any,
  data: AvatarItemData,
}

const AvatarItemPure = ({ index, style, data }: AvatarItemProps) => {
  const { ref, focusKey, hasFocusedChild } = useFocusable({ trackChildren: true })

  const { images, avatarsKeys, onBecameFocusedAvatar, onSelectAvatar } = useMemo(() => data, [data])

  const image = useMemo(() => prop(index, images), [images, index])
  const key = useMemo(() => prop(index, avatarsKeys), [avatarsKeys, index])
  const leftPosition = useMemo(() => prop('left', style), [style])
  const widthCard = useMemo(() => prop('width', style), [style])

  const handleSelectAvatar = useCallback(() => onSelectAvatar(key), [key, onSelectAvatar])

  useEffect(
    () => hasFocusedChild && onBecameFocusedAvatar(leftPosition, widthCard),
    [hasFocusedChild, leftPosition, onBecameFocusedAvatar, widthCard],
  )

  return (
    <FocusContext.Provider value={focusKey}>
      <AvatarContainer ref={ref} style={style}>
        <Avatar focusKey={key} image={image} withMouseOver={false} onClick={handleSelectAvatar} />
      </AvatarContainer>
    </FocusContext.Provider>
  )
}

export const AvatarItem = memo<AvatarItemProps>(AvatarItemPure)

export default AvatarItem
