// @flow
import { makeGetRequest } from '../../utils'

import type { TVShowSeasonDetail } from '../../types/tvshows'
import type { ResponseAPI } from '../../types/api'

export const getTvSeason = (
  domain: string,
  tvShowId: string,
  seasonId: string,
): Promise<ResponseAPI<TVShowSeasonDetail>> =>
  makeGetRequest(`${domain}/client/api/vod/tv-show/${tvShowId}/season/${seasonId}`)
