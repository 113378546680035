// @flow

import React from 'react'

import { Spinner, BounceOne, BounceTwo } from './elements'

type LoaderProps = {
  className?: string,
  color?: string,
  duration?: string,
  size?: string,
}

export const Loader = ({ className, color, duration, size }: LoaderProps) => {
  const params = {
    color,
    duration,
  }

  return (
    <Spinner className={className} size={size}>
      <BounceOne {...params} />
      <BounceTwo {...params} />
    </Spinner>
  )
}

Loader.defaultProps = {
  color: '#333',
  duration: '2.0s',
  size: '40px',
}

export default Loader
