// @flow

import React, { memo } from 'react'

import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'

import { Container, EditIcon } from './EditButton.style'

type EditButtonProps = {
  focusKey?: string,
  onClick: Function,
}

const EditButtonPure = ({ focusKey, onClick, ...props }: EditButtonProps) => {
  const { ref, focused, focusSelf } = useFocusable({ focusKey, onEnterPress: onClick })
  const { handleMouseOver } = useMouseOver(focusSelf)

  return (
    <Container
      ref={ref}
      focused={focused}
      onClick={onClick}
      onMouseOver={handleMouseOver}
      {...props}
    >
      <EditIcon />
    </Container>
  )
}

export const EditButton = memo<EditButtonProps>(EditButtonPure)

export default EditButton
