// @flow
import { schema } from 'normalizr'
import { map, prop, reject, isEmpty } from 'ramda'

import type { CategoriesNormalize } from '@alphaott/common-types/normalized'

import { defaultToEmptyString } from '../../help'

const idAttribute = '_id'

export const categoriesSchema = new schema.Entity('categories', {}, { idAttribute })
export const categoriesListSchema = new schema.Array(categoriesSchema)

export const prepareCategoriesById = (arrIds: Array<string>, arrValues: CategoriesNormalize): Array<string> =>
  reject(isEmpty, map(id => defaultToEmptyString(prop('title', arrValues[id])))(arrIds))

export default categoriesListSchema
