// @flow

import { map, path, values } from 'ramda'

import { ENTITY_TYPE } from '@alphaott/smart-tv-utils'

export const tvshowsToItems = (shows) =>
  shows
    ? map(
        ({ year, title, posters, _id: id, pgRating, tags }) => ({
          id,
          title,
          date: year,
          src: path([0, 'path'], posters),
          type: ENTITY_TYPE.TV_SHOW,
          pgRating,
          tags,
        }),
        values(shows),
      )
    : []
