// @flow

import { cond, equals, map, prop, T, always, pipe, sortBy } from 'ramda'

import { defaultToEmptyString } from '@alphaott/common-utils/utils/help'

import type { MediaStreamType } from '@alphaott/api-client/types/mediaStream'
import type { Source, Sources } from '@alphaott/api-client/types/source'
import type { DRM } from '@alphaott/api-client/types/drm'

import { prepareEmeKeySystems } from './drm/videojs_eme_utils/prepareEmeKeySystems'
import { MEDIA_STREAM_TYPE } from './const'

export type KeySystemObject = {
  certificateUri?: string,
  certificateHeaders?: string,
  url?: string,
  licenseUri?: string,
  licenseHeaders?: string,
  audioContentType?: string,
  videoContentType?: string,
  getCertificate?: Function,
  getContentId?: Function,
  getLicense?: Function,
}

export type VideojsSource = {
  src: string,
  type: string,
  priority: number,
  keySystems: ?{
    [string]: KeySystemObject | string,
  },
}

type BaseMediaStream = {
  type: MediaStreamType,
  sources: Sources,
  drm: ?DRM,
}

export const getSourceSrc = (source: Source = {}, propName: string = 'src'): string =>
  pipe(
    prop(propName),
    defaultToEmptyString,
  )(source)

// TODO: Add other types
export const getVideoType = (type: MediaStreamType) =>
  cond([
    [equals(MEDIA_STREAM_TYPE.MPEG_DASH), always('application/dash+xml')],
    [equals(MEDIA_STREAM_TYPE.HLS), always('application/x-mpegURL')],
    [equals(MEDIA_STREAM_TYPE.MP4), always('video/mp4')],
    [equals(MEDIA_STREAM_TYPE.MP3), always('audio/mpeg')],
    [equals(MEDIA_STREAM_TYPE.AAC), always('audio/aac')],
    [equals(MEDIA_STREAM_TYPE.OGG), always('audio/ogg')],
    [equals(MEDIA_STREAM_TYPE.FLAC), always('audio/flac')],
    [T, always('application/x-mpegURL')],
  ])(type)

export const preparePlayerSources = async (mediaStream: BaseMediaStream): Promise<Array<VideojsSource>> => {
  const { type, sources = [], drm } = mediaStream
  // Prepares media type
  const mediaType = getVideoType(type)
  // Prepares keySystem object according supported DRM & supported keySystem list
  const keySystems = await prepareEmeKeySystems(drm)

  const preparedSources: Array<VideojsSource> = map(source => ({
    priority: source.priority,
    type: mediaType,
    src: getSourceSrc(source),
    keySystems,
  }))(sources)

  return sortBy(prop('priority'))(preparedSources)
}

export default preparePlayerSources
