// @flow
import { cond, equals, always, map, reject, isNil, isEmpty } from 'ramda'
import { isNotEmpty } from '@alphaott/common-utils/utils/help'

import type { DRM } from '@alphaott/api-client/types/drm'

import { DRM_TYPES } from '../const'
import getSupportedDrm from '../getSupportedDRM'
import getSupportedKeySystems from '../getSupportedKeySystems'
import prepareWidevineKeySystems from './prepareWidevineKeySystems'
import preparePlayReadyKeySystems from './preparePlayReadyKeySystems'
import prepareFairPlayKeySystems from './prepareFairPlayKeySystems'

const compact = reject(isNil)

const getProperKeySystem = (drmType?: string, drm) =>
  cond([
    [equals(DRM_TYPES.WIDEVINE), always(prepareWidevineKeySystems(drm))],
    [equals(DRM_TYPES.PLAYREADY), always(preparePlayReadyKeySystems(drm))],
    [equals(DRM_TYPES.FAIRPLAY), always(prepareFairPlayKeySystems(drm))],
  ])(drmType)

const supportedKeySystemList = supportedDRMType => {
  // video-contrib-eme supports only 'com.apple.fps.1_0' keysystem for Safari
  // https://github.com/videojs/videojs-contrib-eme/blob/master/src/fairplay.js#L153
  const FAIRPLAY_KEY_SYSTEM = 'com.apple.fps.1_0'

  return supportedDRMType === DRM_TYPES.FAIRPLAY ? [FAIRPLAY_KEY_SYSTEM] : getSupportedKeySystems(supportedDRMType)
}

export const prepareEmeKeySystems = async (drm: DRM = {}, supportedDRMList: Array<any> = getSupportedDrm()) => {
  const keySystems = {}

  if (isEmpty(drm)) return null

  await Promise.all(
    // Detects supported keySystem for each supported DRM type
    map(async supportedDRMType => {
      const drmObject = drm[supportedDRMType]
      if (drmObject) {
        const supportedKeySystems = await supportedKeySystemList(supportedDRMType)

        // Builds result keySystems object with available supportedKeySystems
        map(key => {
          keySystems[key] = getProperKeySystem(supportedDRMType, drm)
        }, compact(supportedKeySystems))
      }
    }, supportedDRMList),
  )

  return isNotEmpty(keySystems) ? keySystems : null
}
