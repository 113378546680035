// @flow
import styled from 'styled-components'
import { ListComponent } from '@alphaott/smart-tv-components'

import { ChevronUpIconComponent, ChevronDownIconComponent } from '../assets'
import { CHANNEL_ITEM_WIDTH, CHANNEL_ITEM_HEIGHT } from '../ChannelItem'

export const CHEVRON_ICON_WIDTH: number = 32
export const CHEVRON_ICON_HEIGHT: number = 41
export const CHANNELS_LIST_HEIGHT: number = 726

export const StyledList = styled(ListComponent).attrs({
  itemSize: CHANNEL_ITEM_HEIGHT,
  listWidth: CHANNEL_ITEM_WIDTH,
  layout: 'vertical',
  style: { overflow: 'hidden' },
})``

export const Container = styled.div`
  width: ${CHANNEL_ITEM_WIDTH}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`

export const ListContainer = styled.div`
  width: ${CHANNEL_ITEM_WIDTH}rem;
  height: ${CHANNELS_LIST_HEIGHT}rem;
  display: flex;
`

export const ChevronUpIcon = styled(ChevronUpIconComponent)`
  width: ${CHEVRON_ICON_WIDTH}rem;
  height: ${CHEVRON_ICON_HEIGHT}rem;
  margin-bottom: 19rem;
`

export const ChevronDownIcon = styled(ChevronDownIconComponent)`
  width: ${CHEVRON_ICON_WIDTH}rem;
  height: ${CHEVRON_ICON_HEIGHT}rem;
  margin-top: 16rem;
`

export const ChannelsListDivider = styled.div``
