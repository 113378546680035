// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { Image, Text } from '@alphaott/smart-tv-components'
import { Button } from '@alphaott/smart-tv-video-player'
import { getPlaybackBackground } from '@alphaott/smart-tv-themes'

import { ReactComponent as PlayCircleIconComponent } from '../../assets/playCircleIcon.svg'

import DetailInfoComponent from './DetailInfo'

export const Container = styled(Flex)`
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const CentralContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const PlayButton = styled(Button)`
  padding: 10rem;
  border-radius: 50%;
`

export const PlayCircleIcon = styled(PlayCircleIconComponent)`
  width: 100rem;
  height: 100rem;
`

export const InfoContainer = styled(Flex)`
  position: absolute;
  padding: 0 5% 80rem 5%;
  left: 0;
  bottom: 0;
  width: 100%;
  background: ${getPlaybackBackground};
`

export const LeftContainer = styled.div`
  max-width: 288rem;
  width: 100%;
`

export const ChannelImage = styled(Image)`
  width: 100%;
  height: 192rem;
  border-radius: 6rem;
  overflow: hidden;
`

export const ChannelTitle = styled(Text)`
  font-weight: bold;
  font-size: 40rem;
  line-height: 48rem;
  color: ${(props) => props.theme.palette.common.white};
  margin-top: 8rem;
`

export const RightContainer = styled.div`
  width: 100%;
  margin-left: 40rem;
`

export const DetailInfo = styled(DetailInfoComponent)``

export const PosterContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`

export const Poster = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
