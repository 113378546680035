// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

export const Container = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-height: 100%;
  background-color: #0f0f0f;
`

export const ErrorContent = styled(Flex)`
  flex-direction: column;
  max-width: 70%;
`

export const Title = styled.span`
  color: #fff;
  font-size: 30px;
  text-align: center;
`

const Text = styled.span`
  font-size: 19px;
  color: rgba(255, 255, 255, 0.87);
`

export const Label = styled(Text)`
  font-weight: bold;
`

export const Message = styled(Text)``

export const Stack = styled(Text)``
