// @flow
import typeToReducer from 'type-to-reducer'

import { isValidTTL, calculateExpires } from '@alphaott/app-main/selectors'

import { INIT_APP_TYPE } from '../../actions/appInit'
import { CHANGE_PROFILE_LOAD_LISTS_INTERVAL_ID } from '../../actions/appInit/loadPersonalContentInterval'

export type AppState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: {
    profileLoadListsIntervalId: ?number,
  },
}

const initialState: AppState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: null,
  data: {
    profileLoadListsIntervalId: null,
  },
}

const app = typeToReducer(
  {
    [INIT_APP_TYPE]: {
      LOADING: (state): AppState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): AppState => {
        const {
          meta: { ttl },
        } = action

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isError: false,
          errors: null,
          ...(isValidTTL(ttl) && { expires: calculateExpires(ttl) }),
        }
      },

      ERROR: (state, action): AppState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload,
      }),
    },
    [CHANGE_PROFILE_LOAD_LISTS_INTERVAL_ID]: (state, action) => ({
      ...state,
      data: {
        profileLoadListsIntervalId: action.payload.intervalId,
      },
    }),
  },
  initialState,
)

export { app }
