// @flow
import { createSelector } from 'reselect'
import { pick } from 'ramda'

import type { Devices, Device } from '@alphaott/api-client/types/devices'
import type { DeviceStore } from './types'

export const getCurrentDeviceState = (state: DeviceStore): Device => state.currentDevice
export const getCurrentDeviceId = (state: DeviceStore): string => state.currentDevice._id
export const getDeviceType = (state: DeviceStore): string => state.currentDevice.type
export const getDevicePlatform = (state: DeviceStore): string => state.currentDevice.platform
export const getDevices = (state: DeviceStore): Devices => state.devices.data
export const getDeviceErrors = (state: DeviceStore): any => state.devices.errors

export const getCurrentDevice = createSelector(
  [getCurrentDeviceState],
  (device: Device): Device => {
    const fields = ['_id', 'type', 'model', 'platform', 'brand', 'serial', 'mac']
    return pick(fields, device)
  },
)
