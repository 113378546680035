// @flow
import { LARGE_VIEWPORT, MEDIUM_VIEWPORT, SMALL_VIEWPORT } from '@alphaott/app-config'
import { rgba } from 'polished'

import { colorPalette } from '../colorPalettes'
import type { ColorType } from '../colorPalettes'

export const kapangusColors: ColorType = {
  ...colorPalette,
  primary: '#FFFFFF',
  secondary: '#FFFFFF',
  grayText: '#B0B0B0',
  grayLabel: '#717171',
  grayBorder: 'rgba(255, 255, 255, 0.38)',
}

export const darkTheme = {
  colors: kapangusColors,
  background: {
    primary: '#181818',
    secondary: '#262626',
    highlight: kapangusColors.dark,
    select: '#2e2e2e',
    footerTop: '#333',
  },
  text: {
    primary: kapangusColors.primaryGray,
    secondary: kapangusColors.secondaryGray,
    disabled: kapangusColors.grayText,
    link: kapangusColors.secondaryGray,
    hint: kapangusColors.white,
    highlight: kapangusColors.secondary,
    gray: kapangusColors.primaryGray,
    seeAll: kapangusColors.secondaryGray,
    primaryContrast: '#000000',
  },
  border: {
    primary: kapangusColors.primaryGray,
    gray: kapangusColors.secondaryGray,
  },
  filter: {
    primary: kapangusColors.primaryGray,
    secondary: kapangusColors.secondaryGray,
    disabled: kapangusColors.secondaryGray,
    highlight: '#111111',
  },
  tabs: {
    bg: '#181818',
    containerBg: kapangusColors.dark,
    color: kapangusColors.white,
    border: kapangusColors.primary,
    scrollbarThumb: '#bdbdbd',
    programTitle: kapangusColors.white,
  },
  programGuide: {
    programBox: {
      background: {
        main: '#2e2e2e',
        hover: kapangusColors.dark,
        selected: kapangusColors.dark,
        progress: rgba(kapangusColors.primary, 0.3),
        past: rgba(kapangusColors.primary, 0.3),
        pastSelected: kapangusColors.dark,
        pastHover: kapangusColors.dark,
        empty: '#555555',
        emptyProgress: rgba(kapangusColors.primary, 0.1),
      },
      text: {
        empty: kapangusColors.secondaryGray,
      },
    },
    programGuidePictureOverlay: {
      horizontal:
        // eslint-disable-next-line max-len
        `linear-gradient(90deg, #181818 0%,${rgba('#181818', 0.97)} 13.03%,${rgba('#181818', 0.69)} 43.24%,${rgba(
          '#181818',
          0,
        )} 100%);`,
      vertical: `linear-gradient(0, #181818 0%,${rgba('#181818', 0.73)} 34.97%,${rgba('#181818', 0)} 100%);`,
    },
  },
  card: {
    title: kapangusColors.white,
    subTitle: kapangusColors.secondaryGray,
    bg: kapangusColors.grayText,
    number: '#DFDFDF',
    boxShadow: '#2e2e2e',
    loaderBackgroundImage: 'linear-gradient(to right, #222 0%, #111 20%, #222 40%, #222 100%)',
  },
  dimension: {},
  size: 'default',
  breakpoints: [`${SMALL_VIEWPORT}px`, `${MEDIUM_VIEWPORT}px`, `${LARGE_VIEWPORT}px`],
  signIn: {
    clipPath: false,
  },
}
