// @flow

import React, { memo, useEffect } from 'react'

import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'
import { useHorizontalArrows } from '@alphaott/smart-tv-common/src/hooks/useHorizontalArrows'

import { StyledInput } from './Input.styles'

type InputProps = {
  className?: string,
  focusKey?: string,
  placeholder?: string,
  value?: string,
  onChange: Function,
  onKeyDown: Function,
}

const InputPure = ({
  className,
  focusKey,
  placeholder,
  value = '',
  onChange,
  onKeyDown,
}: InputProps) => {
  const { ref, focused, focusSelf, setFocus } = useFocusable({ focusKey })
  const { handleMouseOver } = useMouseOver(focusSelf)
  const handleOnKeyDown = useHorizontalArrows({ ref, setFocus, onKeyDown })

  useEffect(() => {
    if (ref) {
      if (focused) {
        ref.current.focus()
      } else {
        ref.current.blur()
      }
    }
  }, [focused]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledInput
      ref={ref}
      className={className}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={handleOnKeyDown}
      onMouseOver={handleMouseOver}
    />
  )
}

export const Input = memo<InputProps>(InputPure)

export default Input
