// @flow

export const FIRST_PAGE = 1

export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm:ss'
export const VISIBLE_DATE_FORMAT = 'MM/DD/YYYY'
export const VISIBLE_DAY_OF_WEEK = 'ddd'
export const VISIBLE_TIME_FORMAT = 'HH:mm'
export const DAY_NUMBER = 'DDD'

export const FILTER_NAMES = {
  ALL: 'all',
  FAVORITE: 'favorite',
  MOST: 'most',
}

export const PAGE_PARAM_NAME = 'page'
export const FILTER_PARAM_NAME = 'filter'
export const DATE_PARAM_NAME = 'date'
export const TIME_PARAM_NAME = 'time'
export const CHANNEL_PARAM_NAME = 'channel'

export const PROGRAM_COLLAPSE_WIDTH = 30
export const PROGRAM_MIN_CONTENT_WIDTH = 70

export const ID = '_id'

export const SCROLL_OFFSET = 300
