// @flow
import { getChannelSources } from '@alphaott/api-client'
import { logoutRequestWrapper } from '@alphaott/app-main/utils'
import { prepareAcceptMediaStreamHeaders, prepareAcceptMediaStreamEncryptedHeaders } from '@alphaott/drm-web'

export const GET_CHANNELS_SOURCES_MAP = 'channels/GET_SOURCES_MAP'

const acceptMediaStream = prepareAcceptMediaStreamHeaders()
const acceptMediaStreamEncrypted = prepareAcceptMediaStreamEncryptedHeaders()

export const getChannelSourcesMap = (id: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: GET_CHANNELS_SOURCES_MAP,
    payload: logoutRequestWrapper(getChannelSources)({ id, acceptMediaStream, acceptMediaStreamEncrypted })(
      dispatch,
      getState,
    ),
  })
