// @flow

import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'

import { ReactComponent as OutlineStarIcon } from './icons/star-outline.svg'
import { ReactComponent as StarIcon } from './icons/star.svg'
import { ReactComponent as StarHalfIcon } from './icons/star-half.svg'

export const Container = styled(Flex)``

const StarStyle = css`
  width: 20rem;
  height: 20rem;
`

export const OutlineStar = styled(OutlineStarIcon)`
  ${StarStyle};
`

export const FillStar = styled(StarIcon)`
  ${StarStyle};
`

export const HalfStar = styled(StarHalfIcon)`
  ${StarStyle};
`
