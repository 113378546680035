// @flow weak
import { schema } from 'normalizr'
import { isEmpty, find, prop, pipe, not, propEq, isNil, join, propOr } from 'ramda'

import type { Movie } from '@alphaott/api-client/types/movies'
import type {
  CategoriesNormalize,
  CountriesNormalize,
  GenresNormalize,
  LanguageNormalize,
} from '@alphaott/common-types/normalized'

import { prepareGenresById } from '../genres'
import { prepareCategoriesById } from '../categories'
import { prepareLanguagesById } from '../languages'
import { prepareCountriesById } from '../countries'
import { prepareLogos } from '../../prepareLogos'
import { prepareTrailers } from '../../prepareTrailers'
import { prepareRating } from '../../prepareRating'

export type PreparedMovie = Movie & {
  isFavorite?: boolean,
  subtitle?: string,
  maxRating?: string,
  current?: number,
  total?: number,
  completed?: boolean,
}

export type Progress = {
  id: string,
  current: number,
  total: number,
  completed: boolean,
}

const idAttribute = '_id'

export const movieSchema = new schema.Entity('movies', {}, { idAttribute })
export const movieListSchema = new schema.Array(movieSchema)

const prepareSubtitle = (genres: Array<string>, categories: Array<string>): string =>
  join(', ', [...genres, ...categories])

export const prepareMovie = (
  movie: Movie,
  normalGenres: GenresNormalize,
  normalCategories: CategoriesNormalize,
  normalLanguages: LanguageNormalize,
  normalCountries: CountriesNormalize,
  favorite: Array<string>,
  maxRating: number,
  progress: Progress = {},
): PreparedMovie => {
  if (isEmpty(movie)) return {}

  const movieId = prop(idAttribute, movie)
  const genres = prepareGenresById(movie.genres, normalGenres)
  const categories = prepareCategoriesById(movie.categories, normalCategories)
  const languages = prepareLanguagesById(movie.languages, normalLanguages)
  const countries = prepareCountriesById(movie.countries, normalCountries)
  const subtitle = prepareSubtitle(genres, categories)
  const rating = prepareRating(movie.rating, maxRating)
  const current = propOr(0, 'current', progress)
  const total = propOr(0, 'total', progress)
  const completed = propOr(false, 'completed', progress)

  return {
    ...movie,
    genres,
    categories,
    languages,
    countries,
    subtitle,
    rating,
    current,
    total,
    completed,
    isFavorite: pipe(
      find(propEq('item', movieId)),
      isNil,
      not,
    )(favorite),
  }
}

export const prepareMovieImages = (movie: Movie, domain: string): Movie => {
  const posters = prepareLogos(movie.posters, domain)
  const backgrounds = prepareLogos(movie.backgrounds, domain)
  const screenshots = prepareLogos(movie.screenshots, domain)
  const trailers = prepareTrailers(movie.trailers, domain)
  return {
    ...movie,
    posters,
    backgrounds,
    screenshots,
    trailers,
  }
}

export default movieListSchema
