// @flow

import { useMemo } from 'react'
import { propOr, pathOr, and, or, head, split } from 'ramda'

export const useCustomerName = (customer) => {
  const firstName = useMemo(() => propOr('', 'firstName', customer), [customer])
  const lastName = useMemo(() => propOr('', 'lastName', customer), [customer])
  const email = useMemo(() => propOr('', 'email', customer), [customer])
  const nameFromEmail = useMemo(() => head(split('@', email)), [email])
  const phone = useMemo(() => pathOr('', ['phones', 0], customer), [customer])

  const fullName = useMemo(
    () => (and(firstName, lastName) ? `${firstName} ${lastName}` : firstName),
    [firstName, lastName],
  )
  const name = useMemo(
    () => or(or(fullName, nameFromEmail), phone),
    [fullName, nameFromEmail, phone],
  )

  const initials = useMemo(
    () => `${head(firstName)}${head(lastName)}`.toUpperCase(),
    [firstName, lastName],
  )

  return { name, initials }
}
