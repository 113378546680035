// @flow

import typeToReducer from 'type-to-reducer'
import { parseTVShowEpisode } from '@alphaott/common-utils/utils/schemas/tvshows'
import type { TVShowEpisodeDetail } from '@alphaott/api-client/types/tvshows'
import type { TrailerMediaStream } from '@alphaott/api-client/types/movies'

import type { NormalizedTrailers } from '../tvshow'

import { FETCH_TVSHOW_EPISODE, RESET_TVSHOW_EPISODE_DETAIL, FETCH_TVSHOW_EPISODE_TRAILER_SOURCE } from '../../actions'

type TVShowEpisodeTrailerBaseStateType = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: TrailerMediaStream | {},
}

const tvShowEpisodeTrailerBaseState: TVShowEpisodeTrailerBaseStateType = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: null,
  data: {},
}

export type TVShowEpisodeDetailState = {|
  isLoading: boolean,
  isSuccess: boolean,
  isError: boolean,
  errors: any,
  created: ?Date,
  result: TVShowEpisodeDetail | {},
  trailers: NormalizedTrailers,
|}

const initialState: TVShowEpisodeDetailState = {
  created: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  result: {},
  trailers: {},
}

const tvshowEpisodeDetail = typeToReducer(
  {
    [FETCH_TVSHOW_EPISODE]: {
      LOADING: (state): TVShowEpisodeDetailState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): TVShowEpisodeDetailState => {
        const { payload, meta } = action

        return {
          ...state,
          created: new Date().getTime(),
          isLoading: false,
          isSuccess: true,
          result: parseTVShowEpisode(payload.data, meta.contentApi),
        }
      },

      ERROR: (state, action): TVShowEpisodeDetailState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
    [FETCH_TVSHOW_EPISODE_TRAILER_SOURCE]: {
      LOADING: (state, { meta }): TVShowEpisodeDetailState => ({
        ...state,
        trailers: {
          ...state.trailers,
          [meta.trailerId]: {
            ...tvShowEpisodeTrailerBaseState,
            isLoading: true,
          },
        },
      }),

      SUCCESS: (state, { payload, meta }): TVShowEpisodeDetailState => ({
        ...state,
        trailers: {
          ...state.trailers,
          [meta.trailerId]: {
            ...tvShowEpisodeTrailerBaseState,
            isLoading: false,
            isSuccess: true,
            data: payload.data,
          },
        },
      }),

      ERROR: (state, { payload, meta }): TVShowEpisodeDetailState => ({
        ...state,
        trailers: {
          ...state.trailers,
          [meta.trailerId]: {
            isLoading: false,
            isSuccess: false,
            isError: true,
            data: {},
            errors: payload.error,
          },
        },
      }),
    },
    [RESET_TVSHOW_EPISODE_DETAIL]: () => ({ ...initialState }),
  },
  initialState,
)

export { tvshowEpisodeDetail }
