// @flow
import { path } from 'ramda'

import { getTvShowCountries } from '@alphaott/api-client'
import { getPublicDomain, getCacheChannels, isCacheValid } from '@alphaott/app-main/selectors'

export const FETCH_TVSHOW_COUNTRIES = 'tvshows/FETCH_COUNTRIES'

const fetchTVShowCountriesAction = (serviceSpec: string) => (dispatch: Function, getState: Function) => {
  const state = getState()
  const publicApi = getPublicDomain(state)
  const ttl = getCacheChannels(state)

  return dispatch({
    type: FETCH_TVSHOW_COUNTRIES,
    payload: getTvShowCountries(publicApi, serviceSpec),
    meta: { ttl },
  })
}

// eslint-disable-next-line complexity
export const fetchTVShowCountries = (serviceSpec: string, force: ?boolean = false) => (
  dispatch: Function,
  getState: Function,
) => {
  if (force) return dispatch(fetchTVShowCountriesAction(serviceSpec))

  const state = getState()
  const expires = path(['tvshowCountries', 'expires'], state)

  if (expires && isCacheValid(expires)) return null

  return dispatch(fetchTVShowCountriesAction(serviceSpec))
}
