// @flow
import { find, propEq, propOr, reject, and, or } from 'ramda'

import { getPersonalList, putPersonalList } from '@alphaott/api-client'
import { getCustomerId, getServiceLiveTvIds } from '@alphaott/app-core/models/selectors'
import { logoutRequestWrapper } from '@alphaott/app-main/utils'
import { defaultToEmptyObject, isNotEmpty } from '@alphaott/common-utils'

import { UPDATE_CHANNEL_PROGRESS_INTERVAL } from '@alphaott/app-config'

import { getRecent } from '../../selectors'
import { fetchChannels } from '../channels'

export const UPDATE_RECENT_CHANNEL = 'channels/UPDATE_RECENT'
export const GET_RECENT_CHANNELS = 'channels/GET_RECENT'

// eslint-disable-next-line consistent-return
export const fetchChannelById = (dispatch: Function, getState: Function) => {
  const state = getState()
  const serviceIdLiveTv = getServiceLiveTvIds(state)
  const recentList = getRecent(state)
  const uniqRecentList = reject(({ id }) => find(propEq('_id', id), recentList), state.channels.entities)

  // Todo: Change this when will add endpoint for getting channel by id
  if (and(isNotEmpty(serviceIdLiveTv), isNotEmpty(uniqRecentList))) {
    return dispatch(fetchChannels(serviceIdLiveTv, true))
  }
}

export const updateRecentWatchedChannel = (id: string, watchTime: number) => async (
  dispatch: Function,
  getState: Function,
) => {
  const profileId = getCustomerId(getState())
  const channels = getRecent(getState())

  const currentChannel = defaultToEmptyObject(find(propEq('id', id))(channels))
  const total = propOr(0, 'total', currentChannel)
  const preparedTotalWatchTime = total + or(watchTime, UPDATE_CHANNEL_PROGRESS_INTERVAL)

  const data = {
    total: preparedTotalWatchTime,
  }

  // eslint-disable-next-line no-useless-catch
  try {
    await dispatch({
      type: UPDATE_RECENT_CHANNEL,
      payload: logoutRequestWrapper(putPersonalList)(profileId, 'tv', 'history', id, data)(dispatch, getState),
    })
    dispatch(fetchChannelById)
  } catch (err) {
    throw err
  }
}

export const getRecentWatchedChannel = async (dispatch: Function, getState: Function) => {
  const profileId = getCustomerId(getState())

  if (profileId) {
    await dispatch({
      type: GET_RECENT_CHANNELS,
      payload: logoutRequestWrapper(getPersonalList)(profileId, 'tv', 'history')(dispatch, getState),
    })

    dispatch(fetchChannelById)
  }
}
