// @flow

import React, { memo } from 'react'

import type { ComponentType } from 'react'

import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'

import { Container, Label, Button } from './Tab.styles'

export type TabProps = {
  className?: string,
  focusKey?: string,
  label: string,
  icon: any,
  isActive: Boolean,
  onClick: Function,
}

const TabPure = ({ className, focusKey, label, icon: Icon, isActive, onClick }: TabProps) => {
  const { ref, focused, focusSelf } = useFocusable({ focusKey, onEnterPress: onClick })
  const { handleMouseOver } = useMouseOver(focusSelf)

  return (
    <Container className={className} ref={ref}>
      {focused && <Label>{label}</Label>}

      <Button focused={focused} isActive={isActive} onClick={onClick} onMouseOver={handleMouseOver}>
        <Icon />
      </Button>
    </Container>
  )
}

export const Tab: ComponentType<TabProps> = memo<TabProps>(TabPure)

export default Tab
