// @flow
import { SubmissionError } from 'redux-form'
import { push } from 'redux-first-history'
import { pathOr } from 'ramda'

import { sendOtp } from '@alphaott/api-client'
import { getPrivateDomain } from '@alphaott/app-main/models/selectors'
import { getCurrentDevice } from '@alphaott/app-devices/models/selectors'
import { is20x, isApiError } from '@alphaott/api-client/utils/makeResponse'
import { CLIENT_ID, CLIENT_SECRET } from '@alphaott/app-config'

import type { Device } from '@alphaott/api-client/types/devices'

import { resetLoginData } from '../login'

export const AUTH_OTP_TYPE = 'AUTH_OTP_TYPE'

// eslint-disable-next-line complexity
export const authOtpCallback = (request: Function) => async (
  data: any,
  redirect: string,
  device: Device,
  dispatch: Function,
  privateApi: string,
) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const opts = {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      device,
      ...data,
    }

    // TODO: https://github.com/facebook/flow/issues/3461
    // const resp: ResponseAPI<Token>
    const resp: Object = await request(privateApi, opts)

    if (is20x(resp)) {
      if (redirect) dispatch(push(redirect))
    }

    return resp
  } catch (err) {
    if (isApiError(err)) {
      const message = pathOr('Server error', ['error', 'message'], err)
      throw new SubmissionError({ ...err.error, _error: message })
    }

    throw err
  }
}

export const authOTP = (data: any, redirect?: string = '/auth/login/otp/activate') => (
  dispatch: Function,
  getState: Function,
) => {
  dispatch(resetLoginData)
  return dispatch({
    type: AUTH_OTP_TYPE,
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    payload: authOtpCallback(sendOtp)(
      data,
      redirect,
      getCurrentDevice(getState()),
      dispatch,
      getPrivateDomain(getState()),
    ),
    meta: { data, redirect },
  })
}
