// @flow

import typeToReducer from 'type-to-reducer'

import type { Limits } from '@alphaott/api-client/types/limits'

import { GET_CHANNEL_LIMITS, RESET_CHANNEL_LIMITS } from '../../actions/channelLimits'

export type ChannelLimitState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Limits | {},
}

const initialState: ChannelLimitState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: {},
}

const channelLimits = typeToReducer(
  {
    [GET_CHANNEL_LIMITS]: {
      LOADING: (state): ChannelLimitState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): ChannelLimitState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: action.payload.data,
      }),

      ERROR: (state, action): ChannelLimitState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
    [RESET_CHANNEL_LIMITS]: () => initialState,
  },
  initialState,
)

export { channelLimits }
