// @flow
import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { or } from 'ramda'

import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'
import { CheckBox } from '@alphaott/smart-tv-components'

import {
  AvatarImage,
  ButtonWrapper,
  Content,
  EditAvatar,
  EditButton,
  EditHeader,
  EditSmallButton,
  EditSmallText,
  EditTitle,
  AvatarNameLargeText,
  LeftColumn,
  ProfileContainer,
  RightColumn,
  Row,
  RowBottom,
} from './EditProfileView.styles'

import {
  DELETE_PROFILE_BUTTON_FOCUS_KEY,
  DONE_BUTTON_FOCUS_KEY,
  ICON_BUTTON_FOCUS_KEY,
  NAME_BUTTON_FOCUS_KEY,
  PARENTAL_RESTRICTIONS_BUTTON_FOCUS_KEY,
} from './const'

type EditProfileViewProps = {
  profile: Object,
  onEditName: Function,
  onEditIcon: Function,
  onEditPG: Function,
  onChangeProfile: Function,
  onDeleteProfile: Function,
  onChangeRequiresPin: Function,
  accessRating: string,
}

// eslint-disable-next-line complexity
export const EditProfileViewPure = ({
  profile,
  onEditName,
  onEditIcon,
  onEditPG,
  onChangeProfile,
  onDeleteProfile,
  onChangeRequiresPin,
  accessRating,
}: EditProfileViewProps) => {
  const { ref, setFocus } = useFocusable()
  const { t } = useTranslation()

  useEffect(() => {
    if (setFocus) setFocus(NAME_BUTTON_FOCUS_KEY)
  }, [setFocus])

  return (
    <ProfileContainer ref={ref}>
      <EditHeader>
        <EditTitle>{t('mwstv_edit_profile')}</EditTitle>
        <EditSmallText>{t('mwstv_select_what_you_want_to_change')}</EditSmallText>
      </EditHeader>
      <Content>
        <Row>
          <LeftColumn>
            <EditAvatar>
              <AvatarImage src={profile.avatar} alt={profile.name} />
            </EditAvatar>
            <AvatarNameLargeText>{profile.name}</AvatarNameLargeText>
          </LeftColumn>
          <RightColumn>
            <ButtonWrapper>
              <EditButton focusKey={NAME_BUTTON_FOCUS_KEY} onClick={onEditName}>
                {or(profile.name, t('mwstv_profile_name'))}
              </EditButton>
            </ButtonWrapper>
            <ButtonWrapper>
              <EditButton focusKey={ICON_BUTTON_FOCUS_KEY} onClick={onEditIcon}>
                {t('mwstv_profile_icon')}
              </EditButton>
            </ButtonWrapper>
            <ButtonWrapper>
              <EditButton focusKey={PARENTAL_RESTRICTIONS_BUTTON_FOCUS_KEY} onClick={onEditPG}>
                {t('mwstv_parental_restrictions')}
              </EditButton>
            </ButtonWrapper>
            <EditSmallText>
              {t('mwstv_parental_control_rating_is_set_to')}
              {` ${accessRating}`}
            </EditSmallText>
            <ButtonWrapper>
              <CheckBox label={t('mwstv_requires_pin')} onChange={onChangeRequiresPin} />
            </ButtonWrapper>
          </RightColumn>
        </Row>
        <RowBottom>
          <ButtonWrapper>
            <EditSmallButton focusKey={DONE_BUTTON_FOCUS_KEY} onClick={onChangeProfile}>
              {t('mwstv_button_done')}
            </EditSmallButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <EditSmallButton focusKey={DELETE_PROFILE_BUTTON_FOCUS_KEY} onClick={onDeleteProfile}>
              {t('mwstv_button_delete_profile')}
            </EditSmallButton>
          </ButtonWrapper>
        </RowBottom>
      </Content>
    </ProfileContainer>
  )
}

export const EditProfileView = memo(EditProfileViewPure)

export default EditProfileView
