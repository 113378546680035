// @flow weak
import { pipe, find, prop, propEq } from 'ramda'
import { createSelector } from 'reselect'
import type { Program } from '@alphaott/api-client/types/programs'

import { defaultToEmptyArray } from '@alphaott/common-utils/utils/help'

import type { ProgramConfig, ProgramState } from './reducers'
import type { ProgramsStore } from '../types'

export type PreparedProgram = Program & {
  isNewDay?: boolean,
}

export type CurrentEpgIndexes = {
  current?: number,
  next?: number,
}

export type CurrentEpg = {
  current?: PreparedProgram,
  next?: PreparedProgram,
}

export type PreparedPrograms = Array<PreparedProgram>

export const getProgramState = (state: ProgramsStore): ProgramState => state.channelPrograms

export const getConfig = createSelector(
  [getProgramState],
  (programState: ProgramState): ProgramConfig => programState.config,
)

export const isOpenedSegment = createSelector(
  [getConfig],
  (config: ProgramConfig): boolean => config.isOpenSegment,
)

export const getProgramById = (channelId: string, programId: string) =>
  createSelector(
    [getProgramState],
    (programState: ProgramState): ?Program =>
      pipe(
        prop(channelId),
        defaultToEmptyArray,
        find(propEq('_id', programId)),
      )(programState.data),
  )
