// @flow

export const STREAM_TYPE = 'MPEG_DASH'

export const DRM_TYPES = {
  WIDEVINE: 'WIDEVINE',
  PLAYREADY: 'PLAYREADY',
}

// Web OS http://webostv.developer.lge.com/discover/specifications/supported-media-formats/
// Tizen https://developer.samsung.com/smarttv/develop/specifications/media-specifications.html

// eslint-disable-next-line max-len
export const acceptMediaStreamEncrypted = `${STREAM_TYPE}/${DRM_TYPES.WIDEVINE},${STREAM_TYPE}/${DRM_TYPES.PLAYREADY}`

export default acceptMediaStreamEncrypted
