// @flow

import React from 'react'

import { Container } from './EmptyPlaceholder.styles'

type EmptyPlaceholderProps = {
  children: string,
}

export const EmptyPlaceholder = ({ children }: EmptyPlaceholderProps) => (
  <Container>{children}</Container>
)
