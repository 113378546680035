// @flow
export const MOST_PLAYED_RADIO_CHANNEL = 'radioChannels/MOST_PLAYED'

export type RadioChannelMostPlayedAction = {|
  type: 'radioChannels/MOST_PLAYED',
  id: string,
  duration: number,
|}

export const mostPlayedRadioChannel = (id: string, duration: number): RadioChannelMostPlayedAction => ({
  type: MOST_PLAYED_RADIO_CHANNEL,
  id,
  duration,
})
