// @flow
import { useMemo, useCallback } from 'react'
import { throttle } from 'throttle-debounce'

const MOUSE_OVER_TIME = 50

export const useMouseOver = (setFocus: () => void) => {
  const establishFocus = useMemo(() => throttle(MOUSE_OVER_TIME, () => setFocus()), [setFocus])

  const handleMouseOver = useCallback(establishFocus, [establishFocus])

  return {
    handleMouseOver,
  }
}
