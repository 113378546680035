// @flow

import React, { memo } from 'react'

import { CatchupContainer, Icon } from './ChannelCard.style'

import catchup from '../assets/catchup.svg'

export const CatchupPure = () => (
  <CatchupContainer>
    <Icon src={catchup} alt="catchup" />
  </CatchupContainer>
)

export const Catchup = memo(CatchupPure)
