// @flow
import type { DRM } from '../../types'

export const hlsDrmConfig = (licenseUri: string, cert: any): DRM => ({
  servers: {
    'com.apple.fps.1_0': licenseUri,
  },
  advanced: {
    'com.apple.fps.1_0': {
      // $FlowFixMe
      serverCertificate: new Uint8Array(cert),
    },
  },
})
