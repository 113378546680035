// @flow
import { createSelector } from 'reselect'
import { path, pathOr, prop, isEmpty, isNil, propOr, hasPath, map, test, is, mergeRight, mergeDeepRight } from 'ramda'
import Url from 'url-parse'

import type {
  Config,
  ContentCatalogUIConfig,
  GuestAccess,
  GuestAccessTrial,
  LeadDyno,
  PostAffiliatePro,
  Revenuecat,
} from '@alphaott/api-client/types/config'

import { defaultToEmptyString } from '@alphaott/common-utils/utils/help'

import type { TokenState } from '@alphaott/app-auth/reducers'
import type { AuthStore } from '@alphaott/app-auth/types'
import type { BrandConfig, FirebaseOptions, Address } from '@alphaott/common-types'
import {
  DEFAULT_UI_CONFIG,
  DEFAULT_GUEST_ACCESS_CONFIG,
  CHANNEL_PLAYBACK_TABS,
  RADIO_CHANNEL_PLAYBACK_TABS,
  MOVIE_PLAYBACK_TABS,
  TV_SHOW_PLAYBACK_TABS,
  VIDEO_PLAYERS,
  DEFAULT_PAYMENT_HISTORY_ENABLED,
  DEFAULT_EXPIRED_SUBSCRIPTIONS_ENABLED,
  DEFAULT_PICTURE_IN_PICTURE_ENABLED,
  DEFAULT_BANNER_AUTOPLAY_INTERVAL,
} from '@alphaott/app-config'
import { defaultToEmptyObject, defaultToEmptyArray } from '@alphaott/app-server-driven-ui/utils'
import { getContentCatalogWithoutPersonalCatalogs } from '@alphaott/guest-access/utils'

import { getConfig, getUIPlayer } from './config'
import { isEnabled } from '../utils/config'

import type { MainStore } from '../types'

type Store = MainStore & AuthStore

export const getToken = (state: Store): TokenState => state.token
export const getIsLoadingInit = (state: Store): boolean => state.init.isLoading

export const getBrand = (state: Store): BrandConfig => path(['brandConfig', 'data'], state)

export const getTokenJWT = createSelector(
  [getToken],
  (token: TokenState): string | void => token.access,
)

export const getBrandId = createSelector(
  [getBrand],
  (brand: BrandConfig): string => prop('id', brand),
)

export const getSupportEmail = createSelector(
  [getBrand],
  (brand: BrandConfig): string => pathOr('', ['company', 'supportEmail'], brand),
)

export const getIsContactUsEnabled = createSelector(
  [getBrand],
  (brand: BrandConfig): string => pathOr(true, ['company', 'contactUsEnabled'], brand),
)

export const getSupportWww = createSelector(
  [getBrand],
  (brand: BrandConfig): string => pathOr('', ['company', 'www'], brand),
)

export const getIsCompanyUrlEnabled = createSelector(
  [getBrand],
  (brand: BrandConfig): Boolean => pathOr(true, ['company', 'companyUrlEnabled'], brand),
)

export const getSupportPhone = createSelector(
  [getBrand],
  (brand: BrandConfig): string => pathOr('', ['company', 'supportPhone'], brand),
)

export const getBrandAddress = createSelector(
  [getBrand],
  (brand: BrandConfig): Address => pathOr({}, ['company', 'address'], brand),
)

export const getBrandTitle = createSelector(
  [getBrand],
  (brand: BrandConfig): string => pathOr('', ['company', 'title'], brand),
)

export const getBrandTermsUrl = createSelector(
  [getBrand],
  (brand: BrandConfig): string => pathOr('', ['termsUrl'], brand),
)

export const getBrandPrivacyUrl = createSelector(
  [getBrand],
  (brand: BrandConfig): string => pathOr('', ['privacyPolicyUrl'], brand),
)

export const getBrandRemoteConfigUrl = createSelector(
  [getBrand],
  (brand: BrandConfig): string => prop('configUrl', brand),
)

export const getCustomerLink = (url: string, jwtToken: ?string): string => {
  if (isNil(jwtToken) || isEmpty(jwtToken)) return url
  const { protocol, hostname, pathname } = new Url(url)
  const token = defaultToEmptyString(jwtToken)
  return `${protocol}//${hostname}/customer/auth/jwt?token=${token}&to=${pathname}`
}

export const getBrandDefaultLanguage = createSelector(
  [getBrand],
  // $FlowFixMe
  (brand: BrandConfig): Array<{ [string]: string }> | [] => prop('defaultLanguage', brand),
)

export const getBrandLanguageResources = createSelector(
  [getBrand],
  // $FlowFixMe
  (brand: BrandConfig): Array<{ [string]: string }> | [] => propOr([], 'languageResources', brand),
)

export const getOptionsPropertyFromConfigAndBrand = (pathToProperty: string[], defaultValue: any) =>
  createSelector(
    [getBrand, getConfig],
    (brand: BrandConfig, config: Config): boolean => {
      // For config: * -> root
      if (hasPath(pathToProperty, config)) {
        return pathOr(defaultValue, pathToProperty, config)
      }

      // For brand: * -> options
      const brandPathToProperty = ['options', ...pathToProperty]

      if (hasPath(brandPathToProperty, brand)) {
        return pathOr(defaultValue, brandPathToProperty, brand)
      }

      return defaultValue
    },
  )

export const getIsCompanyContactInformationEnabled = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['ui', 'company', 'contactInformation', 'enabled'], true)],
  (enabled: boolean): boolean => enabled,
)

export const getBottomBannerEnabled = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['web', 'bottomBannerEnabled'], false)],
  (value: boolean): boolean => value,
)

export const getRevenuecatConfig = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['billing', 'revenuecat'], {})],
  (value: Revenuecat | Object) => value,
)

export const getRevenuecatEnabled = createSelector(
  [getRevenuecatConfig],
  (revenuecatConfig): boolean => isEnabled(revenuecatConfig),
)

export const getRevenuecatBillingId = createSelector(
  [getRevenuecatConfig],
  (revenuecatConfig): string => propOr('revenuecat', 'id', revenuecatConfig),
)

export const getRevenuecatPollingTimeout = createSelector(
  [getRevenuecatConfig],
  (revenuecatConfig): number => propOr(5000, 'paymentStatusPollingIntervalMs', revenuecatConfig),
)

export const getRevenuecatPublicSdkKey = createSelector(
  [getRevenuecatConfig],
  (revenuecatConfig): ?string => propOr(undefined, 'key', revenuecatConfig),
)

export const selectFirebaseOptions = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['firebase'])],
  (firebaseOptions: ?FirebaseOptions): ?FirebaseOptions => firebaseOptions,
)

export const getContentCatalogUIConfig = (
  content: 'home' | 'discover' | 'channels' | 'radio' | 'movies' | 'tvshows',
  options: {
    withoutPersonalCatalogs?: boolean,
  } = {},
) =>
  createSelector(
    [
      getOptionsPropertyFromConfigAndBrand(['ui', content], {}),
      getOptionsPropertyFromConfigAndBrand(['ui', 'defaultCatalogSectionConfig'], {}),
    ],
    (
      uiContentConfig: any = {},
      uiDefaultCatalogSectionConfig: any = {},
    ): (ContentCatalogUIConfig & { type: string }) | string => {
      const withoutPersonalCatalogs = prop('withoutPersonalCatalogs', options)

      const defaultContentConfig = propOr({}, content, DEFAULT_UI_CONFIG)
      const defaultSectionConfig = propOr({}, 'defaultCatalogSectionConfig', DEFAULT_UI_CONFIG)

      const contentConfig = mergeDeepRight(defaultContentConfig, defaultToEmptyObject(uiContentConfig))
      const sectionConfig = mergeDeepRight(defaultSectionConfig, defaultToEmptyObject(uiDefaultCatalogSectionConfig))

      const contentCatalogConfig = test(/home|discover/, content)
        ? contentConfig
        : defaultToEmptyObject(prop('catalog', contentConfig))

      const sections = defaultToEmptyArray(prop('sections', contentCatalogConfig))
      const preparedSections = withoutPersonalCatalogs ? getContentCatalogWithoutPersonalCatalogs(sections) : sections

      return {
        ...contentCatalogConfig,
        sections: map(section => {
          if (is(Object, section)) {
            return mergeRight(defaultToEmptyObject(prop(prop('type', section), sectionConfig)), section)
          }
          return prop(section, sectionConfig)
            ? { type: section, ...defaultToEmptyObject(prop(section, sectionConfig)) }
            : section
        }, preparedSections),
      }
    },
  )

export const getGuestAccessConfig = createSelector(
  [getBrand, getConfig],
  (brand: BrandConfig, config: Config): GuestAccess => {
    const guestAccessFromBrand = defaultToEmptyObject(path(['ui', 'guest'], brand))
    const guestAccessFromConfig = defaultToEmptyObject(path(['ui', 'guest'], config))
    const preparedExternalConfig = mergeDeepRight(guestAccessFromBrand, guestAccessFromConfig)
    return mergeDeepRight(DEFAULT_GUEST_ACCESS_CONFIG, preparedExternalConfig)
  },
)

export const getGuestAccessTrialConfig = createSelector(
  [getGuestAccessConfig],
  (guestAccess: GuestAccess): GuestAccessTrial => defaultToEmptyObject(prop('trial', guestAccess)),
)

export const getChannelPlaybackTabs = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['ui', 'tv', 'playback', 'tabs'], CHANNEL_PLAYBACK_TABS)],
  (tabs: string[]) => tabs,
)

export const getRadioChannelPlaybackTabs = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['ui', 'radio', 'playback', 'tabs'], RADIO_CHANNEL_PLAYBACK_TABS)],
  (tabs: string[]) => tabs,
)

export const getMoviePlaybackTabs = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['ui', 'movie', 'playback', 'tabs'], MOVIE_PLAYBACK_TABS)],
  (tabs: string[]) => tabs,
)

export const getTVShowPlaybackTabs = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['ui', 'tvshow', 'playback', 'tabs'], TV_SHOW_PLAYBACK_TABS)],
  (tabs: string[]) => tabs,
)

export const getLeadDynoConfig = createSelector(
  [getBrand, getConfig],
  (brand: BrandConfig, config: Config): LeadDyno => {
    const leadDynoFromBrand = defaultToEmptyObject(path(['affiliates', 'leadDyno'], brand))
    const leadDynoFromConfig = defaultToEmptyObject(path(['affiliates', 'leadDyno'], config))
    return mergeDeepRight(leadDynoFromBrand, leadDynoFromConfig)
  },
)

export const getLeadDynoEnabled = createSelector(
  [getLeadDynoConfig],
  (leadDynoConfig: LeadDyno): boolean => isEnabled(leadDynoConfig),
)

export const getPostAffiliateProConfig = createSelector(
  [getBrand, getConfig],
  (brand: BrandConfig, config: Config): PostAffiliatePro => {
    const postAffiliateProFromBrand = defaultToEmptyObject(path(['affiliates', 'postAffiliatePro'], brand))
    const postAffiliateProFromConfig = defaultToEmptyObject(path(['affiliates', 'postAffiliatePro'], config))
    return mergeDeepRight(postAffiliateProFromBrand, postAffiliateProFromConfig)
  },
)

export const getPostAffiliateProEnabled = createSelector(
  [getPostAffiliateProConfig],
  (postAffiliateProConfig: PostAffiliatePro): boolean => isEnabled(postAffiliateProConfig),
)

export const getVideoPlayer = createSelector(
  [getBrand, getUIPlayer],
  (brand: BrandConfig, UIPlayer): string => {
    const videoPlayerFromBrand = pathOr(VIDEO_PLAYERS.VIDEO_JS, ['options', 'ui', 'player', 'videoPlayer'], brand)
    return propOr(videoPlayerFromBrand, 'videoPlayer', UIPlayer)
  },
)

export const getSmartTVVideoPlayer = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['platform', 'smarttv', 'player', 'videoPlayer'], VIDEO_PLAYERS.SHAKA_PLAYER)],
  (videoPlayer: string): string => videoPlayer,
)

export const getForceReadyPlayerHackEnabled = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['platform', 'smarttv', 'player', 'forceReadyPlayerHack', 'enabled'], false)],
  (enabled: boolean): boolean => enabled,
)

export const getHttpForSourceLinkEnabled = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['platform', 'smarttv', 'player', 'httpForSourceLink', 'enabled'], false)],
  (enabled: boolean): boolean => enabled,
)

export const getDeviceRemoveEnabled = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['ui', 'devices', 'remove', 'enabled'], true)],
  (enabled: boolean): boolean => enabled,
)

export const getPurchaseSubscriptionInMobileAppEnabled = createSelector(
  [
    getOptionsPropertyFromConfigAndBrand(
      ['platform', 'web', 'subscriptions', 'purchaseSubscriptionInMobileApp'],
      false,
    ),
  ],
  (enabled: boolean): boolean => enabled,
)

export const getChargebeeRenewLink = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['billing', 'chargebee', 'renewLink'])],
  (renewLink: string): ?string => renewLink,
)

export const isEnabledAppLinksInSuccessSignUpPage = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['ui', 'signup', 'successPage', 'appLinks', 'enabled'], false)],
  (enabled: boolean): boolean => enabled,
)

export const getPaymentHistoryEnabled = createSelector(
  [
    getOptionsPropertyFromConfigAndBrand(
      ['ui', 'subscriptions', 'paymentHistory', 'enabled'],
      DEFAULT_PAYMENT_HISTORY_ENABLED,
    ),
  ],
  (enabled: boolean): boolean => enabled,
)

export const getIsShowExpiredSubscriptions = createSelector(
  [
    getOptionsPropertyFromConfigAndBrand(
      ['ui', 'subscriptions', 'expiredSubscriptions', 'enabled'],
      DEFAULT_EXPIRED_SUBSCRIPTIONS_ENABLED,
    ),
  ],
  (enabled: boolean): boolean => enabled,
)

export const getPictureInPictureEnabled = createSelector(
  [
    getOptionsPropertyFromConfigAndBrand(
      ['ui', 'player', 'pictureInPicture', 'enabled'],
      DEFAULT_PICTURE_IN_PICTURE_ENABLED,
    ),
  ],
  (enabled: boolean): boolean => enabled,
)

export const getBannerAutoPlayInterval = createSelector(
  [getOptionsPropertyFromConfigAndBrand(['ui', 'banner', 'autoplayInterval'], DEFAULT_BANNER_AUTOPLAY_INTERVAL)],
  (autoplayInterval: number): number => autoplayInterval,
)
