// @flow

import { CHANGE_CONTENT_META_DATA_TYPE, CHANGE_PLAYER_PROGRESS_TYPE } from './constants'

export const changeContentMetaData = (payload) => (dispatch) =>
  dispatch({
    type: CHANGE_CONTENT_META_DATA_TYPE,
    payload,
  })

export const changePlayerProgress = (currentTime) => (dispatch) =>
  dispatch({
    type: CHANGE_PLAYER_PROGRESS_TYPE,
    payload: {
      currentTime,
    },
  })
