// @flow

import { useCallback, useEffect } from 'react'
import { BUTTON_KEY_NAMES } from '../../const'

export const keyCodes = {
  Escape: BUTTON_KEY_NAMES.BACK,
  PageUp: BUTTON_KEY_NAMES.CHANNEL_UP,
  PageDown: BUTTON_KEY_NAMES.CHANNEL_DOWN,
  Enter: BUTTON_KEY_NAMES.ENTER,
  EnterUp: BUTTON_KEY_NAMES.ENTER_UP,
  ArrowLeft: BUTTON_KEY_NAMES.ARROW_LEFT,
  ArrowUp: BUTTON_KEY_NAMES.ARROW_UP,
  ArrowRight: BUTTON_KEY_NAMES.ARROW_RIGHT,
  ArrowDown: BUTTON_KEY_NAMES.ARROW_DOWN,
}

export const useInitInput = () => {
  const onKeyDown = useCallback(({ code }) => {
    const handlerName = keyCodes[code]
    if (handlerName) document.dispatchEvent(new CustomEvent(handlerName))
  }, [])

  const onKeyUp = useCallback(({ code }) => {
    const handlerName = keyCodes[`${code}Up`]
    if (handlerName) document.dispatchEvent(new CustomEvent(handlerName))
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [onKeyDown])

  useEffect(() => {
    document.addEventListener('keyup', onKeyUp)
    return () => document.removeEventListener('keyup', onKeyUp)
  }, [onKeyUp])
}
