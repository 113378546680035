// @flow

import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { prepareCurrentNetworkStatus } from '@alphaott/smart-tv-common/src/hooks/useNetworkStatus'
import { setDeviceInfo } from '@alphaott/smart-tv-current-device/actions'

export const useInitDeviceInfo = () => {
  const dispatch = useDispatch()

  const handleChanelDeviceSystemInfo = useCallback(() => {
    dispatch(
      setDeviceInfo({
        networkStatus: prepareCurrentNetworkStatus(navigator.onLine),
        visibilityStatus: document.hidden ? 'hidden' : 'visible',
      }),
    )
  }, [dispatch])

  const handleChangeState = useCallback(
    (value) => () => {
      dispatch(
        setDeviceInfo({
          networkStatus: prepareCurrentNetworkStatus(value),
        }),
      )
    },
    [dispatch],
  )

  const setVisibilityStatus = useCallback(() => {
    dispatch(
      setDeviceInfo({
        visibilityStatus: document.hidden ? 'hidden' : 'visible',
      }),
    )
  }, [dispatch])

  useEffect(() => {
    handleChanelDeviceSystemInfo()

    window.addEventListener('online', handleChangeState(true))
    window.addEventListener('offline', handleChangeState(false))
    document.addEventListener('visibilitychange', setVisibilityStatus)

    return () => {
      window.removeEventListener('online', handleChangeState(true))
      window.removeEventListener('offline', handleChangeState(false))
      document.removeEventListener('visibilitychange', setVisibilityStatus)
    }
  }, [handleChangeState])
}

export default useInitDeviceInfo
