// @flow
import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'

import {
  Background,
  Container,
  Description,
  Header,
  Title,
  ConnectionLostIcon,
} from './NetworkErrorPopup.styles'

const NetworkErrorPopupPure = () => {
  const { t } = useTranslation()
  const { ref, pause, resume } = useFocusable()

  useEffect(() => {
    pause()

    return () => {
      resume()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Background ref={ref}>
      <Container>
        <Header>
          <ConnectionLostIcon />
          <Title>{t('connection_lost')}</Title>
        </Header>

        <Description>
          {t('your_internet_connection_has_been_lost_please_check_your_network_settings')}
        </Description>
      </Container>
    </Background>
  )
}

export const NetworkErrorPopup = memo(NetworkErrorPopupPure)

export default NetworkErrorPopup
