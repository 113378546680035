// @flow
import { map } from 'ramda'
import typeToReducer from 'type-to-reducer'

import type { Movies } from '@alphaott/api-client/types/movies'

import { prepareMovieImages } from '@alphaott/common-utils/utils/schemas/movies'
import { FETCH_SEARCH_MOVIES_TYPE } from '../actions'

export type MoviesSearchListState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +hasMore: boolean,
  +errors: any,
  +data: Movies,
}

const initialState: MoviesSearchListState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  hasMore: true,
  errors: [],
  data: [],
}

const movieSearchList = typeToReducer(
  {
    [FETCH_SEARCH_MOVIES_TYPE]: {
      LOADING: (state): MoviesSearchListState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): MoviesSearchListState => {
        const { payload, meta } = action
        const { data } = payload
        const baseData = meta.resetData ? [] : state.data
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          hasMore: data.length > 0,
          data: [...baseData, ...map(movie => prepareMovieImages(movie, meta.contentApi))(data)],
        }
      },

      ERROR: (state, action): MoviesSearchListState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
  },
  initialState,
)

export { movieSearchList }
