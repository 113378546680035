// @flow
import typeToReducer from 'type-to-reducer'

import type { Devices } from '@alphaott/api-client/types/devices'

import { FETCH_DEVICES_TYPE, REMOVE_DEVICE_TYPE } from '../actions'

export type DevicesState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Devices,
}

const initialState: DevicesState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: {},
  data: [],
}

const devices = typeToReducer(
  {
    [FETCH_DEVICES_TYPE]: {
      LOADING: (state): DevicesState => ({ ...state, isLoading: true, isError: false, errors: {} }),

      SUCCESS: (state, action): DevicesState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: action.payload.data,
        errors: {},
        isError: false,
      }),

      ERROR: (state, action): DevicesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
    [REMOVE_DEVICE_TYPE]: {
      LOADING: (state): DevicesState => ({ ...state, isLoading: true, isError: false, errors: {} }),

      SUCCESS: (state, action): DevicesState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: action.payload.data,
        errors: {},
        isError: false,
      }),

      ERROR: (state, action): DevicesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
  },
  initialState,
)

export { devices }
