export const { PUBLIC_URL } = process.env

export const VERSION = process.env.REACT_APP_VERSION
export const GIT_REVISION = process.env.REACT_APP_GIT_REVISION || 'unknown'

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET

export const FALLBACK_BRAND_ID = process.env.REACT_APP_FALLBACK_BRAND_ID || 'alphaott'

// Sets default limits_interval in seconds
export const LIMITS_INTERVAL = 60

export const DEFAULT_MAX_RATING = 10

// TODO: https://alpha-ott.atlassian.net/browse/MWDC-213
export const BACKGROUND_VIDEO_INTERVAL = 5000

export const SENTRY_DSN = process.env.REACT_APP_RAVEN_DSN

export const APP_ENV = process.env.REACT_APP_ENV

export const RECENT_CHANNEL_MAX_COUNT = 50
export const POPULAR_CHANNELS_MAX_COUNT = 50
export const MOST_WATCHED_CHANNEL_MAX_COUNT = 50
export const SUGGESTED_TV_CHANNEL_MAX_COUNT = 50
export const RECENT_RADIO_CHANNEL_MAX_COUNT = 50
export const RECENT_MOVIE_MAX_COUNT = 50
export const POPULAR_MOVIE_MAX_COUNT = 50
export const RECENT_TVSHOW_MAX_COUNT = 50
export const POPULAR_TVSHOW_MAX_COUNT = 50
export const SUGGESTED_TVSHOW_MAX_COUNT = 50

export const MOST_WATCHED_CHANNEL_MIN_TIME = 60000
export const MOST_PLAYED_RADIO_CHANNEL_MIN_TIME = 60000

export const TVSHOW_EPISODE_COMPLETED_MIN_TIME = 120
export const TVSHOW_EPISODE_COMPLETED_MIN_PERCENTAGE = 0.98

export const CONTAINER_WIDTH = 1330

export const LARGE_VIEWPORT = 1280
export const MEDIUM_VIEWPORT = 1100
export const SMALL_VIEWPORT = 900
export const LARGE_MOBILE_VIEWPORT = 869
export const MEDIUM_MOBILE_VIEWPORT = 560
export const SMALL_MOBILE_VIEWPORT = 480

export const SMALL_VIEWPORT_HEIGHT = 800
export const MEDIUM_VIEWPORT_HEIGHT = 980
export const LARGE_VIEWPORT_HEIGHT = 1100

export const UPDATE_CHANNEL_PROGRESS_INTERVAL = 600 // 600sec = 10min
export const UPDATE_RADIO_CHANNEL_PROGRESS_INTERVAL = 600 // 600sec = 10min

export const UPDATE_RECENT_MOVIE_INTERVAL = 600
export const UPDATE_RECENT_MOVIE_PERCENT = 0.1 // 10%

export const UPDATE_RECENT_TVSHOW_PERCENT = 0.1 // 10%

export const PERCENTAGE_TO_COMPLETED = 0.9 // 90%

export const GET_CONTENT_INTERVAL = 600000 // 10min

export const UPDATE_CURRENT_EPG_INTERVAL = 3000
export const UPDATE_CURRENT_TIME_EPG = 5000

export const UPDATE_VOLUME_SETTINGS_DELAY = 1000

/**
 * SignUp
 */
export const DEFAULT_SIGNUP_ENABLED = true

/**
 * Billing
 */
export const VOUCHER_ENABLE = false
export const BILLING_ENABLED = false
export const BILLING_SIGN_UP_PLAN_STEP_ENABLED = true
export const BILLING_HOSTED_PAGE_IFRAME_ENABLED = false
export const CHECK_PAYMENT_STATUS_INTERVAL = 3 * 1000 // 3 sec

/**
 * Banner options
 */
export const DEFAULT_BANNER_AUTOPLAY_INTERVAL = 10000 // 10 sec

/**
 * Program Guide
 */
export const PROGRAM_CHANNEL_COUNT = 10

/**
 * Video player
 */
export const PLAY_IN_BACKGROUND = true
export const VIDEO_PLAYERS = {
  VIDEO_JS: 'videojs',
  SHAKA_PLAYER: 'shakaplayer',
}
export const DEFAULT_PICTURE_IN_PICTURE_ENABLED = false

export const PLAYER_AUDIO_PARAM = 'audio'
export const PLAYER_SUBTITLES_PARAM = 'text'

export const DEFAULT_LANGUAGE_OPTION = {
  nativeName: 'Default',
  key: 'default',
}
export const DISABLED_LANGUAGE_OPTION = {
  nativeName: 'None',
  key: 'none',
}

export const DEFAULT_STREAM_ERROR_MESSAGE =
  // eslint-disable-next-line max-len
  'Please check your device limit, disable your VPN (if applicable) and try to login again. If the issue persists, please contact our support.'

/**
 * Ad
 */
export const CHECK_ADBLOCK_ENABLED = true

/**
 * Guest Access
 */
export const GUEST_ACCESS_WHITE_LIST_ROUTES = ['/auth/login', '/auth/signup']
export const GUEST_ACCESS_TIMER_STEP = 10 // Seconds
export const DEFAULT_GUEST_ACCESS_CONFIG = {
  trial: {
    enabled: false,
    expires: 0,
  },
}

/**
 * Catalog
 */
export const CATALOG_CONTENT_TYPE = {
  TV: 'TV',
  RADIO: 'RADIO',
  MOVIE: 'MOVIE',
  TV_SHOW: 'TV_SHOW',
}

export const CATALOG_CONTENT_GROUP_TYPE = {
  CATEGORY: 'CATEGORY',
  GENRE: 'GENRE',
}

export const CATALOG_CONTENT_MERGE_RULE = {
  ALTERNATE: 'alternate',
  CONCATENATE: 'concatenate',
}

export const DEFAULT_CATALOG_CONFIG = {
  contentComposition: {
    mergeRule: CATALOG_CONTENT_MERGE_RULE.CONCATENATE,
    groupType: [CATALOG_CONTENT_GROUP_TYPE.GENRE, CATALOG_CONTENT_GROUP_TYPE.CATEGORY],
  },
  discover: {
    contentComposition: {
      mergeRule: CATALOG_CONTENT_MERGE_RULE.ALTERNATE,
      contentType: [
        CATALOG_CONTENT_TYPE.TV,
        CATALOG_CONTENT_TYPE.RADIO,
        CATALOG_CONTENT_TYPE.MOVIE,
        CATALOG_CONTENT_TYPE.TV_SHOW,
      ],
    },
  },
  tv: {
    contentComposition: {
      groupType: [CATALOG_CONTENT_GROUP_TYPE.CATEGORY, CATALOG_CONTENT_GROUP_TYPE.GENRE],
    },
  },
}

export const CATALOG_SECTION_TYPE = {
  POPULAR: 'popular',
  MOST_WATCHED: 'most-watched',
  MOST_PLAYED: 'most-played',
  SUGGESTED: 'suggested',
  FAVORITE: 'favorite',
  RECENT: 'recent',
  ALL: 'all',
  NEW: 'new',
  GROUPS: 'groups',
  LATEST_ADDITIONS: 'latest-additions',
}

export const DEFAULT_UI_CONFIG = {
  defaultCatalogSectionConfig: {
    groups: {
      limit: 0,
      tags: [],
      mergeRule: CATALOG_CONTENT_MERGE_RULE.CONCATENATE,
      groupType: [CATALOG_CONTENT_GROUP_TYPE.GENRE, CATALOG_CONTENT_GROUP_TYPE.CATEGORY],
      contentType: [
        CATALOG_CONTENT_TYPE.TV,
        CATALOG_CONTENT_TYPE.RADIO,
        CATALOG_CONTENT_TYPE.MOVIE,
        CATALOG_CONTENT_TYPE.TV_SHOW,
      ],
    },
  },
  home: {
    sections: [
      CATALOG_SECTION_TYPE.SUGGESTED,
      CATALOG_SECTION_TYPE.POPULAR,
      CATALOG_SECTION_TYPE.FAVORITE,
      CATALOG_SECTION_TYPE.RECENT,
      CATALOG_SECTION_TYPE.LATEST_ADDITIONS,
      { type: CATALOG_SECTION_TYPE.GROUPS, tags: ['home-screen'] },
    ],
  },
  discover: {
    sections: [
      CATALOG_SECTION_TYPE.ALL,
      CATALOG_SECTION_TYPE.POPULAR,
      CATALOG_SECTION_TYPE.RECENT,
      { type: CATALOG_SECTION_TYPE.GROUPS, tags: ['discover-screen'], mergeRule: CATALOG_CONTENT_MERGE_RULE.ALTERNATE },
    ],
  },
  channels: {
    catalog: {
      sections: [CATALOG_SECTION_TYPE.ALL, CATALOG_SECTION_TYPE.GROUPS],
    },
  },
  radio: {
    catalog: {
      sections: [CATALOG_SECTION_TYPE.ALL, CATALOG_SECTION_TYPE.GROUPS],
    },
  },
  movies: {
    catalog: {
      sections: [CATALOG_SECTION_TYPE.ALL, CATALOG_SECTION_TYPE.GROUPS],
    },
  },
  tvshows: {
    catalog: {
      sections: [CATALOG_SECTION_TYPE.ALL, CATALOG_SECTION_TYPE.GROUPS],
    },
  },
}

export const CHANNEL_PLAYBACK_TAB_KEYS = {
  INFO: 'info',
  PROGRAMS: 'programs',
  RELATED: 'related',
  ALL: 'all',
  FAVORITES: 'favorites',
}

export const CHANNEL_PLAYBACK_TABS = [
  CHANNEL_PLAYBACK_TAB_KEYS.INFO,
  CHANNEL_PLAYBACK_TAB_KEYS.PROGRAMS,
  CHANNEL_PLAYBACK_TAB_KEYS.ALL,
  CHANNEL_PLAYBACK_TAB_KEYS.RELATED,
  CHANNEL_PLAYBACK_TAB_KEYS.FAVORITES,
]

export const RADIO_CHANNEL_PLAYBACK_TAB_KEYS = {
  INFO: 'info',
  RELATED: 'related',
  ALL: 'all',
  FAVORITES: 'favorites',
}

export const RADIO_CHANNEL_PLAYBACK_TABS = [
  RADIO_CHANNEL_PLAYBACK_TAB_KEYS.INFO,
  RADIO_CHANNEL_PLAYBACK_TAB_KEYS.ALL,
  RADIO_CHANNEL_PLAYBACK_TAB_KEYS.RELATED,
  RADIO_CHANNEL_PLAYBACK_TAB_KEYS.FAVORITES,
]

export const MOVIE_PLAYBACK_TAB_KEYS = {
  SUGGESTED: 'suggested',
  RELATED: 'related',
  FAVORITES: 'favorites',
}

export const MOVIE_PLAYBACK_TABS = [
  MOVIE_PLAYBACK_TAB_KEYS.SUGGESTED,
  MOVIE_PLAYBACK_TAB_KEYS.RELATED,
  MOVIE_PLAYBACK_TAB_KEYS.FAVORITES,
]

export const TV_SHOW_PLAYBACK_TAB_KEYS = {
  SUGGESTED: 'suggested',
  RELATED: 'related',
  FAVORITES: 'favorites',
}

export const TV_SHOW_PLAYBACK_TABS = [
  TV_SHOW_PLAYBACK_TAB_KEYS.SUGGESTED,
  TV_SHOW_PLAYBACK_TAB_KEYS.RELATED,
  TV_SHOW_PLAYBACK_TAB_KEYS.FAVORITES,
]

export const DEFAULT_CACHE = {
  account: 86400,
  groups: 86400,
  channels: 86400,
  vod: 86400,
  sources: 86400,
  config: 86400,
}

/**
 * Mobile options
 */
export const NO_ACTIVE_SUBSCRIPTION = true
export const LOGOUT_ON_EMPTY_SUBSCRIPTIONS = false
export const DEMO_USER_AND_NO_SUBSCRIPTIONS = true

/**
 * Subscriptions options
 */
export const DEFAULT_PAYMENT_HISTORY_ENABLED = true
export const DEFAULT_EXPIRED_SUBSCRIPTIONS_ENABLED = true

/**
 * OTP
 */
export const OTP_INPUTS_AMOUNT = 6
export const RESEND_OTP_TIMEOUT = 30

/**
 * Profile options
 */
export const DEFAULT_ACCESS_RATINGS = [8, 12, 15, 18]

/**
 * Parental control
 */
export const PARENTAL_CONTROL_RESTRICTED_TAG = 'xxx'
