// @flow

import { pathOr, propOr } from 'ramda'
import typeToReducer from 'type-to-reducer'
import { parseTVShowSeason } from '@alphaott/common-utils/utils/schemas/tvshows'
import type { TVShowSeasonDetail } from '@alphaott/api-client/types/tvshows'

import { FETCH_TVSHOW_SEASON, FETCH_TVSHOW_SEASON_TRAILER_SOURCE, RESET_TVSHOW_DETAIL } from '../../actions'
import { sortEpisodes } from '../utils'

import type { NormalizedTrailers } from '../tvshow'

type BaseStateType = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
}
export type TVShowSeasonsState = {|
  entities: {
    [string]: BaseStateType & {
      result: TVShowSeasonDetail,
    },
  },
  trailers: NormalizedTrailers,
|}

const baseState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: {},
}

const initialState: TVShowSeasonsState = {
  entities: {},
  trailers: {},
}

const initialSeasonState = {
  ...baseState,
  created: null,
  result: {},
}

const prepareEpisodes = (data, domain) => {
  const result = parseTVShowSeason(data, domain)
  return {
    ...result,
    episodes: result && sortEpisodes(propOr([], 'episodes', result)),
  }
}

const tvshowSeasons = typeToReducer(
  {
    [RESET_TVSHOW_DETAIL]: () => ({ ...initialState }),
    [FETCH_TVSHOW_SEASON]: {
      LOADING: (state, action): TVShowSeasonsState => ({
        ...state,
        entities: {
          ...state.entities,
          [action.meta.seasonId]: {
            ...initialSeasonState,
            isLoading: true,
          },
        },
        trailers: {},
      }),
      SUCCESS: (state, { payload, meta }): TVShowSeasonsState => ({
        ...state,
        entities: {
          ...state.entities,
          [meta.seasonId]: {
            ...pathOr(initialSeasonState, [meta.seasonId], state.entities),
            created: new Date().getTime(),
            isLoading: false,
            isSuccess: true,
            result: prepareEpisodes(payload.data, meta.contentApi),
          },
        },
        trailers: {},
      }),
      ERROR: (state, { meta, payload }): TVShowSeasonsState => ({
        ...state,
        entities: {
          ...state.entities,
          [meta.seasonId]: {
            ...pathOr(initialSeasonState, [meta.seasonId], state.entities),
            isLoading: false,
            isSuccess: false,
            isError: true,
            errors: payload.error,
          },
        },
      }),
    },
    [FETCH_TVSHOW_SEASON_TRAILER_SOURCE]: {
      LOADING: (state, { meta }): TVShowSeasonsState => ({
        ...state,
        trailers: {
          ...state.trailers,
          [meta.trailerId]: {
            ...baseState,
            isLoading: true,
          },
        },
      }),
      SUCCESS: (state, { payload, meta }): TVShowSeasonsState => ({
        ...state,
        trailers: {
          ...state.trailers,
          [meta.trailerId]: {
            ...baseState,
            isLoading: false,
            isSuccess: true,
            data: payload.data,
          },
        },
      }),
      ERROR: (state, { meta, payload }): TVShowSeasonsState => ({
        ...state,
        trailers: {
          ...state.trailers,
          [meta.trailerId]: {
            isLoading: false,
            isSuccess: false,
            isError: true,
            data: {},
            errors: payload.error,
          },
        },
      }),
    },
  },
  initialState,
)

export { tvshowSeasons }
