// @flow
import React, { memo, useMemo, useCallback } from 'react'
import { areEqual } from 'react-window'
import { multiply, prop } from 'ramda'

import { useArrowPress } from '@alphaott/smart-tv-common'
import { useLastFocusHistory } from '@alphaott/smart-tv-spatial-navigation'

import { ChannelCard } from '../ChannelCard'

type Item = Object
type CellProps = {
  data: {
    setFocus: Function,
    items: Array<Item>,
    cardPlaceholder?: string,
    itemsPerRow: number,
    listRef: Object,
    withSideMenu: boolean,
    onBecameFocused: () => void,
    onPressItem: Function,
    onLongPressItem: Function,
  },
  columnIndex: number,
  rowIndex: number,
  style: Object,
}

const FROM_INDEX = multiply

export const GUTTER_SIZE = 1

export const getItemIndex = ({
  rowIndex,
  columnIndex,
  itemsPerRow,
}: {
  rowIndex: number,
  columnIndex: number,
  itemsPerRow: number,
}): number => {
  const startIndex = FROM_INDEX(rowIndex, itemsPerRow)
  return startIndex + columnIndex
}

const ChannelCell = ({ columnIndex, data, rowIndex, style }: CellProps) => {
  const { onArrowPress } = useArrowPress()

  const {
    setFocus,
    items,
    cardPlaceholder,
    itemsPerRow,
    listRef,
    withSideMenu,
    onBecameFocused,
    onPressItem,
    onLongPressItem,
  } = data
  const itemIndex = getItemIndex({ rowIndex, columnIndex, itemsPerRow })
  const item = useMemo(() => items[itemIndex], [items, itemIndex])
  const itemId = useMemo(() => prop('id', item), [item])

  const { onSaveLastFocus } = useLastFocusHistory({
    focusKey: itemId,
    sectionIndex: rowIndex,
    itemIndex,
  })

  const handlePress = useCallback(() => {
    onPressItem(item, items)
  }, [item, items, onPressItem])

  const handleLongPressItem = useCallback(() => {
    onLongPressItem(item, items)
  }, [item, items, onLongPressItem])

  const handleArrowPress = useCallback(
    (direction) =>
      onArrowPress({
        setFocus,
        direction,
        columnIndex,
        itemIndex,
        items,
        itemsPerRow,
        withSideMenu,
        listRef,
      }),
    [columnIndex, itemIndex, items, itemsPerRow, listRef, onArrowPress, setFocus, withSideMenu],
  )

  const handlePressCallback = useCallback(
    (callback) => () => {
      onSaveLastFocus()
      callback && callback()
    },
    [onSaveLastFocus],
  )

  const handleBecameFocused = useCallback(
    (values) => onBecameFocused({ ...values, itemId, focusKey: itemId }),
    [itemId, onBecameFocused],
  )

  if (item) {
    return (
      <ChannelCard
        {...item}
        style={{
          ...style,
          left: style.left + GUTTER_SIZE,
          width: style.width - GUTTER_SIZE * 8,
        }}
        focusKey={itemId}
        key={itemId}
        cardPlaceholder={cardPlaceholder}
        onFocus={handleBecameFocused}
        onPress={handlePressCallback(handlePress)}
        onLongPress={handlePressCallback(handleLongPressItem)}
        onArrowPress={handleArrowPress}
      />
    )
  }

  return null
}

export default memo(ChannelCell, areEqual)
