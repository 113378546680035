// @flow
import { PLAYER_AUDIO_PARAM, PLAYER_SUBTITLES_PARAM } from '@alphaott/app-config'

import { PREFIX } from '../../../constants'

export type PreferredTrackPaylaod = {
  videoId: string,
  track: string,
  option: typeof PLAYER_AUDIO_PARAM | typeof PLAYER_SUBTITLES_PARAM,
}

export const CHANGE_PREFERRED_TRACK = `${PREFIX}/CHANGE_PREFERRED_TRACK`

export const changePreferredTrack = (payload: PreferredTrackPaylaod) => (dispatch: Function) =>
  dispatch({
    type: CHANGE_PREFERRED_TRACK,
    payload,
  })
