// @flow weak

import * as Sentry from '@sentry/browser'
import { prop, toLower } from 'ramda'

import type { DRM } from '@alphaott/api-client/types/drm'
import type { DRMProps } from '@alphaott/video-player'

import { DRM_TYPES, IS_SAFARI, IS_EDGE, IS_IE, IS_WINDOWS, IS_TIZEN, IS_WEBOS } from '../drm'

/**
 * WIDEVINE || PLAYREADY
 * ---------------------
 * drm = {
 *  type: 'widevine',
 *  licenseServer: 'https://wv-keyos.licensekeyserver.com/',
 *  headers: {
 *    customdata: 'PEtleU9TQXV0aGVudGljYX...'
 *  }
 * }
 *
 * FAIRPLAY
 * --------
 * drm = {
 *  type: 'fairplay',
 *  licenseServer: 'https://fp-keyos.licensekeyserver.com/getkey/',
 *  certificateUrl: 'https://fp-keyos.licensekeyserver.com...',
 *  headers: {
 *   customdata: 'PEtleU9TQXV0aGVudGljYX...'
 *  },
 * }
 */

const logError = (errMsg, meta) => {
  Sentry.captureException(new Error(errMsg), {
    extra: meta,
  })
}

const preparedFairplayDRM = (drm: DRM, errorExtra): ?DRMProps => {
  const drmObject = prop(DRM_TYPES.FAIRPLAY, drm)

  if (!drmObject) {
    logError(`DRM: source response hasn't ${DRM_TYPES.FAIRPLAY} data`, errorExtra)

    return null
  }

  return {
    type: toLower(DRM_TYPES.FAIRPLAY),
    licenseServer: drmObject.licenseUri,
    certificateUrl: drmObject.certificateUri,
    headers: drmObject.licenseHeaders,
    provider: drm?.provider,
  }
}

const preparedPlayreadyDRM = (drm: DRM, errorExtra): ?DRMProps => {
  const drmObject = prop(DRM_TYPES.PLAYREADY, drm)

  if (!drmObject) {
    logError(`DRM: source response hasn't ${DRM_TYPES.PLAYREADY} data`, errorExtra)

    return null
  }

  // $FlowFixMe
  return {
    type: toLower(DRM_TYPES.PLAYREADY),
    licenseServer: drmObject.licenseUri,
    headers: drmObject.licenseHeaders,
    provider: drm?.provider,
  }
}

const preparedWidevineDRM = (drm: DRM, errorExtra): ?DRMProps => {
  const drmObject = prop(DRM_TYPES.WIDEVINE, drm)

  if (!drmObject) {
    logError(`DRM: source response hasn't ${DRM_TYPES.WIDEVINE} data`, errorExtra)

    return null
  }

  // $FlowFixMe
  return {
    type: toLower(DRM_TYPES.WIDEVINE),
    licenseServer: drmObject.licenseUri,
    headers: drmObject.licenseHeaders,
    provider: drm?.provider,
  }
}

// eslint-disable-next-line complexity
export const preparedDRM = (drm: DRM, src: ?string): ?DRMProps => {
  if (!drm) return null

  const errorExtra = {
    sources: src,
    browser: {
      IS_SAFARI,
      IS_EDGE,
      IS_IE,
    },
  }

  if (IS_SAFARI) {
    return preparedFairplayDRM(drm, errorExtra)
  }

  if ((IS_WINDOWS && IS_EDGE) || IS_IE || IS_TIZEN || IS_WEBOS) {
    return preparedPlayreadyDRM(drm, errorExtra)
  }

  // For other clients return Widevine
  return preparedWidevineDRM(drm, errorExtra)
}

export default preparedDRM
