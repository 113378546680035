// @flow

import { createSelector } from 'reselect'
import { map, reject, isNil, prop, find, propEq } from 'ramda'

import type {
  LanguageNormalize,
  CategoriesNormalize,
  GenresNormalize,
  CountriesNormalize,
} from '@alphaott/common-types/normalized'

import { prepareMovie } from '@alphaott/common-utils/utils/schemas/movies'
import { isInitialLoading, defaultToEmptyObject } from '@alphaott/common-utils/utils/help'
import type { PreparedMovie } from '@alphaott/common-utils/utils/schemas/movies'

import { getMoviesMaxRating } from '@alphaott/app-main/selectors'

import type { MoviesStore } from '../types'

import {
  getFavoriteState,
  getFavoriteIdsState,
  getCategoriesEntities,
  getGenresEntities,
  getLanguagesEntities,
  getCountriesEntities,
  getRecentProgress,
} from './selectors'

import type { FavoriteMoviesIdsState, FavoriteMoviesState, MovieProgress } from '../reducers'

const isDataNil = movie => isNil(prop('_id', movie))
export const getMoviesFavoriteList = createSelector(
  [
    getFavoriteState,
    getGenresEntities,
    getCategoriesEntities,
    getLanguagesEntities,
    getCountriesEntities,
    getFavoriteIdsState,
    getMoviesMaxRating,
    getRecentProgress,
  ],
  (
    favorites: FavoriteMoviesState,
    genres: GenresNormalize,
    categories: CategoriesNormalize,
    languages: LanguageNormalize,
    counties: CountriesNormalize,
    favoriteIds: FavoriteMoviesIdsState,
    maxRating: number,
    recent: Array<MovieProgress>,
  ): Array<PreparedMovie> =>
    reject(
      isDataNil,
      map(
        ({ item }) =>
          prepareMovie(
            defaultToEmptyObject(favorites.entities[item]),
            genres,
            categories,
            languages,
            counties,
            favoriteIds.data,
            maxRating,
            defaultToEmptyObject(find(propEq('id', item))(recent)),
          ),
        favoriteIds.data,
      ),
    ),
)

export const isMoviesLoading = createSelector(
  [getFavoriteState],
  // $FlowFixMe
  (favorites: FavoriteMoviesState): boolean => isInitialLoading(favorites) || favorites.isLoading,
)

export const isMoviesSuccess = (state: MoviesStore): boolean => state.movieFavorites.isSuccess
