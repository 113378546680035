// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { getColorPrimary } from '@alphaott/smart-tv-themes'

import { CheckMarkIcon } from '../../../../assets'

export const Container = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 90rem;
  color: #fff;
  background-color: ${({ focused }) => (focused ? getColorPrimary : 'transparent')};
  margin-top: 1rem;
`

export const CheckMarkContainer = styled.div`
  width: 95rem;
`

export const CheckMark = styled(CheckMarkIcon)`
  width: 35rem;
  height: 26rem;
  margin-left: 35rem;
`

export const Label = styled.div`
  font-size: 33rem;
  font-weight: bold;
  width: 370rem;
  height: 48rem;
  line-height: 50rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
