// @flow
import { getServiceProvider } from '@alphaott/api-client'
import { getPublicDomain } from '@alphaott/app-main/selectors'

export const FETCH_SERVICE_PROVIDER_TYPE = 'serviceProvider/FETCH_SERVICE_PROVIDER'

export const fetchServiceProvider = () => (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_SERVICE_PROVIDER_TYPE,
    payload: getServiceProvider(getPublicDomain(getState())),
  })
