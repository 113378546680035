import styled from 'styled-components'

import { getColorPrimaryContrastText } from '@alphaott/smart-tv-themes'

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32rem;
  line-height: 37rem;
  color: ${getColorPrimaryContrastText};
  text-align: center;
`
