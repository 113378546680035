// @flow
import styled from 'styled-components'
import { Image, Text } from '@alphaott/smart-tv-components'
import { getColorPrimary, getColorSlightPrimary } from '@alphaott/smart-tv-themes'

export const CHANNEL_CARD_ASPECT_RATIO: number = 1.5
export const CHANNEL_CARD_SPACING: number = 16
export const CHANNEL_CARD_WIDTH: number = 270
export const CHANNEL_CARD_HEIGHT: number = CHANNEL_CARD_WIDTH / CHANNEL_CARD_ASPECT_RATIO

export const CHANNEL_CARD_ITEM_WIDTH: number = CHANNEL_CARD_WIDTH + CHANNEL_CARD_SPACING
export const CHANNEL_CARD_ITEM_HEIGHT: number = CHANNEL_CARD_HEIGHT + 50

export const ChannelCardContainer = styled.div`
  width: ${CHANNEL_CARD_WIDTH}rem;
  height: ${CHANNEL_CARD_ITEM_HEIGHT}rem;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
`

export const Placeholder = styled.img`
  width: ${CHANNEL_CARD_WIDTH}rem;
  height: ${CHANNEL_CARD_HEIGHT}rem;
`

export const ChannelCardImage = styled(Image)`
  width: ${CHANNEL_CARD_WIDTH}rem;
  height: ${CHANNEL_CARD_HEIGHT}rem;
  border-radius: 9rem;
  border: 8rem solid;
  border-color: ${(props) => (props.focused ? getColorPrimary(props) : 'transparent')};
  box-shadow: inset 0 0 0 2rem
    ${(props) => (props.focused ? getColorPrimary(props) : 'transparent')};
  overflow: hidden;

  &:hover {
    border-color: ${(props) => {
      if (props.focused) return getColorPrimary(props)
      return getColorSlightPrimary(props)
    }};
  }
`

export const ChannelCardImageContainer = styled.div`
  position: relative;
`

export const ChannelCardTitleBox = styled.div`
  height: 34rem;
  overflow: hidden;
`

export const ChannelCardTitle = styled(Text)`
  display: inline-block;
  width: ${CHANNEL_CARD_WIDTH}rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  letter-spacing: 0.7rem;
`

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 8rem;
  right: 8rem;
`

export const TimezoneContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 30rem;
  width: 54rem;
  opacity: 0.7;
  border-radius: 3rem 0 0 3rem;
  background-color: #222;
  color: #fff;
  font-size: 18rem;
  line-height: 12rem;
  margin-top: 6px;
`

export const TimezoneText = styled.span``

export const CatchupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
  height: 30rem;
  width: 27rem;
  opacity: 0.7;
  border-radius: 3rem 0 0 3rem;
  background-color: #222;
`

export const Icon = styled.img`
  display: block;
  width: 18rem;
  height: 18rem;
`
