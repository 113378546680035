// @flow

import React, { useCallback, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { not, prop, and, equals, or } from 'ramda'

import { ENTITY_TYPE } from '@alphaott/smart-tv-utils'
import { useRestrictedContent } from '@alphaott/app-settings/useRestrictedContent'
import {
  getPinCode,
  getEnableParentalControls,
  isRestrictedContent,
} from '@alphaott/app-settings/models/selectors'
import { EnterPinView } from '@alphaott/smart-tv-user-profiles'

import lockedCard from '../assets/locked-card.png'
import lockedPoster from '../assets/locked-poster.png'

type CardType = {
  src: string,
  focusKey: string,
  pgRating: number,
  tags: string[],
  isActive?: string,
  isPlayable?: boolean,
  isLive?: boolean,
  isCardHorizontal?: boolean,
  onPress: Function,
  onLongPress?: Function,
  onSwitchChannel?: Function,
  startCloseListTimeout?: Function,
  onCloseChannelsList: Function,
  setFocus?: Function,
}

// eslint-disable-next-line complexity
export const withParentalControl =
  (Component: any) =>
  // eslint-disable-next-line react/display-name
  ({
    src,
    type,
    focusKey,
    pgRating,
    tags,
    isActive,
    isPlayable,
    isLive,
    isCardHorizontal,
    onPress,
    onLongPress,
    onSwitchChannel,
    startCloseListTimeout,
    onCloseChannelsList,
    onEnterPress,
    setFocus,
    ...props
  }: CardType) => {
    const pinCode = useSelector(getPinCode)
    const isEnabled = useSelector(getEnableParentalControls)
    const isRestricted = useSelector(isRestrictedContent(pgRating, tags))
    // isRestricted - if includes restricted tag
    const isEnabledParentalControl = useMemo(
      () => or(isRestricted, isEnabled),
      [isRestricted, isEnabled],
    )

    const {
      handleRestrictedUnlock,
      handleInputPinCodeModal,
      handleClosePinCodeModal,
      isVisiblePinCodeModal,
      pinCodeModalMeta,
    } = useRestrictedContent({
      isEnabledParentalControl,
      pinCode,
      defaultUnlocked: false,
    })

    const pinCodeDescription = useMemo(
      () => prop('pinCodeDescription', pinCodeModalMeta),
      [pinCodeModalMeta],
    )
    const errorMessage = useMemo(() => prop('errorMessage', pinCodeModalMeta), [pinCodeModalMeta])

    const posterChannel = useMemo(() => (isRestricted ? lockedCard : src), [isRestricted, src])
    const posterMovie = useMemo(() => (isRestricted ? lockedPoster : src), [isRestricted, src])

    const posters = useMemo(
      () => ({
        [ENTITY_TYPE.LIVE_TV]: posterChannel,
        [ENTITY_TYPE.RADIO]: posterChannel,
        [ENTITY_TYPE.MOVIE]: posterMovie,
        [ENTITY_TYPE.TV_SHOW]: posterMovie,
      }),
      [posterChannel, posterMovie],
    )

    const image = useMemo(() => posters[type], [posters, type])

    const handlePress = useCallback(
      (item) => {
        if (and(equals(isLive, false), equals(isPlayable, false))) return

        if (isRestricted) {
          handleRestrictedUnlock(() => {
            onPress(item)
          })
        } else {
          onPress(item)
        }
      },
      [handleRestrictedUnlock, isLive, isPlayable, isRestricted, onPress],
    )

    const handleLongPress = useCallback(() => {
      if (and(isRestricted, onLongPress)) {
        handleRestrictedUnlock(() => {
          onLongPress()
        })
      } else {
        onLongPress()
      }
    }, [handleRestrictedUnlock, isRestricted, onLongPress])

    const handleSwitchChannel = useCallback(() => {
      if (not(isCardHorizontal)) return null

      if (and(isRestricted, not(isActive))) {
        return ({ id }) =>
          handleRestrictedUnlock(() => {
            onSwitchChannel({ id })
            onCloseChannelsList()
          })
      }
      return onSwitchChannel
    }, [
      isCardHorizontal,
      isRestricted,
      isActive,
      onSwitchChannel,
      handleRestrictedUnlock,
      onCloseChannelsList,
    ])

    const handleSubmit = useCallback(
      (values) => {
        const pin = prop('pin', values)
        handleInputPinCodeModal(pin)
      },
      [handleInputPinCodeModal],
    )

    const handleCancel = useCallback(
      (onCallback) => {
        handleClosePinCodeModal()

        if (isCardHorizontal) {
          onCloseChannelsList()
          return
        }

        if (and(onCallback, focusKey)) onCallback(focusKey)
      },
      [focusKey, handleClosePinCodeModal, isCardHorizontal, onCloseChannelsList],
    )

    return (
      <>
        <Component
          type={type}
          src={image}
          focusKey={focusKey}
          isActive={isActive}
          isPlayable={isPlayable}
          isLive={isLive}
          onPress={handlePress}
          onLongPress={onLongPress && handleLongPress}
          onEnterPress={handlePress}
          isRestricted={isRestricted}
          onSwitchChannel={handleSwitchChannel}
          {...props}
        />

        {isVisiblePinCodeModal &&
          ReactDOM.createPortal(
            <EnterPinView
              subTitle={pinCodeDescription}
              errorMessage={errorMessage}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
            />,
            document.getElementById('root'),
          )}
      </>
    )
  }

export default withParentalControl
