// @flow

import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { rgba } from 'polished'

import { getColorPrimary } from '@alphaott/smart-tv-themes'

const getBorderColor = (props) => {
  if (props.$focused) {
    return getColorPrimary(props)
  }

  return rgba(getColorPrimary(props), 0.6)
}

export const Container = styled(NavLink)`
  font-weight: 700;
  font-size: 36rem;
  line-height: 43rem;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  padding-bottom: 8rem !important;
  border-bottom: 6rem solid ${(props) => (props.$focused ? getColorPrimary(props) : 'transparent')};

  &[aria-current] {
    border-bottom-color: ${getBorderColor} !important;
  }
`
