// @flow
import typeToReducer from 'type-to-reducer'

import { INIT_MAIN_TYPE, GEOBLOCKED_ERROR_TYPE } from '../../actions/init'

export type InitState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
}

const initialState: InitState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: null,
}

const init = typeToReducer(
  {
    [INIT_MAIN_TYPE]: {
      LOADING: (state): InitState => ({
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        errors: null,
      }),

      SUCCESS: (state): InitState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        errors: null,
      }),

      ERROR: (state, action): InitState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload,
      }),
    },

    [GEOBLOCKED_ERROR_TYPE]: (state, { error }) => ({
      ...state,
      isLoading: false,
      isSuccess: false,
      isError: true,
      errors: error,
    }),
  },
  initialState,
)

export { init }
