import styled from 'styled-components'

import { ArrowsIcon as Arrows } from '../../assets'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 98rem;
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(15, 15, 15, 0.8);
  z-index: 1;
`

export const Prompt = styled.div`
  display: flex;
  align-items: center;
  height: 45rem;
  font-weight: 700;
  font-size: 28rem;
  color: #f8f4f4;
  margin-right: 49rem;
`

export const ArrowsIcon = styled(Arrows)`
  margin-right: 8rem;
`
