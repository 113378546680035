//  @flow
import { BROWSER, DRM_FOR_BROWSER } from './const'
import { IS_SAFARI, IS_CHROME, IS_EDGE, IS_FIREFOX, IS_IE } from './detectBrowser'

// eslint-disable-next-line complexity
export const getSupportedDrm = () => {
  let browser

  if (IS_SAFARI) browser = BROWSER.SAFARI
  if (IS_CHROME) browser = BROWSER.CHROME
  if (IS_EDGE) browser = BROWSER.EDGE
  if (IS_FIREFOX) browser = BROWSER.FIREFOX
  if (IS_IE) browser = BROWSER.IE

  return browser ? DRM_FOR_BROWSER[browser] : []
}

export default getSupportedDrm
