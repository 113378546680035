// @flow

import { isPossiblePhoneNumber } from 'libphonenumber-js'

type ValidationFunc<T> = (value: T) => void | string

const isValid: void = undefined

const REQUIRED_MESSAGE = 'Required'

export const isRequired: ValidationFunc<any> = value => (value ? isValid : REQUIRED_MESSAGE)

export const isStrictRequired: ValidationFunc<any> = value => (value && /\S/.test(value) ? isValid : REQUIRED_MESSAGE)

/* eslint-disable no-confusing-arrow */
export const minLength: Function = (min: number) => value =>
  value && value.length < min ? `Password should be ${min} symbols at least. ` : isValid

/* eslint-disable no-confusing-arrow */
export const isEmail: ValidationFunc<string> = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(value) ? 'Invalid email address' : isValid

/* eslint-disable no-confusing-arrow */
export const isPhone: ValidationFunc<string> = value =>
  value && !/^\+?[\d+ ()-]*$/g.test(value) ? 'Invalid phone number' : isValid

export const isPossiblePhone: ValidationFunc<string> = (value: string) =>
  isPossiblePhoneNumber(value) ? isValid : 'Invalid phone number'

export const isPossiblePhoneOrValidEmail: ValidationFunc<string> = (value: string) => {
  const isPhoneCheck = /^\+?[\d+ ()-]*$/g.test(value)

  return isPhoneCheck ? isPossiblePhone(value) : isEmail(value)
}

// on mobile we currently don't have masks, so this checks are need to be done manually
export const isVoucherGoodLength: ValidationFunc<string> = value =>
  value && value.length === 19 ? isValid : 'Voucher code should be 19 characters'
export const isVoucherPattern = (value: string) =>
  value && /(([a-zA-Z0-9]{4})-{1}){3}[a-zA-Z0-9]{4}/gm.test(value)
    ? isValid
    : 'Voucher code should match XXXX-XXXX-XXXX-XXXX pattern, and contain only english characters or numbers'

// on desktop we have mask which already does many checks
export const isMaskedVoucher = (value: string) =>
  value && !/(([a-zA-Z0-9]{4})-?){4}/gm.test(value) ? 'Invalid Activation Code' : isValid

export const composeValidators = (validators: Function[]) => (value: string | number) =>
  validators.reduce((error, validator) => error || validator(value), undefined)

export const isOtp: ValidationFunc<string> = value =>
  value && !/([a-zA-Z0-9]){6}/gm.test(value) ? 'Invalid OTP' : isValid

export const isPIN: ValidationFunc<string> = value => (value && !/([0-9]){4}/gm.test(value) ? 'Invalid PIN' : isValid)
