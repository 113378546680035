// @flow

import ellastvmaxLogoSvg from '../ellastvmax/assets/logo.svg'
import ellastvmaxAuthBg from '../ellastvmax/assets/authBg.png'
import ellastvmaxauBrandConfig from './brand_config.json'

import { themeEllastvmax } from '../ellastvmax'

export const EllastvmaxauTheme = {
  styledTheme: themeEllastvmax,
  logo: ellastvmaxLogoSvg,
  authBg: ellastvmaxAuthBg,
  brandConfig: ellastvmaxauBrandConfig,
  extra: {
    auth: {
      logoHeight: 76,
    },
    logoHeight: 76,
  },
}
