// @flow

import typeToReducer from 'type-to-reducer'
import { map, pathOr } from 'ramda'

import { isValidTTL, calculateExpires } from '@alphaott/app-main/selectors'
import { prepareMovieImages } from '@alphaott/common-utils/utils/schemas/movies'
import type { Movies } from '@alphaott/api-client/types/movies'

import { FETCH_FILTERED_MOVIES } from '../../actions/filters'

export type FilteredMoviesListState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +hasMore: boolean,
  +data: Movies,
  +expires: ?number,
}

const initialState: FilteredMoviesListState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
  hasMore: true,
  expires: null,
}

export type MovieFiltersState = {
  +data: {
    [key: string]: FilteredMoviesListState,
  },
}

const initialMovieFiltersState: MovieFiltersState = {
  data: {},
}

const movieFilters = typeToReducer(
  {
    [FETCH_FILTERED_MOVIES]: {
      LOADING: (state, action): FilteredMoviesListState => {
        const {
          meta: { storeKey },
        } = action
        return {
          ...state,
          data: {
            ...state.data,
            [storeKey]: {
              ...(state.data[storeKey] || initialState),
              isLoading: true,
            },
          },
        }
      },

      // eslint-disable-next-line complexity
      SUCCESS: (state, action): FilteredMoviesListState => {
        const {
          payload: { data = [] },
          meta: { contentApi, storeKey, resetData, ttl },
        } = action
        const previousData = pathOr([], ['data', storeKey, 'data'], state)
        const preparedMovies = map(movie => prepareMovieImages(movie, contentApi))(data)
        const movies = resetData ? preparedMovies : [...previousData, ...preparedMovies]

        return {
          ...state,
          data: {
            ...state.data,
            [storeKey]: {
              ...(state.data[storeKey] || initialState),
              isLoading: false,
              isSuccess: true,
              hasMore: data.length > 0,
              data: movies,
              ...(isValidTTL(ttl) && { expires: calculateExpires(ttl) }),
            },
          },
        }
      },

      ERROR: (state, action): FilteredMoviesListState => {
        const {
          payload: { error },
          meta: { storeKey },
        } = action
        return {
          ...state,
          data: {
            ...state.data,
            [storeKey]: {
              ...(state.data[storeKey] || initialState),
              isLoading: false,
              isSuccess: false,
              isError: true,
              errors: error,
              expires: null,
            },
          },
        }
      },
    },
  },
  initialMovieFiltersState,
)

export { movieFilters }
