// @flow
import { LARGE_VIEWPORT, MEDIUM_VIEWPORT, SMALL_VIEWPORT } from '@alphaott/app-config'
import { rgba } from 'polished'

import { colorPalette } from '../colorPalettes'
import type { ColorType } from '../colorPalettes'

export const ittvColors: ColorType = { ...colorPalette, primary: '#F60030', secondary: '#F60030' }

export const lightTheme = {
  colors: ittvColors,
  background: {
    primary: '#fafafa',
    secondary: '#fafafa',
    highlight: ittvColors.light,
    select: '#fafafa',
    footerTop: '#eee',
  },
  text: {
    primary: ittvColors.dark,
    secondary: ittvColors.grayText,
    disabled: ittvColors.dark,
    link: ittvColors.dark,
    hint: ittvColors.dark,
    highlight: ittvColors.primary,
    gray: ittvColors.grayText,
    seeAll: 'rgba(52, 73, 94, 0.8)',
    primaryContrast: '#ffffff',
  },
  border: {
    primary: '#dddddd',
    gray: '#ddd',
  },
  filter: {
    primary: '#43484d',
    secondary: '#43484d',
    disabled: ittvColors.grayLabel,
    highlight: '#fff',
  },
  tabs: {
    bg: '#fafafa',
    containerBg: ittvColors.dark,
    color: ittvColors.dark,
    border: ittvColors.primary,
    scrollbarThumb: ittvColors.primary,
    programTitle: ittvColors.white,
  },
  programGuide: {
    programBox: {
      background: {
        main: 'rgba(221, 221, 221, 0.8)',
        hover: ittvColors.dark,
        selected: ittvColors.dark,
        progress: rgba(ittvColors.primary, 0.3),
        past: rgba(ittvColors.primary, 0.3),
        pastSelected: ittvColors.dark,
        pastHover: ittvColors.dark,
        empty: 'rgba(221, 221, 221, 0.3)',
        emptyProgress: rgba(ittvColors.primary, 0.1),
      },
      text: {
        empty: ittvColors.grayLabel,
      },
    },
    programGuidePictureOverlay: {
      horizontal:
        // eslint-disable-next-line max-len
        `linear-gradient(90deg, #fafafa 0%,${rgba('#fafafa', 0.97)} 13.03%,${rgba('#fafafa', 0.69)} 43.24%,${rgba(
          '#fafafa',
          0,
        )} 100%);`,
      vertical: `linear-gradient(0,#fafafa 0%,${rgba('#fafafa', 0.73)} 34.97%,${rgba('#fafafa', 0)} 100%);`,
    },
  },
  card: {
    title: ittvColors.dark,
    subTitle: ittvColors.grayText,
    bg: ittvColors.dark,
    number: ittvColors.grayBorder,
    boxShadow: 'rgba(221, 221, 221, 0.8)',
    loaderBackgroundImage: 'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
  },
  dimension: {
    signLogoSize: 160,
  },
  size: 'default',
  breakpoints: [`${SMALL_VIEWPORT}px`, `${MEDIUM_VIEWPORT}px`, `${LARGE_VIEWPORT}px`],
  signIn: {
    clipPath: false,
  },
}
