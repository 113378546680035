// @flow
import React, { memo } from 'react'
import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'

import { Button as ButtonBase } from './elements'

type ButtonProps = Object

const ButtonPure = ({ focusKey, onEnterPress, onClick, ...props }: ButtonProps) => {
  const { ref, focused, focusSelf } = useFocusable({ focusKey, onEnterPress: onClick })
  const { handleMouseOver } = useMouseOver(focusSelf)

  return (
    <ButtonBase
      {...props}
      ref={ref}
      focused={focused}
      onMouseOver={handleMouseOver}
      onClick={onClick}
    />
  )
}

export const Button = memo<ButtonProps>(ButtonPure)

export default Button
