// @flow

// eslint-disable-next-line import/no-extraneous-dependencies
import typeToReducer from 'type-to-reducer'

import { PROD_ENV } from '@alphaott/smart-tv-config'

import type { Device } from '@alphaott/api-client/types/devices'
import {
  getDeviceId as webDeviceId,
  getDeviceType as webDeviceType,
  getDeviceModel as webDeviceModel,
  getDevicePlatform as webDevicePlatform,
  getDeviceBrand as webDeviceBrand,
  getDeviceSerialNumber as webDeviceSerialNumber,
  getDeviceMacAddress as webDeviceMacAddress,
} from '@alphaott/app-devices/models/utils'

import { SET_DEVICE_INFO_TYPE } from '../actions'

import { getDeviceType, getDevicePlatform } from '../utils'

export type CurrentDeviceState = Device & {
  os?: string,
  osVersion?: string,
  wifiMac?: string,
  ethernetMac?: string,
  networkStatus?: string,
  visibilityStatus?: string,
}

const initialState: CurrentDeviceState = PROD_ENV
  ? {
      type: getDeviceType(),
      platform: getDevicePlatform(),
      serial: '',
      _id: '',
      model: '',
      brand: '',
      mac: '',
    }
  : {
      _id: webDeviceId(),
      type: webDeviceType(),
      model: webDeviceModel(),
      platform: webDevicePlatform(),
      brand: webDeviceBrand(),
      serial: webDeviceSerialNumber(),
      mac: webDeviceMacAddress(),
    }

const currentDevice: CurrentDeviceState = typeToReducer(
  {
    [SET_DEVICE_INFO_TYPE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState,
)

export { currentDevice }
