// @flow
import typeToReducer from 'type-to-reducer'
import { take, map, propOr, pathOr, propEq, descend, sortWith, prop, pipe, reject, prepend } from 'ramda'

import { RECENT_CHANNEL_MAX_COUNT } from '@alphaott/app-config'
import { UPDATE_RECENT_CHANNEL, GET_RECENT_CHANNELS } from '../../actions/channelRecentWatched'

type RecentChannel = {
  id: string,
  total: number,
}

export type RecentWatchedChannelsState = {|
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Array<RecentChannel>,
|}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
}

const channelRecentWatched = typeToReducer(
  {
    [GET_RECENT_CHANNELS]: {
      LOADING: (state): RecentWatchedChannelsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): RecentWatchedChannelsState => {
        const {
          payload: { data: response },
        } = action

        const channels = pipe(
          sortWith([descend(prop('ts'))]),
          map(elem => ({
            id: propOr('', 'item', elem),
            total: pathOr(0, ['data', 'total'], elem),
          })),
        )(response)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: take(RECENT_CHANNEL_MAX_COUNT, channels),
        }
      },

      ERROR: (state, action): RecentWatchedChannelsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: [],
        errors: action.payload.error,
      }),
    },
    [UPDATE_RECENT_CHANNEL]: {
      LOADING: (state): RecentWatchedChannelsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): RecentWatchedChannelsState => {
        const {
          payload: { data: response },
        } = action

        const newChannel = {
          id: propOr('', 'item', response),
          total: pathOr(0, ['data', 'total'], response),
        }

        const channels = pipe(
          reject(propEq('id', newChannel.id)),
          prepend(newChannel),
        )(state.data)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: channels,
        }
      },

      ERROR: (state, action): RecentWatchedChannelsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: [],
        errors: action.payload.error,
      }),
    },
  },
  initialState,
)

export { channelRecentWatched }
