// @flow

import typeToReducer from 'type-to-reducer'

import { SET_ACTIVE_SHOW_ID, SET_ACTIVE_SEASON_ID, SET_ACTIVE_EPISODE_ID, RESET_TVSHOW_DETAIL } from '../../actions'

export type CurrentMediaState = {|
  activeTVShow?: string,
  activeTVSeason?: string,
  activeTVEpisode?: string,
|}

const initialState: CurrentMediaState = {
  activeTVShow: undefined,
  activeTVSeason: undefined,
  activeTVEpisode: undefined,
}

const currentMedia = typeToReducer(
  {
    [RESET_TVSHOW_DETAIL]: () => ({ ...initialState }),
    [SET_ACTIVE_SHOW_ID]: (state, action): CurrentMediaState => ({ ...initialState, activeTVShow: action.payload.id }),
    [SET_ACTIVE_SEASON_ID]: (state, action): CurrentMediaState => ({
      ...state,
      activeTVSeason: action.payload.id,
    }),
    [SET_ACTIVE_EPISODE_ID]: (state, action): CurrentMediaState => ({
      ...state,
      activeTVEpisode: action.payload.id,
    }),
  },
  initialState,
)

export { currentMedia }
