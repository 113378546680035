// @flow
import { makeGetRequest } from '../utils'

import type { ResponseAPI } from '../types/api'
import type { Movies } from '../types/movies'

export type MoviesListQuery = {|
  projection?: 'brief' | '',
  limit?: number,
  skip?: number,
  title?: string,
  plot?: string,
  year?: string,
  languages?: string,
  countries?: string,
  genres?: string,
  categories?: string,
  studios?: string,
  sort?: 'rating' | '-rating' | 'year' | '-year' | 'created' | '-created',
|}

export const getMoviesList = (
  domain: string,
  serviceId: string,
  query?: MoviesListQuery,
): Promise<ResponseAPI<Movies>> =>
  makeGetRequest(`${domain}/client/api/servicespec/${serviceId}/movies/`, { data: query })
