// @flow

import alphaottLogoSvg from '../alphaott/assets/logo.svg'
import alphaottAuthBg from '../alphaott/assets/authBg.png'
import alphaottBrandConfig from './brand_config.json'

import { themeAlphaott } from '../alphaott'

export const AlphaTestTheme = {
  styledTheme: themeAlphaott,
  logo: alphaottLogoSvg,
  authBg: alphaottAuthBg,
  brandConfig: alphaottBrandConfig,
  extra: {
    auth: {
      logoHeight: 52,
    },
    logoHeight: 52,
  },
}
