// @flow

import { always, cond, equals, T } from 'ramda'

const firstStep = 'signup'
const secondStep = 'profile'
const thirdStep = 'success'
const billingStep = 'billing'
const billingOrderStep = 'order'

const mapStepSuffixToValue = {
  [firstStep]: 0,
  [secondStep]: 1,
  [thirdStep]: 2,
  [billingStep]: 2,
  [billingOrderStep]: 2,
}

export const mapRouteWithStep = cond([
  [equals(firstStep), always(mapStepSuffixToValue[firstStep])],
  [equals(secondStep), always(mapStepSuffixToValue[secondStep])],
  [equals(thirdStep), always(mapStepSuffixToValue[thirdStep])],
  [equals(billingStep), always(mapStepSuffixToValue[billingStep])],
  [equals(billingOrderStep), always(mapStepSuffixToValue[billingOrderStep])],
  [T, always(mapStepSuffixToValue[firstStep])],
])
