// @flow

import langmap from 'langmap'
import { path, includes } from 'ramda'

const LOCALE_BLACK_LIST = ['und']

const getNativeName = language => {
  if (language) {
    const nativeName = path([language, 'nativeName'], langmap)
    if (nativeName) return nativeName
  }

  return null
}

// eslint-disable-next-line complexity
export const getLanguageName = (number: number, language: ?string, track: Object) => {
  const defaultValue = `Track ${number}`

  if (language) {
    const nativeName = getNativeName(language)

    if (track.label) {
      // Returns magic string
      return `${track.label} ${nativeName ? `(${nativeName})` : ''}`
    }

    // Returns just language
    if (nativeName) return nativeName

    // Check locale in black list
    const localeInBlackList = includes(language, LOCALE_BLACK_LIST)

    // Returns default value if language in black list
    if (localeInBlackList) return defaultValue

    // Returns track.language
    return language
  }

  if (track.label) return track.label

  return defaultValue
}

export default getLanguageName
