// @flow

import React, {
  memo,
  useRef,
  useMemo,
  forwardRef,
  useCallback,
  useLayoutEffect,
  useImperativeHandle,
} from 'react'

import { useHttpForSourceLink } from '@alphaott/smart-tv-video-player/hooks'

import type { VideoPlayer } from '@alphaott/smart-tv-video-player'

import { ShakaPlayer } from '../components'
import { useShakaPlayerTracks, useShakaPlayerErrors } from '../hooks'
import { defaultConfig } from '../config'
import { prepareShakaPlayerSources } from '../../prepareShakaPlayerSource'

export type ShakaPlayerContainerProps = VideoPlayer & {
  shaka?: any,
  config: shaka.extern.PlayerConfiguration,
}

const ShakaPlayerContainerPure = (
  {
    shaka,
    config = defaultConfig,
    videoId,
    mediaStream,
    autoplay,
    defaultTimePosition,
    onLoadMeta,
    onCanPlay,
    onLoading,
    onSeeking,
    onSeeked,
    onPlay,
    onPause,
    onEnd,
    onTimeUpdate,
    onError,
    onChangeAudioTracks,
    onChangeSubtitleTracks,
    onDestroy,
  }: ShakaPlayerContainerProps,
  ref: any,
) => {
  const playerRef = useRef(null)

  const { isRequiredHttpScheme, onPrepareSources } = useHttpForSourceLink()
  const preparedMediaStream = useMemo(() => {
    const shakaMediaStream = prepareShakaPlayerSources(mediaStream)

    if (isRequiredHttpScheme) {
      return {
        ...shakaMediaStream,
        sources: onPrepareSources(shakaMediaStream?.sources) || [],
      }
    }

    return shakaMediaStream
  }, [isRequiredHttpScheme, mediaStream, onPrepareSources])

  const {
    preferredAudioLanguage,
    preferredSubtitleLanguage,
    onInitTracks,
    onChangeAudioTrack,
    onChangedAudioTrack,
    onChangeSubtitleTrack,
    onChangedSubtitleTrack,
    onChangedSubtitleTrackVisibility,
  } = useShakaPlayerTracks(videoId, playerRef, {
    onChangeAudioTracks,
    onChangeSubtitleTracks,
  })

  const { isIgnoredError, onPrepareError } = useShakaPlayerErrors(preparedMediaStream)

  const shakaConfig: shaka.extern.PlayerConfiguration = useMemo(
    () => ({
      ...config,
      preferredAudioLanguage,
      preferredTextLanguage: preferredSubtitleLanguage,
    }),
    [config, preferredAudioLanguage, preferredSubtitleLanguage],
  )

  const handleCanPlay = useCallback(() => {
    onCanPlay()

    if (autoplay) {
      playerRef.current?.onPlay()
    }
  }, [autoplay, onCanPlay])

  const handleLoaded = useCallback(() => {
    const time = playerRef.current?.getTime()

    const { audioTracks, subtitleTracks } = onInitTracks()

    onLoadMeta({
      time,
      audioTracks,
      subtitleTracks,
    })

    handleCanPlay()
  }, [onInitTracks, onLoadMeta, handleCanPlay])

  const handleTimeUpdate = useCallback(() => {
    const time = playerRef.current?.getTime()

    if (time) {
      onTimeUpdate(time)
    }
  }, [onTimeUpdate])

  const handleError = useCallback(
    // eslint-disable-next-line consistent-return
    (err) => {
      const error = onPrepareError(err)

      // Solution to ignore error
      if (isIgnoredError(error)) {
        return null
      }

      onError(error)
    },
    [isIgnoredError, onError, onPrepareError],
  )

  const handleDestroy = useCallback(() => {
    const time = playerRef.current?.getTime()

    onDestroy(time)
  }, [onDestroy])

  useImperativeHandle(
    ref,
    () => ({
      ...playerRef.current,
      onChangeAudioTrack,
      onChangeSubtitleTrack,
    }),
    [onChangeAudioTrack, onChangeSubtitleTrack],
  )

  useLayoutEffect(
    () => () => {
      handleDestroy()
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <ShakaPlayer
      ref={playerRef}
      shaka={shaka}
      config={shakaConfig}
      mediaStream={preparedMediaStream}
      defaultTimePosition={defaultTimePosition}
      // Event callbacks
      onLoading={onLoading}
      onLoaded={handleLoaded}
      onCanPlay={() => {}}
      onPlay={onPlay}
      onSeeking={onSeeking}
      onSeeked={onSeeked}
      onPause={onPause}
      onEnd={onEnd}
      onTimeUpdate={handleTimeUpdate}
      onError={handleError}
      onChangedAudioTrack={onChangedAudioTrack}
      onChangedSubtitleTrack={onChangedSubtitleTrack}
      onChangedSubtitleTrackVisibility={onChangedSubtitleTrackVisibility}
    />
  )
}

export const ShakaPlayerContainer = memo<ShakaPlayerContainerProps>(
  forwardRef(ShakaPlayerContainerPure),
)

export default ShakaPlayerContainer
