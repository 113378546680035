// @flow

import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6rem;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledImage = styled.img`
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  position: absolute;
`

const placeholderShimmer = keyframes`
  0% { background-position: -468px 0; }
  100% { background-position: 468px 0; }
`

export const Loader = styled.div`
  background-color: ${(props) => props.theme.palette.grey[900]};
  background-image: linear-gradient(to right, #222 0%, #111 20%, #222 40%, #222 100%);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  position: absolute;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeholderShimmer};
  animation-timing-function: linear;
  width: 100%;
  height: 100%;
  padding-top: calc(447 / 300 * 100%);
`
