// @flow
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentTime, useCurrentDate } from '@alphaott/smart-tv-common'

import { HeaderTitle, HeaderTitleContainer, HeaderContainer, HeaderDate } from './Header.style'

export const Header = () => {
  const { t } = useTranslation()
  const currentDate = useCurrentDate()
  const currentTime = useCurrentTime()

  return (
    <HeaderContainer>
      <HeaderTitleContainer>
        <HeaderTitle>{t('program_guide_title')}</HeaderTitle>
        <HeaderTitle>{currentTime}</HeaderTitle>
      </HeaderTitleContainer>
      <HeaderDate>{currentDate}</HeaderDate>
    </HeaderContainer>
  )
}
