// @flow

import React, { useEffect, useState, useRef } from 'react'

import { PageLoader } from '@alphaott/smart-tv-components'

import configureI18n from '../core/i18n'

export const withI18Next =
  (Component: any) =>
  // eslint-disable-next-line react/display-name
  ({ theme, ...props }: Object) => {
    const i18n = useRef(null)

    const [isConfiguredI18Next, setIsConfiguredI18Next] = useState(false)

    // eslint-disable-next-line complexity
    useEffect(() => {
      if (!isConfiguredI18Next) {
        const { defaultLanguage, languageResources } = theme?.brandConfig
        const availableLanguageKeys = languageResources.map((item) => item.key)

        i18n.current = configureI18n({
          lng: localStorage.getItem('i18nextLng') || defaultLanguage,
          supportedLngs: availableLanguageKeys.length > 0 ? availableLanguageKeys : null,
        })

        setIsConfiguredI18Next(true)
      }
    }, [theme.brandConfig]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <PageLoader isLoading={!isConfiguredI18Next}>
        <Component {...props} theme={theme} />
      </PageLoader>
    )
  }

export default withI18Next
