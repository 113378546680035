// @flow
import { useEffect } from 'react'
import { useAnalytics } from 'use-analytics'
import { useSelector } from 'react-redux'
import { and } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'

import { isAppAnalyticsEnabled } from '@alphaott/app-main/models/selectors'

export const usePageAnalytics = (history: any) => {
  const analytics = useAnalytics()

  const isAnalyticsEnabled = useSelector(isAppAnalyticsEnabled)

  useEffect(() => {
    if (isAnalyticsEnabled) {
      if (and(isNotNilOrEmpty(analytics), isNotNilOrEmpty(history))) {
        // For the first page load
        analytics.page()

        history.listen(() => {
          analytics.page()
        })
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
