// @flow
import { map } from 'ramda'
import typeToReducer from 'type-to-reducer'

import type { TVShow } from '@alphaott/api-client/types/tvshows'

import { prepareTVShowImages } from '@alphaott/common-utils/utils/schemas/tvshows'
import { FETCH_SEARCH_TVSHOWS } from '../actions'

export type TVShowsSearchListState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +hasMore: boolean,
  +errors: any,
  +data: TVShow[],
}

const initialState: TVShowsSearchListState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  hasMore: true,
  errors: [],
  data: [],
}

const tvshowsSearchList = typeToReducer(
  {
    [FETCH_SEARCH_TVSHOWS]: {
      LOADING: (state): TVShowsSearchListState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): TVShowsSearchListState => {
        const { payload, meta } = action
        const { data } = payload
        const baseData = meta.resetData ? [] : state.data
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          hasMore: data.length > 0,
          data: [...baseData, ...map(tvshow => prepareTVShowImages(tvshow, meta.contentApi))(data)],
        }
      },

      ERROR: (state, action): TVShowsSearchListState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
  },
  initialState,
)

export { tvshowsSearchList }
