// @flow weak
/* eslint-disable camelcase */
import { equals, not, last, init } from 'ramda'

import refreshToken from '../refreshToken'
import { is401 } from './makeResponse'

import type { Token } from '../types/token'

export const isTokenExpired = (expires: number): boolean => {
  const now = new Date().getTime()
  return now >= expires
}

export const isEqualParamLength = (wrapFunction, args) => equals(wrapFunction.length, args.length)

export type PrivateRequestArgs = {|
  domain: string,
  onRefreshToken: Token => any,
  client_id: string,
  client_secret: string,
  refresh_token: string,
  access_token: string,
  token_expires: number,
|}

type RequestFnType = () => Promise<any>

/*
// Example:

const tokenState = {
  access: 'eyJ0eXAiOiJKV1',
  type: 'JWT,
  refresh: 'JWT:eyJ0eXAiOi',
  expires: 1517335200000,
}

const onRefreshToken = (newToken) => console.log('new token received:', newToken)
const customers = await privateRequest({
    client_id: 'Ok8Bc3fsJ4Dg0dh6bDH9bd8wg1gcet',
    client_secret: 'PodF2Bfe4D6Bfbdi4uYt6ee9DURIfy7IguIgRd',
    refresh_token: tokenState.refresh,
    token_expires: tokenState.expires,
    access_token: tokenState.access,
    onRefreshToken,
  })(getCustomer)('/customer')
*/

/* eslint-disable complexity */
export const privateRequest = (fn: RequestFnType) => ({
  domain,
  onRefreshToken,
  client_id,
  client_secret,
  refresh_token,
  access_token,
  token_expires,
}: PrivateRequestArgs) => async (...args) => {
  /**
   * Expected that `wrapperFunction - fn` has next signature
   * fn(domain, ...positionalArgs, options)
   *
   * Expected that `args` has next structure
   * args = [...positionalArgs, { data, ... }] || [...positionalArgs]
   */
  const options = isEqualParamLength(fn, args) ? last(args) : {}
  const positionalArgs = isEqualParamLength(fn, args) ? init(args) : args

  /**
   * Options should be an Object
   */
  if (not(equals(typeof options, 'object'))) {
    throw new TypeError('Options should be an Object')
  }

  const refreshTokenOptions = {
    grant_type: 'refresh_token',
    refresh_token,
    client_id,
    client_secret,
  }

  const refreshTokenAndRetry = async () => {
    const response = await refreshToken(domain, refreshTokenOptions)

    if (response.status === 200) {
      const { data: newToken } = response
      onRefreshToken(newToken)
      return fn(...positionalArgs, { ...options, token: `JWT ${newToken.access_token}` })
    }

    throw response
  }

  if (isTokenExpired(token_expires)) {
    return refreshTokenAndRetry()
  }

  try {
    return await fn(...positionalArgs, { ...options, token: `JWT ${access_token}` }) // eslint-disable-line camelcase
  } catch (err) {
    if (is401(err)) return refreshTokenAndRetry()

    throw err
  }
}

export default privateRequest
