// @flow
import { createSelector } from 'reselect'
import { prop, pipe, head } from 'ramda'

import { defaultToEmptyArray, defaultToEmptyObject, defaultToZero } from '@alphaott/common-utils/utils/help'
import type { CatchupMediaStream } from '@alphaott/api-client/types/catchup/sources'
import type { AspectRatio } from '@alphaott/app-channels/selectors'

import type { CatchupMediaStreamsData, CatchupSourcesState } from '../reducers'
import type { CatchupStore } from '../types'

export const getCatchupSourcesState = (state: CatchupStore): CatchupSourcesState => state.catchupSources

export const getCatchupMediaStreams = (state: CatchupStore): CatchupMediaStreamsData => state.catchupSources.data

// TODO: Get appropriate mediaStream type
export const getCatchupMediaStream = (programId: string) =>
  createSelector(
    [getCatchupMediaStreams],
    (sources: CatchupMediaStreamsData): CatchupMediaStream | {} =>
      pipe(
        prop(programId),
        defaultToEmptyArray,
        head,
        defaultToEmptyObject,
      )(sources),
  )

export const getCatchupAspectRatio = (programId: string) =>
  createSelector(
    [getCatchupMediaStream(programId)],
    // eslint-disable-next-line no-confusing-arrow
    (mediaStream: CatchupMediaStream): AspectRatio => {
      const ratio = pipe(
        prop('screenRatio'),
        defaultToZero,
      )(mediaStream)
      return ratio ? { aspectRatio: `${Math.round(1000 * ratio)}:${1000}` } : {}
    },
  )
