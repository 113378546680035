// @flow

import typeToReducer from 'type-to-reducer'

import { CHANGE_PLAYER_CONTROLS_STATE_TYPE, RESET_PLAYER_STATE_TYPE } from '../actions/constants'

export type PlayerControlsState = {
  +isForceShowControls: boolean,
  +isShowControls: boolean,
  +isShowSubtitlesSettings: boolean,
  +isShowListControl: boolean,
}

const initialState: PlayerControlsState = {
  isForceShowControls: true,
  isShowControls: true,
  isShowSubtitlesSettings: false,
  isShowListControl: false,
}

const playerControls = typeToReducer(
  {
    [CHANGE_PLAYER_CONTROLS_STATE_TYPE]: (state, action): PlayerControlsState => ({
      ...state,
      ...action.payload,
    }),
    [RESET_PLAYER_STATE_TYPE]: () => initialState,
  },
  initialState,
)

export { playerControls }
