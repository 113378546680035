// @flow
import { makeGetRequest, prepareGetParam } from '../utils'

import type { ResponseAPI } from '../types/api'
import type { AuthMethods } from '../types/authMethods'
import type { Device } from '../types/devices'

export const createConfigPath = (domain: string, data: Device): string =>
  `${domain}/client/auth/methods?${prepareGetParam({ device: data })}`

export const getAuthMethods = (domain: string, data: Device): Promise<ResponseAPI<AuthMethods>> =>
  makeGetRequest(createConfigPath(domain, data))
