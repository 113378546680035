// @flow
import { equals, findIndex, not, filter, gte, multiply } from 'ramda'
import { format, isWithinRange, subMinutes, isAfter as isDateAfter, parse, differenceInSeconds } from 'date-fns'

import type { Programs, Program } from '@alphaott/api-client/types/programs'
import type { CurrentEpgIndexes, PreparedPrograms } from '../selectors'

export type DateType = string | number | Date

export const getPrevIndex = (index: number): number => (index - 1 >= 0 ? index - 1 : 0)

export const getEpgByNow = (programs: Programs): CurrentEpgIndexes => {
  const current = new Date()
  const index = findIndex(program => isWithinRange(current, program.start, program.stop), programs)
  if (equals(index, -1)) return {}
  return {
    current: index,
    next: index + 1,
  }
}

export const getIsNewDay = (item: Program): boolean => not(equals(format(item.start, 'DDD'), format(item.stop, 'DDD')))

export const preparePrograms = (programs: Programs): PreparedPrograms =>
  // $FlowFixMe
  programs.map((item: Program, index: number) => ({
    ...item,
    isNewDay: getIsNewDay(programs[getPrevIndex(index)]),
  }))

export const inCatchupInterval = (programStart: number, programStop: number, catchupWindow: number): boolean => {
  const now = new Date()
  const isOnline = isWithinRange(now, programStart, programStop)
  // Convert seconds to minutes - Math.floor(catchupWindow / 60)
  return isWithinRange(programStart, subMinutes(now, Math.floor(catchupWindow / 60)), now) && !isOnline
}
export const isAfter = (program: Program): boolean => isDateAfter(parse(program.stop), parse(program.start))

export const getFilteredPrograms = (programs: Programs): Programs => {
  const filteredPrograms = filter(isAfter, programs)
  return filteredPrograms
}

export const diffInSeconds = (fromDate: DateType, toDate: DateType): number => differenceInSeconds(toDate, fromDate)

export const getPercent = (max: number, amount: any): number => {
  if (gte(amount, max)) return 100
  return multiply(amount, 100) / max || 0
}

export const preparePercent = (current: DateType, total: number) => getPercent(total, current)
