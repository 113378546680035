// @flow

import { getDeviceType } from '../utils'
import { PLATFORMS } from '../const'
import * as tizen from './tizen'
import * as web from './web'
import * as webos from './webos'

export const platforms = {
  [PLATFORMS.WEB]: web,
  [PLATFORMS.TIZEN]: tizen,
  [PLATFORMS.WEBOS]: webos,
}

export const useInitPlatform = () => {
  const type = getDeviceType()
  const platform = platforms[type]
  platform.useInit()
}

export const useExitApp = () => {
  const type = getDeviceType()
  const platform = platforms[type]
  return platform.useExit
}

export default useInitPlatform
