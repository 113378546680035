// @flow

export const getPlayerEvents = (props) => [
  ['loadedmetadata', props.onLoaded],
  ['loadstart', props.onLoading],
  ['canplay', props.onCanPlay],
  ['play', props.onPlay],
  ['timeupdate', props.onTimeUpdate],
  ['seeking', props.onSeeking],
  ['seeked', props.onSeeked],
  ['pause', props.onPause],
  ['ended', props.onEnd],
  // This is necessary to avoid blank screen when switching channels;
  // ['error', props.onError],
  ['retryplaylist', props.onError],
  ['variantchanged', props.onChangedAudioTrack],
  ['textchanged', props.onChangedSubtitleTrack],
]

export const addListeners = (target: any, listeners: any) => {
  listeners.forEach(([event, listener]) => {
    if (event === 'retryplaylist') {
      return target.tech().on(event, listener)
    }

    return target.on(event, listener)
  })
}

export const removeListeners = (target: any, listeners: string[]) => {
  listeners.forEach(([event, listener]) => {
    if (event === 'retryplaylist') {
      return target.tech().off(event, listener)
    }

    return target.off(event, listener)
  })
}

export const getActions = (videoRef: any, playerRef: any, options?: Object) => ({
  // eslint-disable-next-line no-param-reassign,no-return-assign
  onSeek: (time) => (videoRef.current.currentTime = time),

  onPlay: () => videoRef.current?.play(),
  onPause: () => videoRef.current?.pause(),

  onRetryStreaming: () => playerRef.current?.src(options?.sources),

  getTime: () => ({
    currentTime: videoRef.current?.currentTime,
    duration: videoRef.current?.duration,
  }),

  getAudioTracks: () => playerRef.current?.audioTracks(),
  onChangeAudioTrack: (track) => {
    // eslint-disable-next-line no-param-reassign
    track.enabled = true

    return track
  },

  getSubtitleTracks: () => playerRef.current?.textTracks(),
  onChangeSubtitleTrack: (track, tracks) => {
    tracks.forEach((item: Object) => {
      // eslint-disable-next-line no-param-reassign
      item.mode = 'disabled'
    })

    if (track) {
      // eslint-disable-next-line no-param-reassign
      track.mode = 'showing'

      return track
    }

    return null
  },

  on: (event, listener) => playerRef.current?.on(event, listener),
  trigger: (event) => playerRef.current?.trigger(event),

  getError: () => playerRef.current?.error(),
})
