// @flow

import React, { memo, forwardRef } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'

import { ScrollbarComponent } from './Scrollbar.style'

const ScrollbarPure = ({ className, children }, ref) => (
  <AutoSizer>
    {({ width, height }) => (
      <ScrollbarComponent className={className} ref={ref} style={{ width, height }}>
        {children}
      </ScrollbarComponent>
    )}
  </AutoSizer>
)

export const Scrollbar = memo(forwardRef(ScrollbarPure))

export default Scrollbar
