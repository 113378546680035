// @flow
import { getCountries } from '@alphaott/api-client/countries'
import { getPublicDomain } from '@alphaott/app-main/selectors'
import { isNotEmpty } from '@alphaott/common-utils'

import { getCountries as getCountryList } from '../../selectors'

export const FETCH_COUNTRIES_TYPE = 'countries/FETCH'

export const fetchCountries = (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_COUNTRIES_TYPE,
    payload: getCountries(getPublicDomain(getState())),
  })

export const fetchAppCountriesAction = (force?: boolean = false) => (dispatch: Function, getState: Function) => {
  if (force) return dispatch(fetchCountries)

  const countries = getCountryList(getState())

  if (isNotEmpty(countries)) return null

  return dispatch(fetchCountries)
}

export const fetchAppCountries = fetchAppCountriesAction(false)
