// @flow
import typeToReducer from 'type-to-reducer'
import { initialDefaultControlLevel, initialPinCode } from '../../const'
import { UPDATE_PARENTAL_PAGE_SETTINGS } from '../actions'

export type VideoSettings = {|
  defaultQuality: string | null,
  defaultAspectRatio: string | null,
|}

export type LanguageSettings = {|
  defaultAudioLanguage: string | null,
  defaultSubtitlesLanguage: string | null,
|}

export type ParentalSettings = {|
  enableParentalControls: boolean,
  parentalControlLevel: number,
  // TODO add secure store or move this private information to be
  pinCode: string,
|}

export type SettingsPageState = {
  +video: VideoSettings,
  +language: LanguageSettings,
  +parental: ParentalSettings,
}

const initialState: SettingsPageState = {
  video: {
    defaultAspectRatio: null,
    defaultQuality: null,
  },
  language: {
    defaultAudioLanguage: null,
    defaultSubtitlesLanguage: null,
  },
  parental: {
    enableParentalControls: false,
    parentalControlLevel: initialDefaultControlLevel,
    pinCode: initialPinCode,
  },
}

export const settings = typeToReducer(
  {
    [UPDATE_PARENTAL_PAGE_SETTINGS]: (state, action): SettingsPageState => ({
      ...state,
      parental: {
        ...action.payload,
      },
    }),
  },
  initialState,
)

export default settings
