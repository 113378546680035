// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { getTextColorPrimary } from '@alphaott/smart-tv-themes'

import { ReactComponent as ConnectionLostComponent } from '../../assets/connectionLost.svg'

export const Background = styled(Flex)`
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
`

export const Container = styled.div`
  width: 704rem;
  padding: 44rem 68rem 62rem;
  color: ${getTextColorPrimary};
  border-radius: 8rem;
  background: rgba(32, 32, 32, 0.8);
  box-sizing: border-box;
`

export const Header = styled(Flex)`
  justify-content: center;
`

export const ConnectionLostIcon = styled(ConnectionLostComponent)`
  width: 62rem;
  height: 54rem;
`

export const Title = styled.div`
  font-size: 46rem;
  font-weight: 400;
  margin-left: 26rem;
`

export const Description = styled.div`
  font-size: 32rem;
  font-weight: 400;
  text-align: center;
  line-height: 45px;
  margin-top: 42rem;
`
