// @flow

import React, { memo, useEffect, useCallback, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { and, or } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'

import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'
import { getToken } from '@alphaott/app-main/selectors'
import { logout } from '@alphaott/app-auth/actions'
import { usePrevious } from '@alphaott/common-utils'
import { SCREEN_FOCUS_KEYS } from '@alphaott/smart-tv-config'

import { PopupDialog } from '../../Popup'
import {
  PageView,
  Container,
  Title,
  SubTitle,
  Code,
  ButtonsContainer,
  Button,
  LogoutButton,
} from './Error.style'

const ACTION_BUTTON_FOCUS_KEY = 'ActionButton'
const SCREEN_FOCUS_KEY = SCREEN_FOCUS_KEYS.ERROR

type ErrorComponentProps = {
  title: string,
  subTitle?: string,
  buttonLabel?: string,
  code?: string,
  isAvailableButtons?: boolean,
  isAvailableActionButton?: boolean,
  isAvailableLogoutButton?: boolean,
  onClickActionButton?: () => void,
}

// eslint-disable-next-line complexity
const ErrorComponentPure = ({
  title,
  subTitle,
  buttonLabel,
  code,
  isAvailableButtons,
  isAvailableActionButton,
  isAvailableLogoutButton,
  onClickActionButton,
}: ErrorComponentProps) => {
  const { ref, setFocus } = useFocusable()
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false)
  const prevIsLogoutDialogOpen = usePrevious(isLogoutDialogOpen)

  const token = useSelector(getToken)
  const isAuthenticated = useMemo(() => isNotEmpty(token), [token])

  const isAvailableButtonsContainer = useMemo(
    () => and(isAvailableButtons, or(isAvailableActionButton, isAvailableLogoutButton)),
    [isAvailableButtons, isAvailableActionButton, isAvailableLogoutButton],
  )
  const isShowLogoutButton = useMemo(
    () => and(isAvailableLogoutButton, isAuthenticated),
    [isAvailableLogoutButton, isAuthenticated],
  )

  const handleOpenLogoutDialog = useCallback(() => {
    setIsLogoutDialogOpen(true)
  }, [])

  const handleCloseLogoutDialog = useCallback(() => {
    setIsLogoutDialogOpen(false)
  }, [])

  const handleLogout = useCallback(() => dispatch(logout), [dispatch])

  const handleClickActionButton = useCallback(() => {
    if (onClickActionButton) onClickActionButton()
  }, [onClickActionButton])

  useEffect(() => {
    if (setFocus && isAvailableButtons) setFocus(ACTION_BUTTON_FOCUS_KEY)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (isAvailableButtons && !isLogoutDialogOpen && prevIsLogoutDialogOpen) {
      if (setFocus) setFocus(ACTION_BUTTON_FOCUS_KEY)
    }
  }, [isLogoutDialogOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageView focusKey={SCREEN_FOCUS_KEY} ref={ref}>
      <Container>
        <Title>{title}</Title>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}

        {isAvailableButtonsContainer && (
          <ButtonsContainer>
            {isAvailableActionButton && (
              <Button focusKey={ACTION_BUTTON_FOCUS_KEY} onClick={handleClickActionButton}>
                {buttonLabel || t('mwstv_error_support_page')}
              </Button>
            )}

            {isShowLogoutButton && (
              <LogoutButton onClick={handleOpenLogoutDialog}>{t('log_out')}</LogoutButton>
            )}
          </ButtonsContainer>
        )}

        {code && (
          <Code>
            {t('mwstv_error_error_code')}: {code}
          </Code>
        )}
      </Container>

      <PopupDialog
        title={t('log_out')}
        description={t('are_you_sure')}
        isOpen={isLogoutDialogOpen}
        focusable={isLogoutDialogOpen}
        onClose={handleCloseLogoutDialog}
        onApprove={handleLogout}
      />
    </PageView>
  )
}

ErrorComponentPure.defaultProps = {
  isAvailableButtons: true,
  isAvailableActionButton: true,
  isAvailableLogoutButton: true,
}

export const ErrorComponent = memo(ErrorComponentPure)

export default ErrorComponent
