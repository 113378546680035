// @flow

import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'

import { getTextColorPrimary } from '@alphaott/smart-tv-themes'

import { Avatar as AvatarBase } from '../Avatar'
import { EditButton as EditButtonBase } from './EditButton'

export const PROFILE_CARD_WIDTH = 200
export const PROFILE_CARD_HEIGHT = 403
export const ADD_PROFILE_CARD_HEIGHT = PROFILE_CARD_WIDTH + 87
export const PROFILE_CARD_PADDING = 42

export const Container = styled(Flex)`
  width: ${PROFILE_CARD_WIDTH}rem;
  height: ${PROFILE_CARD_HEIGHT}rem;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

export const AddProfileCardContainer = styled(Container)`
  width: ${ADD_PROFILE_CARD_HEIGHT}rem;
`

export const AddNewProfileAvatar = css`
  background-size: 45%;
`

export const Avatar = styled(AvatarBase)``

export const LargeText = styled.div`
  font-weight: 300;
  font-size: 42rem;
  line-height: 50rem;
  text-align: center;
  color: ${getTextColorPrimary};
`

export const Name = styled(LargeText)`
  margin-top: 24rem;
  opacity: ${(props) => (props.highlighted ? '1' : '0.6')};
`

export const EditButton = styled(EditButtonBase)`
  margin-top: 35rem;
`
