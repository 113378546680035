// @flow
import { PREFIX } from '../../../constants'

export const CHANGE_USER_SETTINGS = `${PREFIX}/CHANGE_USER_SETTINGS`

export const setUserSettings = (settings: Object) => (dispatch: Function) =>
  dispatch({
    type: CHANGE_USER_SETTINGS,
    payload: {
      settings,
    },
  })
