// @flow
import { getLimits } from '@alphaott/api-client'
import { limitLogoutWrapper } from '@alphaott/app-main/utils'

export const GET_TVSHOW_LIMITS = 'tvshows/GET_LIMITS'
export const RESET_TVSHOW_LIMITS = 'tvshows/RESET_LIMITS'

export const getTVShowLimits = (contentId: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: GET_TVSHOW_LIMITS,
    payload: limitLogoutWrapper(getLimits)(contentId, 'tvShow')(dispatch, getState),
  })

export const resetTVShowLimits = {
  type: RESET_TVSHOW_LIMITS,
}
