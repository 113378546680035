// @flow

import styled from 'styled-components'

import { Header as HeaderComponent } from '../Header'
import { CastInfo as CastInfoBase } from './CastInfo'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const ImageBackground = styled.img`
  position: fixed;
  width: 100%;
`

export const Header = styled(HeaderComponent).attrs({ withBackground: false })`
  position: fixed;
  padding: 33rem 52rem;
  background-color: transparent;
`

export const Content = styled.div`
  position: relative;
  min-height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 15%, rgba(0, 0, 0, 0.8) 34.67%);
  padding-bottom: 100rem;
`

export const CastInfo = styled(CastInfoBase)`
  position: relative;
  margin: 0 40rem;
  padding-top: 180rem;
`

export const MediaContainer = styled.div``
