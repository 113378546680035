// @flow

import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'

import {
  Container,
  PlayButton,
  PlayIcon,
  TrailerButton,
  TheatersIcon,
  FavoriteButton,
  AddCircleIcon,
  RemoveCircleIcon,
} from './ActionsPanel.style'

const PLAY_FOCUS_KEY = 'Play'
const TRAILER_FOCUS_KEY = 'Trailer'
const FAVORITE_FOCUS_KEY = 'Favorite'

export type ActionsPanelProps = {
  className?: any,
  isFavorite: boolean,
  isAvailableFavoriteButton?: boolean,
  isAvailablePlayButton?: boolean,
  isAvailableTrailerButton?: boolean,
  onFocus: () => void,
  onPlay: () => void,
  onPlayTrailer: () => void,
  onFavorite: () => void,
}

// eslint-disable-next-line complexity
const ActionsPanelPure = ({
  className,
  isFavorite,
  isAvailableFavoriteButton,
  isAvailablePlayButton,
  isAvailableTrailerButton,
  onFocus,
  onPlay,
  onPlayTrailer,
  onFavorite,
}: ActionsPanelProps) => {
  const { ref, focusKey, setFocus } = useFocusable({ onFocus })
  const { t } = useTranslation()

  // eslint-disable-next-line complexity
  useEffect(() => {
    let preparedFocusKey = FAVORITE_FOCUS_KEY

    if (isAvailableTrailerButton) {
      preparedFocusKey = TRAILER_FOCUS_KEY
    }

    if (isAvailablePlayButton) {
      preparedFocusKey = PLAY_FOCUS_KEY
    }

    if (setFocus) setFocus(preparedFocusKey)
  }, [isAvailablePlayButton, isAvailableTrailerButton, setFocus])

  return (
    <FocusContext.Provider value={focusKey}>
      <Container className={className} ref={ref}>
        {isAvailablePlayButton && (
          <PlayButton focusKey={PLAY_FOCUS_KEY} onClick={onPlay}>
            <PlayIcon />
            {t('play')}
          </PlayButton>
        )}

        {isAvailableTrailerButton && (
          <TrailerButton focusKey={TRAILER_FOCUS_KEY} onClick={onPlayTrailer}>
            <TheatersIcon /> {t('watch_trailer')}
          </TrailerButton>
        )}

        {isAvailableFavoriteButton && (
          <FavoriteButton focusKey={FAVORITE_FOCUS_KEY} onClick={onFavorite}>
            {isFavorite ? <RemoveCircleIcon /> : <AddCircleIcon />}
          </FavoriteButton>
        )}
      </Container>
    </FocusContext.Provider>
  )
}

ActionsPanelPure.defaultProps = {
  isAvailableFavoriteButton: true,
  isAvailablePlayButton: true,
  isAvailableTrailerButton: true,
}

export const ActionsPanel = memo<ActionsPanelProps>(ActionsPanelPure)

export default ActionsPanel
