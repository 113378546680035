// @flow
import { map } from 'ramda'
import { format, startOfToday } from 'date-fns'

import { getProgramsByDate } from '@alphaott/api-client/channels/programs'
import { getPublicDomain } from '@alphaott/app-main/selectors'

import { arePrograms } from '../selectors'
import { DATE_FORMAT } from '../../../const'

export const FETCH_PROGRAMS_BY_DATE_TYPE = 'program/FETCH_PROGRAMS_BY_DATE'
export const FETCH_PROGRAMS_BY_DATE_CACHE_HIT_TYPE = 'program/FETCH_PROGRAMS_BY_DATE/CACHE_HIT'
export const FETCH_PROGRAMS_GRID = 'program/FETCH_PROGRAMS_GRID'

export const fetchProgramsByDate = (
  channelId: string,
  date?: string | number = startOfToday(),
  offset?: number,
  include?: boolean,
) => (dispatch: Function, getState: Function) => {
  const preparedDate = format(date, DATE_FORMAT)
  const meta = { channelId, date: preparedDate }
  const state = getState()

  if (arePrograms(channelId, preparedDate)(state)) {
    return dispatch({
      type: FETCH_PROGRAMS_BY_DATE_CACHE_HIT_TYPE,
      meta,
    })
  }

  return dispatch({
    type: FETCH_PROGRAMS_BY_DATE_TYPE,
    payload: getProgramsByDate(getPublicDomain(state), channelId, preparedDate, offset, include),
    meta,
  })
}

const fetchPrograms = ({ channelIDS, date, offset, include }: any, dispatch: Function, getState: Function) => {
  const state = getState()

  const shouldFetchPrograms = channelId => !arePrograms(channelId, date)(state)
  const promises = []

  map(channelId => {
    if (shouldFetchPrograms(channelId)) {
      promises.push(dispatch(fetchProgramsByDate(channelId, date, offset, include)))
    }
  }, channelIDS)

  return Promise.all(promises)
}

export const fetchProgramsByDateForChannels = (
  channelIDS: Array<string>,
  date: Date,
  offset?: number = 0,
  include?: boolean = false,
) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_PROGRAMS_GRID,
    payload: fetchPrograms({ channelIDS, date, offset, include }, dispatch, getState),
  })
