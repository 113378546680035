// @flow

import typeToReducer from 'type-to-reducer'

import {
  CHANGE_PLAYER_TRACKS_STATE_TYPE,
  RESET_PLAYER_STATE_TYPE,
  RESET_PLAYER_TRACKS_STATE_TYPE,
} from '../actions/constants'

export type PlayerTracksState = {
  +audioTracks: Object[],
  +subtitlesTracks: Object[],
  +currentAudioTrack: Object,
  +currentSubtitleTrack: Object,
}

const initialState: PlayerTracksState = {
  audioTracks: [],
  subtitlesTracks: [],
  currentAudioTrack: null,
  currentSubtitleTrack: null,
}

const playerTracks = typeToReducer(
  {
    [CHANGE_PLAYER_TRACKS_STATE_TYPE]: (state, action): PlayerTracksState => ({
      ...state,
      ...action.payload,
    }),
    [RESET_PLAYER_STATE_TYPE]: () => initialState,
    [RESET_PLAYER_TRACKS_STATE_TYPE]: () => initialState,
  },
  initialState,
)

export { playerTracks }
