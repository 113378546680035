// @flow
import React from 'react'
import { Form, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'

import { isRequired } from '@alphaott/common-utils'
import { TextField } from '@alphaott/smart-tv-components'

import {
  EditNameField,
  ErrorMessage,
  FieldContainer,
  FormContainer,
} from './EditProfileNameView.styles'

import { PROFILE_NAME_INPUT_FOCUS_KEY } from './const'

export const EditProfileNameFormPure = ({ error, handleSubmit }) => {
  const { t } = useTranslation()

  return (
    <Form onSubmit={handleSubmit}>
      <FormContainer>
        {error && <ErrorMessage>{error}</ErrorMessage>}

        <FieldContainer>
          <EditNameField
            focusKey={PROFILE_NAME_INPUT_FOCUS_KEY}
            validate={[isRequired]}
            name="name"
            component={TextField}
            type="text"
            placeholder={t('mwstv_profile_name')}
            style={{ paddingLeft: '20rem' }}
          />
        </FieldContainer>
      </FormContainer>
    </Form>
  )
}

export const EditProfileNameForm = reduxForm({ form: 'profile_name' })(EditProfileNameFormPure)
