import styled from 'styled-components'

import { Text } from '@alphaott/smart-tv-components'
import { Button } from '@alphaott/smart-tv-components/src/components/Button/elements'
import { getColorPrimary } from '@alphaott/smart-tv-themes'

export const Container = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 112rem;
  min-height: 76rem;
  padding: ${(props) => props.focused && '15rem 22rem 15rem 33rem'};
  background: ${(props) => (props.focused ? getColorPrimary : 'rgba(71, 71, 71, 0.5)')};
  box-shadow: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .material-icons {
    color: ${(props) => props.theme.palette.common.white};
    font-size: ${(props) => props.iconSize}rem;
  }
`

export const Label = styled(Text)`
  font-weight: 900;
  font-size: 24rem;
  line-height: 19rem;
  text-align: center;
  color: ${(props) => props.theme.palette.common.white};
  margin-left: 12rem;
`
