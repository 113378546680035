// @flow

import * as constants from './constants'

import type { PlayerStateType, PlayerActionType } from './types'

export const INITIAL_STATE: PlayerStateType = {
  ready: false,
  loading: false,
  playing: false,
  paused: false,
  stopped: true,
  seeking: false,
  error: null,
  time: {
    current: 0,
    duration: 0,
  },
  audioTracks: [],
  subtitleTracks: [],
}

const reducer = (state: PlayerStateType, action: PlayerActionType): PlayerState =>
  ({
    [constants.LOAD_META]: () => {
      const { time, audioTracks, subtitleTracks } = action.payload

      return {
        ...state,
        time,
        audioTracks,
        subtitleTracks,
      }
    },
    [constants.LOADING]: () => {
      const { loading } = action.payload

      return { ...state, loading }
    },
    [constants.SEEKING]: () => {
      const { loading, seeking } = action.payload

      return { ...state, loading, seeking }
    },
    [constants.CAN_PLAY]: () => ({
      ...state,
      ready: true,
      // This is necessary to avoid blank screen when switching channels;
      // loading: false,
      error: null,
    }),
    [constants.PLAY]: () => ({
      ...state,
      playing: true,
      paused: false,
      stopped: false,
      error: null,
    }),
    [constants.PAUSE]: () => ({
      ...state,
      playing: false,
      paused: true,
    }),
    [constants.END]: () => ({
      ...state,
      playing: false,
      paused: false,
      stopped: true,
      time: {
        ...state.time,
        current: 0,
      },
    }),
    [constants.ERROR]: () => {
      const { message, critical = false } = action.payload.error

      return {
        ...state,
        loading: false,
        ...(critical && {
          playing: false,
          paused: false,
          stopped: true,
        }),
        error: {
          message,
          critical,
        },
      }
    },
    [constants.CHANGE_AUDIO_TRACKS]: () => ({
      ...state,
      audioTracks: action.payload.audioTracks,
    }),
    [constants.CHANGE_SUBTITLE_TRACKS]: () => ({
      ...state,
      subtitleTracks: action.payload.subtitleTracks,
    }),
    [constants.RESET_ERROR]: () => ({
      ...state,
      error: null,
    }),
    [constants.RESET]: () => INITIAL_STATE,
  }[action.type]())

export default reducer
