// @flow
import { makePostRequest } from '../utils'

import type { ResponseAPI } from '../types/api'
import type { Token } from '../types/token'

export type RefreshTokenParams = {|
  client_id: string,
  client_secret: string,
  device?: any,
  grant_type: 'refresh_token',
  refresh_token: string,
|}

export const refreshToken = (domain: string, data: RefreshTokenParams): Promise<ResponseAPI<Token>> =>
  makePostRequest(`${domain}/client/auth/token`, { data })

export default refreshToken
