// @flow

import styled from 'styled-components'

import { Button } from '@alphaott/smart-tv-components'

export const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  padding: 48rem 0 0 30rem;
`

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80rem;
  height: 80rem;
  border-radius: 50%;
  border: 2rem solid #fff;
  background: linear-gradient(rgb(241, 0, 248) 0%, rgb(248, 0, 0) 100%);

  & svg {
    width: 40rem;
    height: 40rem;
  }
`

export const Initials = styled.div`
  color: #fff;
  font-size: 30rem;
  font-weight: 700;
  letter-spacing: 4rem;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 16rem;
  height: 84rem;
`

export const Name = styled.div`
  font-size: 28rem;
  font-weight: 700;
  color: #fff;
  max-width: 200rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 276rem;
`

export const Title = styled.div``

export const ActionButton = styled(Button).attrs({
  secondaryColor: 'rgba(71, 71, 71, 0.5)',
})`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 40rem;
  height: 40rem;
  min-width: 40rem;
  min-height: 40rem;
  border-radius: 20rem;
  font-size: 17rem;
  text-transform: none;
  padding: 0;
`

export const Notifications = styled(ActionButton)`
  width: 174rem;
  height: 40rem;
`

export const NotificationsIconsContainer = styled.div`
  position: relative;
  top: 1.5rem;

  & svg {
    width: 16rem;
    height: 19rem;
  }
`

export const CircleIcon = styled.div`
  width: 8rem;
  height: 8rem;
  position: absolute;
  top: -2rem;
  left: 13rem;
  background-color: #ff0101;
`

export const Profiles = styled(ActionButton)`
  & svg {
    width: 20rem;
    height: 16rem;
  }
`
