// @flow

export const TOKEN_STORE: string = 'token'
export const DEVICE_STORE: string = 'devices'
export const BRAND_CONFIG_STORE: string = 'brandConfig'
export const CONFIG_STORE: string = 'config'
export const AUTH_METHODS_STORE: string = 'authMethods'
export const ROUTING_STORE: string = 'router'
export const SETTINGS: string = 'settings'
export const USER_SETTINGS: string = 'userSettings'
export const CURRENT_DEVICE_STORE: string = 'currentDevice'
export const CUSTOMER_LIST_STORES: Array<string> = ['channelMostWatched']
