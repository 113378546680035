// @flow
import qs from 'qs'

import { makeGetRequest } from '../utils'

import type { ResponseAPI } from '../types/api'
import type { Programs } from '../types/programs'

export const getChannelsPrograms = (
  domain: string,
  id: string,
  start: string,
  end: string,
  timezoneOffset?: number,
  includeStop?: boolean,
): Promise<ResponseAPI<Programs>> => {
  const query = qs.stringify({ start, end, timezoneOffset, includeStop })
  return makeGetRequest(`${domain}/client/api/tv/channel/${id}/programs/?${query}`)
}

export const getProgramsByDate = (
  domain: string,
  id: string,
  date: string,
  timezoneOffset?: number,
  includeStop?: boolean,
): Promise<ResponseAPI<Programs>> => {
  const query = qs.stringify({ date, timezoneOffset, includeStop })
  return makeGetRequest(`${domain}/client/api/tv/channel/${id}/programs/?${query}`)
}
