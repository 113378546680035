// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { Text } from '../../../Text'

export const Container = styled.div``

export const ItemContainer = styled(Flex)`
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 8rem;
  }
`

export const ItemLabel = styled(Text)`
  font-weight: bold;
  font-size: 24rem;
  color: ${(props) => props.theme.palette.common.white};
`

export const ItemValue = styled(Text)`
  font-size: 24rem;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 10rem;
  width: 100%;
`
