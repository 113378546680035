// @flow

import styled from 'styled-components'

import { Scrollbar } from '../../Scrollbar'

import { Tab as TabBase } from './Tab'

export const Tabs = styled(Scrollbar)`
  width: 1260rem !important;
  height: 55rem;
  margin-left: 85rem;

  .ScrollbarsCustom-Content {
    display: flex;
    align-items: flex-start;
    width: max-content;
    height: 100%;
  }
`

export const Tab = styled(TabBase)`
  margin: 0 20rem;
`

export const FocusableContainer = styled.div``
