// @flow
import React from 'react'
import useResizeObserver from 'use-resize-observer/polyfilled'

import { Container, LogoContainer as LogoShadow, Logo } from './elements'

import shadow from './assets/shadow.png'

type HeaderProps = {
  className?: any,
  logoSrc: string,
  logoHeight: number,
  children?: any,
  withBackground?: boolean,
}

// eslint-disable-next-line complexity
export const Header = ({
  className,
  logoSrc,
  logoHeight,
  children,
  withBackground = true,
}: HeaderProps) => {
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>()

  if (withBackground) {
    return (
      <Container className={className} withBackground>
        {children || <div />}
        <Logo src={logoSrc} height={logoHeight} alt="logo" />
      </Container>
    )
  }

  return (
    <Container className={className}>
      {children || <div />}
      <LogoShadow logoWidth={width} logoHeight={height} image={shadow}>
        <Logo ref={ref} src={logoSrc} height={logoHeight} alt="logo" />
      </LogoShadow>
    </Container>
  )
}
