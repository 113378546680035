// @flow
import typeToReducer from 'type-to-reducer'

import type { TVShowMediaStream } from '@alphaott/api-client/types/tvshows'

import { FETCH_TVSHOW_EPISODE_SOURCE, RESET_TVSHOW_EPISODE_SOURCES } from '../../actions/tvshowSources'
import { RESET_TVSHOW_EPISODE_DETAIL } from '../../actions'

export type TVShowSourcesState = {|
  isLoading: boolean,
  isSuccess: boolean,
  isError: boolean,
  errors: any,
  data: TVShowMediaStream[],
|}

const initialState: TVShowSourcesState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: null,
  data: [],
}

const tvshowSources = typeToReducer(
  {
    [RESET_TVSHOW_EPISODE_SOURCES]: () => initialState,

    [FETCH_TVSHOW_EPISODE_SOURCE]: {
      LOADING: (): TVShowSourcesState => ({ ...initialState, isLoading: true }),

      SUCCESS: (state, action): TVShowSourcesState => {
        const data = { ...state.data, ...action.payload.data }
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isError: false,
          errors: null,
          data,
        }
      },

      ERROR: (state, action): TVShowSourcesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
    [RESET_TVSHOW_EPISODE_DETAIL]: () => ({
      ...initialState,
    }),
  },
  initialState,
)

export { tvshowSources }
