// @flow
import { makeGetRequest, makePutRequest, makeDeleteRequest } from '../utils'

import type { Headers, ResponseAPI } from '../types/api'
import type { PersonalList, UpdatePersonalListResponse } from '../types/synchronization'

export const getPersonalList = (
  domain: string,
  profileId: string,
  contentType: string,
  listType: string,
  { token }: Headers,
): Promise<ResponseAPI<PersonalList>> =>
  makeGetRequest(`${domain}/client/api/customer/profile/${profileId}/${contentType}/${listType}?sort=-ts`, {
    headers: { token },
  })

export const putPersonalList = (
  domain: string,
  profileId: string,
  contentType: string,
  listType: string,
  contentId: string,
  data: any,
  { token }: Headers,
): Promise<ResponseAPI<UpdatePersonalListResponse>> =>
  makePutRequest(`${domain}/client/api/customer/profile/${profileId}/${contentType}/${listType}/${contentId}`, {
    headers: { token },
    data,
  })

export const removeItemFromPersonalList = (
  domain: string,
  profileId: string,
  contentType: string,
  listType: string,
  contentId: string,
  { token }: Headers,
): Promise<ResponseAPI<UpdatePersonalListResponse>> =>
  makeDeleteRequest(`${domain}/client/api/customer/profile/${profileId}/${contentType}/${listType}/${contentId}`, {
    headers: { token },
  })
