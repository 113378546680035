// @flow

export const getVideoEvents = (props) => [
  ['timeupdate', props.onTimeUpdate],
  ['canplay', props.onCanPlay],
  ['play', props.onPlay],
  ['seeking', props.onSeeking],
  ['seeked', props.onSeeked],
  ['pause', props.onPause],
  ['ended', props.onEnd],
]

export const getPlayerEvents = (props) => [
  ['error', props.onError],
  ['loading', props.onLoading],
  ['loaded', props.onLoaded],
  ['variantchanged', props.onChangedAudioTrack],
  ['textchanged', props.onChangedSubtitleTrack],
  ['texttrackvisibility', props.onChangedSubtitleTrackVisibility],
]

export const addListeners = (target, listeners) => {
  listeners.forEach(([event, listener]) => target?.addEventListener(event, listener))
}

export const removeListeners = (target, listeners) => {
  listeners.forEach(([event, listener]) => target?.removeEventListener(event, listener))
}

export const getActions = (videoRef: any, playerRef: any) => ({
  // eslint-disable-next-line no-param-reassign,no-return-assign
  onSeek: (time) => (videoRef.current.currentTime = time),

  onPlay: () => videoRef.current?.play(),
  onPause: () => videoRef.current?.pause(),

  onRetryStreaming: () => playerRef.current?.retryStreaming(),

  getTime: () => ({
    currentTime: videoRef.current?.currentTime,
    duration: videoRef.current?.duration,
  }),

  getAudioTracks: () => playerRef.current?.getVariantTracks(),
  onChangeAudioTrack: (track) => playerRef.current?.selectAudioLanguage(track.language),

  getSubtitleTracks: () => playerRef.current?.getTextTracks(),
  isSubtitleTrackVisible: () => playerRef.current?.isTextTrackVisible(),
  onChangeSubtitleTrack: (track) => playerRef.current?.selectTextTrack(track),
  onChangedSubtitleTrackVisibility: (value) => playerRef.current?.setTextTrackVisibility(value),
})
