// @flow

export const FORM_NAME = 'pinEntryForm'

export const PIN_FIELD = 'pin'

export const INIT_FORM_CONFIG = {
  form: FORM_NAME,
  initialValues: {
    [PIN_FIELD]: '0000',
  },
}
