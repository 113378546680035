// @flow

import styled from 'styled-components'

import { getTextColorPrimary } from '@alphaott/smart-tv-themes'

import {
  Container as CustomerSupportContainer,
  Title as CustomerSupportTitle,
  Description as CustomerSupportDescription,
} from '../CustomerSupport'

export const Container = styled(CustomerSupportContainer)`
  justify-content: unset;
  width: 1124rem;
`

export const Title = styled(CustomerSupportTitle)`
  display: flex;
  justify-content: space-between;
`

export const TitleItem = styled.div``

export const TitleItemDate = styled.div`
  font-size: 20px;
`

export const Description = styled(CustomerSupportDescription)``

export const DeviceInfo = styled.div`
  margin-top: 16rem;
`

export const DeviceInfoRow = styled.div`
  display: flex;
  align-items: flex-end;
  height: 33rem;
  margin-top: 18rem;
`

export const Key = styled.div`
  text-align: right;
  width: 270rem;
  font-size: 24rem;
  color: ${getTextColorPrimary};
`

export const Value = styled.div`
  text-align: left;
  line-height: 33rem;
  margin-left: 31rem;
  font-size: 33rem;
  color: ${getTextColorPrimary};
`
