// @flow

import React, { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'
import type Customer from '@alphaott/api-client/types/customer'

import { SubMenu } from './SubMenu'
import { Profile } from './Profile'
import { Container, Curtain, Menu, ExitButton } from './SideMenu.styles'
import { ExitIcon } from '../assets'

export type SubMenuItems = Array<string>

type SideMenuPureProps = {
  subMenuItems: SubMenuItems,
  customer: Customer,
  onAccount: Function,
  onClickSubMenuItem: Function,
  onExit: Function,
}

// eslint-disable-next-line complexity
const SideMenuPure = ({
  subMenuItems = [],
  customer,
  onAccount,
  onClickSubMenuItem,
  onExit,
}: SideMenuPureProps) => {
  const { ref, focusKey, hasFocusedChild, navigateByDirection } = useFocusable({
    trackChildren: true,
  })
  const { t } = useTranslation()

  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const handleMouseLeave = useCallback(() => {
    navigateByDirection('right')
  }, [navigateByDirection])

  const handleClick = useCallback(
    (callback) => (route) => {
      if (route) {
        callback(route)
      } else {
        callback()
      }

      navigateByDirection('right')
    },
    [navigateByDirection],
  )

  useEffect(() => {
    setIsOpenMenu(hasFocusedChild)
  }, [hasFocusedChild])

  return (
    <FocusContext.Provider value={focusKey}>
      <Container ref={ref}>
        {isOpenMenu && <Curtain />}

        <Menu isOpenMenu={isOpenMenu} onMouseLeave={handleMouseLeave}>
          {isOpenMenu && <Profile customer={customer} onAccount={handleClick(onAccount)} />}

          <SubMenu isOpen={isOpenMenu} items={subMenuItems} onClickItem={onClickSubMenuItem} />

          {isOpenMenu && (
            <ExitButton
              icon={<ExitIcon />}
              label={t('mwstv_exit')}
              isShowLabel={isOpenMenu}
              onClick={onExit}
            />
          )}
        </Menu>
      </Container>
    </FocusContext.Provider>
  )
}

export const SideMenu = memo<SideMenuPureProps>(SideMenuPure)

export default SideMenu
