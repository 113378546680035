// @flow

import React, { memo, useEffect, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { or, not } from 'ramda'
import { isFalse } from 'ramda-adjunct'

import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'

import {
  Container,
  Top,
  Title,
  Description,
  Emails,
  EmailItem,
  Label,
  Text,
  Bottom,
  Address,
} from './CustomerSupport.styles'

export type CustomerSupportProps = {
  focusKey: string,
  className?: string,
  supportEmail: string,
  infoEmail: string,
  companyName: string,
  supportAddress: string,
  companyWww: string,
  focusable?: boolean,
  withMouseOver?: boolean,
  onScroll?: Function,
}

const CustomerSupportPure = ({
  focusKey: parentFocusKey,
  className,
  companyName,
  companyWww,
  supportAddress,
  infoEmail,
  supportEmail,
  focusable = true,
  withMouseOver = true,
  onScroll,
}: CustomerSupportProps) => {
  const { ref, focused, focusSelf } = useFocusable({
    focusKey: parentFocusKey,
    focusable,
  })
  const { handleMouseOver: onMouseOver } = useMouseOver(focusSelf)

  const handleMouseOver = useCallback(
    // eslint-disable-next-line consistent-return
    (event) => {
      if (or(not(focusable), isFalse(withMouseOver))) return null
      onMouseOver(event)
    },
    [focusable, withMouseOver, onMouseOver],
  )

  useEffect(() => {
    if (focused && onScroll) {
      onScroll()
    }
  }, [focused]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container ref={ref} className={className} focused={focused} onMouseOver={handleMouseOver}>
      <Top>
        <Title>
          <Trans i18nKey="mwstv_support_support_title" defaults="24/7 Customer Support" />
        </Title>

        <Description>
          <Trans
            i18nKey="mwstv_support_support_description"
            defaults="For Customer Support,
                  refer to the contacts below according to your requests."
          />
        </Description>
        <Emails>
          <EmailItem>
            <Label>
              <Trans i18nKey="support" defaults="Support" />
            </Label>
            <Text>{supportEmail}</Text>
          </EmailItem>
          <EmailItem>
            <Label>
              <Trans i18nKey="mwstv_support_general_inquiries" defaults="General Inquiries" />
            </Label>
            <Text>{infoEmail}</Text>
          </EmailItem>
        </Emails>
      </Top>

      <Bottom>
        <Address>
          {companyName}
          <br />
          {supportAddress}
        </Address>
        <Text>{companyWww}</Text>
      </Bottom>
    </Container>
  )
}

export const CustomerSupport = memo<CustomerSupportProps>(CustomerSupportPure)

export default CustomerSupport
