// @flow
import { makeGetRequest } from '../utils'

import type { ResponseAPI } from '../types/api'
import type { CatchupChannels } from '../types/catchup/channels'

export const getCatchupChannels = (domain: string, serviceSpec: string): Promise<ResponseAPI<CatchupChannels>> =>
  makeGetRequest(`${domain}/client/api/servicespec/${serviceSpec}/catchup/channels`)

export default getCatchupChannels
