// @flow

import React, { forwardRef, useCallback, useMemo } from 'react'
import { VariableSizeList } from 'react-window'
import * as R from 'ramda'

import { getListScrollOffset } from '@alphaott/smart-tv-utils'
import { useLastFocusHistory } from '@alphaott/smart-tv-spatial-navigation'

// eslint-disable-next-line react/display-name
const LastFocusedHorizontalList = forwardRef(({ onItemsRendered, ...otherProps }: any, ref) => {
  const { sectionType, getColumnIndex, onBecameFocused } = otherProps.itemData

  const { lastFocusedItem, onSaveLastFocus } = useLastFocusHistory({}, sectionType)

  const initialScrollOffset = useMemo(() => {
    if (lastFocusedItem?.itemIndex === undefined) {
      return undefined
    }

    const { width, itemSize } = otherProps

    return getListScrollOffset(width, itemSize(), lastFocusedItem?.itemIndex)
  }, [lastFocusedItem, otherProps])

  const handleSaveData = useCallback(
    ({ itemIndex, focusKey }: Object) => {
      if (itemIndex !== undefined) {
        let focusedItem = {
          ...lastFocusedItem,
        }

        if (R.not(R.hasPath(['sizes'], lastFocusedItem))) {
          focusedItem = {
            ...focusedItem,
            sizes: undefined,
          }
        }

        focusedItem = {
          ...focusedItem,
          focusKey,
          sizes: {
            ...focusedItem.sizes,
            [itemIndex]: otherProps.itemSize(itemIndex),
          },
        }

        onSaveLastFocus({
          ...focusedItem,
          itemIndex,
        })
      }
    },
    [lastFocusedItem, onSaveLastFocus, otherProps],
  )

  const handleBecameFocused = useCallback(
    (item) => {
      const index = getColumnIndex(item.x, item.width)

      if (onBecameFocused) {
        onBecameFocused(item)
      }

      handleSaveData({
        itemIndex: index,
        focusKey: item.focusKey,
      })
    },
    [getColumnIndex, handleSaveData, onBecameFocused],
  )

  return (
    <VariableSizeList
      {...otherProps}
      ref={ref}
      initialScrollOffset={initialScrollOffset}
      itemData={{
        ...otherProps.itemData,
        onBecameFocused: handleBecameFocused,
      }}
    />
  )
})

const HorizontalListInner = ({ withLastFocus, ...props }: any, ref) => {
  if (withLastFocus) {
    return <LastFocusedHorizontalList {...props} layout="horizontal" ref={ref} />
  }

  return <VariableSizeList {...props} layout="horizontal" ref={ref} />
}

export const HorizontalList = forwardRef(HorizontalListInner)

export default HorizontalList
