// @flow
import React, { memo, useCallback, useEffect } from 'react'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'

import {
  Dimmer,
  DialogWindow,
  DialogTitle,
  DialogDescription,
  DialogActions,
  Button,
} from './elements'
import { CANCEL_BUTTON_FOCUS_KEY, APPROVE_BUTTON_FOCUS_KEY } from './const'

type PopupDialogProps = {
  title?: string,
  description?: string,
  approveButtonTitle?: string,
  cancelButtonTitle?: string,
  isOpen: boolean,
  focusable?: boolean,
  onClose: () => void,
  onApprove: () => void,
}

const PopupDialogPure = ({
  title,
  description,
  approveButtonTitle,
  cancelButtonTitle,
  isOpen,
  focusable,
  onClose,
  onApprove,
}: PopupDialogProps) => {
  const { ref, focusKey, setFocus } = useFocusable({ focusable, isFocusBoundary: true })

  const stopPropagation = useCallback((e) => e.stopPropagation(), [])

  useEffect(() => {
    if (setFocus && isOpen) {
      setFocus(CANCEL_BUTTON_FOCUS_KEY)
    }
  }, [setFocus, isOpen])

  if (!isOpen) {
    return null
  }

  return (
    <FocusContext.Provider value={focusKey}>
      <Dimmer onClick={onClose}>
        <DialogWindow ref={ref} onClick={stopPropagation}>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
          <DialogActions>
            <Button focusKey={CANCEL_BUTTON_FOCUS_KEY} onClick={onClose}>
              {cancelButtonTitle}
            </Button>
            <Button focusKey={APPROVE_BUTTON_FOCUS_KEY} onClick={onApprove}>
              {approveButtonTitle}
            </Button>
          </DialogActions>
        </DialogWindow>
      </Dimmer>
    </FocusContext.Provider>
  )
}

PopupDialogPure.defaultProps = {
  approveButtonTitle: 'Approve',
  cancelButtonTitle: 'Cancel',
}

export const PopupDialog = memo<PopupDialogProps>(PopupDialogPure)

export default PopupDialog
