// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

export const PlayerContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;
`
