// @flow

import { CHANGE_PLAYER_CONTROLS_STATE_TYPE } from './constants'

export const changePlayerControlsState = (payload) => (dispatch) =>
  dispatch({
    type: CHANGE_PLAYER_CONTROLS_STATE_TYPE,
    payload,
  })

export const changeForceShowControls = (isForceShowControls) => (dispatch) =>
  dispatch(changePlayerControlsState({ isForceShowControls }))

export const changeShowControls = (isShowControls) => (dispatch) =>
  dispatch(changePlayerControlsState({ isShowControls }))

export const changeShowSubtitlesSettings = (isShowSubtitlesSettings) => (dispatch) =>
  dispatch(changePlayerControlsState({ isShowSubtitlesSettings }))

export const changeShowTVListControl = (isShowListControl) => (dispatch) =>
  dispatch(changePlayerControlsState({ isShowListControl }))
