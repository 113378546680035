// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

export const Container = styled(Flex)`
  align-items: center;
  font-size: 24rem;
  line-height: 29rem;
  letter-spacing: 0.758333rem;
  color: #f8f4f4;
`

export const Divider = styled.span``
