// @flow
import React, { memo } from 'react'
import { pipe } from 'ramda'

import { useFocusable, useLongEnterPress } from '@alphaott/smart-tv-spatial-navigation'
import { isValidTimezone, withParentalControl } from '@alphaott/smart-tv-common'

import defaultChannelLogo from '../assets/defaultChannelLogo.png'

import { TimeZone } from './TimeZone'
import { Catchup } from './Catchup'
import {
  ChannelCardImage,
  ChannelCardTitleBox,
  ChannelCardTitle,
  ChannelCardContainer,
  Placeholder,
  ChannelCardImageContainer,
  Options,
} from './ChannelCard.style'

type ChannelCardProps = {
  hasCatchup: boolean,
  title: string,
  src: string,
  cardPlaceholder?: string,
  offset: number,
  style?: any,
  focusKey?: string,
  onFocus?: Function,
  onPress: Function,
  onLongPress: Function,
  onArrowPress?: Function,
}

export const ChannelCardPure = ({
  hasCatchup,
  title,
  src,
  cardPlaceholder = defaultChannelLogo,
  offset,
  style,
  focusKey,
  onFocus,
  onPress,
  onLongPress,
  onArrowPress,
}: ChannelCardProps) => {
  const { onEnterPress, onEnterRelease } = useLongEnterPress(onPress, onLongPress)

  const { ref, focused } = useFocusable({
    focusKey,
    onFocus,
    onEnterPress,
    onEnterRelease,
    onArrowPress,
  })

  const placeholderComponent = <Placeholder src={cardPlaceholder} alt="placeholder" />

  return (
    <ChannelCardContainer ref={ref} style={style} onClick={onPress}>
      {/* TODO: Add loader */}
      <ChannelCardImageContainer>
        <Options>
          {isValidTimezone(offset) && <TimeZone offset={offset} focused={focused} />}
          {hasCatchup && <Catchup />}
        </Options>
        <ChannelCardImage
          focused={focused}
          src={src}
          placeholder={cardPlaceholder}
          unloader={placeholderComponent}
          alt="placeholder"
        />
      </ChannelCardImageContainer>
      <ChannelCardTitleBox>
        <ChannelCardTitle>{title}</ChannelCardTitle>
      </ChannelCardTitleBox>
    </ChannelCardContainer>
  )
}

export const ChannelCard = pipe(memo, withParentalControl)<ChannelCardProps>(ChannelCardPure)

export default ChannelCard
