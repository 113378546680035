// @flow
import { getLimits } from '@alphaott/api-client'
import { limitLogoutWrapper } from '@alphaott/app-main/utils'

export const GET_CHANNEL_LIMITS = 'channels/GET_LIMITS'
export const RESET_CHANNEL_LIMITS = 'channels/RESET_LIMITS'

export const getChannelLimits = (contentId: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: GET_CHANNEL_LIMITS,
    payload: limitLogoutWrapper(getLimits)(contentId, 'tv')(dispatch, getState),
  })

export const resetChannelLimits = {
  type: RESET_CHANNEL_LIMITS,
}
