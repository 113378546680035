// @flow
import { prop, cond, always, T, either, equals, propEq, is } from 'ramda'

export const isTrueInConfig = either(equals(true), equals('true'))

export const isEnabled = (param: Object): boolean =>
  cond([[isTrueInConfig, always(true)], [T, always(false)]])(prop('enabled', param))

export const isNotEnabled = (param: { enabled: boolean } = {}) => propEq('enabled', false, param)

export const isEnabledOption = (param: any): boolean => {
  const isBoolean = is(Boolean)
  if (isBoolean(param)) return param

  return false
}
