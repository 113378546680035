// @flow
import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { PageView, Header as HeaderComponent, Button } from '@alphaott/smart-tv-components'

import { ProfileList } from './ProfileList'

export const Container = styled(PageView)``

export const Header = styled(HeaderComponent)`
  justify-content: flex-start;
  position: relative;
  padding: 64rem 107rem;
`

export const Content = styled(Flex)`
  align-items: center;
  flex-direction: column;
`

const Text = styled.div`
  font-size: 32rem;
  line-height: 38rem;
  color: rgba(255, 255, 255, 0.87);
  text-align: center;
`

export const Title = styled(Text)`
  font-weight: 300;
  font-size: 66rem;
  line-height: 79rem;
  margin-top: 55rem;
`

export const List = styled(ProfileList)`
  margin-top: 55rem;
`

export const SmallText = styled(Text)`
  margin-top: 50rem;
  color: rgba(255, 255, 255, 0.7);
`

export const LogoutButton = styled(Button)`
  margin-top: 71rem;
`
