// @flow

import React, { memo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useFocusable,
  useLastFocus,
  useLastFocusHistory,
} from '@alphaott/smart-tv-spatial-navigation'

import {
  Container,
  ButtonsContainer,
  Button,
  Sort,
  SortIcon,
  SortLabel,
  ViewListIcon,
  AddCircleIcon,
  RemoveCircleIcon,
  ClosedCaptionIcon,
  ListIcon,
  FeaturedVideoIcon,
  RadioButtonCheckedIcon,
} from './elements'

import {
  PROGRAM_GUIDE_FOCUS_KEY,
  ADD_TO_MY_LIST_FOCUS_KEY,
  AUDIO_SUBS_FOCUS_KEY,
  OPEN_CHANNELS_LIST_FOCUS_KEY,
  MULTI_SCREEN_FOCUS_KEY,
  RECORD_FOCUS_KEY,
} from '../../const'

export type ControlsProps = {
  className?: string,
  isFavorite?: boolean,
  isAvailableProgramGuide?: boolean,
  isAvailableMyList?: boolean,
  isAvailableAudioSubs?: boolean,
  isAvailableChannelsList?: boolean,
  isAvailableMultiScreen?: boolean,
  isAvailableRecord?: boolean,
  openChannelsListButtonTitle: string,
  onOpenProgramGuide?: () => void,
  onFavorite?: () => void,
  onCaptions?: () => void,
  onOpenChannelsList?: () => void,
  onMultiScreen?: () => void,
  onRecord?: () => void,
}

// eslint-disable-next-line complexity
const ControlsPure = ({
  className,
  isFavorite,
  isAvailableProgramGuide,
  isAvailableMyList,
  isAvailableAudioSubs,
  isAvailableChannelsList,
  isAvailableMultiScreen,
  isAvailableRecord,
  openChannelsListButtonTitle,
  onOpenProgramGuide,
  onFavorite,
  onCaptions,
  onOpenChannelsList,
  onMultiScreen,
  onRecord,
}: ControlsProps) => {
  const { ref, setFocus } = useFocusable()
  const { t } = useTranslation()

  useLastFocus(null, {
    setFocus,
    defaultFocusKey: isAvailableMyList ? ADD_TO_MY_LIST_FOCUS_KEY : OPEN_CHANNELS_LIST_FOCUS_KEY,
  })
  const { onSaveLastFocus } = useLastFocusHistory()

  const handleActionButton = useCallback(
    (action, focusKey, withSaveFocus) => () => {
      action()

      if (withSaveFocus) {
        onSaveLastFocus({ focusKey })
      }
    },
    [onSaveLastFocus],
  )

  useEffect(() => {
    if (!isAvailableMyList) {
      setFocus(OPEN_CHANNELS_LIST_FOCUS_KEY)
    }
  }, [isAvailableMyList]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className={className} ref={ref}>
      <ButtonsContainer>
        {isAvailableProgramGuide && (
          <Button
            icon={ViewListIcon}
            key={PROGRAM_GUIDE_FOCUS_KEY}
            focusKey={PROGRAM_GUIDE_FOCUS_KEY}
            onClick={handleActionButton(onOpenProgramGuide, PROGRAM_GUIDE_FOCUS_KEY, true)}
          >
            {t('program_guide')}
          </Button>
        )}
        {isAvailableMyList && (
          <Button
            icon={isFavorite ? RemoveCircleIcon : AddCircleIcon}
            key={ADD_TO_MY_LIST_FOCUS_KEY}
            focusKey={ADD_TO_MY_LIST_FOCUS_KEY}
            onClick={handleActionButton(onFavorite, ADD_TO_MY_LIST_FOCUS_KEY)}
          >
            {t(
              isFavorite
                ? 'mwstv_tv_playback_remove_from_my_list'
                : 'mwstv_tv_playback_add_to_my_list',
            )}
          </Button>
        )}
        {isAvailableAudioSubs && (
          <Button
            icon={ClosedCaptionIcon}
            key={AUDIO_SUBS_FOCUS_KEY}
            focusKey={AUDIO_SUBS_FOCUS_KEY}
            onClick={handleActionButton(onCaptions, AUDIO_SUBS_FOCUS_KEY, true)}
          >
            {t('mwstv_tv_playback_audio_and_subs')}
          </Button>
        )}
        {isAvailableChannelsList && (
          <Button
            icon={ListIcon}
            key={OPEN_CHANNELS_LIST_FOCUS_KEY}
            focusKey={OPEN_CHANNELS_LIST_FOCUS_KEY}
            onClick={handleActionButton(onOpenChannelsList, OPEN_CHANNELS_LIST_FOCUS_KEY)}
          >
            {openChannelsListButtonTitle}
          </Button>
        )}
        {isAvailableMultiScreen && (
          <Button
            icon={FeaturedVideoIcon}
            key={MULTI_SCREEN_FOCUS_KEY}
            focusKey={MULTI_SCREEN_FOCUS_KEY}
            onClick={handleActionButton(onMultiScreen, MULTI_SCREEN_FOCUS_KEY)}
          >
            {t('mwstv_tv_playback_multi_screen')}
          </Button>
        )}
        {isAvailableRecord && (
          <Button
            icon={RadioButtonCheckedIcon}
            key={RECORD_FOCUS_KEY}
            focusKey={RECORD_FOCUS_KEY}
            onClick={handleActionButton(onRecord, RECORD_FOCUS_KEY)}
          >
            {t('mwstv_tv_playback_record')}
          </Button>
        )}
      </ButtonsContainer>
      {isAvailableChannelsList && (
        <Sort>
          <SortIcon />
          <SortLabel>{t('mwstv_tv_playback_next_previous_channel')}</SortLabel>
        </Sort>
      )}
    </Container>
  )
}

ControlsPure.defaultProps = {
  isAvailableProgramGuide: false,
  isAvailableMyList: true,
  isAvailableAudioSubs: true,
  isAvailableChannelsList: false,
  isAvailableMultiScreen: false,
  isAvailableRecord: false,
}

export const Controls = memo(ControlsPure)

export default Controls
