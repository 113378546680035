// @flow

import { map } from 'ramda'

export const trailersToItems = (trailers = []) =>
  map(
    ({ _id, bgImage, src }) => ({
      id: _id,
      src: bgImage || src,
      isTrailer: true,
    }),
    trailers,
  )
