// @flow

import React, { memo, useMemo } from 'react'
import { range, map } from 'ramda'

import { Container, OutlineStar, FillStar, HalfStar } from './Rating.style'

const STEP = 2
const MAX = 5 * STEP

type RatingProps = {
  className?: any,
  rating: number,
}

const RatingPure = ({ className, rating }: RatingProps) => {
  const starRaring = useMemo(() => Math.min(rating, MAX) / STEP, [rating])

  const stars = useMemo(
    () =>
      // eslint-disable-next-line complexity
      map((value) => {
        const preparedValue = starRaring - value
        const isHalf = preparedValue > 0 && preparedValue < 1
        const isStar = value < starRaring

        let Star = OutlineStar

        if (isHalf) {
          Star = HalfStar
        }

        if (!isHalf && isStar) {
          Star = FillStar
        }

        return <Star key={value} />
      }, range(0, 5)),
    [starRaring],
  )

  return <Container className={className}>{stars}</Container>
}

export const Rating = memo<RatingProps>(RatingPure)

export default Rating
