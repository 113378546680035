// @flow
import typeToReducer from 'type-to-reducer'
import { map, pipe, groupBy, prop, assoc, values, flatten, propEq, mergeDeepRight, reject, append, find } from 'ramda'

import type { Subscription } from '@alphaott/api-client/types/subscriptions'

import { prepareSubscription } from '@alphaott/common-utils/utils/schemas/subscriptions'
import { ACTIVATE_VOUCHER_CUSTOMER_TYPE } from '@alphaott/app-core/actions'

import {
  FETCH_SUBSCRIPTION_TYPE,
  CANCEL_SUBSCRIPTION_TYPE,
  CLEAR_CANCEL_SUBSCRIPTION_ERROR_TYPE,
} from '../../actions/appSubscriptions'

export type SubscriptionsState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Array<Subscription>,
  +cancel: {
    +isLoading: boolean,
    +isSuccess: boolean,
    +isError: boolean,
    +errors: any,
  },
}

const initialState: SubscriptionsState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
  cancel: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errors: [],
  },
}

const subscriptions = typeToReducer(
  {
    [ACTIVATE_VOUCHER_CUSTOMER_TYPE]: {
      LOADING: (state): SubscriptionsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): SubscriptionsState => {
        const {
          meta,
          payload: { data },
        } = action

        const newSubscription = prepareSubscription({ ...data, _id: `internal:${data._id}` }, meta.contentApi)
        const findedSubscription = find(propEq('_id', newSubscription._id))(state.data)

        const newData = pipe(
          reject(propEq('_id', newSubscription._id)),
          append(mergeDeepRight(findedSubscription, newSubscription)),
        )(state.data)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          errors: [],
          data: newData,
        }
      },

      ERROR: (state, action): SubscriptionsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },

    [FETCH_SUBSCRIPTION_TYPE]: {
      LOADING: (state): SubscriptionsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): SubscriptionsState => {
        const { meta, payload } = action
        const data = map(item => prepareSubscription(item, meta.contentApi))(payload.data)
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          errors: [],
          data,
        }
      },

      ERROR: (state, action): SubscriptionsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        data: {},
      }),
    },
    [CANCEL_SUBSCRIPTION_TYPE]: {
      LOADING: (state): SubscriptionsState => ({ ...state, cancel: { ...state.cancel, isLoading: true } }),

      SUCCESS: (state, action): SubscriptionsState => {
        const { meta, payload } = action
        const { id } = meta

        const data = pipe(
          groupBy(prop('_id')),
          assoc(id, payload.data),
          values,
          flatten,
        )(state.data)

        return {
          ...state,
          data,
          cancel: {
            ...state.cancel,
            isLoading: false,
            isSuccess: true,
            errors: [],
          },
        }
      },

      ERROR: (state, action): SubscriptionsState => ({
        ...state,
        cancel: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          errors: action.payload.error,
        },
      }),
    },
    [CLEAR_CANCEL_SUBSCRIPTION_ERROR_TYPE]: (state): SubscriptionsState => ({
      ...state,
      cancel: initialState.cancel,
    }),
  },
  initialState,
)

export { subscriptions }
