// @flow
import typeToReducer from 'type-to-reducer'
import { add, prop } from 'ramda'

import { defaultToZero } from '@alphaott/common-utils/utils/help'

import { MOST_PLAYED_RADIO_CHANNEL } from '../../actions'

import type { RadioChannelMostPlayedAction } from '../../actions'

export type RadioChannelMostPLayed = {
  [string]: number,
}

export type RadioChannelsMostPlayedState = {|
  +data: RadioChannelMostPLayed,
|}

const initialState: RadioChannelsMostPlayedState = {
  data: {},
}

const radioChannelMostPlayed = typeToReducer(
  {
    [MOST_PLAYED_RADIO_CHANNEL]: (
      { data }: RadioChannelsMostPlayedState,
      { id, duration }: RadioChannelMostPlayedAction,
    ): RadioChannelsMostPlayedState => ({
      data: {
        ...data,
        [id]: add(defaultToZero(prop(id, data)), duration),
      },
    }),
  },
  initialState,
)

export { radioChannelMostPlayed }
