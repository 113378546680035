// @flow
import { makePostRequest } from '../utils'

import type { ResponseAPI } from '../types/api'
import type { ChannelMediaStreams } from '../types/channels'
import type { Options } from '../utils/getStreamEncryptHeader'

import { getStreamHeader } from '../utils/getStreamEncryptHeader'

type SourceOptions = Options & { id: string }

export const getChannelSources = (
  domain: string,
  options: SourceOptions,
): Promise<ResponseAPI<ChannelMediaStreams>> => {
  const headers = getStreamHeader(options)

  return makePostRequest(`${domain}/client/api/v3/tv/channels/sources`, {
    headers,
    data: { id: options.id },
  })
}

export default getChannelSources
