// @flow
import React from 'react'

import { LinkText, PlayIcon } from './PlayButton.style'

type BtnProps = {
  isLive: boolean,
  focused: boolean,
  children?: any,
  onPress?: Function,
  isSelected: boolean,
}

export const PlayBtn = ({ isLive, focused, children, onPress, isSelected }: BtnProps) => (
  <LinkText onClick={onPress}>
    <PlayIcon $isLive={isLive} $isSelected={isSelected} $focused={focused} />
    {children}
  </LinkText>
)
