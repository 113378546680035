// @flow

import React, { memo, useMemo, useEffect, forwardRef, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { or, not, map } from 'ramda'
import { isFalse, isNonEmptyArray } from 'ramda-adjunct'

import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'

import {
  Container,
  Title,
  Description,
  DeviceInfo,
  DeviceInfoRow,
  Key,
  Value,
  TitleItem,
  TitleItemDate,
} from './SupportDetails.styles'

export type SupportDetailsProps = {
  focusKey: string,
  className?: string,
  deviceInfo: Object[],
  date: string,
  focusable?: boolean,
  withMouseOver?: boolean,
  onScroll?: Function,
}

const SupportDetailsPure = ({
  className,
  focusKey,
  deviceInfo,
  date,
  focusable,
  withMouseOver = true,
  onScroll,
}: SupportDetailsProps) => {
  const { ref, focused, focusSelf } = useFocusable({ focusKey, focusable })
  const { handleMouseOver: onMouseOver } = useMouseOver(focusSelf)

  const handleMouseOver = useCallback(
    // eslint-disable-next-line consistent-return
    (event) => {
      if (or(not(focusable), isFalse(withMouseOver))) return null
      onMouseOver(event)
    },
    [focusable, withMouseOver, onMouseOver],
  )

  useEffect(() => {
    if (focused && onScroll) {
      onScroll()
    }
  }, [focused]) // eslint-disable-line react-hooks/exhaustive-deps

  const dataBlock = useMemo(
    () =>
      map(
        ([key, value]) => (
          <DeviceInfoRow key={key}>
            <Key>{key}</Key>
            <Value>{value}</Value>
          </DeviceInfoRow>
        ),
        deviceInfo,
      ),
    [deviceInfo],
  )

  return (
    <Container ref={ref} className={className} focused={focused} onMouseOver={handleMouseOver}>
      <Title>
        <TitleItem>
          <Trans i18nKey="mwstv_support_support_details" defaults="Support Details" />
        </TitleItem>
        <TitleItemDate>{date}</TitleItemDate>
      </Title>

      <Description>
        <Trans
          i18nKey="mwstv_support_support_details_description"
          defaults="For your convenience and faster support responce,
                here are some helpful details for
              you device and your customer account."
        />
      </Description>

      {isNonEmptyArray(deviceInfo) && <DeviceInfo>{dataBlock}</DeviceInfo>}
    </Container>
  )
}

export const SupportDetails = memo(SupportDetailsPure)

export default SupportDetails

// eslint-disable-next-line react/display-name
export const ForwardedSupportDetails = forwardRef((props, ref) => (
  <div ref={ref}>
    <SupportDetails {...props} />
  </div>
))
