// @flow
import { makeGetRequest } from '../../utils'

import type { Language } from '../../types/languages'
import type { ResponseAPI } from '../../types/api'

type Projection = 'default' | 'cards' | 'posters'

export const getTvShowLanguages = (
  domain: string,
  serviceId: string,
  projection?: Projection = 'default',
): Promise<ResponseAPI<Array<Language>>> =>
  makeGetRequest(`${domain}/client/api/servicespec/${serviceId}/tv-show/languages?projection=${projection}`)
