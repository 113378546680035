// @flow

import { useMemo } from 'react'
import { drop } from 'ramda'

import { languages } from '@alphaott/app-dictionary'
import { DEFAULT_LANGUAGE_OPTION, DISABLED_LANGUAGE_OPTION } from '@alphaott/app-config'

import { useTranslateDefaultLanguageOption } from './useTranslateDefaultLanguageOption'

export const usePrepareLanguageList = (withoutDisableOption?: boolean) => {
  const defaultLanguageOption = useTranslateDefaultLanguageOption(DEFAULT_LANGUAGE_OPTION)
  const disabledLanguageOption = useTranslateDefaultLanguageOption(DISABLED_LANGUAGE_OPTION)

  const preparedLanguages = useMemo(() => {
    const list = [disabledLanguageOption, defaultLanguageOption, ...languages]
    return withoutDisableOption ? drop(1, list) : list
  }, [defaultLanguageOption, disabledLanguageOption, withoutDisableOption])

  return preparedLanguages
}
