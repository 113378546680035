// @flow

import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { pipe, flatten, reject, not } from 'ramda'

import { SideMenu, MENU_ITEMS } from '@alphaott/smart-tv-sidebar'
import {
  getIsAvailableLiveTv,
  getIsAvailableTvShows,
  getIsAvailableMovies,
  getCustomer,
  getCustomerId,
  isNewCustomerAndWithoutServices,
  getIsAvailableRadio,
} from '@alphaott/app-core/selectors'
import { getEnabledSmartTVEpg } from '@alphaott/smart-tv-epg'
import { useDeviceDataForAnalytics } from '@alphaott/smart-tv-current-device/hooks'
import { WelcomeModal } from '@alphaott/smart-tv-notifications'

import { useExitAppModal } from '@alphaott/smart-tv-notifications/hooks'
import { useIdentify } from '@alphaott/app-analytics'
import { isWebOS } from '@alphaott/smart-tv-platforms'

import { useDemoModal } from '../../hooks'

type Props = {
  children: any,
}

const { SEARCH, HOME, LIVE_TV, TV_GUIDE, MOVIES, TV_SHOWS, RADIO, MY_LIST, SETTINGS } = MENU_ITEMS

export const App = ({ children }: Props) => {
  const navigate = useNavigate()

  const { handleOpenExitAppModal, handleApproveExitAppModal } = useExitAppModal()
  const currentDevice = useDeviceDataForAnalytics()

  const customer = useSelector(getCustomer)
  const customerId = useSelector(getCustomerId)

  useIdentify(customerId, currentDevice)

  const isNewCustomer = useSelector(isNewCustomerAndWithoutServices)

  const isEpg = useSelector(getEnabledSmartTVEpg)

  const isAvailableLiveTv = useSelector(getIsAvailableLiveTv)
  const isAvailableTvShows = useSelector(getIsAvailableTvShows)
  const isAvailableMovies = useSelector(getIsAvailableMovies)
  const isAvailableRadio = useSelector(getIsAvailableRadio)

  const isAllowedSearch = useMemo(() => not(isNewCustomer) && SEARCH, [isNewCustomer])
  const isAllowedLiveTV = useMemo(() => isAvailableLiveTv && LIVE_TV, [isAvailableLiveTv])
  const isAllowedEpg = useMemo(() => isEpg && TV_GUIDE, [isEpg])
  const isAllowedMovies = useMemo(() => isAvailableMovies && MOVIES, [isAvailableMovies])
  const isAllowedTVShows = useMemo(() => isAvailableTvShows && TV_SHOWS, [isAvailableTvShows])
  const isAllowedMyList = useMemo(() => not(isNewCustomer) && MY_LIST, [isNewCustomer])
  const isAllowedRadio = useMemo(() => isAvailableRadio && RADIO, [isAvailableRadio])

  const { companyName, isOpenDemoWelcomeModal, subscriptionLink, handleCloseDemoWelcomeScreen } =
    useDemoModal()

  const subMenuItems = useMemo(
    () =>
      pipe(
        flatten,
        reject(not),
      )([
        isAllowedSearch,
        HOME,
        isAllowedLiveTV,
        isAllowedEpg,
        isAllowedMovies,
        isAllowedTVShows,
        isAllowedRadio,
        isAllowedMyList,
        SETTINGS,
      ]),
    [
      isAllowedSearch,
      isAllowedLiveTV,
      isAllowedEpg,
      isAllowedMovies,
      isAllowedTVShows,
      isAllowedRadio,
      isAllowedMyList,
    ],
  )

  const handleExitApp = useCallback(() => {
    if (isWebOS) {
      return handleApproveExitAppModal()
    }

    return handleOpenExitAppModal()
  }, [handleApproveExitAppModal, handleOpenExitAppModal])

  const handlePressItem = useCallback((route) => navigate(route), [navigate])

  const handleAccount = useCallback(() => navigate('/account'), [navigate])

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <SideMenu
        subMenuItems={subMenuItems}
        customer={customer}
        onAccount={handleAccount}
        onClickSubMenuItem={handlePressItem}
        onExit={handleExitApp}
      />

      {children}

      {isOpenDemoWelcomeModal && (
        <WelcomeModal
          name={companyName}
          onCloseModal={handleCloseDemoWelcomeScreen}
          link={subscriptionLink}
        />
      )}
    </div>
  )
}

export default App
