// @flow

import { CUSTOMER_DEACTIVATE } from '../../actions/appCustomer'

import type { CustomerState } from '.'

const deactivateReducer = {
  [CUSTOMER_DEACTIVATE]: {
    LOADING: (state: CustomerState): CustomerState => ({
      ...state,
      errors: [],
      isLoading: true,
    }),

    SUCCESS: (state: CustomerState): CustomerState => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      errors: [],
      data: {},
    }),

    ERROR: (state: CustomerState, action: any): CustomerState => ({
      ...state,
      isLoading: false,
      isError: true,
      errors: action.payload.error,
    }),
  },
}
export { deactivateReducer }
