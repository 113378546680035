// @flow

import styled from 'styled-components'
import { ListComponent } from '@alphaott/smart-tv-components'

import {
  getColorPrimary,
  getColorPrimaryContrastText,
  getBackgroundSecondaryColor,
} from '@alphaott/smart-tv-themes'

import { ChevronDownIconComponent } from '../assets'
import { PROGRAM_ITEM_WIDTH, PROGRAM_ITEM_HEIGHT } from '../ProgramItem'
import { CHEVRON_ICON_WIDTH, CHEVRON_ICON_HEIGHT } from '../ChannelsList'

export const StyledList = styled(ListComponent).attrs({
  itemSize: PROGRAM_ITEM_HEIGHT,
  listWidth: PROGRAM_ITEM_WIDTH,
  layout: 'vertical',
  style: { overflow: 'hidden' },
})``

export const Container = styled.div`
  width: ${PROGRAM_ITEM_WIDTH}rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  height: 100%;
  margin-top: 3rem;
`

export const ListContainer = styled.div`
  display: flex;
  flex: 1;
  width: ${PROGRAM_ITEM_WIDTH}rem;
  height: 100%;
`

export const ChevronDownIcon = styled(ChevronDownIconComponent)`
  width: ${CHEVRON_ICON_WIDTH}rem;
  height: ${CHEVRON_ICON_HEIGHT}rem;
  margin-top: 16rem;
  margin-left: 35rem;
`

export const ProgramsListDivider = styled.div``

export const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

export const Placeholder = styled.div`
  color: ${getColorPrimaryContrastText};
  width: 100%;
  height: ${PROGRAM_ITEM_HEIGHT}rem;
  font-size: 32rem;
  line-height: 37rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.focused ? getColorPrimary(props) : getBackgroundSecondaryColor(props)};
`
