// @flow weak
/* eslint-disable no-confusing-arrow */
import { createSelector } from 'reselect'
import {
  values,
  pipe,
  prop,
  pick,
  sortWith,
  // $FlowFixMe
  descend,
  uniq,
  take,
  drop,
  last,
  toPairs,
  fromPairs,
  head,
  map,
  filter,
  intersection,
  keys,
  isEmpty,
  isNil,
  reject,
  propEq,
  propSatisfies,
} from 'ramda'

import type { Channel } from '@alphaott/api-client/types/channels'
import type { Genre } from '@alphaott/api-client/types/genres'
import type { Category } from '@alphaott/api-client/types/categories'

import { prepareChannelList } from '@alphaott/common-utils/utils/schemas/channels'
import { concatAll, isNotEmpty } from '@alphaott/common-utils/utils/help'

import type { Channels } from '@alphaott/common-utils/utils/filterChannels'
import type { PreparedChannel } from '@alphaott/common-utils/utils/schemas/channels'
import {
  RECENT_CHANNEL_MAX_COUNT,
  SUGGESTED_TV_CHANNEL_MAX_COUNT,
  POPULAR_CHANNELS_MAX_COUNT,
  MOST_WATCHED_CHANNEL_MAX_COUNT,
} from '@alphaott/app-config'

import { getRestrictedLevel } from '@alphaott/app-settings/models/selectors'
import { getCatchupChannels } from '@alphaott/app-catchup/models/selectors'
import { isNotNil } from '@alphaott/common-utils'
import { getChannels, getFavorite, getGenres, getCategories } from './channels'
import type { ChannelsStore } from '../types'

type Store = ChannelsStore

export type MostWatchedChannel = {
  [string]: number,
}

type RecentChannel = {
  id: string,
  total: number,
}
type RecentIds = Array<string>

type RecentWatchedChannel = Array<RecentChannel>
export type FavoriteChannels = Array<string>

export const isRecentChannelsLoading = (state: Store) => state.channelRecentWatched.isLoading
export const isRecentChannelsLoadedSuccess = (state: Store) => state.channelRecentWatched.isSuccess
export const getRecent = (state: Store): RecentWatchedChannel => state.channelRecentWatched.data
export const getMostWatched = (state: Store): MostWatchedChannel => state.channelMostWatched.data

export const getRecentIds = createSelector(
  [getRecent],
  (recentWatched: RecentWatchedChannel): RecentIds => map(item => prop('id', item), recentWatched),
)

export const getNotRestrictedChannels = createSelector(
  [getChannels, getRestrictedLevel],
  (channels: Channels = {}, parentControlLevel: ?number): Channels =>
    filter(
      propSatisfies(val => isNil(val) || isNil(parentControlLevel) || val < parentControlLevel, 'pgRating'),
      channels,
    ),
)

export const isRestrictedChannel = (channel = {}, parentControlLevel: ?number) =>
  propSatisfies(val => isNotNil(val) && isNotNil(parentControlLevel) && val >= parentControlLevel, 'pgRating', channel)

export const sortMostWatched = (mostWatched: MostWatchedChannel) => {
  /**
   * {channel_1: 60, ..., channel_n: 100}
   * toPairs =>
   * [[channel_1, 60], ..., [channel_n, 100]]
   * descendSort =>
   * [[channel_n, 100], ..., [channel_1, 60]]
   * map(head) =>
   * [channel_n, ..., channel_1]
   */
  const descendSort = sortWith([descend(last)])
  return pipe(
    toPairs,
    descendSort,
    map(head),
  )(mostWatched)
}

export const sortChannelsByRating = (channels: Channels) => {
  /**
   * {channel_1: {rating: 0.2}, ..., channel_n: {rating: 0.87}}
   * toPairs =>
   * [[channel_1, {rating: 0.2}], ..., [channel_n, {rating: 0.87}]]
   * descendSort =>
   *    [channel_1, {rating: 0.2}]
   *    last =>
   *        {rating: 0.2}
   *     prop =>
   *         0.2
   * map(head) =>
   * [channel_n, ..., channel_1]
   */
  const descendSort = sortWith([
    descend(
      pipe(
        last,
        prop('rating'),
      ),
    ),
  ])
  return pipe(
    toPairs,
    descendSort,
    map(head),
  )(channels)
}

export const calculateSuggested = (
  recentIds: RecentIds,
  mostWatched: MostWatchedChannel,
  channelList: Channels,
): Array<Channel> => {
  // See https://alpha-ott.atlassian.net/browse/MW-836
  const channelIds = pipe(
    concatAll,
    uniq,
    take(SUGGESTED_TV_CHANNEL_MAX_COUNT),
  )([
    take(RECENT_CHANNEL_MAX_COUNT, recentIds),
    sortMostWatched(mostWatched),
    drop(RECENT_CHANNEL_MAX_COUNT, recentIds),
    sortChannelsByRating(channelList),
  ])
  return pipe(
    pick(channelIds),
    values,
  )(channelList)
}

export const calculateMostWatched = (channels: Channels, mostWatched: MostWatchedChannel): Array<Channel> => {
  const descendSort = sortWith([descend(last)])
  /**
   * {channel_1: 60, ..., channel_n: 100}
   * toPairs =>
   * [[channel_1, 60], ..., [channel_n, 100]]
   * descendSort =>
   * [[channel_n, 100], ..., [channel_1, 60]]
   * take =>
   * [[channel_n, 100], ..., [channel_k, 70]]
   * fromPairs =>
   * {channel_n: 100, ..., channel_k: 70}
   */
  const mostWatchedChannels = pipe(
    toPairs,
    descendSort,
    take(MOST_WATCHED_CHANNEL_MAX_COUNT),
    fromPairs,
  )(mostWatched)
  return values(pick(keys(mostWatchedChannels), channels))
}

export const calculateRecent = (channels: Channels, recentIds: RecentIds): Array<Channel> =>
  pipe(
    pick(recentIds),
    values,
    take(RECENT_CHANNEL_MAX_COUNT),
  )(channels)

export const calculatePopular = (channelList: Channels): Array<Channel> =>
  pipe(
    pick(take(POPULAR_CHANNELS_MAX_COUNT, sortChannelsByRating(channelList))),
    values,
  )(channelList)

export const getRelated = (channels: Array<Channel>, selectedChannel: Channel): Array<Channel> => {
  const hasOption = (opt: string, channel: Channel): boolean =>
    isNotEmpty(intersection(selectedChannel[opt], channel[opt]))

  let result = filter(channel => hasOption('genres', channel), channels)
  if (isEmpty(result)) {
    result = filter(channel => hasOption('categories', channel), channels)
  }
  if (isEmpty(result)) {
    result = filter(channel => hasOption('languages', channel), channels)
  }
  return reject(propEq('_id', selectedChannel._id), result)
}

export const calculateRelated = (channels: Array<Channel>, selectedChannel: Channel): Array<Channel> =>
  isNil(selectedChannel) ? [] : getRelated(channels, selectedChannel)

export const getMostWatchedList = createSelector(
  [getMostWatched, getChannels, getFavorite, getGenres, getCategories, getCatchupChannels],
  (
    mostWatched: MostWatchedChannel,
    channels: Channels,
    favorite: FavoriteChannels,
    genres: Array<Genre>,
    categories: Array<Category>,
    catchupChannels,
  ): Array<PreparedChannel> =>
    prepareChannelList(calculateMostWatched(channels, mostWatched), favorite, genres, categories, catchupChannels),
)

export const getRecentList = createSelector(
  [getRecentIds, getChannels, getFavorite, getGenres, getCategories, getCatchupChannels],
  (
    recentIds: RecentIds,
    channels: Channels,
    favorite: FavoriteChannels,
    genres: Array<Genre>,
    categories: Array<Category>,
    catchupChannels,
  ): Array<PreparedChannel> =>
    prepareChannelList(calculateRecent(channels, recentIds), favorite, genres, categories, catchupChannels),
)

export const getPopularList = createSelector(
  [getChannels, getFavorite, getGenres, getCategories, getCatchupChannels],
  (
    channels: Channels,
    favorite: FavoriteChannels,
    genres: Array<Genre>,
    categories: Array<Category>,
    catchupChannels,
  ): Array<PreparedChannel> =>
    prepareChannelList(calculatePopular(channels), favorite, genres, categories, catchupChannels),
)

export const getSuggestedList = createSelector(
  [getRecentIds, getMostWatched, getChannels, getFavorite, getGenres, getCategories, getCatchupChannels],
  (
    recentIds: RecentIds,
    mostWatched: MostWatchedChannel,
    channels: Channels,
    favorite: FavoriteChannels,
    genres: Array<Genre>,
    categories: Array<Category>,
    catchupChannels,
  ): Array<PreparedChannel> =>
    prepareChannelList(
      calculateSuggested(recentIds, mostWatched, channels),
      favorite,
      genres,
      categories,
      catchupChannels,
    ),
)

export const getRestrictedSuggestedList = createSelector(
  [getRecentIds, getMostWatched, getNotRestrictedChannels, getFavorite, getGenres, getCategories, getCatchupChannels],
  (
    recentIds: RecentIds,
    mostWatched: MostWatchedChannel,
    channels: Channels,
    favorite: FavoriteChannels,
    genres: Array<Genre>,
    categories: Array<Category>,
    catchupChannels,
  ): Array<PreparedChannel> =>
    prepareChannelList(
      calculateSuggested(recentIds, mostWatched, channels),
      favorite,
      genres,
      categories,
      catchupChannels,
    ),
)

export const getRelatedList = (id: string) =>
  createSelector(
    [getFavorite, getChannels, getGenres, getCategories],
    (
      favorite: FavoriteChannels,
      channels: Channels,
      genres: Array<Genre>,
      categories: Array<Category>,
    ): Array<PreparedChannel> => {
      const channel = prop(id)(channels)
      return prepareChannelList(calculateRelated(values(channels), channel), favorite, genres, categories)
    },
  )

export const getRestrictedRelatedList = (id: string) =>
  createSelector(
    [getFavorite, getChannels, getNotRestrictedChannels, getGenres, getCategories, getRestrictedLevel],
    (
      favorite: FavoriteChannels,
      channels: Channels,
      restrictedChannels: Channels,
      genres: Array<Genre>,
      categories: Array<Category>,
      parentControlLevel: number,
    ): Array<PreparedChannel> => {
      const channel = prop(id)(channels)
      const allChannels = isRestrictedChannel(channel, parentControlLevel) ? channels : restrictedChannels
      return prepareChannelList(calculateRelated(values(allChannels), channel), favorite, genres, categories)
    },
  )
