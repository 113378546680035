// @flow
export const MOST_WATCHED_CHANNEL = 'channels/MOST_WATCHED'

export type MostWatchedChannelAction = {|
  type: 'channels/MOST_WATCHED',
  id: string,
  duration: number,
|}

export const mostWatchedChannel = (id: string, duration: number): MostWatchedChannelAction => ({
  type: MOST_WATCHED_CHANNEL,
  id,
  duration,
})
