// @flow

import { createSelector } from 'reselect'
import { prop } from 'ramda'

import type { PlayerStore } from '../types'
import type { PlayerControlsState } from '../reducers'

export const getPlayerControlsState = (state: PlayerStore): PlayerControlsState =>
  state.playerControls

export const getForceShowControls = createSelector(
  [getPlayerControlsState],
  (playerControls: PlayerControlsState): boolean => prop('isForceShowControls', playerControls),
)

export const getShowControls = createSelector(
  [getPlayerControlsState],
  (playerControls: PlayerControlsState): boolean => prop('isShowControls', playerControls),
)

export const getShowSubtitlesSettings = createSelector(
  [getPlayerControlsState],
  (playerControls: PlayerControlsState): boolean => prop('isShowSubtitlesSettings', playerControls),
)

export const getShowListControl = createSelector(
  [getPlayerControlsState],
  (playerControls: PlayerControlsState): boolean => prop('isShowListControl', playerControls),
)
