// @flow

import typeToReducer from 'type-to-reducer'
import { mergeDeepRight, assocPath } from 'ramda'

import type { UserSettingsState } from '@alphaott/common-types'

import { CHANGE_USER_SETTINGS, CHANGE_PREFERRED_TRACK } from '../actions'

export const initialState: UserSettingsState = {
  tracks: {},
  player: {
    muted: false,
    volume: 1,
  },
}

const userSettings = typeToReducer(
  {
    [CHANGE_USER_SETTINGS]: (state, { payload }) => mergeDeepRight(state, payload.settings),

    [CHANGE_PREFERRED_TRACK]: (state, { payload }) => {
      const { videoId, track, option } = payload

      return assocPath(['tracks', videoId, option], track, state)
    },
  },
  initialState,
)

export { userSettings }
