// @flow
import { createSelector } from 'reselect'
import { keys, map, pathOr, values, propOr, pipe, find, propEq, isNil, not, isEmpty, reject, path } from 'ramda'
import type {
  CategoriesNormalize,
  CountriesNormalize,
  GenresNormalize,
  LanguageNormalize,
} from '@alphaott/common-types/normalized'
import type { TVShow, TVShowDetail, TVShowEpisodeDetail, TVShowMediaStream } from '@alphaott/api-client/types/tvshows'
import {
  isInitialLoading,
  prepareTVShow,
  type PreparedTVShow,
  type PreparedTVShowEpisode,
  type PreparedTVShowSeason,
  prepareTVShowEpisode,
  prepareTVShowSeason,
  setDefaultBgImageToEpisodes,
  defaultToEmptyString,
  defaultToEmptyObject,
} from '@alphaott/common-utils'

import { getTVShowsMaxRating } from '@alphaott/app-main/selectors'

import type { TVShowsStore } from '../types'
import type {
  FavoriteTVShowsState,
  FavoriteTVShowsIdsState,
  NormalizedTrailers,
  TVShowDetailState,
  TVShowSeasonsState,
  TVShowProgress,
  RecentTVShowState,
  NormalizedTVShowProgress,
} from '../reducers'

export const selectFavoriteState = (store: TVShowsStore): FavoriteTVShowsState => store.tvshowFavorites
export const selectFavoriteIdsState = (store: TVShowsStore): FavoriteTVShowsIdsState => store.tvshowFavoriteIds
export const getFavoriteTVShowsIds = (store: TVShowsStore): string[] => store.tvshowFavoriteIds.data

export const selectGenresEntities = (store: TVShowsStore): GenresNormalize => store.tvshowGenres.entities
export const selectCategoriesEntities = (store: TVShowsStore): CategoriesNormalize => store.tvshowCategories.entities
export const selectLanguagesEntities = (store: TVShowsStore): LanguageNormalize => store.tvshowLanguages.entities
export const selectCountriesEntities = (store: TVShowsStore): CountriesNormalize => store.tvshowCountries.entities

const selectTVShowsSearchListEntities = (state: TVShowsStore): TVShow[] => state.tvshowsSearchList.data
export const selectTVShowsSearchListIsLoading = (state: TVShowsStore): boolean => state.tvshowsSearchList.isLoading
export const selectTVShowsSearchListIsInitialLoading = (state: TVShowsStore): boolean =>
  isInitialLoading(state.tvshowsSearchList)
export const hasMoreTVShowsSearchLis = (state: TVShowsStore): boolean =>
  pathOr(false, ['tvshowsSearchList', 'hasMore'], state)

export const selectTVShowState = (state: TVShowsStore): TVShowDetailState => state.tvshowDetail
export const selectTVShowDetail = (store: TVShowsStore): TVShow | {} => store.tvshowDetail.result

const selectTVShowRelatedEntities = (store: TVShowsStore): { [id: string]: TVShow } =>
  store.tvshowDetail.related.entities
const selectTVShowEpisodeDetail = (store: TVShowsStore): TVShowEpisodeDetail | {} => store.tvshowEpisodeDetail.result

export const selectTVShowTrailers = (store: TVShowsStore): NormalizedTrailers => store.tvshowDetail.trailers
export const selectTVShowSeasonTrailers = (store: TVShowsStore): NormalizedTrailers => store.tvshowSeasons.trailers

export const selectTVShowEpisodeIsLoading = (store: TVShowsStore): boolean =>
  isInitialLoading(store.tvshowEpisodeDetail, 'result')

export const selectTVShowEpisodeSources = (store: TVShowsStore): TVShowMediaStream[] => store.tvshowSources.data
export const selectTVShowEpisodeSourcesIsLoading = (store: TVShowsStore): boolean => store.tvshowSources.isLoading
export const selectTVShowEpisodeSourcesError = (store: TVShowsStore): any => store.tvshowSources.errors

export const selectTVShowEpisodeTrailers = (store: TVShowsStore): NormalizedTrailers =>
  store.tvshowEpisodeDetail.trailers

export const selectTVShowLimitsError = (state: TVShowsStore): any => state.tvshowLimits.errors

export const selectTVShowsSeasonEntitites = (state: TVShowsStore): $PropertyType<TVShowSeasonsState, 'entities'> =>
  state.tvshowSeasons.entities
export const selectActiveShowId = (state: TVShowsStore): string => state.currentMedia.activeTVShow || ''
export const selectActiveSeasonId = (state: TVShowsStore): string => state.currentMedia.activeTVSeason || ''
export const selectActiveEpisodeId = (state: TVShowsStore): string => state.currentMedia.activeTVEpisode || ''

export const selectRecentTVShowsState = (state: TVShowsStore): RecentTVShowState => state.tvshowRecent
export const selectRecentTVShowsIsLoading = (state: TVShowsStore): boolean => state.tvshowRecent.isLoading
export const selectRecentTVShowsIsSuccess = (state: TVShowsStore): boolean => state.tvshowRecent.isSuccess

export const selectRecentTVShowsIds = (state: TVShowsStore): string[] =>
  pathOr([], ['data', 'idList'], state.tvshowRecentProgress)
export const selectRecentTVShowsProgress = (state: TVShowsStore): NormalizedTVShowProgress =>
  pathOr({}, ['data', 'entities'], state.tvshowRecentProgress)

export const selectTVShowMediaStream: ?TVShowMediaStream = createSelector(
  [selectTVShowEpisodeSources],
  (mediaStreams: TVShowMediaStream[]): TVShowMediaStream | {} => mediaStreams[0] || {},
)

export const isFavoriteTVShow = (tvshowId: string) =>
  createSelector(
    [getFavoriteTVShowsIds],
    (favorite: FavoriteTVShowsIdsState): boolean =>
      pipe(
        find(propEq('item', tvshowId)),
        isNil,
        not,
      )(favorite),
  )

export const selectTVShowDetails = createSelector(
  [
    selectTVShowDetail,
    selectGenresEntities,
    selectCategoriesEntities,
    selectLanguagesEntities,
    selectCountriesEntities,
    selectFavoriteIdsState,
    getTVShowsMaxRating,
  ],
  (
    tvshow: TVShowDetail,
    genres: GenresNormalize,
    categories: CategoriesNormalize,
    languages: LanguageNormalize,
    countries: CountriesNormalize,
    favoriteIds: FavoriteTVShowsIdsState,
    maxRating: number,
  ): PreparedTVShow | {} =>
    prepareTVShow(tvshow, genres, categories, languages, countries, favoriteIds.data, maxRating),
)

export const selectIsTVShowLoading = createSelector(
  [selectTVShowState],
  (tvshow: TVShowDetailState): boolean => isInitialLoading(tvshow, 'result'),
)
export const selectTVShowEpisode = createSelector(
  [selectTVShowEpisodeDetail, selectGenresEntities],
  (episode: TVShowEpisodeDetail, genres: GenresNormalize): PreparedTVShowEpisode | {} =>
    prepareTVShowEpisode(episode, genres),
)

export const selectActiveTVShowSeasonData = createSelector(
  [selectActiveSeasonId, selectTVShowsSeasonEntitites, selectGenresEntities, selectTVShowDetail],
  (
    id: string,
    seasons: $PropertyType<TVShowSeasonsState, 'entities'>,
    genres: GenresNormalize,
    tvshow: TVShowDetail,
  ): PreparedTVShowSeason | {} => prepareTVShowSeason(pathOr({}, [id, 'result'], seasons), genres, tvshow),
)
export const selectActiveTVShowSeasonIsLoading = createSelector(
  [selectActiveSeasonId, selectTVShowsSeasonEntitites],
  (id: string, seasons: $PropertyType<TVShowSeasonsState, 'entities'>): boolean =>
    isInitialLoading(seasons[id], 'result'),
)

export const selectEpisodesForSeasonIsLoading = (seasonId: string) =>
  createSelector(
    [selectTVShowsSeasonEntitites],
    seasons => pathOr(false, ['isLoading'], seasons[seasonId]),
  )

export const selectEpisodesForSeason = (seasonId: string) =>
  createSelector(
    [selectTVShowDetail, selectTVShowsSeasonEntitites, selectRecentTVShowsProgress],
    (
      tvshow: TVShow,
      seasons: $PropertyType<TVShowSeasonsState, 'entities'>,
      recentHistory: NormalizedTVShowProgress,
    ) => {
      const season = pathOr({}, ['result'], seasons[seasonId])
      const episodesWithBg = setDefaultBgImageToEpisodes(season, tvshow)
      const episodesHistory = pathOr({}, [tvshow._id, 'seasons', seasonId, 'episodes'], recentHistory)

      const res = map(ep => {
        const runtime = propOr(0, 'runtime', ep) * 60 // Seconds
        const current = pathOr(0, [ep._id, 'current'], episodesHistory) // Seconds
        const total = path([ep._id, 'total'], episodesHistory) // Seconds

        return {
          ...ep,
          runtime,
          current,
          total,
        }
      }, episodesWithBg)

      return res
    },
  )

export const selectEpisodesForSeasons = (seasons: any[] = []) =>
  createSelector(
    [selectTVShowDetail, selectTVShowsSeasonEntitites, selectRecentTVShowsProgress],
    (
      tvshow: TVShow,
      seasonEntities: $PropertyType<TVShowSeasonsState, 'entities'>,
      recentHistory: NormalizedTVShowProgress,
    ) => {
      const seasonsWithEpisodes = {}
      map(({ id }) => {
        const season = pathOr({}, ['result'], seasonEntities[id])
        const episodesWithBg = setDefaultBgImageToEpisodes(season, tvshow)
        const episodesHistory = pathOr({}, [tvshow._id, 'seasons', id, 'episodes'], recentHistory)

        const res = map(ep => {
          const runtime = propOr(0, 'runtime', ep) * 60 // Seconds
          const current = pathOr(0, [ep._id, 'current'], episodesHistory) // Seconds
          const total = pathOr(0, [ep._id, 'total'], episodesHistory) // Seconds

          return {
            ...ep,
            runtime,
            current,
            total,
          }
        }, episodesWithBg)

        seasonsWithEpisodes[id] = res
      }, seasons)
      return seasonsWithEpisodes
    },
  )

/* SEARCH LIST */
export const selectTVShowsSearchList = createSelector(
  [
    selectTVShowsSearchListEntities,
    selectGenresEntities,
    selectCategoriesEntities,
    selectLanguagesEntities,
    selectCountriesEntities,
    selectFavoriteIdsState,
    getTVShowsMaxRating,
  ],
  (
    tvshows: TVShow[],
    genres: GenresNormalize,
    categories: CategoriesNormalize,
    languages: LanguageNormalize,
    countries: CountriesNormalize,
    favoriteIds: FavoriteTVShowsIdsState,
    maxRating: number,
  ): Array<PreparedTVShow> =>
    map(item => prepareTVShow(item, genres, categories, languages, countries, favoriteIds.data, maxRating), tvshows),
)

/* RELATED */
export const selectRelatedTVShows = createSelector(
  [
    selectTVShowRelatedEntities,
    selectGenresEntities,
    selectCategoriesEntities,
    selectLanguagesEntities,
    selectCountriesEntities,
    selectFavoriteIdsState,
    getTVShowsMaxRating,
  ],
  (
    tvshows: { [id: string]: TVShow },
    genres: GenresNormalize,
    categories: CategoriesNormalize,
    languages: LanguageNormalize,
    countries: CountriesNormalize,
    favoriteIds: FavoriteTVShowsIdsState,
    maxRating: number,
  ): Array<PreparedTVShow> =>
    map(
      item => prepareTVShow(item, genres, categories, languages, countries, favoriteIds.data, maxRating),
      values(tvshows),
    ),
)

/* TRAILERS */
export const selectTVShowTrailerMediaStream = (trailerId?: string) =>
  createSelector(
    [selectTVShowTrailers],
    (trailers: NormalizedTrailers): TVShowMediaStream | {} =>
      pathOr({}, [trailerId || keys(trailers)[0], 'data'], trailers),
  )
export const selectTVShowTrailerMediaStreamIsLoading = (trailerId?: string) =>
  createSelector(
    [selectTVShowTrailers],
    (trailers: NormalizedTrailers): TVShowMediaStream | {} =>
      pathOr(false, [trailerId || keys(trailers)[0], 'isLoading'], trailers),
  )
export const selectTVShowTrailerSourcesErrors = (trailerId?: string) =>
  createSelector(
    [selectTVShowTrailers],
    (trailers: NormalizedTrailers): any | null => pathOr(null, [trailerId || keys(trailers)[0], 'errors'], trailers),
  )

export const selectTVShowSeasonTrailerSources = (trailerId: string) =>
  createSelector(
    [selectTVShowSeasonTrailers],
    (trailers: NormalizedTrailers): TVShowMediaStream | {} => pathOr({}, [trailerId, 'data'], trailers),
  )
export const selectTVShowSeasonTrailerIsLoading = (trailerId: string) =>
  createSelector(
    [selectTVShowSeasonTrailers],
    (trailers: NormalizedTrailers): TVShowMediaStream | {} => pathOr(false, [trailerId, 'isLoading'], trailers),
  )
export const selectTVShowSeasonTrailerErrors = (trailerId: string) =>
  createSelector(
    [selectTVShowSeasonTrailers],
    (trailers: NormalizedTrailers): any | null => pathOr(null, [trailerId, 'errors'], trailers),
  )

export const selectTVShowEpisodeTrailerSources = (trailerId: string) =>
  createSelector(
    [selectTVShowEpisodeTrailers],
    (trailers: NormalizedTrailers): TVShowMediaStream | {} => pathOr({}, [trailerId, 'data'], trailers),
  )

export const selectTVShowEpisodeTrailerIsLoading = (trailerId: string) =>
  createSelector(
    [selectTVShowEpisodeTrailers],
    (trailers: NormalizedTrailers): boolean => pathOr(false, [trailerId, 'isLoading'], trailers),
  )

export const selectTVShowEpisodeTrailerErrors = (trailerId: string) =>
  createSelector(
    [selectTVShowEpisodeTrailers],
    (trailers: NormalizedTrailers): any | null => pathOr(null, [trailerId, 'errors'], trailers),
  )
/* RECENT */
export const selectIsRecentTVShowInitialLoading = createSelector(
  [selectRecentTVShowsState],
  (recent: RecentTVShowState): boolean => isInitialLoading(recent),
)

export const selectRecentTVShows = createSelector(
  [
    selectRecentTVShowsState,
    selectRecentTVShowsProgress,
    selectGenresEntities,
    selectCategoriesEntities,
    selectLanguagesEntities,
    selectCountriesEntities,
    selectFavoriteIdsState,
    getTVShowsMaxRating,
  ],
  (
    tvshows: RecentTVShowState,
    tvshowsHistory: NormalizedTVShowProgress,
    genres: GenresNormalize,
    categories: CategoriesNormalize,
    languages: LanguageNormalize,
    countries: CountriesNormalize,
    favoriteIds: FavoriteTVShowsIdsState,
    maxRating: number,
  ): Array<PreparedTVShow> =>
    reject(
      isEmpty,
      map(id => {
        const detailedShow = prepareTVShow(
          defaultToEmptyObject(find(propEq('_id', id))(tvshows.data)),
          genres,
          categories,
          languages,
          countries,
          favoriteIds.data,
          maxRating,
        )

        if (isEmpty(detailedShow)) return {}

        const historyForTVShow = propOr({}, id, tvshowsHistory)

        return {
          ...detailedShow,
          seasonId: historyForTVShow.lastSeasonId,
          episodeId: historyForTVShow.lastEpisodeId,
        }
      }, keys(tvshowsHistory)),
    ),
)

// we cannot use activeShowId here because user may come from direct url
export const selectHistoryForTVShowId = (showId: string) =>
  createSelector(
    [selectRecentTVShowsProgress],
    (recent: NormalizedTVShowProgress) => defaultToEmptyObject(recent[showId]),
  )

export const selectLastWatchedTVShowSeason = (showId: string) =>
  createSelector(
    [selectHistoryForTVShowId(showId)],
    (show: TVShowProgress): string => defaultToEmptyString(show.lastSeasonId),
  )

export const selectLastWatchedTVShowEpisode = (showId: string) =>
  createSelector(
    [selectHistoryForTVShowId(showId)],
    (show: TVShowProgress): string => defaultToEmptyString(show.lastEpisodeId),
  )

export const selectHistoryForEpisode = (showId: string, seasonId?: string, episodeId?: string) =>
  createSelector(
    [selectHistoryForTVShowId(showId)],
    (tvshow: TVShowProgress) => pathOr({}, ['seasons', seasonId, 'episodes', episodeId], tvshow),
  )

export const isRecentTVShow = (showId: string) =>
  createSelector(
    [selectRecentTVShowsState],
    (recent: RecentTVShowState): boolean =>
      pipe(
        find(propEq('_id', showId)),
        isNil,
        not,
      )(recent.data),
  )
