// @flow

import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import i18n from 'i18next'
import { find, path, prop, head } from 'ramda'

import { useUserSettings } from '@alphaott/user-settings'
import { getCustomerTracksSettings } from '@alphaott/app-core/selectors'
import { getBrowserLanguageKey } from '@alphaott/common-utils/utils/languages'
import { isEqualLanguages } from '@alphaott/common-utils/settings'
import { prepareTrackLanguage } from '@alphaott/app-core/models/utils'
import { DISABLED_LANGUAGE_OPTION, PLAYER_AUDIO_PARAM, PLAYER_SUBTITLES_PARAM } from '@alphaott/app-config'

export const useTracks = (videoId: string) => {
  const { settings } = useUserSettings()

  const customerTracksSettings = useSelector(getCustomerTracksSettings)

  const handleFindTrack = useCallback((availableLanguages, availableTracks) => {
    const hasLanguage = track => find(item => isEqualLanguages(prop('language', item), track), availableTracks)

    /* eslint-disable no-restricted-syntax */
    for (const item of availableLanguages) {
      const availableTrack = hasLanguage(item)
      if (availableTrack) return availableTrack
    }
    /* eslint-enable */
    return head(availableTracks)
  }, [])

  const handleGetAvailableLanguages = useCallback(
    (option: string) => {
      const savedTrackLanguage = path(['tracks', videoId, option], settings)
      const primaryLanguage = prepareTrackLanguage(option, 'primary', path([option, 0], customerTracksSettings))
      const secondaryLanguage = prepareTrackLanguage(option, 'secondary', path([option, 1], customerTracksSettings))
      const interfaceLanguage = prop('language', i18n)
      const browserLanguageKey = getBrowserLanguageKey()

      return [savedTrackLanguage, primaryLanguage, secondaryLanguage, interfaceLanguage, browserLanguageKey]
    },
    [videoId, settings, customerTracksSettings],
  )

  const preferredAudioLanguages = useMemo(() => handleGetAvailableLanguages(PLAYER_AUDIO_PARAM), [
    handleGetAvailableLanguages,
  ])

  const preferredSubtitleLanguages = useMemo(() => handleGetAvailableLanguages(PLAYER_SUBTITLES_PARAM), [
    handleGetAvailableLanguages,
  ])

  const handleGetPreferredAudioTrack = useCallback(
    (availableTracks: Array<any>) => handleFindTrack(preferredAudioLanguages, availableTracks),
    [preferredAudioLanguages, handleFindTrack],
  )

  const handleGetPreferredSubtitleTrack = useCallback(
    (availableTracks: Array<any>) =>
      handleFindTrack(preferredSubtitleLanguages, [
        {
          language: DISABLED_LANGUAGE_OPTION.key,
          active: false,
        },
        ...availableTracks,
      ]),
    [preferredSubtitleLanguages, handleFindTrack],
  )

  return {
    preferredAudioLanguage: preferredAudioLanguages?.[0],
    preferredSubtitleLanguage: preferredSubtitleLanguages?.[0],
    preferredAudioLanguages,
    preferredSubtitleLanguages,
    getAudioTrack: handleGetPreferredAudioTrack,
    getSubtitlesTrack: handleGetPreferredSubtitleTrack,
  }
}

export default useTracks
