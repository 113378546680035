// @flow
import { push } from 'redux-first-history'
import { v4 as uuidv4 } from 'uuid'
import { isEmpty } from 'ramda'

import type { LocationShape } from 'react-router-dom'

import {
  getCustomer,
  updateCustomer,
  updateCustomerSettings,
  changePassword,
  voucherActivate,
  authorize,
  deactivateCustomer,
} from '@alphaott/api-client'
import { withoutDataLogoutWrapper, formLogoutWrapper, logoutRequestWrapper } from '@alphaott/app-main/utils'
import { getCurrentDevice } from '@alphaott/app-devices/selectors'
import { getUISignupFields, getContentDomain } from '@alphaott/app-main/models/selectors'
import { CLIENT_ID, CLIENT_SECRET } from '@alphaott/app-config'

type Location = string | LocationShape
type PasswordData = {
  oldPassword: string,
  newPassword: string,
  confirmPassword: string,
}
type VoucherData = {
  code: string,
}

export const FETCH_CUSTOMER_TYPE = 'customer/FETCH_CUSTOMER'
export const UPDATE_CUSTOMER_TYPE = 'customer/UPDATE_CUSTOMER'
export const UPDATE_CUSTOMER_SETTINGS_TYPE = 'customer/UPDATE_CUSTOMER_SETTINGS'
export const CHANGE_PASSWORD_CUSTOMER_TYPE = 'customer/CHANGE_PASSWORD'
export const ACTIVATE_VOUCHER_CUSTOMER_TYPE = 'customer/ACTIVATE_VOUCHER'
export const CUSTOMER_AUTH_BY_QR_CODE = 'customer/CUSTOMER_AUTH_BY_QR_CODE'

export const CUSTOMER_DEACTIVATE = 'customer/DEACTIVATE'

export const fetchCustomer = (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_CUSTOMER_TYPE,
    payload: withoutDataLogoutWrapper(getCustomer)(dispatch, getState),
    meta: {
      fields: getUISignupFields(getState()),
    },
  })

export const update = (data: any, redirect?: Location = '/account') => (dispatch: Function, getState: Function) => {
  if (isEmpty(data)) {
    return dispatch(push(redirect))
  }

  return dispatch({
    type: UPDATE_CUSTOMER_TYPE,
    payload: formLogoutWrapper(updateCustomer, redirect)({ data })(dispatch, getState),
    meta: {
      fields: getUISignupFields(getState()),
    },
  })
}

export const updateSettings = (data: any, redirect?: Location = '/account/settings') => (
  dispatch: Function,
  getState: Function,
) => {
  if (isEmpty(data)) {
    return dispatch(push(redirect))
  }

  return dispatch({
    type: UPDATE_CUSTOMER_SETTINGS_TYPE,
    payload: formLogoutWrapper(updateCustomerSettings, redirect)({ data })(dispatch, getState),
  })
}

export const updatePassword = (data: PasswordData, redirect?: string = '/account') => (
  dispatch: Function,
  getState: Function,
) =>
  dispatch({
    type: CHANGE_PASSWORD_CUSTOMER_TYPE,
    payload: formLogoutWrapper(changePassword, redirect)({ data })(dispatch, getState),
  })

export const activateVoucher = (data: VoucherData, redirect?: string = '/account') => (
  dispatch: Function,
  getState: Function,
) =>
  dispatch({
    type: ACTIVATE_VOUCHER_CUSTOMER_TYPE,
    payload: formLogoutWrapper(voucherActivate, redirect)({
      data,
    })(dispatch, getState),
    meta: {
      contentApi: getContentDomain(getState()),
    },
  })

export const authorizeQRCode = (dispatch: Function, getState: Function) => {
  const device = getCurrentDevice(getState())
  const requestData = {
    response_type: 'code',
    scope: 'client-api',
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    state: uuidv4(),
    device,
  }

  return dispatch({
    type: CUSTOMER_AUTH_BY_QR_CODE,
    payload: logoutRequestWrapper(authorize)({ data: requestData })(dispatch, getState),
  })
}

export const deactivate = (dispatch: Function, getState: Function) =>
  dispatch({
    type: CUSTOMER_DEACTIVATE,
    payload: logoutRequestWrapper(deactivateCustomer)({})(dispatch, getState),
  })
