// @flow

import React, { memo, useMemo, useEffect } from 'react'
import { or, and } from 'ramda'

import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'
import { useHorizontalArrows } from '@alphaott/smart-tv-common/src/hooks/useHorizontalArrows'

import { StyledInput, FormGroup, WarningLabel } from './TextField.style'

const TextFieldPure = ({
  focusKey,
  input,
  type,
  placeholder,
  meta,
  onKeyDown,
  switchFocusKey,
  ...rest
}) => {
  const { ref, focused, setFocus } = useFocusable({ focusKey })
  const handleOnKeyDown = useHorizontalArrows({ ref, switchFocusKey, setFocus, onKeyDown })

  const { touched, error, pristine, valid } = meta
  const isValid = useMemo(() => or(pristine, valid), [pristine, valid])
  const hasError = useMemo(() => and(touched, error), [touched, error])

  useEffect(() => {
    if (ref.current) {
      if (focused) {
        ref.current.focus()
      } else {
        ref.current.blur()
      }
    }
  }, [focused, ref])

  return (
    <FormGroup>
      <StyledInput
        {...input}
        {...rest}
        ref={ref}
        type={type}
        placeholder={placeholder}
        isValid={isValid}
        hasError={hasError}
        onKeyDown={handleOnKeyDown}
      />
      {hasError && <WarningLabel>{error}</WarningLabel>}
    </FormGroup>
  )
}

export const TextField = memo(TextFieldPure)

export default TextField
