// @flow
import styled from 'styled-components'
import { Text } from '@alphaott/smart-tv-components'
import {
  getColorPrimary,
  getColorSlightPrimary,
  getColorPrimaryContrastText,
} from '@alphaott/smart-tv-themes'

import { CHANNEL_CARD_WIDTH, CHANNEL_CARD_HEIGHT, CHANNEL_CARD_ITEM_HEIGHT } from '../ChannelCard'

export const ChannelCardContainer = styled.div`
  width: ${CHANNEL_CARD_WIDTH}rem;
  height: ${CHANNEL_CARD_ITEM_HEIGHT}rem;
`

export const ChannelCardImage = styled.div`
  width: 116rem;
  height: 58rem;
  fill: ${getColorPrimaryContrastText};
`

export const ChannelCardImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${CHANNEL_CARD_WIDTH}rem;
  height: ${CHANNEL_CARD_HEIGHT}rem;
  border-radius: 6rem;
  border: ${(props) => (props.focused ? '8rem solid' : 'none')};
  margin: ${(props) => (props.focused ? '0' : '8rem')};
  border-color: ${(props) => (props.focused ? getColorPrimary(props) : 'transparent')};
  overflow: hidden;
  background-color: ${getColorPrimary};
  &:hover {
    border-color: ${(props) => {
      if (props.focused) return getColorPrimary(props)
      return getColorSlightPrimary(props)
    }}
`

export const ChannelCardTitleBox = styled.div`
  height: 34rem;
  overflow: hidden;
`

export const ChannelCardTitle = styled(Text)`
  display: inline-block;
  width: ${CHANNEL_CARD_WIDTH}rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  letter-spacing: 0.7rem;
`
