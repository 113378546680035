// @flow

const getTopLevelDomain = (origin: string): string =>
  origin
    .toLocaleLowerCase()
    .split('.')
    .pop()

const langByTLD = {
  cl: 'es', // cl -> Chile -> Spanish
}

/**
 * Language detector by TLD (top-level domain)
 * Ex. hiplay.cl -> TLD=cl -> Spanish
 */
export default {
  name: 'domain',

  lookup() {
    if (typeof window === 'undefined') {
      return undefined
    }

    const tld = getTopLevelDomain(window.location.origin)
    const lang = langByTLD[tld]
    return lang
  },
}
