// @flow
import React, { memo, lazy, Suspense, useEffect, useMemo } from 'react'
import { Routes, Route } from 'react-router-dom'
import { HistoryRouter } from 'redux-first-history/rr6'
import { replace } from 'redux-first-history'
import { useDispatch, useSelector } from 'react-redux'
import { not, and, includes } from 'ramda'
import { isNotNilOrEmpty, notEqual } from 'ramda-adjunct'

import { PageLoaderInner } from '@alphaott/smart-tv-components/src/components/PageView/PageLoader'

import { getRoutingPathname } from '@alphaott/app-main/selectors'

import { usePageAnalytics } from '@alphaott/app-analytics'
import { usePrevious } from '@alphaott/common-utils'
import { prepareBasename } from '@alphaott/smart-tv-common'

import AppNavigation from './AppNavigation'
import NotFoundContainer from '../containers/NotFound'
import SupportContainer from '../containers/Support'
import ErrorContainer from '../containers/Error'

import { userIsAuthenticated, hasError, hasNoError } from './hocs'

const AuthorizationPageContainer = lazy(() => import('../containers/Authorization'))

const ErrorView = hasError(ErrorContainer)
const AppNavigationView = hasNoError(userIsAuthenticated(AppNavigation))

type RootNavigationProps = {
  basename?: string,
  history: any,
}

const RootNavigationPure = ({ basename, history }: RootNavigationProps) => {
  const dispatch = useDispatch()

  usePageAnalytics(history)

  const pathname = useSelector(getRoutingPathname)
  const prevPathname = usePrevious(pathname)

  const preparedBasename = useMemo(() => prepareBasename(basename), [basename])

  // eslint-disable-next-line max-len
  // This is workaround for this issue - https://github.com/salvoravida/redux-first-history/issues/65
  // eslint-disable-next-line complexity
  useEffect(() => {
    if (isNotNilOrEmpty(preparedBasename)) {
      if (and(not(includes(preparedBasename, pathname)), notEqual(pathname, prevPathname))) {
        dispatch(replace(`${preparedBasename}${pathname}`))
      }
    }
  }, [pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <HistoryRouter history={history} basename={preparedBasename}>
      <Suspense fallback={<PageLoaderInner />}>
        <Routes>
          <Route path="notfound" element={<NotFoundContainer />} />
          <Route path="support" element={<SupportContainer />} />
          <Route path="error" element={<ErrorView />} />

          <Route path="auth/*" element={<AuthorizationPageContainer />} />

          <Route path="/*" element={<AppNavigationView />} />
          <Route path="*" element={<NotFoundContainer />} />
        </Routes>
      </Suspense>
    </HistoryRouter>
  )
}

export const RootNavigation = memo<RootNavigationProps>(RootNavigationPure)

export default RootNavigation
