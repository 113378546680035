// @flow

import React, { memo, useRef } from 'react'

import { AlphaottVideoPlayer } from '@alphaott/smart-tv-video-player'
import { withLimitError } from '@alphaott/smart-tv-components'
import { ENTITY_TYPE } from '@alphaott/app-server-driven-ui/const'

import { ChannelVideoPlayerControls } from './ChannelVideoPlayerControls'
import { Container } from './ChannelVideoPlayer.styles'

import type { ChannelVideoPlayerControlsProps } from './ChannelVideoPlayerControls'

type ChannelVideoPlayerProps = {
  isError?: boolean,
  mediaStream: string,
  drm: Object,
  poster?: string,
  channelCardPlaceholder?: string,
  contentType?: string,
  openChannelsListButtonTitle?: string,
  onProgress: Function,
} & ChannelVideoPlayerControlsProps

// $FlowFixMe
const ChannelVideoPlayerPure = ({
  isError,
  channelId,
  mediaStream,
  drm,
  poster,
  channelCardPlaceholder,
  contentType = ENTITY_TYPE.LIVE_TV,
  openChannelsListButtonTitle,
  program,
  onProgress,
  onShowLimitError,
  ...props
}: ChannelVideoPlayerProps) => {
  const shakaPlayerRef = useRef(null)

  return (
    <Container>
      <AlphaottVideoPlayer
        autoplay
        liveStream
        contentType={contentType}
        ref={shakaPlayerRef}
        videoId={channelId}
        mediaStream={mediaStream}
        drm={drm}
        poster={poster}
        isError={isError}
        onProgress={onProgress}
        onShowLimitError={onShowLimitError}
      />
      <ChannelVideoPlayerControls
        {...props}
        channelId={channelId}
        program={program}
        channelCardPlaceholder={channelCardPlaceholder}
        openChannelsListButtonTitle={openChannelsListButtonTitle}
      />
    </Container>
  )
}

export const ChannelVideoPlayer = memo(withLimitError(ChannelVideoPlayerPure))
export default ChannelVideoPlayer
