// @flow
import React from 'react'

import { AlphaTheme } from './alphaott'
import type { Theme } from './types'

export type ThemeProviderType = {
  styledTheme: Theme,
  brandConfig: any,
  logo: any,
  logoImage?: any,
  splash: any,
  extra?: any,
  authBg?: any,
}

export const ThemeProvider = React.createContext<ThemeProviderType>(AlphaTheme)

export default ThemeProvider
