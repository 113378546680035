// @flow

import React, { memo } from 'react'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'

import { Container, Avatar, Name, EditButton } from './ProfileCard.style'

export type ProfileCardProps = {
  focusKey?: string,
  style?: Object,
  name: string,
  avatar: any,
  onFocus?: Function,
  onChange?: Function,
  onEdit?: Function,
}

const ProfileCardPure = ({
  focusKey: parentFocusKey,
  style,
  name,
  avatar,
  onFocus,
  onChange,
  onEdit,
}: ProfileCardProps) => {
  const { ref, focusKey, hasFocusedChild } = useFocusable({
    focusKey: parentFocusKey,
    trackChildren: true,
    onFocus,
    onEnterPress: onChange,
  })

  return (
    <FocusContext.Provider value={focusKey}>
      <Container ref={ref} style={style}>
        <Avatar image={avatar} highlighted={hasFocusedChild} onClick={onChange} />
        <Name highlighted={hasFocusedChild}>{name}</Name>

        {hasFocusedChild && <EditButton onClick={onEdit} />}
      </Container>
    </FocusContext.Provider>
  )
}

export const ProfileCard = memo<ProfileCardProps>(ProfileCardPure)

export default ProfileCard
