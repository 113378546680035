// @flow

import { map, or } from 'ramda'

import { DEFAULT_LANGUAGE_OPTION } from '@alphaott/app-config'

import { useTranslateDefaultLanguageOption } from './useTranslateDefaultLanguageOption'
import { getLanguageNativeNameByKey } from '../utils'
import { prepareLanguageKeyWithDialect } from '../player'

export const usePrepareLanguageItems = (languages: string[]) => {
  const { nativeName: defaultValue } = useTranslateDefaultLanguageOption(DEFAULT_LANGUAGE_OPTION)

  return map(key => {
    const preparedKey = prepareLanguageKeyWithDialect(key)

    return {
      key: preparedKey,
      // $FlowFixMe
      nativeName: or(getLanguageNativeNameByKey(preparedKey), defaultValue),
    }
  })(languages)
}
