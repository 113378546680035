// @flow
import typeToReducer from 'type-to-reducer'
import type { BrandConfig } from '@alphaott/common-types'

import { FETCH_BRAND_CONFIG_TYPE, SET_BRAND_CONFIG_TYPE } from '../../actions/brand'

export type BrandState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: BrandConfig,
}

const initialState: BrandState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  // BrandState has required fields, brand should impor data from brand_config.json
  // $FlowFixMe
  data: {},
}

const brandConfig = typeToReducer(
  {
    [SET_BRAND_CONFIG_TYPE]: (state, { payload }) => ({
      ...state,
      isSuccess: true,
      data: payload,
    }),
    [FETCH_BRAND_CONFIG_TYPE]: {
      LOADING: (state): BrandState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): BrandState => ({
        ...state,
        errors: [],
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: action.payload.data,
      }),

      ERROR: (state, action): BrandState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
  },
  initialState,
)

export { brandConfig }
