import './polyfill'

import './wdyr'
import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'

import { isWeb } from '@alphaott/smart-tv-platforms'

import { arrive } from './core'
import reportWebVitals from './reportWebVitals'
import App from './App'

const init = () => {
  // TODO: Add in the future <React.StrictMode />
  const root = createRoot(document.getElementById('root'))
  root.render(<App />)
}

if (isWeb) {
  init()
} else {
  arrive('root', () => {
    init()
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
