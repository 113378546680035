import { mapObjIndexed } from 'ramda'

export const migrations = {
  1: (state) => ({
    ...state,
    userSettings: {
      languages: {
        audio: {
          primary: {},
          secondary: {},
        },
        subtitles: {
          primary: {},
          secondary: {},
        },
      },
      player: {
        ...state.userSettings.player,
        tracks: {},
      },
    },
  }),
  2: (state) => ({
    ...state,
    userSettings: {
      tracks: mapObjIndexed((item) => (item.key === 'no subtitles' ? null : item))(
        state.userSettings.tracks,
      ),
      player: state.userSettings.player,
    },
  }),
}
