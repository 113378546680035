// @flow weak
import { createSelector } from 'reselect'
import {
  // $FlowFixMe
  ascend,
  values,
  sortWith,
  pipe,
  includes,
  prop,
  curry,
  either,
  intersection,
  path,
  pathOr,
  filter,
  head,
  always,
  cond,
  T,
  propOr,
} from 'ramda'

import type { Channel, ChannelMediaStream, ChannelMediaStreams } from '@alphaott/api-client/types/channels'
import type { Genre } from '@alphaott/api-client/types/genres'
import type { Category } from '@alphaott/api-client/types/categories'
import type { Language } from '@alphaott/api-client/types/languages'
import type { Country } from '@alphaott/api-client/types/countries'

import { filterChannels, FILTER_NAMES } from '@alphaott/common-utils/utils/filterChannels'
import { prepareChannelList, prepareChannel } from '@alphaott/common-utils/utils/schemas/channels'
import { getParam } from '@alphaott/common-utils/utils/urlSearchParams'
import {
  mapId,
  isNotEmpty,
  isNotNilOrEmpty,
  defaultToEmptyArray,
  defaultToEmptyObject,
} from '@alphaott/common-utils/utils/help'
import { isURL, insertVariablesInJSON } from '@alphaott/common-utils'
import { PUBLIC_URL } from '@alphaott/app-config'

import type { Channels, FilterObject } from '@alphaott/common-utils/utils/filterChannels'
import type { PreparedChannel } from '@alphaott/common-utils/utils/schemas/channels'
import type { Routing } from '@alphaott/common-types'
import { getRouting, getOptionsPropertyFromConfigAndBrand } from '@alphaott/app-main/selectors'
import { getCatchupChannels } from '@alphaott/app-catchup/models/selectors'

import type { ChannelLimitState } from '../reducers/channelLimits'
import type { ChannelsStore } from '../types'

type FavoriteChannels = Array<string>

type GetFilteredChannelsProps = {
  genres: Array<Genre>,
  categories: Array<Category>,
  languages: Array<Language>,
  countries: Array<Country>,
  search: string,
}

export type AspectRatio = {
  aspectRatio?: string,
}

export const getChannels = (state: ChannelsStore): Channels => state.channels.entities
export const getMediaStreams = (state: ChannelsStore): ChannelMediaStreams => state.channelSources.data
export const getFavorite = (state: ChannelsStore): FavoriteChannels => state.channelFavorite.data
export const getGenres = (state: ChannelsStore): Array<Genre> => state.channelGenres.data
export const getCategories = (state: ChannelsStore): Array<Category> => state.channelCategories.data
export const getLanguages = (state: ChannelsStore): Array<Language> => state.channelLanguages.data
export const getCountries = (state: ChannelsStore): Array<Country> => state.channelCountries.data
export const getChannelLimit = (state: ChannelsStore): ChannelLimitState => state.channelLimits
export const getChannelLimitError = (state: ChannelsStore): any => state.channelLimits.errors
export const isOpenChannelFilter = (state: ChannelsStore): boolean => state.channels.isOpenFilter
export const isChannelsLoading = (state: ChannelsStore): boolean => state.channels.isLoading
export const isChannelsSuccess = (state: ChannelsStore): boolean => state.channels.isSuccess

// TODO: Get appropriate mediaStream type
export const getMediaStream = (channelId: string) =>
  createSelector(
    [getMediaStreams],
    (channelMediaStreams: ChannelMediaStreams): ChannelMediaStream | {} =>
      pipe(
        prop(channelId),
        defaultToEmptyArray,
        head,
        defaultToEmptyObject,
      )(channelMediaStreams),
  )

export const getSortedChannels = curry(
  (search: string, channels: Array<Channel>): Array<Channel> => {
    const defaultParam = 'number'
    const params = getParam(search, 'sort')
    const param = isNotEmpty(params) ? params[0] : defaultParam
    const value = either(prop(param), prop(defaultParam))
    // $FlowFixMe
    return sortWith([ascend(value)])(channels)
  },
)

const getFilterObject = ({
  search,
  genres,
  categories,
  languages,
  countries,
}: GetFilteredChannelsProps): FilterObject => ({
  /**
   * genres => [{ _id: '60d', ... }, { _id: '80a', ... }]
   * mapId(genres) => ['60d', '80a']
   * getParam(search, FILTER_NAMES.genre) => ['80a', '60d', 'incorrectID']
   * intersection => ['80a', '60d']
   */
  genre: intersection(mapId(genres), getParam(search, FILTER_NAMES.genre)),
  country: intersection(mapId(countries), getParam(search, FILTER_NAMES.country)),
  category: intersection(mapId(categories), getParam(search, FILTER_NAMES.category)),
  language: intersection(mapId(languages), getParam(search, FILTER_NAMES.language)),
})

export const getFilteredChannels = curry(
  (
    { search, genres, categories, languages, countries }: GetFilteredChannelsProps,
    channels: Array<Channel>,
  ): Array<Channel> => {
    const filtered = getFilterObject({ search, genres, categories, languages, countries })
    return pipe(
      filterChannels(filtered),
      getSortedChannels(search),
    )(channels)
  },
)

export const getFavoriteDetail = (id: string) =>
  createSelector(
    [getFavorite],
    (favorite: FavoriteChannels): boolean => includes(id, favorite),
  )

export const getChannelList = createSelector(
  [getFavorite, getChannels, getGenres, getCategories, getLanguages, getCountries, getRouting, getCatchupChannels],
  (
    favorite: FavoriteChannels,
    channels: Channels,
    genres: Array<Genre>,
    categories: Array<Category>,
    languages: Array<Language>,
    countries: Array<Country>,
    routing: Routing,
    catchupChannels,
  ): Array<PreparedChannel> =>
    prepareChannelList(
      pipe(
        values,
        getFilteredChannels({
          search: path(['location', 'search'], routing),
          genres,
          categories,
          languages,
          countries,
        }),
      )(channels),
      favorite,
      genres,
      categories,
      catchupChannels,
    ),
)

/*
  ToDo: Refactor me for react-native app
  START
*/

export const getFilteredChannelsPure = curry(
  ({ filters, search }, channels: Array<Channel>): Array<Channel> =>
    pipe(
      filterChannels(filters),
      getSortedChannels(search),
    )(channels),
)

export const getChannelsFilteredList = (filters = {}, search = '') =>
  createSelector(
    [getFavorite, getChannels, getGenres, getCategories, getLanguages, getCountries, getRouting, getCatchupChannels],
    (
      favorite: FavoriteChannels,
      channels: Channels,
      genres: Array<Genre>,
      categories: Array<Category>,
      /* eslint-disable no-unused-vars */
      languages: Array<Language>,
      countries: Array<Country>,
      routing: Routing,
      /* eslint-enable no-unused-vars */
      catchupChannels,
    ): Array<PreparedChannel> =>
      prepareChannelList(
        pipe(
          values,
          getFilteredChannelsPure({ filters, search }),
        )(channels),
        favorite,
        genres,
        categories,
        catchupChannels,
      ),
  )

export const getSearchChannelListPure = (search = '') =>
  createSelector(
    [getFavorite, getChannels, getGenres, getCategories, getLanguages, getCountries, getRouting, getCatchupChannels],
    (
      favorite: FavoriteChannels,
      channels: Channels,
      genres: Array<Genre>,
      categories: Array<Category>,
      /* eslint-disable no-unused-vars */
      languages: Array<Language>,
      countries: Array<Country>,
      routing: Routing,
      /* eslint-enable no-unused-vars */
      catchupChannels,
    ): Array<PreparedChannel> =>
      prepareChannelList(
        // eslint-disable-next-line no-use-before-define
        getSearchedChannels({
          search,
          channels,
        }),
        favorite,
        genres,
        categories,
        catchupChannels,
      ),
  )

/*
  ToDo: Refactor me for react-native app
  END
*/

const getSearchResults = (channels, params) => {
  const value = params[0].toLowerCase()
  // TODO Think about how you can do better
  return filter(
    item => item.title.toLowerCase().indexOf(value) >= 0 || item.number.toString().indexOf(value) >= 0,
    values(channels),
  )
}

export const getSearchedChannels = ({ search, channels }) => {
  const params = getParam(search, 'search')
  // TODO need to do better.
  return head(params) ? getSearchResults(channels, params) : values(channels)
}

export const getSearchChannelList = createSelector(
  [getFavorite, getChannels, getGenres, getCategories, getLanguages, getCountries, getRouting, getCatchupChannels],
  (
    favorite: FavoriteChannels,
    channels: Channels,
    genres: Array<Genre>,
    categories: Array<Category>,
    languages: Array<Language>,
    countries: Array<Country>,
    routing: Routing,
    catchupChannels,
  ): Array<PreparedChannel> =>
    prepareChannelList(
      getSearchedChannels({
        search: path(['location', 'search'], routing),
        channels,
      }),
      favorite,
      genres,
      categories,
      catchupChannels,
    ),
)

export const getPreparedChannel = (id: string) =>
  createSelector(
    [getChannels, getFavorite, getGenres, getCategories],
    (
      channels: Channels,
      favorite: FavoriteChannels,
      genres: Array<Genre>,
      categories: Array<Category>,
    ): PreparedChannel | any => {
      const channel = prop(id)(channels)
      if (channel) {
        return prepareChannel(channel, favorite, genres, categories)
      }
      return {}
    },
  )

export const getVastTag = (id: string) =>
  createSelector(
    [getMediaStream(id)],
    // $FlowFixMe
    (mediaStream: ChannelMediaStream): string => pathOr('', ['ads', 'vastUrl'], mediaStream),
  )

export const getAdProvider = (id: string) =>
  createSelector(
    [getMediaStream(id)],
    // $FlowFixMe
    (mediaStream: ChannelMediaStream): string => pathOr('', ['ads', 'provider'], mediaStream),
  )

export const getAspectRatio = (id: string) =>
  createSelector(
    [getMediaStream(id)],
    (mediaStream: ChannelMediaStream): AspectRatio => {
      const ratio = prop('screenRatio', mediaStream)
      return ratio ? { aspectRatio: `${Math.round(1000 * ratio)}:${1000}` } : {}
    },
  )

export const getChannelPreLoaderURL = (id: string) =>
  createSelector(
    [getOptionsPropertyFromConfigAndBrand(['ui', 'tv', 'preLoader', 'url'], ''), getPreparedChannel(id)],
    (preLoaderPath: string, channel: PreparedChannel | any): string =>
      cond([
        [isURL, always(preLoaderPath)],
        [isNotNilOrEmpty, () => insertVariablesInJSON(preLoaderPath, { PUBLIC_URL })],
        [T, () => propOr('', 'image', channel)],
      ])(preLoaderPath),
  )
