// @flow

import styled from 'styled-components'

import { getTextColorPrimary, getBackgroundSecondaryColor } from '@alphaott/smart-tv-themes'

export const StyledInput = styled.input`
  box-sizing: border-box;
  border: none;
  height: 72rem;
  width: 100%;
  margin: 12rem auto 0;
  display: flex;
  font-size: 40rem;
  line-height: 48rem;
  padding: 0 19rem;
  color: ${getTextColorPrimary};
  outline: none;
  background-color: ${getBackgroundSecondaryColor};
  font-weight: normal;
  font-style: normal;

  ::placeholder {
    color: ${getTextColorPrimary};
  }
  &:focus {
    border: 6rem solid #dedede;
  }
`
