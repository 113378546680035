// @flow
import { map, find, propEq, prop, reject, join } from 'ramda'

import { getContentDomain, getPublicDomain } from '@alphaott/app-main/selectors'
import { getCustomerId } from '@alphaott/app-core/models/selectors'
import {
  getTvShow,
  getTvShowsByIds,
  getPersonalList,
  putPersonalList,
  removeItemFromPersonalList,
} from '@alphaott/api-client'
import logoutRequestWrapper from '@alphaott/app-main/core/logoutWrapper'
import { isNilOrEmpty, isNotEmpty } from '@alphaott/common-utils'

import type { Dispatch } from '@alphaott/common-types'

import { getFavoriteTVShowsIds, isFavoriteTVShow } from '../../selectors/selectors'
import { getTVShowsFavoriteList } from '../../selectors/favorites'

export const FETCH_FAVORITE_TV_SHOWS_TYPE = 'tvshows/FETCH_FAVORITE_TV_SHOWS_TYPE'
export const FETCH_FAVORITE_TV_SHOWS_IDS_TYPE = 'tvshows/FETCH_FAVORITE_TV_SHOWS_IDS_TYPE'
export const FETCH_FAVORITE_TV_SHOWS_BY_IDS_TYPE = 'tvshows/FETCH_FAVORITE_TV_SHOWS_BY_IDS_TYPE'
export const FETCH_FAVORITE_TV_SHOW_BY_ID_TYPE = 'tvshows/FETCH_FAVORITE_TV_SHOW_BY_ID_TYPE'
export const ADD_TV_SHOW_ID_TO_FAVORITE_IDS_TYPE = 'tvshows/ADD_TV_SHOW_ID_TO_FAVORITE_IDS_TYPE'
export const REMOVE_TV_SHOW_ID_FROM_FAVORITE_IDS_TYPE = 'tvshows/REMOVE_TV_SHOW_ID_FROM_FAVORITE_IDS_TYPE'
export const REMOVE_TV_SHOW_FROM_FAVORITES_TYPE = 'tvshows/REMOVE_TV_SHOW_FROM_FAVORITES_TYPE'

export const addTVShowIdToFavoriteIds = (tvshowId: string, customerId: string) => (
  dispatch: Dispatch,
  getState: Function,
) =>
  dispatch({
    type: ADD_TV_SHOW_ID_TO_FAVORITE_IDS_TYPE,
    payload: logoutRequestWrapper(putPersonalList)(customerId, 'tv_show', 'favorites', tvshowId, {})(
      dispatch,
      getState,
    ),
  })

export const fetchFavoriteTVShowById = (tvshowId: string) => (dispatch: Dispatch, getState: Function) =>
  dispatch({
    type: FETCH_FAVORITE_TV_SHOW_BY_ID_TYPE,
    payload: getTvShow(getPublicDomain(getState()), tvshowId),
    meta: {
      contentApi: getContentDomain(getState()),
    },
  })

export const addTVShowToFavorite = (tvshowId: string, customerId: string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line no-useless-catch
  try {
    await dispatch(addTVShowIdToFavoriteIds(tvshowId, customerId))
    return dispatch(fetchFavoriteTVShowById(tvshowId))
  } catch (err) {
    throw err
  }
}

export const removeTVShowIdFromFavoritesIds = (tvshowId: string, customerId: string) => (
  dispatch: Dispatch,
  getState: Function,
) => {
  const favoriteTVShowsIds = getFavoriteTVShowsIds(getState())
  const tvshow = find(propEq('item', tvshowId), favoriteTVShowsIds)
  const itemId = prop('_id', tvshow)

  return dispatch({
    type: REMOVE_TV_SHOW_ID_FROM_FAVORITE_IDS_TYPE,
    payload: logoutRequestWrapper(removeItemFromPersonalList)(customerId, 'tv_show', 'favorites', itemId)(
      dispatch,
      getState,
    ),
    meta: {
      itemId,
    },
  })
}

export const removeTVShowFromFavorites = (tvshowId: string, customerId: string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line no-useless-catch
  try {
    await dispatch(removeTVShowIdFromFavoritesIds(tvshowId, customerId))

    return dispatch({
      type: REMOVE_TV_SHOW_FROM_FAVORITES_TYPE,
      payload: {
        tvshowId,
      },
    })
  } catch (err) {
    throw err
  }
}

export const favoriteTVShow = (tvshowId: string) => (dispatch: Dispatch, getState: Function) => {
  const customerId = getCustomerId(getState())
  const isFavorite = isFavoriteTVShow(tvshowId)(getState())

  if (isFavorite) {
    return dispatch(removeTVShowFromFavorites(tvshowId, customerId))
  }

  return dispatch(addTVShowToFavorite(tvshowId, customerId))
}

export const fetchFavoriteTVShowsIds = (dispatch: Dispatch, getState: Function) => {
  const customerId = getCustomerId(getState())

  return dispatch({
    type: FETCH_FAVORITE_TV_SHOWS_IDS_TYPE,
    payload: logoutRequestWrapper(getPersonalList)(customerId, 'tv_show', 'favorites')(dispatch, getState),
  })
}

// eslint-disable-next-line consistent-return
export const fetchFavoriteTVShowsByIds = (dispatch: Dispatch, getState: Function) => {
  const state = getState()
  const favoriteList = getTVShowsFavoriteList(state)
  const uniqFavoriteIds = reject(({ item }) => find(propEq('_id', item), favoriteList), state.tvshowFavoriteIds.data)
  const idList = map(({ item }) => item, uniqFavoriteIds)

  if (isNotEmpty(idList)) {
    return dispatch({
      type: FETCH_FAVORITE_TV_SHOWS_BY_IDS_TYPE,
      payload: getTvShowsByIds(getPublicDomain(state), join(',', idList)),
      meta: {
        contentApi: getContentDomain(getState()),
      },
    })
  }
}

export const fetchFavoriteTVShows = async (dispatch: Dispatch, getState: Function) => {
  const customerId = getCustomerId(getState())

  if (isNilOrEmpty(customerId)) return null

  return dispatch({
    type: FETCH_FAVORITE_TV_SHOWS_TYPE,
    payload: Promise.all([await dispatch(fetchFavoriteTVShowsIds), dispatch(fetchFavoriteTVShowsByIds)]),
  })
}
