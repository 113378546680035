// @flow
import { replace } from 'redux-first-history'

import type { Token } from '@alphaott/api-client/types/token'

import { resetProfileLoadListsInterval } from '@alphaott/app-core/models/actions/appInit/loadPersonalContentInterval'

import { resetAfterLogout } from '../resetAfterLogout'

export const SAVE_TOKEN_TYPE = 'TOKEN/SAVE_TOKEN'
export const REMOVE_TOKEN_TYPE = 'TOKEN/REMOVE_TOKEN'

export type TokenAction = {|
  type: 'TOKEN/SAVE_TOKEN',
  token: Token,
|}

export const saveToken = (token: Token): TokenAction => ({
  type: SAVE_TOKEN_TYPE,
  token,
})

const logoutAction = () => ({
  type: REMOVE_TOKEN_TYPE,
})

// TODO: clear all personal data (recent, favorite) after sync to server
export const logout = (dispatch: Function) => {
  dispatch(replace({ pathname: '/', search: null }))
  dispatch(logoutAction())
  dispatch(resetProfileLoadListsInterval)
  dispatch(resetAfterLogout())
}
