// @flow
import { getAuthMethods } from '@alphaott/api-client'
import { getPrivateDomain } from '@alphaott/app-main/selectors'
import { getCurrentDevice } from '@alphaott/app-devices/selectors'

export const AUTH_METHODS_TYPE = 'authorization/AUTH_METHODS'

export const authMethods = () => (dispatch: Function, getState: Function) =>
  dispatch({
    type: AUTH_METHODS_TYPE,
    payload: getAuthMethods(getPrivateDomain(getState()), getCurrentDevice(getState())),
    meta: {},
  })
