// @flow

import React, { memo } from 'react'
import { join } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'

import { Container, Item, Rating } from './DetailLine.style'

export type GenresLineProps = {
  className?: any,
  pgRating?: number,
  rating?: number,
  genres?: string,
}

// eslint-disable-next-line complexity
const GenresLinePure = ({ className, pgRating, rating, genres }: GenresLineProps) => (
  <Container className={className}>
    {isNotNilOrEmpty(pgRating) && <Item>{pgRating}+</Item>}
    {isNotNilOrEmpty(rating) && (
      <Item>
        <Rating rating={rating} />
        {rating.toFixed(1)}
      </Item>
    )}
    {isNotNilOrEmpty(genres) && <Item>{join(', ', genres)}</Item>}
  </Container>
)

export const GenresLine = memo<GenresLineProps>(GenresLinePure)

export default GenresLine
