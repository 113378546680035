// @flow
import React, { lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { PageLoaderInner } from '@alphaott/smart-tv-components/src/components/PageView/PageLoader'

import { App } from '../../containers/App'

import { userWithSubscriptions } from '../hocs'

const HomeContainer = lazy(() => import('../../containers/Home'))
const WelcomeContainer = lazy(() => import('../../containers/Notifications/NewUser'))
const ExpiredContainer = lazy(() => import('../../containers/Notifications/ExpiredSubscription'))
const MyAccountNavigation = lazy(() => import('./MyAccountNavigation'))
const SearchContainer = lazy(() => import('../../containers/Search'))
const ChannelsNavigation = lazy(() => import('./ChannelsNavigation'))
const SimpleEPGContainer = lazy(() => import('../../containers/EPG'))
const MoviesNavigation = lazy(() => import('./MoviesNavigation'))
const TVShowsNavigation = lazy(() => import('./TVShowsNavigation'))
const RadioChannelsNavigation = lazy(() => import('./RadioChannelsNavigation'))
const MyListNavigation = lazy(() => import('./MyListNavigation'))
const SettingsNavigation = lazy(() => import('./SettingsNavigation'))

const HomeView = userWithSubscriptions(HomeContainer)
const SearchView = userWithSubscriptions(SearchContainer)
const EPGView = userWithSubscriptions(SimpleEPGContainer)

export const AppNavigationWithMenu = () => (
  <App>
    <Suspense fallback={<PageLoaderInner isFlexBehavior />}>
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="welcome" element={<WelcomeContainer />} />
        <Route path="expired" element={<ExpiredContainer />} />
        <Route path="account/*" element={<MyAccountNavigation />} />
        <Route path="search" element={<SearchView />} />
        <Route path="channels/*" element={<ChannelsNavigation />} />
        <Route path="epg-simple" element={<EPGView />} />
        <Route path="movies/*" element={<MoviesNavigation />} />
        <Route path="tvshows/*" element={<TVShowsNavigation />} />
        <Route path="radio-channels/*" element={<RadioChannelsNavigation />} />
        <Route path="my-list/*" element={<MyListNavigation />} />
        <Route path="settings/*" element={<SettingsNavigation />} />
        <Route path="*" element={<Navigate to="/notfound" />} />
      </Routes>
    </Suspense>
  </App>
)
