// @flow

// eslint-disable-next-line no-unused-vars
import React, { memo, useEffect, useRef, useState, useMemo } from 'react'
import { path } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'

// eslint-disable-next-line no-unused-vars
import { Container, Time, SeekBar } from './elements'

export type Progress = {
  // currentTime: number,
  // duration: number,
}

type ProgressBarProps = {
  className?: string,
  time?: string,
} & Progress

export const ProgressBarPure = ({
  className,
  // currentTime = 0,
  // duration = 1,
  time,
}: ProgressBarProps) => {
  // eslint-disable-next-line no-unused-vars
  const [seekBarWidth, setSeekBarWidth] = useState(0)
  const seekBarRef = useRef(null)

  // Todo: For future functionality
  // const progress = useMemo(() => {
  //   const percent = currentTime / duration
  //   return seekBarWidth * percent
  // }, [currentTime, duration, seekBarWidth])

  useEffect(() => {
    const seekBarElementWidth = path(['current', 'clientWidth'], seekBarRef)

    if (seekBarElementWidth) {
      setSeekBarWidth(seekBarElementWidth)
    }
  }, [])

  return (
    <Container className={className}>
      {isNotNilOrEmpty(time) && <Time>{time}</Time>}
      {/* <SeekBar progress={progress} ref={seekBarRef} /> */}
    </Container>
  )
}

export const ProgressBar = memo(ProgressBarPure)

export default ProgressBar
