// @flow
import { path } from 'ramda'

import { getTvShowList } from '@alphaott/api-client'
import { getContentDomain, getPublicDomain, getCacheVOD, isCacheValid } from '@alphaott/app-main/selectors'
import { getServiceTVShowsVodIds } from '@alphaott/app-core/selectors'
import type { TvShowListQuery } from '@alphaott/api-client/tvShow'

export const FETCH_FILTERED_TVSHOWS = 'tvshows/FETCH_FILTERED_TVSHOWS'

export const fetchFilteredTVShowsAction = (params: TvShowListQuery, storeKey: string, resetData?: boolean) => (
  dispatch: Function,
  getState: Function,
) => {
  const state = getState()
  const serviceSpec = getServiceTVShowsVodIds(state)
  const publicApi = getPublicDomain(state)
  const contentApi = getContentDomain(state)
  const ttl = getCacheVOD(state)

  return dispatch({
    type: FETCH_FILTERED_TVSHOWS,
    payload: getTvShowList(publicApi, serviceSpec, params),
    meta: {
      contentApi,
      resetData,
      storeKey,
      ttl,
    },
  })
}

export const fetchFilteredTVShows = (
  params: TvShowListQuery,
  storeKey: string,
  resetData?: boolean,
  force?: boolean,
  // eslint-disable-next-line complexity
) => (dispatch: Function, getState: Function) => {
  /**
   * Force Fetch Movies
   * For example, for MWTC `pull to refresh` feature
   *  */
  if (force) return dispatch(fetchFilteredTVShowsAction(params, storeKey, resetData))

  const state = getState()
  const expires = path(['tvshowFilters', 'data', storeKey, 'expires'], state)

  if (expires && isCacheValid(expires)) return null

  return dispatch(fetchFilteredTVShowsAction(params, storeKey, resetData))
}
