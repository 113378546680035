// @flow
import shaka from 'shaka-player'
import { equals, isNil, isEmpty, cond, propSatisfies, always, T, not } from 'ramda'
import { isNotNil } from 'ramda-adjunct'

import { DRMType, hlsDrmConfig, getDashLicense, getProviderData } from './drm'

import type { DRMProps } from './types'
import { NotSupportedDRMError } from './errors'

const isFairplay = equals(DRMType.FAIRPLAY)
const isWidevine = equals(DRMType.WIDEVINE)
const isPlayready = equals(DRMType.PLAYREADY)

const setFairplayConfig = async (player: Object, drm: DRMProps) => {
  const { licenseServer, headers, certificateUrl } = drm

  // Fetch certificate
  const req = await fetch(certificateUrl)
  const cert = await req.arrayBuffer()

  // TODO: Improve getting contentID in requestFilter method
  const contentID = {
    assetId: '',
  }

  shaka.polyfill.PatchedMediaKeysApple.install()

  const { getContentId, getRequestFilter, getResponseFilter } = getProviderData(drm?.provider)

  // Set fairplay settings
  player.configure({
    drm: {
      ...hlsDrmConfig(licenseServer, cert),
      // eslint-disable-next-line consistent-return
      initDataTransform: (initData, initDataType, drmInfo) => {
        try {
          const contentId = getContentId(initData)

          if (contentId) {
            contentID.assetId = contentId
            const certificate = drmInfo.serverCertificate

            return shaka.util.FairPlayUtils.initDataTransform(initData, contentId, certificate)
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      },
    },
  })

  // Override getLicense method
  player.getNetworkingEngine().registerRequestFilter(getRequestFilter(headers, contentID))

  // Prepare License response
  player.getNetworkingEngine().registerResponseFilter(getResponseFilter)
}

const setWidevineConfig = (player: Object, drm: DRMProps) => {
  const { licenseServer, headers } = drm

  player.configure({
    drm: {
      servers: {
        'com.widevine.alpha': licenseServer,
      },
    },
  })

  player.getNetworkingEngine().registerRequestFilter(getDashLicense(headers))
}

const setPlayreadyConfig = (player: Object, drm: DRMProps) => {
  const { licenseServer, headers } = drm

  player.configure({
    drm: {
      servers: {
        'com.microsoft.playready': licenseServer,
      },
    },
  })

  player.getNetworkingEngine().registerRequestFilter(getDashLicense(headers))
}

/**
 * is browser supported drmType or not
 */
export const hasSupportedDRM = async (drmType: $Values<typeof DRMType>): Promise<boolean> => {
  // Uses https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html#.probeSupport
  const { drm = {} } = await shaka.Player.probeSupport()
  return cond([
    [isWidevine, always(propSatisfies(isNotNil, 'com.widevine.alpha', drm))],
    [isPlayready, always(propSatisfies(isNotNil, 'com.microsoft.playready', drm))],
    [isFairplay, always(propSatisfies(isNotNil, 'com.apple.fps.1_0', drm))],
    [T, always(false)],
  ])(drmType)
}

// eslint-disable-next-line complexity
export const configureDrm = async (player: Object, drm: ?DRMProps) => {
  if (isNil(player) || isEmpty(drm) || isNil(drm)) return null

  // $FlowFixMe
  const { type } = drm

  if (not(await hasSupportedDRM(type))) {
    throw new NotSupportedDRMError(type)
  }

  return cond([
    // $FlowFixMe
    [isFairplay, () => setFairplayConfig(player, drm)],
    // $FlowFixMe
    [isWidevine, () => setWidevineConfig(player, drm)],
    // $FlowFixMe
    [isPlayready, () => setPlayreadyConfig(player, drm)],
  ])(type)
}

export default configureDrm
