// @flow
import { LARGE_VIEWPORT, MEDIUM_VIEWPORT, SMALL_VIEWPORT } from '@alphaott/app-config'
import { rgba } from 'polished'

import { colorPalette } from '../colorPalettes'
import type { ColorType } from '../colorPalettes'

export const umaxxColors: ColorType = { ...colorPalette }

export const lightTheme = {
  colors: umaxxColors,
  background: {
    primary: '#fafafa',
    secondary: '#fafafa',
    highlight: umaxxColors.light,
    select: '#fafafa',
    footerTop: '#eee',
  },
  text: {
    primary: umaxxColors.dark,
    secondary: umaxxColors.grayText,
    disabled: umaxxColors.dark,
    link: umaxxColors.dark,
    hint: umaxxColors.dark,
    highlight: umaxxColors.primary,
    gray: umaxxColors.grayText,
    seeAll: 'rgba(52, 73, 94, 0.8)',
    primaryContrast: '#ffffff',
  },
  border: {
    primary: '#dddddd',
    gray: '#ddd',
  },
  filter: {
    primary: '#43484d',
    secondary: '#43484d',
    disabled: umaxxColors.grayLabel,
    highlight: '#fff',
  },
  tabs: {
    bg: '#fafafa',
    containerBg: umaxxColors.dark,
    color: umaxxColors.dark,
    border: umaxxColors.primary,
    scrollbarThumb: umaxxColors.primary,
    programTitle: umaxxColors.white,
  },
  programGuide: {
    programBox: {
      background: {
        main: 'rgba(221, 221, 221, 0.8)',
        hover: umaxxColors.dark,
        selected: umaxxColors.dark,
        progress: rgba(umaxxColors.primary, 0.3),
        past: rgba(umaxxColors.primary, 0.3),
        pastSelected: umaxxColors.dark,
        pastHover: umaxxColors.dark,
        empty: 'rgba(221, 221, 221, 0.3)',
        emptyProgress: rgba(umaxxColors.primary, 0.1),
      },
      text: {
        empty: umaxxColors.grayLabel,
      },
    },
    programGuidePictureOverlay: {
      horizontal:
        // eslint-disable-next-line max-len
        `linear-gradient(90deg, #fafafa 0%,${rgba('#fafafa', 0.97)} 13.03%,${rgba('#fafafa', 0.69)} 43.24%,${rgba(
          '#fafafa',
          0,
        )} 100%);`,
      vertical: `linear-gradient(0,#fafafa 0%,${rgba('#fafafa', 0.73)} 34.97%,${rgba('#fafafa', 0)} 100%);`,
    },
  },
  card: {
    title: umaxxColors.dark,
    subTitle: umaxxColors.grayText,
    bg: umaxxColors.dark,
    number: umaxxColors.grayBorder,
    boxShadow: 'rgba(221, 221, 221, 0.8)',
    loaderBackgroundImage: 'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
  },
  dimension: {},
  size: 'default',
  breakpoints: [`${SMALL_VIEWPORT}px`, `${MEDIUM_VIEWPORT}px`, `${LARGE_VIEWPORT}px`],
  signIn: {
    clipPath: false,
  },
}
