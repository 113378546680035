// @flow
import { find, has, propEq, propOr } from 'ramda'

type Item = { _id: string, commonName: string }

const findItem = (code: string, dict: [Item]): Item => find(propEq('_id', code), dict)

export const prepareCountry = (code: string, dict: [Item]): string => {
  const country = findItem(code, dict)

  return propOr(code, 'commonName', country)
}

export const prepareServiceProviderAddress = (address: Object, dict: [Item]) => {
  if (has('country', address) && dict) {
    return {
      ...address,
      country: prepareCountry(address.country, dict),
    }
  }

  return address
}
