// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Prompt, ArrowsIcon } from './Helper.styles'

export const Helper = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Prompt>{t('ok_select_content')}</Prompt>
      <Prompt>
        <ArrowsIcon />
        {t('navigate')}
      </Prompt>
      <Prompt>{t('hold_ok_for_options')}</Prompt>
    </Container>
  )
}
