// @flow
import { equals, trim, prop } from 'ramda'

import { isLicenseRequest, fromUTF8, fromBase64 } from '../utils'

// eslint-disable-next-line complexity
export const registerFairplayResponse = (type: string, response: Object) => {
  if (isLicenseRequest(type)) {
    let responseText = trim(fromUTF8(response.data))

    // Look for <ckc> wrapper and remove it.
    if (equals(responseText.substr(0, 5), '<ckc>') && equals(responseText.substr(-6), '</ckc>')) {
      responseText = responseText.slice(5, -6)
    }

    const payload = fromBase64(responseText)
    response.data = prop('buffer', payload)
  }
}

export default registerFairplayResponse
