// @flow

import { useMemo, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import {
  prop,
  path,
  reject,
  equals,
  dissoc,
  append,
  dropLast,
  last,
  filter,
  split,
  propEq,
  not,
} from 'ramda'
import { isNotNilOrEmpty, notEqual } from 'ramda-adjunct'

import { useLastFocusContext } from '../components'

type Options = {
  [string]: string | number,
}

export const useLastFocusHistory = (options: Options = {}, postfix?: string) => {
  const location = useLocation()
  const {
    historyKeys,
    lastFocusHistory,
    actionHistory,
    onChangeHistoryKeys,
    onChangeLastFocusHistory,
    onChangeActionHistory,
  } = useLastFocusContext()

  // Unique key for page from location
  const originalPathKey = prop('key', location)

  const pathKey = useMemo(() => {
    if (postfix) {
      return `${prop('key', location)}-${postfix}`
    }

    return prop('key', location)
  }, [postfix, location])

  const lastHistoryKey = useMemo(() => last(historyKeys), [historyKeys])
  const lastFocusedKey = useMemo(() => {
    const contentKeys = filter((item) => not(propEq('length', 2, split('-', item))), historyKeys)
    return last(contentKeys)
  }, [historyKeys])
  const lastFocusedItem = useMemo(
    () => prop(pathKey, lastFocusHistory),
    [pathKey, lastFocusHistory],
  )
  const prevFocusedItem = useMemo(() => prop(pathKey, actionHistory), [pathKey, actionHistory])
  const lastFocusedRowIndex = useMemo(() => {
    const key = `${prop('key', location)}-vertical`
    const history = prop(key, lastFocusHistory)
    const itemIndex = prop('itemIndex', history)

    return itemIndex
  }, [lastFocusHistory, location])

  const handleGetLastKeyByTypeList = useCallback(
    (type) => {
      if (!type) {
        return undefined
      }

      const contentKeys = filter((item) => {
        // eslint-disable-next-line no-unused-vars
        const [_, listType] = split('-', item)

        return listType && equals(type, listType)
      }, historyKeys)

      return last(contentKeys)
    },
    [historyKeys],
  )

  const handleGetItemByKey = useCallback(
    (key) => {
      if (!key) {
        return undefined
      }

      return prop(key, lastFocusHistory)
    },
    [lastFocusHistory],
  )

  const handleGetFocusKey = useCallback(
    // eslint-disable-next-line consistent-return,complexity
    (sections) => {
      if (isNotNilOrEmpty(lastFocusedRowIndex)) {
        const type = path([lastFocusedRowIndex, 'type'], sections)

        if (!type) {
          return undefined
        }

        const item = handleGetItemByKey(`${originalPathKey}-${type}`)

        if (!item) {
          return undefined
        }

        const focusKey = prop('focusKey', item)

        return focusKey
      }
    },
    [handleGetItemByKey, lastFocusedRowIndex, originalPathKey],
  )

  const handlePrepareOptions = useCallback((values = {}) => reject(equals(''))(values), [])

  const handleGetItemOption = useCallback(
    (pathToOption: string | number[]) => path(pathToOption, lastFocusedItem),
    [lastFocusedItem],
  )

  const handleChangeOptions = useCallback(
    (localOptions: Options) => (prevOptions: Options) => ({
      ...prevOptions,
      [pathKey]: {
        ...handlePrepareOptions(options),
        ...handlePrepareOptions(localOptions),
      },
    }),
    [pathKey, options, handlePrepareOptions],
  )

  const handleSaveLastKey = useCallback(
    () => onChangeHistoryKeys(append(pathKey, historyKeys)),
    [pathKey, historyKeys, onChangeHistoryKeys],
  )

  const handleRemoveLastKey = useCallback(
    () => onChangeHistoryKeys(dropLast(1, historyKeys)),
    [historyKeys, onChangeHistoryKeys],
  )

  const handleSaveLastFocus = useCallback(
    (localOptions: Options) => {
      const preparedOptions = handleChangeOptions({ ...localOptions, action: 'add' })

      if (notEqual(lastFocusHistory, preparedOptions)) {
        handleSaveLastKey()
        onChangeLastFocusHistory((prevOptions) => {
          const mergedOptions = handleChangeOptions({ ...localOptions, action: 'add' })(prevOptions)

          onChangeActionHistory((prevActionHistory) => ({
            ...prevActionHistory,
            ...mergedOptions,
          }))

          return mergedOptions
        })
      }
    },
    [
      lastFocusHistory,
      handleSaveLastKey,
      onChangeLastFocusHistory,
      onChangeActionHistory,
      handleChangeOptions,
    ],
  )

  const handleRemoveFocusItem = useCallback(
    (key: string) => {
      const preparedOptions = dissoc(key, lastFocusHistory)

      if (notEqual(lastFocusHistory, preparedOptions)) {
        handleRemoveLastKey()
        onChangeLastFocusHistory(preparedOptions)

        onChangeActionHistory((prevActionHistory) => ({
          ...prevActionHistory,
          [key]: {
            ...lastFocusHistory[key],
            action: 'remove',
          },
        }))
      }
    },
    [lastFocusHistory, handleRemoveLastKey, onChangeLastFocusHistory, onChangeActionHistory],
  )

  const handleRemoveLastFocus = useCallback(
    () => handleRemoveFocusItem(pathKey),
    [pathKey, handleRemoveFocusItem],
  )

  return {
    originalPathKey,
    lastFocusedRowIndex,
    pathKey,
    historyKeys,
    lastHistoryKey,
    lastFocusedKey,
    lastFocusedItem,
    prevFocusedItem,
    actionHistory,
    itemFocusKey: handleGetItemOption(['focusKey']),
    onGetItemOption: handleGetItemOption,
    onGetLastKeyByTypeList: handleGetLastKeyByTypeList,
    onGetFocusKey: handleGetFocusKey,
    onSaveLastFocus: handleSaveLastFocus,
    onRemoveFocusItem: handleRemoveFocusItem,
    onRemoveLastFocus: handleRemoveLastFocus,
  }
}
