// @flow

import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'

import { Button as ButtonBase } from '../../../Button'

import { ReactComponent as AddCircleIconComponent } from '../../../../assets/addCircleIcon.svg'
// eslint-disable-next-line max-len
import { ReactComponent as RemoveCircleIconComponent } from '../../../../assets/removeCircleIcon.svg'
import { ReactComponent as PlayIconComponent } from '../../../../assets/playIcon.svg'
import { ReactComponent as TheatersIconComponent } from '../../../../assets/theatersIcon.svg'

export const Container = styled(Flex)``

const Button = styled(ButtonBase).attrs({
  secondaryColor: 'rgba(71, 71, 71, 0.5)',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10rem 32rem;
  min-width: 0;

  &:not(:first-child) {
    margin-left: 24rem;
  }
`

export const PlayButton = styled(Button)``

const Icon = css`
  width: 32rem;
  height: 32rem;
  padding-right: 10rem;
`

export const PlayIcon = styled(PlayIconComponent)`
  ${Icon};
`

export const TrailerButton = styled(Button)``

export const TheatersIcon = styled(TheatersIconComponent)`
  ${Icon};
  height: 40rem;
`

export const FavoriteButton = styled(Button)``

export const AddCircleIcon = styled(AddCircleIconComponent)`
  ${Icon};
  padding: 0;
`

export const RemoveCircleIcon = styled(RemoveCircleIconComponent)`
  ${Icon};
  padding: 0;
`
