// @flow

import React, { memo } from 'react'

import { Container, ErrorContent, Title, Label, Message, Stack } from './GlobalError.style'

type GlobalErrorProps = any

export const GlobalErrorPure = ({ error }: GlobalErrorProps) => (
  <Container>
    <ErrorContent>
      <Title>Error</Title>
      <Message>
        <Label>Message:</Label> {error.message}
      </Message>
      <Stack>
        <Label>Stack:</Label> {error.stack}
      </Stack>
    </ErrorContent>
  </Container>
)

export const GlobalError = memo(GlobalErrorPure)

export default GlobalError
