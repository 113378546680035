// COPY PASTE from https://github.com/videojs/video.js/blob/master/src/js/utils/browser.js
// @flow

const USER_AGENT = (window.navigator && window.navigator.userAgent) || ''

const PLATFORM = window.navigator && window.navigator.platform
const WINDOWS_PLATFORM = ['Win32', 'Win64', 'Windows', 'WinCE']

export const IS_WINDOWS = WINDOWS_PLATFORM.indexOf(PLATFORM) !== -1

export const IS_EDGE: boolean = /Edg/i.test(USER_AGENT)

export const IS_FIREFOX: boolean = /Firefox/i.test(USER_AGENT)

export const IS_CHROME: boolean = !IS_EDGE && (/Chrome/i.test(USER_AGENT) || /CriOS/i.test(USER_AGENT))

export const IS_ANDROID: boolean = /Android/i.test(USER_AGENT)

export const IS_TIZEN: boolean = /Tizen/i.test(USER_AGENT)

export const IS_WEBOS: boolean = /Web0S/i.test(USER_AGENT)

export const IS_SAFARI: boolean =
  /Safari/i.test(USER_AGENT) && !IS_CHROME && !IS_ANDROID && !IS_EDGE && !IS_TIZEN && !IS_WEBOS

export const IS_IE: boolean = /MSIE|Trident/i.test(USER_AGENT)
