// @flow

import styled from 'styled-components'

import { Text as TextComponent } from '@alphaott/smart-tv-components'
import {
  getTextColorPrimary,
  getBackgroundSecondaryColor,
  getTextColorSecondary,
} from '@alphaott/smart-tv-themes'

const getBackground = ({ focused, ...props }) =>
  focused ? '#363636' : getBackgroundSecondaryColor(props)

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 505rem;
  background-color: ${getBackground};
  border-radius: 8rem;
  padding: 28rem 24rem;
`

export const Top = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled(TextComponent)`
  font-size: 42rem;
  line-height: 50rem;
  font-weight: bold;
  color: ${getTextColorPrimary};
`

export const Description = styled(TextComponent)`
  font-size: 26rem;
  line-height: 30rem;
  color: ${getTextColorSecondary};
  margin-top: 16rem;
`

export const Emails = styled.div`
  display: flex;
  flex-direction: column;
`

export const EmailItem = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled(TextComponent)`
  margin-top: 24rem;
  font-size: 18rem;
  line-height: 22rem;
  margin-bottom: 8rem;
`

export const Text = styled(TextComponent)``

export const Bottom = styled(Top)``

export const Address = styled(TextComponent)`
  font-size: 21rem;
  line-height: 30rem;
  margin-bottom: 24rem;
`
