// @flow
import React, { useMemo, useCallback } from 'react'
import { cond } from 'ramda'

import {
  preparedCodeValues,
  incrementValue,
  decrementValue,
  isDownArrow,
  isUpArrow,
  isDownOrUpArrow,
  SINGLE_CODE_INPUT_FOCUS_KEY,
} from './const'

import { SingleCodeInput } from './SingleCodeInput'
import * as S from './CodeInput.style'

type Props = {
  input: Object,
  inputNumber: number,
}

export const CodeInput = ({ inputNumber = 4, input }: Props) => {
  const { value, onChange } = input

  const handleChange = useCallback(
    (newValues: string[]) => {
      onChange(newValues.join(''))
    },
    [onChange],
  )

  const onArrowPress = useCallback(
    (index: number) => (direction: string) => {
      if (isDownOrUpArrow(direction)) {
        const codeValues = preparedCodeValues({ value, inputNumber })
        const currentValue = +codeValues[index]

        const newValue = cond([
          [isUpArrow, () => incrementValue(currentValue)],
          [isDownArrow, () => decrementValue(currentValue)],
        ])(direction)

        codeValues[index] = newValue
        handleChange(codeValues)

        return false
      }
      return true
    },
    [handleChange, inputNumber, value],
  )

  const renderInputs = useMemo(() => {
    const codeValues = preparedCodeValues({ value, inputNumber })

    return codeValues.map((code, index) => (
      <SingleCodeInput
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        index={index}
        value={code}
        focusKey={`${SINGLE_CODE_INPUT_FOCUS_KEY}_${index}`}
        onArrowPress={onArrowPress(index)}
      />
    ))
  }, [inputNumber, value, onArrowPress])

  return <S.CodeInputWrapper>{renderInputs}</S.CodeInputWrapper>
}
