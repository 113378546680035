// @flow

import React, {
  memo,
  forwardRef,
  useEffect,
  useRef,
  useMemo,
  useImperativeHandle,
  useCallback,
} from 'react'
import shakaPlayer from 'shaka-player'
import muxjs from 'mux.js'

import { useVersionValue } from '@alphaott/smart-tv-common'
import { configureDrm } from '@alphaott/video-player/src/configureDrm'

import type { DRMProps } from '@alphaott/video-player'

import {
  getVideoEvents,
  getPlayerEvents,
  addListeners,
  removeListeners,
  getActions,
} from '../utils'

window.muxjs = muxjs

type EventType = [string, (value?: string | number | Object) => void]

const initPlayer = async (
  shaka: any,
  config: shaka.extern.PlayerConfiguration,
  {
    videoRef,
    videoEvents,
    playerEvents,
  }: {
    videoRef: any,
    videoEvents: EventType[],
    playerEvents: EventType[],
  },
) => {
  shaka.polyfill.installAll()

  const player = new shaka.Player()
  await player.attach(videoRef.current)

  player.configure(config)

  addListeners(videoRef.current, videoEvents)
  addListeners(player, playerEvents)

  return player
}

interface ISource {
  src: string;
}

interface IMediaStream {
  sources: ISource[];
  type?: string;
  drm?: DRMProps;
}

type ShakaPlayerProps = {
  className?: string,
  shaka?: any,
  config: shaka.extern.PlayerConfiguration,
  mediaStream: IMediaStream,
  defaultTimePosition?: number,
  // Event callbacks
  onLoading: () => void,
  onLoaded: () => void,
  onCanPlay: () => void,
  onPlay: () => void,
  onSeeking: () => void,
  onSeeked: () => void,
  onPause: () => void,
  onEnd: () => void,
  onTimeUpdate: () => void,
  onError: (error: shaka.util.Error | Object) => void,
  onChangedAudioTrack: () => void,
  onChangedSubtitleTrack: () => void,
}

export const ShakaPlayerPure = (
  {
    className,
    shaka = shakaPlayer,
    config,
    mediaStream,
    defaultTimePosition,
    onError,
    ...otherProps
  }: ShakaPlayerProps,
  ref: any,
) => {
  const videoEvents = useMemo(() => getVideoEvents(otherProps), [otherProps])
  const playerEvents = useMemo(
    () => getPlayerEvents({ ...otherProps, onError }),
    [otherProps, onError],
  )

  const videoRef = useRef(null)
  const playerRef = useRef(null)

  const mediaStreamVersion = useVersionValue<IMediaStream>(mediaStream)

  const actions = getActions(videoRef, playerRef)
  useImperativeHandle(ref, () => actions, [actions])

  /* eslint-disable */
  const handleLoadSrc = async (
    player,
    currentMediaStream: IMediaStream,
    { defaultTime = 0 }: { defaultTime?: number },
  ) => {
    if (player && currentMediaStream?.sources?.length) {
      try {
        configureDrm(player, currentMediaStream?.drm)

        for (let i = 0; i < currentMediaStream?.sources?.length; i++) {
          const source = currentMediaStream?.sources?.[i]

          try {
            // For future
            // const preloadManager = await player.preload(source.src, defaultTime, currentMediaStream?.type)
            await player.load(source.src, defaultTime, currentMediaStream?.type)

            break
          } catch (error) {
            console.warn('Failed to load stream:', source, error)

            if (i === currentMediaStream?.sources?.length - 1) {
              onError(error)
            }
          }
        }
      } catch (error) {
        onError(error)
      }
    }
  }
  /* eslint-enable */

  const handleInit = useCallback(async () => {
    try {
      playerRef.current = await initPlayer(shaka, config, {
        videoRef,
        videoEvents,
        playerEvents,
      })

      await handleLoadSrc(playerRef.current, mediaStream, {
        defaultTime: defaultTimePosition,
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error initializing player:', error)

      onError(error)
    }
  }, [
    config,
    defaultTimePosition,
    handleLoadSrc,
    mediaStream,
    onError,
    playerEvents,
    shaka,
    videoEvents,
  ])

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (mediaStreamVersion.isNew && mediaStreamVersion.value && mediaStreamVersion.prevValue) {
      handleLoadSrc(playerRef.current, mediaStreamVersion.value, {
        defaultTime: defaultTimePosition,
      })
    }
  }, [mediaStreamVersion.value]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      handleInit()

      return () => {
        if (videoRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          removeListeners(videoRef.current, videoEvents)
        }

        if (playerRef.current) {
          removeListeners(playerRef.current, playerEvents)
          playerRef.current.destroy()
        }
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      id="video"
      ref={videoRef}
      className={className}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
      }}
    />
  )
}

export const ShakaPlayer = memo<ShakaPlayerProps>(forwardRef(ShakaPlayerPure))

export default ShakaPlayer
