// @flow

import { values, pick, pipe, find, propEq, isNil, not, prop, map } from 'ramda'
import { createSelector } from 'reselect'
import { prepareChannelList } from '@alphaott/common-utils/utils/schemas/channels'

import type { Genre } from '@alphaott/api-client/types/genres'
import type { Category } from '@alphaott/api-client/types/categories'

import type { Channels } from '@alphaott/common-utils/utils/filterChannels'
import type { PreparedChannel } from '@alphaott/common-utils/utils/schemas/channels'

import { getCatchupChannels } from '@alphaott/app-catchup/models/selectors'
import { getFavorite, getChannels, getGenres, getCategories } from './channels'

type FavoriteChannels = Array<string>

export const isFavoriteChannel = (channelId: string) =>
  createSelector(
    [getFavorite],
    (favorite: FavoriteChannels): boolean =>
      pipe(
        find(propEq('item', channelId)),
        isNil,
        not,
      )(favorite),
  )

export const getFavoriteList = createSelector(
  [getFavorite, getChannels, getGenres, getCategories, getCatchupChannels],
  (
    favorite: FavoriteChannels,
    channels: Channels,
    genres: Array<Genre>,
    categories: Array<Category>,
    catchupChannels,
  ): Array<PreparedChannel> =>
    prepareChannelList(
      values(pick(map(prop('item'), favorite), channels)),
      favorite,
      genres,
      categories,
      catchupChannels,
    ),
)
