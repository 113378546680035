// @flow
import { makeGetRequest } from './utils'

import type { ResponseAPI } from './types/api'
import type { Promo } from './types/promo'
import type { DeviceType } from './types/devices'

export const getPromos = (
  domain: string,
  serviceSpec: string,
  deviceType: DeviceType,
  devicePlatform: string,
): Promise<ResponseAPI<Promo>> =>
  makeGetRequest(
    // eslint-disable-next-line max-len
    `${domain}/client/api/v2/servicespec/${serviceSpec}/promos?deviceType=${deviceType}&devicePlatform=${devicePlatform}`,
  )
