// @flow

import { concat, dropLast, join, keys, map, not, path, pathOr, reject } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'

import { flattenObj } from '@alphaott/common-utils'
import type { Fields } from '@alphaott/webtv-app/components/Authorization/Forms/SignUp/types'
import type { Customer } from '@alphaott/api-client/types/customer'

const DEFAULT_ENABLED = true
const DEFAULT_REQUIRED = false

export const isEnabled = (fieldPath: string[], fields: Fields): boolean =>
  pathOr(DEFAULT_ENABLED, concat(fieldPath, ['enabled']), fields)

export const isRequired = (fieldPath: string[], fields: Fields): boolean =>
  pathOr(DEFAULT_REQUIRED, concat(fieldPath, ['required']), fields)

export const prepareMissedAccountFields = (fields: Object, customer: Customer) => {
  const fieldKeys = keys(flattenObj(fields))
  const preparedAllFieldPaths = map(key => dropLast(1, key.split('.')), fieldKeys)
  const preparedRequiredFieldPaths = reject(
    fieldPath => not(isEnabled(fieldPath, fields) && isRequired(fieldPath, fields)),
    preparedAllFieldPaths,
  )
  const missedFieldPaths = reject(fieldPath => isNotNilOrEmpty(path(fieldPath, customer)), preparedRequiredFieldPaths)
  return map(fieldPath => join('.', fieldPath), missedFieldPaths)
}
