// @flow
import { makeGetRequest, makePatchRequest, makePostRequest } from './utils'

import type { ResponseAPI, Headers } from './types/api'
import type { Customer } from './types/customer'

type Options = { ...Headers, data?: any }

export const getCustomer = (domain: string, { token }: Headers): Promise<ResponseAPI<Customer>> =>
  makeGetRequest(`${domain}/client/api/customer`, { headers: { token } })

export const updateCustomer = (domain: string, { token, data }: Options): Promise<ResponseAPI<Customer>> =>
  makePatchRequest(`${domain}/client/api/customer`, {
    headers: { token },
    data,
  })

export const updateCustomerSettings = (domain: string, { token, data }: Options): Promise<ResponseAPI<Customer>> =>
  makePostRequest(`${domain}/client/api/customer/settings`, {
    headers: { token },
    data,
  })

export const changePassword = (domain: string, { token, data }: Options): Promise<ResponseAPI<any>> =>
  makePostRequest(`${domain}/client/api/customer/password`, {
    headers: { token },
    data,
  })

export const voucherActivate = (domain: string, { token, data }: Options): Promise<ResponseAPI<any>> =>
  makePostRequest(`${domain}/client/api/customer/voucher/activate`, {
    headers: { token },
    data,
  })

export const deactivateCustomer = (domain: string, { token }: Options): Promise<ResponseAPI<any>> =>
  makePostRequest(`${domain}/client/api/customer/deactivate`, {
    headers: { token },
  })

export default getCustomer
