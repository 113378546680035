// @flow
import { getRadioChannelSources } from '@alphaott/api-client'
import { logoutRequestWrapper } from '@alphaott/app-main/utils'
import { prepareAcceptMediaStreamHeaders, prepareAcceptMediaStreamEncryptedHeaders } from '@alphaott/drm-web'

export const GET_RADIO_CHANNELS_SOURCES_MAP = 'radioChannels/GET_SOURCES_MAP'
export const RESET_RADIO_CHANNEL_SOURCES = 'radioChannels/RESET_RADIO_CHANNEL_SOURCES'

const acceptMediaStream = prepareAcceptMediaStreamHeaders()
const acceptMediaStreamEncrypted = prepareAcceptMediaStreamEncryptedHeaders()

export const getRadioChannelSourcesMap = (radioId: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: GET_RADIO_CHANNELS_SOURCES_MAP,
    payload: logoutRequestWrapper(getRadioChannelSources)(radioId, { acceptMediaStream, acceptMediaStreamEncrypted })(
      dispatch,
      getState,
    ),
  })

export const resetRadioChannelSource = {
  type: RESET_RADIO_CHANNEL_SOURCES,
}
