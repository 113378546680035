// @flow
import { map, pipe, prop, path, isEmpty, isNil, last } from 'ramda'
import type { Product, Subscription } from '@alphaott/api-client/types/subscriptions'

import { defaultToEmptyObject, defaultToEmptyArray, defaultToEmptyString } from '../../help'

const prepareImage = (domain: string) => map(item => ({ ...item, path: `${domain}${item.path}` }))

export const getProduct = pipe(
  prop('products'),
  defaultToEmptyArray,
  last,
  defaultToEmptyObject,
)

export const getTitle = pipe(
  // $FlowFixMe
  getProduct,
  prop('title'),
  defaultToEmptyString,
)

export const getPosters = pipe(
  getProduct,
  path(['productSpec', 'posters']),
  defaultToEmptyArray,
)

const prepareProducts = (posters, products) =>
  map((product: Product) => ({ ...product, productSpec: { ...product.productSpec, posters } }), products)

/* eslint-disable complexity */
export const prepareSubscription = (subscription: Subscription, domain: string): Subscription => {
  if (isEmpty(subscription) || isNil(subscription)) return subscription

  const posters = prepareImage(domain)(getPosters(subscription))
  const products = subscription.products ? prepareProducts(posters, subscription.products) : []

  return {
    ...subscription,
    products,
  }
}
/* eslint-enable */
