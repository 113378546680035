// @flow

import { useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BUTTON_KEY_NAMES } from '@alphaott/smart-tv-platforms'

import {
  changeForceShowControls,
  changeShowControls,
  changeShowSubtitlesSettings,
  changeShowTVListControl,
} from '../models/actions'
import {
  getForceShowControls,
  getShowControls,
  getShowSubtitlesSettings,
  getShowListControl,
} from '../models/selectors'

const HIDE_CONTROLS_DELAY = 5000

export const useUiControls = () => {
  const dispatch = useDispatch()

  const isForceShowControls = useSelector(getForceShowControls)
  const isShowControls = useSelector(getShowControls)
  const isShowSubtitlesSettings = useSelector(getShowSubtitlesSettings)
  const isShowListControl = useSelector(getShowListControl)

  const ref = useRef(null)

  const handleForceShowControls = useCallback(
    (value) => {
      dispatch(changeForceShowControls(value))
    },
    [dispatch],
  )

  const handleSetTimeout = useCallback(() => {
    ref.current = setTimeout(() => {
      dispatch(changeShowControls(false))
    }, HIDE_CONTROLS_DELAY)
  }, [dispatch])

  const handleClearTimeout = useCallback(() => {
    clearTimeout(ref.current)
  }, [])

  // eslint-disable-next-line complexity
  const handleShowControls = useCallback(() => {
    if (isShowSubtitlesSettings || isShowListControl) {
      return
    }
    dispatch(changeShowControls(true))
    if (isForceShowControls) {
      return
    }
    handleClearTimeout()
    handleSetTimeout()
  }, [
    isShowSubtitlesSettings,
    isShowListControl,
    dispatch,
    isForceShowControls,
    handleClearTimeout,
    handleSetTimeout,
  ])

  useEffect(() => {
    window?.removeEventListener('keydown', handleShowControls)
    window?.removeEventListener('keyup', handleShowControls)

    window?.addEventListener('keydown', handleShowControls)
    window?.addEventListener('keyup', handleShowControls)
    return () => {
      window?.removeEventListener('keydown', handleShowControls)
      window?.removeEventListener('keyup', handleShowControls)
    }
  }, [handleShowControls])

  const handleOpenSubtitleSettings = useCallback(() => {
    if (isShowControls) {
      handleClearTimeout()
      dispatch(changeShowControls(false))
    }

    dispatch(changeShowSubtitlesSettings(true))
  }, [isShowControls, dispatch, handleClearTimeout])

  const handleCloseSubtitleSettings = useCallback(() => {
    dispatch(changeShowSubtitlesSettings(false))

    if (isForceShowControls) {
      dispatch(changeShowControls(true))
    }
  }, [isForceShowControls, dispatch])

  const handleOpenChannelsList = useCallback(() => {
    if (isShowControls) {
      handleClearTimeout()
      dispatch(changeShowControls(false))
    }

    dispatch(changeShowTVListControl(true))
  }, [isShowControls, dispatch, handleClearTimeout])

  const handleCloseChannelsList = useCallback(() => {
    dispatch(changeShowTVListControl(false))
  }, [dispatch])

  const handleCallback = useCallback(() => {
    if (!isForceShowControls) handleShowControls()
  }, [isForceShowControls, handleShowControls])

  useEffect(() => {
    if (!isShowListControl) {
      handleShowControls()
    }
  }, [isShowListControl, handleShowControls])

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (isForceShowControls) {
      handleClearTimeout()
      if (!isShowSubtitlesSettings && !isShowControls && !isShowListControl) {
        dispatch(changeShowControls(true))
      }
    } else {
      handleShowControls()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForceShowControls])

  useEffect(
    () => () => {
      handleClearTimeout()
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleBackPress = useCallback(
    (event) => {
      if (isShowSubtitlesSettings) {
        handleCloseSubtitleSettings(false)
        event.stopPropagation()
      }
    },
    [handleCloseSubtitleSettings, isShowSubtitlesSettings],
  )

  useEffect(() => {
    window?.addEventListener(BUTTON_KEY_NAMES.BACK, handleBackPress, true)
    return () => window?.removeEventListener(BUTTON_KEY_NAMES.BACK, handleBackPress, true)
  }, [handleBackPress])

  return {
    isShowControls,
    isShowSubtitlesSettings,
    isShowListControl,
    onOpenSubtitleSettings: handleOpenSubtitleSettings,
    onCloseSubtitleSettings: handleCloseSubtitleSettings,
    onOpenChannelsList: handleOpenChannelsList,
    onCloseChannelsList: handleCloseChannelsList,
    onShowControls: handleCallback,
    onForceShowControls: handleForceShowControls,
  }
}

export default useUiControls
