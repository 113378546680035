// @flow
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

import { getBackgroundColor, getTextColorPrimary } from '@alphaott/smart-tv-themes'
import { Button } from '@alphaott/smart-tv-components'

export const ProfileContainer = styled(Flex)`
  flex-direction: column;
  min-height: 900rem;
  height: 100vh;
  width: 100vw;
  background-color: ${getBackgroundColor};
  padding: 0 107rem 107rem;
`

export const Row = styled(Flex)`
  display: inline-flex;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  margin-top: 33rem;
`

export const RowBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  margin-top: 54rem;
`

export const RightColumn = styled(Box)`
  width: 577rem;
`

export const ButtonWrapper = styled.div`
  align-self: flex-end;
  margin-top: 15rem;
`

export const EditHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50rem 0;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
`

export const EditSmallButton = styled(Button)`
  min-width: 276rem;
  min-height: 54rem;
  text-align: left;
  text-transform: uppercase;
  font-size: 24rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.87);
`

export const EditTitle = styled.div`
  font-weight: 300;
  font-size: 66rem;
  line-height: 79rem;
  margin-top: 0;
  color: ${getTextColorPrimary};
  opacity: 0.87;
`

export const EditSmallText = styled.div`
  font-weight: 400;
  font-size: 32rem;
  line-height: 38rem;
  margin-top: 10rem;
  color: ${getTextColorPrimary};
  opacity: 0.7;
`

export const ParentControlLargeText = styled.div`
  font-weight: 300;
  font-size: 40rem;
  line-height: 50rem;
  margin-top: 30rem;
  text-align: left;
  color: ${getTextColorPrimary};
`
