// @flow

import { join } from 'ramda'

import { getTvShowsByIds } from '@alphaott/api-client'
import { isNotEmpty } from '@alphaott/common-utils'
import { getContentDomain, getPublicDomain } from '@alphaott/app-main/selectors'

import type { Dispatch } from '@alphaott/common-types'

import type { TVShowsStore } from '../../types'

export const SUGGESTED_TVSHOW = 'tvshows/SUGGESTED'
export const FETCH_SUGGESTED_TVSHOW = 'tvshows/FETCH_SUGGESTED'

export type SuggestedTVShowAction = {|
  type: 'tvshows/SUGGESTED',
  id: string,
|}

export const suggestedTVShow = (id: string): SuggestedTVShowAction => ({
  type: SUGGESTED_TVSHOW,
  id,
})

export const fetchSuggestedTVShowByIds = (suggestedIds: string[] = []) => (dispatch: Dispatch, getState: Function) => {
  const state: TVShowsStore = getState()

  if (isNotEmpty(suggestedIds)) {
    dispatch({
      type: FETCH_SUGGESTED_TVSHOW,
      payload: getTvShowsByIds(getPublicDomain(state), join(',', suggestedIds)),
      meta: {
        contentApi: getContentDomain(getState()),
      },
    })
  }
}
