// @flow
import typeToReducer from 'type-to-reducer'

import { SELECT_PROGRAM_GUIDE } from '../actions'

export type ProgramGuideConfigState = {|
  channel: any,
  program: any,
|}

const initialState = {
  channel: {},
  program: {},
}

const programGuideConfig = typeToReducer(
  {
    [SELECT_PROGRAM_GUIDE]: (state: ProgramGuideConfigState, { channel, program }: any): ProgramGuideConfigState => ({
      ...state,
      channel,
      program,
    }),
  },
  initialState,
)

export { programGuideConfig }
