// @flow
import React, { memo } from 'react'
import { toString } from 'ramda'

import { Container, CheckIcon, Button } from './AccessRatingButton.styles'

type AccessRatingButtonProps = {
  label: string,
  isActive?: boolean,
  onClick: Function,
}

export const AccessRatingButtonPure = ({ label, isActive, onClick }: AccessRatingButtonProps) => (
  <Container>
    <Button focusKey={toString(label)} label={label} onClick={onClick}>
      <span>{label}</span>
      {isActive && <CheckIcon />}
    </Button>
  </Container>
)

export const AccessRatingButton = memo(AccessRatingButtonPure)
