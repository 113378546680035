// @flow
import typeToReducer from 'type-to-reducer'

import type { City } from '@alphaott/api-client/types/cities'

import { FETCH_CITIES_TYPE } from '../../actions/appCities'

export type CitiesState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Array<City>,
}

const initialState: CitiesState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
}

const cities = typeToReducer(
  {
    [FETCH_CITIES_TYPE]: {
      LOADING: (state): CitiesState => ({
        ...state,
        errors: [],
        data: [],
        isLoading: true,
      }),

      SUCCESS: (state, action): CitiesState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        errors: [],
        data: action.payload.data,
      }),

      ERROR: (state, action): CitiesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        data: [],
      }),
    },
  },
  initialState,
)
export { cities }
