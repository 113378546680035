// @flow
import styled from 'styled-components'

import {
  getBackgroundSecondaryColor,
  getColorPrimary,
  getColorSlightPrimary,
  getColorPrimaryContrastText,
  getTextColorPrimary,
} from '@alphaott/smart-tv-themes'
import { Image } from '@alphaott/smart-tv-components'

export const CHANNEL_ITEM_WIDTH: number = 524
export const CHANNEL_ITEM_HEIGHT: number = 78
const LOGO_WIDTH: number = 90
const LOGO_HEIGHT: number = 60

const getBackgroundColor = (props) => {
  if (props.focused) {
    return getColorPrimary(props)
  }
  if (props.isSelected) {
    return getColorSlightPrimary(props)
  }
  return getBackgroundSecondaryColor(props)
}

export const ItemContainer = styled.div`
  color: ${(props) =>
    props.focused ? getColorPrimaryContrastText(props) : getTextColorPrimary(props)};
  background: ${(props) => getBackgroundColor(props)};
  border-radius: 4rem;
  width: ${CHANNEL_ITEM_WIDTH}rem;
  height: ${CHANNEL_ITEM_HEIGHT}rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.palette.grey[700]};
  }
`

export const Item = styled.div`
  width: calc(100% - 30rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
`

export const Logo = styled(Image)`
  min-width: ${LOGO_WIDTH}rem;
  width: ${LOGO_WIDTH}rem;
  height: ${LOGO_HEIGHT}rem;
  border-radius: 2rem;
  overflow: hidden;
`

export const Placeholder = styled.img`
  width: ${LOGO_WIDTH}rem;
  height: ${LOGO_HEIGHT}rem;
`

const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 38rem;
  line-height: 45rem;
`

export const Number = styled(Text)`
  margin-left: 16rem;
`

export const Title = styled(Text)`
  margin-left: 21rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
