// @flow
import { path, equals, cond, always, T } from 'ramda'
import { createSelector } from 'reselect'

import type { Country } from '@alphaott/api-client/types/countries'
import type { City } from '@alphaott/api-client/types/cities'
import type { Region } from '@alphaott/api-client/types/regions'
import type { Language } from '@alphaott/api-client/types/languages'

import { isNotEmpty } from '@alphaott/common-utils/utils/help'

import type { AppState } from '../reducers'

// ToDo: fixme
type Store = any
// type Store = $Exact<{
//   +customer: CustomerState,
//   +subscriptions: SubscriptionsState,
//   +app: AppState,
//   +countries: CountriesState,
// }>

export const getApp = (state: Store): AppState => state.app

export const isAppLoading = createSelector(
  [getApp],
  (appState: AppState): boolean => appState.isLoading,
)

export const isAppReadySelector = createSelector(
  [getApp],
  (appState: AppState): boolean => appState.isSuccess,
)

export const getAppErrors = (state: Store): any =>
  cond([
    [store => isNotEmpty(store.customer.errors), always(state.customer.errors)],
    [store => isNotEmpty(store.subscriptions.errors), always(state.subscriptions.errors)],
    [T, always(undefined)],
  ])(state)

export const getProfileLoadListsIntervalId = createSelector(
  [getApp],
  (appState: AppState): ?number => path(['data', 'profileLoadListsIntervalId'], appState),
)

export const getLocationSearch = (pathname: string) => equals(pathname, '/search')

export const isLoadingCountries = (store: Store): boolean => store.countries.isLoading

export const getCountries = (store: Store): Array<Country> => store.countries.data

export const getCities = (store: Store): Array<City> => store.cities.data

export const getRegions = (store: Store): Array<Region> => store.regions.data

export const getLanguages = (store: Store): Array<Language> => store.languages.data

export const isLanguagesLoading = (store: Store): boolean => store.languages.isLoading
