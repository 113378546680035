// @flow

import React, { memo, useEffect, useCallback } from 'react'
import { equals } from 'ramda'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'

import { Container, Menu, TabBar, Scene } from './TracksMenu.styles'

import { useTabs } from './useTabs'
import { useTracks } from './useTracks'

export type TracksMenuProps = {
  className?: any,
  videoId: string,
  onChangeAudioTrack: (track: Object) => void,
  onChangeSubtitleTrack: (track: Object) => void,
  onClose: Function,
}

const TracksMenuPure = ({
  className,
  videoId,
  onChangeAudioTrack,
  onChangeSubtitleTrack,
  onClose,
}: TracksMenuProps) => {
  const { ref, focusKey, setFocus } = useFocusable({ isFocusBoundary: true })

  const { defaultTab, activeTab, activeTabTitle, tabs, onSelectTab } = useTabs(videoId)
  const { tracks, onSelectTrack } = useTracks(
    videoId,
    activeTab,
    onChangeAudioTrack,
    onChangeSubtitleTrack,
  )

  const handleSelectTab = useCallback(
    (value) => {
      if (equals(value, 'close')) {
        onClose()
      }

      onSelectTab(value)
    },
    [onSelectTab, onClose],
  )

  useEffect(() => {
    if (defaultTab && setFocus) setFocus(defaultTab)
  }, [defaultTab, setFocus]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FocusContext.Provider value={focusKey}>
      <Container ref={ref}>
        <Menu className={className}>
          <TabBar activeItem={activeTab} items={tabs} onClick={handleSelectTab} />
          <Scene title={activeTabTitle} items={tracks} onClick={onSelectTrack} />
        </Menu>
      </Container>
    </FocusContext.Provider>
  )
}

export const TracksMenu = memo(TracksMenuPure)

export default TracksMenu
