// @flow weak
import { createSelector } from 'reselect'
import { and, not, path, prop, propEq, findIndex, pipe, ifElse, equals, always } from 'ramda'

import type { CatchupMediaStream } from '@alphaott/api-client/types/catchup/sources'
import type { CatchupChannelOption } from '@alphaott/api-client/types/catchup/channels'
import type { CatchupSourcesState } from '@alphaott/app-catchup/reducers'
import type { PreparedPrograms, PreparedProgram } from '@alphaott/app-programs/selectors'

import { defaultToEmptyString, isInitialLoading } from '@alphaott/common-utils/utils/help'
import { getCatchupMediaStream, getCatchupSourcesState, getCatchupChannel } from '@alphaott/app-catchup/selectors'
import { getProgramList } from '@alphaott/app-programs/selectors'
import { inCatchupInterval } from '@alphaott/app-programs/utils'

export const getCatchupVastTag = (id: string) =>
  createSelector(
    [getCatchupMediaStream(id)],
    (mediaStream: CatchupMediaStream): string => defaultToEmptyString(path(['ads', 'vastUrl'], mediaStream)),
  )

export const getCatchupAdProvider = (id: string) =>
  createSelector(
    [getCatchupMediaStream(id)],
    (mediaStream: CatchupMediaStream): string => defaultToEmptyString(path(['ads', 'provider'], mediaStream)),
  )

export const isProgramSourcesLoading = createSelector(
  [getCatchupSourcesState],
  (sourcesState: CatchupSourcesState): boolean => isInitialLoading(sourcesState),
)

export const hasProgramSourcesError = createSelector(
  [getCatchupSourcesState, isProgramSourcesLoading],
  (sourcesState: CatchupSourcesState, isLoading: boolean): boolean =>
    and(not(isLoading), prop('isError', sourcesState)),
)

export const getNextCatchupProgram = (channelId: string, currentProgramId: string) =>
  createSelector(
    [getProgramList(channelId), getCatchupChannel(channelId)],
    // eslint-disable-next-line complexity
    (programs: PreparedPrograms, channelOptions: ?CatchupChannelOption): ?PreparedProgram => {
      const nextProgram = pipe(
        findIndex(propEq('_id', currentProgramId)),
        ifElse(equals(-1), always(null), index => programs[index + 1]),
      )(programs)

      if (!nextProgram) return null
      if (channelOptions && inCatchupInterval(nextProgram.start, nextProgram.stop, channelOptions.window)) {
        return nextProgram
      }
      return null
    },
  )
