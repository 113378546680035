// @flow
import typeToReducer from 'type-to-reducer'

import type { RadioChannelMediaStreams } from '@alphaott/api-client/types/radioChannels'

import { GET_RADIO_CHANNELS_SOURCES_MAP, RESET_RADIO_CHANNEL_SOURCES } from '../../actions'

export type RadioChannelSourcesState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: RadioChannelMediaStreams,
}

const initialState: RadioChannelSourcesState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: null,
  data: [],
}

const radioChannelSources = typeToReducer(
  {
    [GET_RADIO_CHANNELS_SOURCES_MAP]: {
      LOADING: (state): RadioChannelSourcesState => ({ ...state, data: [], isLoading: true }),

      SUCCESS: (state, action): RadioChannelSourcesState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        errors: null,
        data: action.payload.data,
      }),

      ERROR: (state, action): RadioChannelSourcesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
    [RESET_RADIO_CHANNEL_SOURCES]: () => initialState,
  },
  initialState,
)

export { radioChannelSources }
