// @flow

import { equals, reject, pipe, head, ifElse, always, isNil, cond, T } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'

import { getBrowserLanguageKey, getPreferredBrowserLanguageKeys } from '@alphaott/common-utils/utils/languages'
import {
  PLAYER_AUDIO_PARAM,
  PLAYER_SUBTITLES_PARAM,
  DEFAULT_LANGUAGE_OPTION,
  DISABLED_LANGUAGE_OPTION,
} from '@alphaott/app-config'

type Option = typeof PLAYER_AUDIO_PARAM | typeof PLAYER_SUBTITLES_PARAM

type Type = 'primary' | 'secondary'

export const prepareTrackLanguage = (option: Option, type: Type, language?: string) => {
  const preferredBrowserLanguageKeys = getPreferredBrowserLanguageKeys()
  const browserLanguageKey = getBrowserLanguageKey()

  /**
   * preferredBrowserLanguageKeys: ['ru', 'en']
   * browserLanguageKey: 'en'
   *
   * reject browserLanguageKey => ['ru']
   * head => 'ru'
   * ifElse => 'ru'
   */
  const secondaryAudioLanguage = pipe(
    reject(equals(browserLanguageKey)),
    head,
    ifElse(isNil, always(browserLanguageKey), value => value),
  )(preferredBrowserLanguageKeys)

  const prepareLanguage = (systemLanguage, defaultValue = browserLanguageKey) =>
    cond([
      [equals(DEFAULT_LANGUAGE_OPTION.key), always(systemLanguage)],
      [isNotNilOrEmpty, always(language)],
      [T, always(defaultValue)],
    ])(language)

  return cond([
    [
      equals(PLAYER_AUDIO_PARAM),
      always(
        cond([
          [equals('primary'), always(prepareLanguage(browserLanguageKey))],
          [equals('secondary'), always(prepareLanguage(secondaryAudioLanguage))],
        ])(type),
      ),
    ],
    [
      equals(PLAYER_SUBTITLES_PARAM),
      always(
        cond([
          [equals('primary'), always(prepareLanguage(browserLanguageKey, DISABLED_LANGUAGE_OPTION.key))],
          [equals('secondary'), always(prepareLanguage(browserLanguageKey, DISABLED_LANGUAGE_OPTION.key))],
        ])(type),
      ),
    ],
  ])(option)
}
