// @flow
import React, { forwardRef, memo, useCallback, useMemo, useState, useImperativeHandle } from 'react'
import { areEqual } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { findIndex, propEq, not } from 'ramda'
import { isNotNaN, notEqual } from 'ramda-adjunct'

import {
  useLastFocus,
  useLastFocusHistory,
  LIST_TYPES,
} from '@alphaott/smart-tv-spatial-navigation'
import { getHorizontalListScrollOffset, scale } from '@alphaott/smart-tv-utils'
import { HorizontalList } from '@alphaott/smart-tv-components'

import { ChannelCard, CHANNEL_CARD_ITEM_WIDTH, CHANNEL_CARD_ITEM_HEIGHT } from '../ChannelCard'

import { ChannelViewAllCard } from '../ChannelViewAllCard'

type ItemProps = {
  index: number,
  style: any,
  data: {
    items: Array<Object>,
    sectionIndex: number,
    heading: string,
    cardPlaceholder?: string,
    onBecameFocused: Function,
    onPressItem: Function,
    onLongPressItem: Function,
    onPressViewAll: Function,
  },
}

type ChannelListProps = {
  items: Array<any>,
  sectionIndex?: number,
  sectionType: string,
  heading: string,
  cardPlaceholder?: string,
  onPressItem: (any) => void,
  onLongPressItem: (any) => void,
  onPressViewAll: () => void,
}

const listStyle = { overflow: 'hidden' }
const columnIndex = (parentWidth: number, itemWidth: number): number =>
  Math.round(parentWidth / itemWidth)

export const prepareChannelItemFocusKey = (heading: string, id: string): ?string => {
  if (heading && id) return `${id}-${heading}`

  // There is not that element, so don't setFocus
  return null
}

const padding = scale(window.innerWidth, 32)

// eslint-disable-next-line react/display-name,consistent-return
const ChannelItem = memo(({ index, style, data }: ItemProps) => {
  const {
    items,
    sectionIndex,
    sectionType,
    heading,
    cardPlaceholder,
    onBecameFocused,
    onPressItem,
    onLongPressItem,
    onPressViewAll,
  } = data

  const item = items[index]
  const { id, isViewAll } = item
  const focusKey = prepareChannelItemFocusKey(heading, id)
  const preparedStyles = useMemo(() => ({ ...style, left: style.left + padding }), [style])

  const { onSaveLastFocus } = useLastFocusHistory({
    id,
    focusKey,
    sectionIndex,
    itemIndex: index,
    sectionType,
    prepareItemFocusKey: (itemId) => prepareChannelItemFocusKey(heading, itemId),
  })

  const handlePress = useCallback(() => {
    onPressItem(item)
  }, [item, onPressItem])

  const handleLongPress = useCallback(() => {
    onLongPressItem && onLongPressItem(item)
  }, [item, onLongPressItem])

  const handlePressViewAll = useCallback(() => {
    onPressViewAll()
  }, [onPressViewAll])

  const handlePressCallback = useCallback(
    (callback) => () => {
      onSaveLastFocus()
      callback && callback()
    },
    [onSaveLastFocus],
  )

  const handleBecameFocused = useCallback(
    (values) => onBecameFocused({ ...values, focusKey }),
    [focusKey, onBecameFocused],
  )

  if (isViewAll) {
    return (
      <ChannelViewAllCard
        key={focusKey}
        focusKey={focusKey}
        style={preparedStyles}
        onFocus={handleBecameFocused}
        onPress={handlePressCallback(handlePressViewAll)}
      />
    )
  }

  if (not(isViewAll)) {
    return (
      <ChannelCard
        {...item}
        style={preparedStyles}
        cardPlaceholder={cardPlaceholder}
        focusKey={focusKey}
        key={focusKey}
        onFocus={handleBecameFocused}
        onPress={handlePressCallback(handlePress)}
        onLongPress={handlePressCallback(handleLongPress)}
      />
    )
  }
}, areEqual)

// eslint-disable-next-line react/display-name
const innerElementType = forwardRef(({ style, ...props }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      width: style.width + padding * 2,
    }}
    {...props}
  />
))

const ChannelListPure = (
  {
    v2,
    items,
    sectionIndex,
    sectionType,
    heading,
    cardPlaceholder,
    setFocus,
    onPressItem,
    onLongPressItem,
    onPressViewAll,
    onChangeCurrentFocusedItem,
  }: ChannelListProps,
  ref: any,
) => {
  const [listRef, setListRef] = useState(null)

  useLastFocus(LIST_TYPES.HORIZONTAL_LIST, {
    listRef,
    setFocus,
    sectionIndex,
    enableInitialFocus: false,
    isEnabledScroll: !v2,
  })

  const listHeight = useMemo(() => scale(window.innerWidth, CHANNEL_CARD_ITEM_HEIGHT), [])
  const getItemSize = useCallback(() => scale(window.innerWidth, CHANNEL_CARD_ITEM_WIDTH), [])

  const onBecameFocused = useCallback(
    // eslint-disable-next-line complexity
    ({ x, width, focusKey }) => {
      const index = columnIndex(x, width)

      if (isNotNaN(index)) {
        listRef && listRef.scrollTo(getHorizontalListScrollOffset(listRef, index))
      }

      if (onChangeCurrentFocusedItem) {
        onChangeCurrentFocusedItem(focusKey)
      }
    },
    [listRef, onChangeCurrentFocusedItem],
  )

  const handleFocus = useCallback(
    // eslint-disable-next-line complexity
    (focusKey, itemId) => {
      const index = findIndex(propEq('id', itemId), items)

      if (notEqual(index, -1)) {
        listRef && listRef.scrollTo(getHorizontalListScrollOffset(listRef, index))

        setFocus(focusKey)
      }
    },
    [items, listRef, setFocus],
  )

  useImperativeHandle(
    ref,
    () => ({
      heading,
      sectionIndex,
      height: listHeight,
      onFocus: handleFocus,
    }),
    [handleFocus, heading, listHeight, sectionIndex],
  )

  return (
    <AutoSizer>
      {({ width }) => (
        <HorizontalList
          withLastFocus={v2}
          ref={setListRef}
          layout="horizontal"
          height={listHeight}
          width={width}
          itemCount={items.length}
          itemSize={getItemSize}
          innerElementType={innerElementType}
          overscanColumnCount={3}
          itemData={{
            items,
            sectionIndex,
            sectionType,
            heading,
            cardPlaceholder,
            onBecameFocused,
            onPressItem,
            onLongPressItem,
            onPressViewAll,
            getColumnIndex: columnIndex,
          }}
          style={listStyle}
        >
          {ChannelItem}
        </HorizontalList>
      )}
    </AutoSizer>
  )
}

export const ChannelListScrollable = memo<ChannelListProps>(forwardRef(ChannelListPure))

export default ChannelListScrollable
