// @flow

import styled from 'styled-components'
import { or } from 'ramda'

import { Button as ButtonBase } from '@alphaott/smart-tv-components'

import { CustomerSupport as CustomerSupportComponent } from '../CustomerSupport'
import { SupportDetails as SupportDetailsComponent } from '../SupportDetails'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 107rem;
`

export const Header = styled.div`
  padding: 64rem 0;
`

export const Logo = styled.img`
  height: ${({ height }) => or(height, 52)}rem;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

export const CustomerSupport = styled(CustomerSupportComponent)``

export const SupportDetails = styled(SupportDetailsComponent)`
  margin-left: 60rem;
`

export const ButtonsContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ logoHeight }) => 300 - logoHeight}rem;
`

export const Button = styled(ButtonBase).attrs((props) => ({
  onEnterPress: props.onClick,
}))``
