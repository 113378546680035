// @flow
import React, { memo, useMemo } from 'react'
import type { ComponentType } from 'react'

import { useFocusable, useMouseOver } from '@alphaott/smart-tv-spatial-navigation'

import { Container, Label } from './elements'

type Props = {
  focusKey?: string,
  className?: string,
  children?: string,
  icon: any,
  iconSize?: number,
  onClick?: () => void,
}

const ButtonPure = ({ focusKey, className, children, icon: Icon, iconSize, onClick }: Props) => {
  const { ref, focused, focusSelf } = useFocusable({ focusKey, onEnterPress: onClick })
  const { handleMouseOver } = useMouseOver(focusSelf)

  const isShowLabel = useMemo(() => children && focused, [children, focused])

  return (
    <Container
      className={className}
      ref={ref}
      type="button"
      focused={focused}
      iconSize={iconSize}
      onClick={onClick}
      onMouseOver={handleMouseOver}
    >
      <Icon />
      {isShowLabel && <Label>{children}</Label>}
    </Container>
  )
}

ButtonPure.defaultProps = {
  iconSize: 47,
}

export const Button: ComponentType<Props> = memo<Props>(ButtonPure)

export default Button
