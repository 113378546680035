// @flow
import { equals } from 'ramda'

import { GUEST, VOUCHER, DEVICE_CODE, QR_CODE, FIREBASE } from '@alphaott/app-auth/models/constants'
import { ENTITY_TYPE } from '@alphaott/app-server-driven-ui/const'

export const APP_BRAND: ?string = process.env.REACT_APP_BRAND
export const PROD_ENV: boolean = equals(process.env.NODE_ENV, 'production')
export const DEV_ENV: boolean = equals(process.env.NODE_ENV, 'development')
export const TEST_ENV: boolean = equals(process.env.NODE_ENV, 'test')

export const VERSION: string = process.env.REACT_APP_VERSION
export const GIT_REVISION: string = process.env.REACT_APP_GIT_REVISION

export const SCREEN_FOCUS_KEYS = {
  AUTH: {
    METHODS: 'AUTH_METHODS_SCREEN',
    LOGIN: 'LOGIN_SCREEN',
    LOGIN_OTP_EMAIL: 'LOGIN_OTP_EMAIL',
    LOGIN_OTP_SMS: 'LOGIN_OTP_SMS',
  },
  HOME: 'HOME_SCREEN',
  SEARCH: {
    HOME: 'SEARCH_SCREEN',
    RESULTS: 'SEARCH_ALL_RESULTS_SCREEN',
  },
  EPG: 'EPG_SCREEN',
  WELCOME: 'WELCOME_SCREEN',
  EXPIRED: 'EXPIRED_SCREEN',
  TV: 'TV_SCREEN',
  RADIO: 'RADIO_SCREEN',
  MOVIES: {
    LIST: 'MOVIES_SCREEN',
    DETAIL: 'MOVIE_DETAIL_SCREEN',
  },
  TV_SHOWS: {
    LIST: 'TV_SHOWS_SCREEN',
    DETAIL: 'TV_SHOW_DETAIL_SCREEN',
  },
  ACCOUNT: 'ACCOUNT_SCREEN',
  MY_LIST: 'MY_LIST_SCREEN',
  SETTINGS: {
    HOME: 'SETTINGS_HOME_SCREEN',
    LANGUAGE: {
      HOME: 'SETTINGS_LANGUAGE_SCREEN',
      EDIT: 'SETTINGS_EDIT_LANGUAGE_SCREEN',
    },
    PARENTAL_CONTROL: {
      HOME: 'SETTINGS_PARENTAL_CONTROL_SCREEN',
      EDIT: 'SETTINGS_EDIT_PARENTAL_CONTROL_SCREEN',
    },
    SUPPORT: 'SETTINGS_SUPPORT_SCREEN',
  },
  ERROR: 'ERROR_SCREEN',
}

export const FAVORITE_SECTION = 'favorite'

export const HOME_SCREEN_SECTION_BLACK_LIST = [
  `${ENTITY_TYPE.LIVE_TV}:${FAVORITE_SECTION}`,
  `${ENTITY_TYPE.RADIO}:${FAVORITE_SECTION}`,
  `${ENTITY_TYPE.MOVIE}:${FAVORITE_SECTION}`,
  `${ENTITY_TYPE.TV_SHOW}:${FAVORITE_SECTION}`,
]

export const BLACK_LIST_AUTH_METHODS = [GUEST, VOUCHER, DEVICE_CODE, QR_CODE, FIREBASE]

export const HOME_SCREEN_DEFAULT_PREFETCH_SECTIONS_COUNT = 6
