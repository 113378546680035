// @flow

import typeToReducer from 'type-to-reducer'

import {
  RESEND_VERIFICATION_EMAIL_STATE_TYPE,
  RESEND_VERIFICATION_EMAIL_TYPE,
} from '../../actions/resendVerificationEmailAction'

export type ResendVerificationEmailState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
}

const initialState: ResendVerificationEmailState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
}

const resendVerificationEmail = typeToReducer(
  {
    [RESEND_VERIFICATION_EMAIL_TYPE]: {
      LOADING: (): ResendVerificationEmailState => ({ ...initialState, isLoading: true }),

      SUCCESS: (state): ResendVerificationEmailState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        errors: [],
      }),

      ERROR: (state, action): ResendVerificationEmailState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
    [RESEND_VERIFICATION_EMAIL_STATE_TYPE]: () => initialState,
  },
  initialState,
)

export { resendVerificationEmail }
