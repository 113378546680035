// @flow

import React from 'react'
import styled from 'styled-components'
import { cond, always, T, equals } from 'ramda'

type Props = {
  className?: string,
  type?: string,
  title: string | string[],
}

const types = {
  warning: 'warning',
  danger: 'danger',
  success: 'success',
}

const getBackground = cond([
  [equals(types.danger), always('#F54B5E')],
  [equals(types.success), always('#48D2A0')],
  [T, always('#f8c51c')],
])

const Alert = styled.div`
  position: relative;
  display: inline-block;
  align-items: center;
  max-width: 500rem;
  min-width: 20rem;
  min-height: 40rem;
  padding: ${(props) => (props.useIcon ? '18rem 16rem 18rem 45rem' : '18rem 22rem')};
  background-color: ${(props) => getBackground(props.type)};
  border-radius: 2rem;
  font-size: 26rem;
  color: #fff;
  font-weight: bold;
  line-height: 24rem;
  justify-content: center;
  box-sizing: border-box;
  clear: both;
`

const Title = styled.div`
  word-wrap: break-word;
`
export const AlertMessage = ({ className, type, title }: Props) => (
  <Alert className={className} type={type}>
    {title && <Title>{title}</Title>}
  </Alert>
)
AlertMessage.types = types

AlertMessage.defaultProps = {
  type: types.success,
  // eslint-disable-next-line react/default-props-match-prop-types
  title: 'You are not connected to the internet.',
}

export default AlertMessage
