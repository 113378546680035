// @flow

import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

import { getColorPrimary } from '@alphaott/smart-tv-themes'

import { Text, Button } from '@alphaott/smart-tv-components'

export const StyledModal = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 90rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
`

export const ModalContainer = styled.div`
  background-color: rgba(44, 44, 44, 0.9);
  border-radius: 8rem;
  display: flex;
  padding: 50rem;
  display: flex;
  flex-direction: column;
  max-width: 800rem;
  align-items: flex-start;
`

export const Title = styled(Text)`
  font-weight: bold;
  letter-spacing: 0.47rem;
  text-transform: uppercase;
`

export const Divider = styled.div`
  height: 2rem;
  width: 34rem;
  margin: 8rem 0;
  background-color: white;
`

export const TextTitle = styled(Text)`
  font-size: 32rem;
  font-weight: bold;
  letter-spacing: 0.93rem;
  line-height: 39rem;
`

export const TextMessage = styled(Text)`
  margin-top: 24rem;
  letter-spacing: 0.47rem;
  white-space: pre-line;
`

export const StyledButton = styled(Button)`
  margin-top: 20rem;
`

export const LinkText = styled(Text)`
  font-weight: bold;
  color: ${getColorPrimary};
  margin-top: 12rem;
`

export const Row = styled(Flex)`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
`
export const LeftColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-right: 50rem;
  flex: 1;
`

export const RightColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 220rem;
`

export const QRHeading = styled(Text)`
  font-size: 21rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.87);
  width: 220rem;
  text-align: center;
  overflow: hidden;
`

export const QRCodeContainer = styled.div`
  background-color: white;
  padding: 10rem;
  margin-top: 20rem;
`
