// @flow

import React, { memo, useEffect, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'

import {
  Container,
  Title,
  Content,
  CustomerSupport,
  SupportDetails,
} from './VerticalSupportScreen.styles'

import type { CustomerSupportProps } from '../CustomerSupport'
import type { SupportDetailsProps } from '../SupportDetails'

type VerticalSupportScreenProps = CustomerSupportProps &
  SupportDetailsProps & {
    className?: string,
    supportAddress: string,
  }

const VerticalSupportScreenPure = ({
  className,
  supportEmail,
  infoEmail,
  companyWww,
  companyName,
  supportAddress,
  deviceInfo,
  date,
}: VerticalSupportScreenProps) => {
  const { ref, setFocus } = useFocusable()
  const { t } = useTranslation()

  const scrollbarRef = useRef(null)
  const supportDetailsRef = useRef(null)

  useEffect(() => {
    if (setFocus) setFocus('CUSTOMER_SUPPORT')
  }, [setFocus])

  const handleScrollToCustomerSupport = useCallback(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollTo(0, 0)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleScrollToSupportDetails = useCallback(() => {
    if (scrollbarRef.current && supportDetailsRef.current) {
      const { top } = supportDetailsRef.current.getBoundingClientRect()
      scrollbarRef.current.scrollTo(0, top)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={ref} style={{ height: '100%' }}>
      <Container className={className} ref={scrollbarRef}>
        <Title>{t('Support')}</Title>

        <Content>
          <CustomerSupport
            focusKey="CUSTOMER_SUPPORT"
            companyName={companyName}
            companyWww={companyWww}
            supportAddress={supportAddress}
            infoEmail={infoEmail}
            supportEmail={supportEmail}
            onScroll={handleScrollToCustomerSupport}
          />

          <SupportDetails
            focusKey="SUPPORT_DETAILS"
            ref={supportDetailsRef}
            deviceInfo={deviceInfo}
            date={date}
            onScroll={handleScrollToSupportDetails}
          />
        </Content>
      </Container>
    </div>
  )
}

export const VerticalSupportScreen = memo<VerticalSupportScreenProps>(VerticalSupportScreenPure)

export default VerticalSupportScreen
