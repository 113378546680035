// @flow

import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { replace, map, prop, split, head, gte, equals, is, or } from 'ramda'

import { getHttpForSourceLinkEnabled } from '@alphaott/app-main/selectors'
import { getOsVersion } from '@alphaott/smart-tv-current-device/selectors'
import { getDeviceType, PLATFORMS } from '@alphaott/smart-tv-platforms'

const devicePlatform = getDeviceType()
// From this version and below - old devices
const OLD_OS_VERSION = 4

// For older devices
export const useHttpForSourceLink = () => {
  const isHttpForSourceLinkEnabled = useSelector(getHttpForSourceLinkEnabled)
  const osVersion = useSelector(getOsVersion)

  const isWebPlatform = equals(devicePlatform, PLATFORMS.WEB)
  const isOldDevice = useMemo(() => {
    if (isWebPlatform) return false

    const majorVersion = head(split('.', String(osVersion)))
    return gte(OLD_OS_VERSION, majorVersion)
  }, [isWebPlatform, osVersion])

  const handleReplaceScheme = useCallback((src) => replace('https://', 'http://', src), [])

  const handlePrepareSource = useCallback(
    (source) => {
      if (!source) {
        return null
      }

      return {
        ...source,
        src: handleReplaceScheme(source.src),
      }
    },
    [handleReplaceScheme],
  )

  const handlePrepareSources = useCallback(
    (sources) =>
      is(Array, sources) &&
      map(
        (item) => ({
          ...item,
          src: handleReplaceScheme(prop('src', item)),
        }),
        or(sources, []),
      ),
    [handleReplaceScheme],
  )

  return {
    isRequiredHttpScheme: isHttpForSourceLinkEnabled && isOldDevice,
    onPrepareSource: handlePrepareSource,
    onPrepareSources: handlePrepareSources,
  }
}

export default useHttpForSourceLink
