// @flow

import { useMemo } from 'react'
import { path, prop, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'

export const useCastInfo = (data) => {
  const title = useMemo(() => prop('title', data), [data])
  const plot = useMemo(() => prop('plot', data), [data])
  const posterSrc = useMemo(() => path(['posters', 0, 'path'], data), [data])
  const crew = useMemo(() => prop('crew', data), [data])
  const year = useMemo(() => prop('year', data), [data])
  const runtime = useMemo(() => prop('runtime', data), [data])
  const seasons = useMemo(() => prop('seasons', data), [data])
  const episodes = useMemo(() => prop('episodes', data), [data])
  const pgRating = useMemo(() => prop('pgRating', data), [data])
  const rating = useMemo(() => prop('rating', data), [data])
  const genres = useMemo(() => prop('genres', data), [data])
  const country = useMemo(() => path(['countries', 0], data), [data])
  const isShowRuntime = useMemo(() => propOr(true, 'isShowRuntime', data), [data])
  const isAvailableFavoriteButton = useMemo(() => prop('isAvailableFavoriteButton', data), [data])
  const isAvailablePlayButton = useMemo(() => prop('isAvailablePlayButton', data), [data])
  const isAvailableTrailerButton = useMemo(() => isNotNilOrEmpty(path(['trailers'], data)), [data])
  const isFavorite = useMemo(() => prop('isFavorite', data), [data])
  const onPlay = useMemo(() => prop('onPlay', data), [data])
  const onPlayTrailer = useMemo(() => prop('onPlayTrailer', data), [data])
  const onFavorite = useMemo(() => prop('onFavorite', data), [data])

  return {
    title,
    plot,
    posterSrc,
    crew,
    year,
    runtime,
    seasons,
    episodes,
    pgRating,
    rating,
    genres,
    country,
    isShowRuntime,
    isAvailableFavoriteButton,
    isAvailablePlayButton,
    isAvailableTrailerButton,
    isFavorite,
    onPlay,
    onPlayTrailer,
    onFavorite,
  }
}

export default useCastInfo
