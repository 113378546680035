// @flow

import typeToReducer from 'type-to-reducer'
import { sortBy, prop, map } from 'ramda'

import type { Language } from '@alphaott/api-client/types/languages'

import { FETCH_LANGUAGES_TYPE } from '../../actions/appLanguages'

export type LanguagesState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Array<Language>,
}

export const initialState: LanguagesState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
}

const languages = typeToReducer(
  {
    [FETCH_LANGUAGES_TYPE]: {
      LOADING: (state): LanguagesState => ({
        ...state,
        errors: [],
        data: [],
        isLoading: true,
      }),

      SUCCESS: (state, action): LanguagesState => {
        const preparedLanguages = map(({ alpha2, nativeName }) => ({ key: alpha2, nativeName }), action.payload.data)
        const sortedLanguages = sortBy(prop('nativeName'), preparedLanguages)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          errors: [],
          data: sortedLanguages,
        }
      },

      ERROR: (state, action): LanguagesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        data: [],
      }),
    },
  },
  initialState,
)
export { languages }
