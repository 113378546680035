// @flow
import { getChannelSources } from '@alphaott/api-client'
import { logoutRequestWrapper } from '@alphaott/app-main/utils'
import { GET_CHANNELS_SOURCES_MAP } from '@alphaott/app-channels/actions'

import { acceptMediaStream, acceptMediaStreamEncrypted } from '@alphaott/smart-tv-drm'

export const getChannelSourcesMap = (id: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: GET_CHANNELS_SOURCES_MAP,
    payload: logoutRequestWrapper(getChannelSources)({
      id,
      acceptMediaStream,
      acceptMediaStreamEncrypted,
    })(dispatch, getState),
  })

export default getChannelSourcesMap
