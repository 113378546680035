// @flow
import { getLimits } from '@alphaott/api-client'
import { limitLogoutWrapper } from '@alphaott/app-main/utils'

export const GET_RADIO_CHANNEL_LIMITS = 'radioChannels/GET_LIMITS'
export const RESET_RADIO_CHANNEL_LIMITS = 'radioChannels/RESET_LIMITS'

export const getRadioChannelLimits = (contentId: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: GET_RADIO_CHANNEL_LIMITS,
    payload: limitLogoutWrapper(getLimits)(contentId, 'radio')(dispatch, getState),
  })

export const resetRadioChannelLimits = {
  type: RESET_RADIO_CHANNEL_LIMITS,
}
