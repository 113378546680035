// @flow

import { useInitInput } from './keyboard'
import { useInitDeviceInfo } from './deviceInfo'

export const useInit = () => {
  useInitInput()
  useInitDeviceInfo()
}

export const useExit = () => {
  window.close()
}
