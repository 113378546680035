// @flow
import typeToReducer from 'type-to-reducer'
import { normalize } from 'normalizr'
import { path } from 'ramda'

import tvProgramsListSchema from '@alphaott/common-utils/utils/schemas/tvPrograms'

import type { Program } from '@alphaott/api-client/types/programs'

import { FETCH_PROGRAMS_BY_DATE_TYPE, FETCH_PROGRAMS_GRID } from '../actions'
import { getFilteredPrograms } from '../../../utils'

type ChannelID = string
type ProgramID = string

export type ProgramsListState = {
  [ProgramID]: Program,
}

export type ProgramsDyDateState = {
  [Date]: {
    +isLoading: boolean,
    +isSuccess: boolean,
    +isError: boolean,
    +errors: any,
    +data: ProgramsListState,
  },
}

export type ChannelProgramsListState = {
  [ChannelID]: ProgramsDyDateState,
}

export type TVGridState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: ChannelProgramsListState | {},
}

const initialState: TVGridState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: {},
}

const tvGrid = typeToReducer(
  {
    [FETCH_PROGRAMS_GRID]: {
      LOADING: (state): TVGridState => ({
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
      }),

      SUCCESS: (state): TVGridState => ({
        ...state,
        isSuccess: true,
        isLoading: false,
        isError: false,
      }),

      ERROR: (state, action): TVGridState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },

    [FETCH_PROGRAMS_BY_DATE_TYPE]: {
      LOADING: (state, action): TVGridState => {
        const { meta } = action
        const { channelId, date } = meta

        return {
          ...state,
          isLoading: true,
          isSuccess: false,
          isError: false,
          data: {
            ...state.data,
            [channelId]: {
              ...path(['data', channelId], state),
              [date]: {
                ...path(['data', channelId, date], state),
                isLoading: true,
                isSuccess: false,
                isError: false,
              },
            },
          },
        }
      },

      SUCCESS: (state, action): TVGridState => {
        const { payload, meta } = action
        const { channelId, date } = meta
        const filteredPrograms = getFilteredPrograms(payload.data)
        const { entities } = normalize(filteredPrograms, tvProgramsListSchema)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isError: false,
          data: {
            ...state.data,
            [channelId]: {
              ...path(['data', channelId], state),
              [date]: {
                ...path(['data', channelId, date], state),
                isLoading: false,
                isSuccess: true,
                isError: false,
                data: entities.tvPrograms,
              },
            },
          },
        }
      },

      ERROR: (state, action): TVGridState => {
        const { payload, meta } = action
        const { channelId, date } = meta

        return {
          ...state,
          data: {
            ...state.data,
            [channelId]: {
              ...path(['data', channelId], state),
              [date]: {
                ...path(['data', channelId, date], state),
                isLoading: false,
                isSuccess: false,
                isError: true,
                errors: payload.error,
              },
            },
          },
        }
      },
    },
  },
  initialState,
)

export { tvGrid }
