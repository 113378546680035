// @flow
import { path } from 'ramda'

import { getRadioChannelCategories } from '@alphaott/api-client'
import { getPublicDomain, getCacheChannels, isCacheValid } from '@alphaott/app-main/selectors'

export const FETCH_RADIO_CHANNEL_CATEGORIES = 'radioChannels/FETCH_CATEGORIES'

const fetchRadioChannelCategoriesAction = (serviceSpec: string) => (dispatch: Function, getState: Function) => {
  const state = getState()
  const publicApi = getPublicDomain(state)
  const ttl = getCacheChannels(state)

  return dispatch({
    type: FETCH_RADIO_CHANNEL_CATEGORIES,
    payload: getRadioChannelCategories(publicApi, serviceSpec),
    meta: {
      ttl,
    },
  })
}

// eslint-disable-next-line complexity
export const fetchRadioChannelCategories = (serviceSpec: string, force: ?boolean = false) => (
  dispatch: Function,
  getState: Function,
) => {
  if (force) return dispatch(fetchRadioChannelCategoriesAction(serviceSpec))

  const state = getState()
  const expires = path(['channelCategories', 'expires'], state)

  if (expires && isCacheValid(expires)) return null

  return dispatch(fetchRadioChannelCategoriesAction(serviceSpec))
}
