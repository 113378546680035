// @flow
import { createSelector } from 'reselect'
import {
  both,
  either,
  filter,
  head,
  lte,
  map,
  not,
  pipe,
  prop,
  propEq,
  propOr,
  isEmpty,
  F,
  propSatisfies,
  find,
  complement,
  pathEq,
  toLower,
} from 'ramda'

import { isNotEmpty, defaultToEmptyString } from '@alphaott/common-utils/utils/help'
import type { Subscription } from '@alphaott/api-client/types/subscriptions'
import type { Customer } from '@alphaott/api-client/types/customer'
import type { SubscriptionsState } from '../reducers/appSubscriptions'

import { ACTIVE, EXPIRED, PAUSED } from './const'
import { getCustomer } from './customer'

// ToDo: fixme
type Store = any
// type Store = $Exact<{
//   +subscriptions: SubscriptionsState,
// }>

export type SubscriptionView = Subscription & {
  title: string,
  activated: string,
}

export const wasSubscriptionsSuccess = (state: Store): boolean => state.subscriptions.isSuccess
const getByStatus = (status: string): Function => filter(propEq('status', status))

export const isExpiredDate = (expiresDate: string): boolean => lte(new Date(expiresDate), new Date())
// $FlowFixMe
export const isNotExpiredDate = complement(isExpiredDate)

export const isActive = (item: Subscription): boolean =>
  both(propEq('status', ACTIVE), propSatisfies(isNotExpiredDate, 'expires'))(item)

export const isExpired = (item: Subscription): boolean =>
  either(propEq('status', EXPIRED), propSatisfies(isExpiredDate, 'expires'))(item)

export const isPaused = (item: Subscription): boolean =>
  pathEq(['meta', 'chargebeeSubscription', 'status'], toLower(PAUSED))(item)

export const getSubscriptions = (state: Store): Array<Subscription> => state.subscriptions.data

export const getSubscriptionState = (state: Store): SubscriptionsState => state.subscriptions

const getSubscriptionList = (list: Array<Subscription>): Array<SubscriptionView> =>
  map(
    (item: Subscription) => ({
      ...item,
      /**
       * item = {expires: '', created: '', products: [{title: 'titleName', ...}, ...], ...}
       * prop =>
       * [{title: 'titleName', ...}, ...]
       * head =>
       * {title: 'titleName', ...}
       * propOr =>
       * 'titleName'
       */
      // $FlowFixMe
      title: propOr('title', '')(head(prop('products')(item))),
      activated: prop('created')(item),
      expires: prop('expires')(item),
    }),
    list,
  )

export const getActiveSubscriptions = createSelector(
  [getSubscriptions],
  (subscriptions: Array<Subscription>): Array<SubscriptionView> => getSubscriptionList(filter(isActive)(subscriptions)),
)

export const getActiveSubscriptionById = (id: string) =>
  createSelector(
    [getActiveSubscriptions],
    (subscriptions: Array<SubscriptionView>): ?SubscriptionView => find(propEq('_id', id))(subscriptions),
  )

export const getExpiredSubscriptions = createSelector(
  [getSubscriptions],
  (subscriptions: Array<Subscription>): Array<SubscriptionView> =>
    getSubscriptionList(filter(isExpired)(subscriptions)),
)
// Status PLACED' | 'CANCELED' | 'EXPIRED' or is expired
export const getNotActiveSubscriptions = createSelector(
  [getSubscriptions],
  (subscriptions: Array<Subscription>): Array<SubscriptionView> =>
    getSubscriptionList(
      filter(
        pipe(
          isActive,
          not,
        ),
      )(subscriptions),
    ),
)

export const getCanceledSubscriptions = createSelector(
  [getSubscriptions],
  (subscriptions: Array<Subscription>): Array<SubscriptionView> =>
    getSubscriptionList(getByStatus('CANCELED')(subscriptions)),
)

export const getPausedSubscriptions = createSelector(
  [getNotActiveSubscriptions],
  (subscriptions: Array<Subscription>): Array<SubscriptionView> => getSubscriptionList(filter(isPaused)(subscriptions)),
)

export const getPlacedSubscriptions = createSelector(
  [getSubscriptions],
  (subscriptions: Array<Subscription>): Array<SubscriptionView> =>
    getSubscriptionList(getByStatus('PLACED')(subscriptions)),
)

// TODO: need be more researched
// const calculateLatestExpireSubscription = (subscriptions: Array<SubscriptionView> = []): SubscriptionView | {} => {
//   const latestDate = max(...pluck('expires', subscriptions))
//   return (
//     find(sub => {
//       const expires = prop('expires', sub)
//       return equals(new Date(expires), new Date(latestDate))
//     })(subscriptions) || {}
//   )
// }

// export const getLatestExpiredSubscription = createSelector(
//   [getExpiredSubscriptions],
//   (subscriptions: Array<SubscriptionView>): SubscriptionView | {} => {
//     if (isNil(subscriptions) || isEmpty(subscriptions)) return {}
//     return calculateLatestExpireSubscription(subscriptions)
//   },
// )

/**
+ * New customer and no content available
+ * subs === [] && customer.services === []
+ */
export const isNewCustomerAndWithoutServices = createSelector(
  [wasSubscriptionsSuccess, getSubscriptions, getCustomer],
  //  eslint-disable-next-line complexity
  (subscriptionsWasSuccess: boolean, subscriptions: Array<Subscription>, customer: Customer): boolean => {
    // If customer is undefined or subscriptions wasn't fetched successfully => the result is undefined
    if (isEmpty(customer) || not(subscriptionsWasSuccess)) return F()

    const services = prop('services', customer)
    return isEmpty(subscriptions) && isEmpty(services)
  },
)

/**
+ * Demo customer and no content available
+ * subs === [] && customer.demoMode === true
+ */
export const isDemoCustomerAndWithoutSubscriptions = createSelector(
  [wasSubscriptionsSuccess, getSubscriptions, getCustomer],
  //  eslint-disable-next-line complexity
  (subscriptionsWasSuccess: boolean, subscriptions: Array<Subscription>, customer: Customer): boolean => {
    // If customer is undefined or subscriptions wasn't fetched successfully => the result is undefined
    if (isEmpty(customer) || not(subscriptionsWasSuccess)) return F()

    const isDemo = propEq('demoMode', true, customer)
    return isEmpty(subscriptions) && isDemo
  },
)

/**
 * Demo customer and no content available
 * subs === [] && customer.demoMode === false
 */
export const isNotDemoCustomerAndWithoutSubscriptions = createSelector(
  [wasSubscriptionsSuccess, getSubscriptions, getCustomer],
  //  eslint-disable-next-line complexity
  (subscriptionsWasSuccess: boolean, subscriptions: Array<Subscription>, customer: Customer): boolean => {
    // If customer is undefined or subscriptions wasn't fetched successfully => the result is undefined
    if (isEmpty(customer) || not(subscriptionsWasSuccess)) return F()

    const isDemo = propEq('demoMode', true, customer)
    return isEmpty(subscriptions) && not(isDemo)
  },
)

/**
+ * Expired subscription and no content available:
+ * subs !== [] && active subs === [] && customer.services === []
+ */
export const isExpiredSubscriptionAndWithoutServices = createSelector(
  [wasSubscriptionsSuccess, getSubscriptions, getActiveSubscriptions, getCustomer],
  //  eslint-disable-next-line complexity
  (
    subscriptionsWasSuccess: boolean,
    subscriptions: Array<Subscription>,
    activeSubscriptions: Array<SubscriptionView>,
    customer: Customer,
  ): boolean => {
    // If customer is undefined or subscriptions wasn't fetched successfully => the result is undefined
    if (isEmpty(customer) || not(subscriptionsWasSuccess)) return F()

    const services = prop('services', customer)
    return isNotEmpty(subscriptions) && isEmpty(activeSubscriptions) && isEmpty(services)
  },
)

export const isCancelLoading = createSelector(
  [getSubscriptionState],
  (state: SubscriptionsState): boolean => state.cancel.isLoading,
)

export const getCancelErrorMessage = createSelector(
  [getSubscriptionState],
  (state: SubscriptionsState): any => defaultToEmptyString(state.cancel.errors.message),
)
