// @flow

import { useMemo } from 'react'
import { or } from 'ramda'

export const useContentProgress = (currentTime, totalTime) => {
  const preparedCurrentTime = useMemo(() => or(currentTime, 0), [currentTime])
  const preparedTotalTime = useMemo(() => or(totalTime, 1), [totalTime])
  return useMemo(
    () => Math.min(Math.round((preparedCurrentTime / preparedTotalTime) * 100), 100),
    [preparedCurrentTime, preparedTotalTime],
  )
}

export default useContentProgress
