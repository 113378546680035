// @flow weak
import { createSelector } from 'reselect'
import { pathOr, map, prop, propOr, find, propEq } from 'ramda'

import { prepareMovieOptions, prepareMovie } from '@alphaott/common-utils'

import type { Category } from '@alphaott/api-client/types/categories'
import type { Language } from '@alphaott/api-client/types/languages'
import type { Genre } from '@alphaott/api-client/types/genres'
import type { Country } from '@alphaott/api-client/types/countries'
import { getMoviesMaxRating } from '@alphaott/app-main/selectors'
import { defaultToEmptyObject } from '@alphaott/common-utils/utils/help'
import type {
  CategoriesNormalize,
  CountriesNormalize,
  GenresNormalize,
  LanguageNormalize,
} from '@alphaott/common-types/normalized'

import {
  getFavoriteIdsState,
  getCategoriesEntities,
  getGenresEntities,
  getLanguagesEntities,
  getCountriesEntities,
  getRecentProgress,
} from './selectors'
import type { MoviesStore } from '../types'
import type { MovieFiltersState, FilteredMoviesListState, FavoriteMoviesIdsState, MovieProgress } from '../reducers'

type FilteredMovies = {
  [key: string]: FilteredMoviesListState,
}

export const getCategoriesData = (state: MoviesStore): Array<Category> => state.movieCategories.data
export const getLanguagesData = (state: MoviesStore): Array<Language> => state.movieLanguages.data
export const getGenresData = (state: MoviesStore): Array<Genre> => state.movieGenres.data
export const getCountriesData = (state: MoviesStore): Array<Country> => state.movieCountries.data

export const getMovieFiltersData = (state: MoviesStore) => state.movieFilters.data

export const getFilteredMovies = createSelector(
  [getMovieFiltersData],
  (movieFiltersData: MovieFiltersState) => map(prop('data'), movieFiltersData),
)

export const getFilteredMoviesByKey = (storeKey: string) =>
  createSelector(
    [
      getFilteredMovies,
      getGenresEntities,
      getCategoriesEntities,
      getLanguagesEntities,
      getCountriesEntities,
      getFavoriteIdsState,
      getMoviesMaxRating,
      getRecentProgress,
    ],
    (
      movieFilters: FilteredMovies,
      genres: GenresNormalize,
      categories: CategoriesNormalize,
      languages: LanguageNormalize,
      countries: CountriesNormalize,
      favorite: FavoriteMoviesIdsState,
      maxRating: number,
      recent: Array<MovieProgress>,
    ) =>
      map(
        item =>
          prepareMovie(
            item,
            genres,
            categories,
            languages,
            countries,
            favorite.data,
            maxRating,
            defaultToEmptyObject(find(propEq('id', item._id))(recent)),
          ),
        propOr([], storeKey, movieFilters),
      ),
  )

export const getFilteredMoviesHasMore = (storeKey: string) =>
  createSelector(
    [getMovieFiltersData],
    (movieFiltersData: MovieFiltersState) => pathOr(false, [storeKey, 'hasMore'], movieFiltersData),
  )

export const getFilteredMoviesLoading = (storeKey: string) =>
  createSelector(
    [getMovieFiltersData],
    (movieFiltersData: MovieFiltersState) => pathOr(false, [storeKey, 'isLoading'], movieFiltersData),
  )

export const getFilteredMoviesIsSuccess = (storeKey: string) =>
  createSelector(
    [getMovieFiltersData],
    (movieFiltersData: MovieFiltersState) => pathOr(false, [storeKey, 'isSuccess'], movieFiltersData),
  )

export const getFilteredMoviesIsError = (storeKey: string) =>
  createSelector(
    [getMovieFiltersData],
    (movieFiltersData: MovieFiltersState) => pathOr(false, [storeKey, 'isError'], movieFiltersData),
  )

export const prepareCategories = (selected: Array<string>) =>
  createSelector(
    [getCategoriesData],
    (categories: Array<Category>): Array<any> => prepareMovieOptions({ items: categories, selected }),
  )

export const prepareLanguages = (selected: Array<string>) =>
  createSelector(
    [getLanguagesData],
    (languages: Array<Language>): Array<any> => prepareMovieOptions({ items: languages, selected }),
  )

export const prepareGenres = (selected: Array<string>) =>
  createSelector(
    [getGenresData],
    (genres: Array<Genre>): Array<any> => prepareMovieOptions({ items: genres, selected }),
  )

export const prepareCountries = (selected: Array<string>) =>
  createSelector(
    [getCountriesData],
    (countries: Array<Country>): Array<any> => prepareMovieOptions({ items: countries, selected }),
  )
