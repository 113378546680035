// @flow
import { themeStyle } from '../colorPalettes'

import { lightTheme } from './lightTheme'

import type { ThemeType } from '..'

const keturaThemeName = 'ketura'

export const themeKetura: ThemeType = {
  name: keturaThemeName,
  getThemeVariation: (themeVariation: string) => {
    switch (themeVariation) {
      case themeStyle.light:
        return lightTheme
      default:
        return lightTheme
    }
  },
}
