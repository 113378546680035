// @flow

import { useEffect, useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { path, prop, keys } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'

import { isNotEqual } from '@alphaott/common-utils'

import { AudioIcon, CloseIcon, SubtitlesIcon } from './TracksMenu.styles'
import { usePlayerContext } from '../../store'

export const useTabs = () => {
  const { t } = useTranslation()

  const [playerState] = usePlayerContext()

  const isShowTab = useCallback(
    (values, num = 0) => isNotNilOrEmpty(values) && isNotEqual(prop('length', values), num),
    [],
  )

  const isShowAudioTab = useMemo(
    () => isShowTab(playerState.audioTracks),
    [playerState.audioTracks, isShowTab],
  )
  const isShowSubtitlesTab = useMemo(
    () => isShowTab(playerState.subtitleTracks),
    [playerState.subtitleTracks, isShowTab],
  )

  const tabs = useMemo(
    () => ({
      close: {
        label: t('mwstv_close'),
        icon: CloseIcon,
      },
      ...(isShowAudioTab && {
        audio: {
          label: t('mwstv_subtitles_audio'),
          icon: AudioIcon,
        },
      }),
      ...(isShowSubtitlesTab && {
        subtitles: {
          label: t('mwstv_subtitles_subtitles'),
          icon: SubtitlesIcon,
        },
      }),
    }),
    [isShowAudioTab, isShowSubtitlesTab, t],
  )

  const defaultTab = useMemo(() => {
    const tabKeys = keys(tabs)

    return prop(isShowTab(tabKeys, 1) ? 1 : 0, tabKeys)
  }, [tabs, isShowTab])

  const [activeTab, setActiveTab] = useState(defaultTab)

  const activeTabTitle = useMemo(() => path([activeTab, 'label'], tabs), [activeTab, tabs])

  useEffect(() => {
    if (defaultTab) setActiveTab(defaultTab)
  }, [defaultTab])

  return {
    defaultTab,
    activeTab,
    activeTabTitle,
    tabs,
    onSelectTab: setActiveTab,
  }
}

export default useTabs
