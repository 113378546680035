// @flow
import styled from 'styled-components'

import {
  getTextColorPrimary,
  getBackgroundSecondaryColor,
  getColorPrimary,
  getBackgroundColor,
  getGrey600,
} from '@alphaott/smart-tv-themes'

import arrowUpIcon from '../../../assets/arrowUpIcon.svg'

const getFocusColor = (props) => (props.focused ? getColorPrimary(props) : getGrey600(props))

export const CodeInputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 860rem;
`

export const SingleCodeInputWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

export const SingleCodeInput = styled.input.attrs({
  type: 'text',
  autoComplete: 'off',
  maxLength: 1,
  readOnly: true,
})`
  font-family: 'Lato';
  font-weight: 700;
  font-size: 152rem;
  line-height: 152rem;
  color: ${getTextColorPrimary};
  background-color: ${getBackgroundSecondaryColor};
  width: 170rem;
  height: 234rem;
  text-align: center;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  user-select: none;
  border: 6rem solid ${getFocusColor};

  &:focus {
    border-color: ${getColorPrimary};
  }
`

export const ArrowWrapper = styled.div`
  min-width: 64rem;
  min-height: 64rem;
  background-image: url(${arrowUpIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${getBackgroundColor};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  transform: rotateX(${({ topDirection }) => (topDirection ? 0 : 180)}deg);
`
