// @flow
import { pipe, split, map, head, prop, equals, either } from 'ramda'
import scrollToElement from 'scroll-to-element'

import type { Movie } from '@alphaott/api-client/types/movies'

import { defaultToEmptyPath, defaultToEmptyArray } from '@alphaott/common-utils/utils/help'

export const TRAILER_HASH_CLASS = 'play-trailer'
export const MOVIE_HASH_CLASS = 'play-movie'
export const MOVIE_INFO_HASH_CLASS = 'movie-info'

export const TRAILER_HASH = `#${TRAILER_HASH_CLASS}`
export const MOVIE_HASH = `#${MOVIE_HASH_CLASS}`
export const SCROLL_ANIMATION_DURATION = 500
export const INFO_HASH = `#${MOVIE_INFO_HASH_CLASS}`

export const isTrailerHash = equals(TRAILER_HASH)
export const isMovieHash = equals(MOVIE_HASH)
export const hasHash = either(isTrailerHash, isMovieHash)

export const getNameInitials = pipe(
  split(' '),
  map(head),
)

export const getReleaseDate = (year: number, countries: Array<string>): string => {
  const country = head(countries)
  return `${year} ${country ? `(${country})` : ''}`
}

export const getFirstPath = pipe(
  defaultToEmptyArray,
  head,
  defaultToEmptyPath,
  prop('path'),
)

export const getPosterImagePath = (movie: Movie): string => {
  const poster = defaultToEmptyPath(head(prop('posters', movie)))
  return prop('path', poster)
}

export const jumpToHash = (hash: string) => {
  hash && scrollToElement(hash, { offset: -100, duration: SCROLL_ANIMATION_DURATION })
}
