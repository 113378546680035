// @flow
import { reject } from 'ramda'
import { isNilOrEmpty } from 'ramda-adjunct'

import { getCurrentDevice } from '@alphaott/app-devices/selectors'
import { getConfig } from '@alphaott/api-client'
import { stringify } from '@alphaott/common-utils/utils/urlSearchParams'

import { getBrandRemoteConfigUrl } from '../../selectors'

export const FETCH_CONFIG_TYPE = 'config/FETCH_CONFIG'

export const fetchConfig = (brandConfigPath: string) => (dispatch: Function, getState: Function) => {
  const configUrl = brandConfigPath || getBrandRemoteConfigUrl(getState())
  const deviceData = getCurrentDevice(getState())

  const preparedDeviceData = reject(isNilOrEmpty, deviceData)
  const queryString = stringify(preparedDeviceData)

  return dispatch({
    type: FETCH_CONFIG_TYPE,
    payload: getConfig(`${configUrl}${queryString}`),
  })
}
