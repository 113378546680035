// @flow

import React, {
  memo,
  useMemo,
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { F } from 'ramda'

import { OptionsMenu } from '../OptionsMenu'

export type OptionsMenuContextProps = {
  isShowMenu: boolean,
  onShowMenu: Function,
  onCloseMenu: Function,
}

const defaultValues = {
  isShowMenu: false,
  onShowMenu: F,
  onCloseMenu: F,
}

export const OptionsMenuContext = createContext<OptionsMenuContextProps>(defaultValues)

export const useOptionsMenuContext = (): OptionsMenuContextProps =>
  useContext<OptionsMenuContextProps>(OptionsMenuContext)

// eslint-disable-next-line complexity
const OptionsMenuContainerPure = ({ children }) => {
  const [isShowMenu, setShowMenu] = useState(false)
  const [options, setOptions] = useState([])

  const handleShowMenu = useCallback((optionsMenu) => {
    setShowMenu(true)
    setOptions(optionsMenu)
  }, [])

  const handleHideMenu = useCallback(() => {
    setShowMenu(false)
    setOptions([])
  }, [])

  const value = useMemo(
    () => ({
      isShowMenu,
      onShowMenu: handleShowMenu,
      onHideMenu: handleHideMenu,
    }),
    [isShowMenu, handleShowMenu, handleHideMenu],
  )

  useEffect(
    () => () => {
      handleHideMenu()
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <OptionsMenuContext.Provider value={value}>
      {children}

      {isShowMenu && <OptionsMenu options={options} onHideMenu={handleHideMenu} />}
    </OptionsMenuContext.Provider>
  )
}

export const OptionsMenuContainer = memo(OptionsMenuContainerPure)

export default OptionsMenuContainer
