// @flow
import { LARGE_VIEWPORT, MEDIUM_VIEWPORT, SMALL_VIEWPORT } from '@alphaott/app-config'
import { rgba } from 'polished'

import { colorPalette } from '../colorPalettes'
import type { ColorType } from '../colorPalettes'

export const tugotvColors: ColorType = {
  ...colorPalette,
  primary: '#EA2052',
  secondary: '#EA2052',
  grayText: '#B0B0B0',
  grayLabel: '#717171',
  grayBorder: 'rgba(255, 255, 255, 0.38)',
}

export const darkTheme = {
  colors: tugotvColors,
  background: {
    primary: '#111',
    secondary: '#262626',
    highlight: tugotvColors.dark,
    select: '#2e2e2e',
    footerTop: '#333',
  },
  text: {
    primary: tugotvColors.primaryGray,
    secondary: tugotvColors.secondaryGray,
    disabled: tugotvColors.grayText,
    link: tugotvColors.secondaryGray,
    hint: tugotvColors.white,
    highlight: tugotvColors.secondary,
    gray: tugotvColors.primaryGray,
    seeAll: tugotvColors.secondaryGray,
    primaryContrast: tugotvColors.white,
  },
  border: {
    primary: tugotvColors.primaryGray,
    gray: tugotvColors.secondaryGray,
  },
  filter: {
    primary: tugotvColors.primaryGray,
    secondary: tugotvColors.secondaryGray,
    disabled: tugotvColors.secondaryGray,
    highlight: '#111111',
  },
  tabs: {
    bg: '#181818',
    containerBg: tugotvColors.dark,
    color: tugotvColors.white,
    border: tugotvColors.primary,
    scrollbarThumb: '#bdbdbd',
    programTitle: tugotvColors.white,
  },
  programGuide: {
    programBox: {
      background: {
        main: '#2e2e2e',
        hover: tugotvColors.dark,
        selected: tugotvColors.dark,
        progress: rgba(tugotvColors.primary, 0.3),
        past: rgba(tugotvColors.primary, 0.3),
        pastSelected: tugotvColors.dark,
        pastHover: tugotvColors.dark,
        empty: '#555555',
        emptyProgress: rgba(tugotvColors.primary, 0.1),
      },
      text: {
        empty: tugotvColors.secondaryGray,
      },
    },
    programGuidePictureOverlay: {
      horizontal:
        // eslint-disable-next-line max-len
        `linear-gradient(90deg, #111 0%,${rgba('#111', 0.97)} 13.03%,${rgba('#111', 0.69)} 43.24%,${rgba(
          '#111',
          0,
        )} 100%);`,
      vertical: `linear-gradient(0, #111 0%,${rgba('#111', 0.73)} 34.97%,${rgba('#111', 0)} 100%);`,
    },
  },
  card: {
    title: tugotvColors.white,
    subTitle: tugotvColors.secondaryGray,
    bg: tugotvColors.grayText,
    number: '#DFDFDF',
    boxShadow: '#2e2e2e',
    loaderBackgroundImage: 'linear-gradient(to right, #222 0%, #111 20%, #222 40%, #222 100%)',
  },
  dimension: {
    signLogoSize: 140,
  },
  size: 'default',
  breakpoints: [`${SMALL_VIEWPORT}px`, `${MEDIUM_VIEWPORT}px`, `${LARGE_VIEWPORT}px`],
  signIn: {
    clipPath: false,
  },
}
