// @flow

import { isLicenseRequest } from '../utils'

export const getDashLicense = (licenseHeaders: Object = {}) => (type: string, request: Object) => {
  if (isLicenseRequest(type)) {
    const { customdata, ...headers } = licenseHeaders

    request.headers = {
      ...request.headers,
      ...headers,
      ...(customdata && { customdata }),
    }
  }
}

export default getDashLicense
