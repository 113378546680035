// @flow

export const defaultConfig: shaka.extern.PlayerConfiguration = {
  manifest: {
    dash: {
      ignoreMinBufferTime: true,
    },
  },
  streaming: {
    autoLowLatencyMode: true,
    rebufferingGoal: 0.1,
    bufferingGoal: 30,
    // segmentPrefetchLimit: 2,
  },
}
