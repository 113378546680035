// @flow
import { normalize } from 'normalizr'
import typeToReducer from 'type-to-reducer'

import type { Category } from '@alphaott/api-client/types/categories'
import type { CategoriesNormalize } from '@alphaott/common-types/normalized'

import { isValidTTL, calculateExpires } from '@alphaott/app-main/selectors'
import { defaultToEmptyObject } from '@alphaott/common-utils/utils/help'
import categoriesListSchema from '@alphaott/common-utils/utils/schemas/categories'
import { FETCH_TVSHOW_CATEGORIES } from '../../actions/tvshowCategories'

export type TVShowCategoriesState = {|
  isLoading: boolean,
  isSuccess: boolean,
  isError: boolean,
  errors: any,
  data: Array<Category>,
  entities: CategoriesNormalize,
  +expires: ?number,
|}

const initialState: TVShowCategoriesState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
  entities: {},
  expires: null,
}

const tvshowCategories = typeToReducer(
  {
    [FETCH_TVSHOW_CATEGORIES]: {
      LOADING: (state): TVShowCategoriesState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): TVShowCategoriesState => {
        const {
          payload,
          meta: { ttl },
        } = action
        const { entities } = normalize(payload.data, categoriesListSchema)
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: action.payload.data,
          entities: defaultToEmptyObject(entities.categories),
          ...(isValidTTL(ttl) && { expires: calculateExpires(ttl) }),
        }
      },

      ERROR: (state, action): TVShowCategoriesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        expires: null,
      }),
    },
  },
  initialState,
)

export { tvshowCategories }
