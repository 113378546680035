// @flow
import { schema } from 'normalizr'
import { map, prop, isEmpty, reject } from 'ramda'

import type { GenresNormalize } from '@alphaott/common-types/normalized'

import { defaultToEmptyString } from '../../help'

const idAttribute = '_id'

export const genresSchema = new schema.Entity('genres', {}, { idAttribute })
export const genresListSchema = new schema.Array(genresSchema)

export const prepareGenresById = (arrIds: Array<string>, arrValues: GenresNormalize): Array<string> =>
  reject(isEmpty, map(id => defaultToEmptyString(prop('title', arrValues[id])))(arrIds))

export default genresListSchema
