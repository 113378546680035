// @flow
import { LARGE_VIEWPORT, MEDIUM_VIEWPORT, SMALL_VIEWPORT } from '@alphaott/app-config'
import { rgba } from 'polished'

import { colorPalette } from '../colorPalettes'
import type { ColorType } from '../colorPalettes'

export const onlinearabiaColors: ColorType = {
  ...colorPalette,
  primary: '#006C35',
  secondary: '#009A4C',
  greenSuccess: '#006C35',
  grayText: '#B0B0B0',
  grayLabel: '#717171',
  grayBorder: 'rgba(255, 255, 255, 0.38)',
}

export const darkTheme = {
  colors: onlinearabiaColors,
  background: {
    primary: '#181818',
    secondary: '#262626',
    highlight: onlinearabiaColors.dark,
    select: '#2e2e2e',
    footerTop: '#333',
  },
  text: {
    primary: onlinearabiaColors.primaryGray,
    secondary: onlinearabiaColors.secondaryGray,
    disabled: onlinearabiaColors.grayText,
    link: onlinearabiaColors.secondaryGray,
    hint: onlinearabiaColors.white,
    highlight: onlinearabiaColors.secondary,
    gray: onlinearabiaColors.primaryGray,
    seeAll: onlinearabiaColors.secondaryGray,
    primaryContrast: '#ffffff',
  },
  border: {
    primary: onlinearabiaColors.primaryGray,
    gray: onlinearabiaColors.secondaryGray,
  },
  filter: {
    primary: onlinearabiaColors.primaryGray,
    secondary: onlinearabiaColors.secondaryGray,
    disabled: onlinearabiaColors.secondaryGray,
    highlight: '#111111',
  },
  tabs: {
    bg: '#181818',
    containerBg: onlinearabiaColors.dark,
    color: onlinearabiaColors.white,
    border: onlinearabiaColors.primary,
    scrollbarThumb: '#bdbdbd',
    programTitle: onlinearabiaColors.white,
  },
  programGuide: {
    programBox: {
      background: {
        main: '#2e2e2e',
        hover: onlinearabiaColors.dark,
        selected: onlinearabiaColors.dark,
        progress: rgba(onlinearabiaColors.primary, 0.3),
        past: rgba(onlinearabiaColors.primary, 0.3),
        pastSelected: onlinearabiaColors.dark,
        pastHover: onlinearabiaColors.dark,
        empty: '#555555',
        emptyProgress: rgba(onlinearabiaColors.primary, 0.1),
      },
      text: {
        empty: onlinearabiaColors.secondaryGray,
      },
    },
    programGuidePictureOverlay: {
      horizontal:
        // eslint-disable-next-line max-len
        `linear-gradient(90deg, #181818 0%,${rgba('#181818', 0.97)} 13.03%,${rgba('#181818', 0.69)} 43.24%,${rgba(
          '#181818',
          0,
        )} 100%);`,
      vertical: `linear-gradient(0, #181818 0%,${rgba('#181818', 0.73)} 34.97%,${rgba('#181818', 0)} 100%);`,
    },
  },
  card: {
    title: onlinearabiaColors.white,
    subTitle: onlinearabiaColors.secondaryGray,
    bg: onlinearabiaColors.grayText,
    number: '#DFDFDF',
    boxShadow: '#2e2e2e',
    loaderBackgroundImage: 'linear-gradient(to right, #222 0%, #111 20%, #222 40%, #222 100%)',
  },
  dimension: {},
  size: 'default',
  breakpoints: [`${SMALL_VIEWPORT}px`, `${MEDIUM_VIEWPORT}px`, `${LARGE_VIEWPORT}px`],
  signIn: {
    clipPath: false,
  },
}
