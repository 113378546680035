// @flow
import { getPromos } from '@alphaott/api-client'
import { getPublicDomain, getContentDomain, getBrand } from '@alphaott/app-main/selectors'
import { getDeviceType, getDevicePlatform } from '@alphaott/app-devices/selectors'
import { prop } from 'ramda'

export const FETCH_PROMOS_TYPE = 'promo/FETCH_PROMOS'

export const fetchPromos = (serviceSpec: string) => (dispatch: Function, getState: Function) => {
  const state = getState()
  const brand = getBrand(state)
  const themeName = prop('theme', brand)
  const themeVariation = prop('themeVariation', brand)

  return dispatch({
    type: FETCH_PROMOS_TYPE,
    payload: getPromos(getPublicDomain(state), serviceSpec, getDeviceType(state), getDevicePlatform(state)),
    meta: {
      contentApi: getContentDomain(getState()),
      themeName,
      themeVariation,
    },
  })
}
