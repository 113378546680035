// @flow

import styled from 'styled-components'

import { getGrey800, getColorSecondaryContrastText } from '@alphaott/smart-tv-themes'

export const AVATAR_CARD_WIDTH = 200

const getBorderColor = ({ focused }) => (focused ? getColorSecondaryContrastText : getGrey800)

export const Image = styled.div`
  width: ${AVATAR_CARD_WIDTH}rem;
  height: ${AVATAR_CARD_WIDTH}rem;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: 103%;
  background-repeat: no-repeat;
  border: 8rem solid ${getBorderColor};
  border-radius: 100%;
  background-color: #737373;

  ${({ imageStyles }) => imageStyles}
  
  &:hover {
  border-color: ${(props) => {
    if (props.focused) return getColorSecondaryContrastText(props)
    return props.theme.palette.grey['400']
  }}
`
