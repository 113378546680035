// @flow
import { getRegions } from '@alphaott/api-client/regions'
import { getPublicDomain } from '@alphaott/app-main/selectors'

export const FETCH_REGIONS_TYPE = 'regions/FETCH'

export const fetchAppRegions = (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_REGIONS_TYPE,
    payload: getRegions(getPublicDomain(getState())),
  })
