// @flow
import { keys, pipe, reduce, replace, isEmpty, or } from 'ramda'
import { isNilOrEmpty } from './help'

export const insertVariablesInJSON = (value: string, variables: Object): string => {
  if (or(isNilOrEmpty(value), isEmpty(variables))) return value

  return pipe(
    keys,
    reduce((result, varKey) => {
      const varValue = variables[varKey]
      const reg = new RegExp(`\\$\\{${varKey}}`, 'g')

      return replace(reg, varValue, result)
    }, value),
  )(variables)
}
