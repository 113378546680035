// @flow

import React, { useCallback, useEffect, useRef, useMemo, memo } from 'react'
import { head, keys, prop } from 'ramda'
import { useTranslation } from 'react-i18next'
import { VariableSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'
import { scale } from '@alphaott/smart-tv-utils'

import { SelectIconRowItem } from './RowItem'
import { BackgroundContainer, Prompt, Title, listStyle } from './SelectIconView.style'
import { AVATAR_CARD_WIDTH } from '../Avatar/Avatar.style'

import type { IconsPack } from './types'

export const PERCENT_OF_HEIGHT = 0.82 // 82%
export const AVATAR_CARD_PADDING = 152
export const ROW_HEIGHT = AVATAR_CARD_WIDTH + AVATAR_CARD_PADDING

type SelectIconProps = {
  icons: Array<IconsPack>,
  onSelectAvatar: Function,
}

// eslint-disable-next-line react/display-name
const SelectIconViewPure = memo(({ icons, onSelectAvatar }: SelectIconProps) => {
  const { ref, setFocus } = useFocusable()
  const { t } = useTranslation()

  const listRef = useRef()

  const getItemSize = useCallback(() => scale(window.innerWidth, ROW_HEIGHT), [])
  const listHeight = useMemo(() => window.innerHeight * PERCENT_OF_HEIGHT, [])

  const onBecameFocusedRow = useCallback(
    (idx) => {
      if (listRef.current) listRef.current.scrollToItem(idx, 'center')
    },
    [listRef],
  )

  useEffect(() => {
    const firstAvatarKey = head(keys(prop('icons', head(icons))))
    setFocus && setFocus(firstAvatarKey)
  }, [icons, setFocus])

  return (
    <BackgroundContainer ref={ref}>
      <Title>{t('edit_icon')}</Title>
      <Prompt>{t('mwstv_select_the_icon_for_your_profile')}</Prompt>
      <AutoSizer>
        {({ width }) => (
          <List
            ref={listRef}
            width={width}
            height={listHeight}
            itemCount={icons.length}
            itemSize={getItemSize}
            itemData={{ icons, onBecameFocusedRow, onSelectAvatar }}
            style={listStyle}
          >
            {SelectIconRowItem}
          </List>
        )}
      </AutoSizer>
    </BackgroundContainer>
  )
})

export const SelectIconView = memo<SelectIconProps>(SelectIconViewPure)

export default SelectIconView
