// @flow

import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { isNotNilOrEmpty } from 'ramda-adjunct'

import { getPlayerProgressState } from '@alphaott/smart-tv-video-player/selectors'
import { useCurrentTime } from '@alphaott/smart-tv-common'

import {
  Container,
  TitleContainer,
  Title,
  CurrentTime,
  DescriptionContainer,
  Description,
  ProgressBar,
  Controls,
} from './elements'

import type { ControlsProps } from './Controls'

export type Program = {
  title: string,
  description?: string,
  time: string,
}

type DetailInfoProps = {
  className?: string,
  openChannelsListButtonTitle?: string,
} & Program &
  ControlsProps

const TitleComponentPure = ({ title, currentTime }) => (
  <TitleContainer>
    {isNotNilOrEmpty(title) && <Title>{title}</Title>}
    <CurrentTime>{currentTime}</CurrentTime>
  </TitleContainer>
)
const TitleComponent = memo(TitleComponentPure)

const ProgressBarComponentPure = ({ time }) => {
  const progress = useSelector(getPlayerProgressState)
  return <ProgressBar time={time} {...progress} />
}
const ProgressBarComponent = memo(ProgressBarComponentPure)

const DescriptionConmponentPure = ({ description }) => (
  <DescriptionContainer clamp={3}>
    {isNotNilOrEmpty(description) && <Description>{description}</Description>}
  </DescriptionContainer>
)
const DescriptionConmponent = memo(DescriptionConmponentPure)

const ControlsComponentPure = ({
  isFavorite,
  isAvailableProgramGuide,
  isAvailableMyList,
  isAvailableAudioSubs,
  isAvailableChannelsList,
  isAvailableMultiScreen,
  isAvailableRecord,
  openChannelsListButtonTitle,
  onOpenProgramGuide,
  onFavorite,
  onCaptions,
  onOpenChannelsList,
  onMultiScreen,
  onRecord,
}) => (
  <Controls
    isFavorite={isFavorite}
    isAvailableProgramGuide={isAvailableProgramGuide}
    isAvailableMyList={isAvailableMyList}
    isAvailableAudioSubs={isAvailableAudioSubs}
    isAvailableChannelsList={isAvailableChannelsList}
    isAvailableMultiScreen={isAvailableMultiScreen}
    isAvailableRecord={isAvailableRecord}
    openChannelsListButtonTitle={openChannelsListButtonTitle}
    onOpenProgramGuide={onOpenProgramGuide}
    onFavorite={onFavorite}
    onCaptions={onCaptions}
    onOpenChannelsList={onOpenChannelsList}
    onMultiScreen={onMultiScreen}
    onRecord={onRecord}
  />
)
const ControlsComponent = memo(ControlsComponentPure)

// eslint-disable-next-line complexity
export const DetailInfoPure = ({
  className,
  title,
  description,
  time,
  isFavorite,
  isAvailableProgramGuide,
  isAvailableMyList,
  isAvailableAudioSubs,
  isAvailableChannelsList,
  isAvailableMultiScreen,
  isAvailableRecord,
  openChannelsListButtonTitle,
  onOpenProgramGuide,
  onFavorite,
  onCaptions,
  onOpenChannelsList,
  onMultiScreen,
  onRecord,
}: DetailInfoProps) => {
  const currentTime = useCurrentTime()

  return (
    <Container className={className}>
      <TitleComponent title={title} currentTime={currentTime} />
      <ProgressBarComponent time={time} />
      {description && <DescriptionConmponent description={description} />}
      <ControlsComponent
        isFavorite={isFavorite}
        isAvailableProgramGuide={isAvailableProgramGuide}
        isAvailableMyList={isAvailableMyList}
        isAvailableAudioSubs={isAvailableAudioSubs}
        isAvailableChannelsList={isAvailableChannelsList}
        isAvailableMultiScreen={isAvailableMultiScreen}
        isAvailableRecord={isAvailableRecord}
        openChannelsListButtonTitle={openChannelsListButtonTitle}
        onOpenProgramGuide={onOpenProgramGuide}
        onFavorite={onFavorite}
        onCaptions={onCaptions}
        onOpenChannelsList={onOpenChannelsList}
        onMultiScreen={onMultiScreen}
        onRecord={onRecord}
      />
    </Container>
  )
}

export const DetailInfo = memo(DetailInfoPure)

export default DetailInfo
