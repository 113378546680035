// flow

import styled, { keyframes } from 'styled-components'

export const Spinner = styled.div`
  position: relative;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
`

export const pulsate = keyframes`
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
`

export const BounceOne = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${pulsate} 2s infinite ease-in-out;
  animation-duration: ${(props) => props.duration || '2.0s'};
`

export const BounceTwo = styled(BounceOne)`
  animation-delay: -1s;
`
