// @flow

import { push } from 'redux-first-history'

import { resendVerificationEmail } from '@alphaott/api-client/authorization'
import { getPrivateDomain } from '@alphaott/app-main/selectors'

export const RESEND_VERIFICATION_EMAIL_TYPE = 'resendVerificationEmail/RESEND'
export const RESEND_VERIFICATION_EMAIL_STATE_TYPE = 'resendVerificationEmail/RESET_STATE'

export const resetResendVerificationEmailStateAction = (dispatch: Function) => {
  dispatch({
    // https://github.com/pitzcarraldo/reduxible/issues/8
    type: RESEND_VERIFICATION_EMAIL_STATE_TYPE,
  })
}

export const resendVerificationEmailAction = (email: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    // https://github.com/pitzcarraldo/reduxible/issues/8
    type: RESEND_VERIFICATION_EMAIL_TYPE,
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    payload: async () => {
      try {
        const result = await resendVerificationEmail(getPrivateDomain(getState()), { email })
        dispatch(push('/'))
        return result
      } catch (err) {
        return Promise.reject(err)
      }
    },
  })
