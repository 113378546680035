// @flow
import type { MediaStreamType } from '@alphaott/api-client/types/mediaStream'

import { DRM_TYPES } from './drm/const'

export type AcceptMediaStreamList = Array<MediaStreamType>

export const MEDIA_STREAM_TYPE = {
  MPEG_DASH: 'MPEG_DASH',
  HLS: 'HLS',
  MSS: 'MSS',
  HDS: 'HDS',
  MPEG_TS: 'MPEG_TS',
  MP4: 'MP4',
  MP3: 'MP3',
  AAC: 'AAC',
  OGG: 'OGG',
  FLAC: 'FLAC',
}

// Returns static mapping for DRM -> {media_stream}/{DRM}
export const MEDIA_STREAM_TYPE_FOR_DRM = {
  [DRM_TYPES.WIDEVINE]: `${MEDIA_STREAM_TYPE.MPEG_DASH}/${DRM_TYPES.WIDEVINE}`,
  [DRM_TYPES.PLAYREADY]: `${MEDIA_STREAM_TYPE.MPEG_DASH}/${DRM_TYPES.PLAYREADY}`,
  [DRM_TYPES.FAIRPLAY]: `${MEDIA_STREAM_TYPE.HLS}/${DRM_TYPES.FAIRPLAY}`,
  [DRM_TYPES.CLEARKEY]: `${MEDIA_STREAM_TYPE.HLS}/${DRM_TYPES.CLEARKEY}`,
}
