// @flow
import { makeGetRequest } from '../utils'

import type { Headers, ResponseAPI } from '../types/api'
import type { TVShowDetail, TVShow } from '../types/tvshows'
import type { TrailerMediaStream } from '../types/movies'

export type TvShowListQuery = {|
  limit?: number,
  skip?: number,
  title?: string,
  plot?: string,
  year?: string,
  languages?: string,
  countries?: string,
  genres?: string,
  categories?: string,
  studios?: string,
  sort?: 'rating' | '-rating',
|}

export const getTvShowList = (
  domain: string,
  serviceId: string,
  query?: TvShowListQuery,
): Promise<ResponseAPI<TVShow[]>> =>
  makeGetRequest(`${domain}/client/api/servicespec/${serviceId}/tv-shows`, { data: query })

export const getTvShow = (domain: string, showId: string): Promise<ResponseAPI<TVShowDetail>> =>
  makeGetRequest(`${domain}/client/api/vod/tv-show/${showId}`)

export const getTvShowsByIds = (domain: string, idList: string): Promise<ResponseAPI<Array<TVShowDetail>>> =>
  makeGetRequest(`${domain}/client/api/v2/vod/tv-show/${idList}`)

export const getTvShowRelated = (
  domain: string,
  serviceId: string,
  tvShowId: string,
): Promise<ResponseAPI<TVShowDetail>> =>
  makeGetRequest(`${domain}/client/api/servicespec/${serviceId}/related/${tvShowId}/tv-shows`)

export const getTvShowTrailer = (
  domain: string,
  tvShowId: string,
  trailerIndex: number,
  { token }: Headers,
): Promise<ResponseAPI<TrailerMediaStream>> =>
  makeGetRequest(`${domain}/client/api/v3/vod/tv-show/${tvShowId}/trailer/${trailerIndex}`, {
    headers: { token },
  })
