// @flow
import typeToReducer from 'type-to-reducer'
import type { Program } from '@alphaott/api-client/types/programs'

import {
  HIDE_PROGRAM_MODAL_TYPE,
  SHOW_PROGRAM_MODAL_TYPE,
  SWITCH_PROGRAM_SEGMENT_TYPE,
  UPDATE_PROGRAM_CURRENT_TYPE,
  FETCH_PROGRAMS_BY_RANGE_TYPE,
} from '../actions'

import { getEpgByNow, getFilteredPrograms } from '../../../utils'
import type { CurrentEpgIndexes } from '../selectors'

export type ProgramConfig = {
  modalIndex: number,
  isShownModal: boolean,
  isOpenSegment: boolean,
}

type Current = { [string]: CurrentEpgIndexes }

export type ProgramState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: { [string]: Program },
  +config: ProgramConfig,
  +current: Current,
}

const initialState: ProgramState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: {},
  config: {
    modalIndex: 0,
    isShownModal: false,
    isOpenSegment: false,
  },
  current: {},
}

const channelPrograms = typeToReducer(
  {
    [FETCH_PROGRAMS_BY_RANGE_TYPE]: {
      LOADING: (state): ProgramState => ({ ...state, isLoading: true, isSuccess: false, isError: false }),

      SUCCESS: (state, action): ProgramState => {
        const { payload, meta } = action
        const filteredPrograms = getFilteredPrograms(payload.data)
        const epg = getEpgByNow(filteredPrograms)
        return {
          ...state,
          isSuccess: true,
          isLoading: false,
          data: {
            ...state.data,
            [meta.id]: filteredPrograms,
          },
          current: {
            ...state.current,
            [meta.id]: epg,
          },
        }
      },

      ERROR: (state, action): ProgramState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
    [SHOW_PROGRAM_MODAL_TYPE]: (state, action): ProgramState => ({
      ...state,
      config: {
        ...state.config,
        modalIndex: action.index,
        isShownModal: true,
      },
    }),
    [HIDE_PROGRAM_MODAL_TYPE]: (state): ProgramState => ({
      ...state,
      config: {
        ...state.config,
        isShownModal: false,
      },
    }),
    [SWITCH_PROGRAM_SEGMENT_TYPE]: (state): ProgramState => ({
      ...state,
      config: {
        ...state.config,
        isOpenSegment: !state.config.isOpenSegment,
      },
    }),
    [UPDATE_PROGRAM_CURRENT_TYPE]: (state, action): ProgramState => {
      const { id } = action
      const programs = state.data[id]
      const epg = getEpgByNow(programs)
      return {
        ...state,
        current: {
          ...state.current,
          [id]: epg,
        },
      }
    },
  },
  initialState,
)

export { channelPrograms }
