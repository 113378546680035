// @flow

// https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html

import { propEq, path, includes } from 'ramda'

type Response = {
  isCritical?: boolean,
  errorMessage: string,
  error: any,
}

const streamErrorMsg = "Streamer protection system doesn't allow you to watch this content."

export const isBadHttpStatus = propEq('code', 1001)

export const prepareBadHttpStatusError = (error: any = {}): Response => {
  const text = path(['data', 2], error)

  const isStreamError = includes(streamErrorMsg, text)
  if (isStreamError) return { isCritical: true, errorMessage: streamErrorMsg, error }

  return { errorMessage: text, error }
}
