// @flow
import styled from 'styled-components'

import { getColorSecondaryContrastText } from '@alphaott/smart-tv-themes'
import { Button as ButtonBase } from '@alphaott/smart-tv-components'

import { CheckBoldIcon } from '../assets'

export const Button = styled(ButtonBase)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 577rem;
  min-height: 72rem;
  margin-bottom: 15rem;
  text-align: left;
  text-transform: none;
  font-size: 40rem;
  font-weight: 400;
  padding: 0 20rem;
`

export const CheckIcon = styled(CheckBoldIcon)`
  width: 41rem;
  height: 30rem;
  fill: ${getColorSecondaryContrastText};
`

export const Container = styled.div`
  align-self: flex-end;
  margin-top: 15rem;
`
