// @flow

import { useContext } from 'react'

import { PlayerContext } from './provider'

export const usePlayerContext = () => {
  const contextValue = useContext(PlayerContext)

  return contextValue
}

export default usePlayerContext
