// @flow
/* eslint-disable prefer-promise-reject-errors */

import { propEq, cond, T, either, propSatisfies, is, ifElse, and, always, gte, lte } from 'ramda'

import type { ResponseAPI } from '../types/api'

// is(Number) because status is $Values<Response>, not  Response[status] - number
// https://github.com/flow-typed/flow-typed/pull/3239/commits/9cee518580365b36160339b491bbde103428d70a

const inInterval = (start: number, stop: number) =>
  ifElse(is(Number), (status: number) => and(gte(status, start), lte(status, stop)), always(false))

export const isOK = propEq('ok', true)
export const isNotOK = propEq('ok', false)

export const is20x = propSatisfies<{ status: number }>(inInterval(200, 299), 'status')
export const is40x = propSatisfies<{ status: number }>(inInterval(400, 499), 'status')
export const is400 = propEq('status', 400)
export const is401 = propEq('status', 401)
export const is403 = propEq('status', 403)
export const is404 = propEq('status', 404)
export const is409 = propEq('status', 409)
export const is429 = propEq('status', 429)
export const is451 = propEq('status', 451)
export const is50x = propSatisfies<{ status: number }>(inInterval(500, 599), 'status')
export const isApiError = either(is40x, is50x)
export const isNetworkError = propEq('status', -1)

const on20x = async (response: Response) => {
  const result = await response.json()
  return {
    ok: true,
    status: response.status,
    data: result,
  }
}

const on50x = async (response: Response) => {
  const text = await response.text()
  return Promise.reject({
    ok: false,
    statusText: response.statusText,
    status: response.status,
    error: text,
  })
}

const on40x = async (response: Response) => {
  let result
  try {
    result = await response.json()
  } catch (err) {
    result = response.statusText
  }
  return Promise.reject({
    ok: false,
    status: response.status,
    error: result,
  })
}

const onUnknow = (response: Response) =>
  Promise.reject({
    ok: false,
    status: response.status,
    statusText: response.statusText,
    error: 'Unknown response status',
  })

const onOK = async (response: Response) => cond([[is20x, on20x]])(response)

const onNotOK = async (response: Response) => cond([[is40x, on40x], [is50x, on50x], [T, onUnknow]])(response)

// eslint-disable-next-line complexity
export const prepareResponse = async (response: Response): Promise<ResponseAPI<any>> =>
  cond([[isOK, onOK], [isNotOK, onNotOK]])(response)

export default prepareResponse
