// @flow
import typeToReducer from 'type-to-reducer'
import { add, prop } from 'ramda'

import { defaultToZero } from '@alphaott/common-utils/utils/help'
import { MOST_WATCHED_CHANNEL } from '../../actions/channelMostWatched'

import type { MostWatchedChannelAction } from '../../actions/channelMostWatched'

export type MostWatchedChannelsState = {|
  +data: { [string]: number },
|}

const initialState = {
  data: {},
}

const channelMostWatched = typeToReducer(
  {
    [MOST_WATCHED_CHANNEL]: (
      { data }: MostWatchedChannelsState,
      { id, duration }: MostWatchedChannelAction,
    ): MostWatchedChannelsState => ({
      data: {
        ...data,
        [id]: add(defaultToZero(prop(id, data)), duration),
      },
    }),
  },
  initialState,
)

export { channelMostWatched }
