// @flow
import { createSelector } from 'reselect'
import type { CatchupChannelOption, CatchupChannels } from '@alphaott/api-client/types/catchup/channels'

import type { CatchupStore } from '../types'

export const getCatchupChannels = (state: CatchupStore): CatchupChannels => state.catchupChannels.data

export const getCatchupChannel = (channelId: string) =>
  createSelector(
    [getCatchupChannels],
    (channels: CatchupChannels): ?CatchupChannelOption => channels[channelId],
  )
