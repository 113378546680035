// @flow

import { getErrorMessagePlayer, getCriticalErrorMessagePlayer } from '../selectors'

import {
  CHANGE_PLAYER_STATE_TYPE,
  CLEAR_PLAYER_ERRORS_TYPE,
  RESET_PLAYER_STATE_TYPE,
} from './constants'

export const changePlayerState = (payload) => (dispatch) =>
  dispatch({
    type: CHANGE_PLAYER_STATE_TYPE,
    payload,
  })

export const changePlayerReady = (isPlayerReady) => (dispatch) =>
  dispatch(changePlayerState({ isPlayerReady }))

export const changePlayerLoading = (isLoading) => (dispatch) =>
  dispatch(changePlayerState({ isLoading }))

export const changePlayerPlay = (dispatch, getState) => {
  const state = getState()
  const errorMessage = getErrorMessagePlayer(state)
  const criticalErrorMessage = getCriticalErrorMessagePlayer(state)

  if (!errorMessage && !criticalErrorMessage) {
    dispatch(changePlayerState({ isPaused: false }))
  }
}

export const changePlayerPause = (dispatch) => dispatch(changePlayerState({ isPaused: true }))

export const changePlayerSeeking = (isSeeking) => (dispatch) =>
  dispatch(changePlayerState({ isSeeking }))

export const changePlayerError = (errorMessage) => (dispatch) =>
  dispatch(changePlayerState({ errorMessage }))

export const changePlayerCriticalError = (criticalErrorMessage) => (dispatch) =>
  dispatch(changePlayerState({ criticalErrorMessage }))

export const clearPlayerErrors = (dispatch, getState) => {
  const state = getState()
  const errorMessage = getErrorMessagePlayer(state)
  const criticalErrorMessage = getCriticalErrorMessagePlayer(state)

  if (errorMessage || criticalErrorMessage) {
    dispatch({ type: CLEAR_PLAYER_ERRORS_TYPE })
  }
}

export const resetPlayer = (dispatch) => dispatch({ type: RESET_PLAYER_STATE_TYPE })
