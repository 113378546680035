/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable no-underscore-dangle */
// @flow
/* eslint-disable react/display-name */
import React, { memo, useMemo, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { equals, propEq, multiply, findIndex, isEmpty, prop } from 'ramda'

import { scale } from '@alphaott/smart-tv-utils'
import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'
import { Loader } from '@alphaott/smart-tv-components'
import {
  Container,
  ListContainer,
  StyledList,
  ChevronDownIcon,
  ProgramsListDivider,
  LoaderContainer,
  Placeholder,
} from './ProgramsList.style'
import { ProgramItem, PROGRAM_ITEM_HEIGHT } from '../ProgramItem'

type ProgramsListProps = {
  focusKey?: string,
  focusable?: boolean,
  items: Array<any>,
  selectedProgramId: string,
  channelPgRating: number,
  selectedChannelTags: string[],
  isLoading: boolean,
  onPressItem: Function,
  onFocusItem: Function,
}

const DIVIDER_HEIGHT: number = 3

const EmptyPlaceholder = ({ focusKey }: Object) => {
  const { ref, focused } = useFocusable({ focusKey })
  const { t } = useTranslation()

  return (
    <Placeholder ref={ref} focused={focused}>
      {t('mwstv_program_info_is_not_available')}
    </Placeholder>
  )
}

const ProgramsListPure = ({
  focusKey: parentFocusKey,
  focusable,
  items,
  selectedProgramId,
  channelPgRating,
  selectedChannelTags,
  isLoading,
  onPressItem,
  onFocusItem,
}: ProgramsListProps) => {
  const { ref, focusKey } = useFocusable({
    focusKey: parentFocusKey,
    focusable,
    preferredChildFocusKey: selectedProgramId,
  })
  const listRef = useRef(null)

  const renderItem = useCallback(
    ({ item }) => {
      const id = prop('_id', item)
      const onFocus = prop('onBecameFocused', item)
      const isSelected = equals(selectedProgramId, id)

      const handlePressItem = () => onPressItem(id)

      return (
        <ProgramItem
          {...item}
          key={id}
          focusKey={id}
          pgRating={channelPgRating}
          tags={selectedChannelTags}
          isSelected={isSelected}
          onFocus={onFocus}
          onPress={handlePressItem}
        />
      )
    },
    [channelPgRating, selectedChannelTags, selectedProgramId, onPressItem],
  )

  const renderDivider = useCallback(({ style }) => <ProgramsListDivider style={style} />, [])

  const focusedItemIndex = useMemo(
    () => findIndex(propEq('_id', selectedProgramId))(items),
    [items, selectedProgramId],
  )

  const SCALED_PROGRAM_ITEM_HEIGHT = useMemo(
    () => scale(window.innerWidth, PROGRAM_ITEM_HEIGHT + DIVIDER_HEIGHT),
    [],
  )

  const initialScrollOffset = useMemo(
    () => multiply(SCALED_PROGRAM_ITEM_HEIGHT, focusedItemIndex),
    [SCALED_PROGRAM_ITEM_HEIGHT, focusedItemIndex],
  )

  return (
    <FocusContext.Provider value={focusKey}>
      <Container ref={ref}>
        <ListContainer>
          {isLoading ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : isEmpty(items) ? (
            <EmptyPlaceholder />
          ) : (
            <StyledList
              ref={listRef}
              scrollStrategy="auto"
              initialScrollOffset={initialScrollOffset}
              items={items}
              dividerSize={DIVIDER_HEIGHT}
              renderItem={renderItem}
              renderDivider={renderDivider}
              onFocusItem={onFocusItem}
            />
          )}
        </ListContainer>
        <ChevronDownIcon />
      </Container>
    </FocusContext.Provider>
  )
}

export const ProgramsList = memo<ProgramsListProps>(ProgramsListPure)

export default ProgramsList
