// @flow

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { NotFound as NotFoundComponent } from '../../components/NotFound'

export const NotFound = () => {
  const navigate = useNavigate()

  const handleClickButton = useCallback(() => navigate('/', { replace: true }), [navigate])

  return <NotFoundComponent onClickButton={handleClickButton} />
}

export default NotFound
