// @flow
import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { reducer as formReducer } from 'redux-form'
import { path } from 'ramda'

// $FlowFixMe https://github.com/rt2zz/redux-persist/issues/619
import { persistStore, persistReducer, createMigrate } from 'redux-persist'
// $FlowFixMe https://github.com/rt2zz/redux-persist/issues/619
import storage from 'redux-persist/lib/storage'
import { createReduxHistoryContext } from 'redux-first-history'
import createSentryMiddleware from 'redux-sentry-middleware'
import { createBrowserHistory } from 'history'
import { persistState } from 'redux-devtools'
import { createPromise } from 'redux-promise-middleware'
import thunk from 'redux-thunk'

import { GIT_REVISION } from '@alphaott/smart-tv-config'
import { getBaseName } from '@alphaott/smart-tv-common'

import * as reducers from './reducers'
import {
  TOKEN_STORE,
  DEVICE_STORE,
  CUSTOMER_LIST_STORES,
  USER_SETTINGS,
  SETTINGS,
} from './constants'
import { migrations } from './migrations'
import { rootReducer } from './rootReducer/reducers'
import Sentry from '../core/sentry'
import { transformState } from './utils'

export const basename = getBaseName()

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory({ basename }),
})

const reducer = combineReducers({
  ...reducers,
  router: routerReducer,
  form: formReducer,
})

const persistConfig = {
  key: 'app',
  migrate: createMigrate(migrations),
  whitelist: [TOKEN_STORE, DEVICE_STORE, USER_SETTINGS, SETTINGS, ...CUSTOMER_LIST_STORES],
  storage,
}

const withRootReducers = rootReducer(reducer)
const persistedReducer = persistReducer(persistConfig, withRootReducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    routerMiddleware,
    thunk,
    createPromise({ promiseTypeSuffixes: ['LOADING', 'SUCCESS', 'ERROR'] }),
    createSentryMiddleware(Sentry, {
      getUserContext: (state) => state.customer.data,
      stateTransformer: (state) => transformState(state),
      getTags: (state) => ({
        git_commit: GIT_REVISION,
        brand: path(['brandConfig', 'data', 'id'], state),
      }),
    }),
  ],
  enhancers: [persistState(window.location.href.match(/[?&]debug_session=([^&#]+)\b/))],
})

export const history = createReduxHistory(store)
export const persistor = persistStore(store)

export default store
