// @flow

import typeToReducer from 'type-to-reducer'
import { sortBy, prop } from 'ramda'

import type { Country } from '@alphaott/api-client/types/countries'

import { FETCH_COUNTRIES_TYPE } from '../../actions/appCountries'

export type CountriesState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Array<Country>,
}

const initialState: CountriesState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
}

const countries = typeToReducer(
  {
    [FETCH_COUNTRIES_TYPE]: {
      LOADING: (state): CountriesState => ({
        ...state,
        errors: [],
        data: [],
        isLoading: true,
      }),

      SUCCESS: (state, action): CountriesState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        errors: [],
        data: sortBy(prop('commonName'), action.payload.data),
      }),

      ERROR: (state, action): CountriesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
        data: [],
      }),
    },
  },
  initialState,
)
export { countries }
