// @flow

import React, { forwardRef, useCallback, useMemo } from 'react'
import { VariableSizeGrid } from 'react-window'

import { getListScrollOffset } from '@alphaott/smart-tv-utils'
import { useLastFocusHistory } from '@alphaott/smart-tv-spatial-navigation'

// eslint-disable-next-line react/display-name
const LastFocusedGrid = forwardRef(({ postfix, itemData, ...otherProps }: any, ref) => {
  const { lastFocusedItem, onSaveLastFocus } = useLastFocusHistory({}, postfix)
  const { getRowIndex, onBecameFocused } = itemData

  const handleBecameFocused = useCallback(
    (item) => {
      const index = getRowIndex(item.y, item.height)

      if (onBecameFocused) {
        onBecameFocused(item)
      }

      onSaveLastFocus({ itemIndex: index, focusKey: item.focusKey })
    },
    [getRowIndex, onBecameFocused, onSaveLastFocus],
  )

  const initialScrollTop = useMemo(() => {
    if (!lastFocusedItem?.itemIndex) {
      return undefined
    }

    const { height, rowHeight } = otherProps

    return getListScrollOffset(height, rowHeight(), lastFocusedItem?.itemIndex)
  }, [lastFocusedItem?.itemIndex, otherProps])

  return (
    <VariableSizeGrid
      {...otherProps}
      ref={ref}
      initialScrollTop={initialScrollTop}
      itemData={{
        ...itemData,
        onBecameFocused: handleBecameFocused,
      }}
    />
  )
})

const GridInner = ({ withLastFocus, ...props }: any, ref) => {
  if (withLastFocus) {
    return <LastFocusedGrid {...props} ref={ref} />
  }

  return <VariableSizeGrid {...props} ref={ref} />
}

export const Grid = forwardRef(GridInner)

export default Grid
