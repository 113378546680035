// @flow
export const MAX_AVAILABLE_ORDER_VALUE: number = 100

export const ENTITY_TYPE = {
  LIVE_TV: 'LIVE_TV',
  RADIO: 'RADIO',
  MOVIE: 'MOVIE',
  TV_SHOW: 'TV_SHOW',
  PROMO: 'PROMO',
}

export const LIMIT_QUERY_VALUE: number = 20
