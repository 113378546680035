// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { Image } from '../../Image'
import { Text } from '../../Text'

import { Crew as CrewComponent } from './Crew'
import { ActionsPanel as ActionsPanelBase } from './ActionsPanel'
import { DetailLine as DetailLineComponent, GenresLine as GenresLineComponent } from './DetailLine'

export const Container = styled(Flex)``

export const LeftContainer = styled.div``

export const Poster = styled(Image)`
  width: 288rem;
  height: 430rem;
  border-radius: 6rem;
  overflow: hidden;
`

// Todo: Change this size
export const Placeholder = styled.img`
  width: 288rem;
  height: 482rem;
`

export const RightContainer = styled.div`
  width: 1100rem;
  margin-left: 40rem;
`

export const Title = styled(Text)`
  display: block;
  font-weight: bold;
  font-size: 48rem;
  line-height: 58rem;
  letter-spacing: 1.05rem;
  margin-top: 83rem;
`

export const DetailLine = styled(DetailLineComponent)`
  margin-top: 16rem;
`

export const GenresLine = styled(GenresLineComponent)`
  margin-top: 7rem;
`

export const Description = styled(Text)`
  display: block;
  font-size: 28rem;
  line-height: 36rem;
  margin-top: 22rem;
`

export const Crew = styled(CrewComponent)`
  margin-top: 16rem;
`

export const ActionsPanel = styled(ActionsPanelBase)`
  margin-top: 24rem;
`
