// @flow

import React, { memo, useMemo, useEffect } from 'react'
import QRCodeGenerator from 'qrcode.react'
import { useTranslation } from 'react-i18next'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'
import { Button } from '@alphaott/smart-tv-components'
import { SCREEN_FOCUS_KEYS } from '@alphaott/smart-tv-config'

import {
  PageView,
  Row,
  LeftColumn,
  RightColumn,
  Header,
  Title,
  Description,
  Logo,
  Content,
  QRHeading,
  LinkText,
  StyledIcon,
  QRCodeContainer,
} from './elements'

import { WarningIcon } from '../assets'

import { SUPPORT_BUTTON_FOCUS_KEY } from '../const'

type ExpiredSubscriptionViewProps = {
  logoSrc: string,
  logoHeight: number,
  link: string,
  onSupport: Function,
}

const QRCodeStyles = { width: '350rem', height: '350rem' }
const SCREEN_FOCUS_KEY = SCREEN_FOCUS_KEYS.EXPIRED

const ExpiredSubscriptionViewPure = ({
  logoSrc,
  logoHeight,
  link,
  onSupport,
}: ExpiredSubscriptionViewProps) => {
  const { ref, focusKey, setFocus } = useFocusable()
  const { t } = useTranslation()

  const uri = useMemo(() => link || '', [link])

  useEffect(() => {
    if (setFocus) setFocus(SUPPORT_BUTTON_FOCUS_KEY)
  }, [setFocus])

  return (
    <FocusContext.Provider value={focusKey}>
      <PageView ref={ref} focusKey={SCREEN_FOCUS_KEY}>
        <Header>
          <Logo src={logoSrc} height={logoHeight} alt="logo" />
        </Header>
        <Content>
          <Row>
            <LeftColumn>
              <Title>
                <StyledIcon as={WarningIcon} />
                {t('subscription_expired')}
              </Title>
              <Description>{t('mwstv_notifications_expired_subscription_message')}</Description>
              <LinkText>{link}</LinkText>
            </LeftColumn>

            {uri && (
              <RightColumn>
                <QRHeading>{t('mwstv_notifications_qr_code_heading')}</QRHeading>
                <QRCodeContainer>
                  <QRCodeGenerator style={QRCodeStyles} value={uri} />
                </QRCodeContainer>
              </RightColumn>
            )}
          </Row>
          <Row>
            <div>
              <Button onClick={onSupport} focusKey={SUPPORT_BUTTON_FOCUS_KEY}>
                {t('support')}
              </Button>
            </div>
            <div />
          </Row>
        </Content>
      </PageView>
    </FocusContext.Provider>
  )
}

export const ExpiredSubscriptionView = memo<ExpiredSubscriptionViewProps>(
  ExpiredSubscriptionViewPure,
)

export default ExpiredSubscriptionView
