// @flow

import shaka from 'shaka-player'
import { always, cond, equals, T } from 'ramda'

import { getFairplayLicense, getFairplayEzdrmLicense } from './registerRequestFilter'
import { registerFairplayResponse } from './registerResponseFilter'

const buyDRM = {
  getContentId: shaka.util.FairPlayUtils.defaultGetContentId,
  getRequestFilter: getFairplayLicense,
  getResponseFilter: registerFairplayResponse,
}

const ezDRM = {
  getContentId: initData => {
    const initDataAsString = shaka.util.StringUtils.fromBytesAutoDetect(initData)
    const contentId = initDataAsString.split(';').pop()

    return contentId
  },
  getRequestFilter: getFairplayEzdrmLicense,
  getResponseFilter: shaka.util.FairPlayUtils.commonFairPlayResponse,
}

export const getProviderData = cond([[equals('EZDRM'), always(ezDRM)], [T, always(buyDRM)]])

export default getProviderData
