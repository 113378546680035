// @flow
import { createSelector } from 'reselect'
import { prop } from 'ramda'

import type { AppNotificationsData } from '../../reducers'

type Store = any

export const getAppNotifications = (state: Store): AppNotificationsData => state.appNotifications.data

export const shouldOpenDemoWelcomeScreen = createSelector(
  [getAppNotifications],
  (appNotifications: AppNotificationsData): boolean => prop('shouldOpenDemoWelcomeScreen', appNotifications),
)
