// @flow
import typeToReducer from 'type-to-reducer'

import type { AuthOtp } from '@alphaott/api-client/types/authOtp'

import { AUTH_OTP_TYPE } from '../../actions'

export type AuthOtpState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: AuthOtp | Object,
}

const initialState: AuthOtpState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: {},
}

const authOtp = typeToReducer(
  {
    [AUTH_OTP_TYPE]: {
      LOADING: (state): AuthOtpState => ({
        ...state,
        errors: [],
        isLoading: true,
      }),

      SUCCESS: (state, action): AuthOtpState => ({
        ...state,
        data: action.payload.data,
        isLoading: false,
        isSuccess: true,
        errors: [],
      }),

      ERROR: (state, action): AuthOtpState => ({
        ...state,
        isLoading: false,
        errors: action.payload.errors,
      }),
    },
  },
  initialState,
)

export { authOtp }
