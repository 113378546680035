// @flow

type Options = {|
  isBillingEnabled: boolean,
  isPlanStepEnabled: boolean,
  isPlanStepEnabledFromUrl: boolean,
|}

// eslint-disable-next-line complexity
export const shouldShowPlanStep = ({
  isBillingEnabled,
  isPlanStepEnabled,
  isPlanStepEnabledFromUrl,
}: Options): boolean => {
  if (!isBillingEnabled) return false
  if (isPlanStepEnabled && !isPlanStepEnabledFromUrl) return false
  if (!isPlanStepEnabled && !isPlanStepEnabledFromUrl) return false
  return true
}
