// @flow
import typeToReducer from 'type-to-reducer'

import { WELCOME_MODAL } from '../../actions/appNotifications'

import type { WelcomeModalAction } from '../../actions/appNotifications'

export type AppNotificationsData = { [string]: any }

export type AppNotificationsState = {|
  +data: AppNotificationsData,
|}

const initialState = {
  data: {
    shouldOpenDemoWelcomeScreen: false,
  },
}

export const appNotifications = typeToReducer(
  {
    [WELCOME_MODAL]: (
      { data }: AppNotificationsState,
      { shouldOpenDemoWelcomeScreen }: WelcomeModalAction,
    ): AppNotificationsState => ({
      data: {
        ...data,
        shouldOpenDemoWelcomeScreen,
      },
    }),
  },
  initialState,
)

export default appNotifications
