// @flow
import { getCities } from '@alphaott/api-client/cities'
import { getPublicDomain } from '@alphaott/app-main/selectors'

export const FETCH_CITIES_TYPE = 'cities/FETCH'

export const fetchAppCities = (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_CITIES_TYPE,
    payload: getCities(getPublicDomain(getState())),
  })
