// @flow

import { useState, useCallback } from 'react'

export const useIsEmptyListState = (defaultValue: boolean = false) => {
  const [isEmptyList, setIsEmptyList] = useState(defaultValue)

  const handleSetEmptyList = useCallback(() => setIsEmptyList(true), [])

  const handleSetNotEmptyList = useCallback(() => setIsEmptyList(false), [])

  return {
    isEmptyList,
    setIsEmptyList,
    onSetEmptyList: handleSetEmptyList,
    onSetNotEmptyList: handleSetNotEmptyList,
  }
}

export default useIsEmptyListState
