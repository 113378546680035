// @flow
import styled from 'styled-components'
import { ListComponent } from '@alphaott/smart-tv-components'

import { getBackgroundSecondaryColor } from '@alphaott/smart-tv-themes'

import { ChevronLeftIconComponent, ChevronRightIconComponent } from '../assets'
import { DATE_PICKER_ITEM_WIDTH, DATE_PICKER_ITEM_HEIGHT } from '../DatePickerItem'

const CHEVRON_ICON_WIDTH = 23
const CHEVRON_ICON_HEIGHT = 41

export const Container = styled.div`
  background: ${getBackgroundSecondaryColor};
  width: 1193rem;
  min-height: ${DATE_PICKER_ITEM_HEIGHT}rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 60rem;
`

export const ChevronLeftIcon = styled(ChevronLeftIconComponent)`
  width: ${CHEVRON_ICON_WIDTH}rem;
  height: ${CHEVRON_ICON_HEIGHT}rem;
  margin-left: 10rem;
  margin-right: 12rem;
`

export const ChevronRightIcon = styled(ChevronRightIconComponent)`
  width: ${CHEVRON_ICON_WIDTH}rem;
  height: ${CHEVRON_ICON_HEIGHT}rem;
  margin-left: 12rem;
  margin-right: 10rem;
`

export const StyledList = styled(ListComponent).attrs({
  itemSize: DATE_PICKER_ITEM_WIDTH,
  listHeight: DATE_PICKER_ITEM_HEIGHT,
  layout: 'horizontal',
  style: { overflow: 'hidden' },
})``

export const ListContainer = styled.div`
  width: 1090rem;
  height: ${DATE_PICKER_ITEM_HEIGHT}rem;
`

export const DatePickerDivider = styled.div`
  width: 2rem;
  height: 60rem;
  margin-top: 9rem;
  background: ${(props) => props.theme.palette.grey[500]};
`
