// @flow
import React, { memo } from 'react'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import type { DateType } from '@alphaott/app-programs/utils'

import {
  Container,
  Label,
  Title,
  TimeContainer,
  Time,
  ProgressBox,
  StyledProgress,
  Paragraph,
} from './ProgramDescription.style'

type ProgramDescriptionProps = {
  title: string,
  start: DateType,
  stop: DateType,
  description: string,
  isLive?: boolean,
  isPlayable?: boolean,
}

/* eslint-disable complexity */
/* eslint-disable react/display-name */
// $FlowFixMe
export const ProgramDescription = memo(
  ({ title, start, stop, description, isLive, isPlayable }): ProgramDescriptionProps => {
    const { t } = useTranslation()

    return (
      <Container>
        {isPlayable && <Label>{t('mwstv_catchup_available')}</Label>}
        <Title isLive={isLive} isPlayable={isPlayable}>
          {title}
        </Title>
        {isLive && (
          <TimeContainer>
            <Time isLive={isLive} isPlayable={isPlayable}>
              {format(start, 'HH:mm')} - {format(stop, 'HH:mm')}
            </Time>
            <ProgressBox>
              <StyledProgress from={start} to={stop} />
            </ProgressBox>
          </TimeContainer>
        )}
        {isPlayable && !isLive && (
          <Time isLive={isLive} isPlayable={isPlayable}>
            {t('mwstv_aired')}: {format(start, 'HH:mm')} - {format(stop, 'HH:mm')}{' '}
            {format(start, 'DD/MM/YYYY')}
          </Time>
        )}
        <Paragraph>{description}</Paragraph>
      </Container>
    )
  },
)
