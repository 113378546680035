// @flow
import typeToReducer from 'type-to-reducer'
import { propEq, reject, map, prop, sortWith, descend } from 'ramda'
import { normalize } from 'normalizr'

import type { Movie } from '@alphaott/api-client/types/movies'

import { movieListSchema, prepareMovieImages } from '@alphaott/common-utils/utils/schemas/movies'
import { defaultToEmptyObject } from '@alphaott/common-utils/utils/help'
import {
  FETCH_FAVORITE_MOVIES_TYPE,
  FETCH_FAVORITE_MOVIES_IDS_TYPE,
  FETCH_FAVORITE_MOVIES_BY_IDS_TYPE,
  FETCH_FAVORITE_MOVIE_BY_ID_TYPE,
  ADD_MOVIE_ID_TO_FAVORITE_IDS_TYPE,
  REMOVE_MOVIE_ID_FROM_FAVORITE_IDS_TYPE,
  REMOVE_MOVIE_FROM_FAVORITES_TYPE,
} from '../../actions'

export type FavoriteMoviesIdsState = {|
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Array<Object>,
|}

const initialIdsState: FavoriteMoviesIdsState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
}

const movieFavoriteIds = typeToReducer(
  {
    [FETCH_FAVORITE_MOVIES_IDS_TYPE]: {
      LOADING: (state): FavoriteMoviesIdsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): FavoriteMoviesIdsState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: sortWith([descend(prop('ts'))], action.payload.data),
      }),

      ERROR: (state, action): FavoriteMoviesIdsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },

    [ADD_MOVIE_ID_TO_FAVORITE_IDS_TYPE]: {
      LOADING: (state): FavoriteMoviesIdsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): FavoriteMoviesIdsState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: [action.payload.data, ...state.data],
      }),

      ERROR: (state, action): FavoriteMoviesIdsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },

    [REMOVE_MOVIE_ID_FROM_FAVORITE_IDS_TYPE]: {
      LOADING: (state): FavoriteMoviesIdsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): FavoriteMoviesIdsState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: reject(propEq('_id', action.meta.itemId), state.data),
      }),

      ERROR: (state, action): FavoriteMoviesIdsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
  },
  initialIdsState,
)

export { movieFavoriteIds }

export type MoviesNormalize = { [string]: Movie }

export type FavoriteMoviesState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +entities: MoviesNormalize,
}

const initialState: FavoriteMoviesState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  entities: {},
}

const movieFavorites = typeToReducer(
  {
    [FETCH_FAVORITE_MOVIES_TYPE]: {
      LOADING: (state): FavoriteMoviesState => ({
        ...state,
        isLoading: true,
        isSuccess: false,
      }),

      SUCCESS: (state): FavoriteMoviesState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
      }),

      ERROR: (state, action): FavoriteMoviesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },

    [FETCH_FAVORITE_MOVIES_BY_IDS_TYPE]: {
      LOADING: (state): FavoriteMoviesState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): FavoriteMoviesState => {
        const {
          payload: { data },
          meta,
        } = action
        const preparedData = map(load => prepareMovieImages(load, meta.contentApi), data)

        const { entities } = normalize(preparedData, movieListSchema)
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          entities: defaultToEmptyObject({ ...entities.movies, ...state.entities }),
        }
      },

      ERROR: (state, action): FavoriteMoviesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },

    [FETCH_FAVORITE_MOVIE_BY_ID_TYPE]: {
      LOADING: (state): FavoriteMoviesState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): FavoriteMoviesState => {
        const { payload, meta } = action
        const preparedData = prepareMovieImages(payload.data, meta.contentApi)
        const { entities } = normalize([preparedData], movieListSchema)

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          entities: defaultToEmptyObject({ ...entities.movies, ...state.entities }),
        }
      },

      ERROR: (state, action): FavoriteMoviesState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },

    [REMOVE_MOVIE_FROM_FAVORITES_TYPE]: (state, action) => ({
      ...state,
      entities: reject(propEq('_id', action.payload.movieId), state.entities),
    }),
  },
  initialState,
)

export { movieFavorites }
