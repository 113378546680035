// @flow

import React, { memo, useMemo } from 'react'
import { not, all } from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct'
import Dotdotdot from 'react-dotdotdot'

import {
  Container,
  LeftContainer,
  Poster,
  Placeholder,
  RightContainer,
  Title,
  DetailLine,
  GenresLine,
  Description,
  Crew,
  ActionsPanel,
} from './CastInfo.style'

import placeholderImage from '../../../assets/placeholder.png'

import type { DetailLineProps, GenresLineProps } from './DetailLine'
import type { CrewProps } from './Crew'
import type { ActionsPanelProps } from './ActionsPanel'

export type CastInfoProps = {
  className?: any,
  title: string,
  plot?: string,
  posterSrc?: string,
} & DetailLineProps &
  GenresLineProps &
  CrewProps &
  ActionsPanelProps

// eslint-disable-next-line complexity
const CastInfoPure = ({
  className,
  title,
  plot,
  posterSrc,
  crew,
  year,
  runtime,
  seasons,
  episodes,
  pgRating,
  rating,
  genres,
  country,
  isFavorite,
  isShowRuntime,
  isAvailableFavoriteButton,
  isAvailablePlayButton,
  isAvailableTrailerButton,
  onFocus,
  onPlay,
  onPlayTrailer,
  onFavorite,
}: CastInfoProps) => {
  const unloader = useMemo(() => <Placeholder src={placeholderImage} alt="placeholder" />, [])

  const isShowBlock = (list: any[]) => not(all(isNilOrEmpty)(list))

  const isShowDetailLine = isShowBlock([year, runtime, country])
  const isShowGenresLine = isShowBlock([pgRating, rating, genres])

  return (
    <Container className={className}>
      <LeftContainer>
        <Poster
          src={posterSrc}
          placeholder={placeholderImage}
          unloader={unloader}
          alt="placeholder"
        />
      </LeftContainer>
      <RightContainer>
        <Title>{title}</Title>
        {isShowDetailLine && (
          <DetailLine
            year={year}
            runtime={runtime}
            seasons={seasons}
            episodes={episodes}
            country={country}
            isShowRuntime={isShowRuntime}
          />
        )}
        {isShowGenresLine && <GenresLine pgRating={pgRating} rating={rating} genres={genres} />}
        {isNotNilOrEmpty(plot) && (
          <Description>
            <Dotdotdot clamp={10}>{plot}</Dotdotdot>
          </Description>
        )}
        {isNotNilOrEmpty(crew) && <Crew items={crew} />}
        <ActionsPanel
          isFavorite={isFavorite}
          isAvailableFavoriteButton={isAvailableFavoriteButton}
          isAvailablePlayButton={isAvailablePlayButton}
          isAvailableTrailerButton={isAvailableTrailerButton}
          onPlay={onPlay}
          onPlayTrailer={onPlayTrailer}
          onFavorite={onFavorite}
          onFocus={onFocus}
        />
      </RightContainer>
    </Container>
  )
}

export const CastInfo = memo(CastInfoPure)

export default CastInfo
