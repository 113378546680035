import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { Text } from '@alphaott/smart-tv-components'

export const Container = styled(Flex)`
  flex-direction: column;
  max-width: 80%;
  z-index: 1;
  padding: 20rem 30rem;
  border: 1px solid #ffc9ca;
  background-color: #fff0f0;
  border-radius: 4rem;
`

export const Title = styled(Text)`
  font-size: 28rem;
  color: rgba(0, 0, 0, 0.85);
`

export const Description = styled(Text)`
  font-size: 24rem;
  line-height: 22rem;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 15rem;
`
