// @flow
import styled, { keyframes } from 'styled-components'

import { getTextColorPrimary } from '@alphaott/smart-tv-themes'

import { Button as ButtonBase } from '../Button'

const Fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Dimmer = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(1, 1, 1, 0.7);
  animation: ${Fade} 0.4s;
`
export const DialogWindow = styled.div`
  min-width: 400px;
  min-height: 100px;
  background-color: #1d1d1d;
  border-radius: 7px;
  border-top: 10px;
  border-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  color: ${getTextColorPrimary};
  animation: ${Fade} 0.4s;
`
export const DialogTitle = styled.div`
  font-size: 40rem;
  margin-bottom: 30rem;
`
export const DialogDescription = styled.div`
  font-size: 25rem;
  margin-bottom: 50rem;
`
export const DialogActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const Button = styled(ButtonBase)`
  margin: 1rem;
`
