// @flow
import { createSelector } from 'reselect'
import { map, propOr } from 'ramda'
import { isInitialLoading } from '@alphaott/common-utils/utils/help'
import { prepareMovie } from '@alphaott/common-utils/utils/schemas/movies'

import type { Movies } from '@alphaott/api-client/types/movies'
import type {
  CategoriesNormalize,
  CountriesNormalize,
  GenresNormalize,
  LanguageNormalize,
} from '@alphaott/common-types/normalized'
import { getMoviesMaxRating } from '@alphaott/app-main/selectors'
import type { PreparedMovie } from '@alphaott/common-utils/utils/schemas/movies'

import {
  getCategoriesEntities,
  getGenresEntities,
  getLanguagesEntities,
  getFavoriteIdsState,
  getCountriesEntities,
} from './selectors'

import type { FavoriteMoviesIdsState, MoviesSearchListState } from '../reducers'
import type { MoviesStore } from '../types'

export const getMoviesSearchListState = (state: MoviesStore): MoviesSearchListState => state.movieSearchList

export const getMoviesSearchListData = (state: MoviesStore): Movies => state.movieSearchList.data

export const isMovieSearchLoading = createSelector(
  [getMoviesSearchListState],
  (searchMovieState: MoviesSearchListState): boolean => isInitialLoading(searchMovieState),
)

export const hasMoreMoviesSearchList = createSelector(
  [getMoviesSearchListState],
  (searchMovieState: MoviesSearchListState): boolean => propOr(false, 'hasMore', searchMovieState),
)

export const getSearchedMovies = createSelector(
  [
    getMoviesSearchListData,
    getGenresEntities,
    getCategoriesEntities,
    getLanguagesEntities,
    getCountriesEntities,
    getFavoriteIdsState,
    getMoviesMaxRating,
  ],
  (
    movies: Movies,
    genres: GenresNormalize,
    categories: CategoriesNormalize,
    languages: LanguageNormalize,
    counties: CountriesNormalize,
    favorite: FavoriteMoviesIdsState,
    maxRating: number,
  ): Array<PreparedMovie> =>
    map(item => prepareMovie(item, genres, categories, languages, counties, favorite.data, maxRating), movies),
)
