// @flow
import typeToReducer from 'type-to-reducer'
import { reject, sortWith, descend, prop, propEq } from 'ramda'

import {
  FETCH_FAVORITE_CHANNELS_IDS_TYPE,
  ADD_CHANNEL_ID_TO_FAVORITE_IDS_TYPE,
  REMOVE_CHANNEL_ID_FROM_FAVORITE_IDS_TYPE,
} from '../../actions'

export type FavoriteChannelsState = {|
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: Array<Object>,
|}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: [],
}

const channelFavorite = typeToReducer(
  {
    [FETCH_FAVORITE_CHANNELS_IDS_TYPE]: {
      LOADING: (state): FavoriteChannelsState => ({
        ...state,
        isLoading: true,
        isSuccess: false,
      }),

      SUCCESS: (state, action): FavoriteChannelsState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: sortWith([descend(prop('ts'))], action.payload.data),
      }),

      ERROR: (state, action): FavoriteChannelsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
    [ADD_CHANNEL_ID_TO_FAVORITE_IDS_TYPE]: {
      LOADING: (state): FavoriteChannelsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): FavoriteChannelsState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: [action.payload.data, ...state.data],
      }),

      ERROR: (state, action): FavoriteChannelsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
    [REMOVE_CHANNEL_ID_FROM_FAVORITE_IDS_TYPE]: {
      LOADING: (state): FavoriteChannelsState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): FavoriteChannelsState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: reject(propEq('_id', action.meta.itemId), state.data),
      }),

      ERROR: (state, action): FavoriteChannelsState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
  },
  initialState,
)

export { channelFavorite }
