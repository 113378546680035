// @flow

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'ramda'

import { getMediaStream } from '@alphaott/app-channels/selectors'

export const useMediaStream = (channelId: string) => {
  const mediaStream = useSelector(getMediaStream(channelId))

  const cachedMediaStream = useMemo(() => mediaStream, [channelId, mediaStream]) // eslint-disable-line react-hooks/exhaustive-deps

  return useMemo(
    () => (isEmpty(cachedMediaStream) ? mediaStream : cachedMediaStream),
    [cachedMediaStream, mediaStream],
  )
}

export default useMediaStream
