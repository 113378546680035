// @flow

import styled from 'styled-components'

import { Scrollbar } from '@alphaott/smart-tv-components'

import { CustomerSupport as CustomerSupportBase, Address, Bottom } from '../CustomerSupport'
import { ForwardedSupportDetails } from '../SupportDetails'

export const Container = styled(Scrollbar)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  //padding-bottom: 200rem;
`

export const Title = styled.div`
  font-size: 28rem;
  font-weight: 700;
  letter-spacing: 3rem;
  text-transform: uppercase;
  margin: 28rem 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 50rem;
`

export const CustomerSupport = styled(CustomerSupportBase)`
  flex-direction: row;
  width: 1050rem;

  ${Bottom} {
    padding-left: 20rem;
  }

  ${Address} {
    margin-top: 20rem;
  }
`

export const SupportDetails = styled(ForwardedSupportDetails)`
  width: 1050rem;
  margin-top: 24rem;
`
