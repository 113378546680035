// @flow

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { DetailLineItem } from './DetailLineItem'
import { Rating as RatingComponent } from './Rating'

export const Container = styled(Flex)``

export const Item = styled(DetailLineItem)`
  &:first-child span {
    display: none;
  }

  span {
    padding: 0 8rem;
  }
`

export const Capitalize = styled.span`
  text-transform: capitalize;
`

export const Rating = styled(RatingComponent)`
  margin-right: 8rem;
`
