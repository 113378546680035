// @flow
import { propOr, find, propEq, reject, not, join, isEmpty } from 'ramda'

import { PERCENTAGE_TO_COMPLETED } from '@alphaott/app-config'
import { isNilOrEmpty } from '@alphaott/common-utils'
import { getTvShow, getTvShowsByIds, getPersonalList, putPersonalList } from '@alphaott/api-client'
import { logoutRequestWrapper } from '@alphaott/app-main/utils'
import { getPublicDomain, getContentDomain } from '@alphaott/app-main/selectors'
import { getCustomerId } from '@alphaott/app-core/models/selectors'

import {
  selectRecentTVShowsIds,
  selectRecentTVShowsProgress,
  selectRecentTVShowsState,
  isRecentTVShow,
} from '../../selectors/selectors'

export const GET_RECENT_WATCHED_TVSHOW = 'tvshows/GET_RECENT_WATCHED'
export const FETCH_RECENT_TVSHOW = 'tvshows/FETCH_RECENT_WATCHED'
export const FETCH_RECENT_TVSHOW_BY_IDS = 'tvshows/FETCH_RECENT_TVSHOW_BY_IDS'
export const FETCH_RECENT_CURRENT_TVSHOW = 'tvshows/FETCH_RECENT_CURRENT_TVSHOW'
export const RESET_RECENT_TVSHOW = 'tvshows/RESET_RECENT_TVSHOW'
export const UPDATE_RECENT_TVSHOW_LIST = 'tvshow/UPDATE_RECENT_TVSHOW_LIST'

export type TVShowProgressAction = {
  showId: string,
  episodeId: string,
  seasonId: string,
  // episodesInSeason: number,
  // seasonsTotal: number,
  total: number,
  current: number,
}

export const fetchRecentCurrentTVShow = (id: string) => async (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_RECENT_CURRENT_TVSHOW,
    payload: getTvShow(getPublicDomain(getState()), id),

    meta: {
      contentApi: getContentDomain(getState()),
    },
  })

export const updateRecentWatchedTVShow = (progress: TVShowProgressAction) => async (
  dispatch: Function,
  getState: Function,
) => {
  const history = selectRecentTVShowsProgress(getState())
  const customerId = getCustomerId(getState())

  const { showId, seasonId, episodeId, current, total } = progress

  const currentShow = propOr({}, showId, history)
  const seasons = propOr({}, 'seasons', currentShow)
  const episodes = propOr({}, 'episodes', seasons[seasonId])

  const completed = current > total * PERCENTAGE_TO_COMPLETED
  const lastUpdated = new Date().getTime()

  const isNotRecent = not(isRecentTVShow(showId)(getState()))

  const data = {
    lastUpdated,
    lastEpisodeId: episodeId,
    lastSeasonId: seasonId,
    seasons: {
      ...seasons,
      [seasonId]: {
        episodes: {
          ...episodes,
          [episodeId]: {
            current,
            total,
            completed,
          },
        },
      },
    },
  }
  // eslint-disable-next-line no-useless-catch
  try {
    await dispatch({
      type: UPDATE_RECENT_TVSHOW_LIST,
      payload: logoutRequestWrapper(putPersonalList)(customerId, 'tv_show', 'history', showId, data)(
        dispatch,
        getState,
      ),
    })
    if (isNotRecent) dispatch(fetchRecentCurrentTVShow(showId))
  } catch (err) {
    throw err
  }
}

export const getRecentTVShows = async (dispatch: Function, getState: Function) => {
  const customerId = getCustomerId(getState())

  return dispatch({
    type: GET_RECENT_WATCHED_TVSHOW,
    payload: logoutRequestWrapper(getPersonalList)(customerId, 'tv_show', 'history')(dispatch, getState),
  })
}

export const fetchRecentTVShowsByIds = (dispatch: Function, getState: Function) => {
  const recentTVShows = selectRecentTVShowsState(getState())
  const idList = reject(id => find(propEq('_id', id))(recentTVShows.data), selectRecentTVShowsIds(getState()))

  if (isEmpty(idList)) return null

  return dispatch({
    type: FETCH_RECENT_TVSHOW_BY_IDS,
    payload: getTvShowsByIds(getPublicDomain(getState()), join(',', idList)),
    meta: {
      contentApi: getContentDomain(getState()),
    },
  })
}

export const fetchRecentTVShows = () => async (dispatch: Function, getState: Function) => {
  const customerId = getCustomerId(getState())

  if (isNilOrEmpty(customerId)) return null

  return dispatch({
    type: FETCH_RECENT_TVSHOW,
    payload: Promise.all([await dispatch(getRecentTVShows), dispatch(fetchRecentTVShowsByIds)]),
  })
}
