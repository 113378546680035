// @flow
import { path } from 'ramda'

import { getTvShowLanguages } from '@alphaott/api-client'
import { getPublicDomain, getCacheChannels, isCacheValid } from '@alphaott/app-main/selectors'

export const FETCH_TVSHOW_LANGUAGES = 'tvshows/FETCH_LANGUAGES'

const fetchTVShowLanguagesAction = (serviceSpec: string) => (dispatch: Function, getState: Function) => {
  const state = getState()
  const publicApi = getPublicDomain(state)
  const ttl = getCacheChannels(state)

  return dispatch({
    type: FETCH_TVSHOW_LANGUAGES,
    payload: getTvShowLanguages(publicApi, serviceSpec),
    meta: { ttl },
  })
}

// eslint-disable-next-line complexity
export const fetchTVShowLanguages = (serviceSpec: string, force: ?boolean = false) => (
  dispatch: Function,
  getState: Function,
) => {
  if (force) return dispatch(fetchTVShowLanguagesAction(serviceSpec))

  const state = getState()
  const expires = path(['tvshowLanguages', 'expires'], state)

  if (expires && isCacheValid(expires)) return null

  return dispatch(fetchTVShowLanguagesAction(serviceSpec))
}
