// @flow

import { createSelector } from 'reselect'
import { map, reject, isNil, propSatisfies } from 'ramda'

import type {
  LanguageNormalize,
  CategoriesNormalize,
  GenresNormalize,
  CountriesNormalize,
} from '@alphaott/common-types/normalized'

import { prepareTVShow, type PreparedTVShow } from '@alphaott/common-utils/utils/schemas/tvshows'
import { isInitialLoading, defaultToEmptyObject } from '@alphaott/common-utils/utils/help'
import { getTVShowsMaxRating } from '@alphaott/app-main/selectors'

import {
  selectFavoriteState,
  selectFavoriteIdsState,
  selectCategoriesEntities,
  selectGenresEntities,
  selectLanguagesEntities,
  selectCountriesEntities,
} from './selectors'

import type { FavoriteTVShowsIdsState, FavoriteTVShowsState } from '../reducers'
import type { TVShowsStore } from '../types'

const isDataNil = tvshow => propSatisfies(isNil, '_id', tvshow)
export const getTVShowsFavoriteList = createSelector(
  [
    selectFavoriteState,
    selectGenresEntities,
    selectCategoriesEntities,
    selectLanguagesEntities,
    selectCountriesEntities,
    selectFavoriteIdsState,
    getTVShowsMaxRating,
  ],
  (
    favorites: FavoriteTVShowsState,
    genres: GenresNormalize,
    categories: CategoriesNormalize,
    languages: LanguageNormalize,
    countries: CountriesNormalize,
    favoriteIds: FavoriteTVShowsIdsState,
    maxRating: number,
  ): Array<PreparedTVShow> =>
    reject(
      isDataNil,
      map(
        ({ item }) =>
          prepareTVShow(
            defaultToEmptyObject(favorites.entities[item]),
            genres,
            categories,
            languages,
            countries,
            favoriteIds.data,
            maxRating,
          ),
        favoriteIds.data || {},
      ),
    ),
)

export const isTVShowsFavoritesLoading = createSelector(
  [selectFavoriteState],
  // $FlowFixMe
  (favorites: FavoriteTVShowsState): boolean => isInitialLoading(favorites) || favorites.isLoading,
)

export const isTVShowsFavoritesSuccess = (store: TVShowsStore): boolean => store.tvshowFavorites.isSuccess
