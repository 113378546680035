// @flow
import typeToReducer from 'type-to-reducer'

import { EXIT_APP_MODAL } from '../actions'

import type { ExitModalAction } from '../actions'

export type AppModalsData = { [string]: any }

export type AppModalsState = {|
  +data: AppModalsData,
|}

const initialState = {
  data: {
    isAppExitModalOpened: false,
  },
}

const appModals = typeToReducer(
  {
    [EXIT_APP_MODAL]: (
      { data }: AppModalsState,
      { isAppExitModalOpened }: ExitModalAction,
    ): AppModalsState => ({
      data: {
        ...data,
        isAppExitModalOpened,
      },
    }),
  },
  initialState,
)

export { appModals }
