// @flow
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { submit } from 'redux-form'
import { prop } from 'ramda'

import { useFocusable } from '@alphaott/smart-tv-spatial-navigation'

import { EditProfileNameForm } from './EditProfileNameForm'

import {
  Content,
  Title,
  EditNameSubtitle,
  EditNameRowBottom,
  EditNameProfileContainer,
  EditNameSmallButton,
} from './EditProfileNameView.styles'

import { PROFILE_NAME_INPUT_FOCUS_KEY, SAVE_NAME_BUTTON_FOCUS_KEY } from './const'

type EditProfileNameViewProps = {
  profile: Object,
  onSubmit: Function,
}

export const EditProfileNameViewPure = ({ profile, onSubmit }: EditProfileNameViewProps) => {
  const { ref, setFocus } = useFocusable()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const name = useMemo(() => prop('name', profile), [profile])

  useEffect(() => {
    if (setFocus) setFocus(PROFILE_NAME_INPUT_FOCUS_KEY)
  }, [setFocus])

  const handleSubmit = useCallback(() => {
    dispatch(submit('profile_name'))
  }, [dispatch])

  return (
    <EditNameProfileContainer ref={ref}>
      <Content>
        <Title>{t('mwstv_edit_name')}</Title>
        <EditNameSubtitle>{t('mwstv_please_fill_in_the_name_of_your_profile')}</EditNameSubtitle>

        <EditProfileNameForm onSubmit={onSubmit} initialValues={{ name }} />

        <EditNameRowBottom>
          <EditNameSmallButton focusKey={SAVE_NAME_BUTTON_FOCUS_KEY} onClick={handleSubmit}>
            {t('mwstv_button_done')}
          </EditNameSmallButton>
        </EditNameRowBottom>
      </Content>
    </EditNameProfileContainer>
  )
}

export const EditProfileNameView = memo<EditProfileNameViewProps>(EditProfileNameViewPure)

export default EditProfileNameView
