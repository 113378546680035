// @flow
import escapeRegexp from 'escape-string-regexp'
import { path, propOr } from 'ramda'

import { POPULAR_TVSHOW_MAX_COUNT } from '@alphaott/app-config'
import { getTvShowList, getTvShow } from '@alphaott/api-client'

import {
  getPublicDomain,
  getContentDomain,
  getUITVShowsSort,
  getCacheVOD,
  isCacheValid,
} from '@alphaott/app-main/selectors'
import { getServiceTVShowsVodIds } from '@alphaott/app-core/selectors'
import { defaultToEmptyString } from '@alphaott/common-utils'
import { getTvSeason } from '@alphaott/api-client/tvShow/season'
import { getTvEpisode } from '@alphaott/api-client/tvShow/episode'
import { getTvShowRelated } from '@alphaott/api-client/tvShow'

import type { TvShowListQuery } from '@alphaott/api-client/tvShow'

export const FETCH_TVSHOWS = 'tvshows/FETCH_TVSHOWS'
export const FETCH_TOP_TVSHOWS = 'tvshows/FETCH_TOP_TVSHOWS'
export const FETCH_SEARCH_TVSHOWS = 'movies/FETCH_SEARCH_TVSHOWS'
export const FETCH_TVSHOW_DETAIL = 'tvshows/FETCH_TVSHOWS_DETAIL'
export const FETCH_TVSHOW_RELATED = 'tvshows/FETCH_TVSHOW_RELATED'
export const FETCH_TVSHOW_SEASON = 'tvshows/FETCH_TVSHOW_SEASON'
export const FETCH_TVSHOW_EPISODE = 'tvshows/FETCH_TVSHOWS_EPISODE'
export const SET_ACTIVE_SHOW_ID = 'tvshows/SET_ACTIVE_SHOW_ID'
export const SET_ACTIVE_SEASON_ID = 'tvshows/SET_ACTIVE_SEASON_ID'
export const SET_ACTIVE_EPISODE_ID = 'tvshows/SET_ACTIVE_EPISODE_ID'
export const RESET_TVSHOW_DETAIL = 'tvshows/RESET_TVSHOW_DETAIL'
export const RESET_TVSHOW_EPISODE_DETAIL = 'tvshows/RESET_TVSHOW_EPISODE_DETAIL'

export const FILTER_OPEN_TVSHOW = 'tvshows/FILTER_OPEN'

export type FilterOpenTVShowAction = {|
  type: 'tvshows/FILTER_OPEN',
  isOpenFilter: boolean,
|}

export const setActiveTVShow = (id: string) => (dispatch: Function) =>
  dispatch({
    type: SET_ACTIVE_SHOW_ID,
    payload: {
      id,
    },
  })

export const setActiveTVSeason = (id: string) => (dispatch: Function) =>
  dispatch({
    type: SET_ACTIVE_SEASON_ID,
    payload: {
      id,
    },
  })

export const setActiveTVEpisode = (id: string) => (dispatch: Function) =>
  dispatch({
    type: SET_ACTIVE_EPISODE_ID,
    payload: {
      id,
    },
  })

export const resetTVShow = {
  type: RESET_TVSHOW_DETAIL,
}

export const resetTVShowEpisode = {
  type: RESET_TVSHOW_EPISODE_DETAIL,
}

export const fetchTVShowsAction = (params?: TvShowListQuery, resetData?: boolean) => (
  dispatch: Function,
  getState: Function,
) => {
  const state = getState()
  const serviceSpec = getServiceTVShowsVodIds(state)
  const publicApi = getPublicDomain(state)
  const contentApi = getContentDomain(state)
  const ttl = getCacheVOD(state)

  return dispatch({
    type: FETCH_TVSHOWS,
    payload: getTvShowList(publicApi, serviceSpec, params),
    meta: {
      contentApi,
      resetData,
      ttl,
    },
  })
}

export const fetchTopTVShowsAction = (params?: TvShowListQuery) => (dispatch: Function, getState: Function) => {
  const state = getState()
  const defaultSort = getUITVShowsSort()(state)
  const serviceSpec = getServiceTVShowsVodIds(state)
  const publicApi = getPublicDomain(state)
  const contentApi = getContentDomain(state)
  const ttl = getCacheVOD(state)

  const paramObj = {
    ...params,
    sort: defaultSort,
    limit: POPULAR_TVSHOW_MAX_COUNT,
  }

  return dispatch({
    type: FETCH_TOP_TVSHOWS,
    payload: getTvShowList(publicApi, serviceSpec, paramObj),
    meta: {
      contentApi,
      ttl,
    },
  })
}

// eslint-disable-next-line complexity
export const fetchTVShows = (params?: TvShowListQuery, resetData?: boolean, force: ?boolean = false) => (
  dispatch: Function,
  getState: Function,
) => {
  /**
   * Force Fetch TVShows
   * For example, for MWTC `pull to refresh` feature
   *  */
  if (force) return dispatch(fetchTVShowsAction(params, resetData))

  const state = getState()
  const expires = path(['tvshowsList', 'expires'], state)

  if (expires && isCacheValid(expires)) return null

  return dispatch(fetchTVShowsAction(params, resetData))
}

// eslint-disable-next-line complexity
export const fetchTopTVShows = (params?: TvShowListQuery, force: ?boolean = false) => (
  dispatch: Function,
  getState: Function,
) => {
  /**
   * Force Fetch TVShows
   * For example, for MWTC `pull to refresh` feature
   *  */
  if (force) return dispatch(fetchTopTVShowsAction(params))

  const state = getState()
  const expires = path(['topTVShowsList', 'expires'], state)

  if (expires && isCacheValid(expires)) return null

  return dispatch(fetchTopTVShowsAction(params))
}

export const searchTVShows = ({
  search,
  params,
  resetData,
}: {
  search: string,
  params?: TvShowListQuery,
  resetData?: boolean,
}) => (dispatch: Function, getState: Function) => {
  const searchStr = defaultToEmptyString(search)
  const searchRegexp = `/${escapeRegexp(searchStr)}/i`
  const serviceSpec = getServiceTVShowsVodIds(getState())
  const resParams = {
    title: searchRegexp,
    plot: searchRegexp,
    limit: propOr(50, 'limit', params),
    skip: propOr(0, 'skip', params),
    ...params,
  }
  return dispatch({
    type: FETCH_SEARCH_TVSHOWS,
    payload: getTvShowList(getPublicDomain(getState()), serviceSpec, resParams),
    meta: {
      contentApi: getContentDomain(getState()),
      resetData,
    },
  })
}

export const fetchTVShowDetail = (showId: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_TVSHOW_DETAIL,
    payload: getTvShow(getPublicDomain(getState()), showId),
    meta: {
      contentApi: getContentDomain(getState()),
    },
  })

export const fetchTVShowRelated = (showId: string) => (dispatch: Function, getState: Function) => {
  const serviceSpec = getServiceTVShowsVodIds(getState())
  return dispatch({
    type: FETCH_TVSHOW_RELATED,
    payload: getTvShowRelated(getPublicDomain(getState()), serviceSpec, showId),
    meta: {
      contentApi: getContentDomain(getState()),
    },
  })
}

export const fetchTVShowSeason = (tvshowId: string, seasonId: string) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_TVSHOW_SEASON,
    payload: getTvSeason(getPublicDomain(getState()), tvshowId, seasonId),
    meta: {
      contentApi: getContentDomain(getState()),
      seasonId,
    },
  })

export const fetchTVShowEpisode = (tvshowId: string, seasonId: string, episodeId: string) => (
  dispatch: Function,
  getState: Function,
) =>
  dispatch({
    type: FETCH_TVSHOW_EPISODE,
    payload: getTvEpisode(getPublicDomain(getState()), tvshowId, seasonId, episodeId),
    meta: {
      contentApi: getContentDomain(getState()),
    },
  })

export const filterOpenTVShow = (isOpenFilter: boolean): FilterOpenTVShowAction => ({
  type: FILTER_OPEN_TVSHOW,
  isOpenFilter,
})
