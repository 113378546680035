// @flow

import styled from 'styled-components'
import { Text } from '@alphaott/smart-tv-components'

export const CHANNEL_LIST_CONTROL_WIDTH = 500

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${CHANNEL_LIST_CONTROL_WIDTH}rem;
  height: 100%;
  background-color: rgba(29, 29, 29, 0.8);
`

export const Heading = styled.div`
  padding: 71rem 0 32rem 24rem;
`

export const Title = styled(Text)`
  font-size: 38rem;
  line-height: 46rem;
  letter-spacing: 1.05rem;
`
