// @flow

import React, { forwardRef, memo } from 'react'
import { useSelector } from 'react-redux'
import { always, cond, T } from 'ramda'

import {
  ShakaPlayerContainer as ShakaPlayer,
  ShakaPlayer3Container as ShakaPlayer3,
} from '@alphaott/smart-tv-shaka-player'
import { VideoJSPlayerContainer as VideoJSPlayer } from '@alphaott/smart-tv-video-js-player'
import { getSmartTVVideoPlayer } from '@alphaott/app-main/selectors'

import { PlayerContainer } from '../AlphaottVideoPlayer/AlphaottVideoPlayer.styles'

import { isShakaPlayer, isShakaPlayer3, isVideoJSPlayer } from './utils'
import { BaseControls } from '../AlphaottVideoPlayer/BaseControls'

import type { VideoPlayer as VideoPlayerTypes } from '../../types'

type VideoPlayerProps = VideoPlayerTypes & {}

const VideoPlayerPure = (
  {
    videoId,
    mediaStream,
    autoplay,
    defaultTimePosition,
    onLoadMeta,
    onCanPlay,
    onLoading,
    onSeeking,
    onSeeked,
    onPlay,
    onPause,
    onEnd,
    onTimeUpdate,
    onError,
    onChangeAudioTracks,
    onChangeSubtitleTracks,
    onDestroy,
  }: VideoPlayerProps,
  playerRef: any,
) => {
  const videoPlayer = useSelector(getSmartTVVideoPlayer)

  const Player = cond([
    [isShakaPlayer3, always(ShakaPlayer3)],
    [isShakaPlayer, always(ShakaPlayer)],
    [isVideoJSPlayer, always(VideoJSPlayer)],
    [T, always(ShakaPlayer)],
  ])(videoPlayer)

  return (
    <PlayerContainer>
      <Player
        videoId={videoId}
        ref={playerRef}
        mediaStream={mediaStream}
        autoplay={autoplay}
        defaultTimePosition={defaultTimePosition}
        onLoadMeta={onLoadMeta}
        onCanPlay={onCanPlay}
        onLoading={onLoading}
        onSeeking={onSeeking}
        onSeeked={onSeeked}
        onPlay={onPlay}
        onPause={onPause}
        onEnd={onEnd}
        onTimeUpdate={onTimeUpdate}
        onError={onError}
        onChangeAudioTracks={onChangeAudioTracks}
        onChangeSubtitleTracks={onChangeSubtitleTracks}
        onDestroy={onDestroy}
      />
      <BaseControls />
    </PlayerContainer>
  )
}

export const VideoPlayer = memo<VideoPlayerProps>(forwardRef(VideoPlayerPure))

export default VideoPlayer
