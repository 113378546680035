// @flow

import React, { createContext, useContext } from 'react'
import type { Element } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import type { UserSettingsDetails } from '@alphaott/common-types'

import { initialState } from '../../reducers'
import { getUserSettings } from '../../selectors'
import { setUserSettings, changePreferredTrack } from '../../actions'

import type { PreferredTrackPaylaod } from '../../actions/track'

export type UserSettingsContextTypes = {
  settings: UserSettingsDetails,
  changeSettings: (settings: UserSettingsDetails) => void,
  changePreferredTrack: (payload: PreferredTrackPaylaod) => void,
}

const defaultUserSettings: UserSettingsContextTypes = {
  settings: initialState,
  changeSettings: () => {},
  changePreferredTrack: () => {},
}

export const UserSettingsContext = createContext<UserSettingsContextTypes>(defaultUserSettings)

export type UserSettingsProviderProps = {
  children: Element<any>,
}

export const useUserSettings = (): UserSettingsContextTypes => useContext<UserSettingsContextTypes>(UserSettingsContext)

export const UserSettingsProvider = ({ children }: UserSettingsProviderProps) => {
  const dispatch = useDispatch()
  const userSettings = useSelector(getUserSettings)

  const value: UserSettingsContextTypes = {
    settings: userSettings,
    changeSettings: (settings: UserSettingsDetails) => dispatch(setUserSettings(settings)),
    changePreferredTrack: (payload: PreferredTrackPaylaod) => dispatch(changePreferredTrack(payload)),
  }

  return <UserSettingsContext.Provider value={value}>{children}</UserSettingsContext.Provider>
}
