// @flow

import { useSelector } from 'react-redux'
import { equals } from 'ramda'

import { getNetworkStatus } from '@alphaott/smart-tv-current-device/selectors'

export const CONNECTED = 'connected'
export const DISCONNECTED = 'disconnected'

export const prepareCurrentNetworkStatus = (value: boolean) => (value ? CONNECTED : DISCONNECTED)

export const useNetworkStatus = () => {
  const networkStatus = useSelector(getNetworkStatus)

  return equals(CONNECTED, networkStatus)
}

export default useNetworkStatus
