// @flow

import React, {
  memo,
  useRef,
  useMemo,
  useEffect,
  forwardRef,
  useCallback,
  useState,
  useImperativeHandle,
  useLayoutEffect,
} from 'react'

import { preparePlayerSources } from '@alphaott/drm-web'
import { useHttpForSourceLink } from '@alphaott/smart-tv-video-player/hooks'

import type { VideoPlayer } from '@alphaott/smart-tv-video-player'

import { VideoJSPlayer } from '../components'
import { useVideoJSPlayerTracks, useVideoJSPlayerErrors } from '../hooks'
import { defaultConfig } from '../config'

export type VideoJSPlayerContainerProps = VideoPlayer & {
  config: any,
}

const VideoJSPlayerContainerPure = (
  {
    videoId,
    config = defaultConfig,
    mediaStream,
    autoplay,
    defaultTimePosition,
    onLoadMeta,
    onCanPlay,
    onLoading,
    onSeeking,
    onSeeked,
    onPlay,
    onPause,
    onEnd,
    onTimeUpdate,
    onError,
    onChangeAudioTracks,
    onChangeSubtitleTracks,
    onDestroy,
  }: VideoJSPlayerContainerProps,
  ref: any,
) => {
  const playerRef = useRef(null)

  const { isRequiredHttpScheme, onPrepareSources } = useHttpForSourceLink()
  const [sources, setSources] = useState(null)

  const {
    onInitTracks,
    onChangeAudioTrack,
    onChangedAudioTrack,
    onChangeSubtitleTrack,
    onChangedSubtitleTrack,
  } = useVideoJSPlayerTracks(videoId, playerRef, {
    onChangeAudioTracks,
    onChangeSubtitleTracks,
  })

  const onPrepareError = useVideoJSPlayerErrors(sources, onError)

  const videoJSConfig = useMemo(() => ({ ...config, autoplay }), [config, autoplay])

  const handleLoaded = useCallback(() => {
    const time = playerRef.current?.getTime()

    const { audioTracks, subtitleTracks } = onInitTracks()

    onLoadMeta({
      time,
      audioTracks,
      subtitleTracks,
    })

    if (defaultTimePosition) {
      playerRef.current?.onSeek(defaultTimePosition)
    }
  }, [defaultTimePosition, onInitTracks, onLoadMeta])

  const handleCanPlay = useCallback(() => {
    onCanPlay()

    if (autoplay) {
      playerRef.current?.onPlay()
    }
  }, [autoplay, onCanPlay])

  const handleTimeUpdate = useCallback(() => {
    const time = playerRef.current?.getTime()

    if (time) {
      onTimeUpdate(time)
    }
  }, [onTimeUpdate])

  const handleError = useCallback(
    // eslint-disable-next-line consistent-return
    async () => {
      const err = playerRef.current?.getError()
      const error = await onPrepareError(err)

      onError(error)
    },
    [onPrepareError, onError],
  )

  const handleDestroy = useCallback(() => {
    const time = playerRef.current?.getTime()

    onDestroy(time)
  }, [onDestroy])

  useImperativeHandle(
    ref,
    () => ({
      ...playerRef.current,
      onChangeAudioTrack,
      onChangeSubtitleTrack,
    }),
    [sources, onChangeAudioTrack, onChangeSubtitleTrack], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getSources = useCallback(async () => {
    const source = await preparePlayerSources(mediaStream)

    setSources(isRequiredHttpScheme ? onPrepareSources(source) : source)
  }, [isRequiredHttpScheme, mediaStream, onPrepareSources])

  useEffect(() => {
    getSources()
  }, [mediaStream]) // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(
    () => () => {
      handleDestroy()
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  if (!sources) {
    return null
  }

  return (
    <VideoJSPlayer
      ref={playerRef}
      config={videoJSConfig}
      sources={sources}
      defaultTimePosition={defaultTimePosition}
      // Event callbacks
      onLoading={onLoading}
      onLoaded={handleLoaded}
      onCanPlay={handleCanPlay}
      onPlay={onPlay}
      onSeeking={onSeeking}
      onSeeked={onSeeked}
      onPause={onPause}
      onEnd={onEnd}
      onTimeUpdate={handleTimeUpdate}
      onError={handleError}
      onChangedAudioTrack={onChangedAudioTrack}
      onChangedSubtitleTrack={onChangedSubtitleTrack}
    />
  )
}

export const VideoJSPlayerContainer = memo<VideoJSPlayerContainerProps>(
  forwardRef(VideoJSPlayerContainerPure),
)

export default VideoJSPlayerContainer
