// @flow
import typeToReducer from 'type-to-reducer'

import { CUSTOMER_AUTH_BY_QR_CODE } from '../../actions/appCustomer'

export type CustomerAuthorizeState = {
  data: {
    expires_in: number,
    code: string | null,
    uri: string | null,
  },
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
}

const initialState: CustomerAuthorizeState = {
  data: {
    expires_in: 0,
    code: null,
    uri: null,
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
}

const customerAuthorize = typeToReducer(
  {
    [CUSTOMER_AUTH_BY_QR_CODE]: {
      LOADING: (): CustomerAuthorizeState => ({ ...initialState, isLoading: true }),

      SUCCESS: (state, action): CustomerAuthorizeState => ({
        ...state,
        data: action.payload.data,
        isLoading: false,
        isSuccess: true,
        isError: false,
        errors: [],
      }),

      ERROR: (state, action): CustomerAuthorizeState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
  },
  initialState,
)

export { customerAuthorize }
