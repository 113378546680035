// @flow

export {
  getCustomer,
  updateCustomer,
  updateCustomerSettings,
  changePassword,
  voucherActivate,
  deactivateCustomer,
} from './customer'
export { getPromos } from './promos'
export { getServiceProvider } from './serviceProvider'
export { getSubscription, cancelSubscription } from './subscriptions'
export { getConfig } from './config'
export { getLimits } from './limits'

export { login, signUp, signUpV2, resetPassword, signUpActivate, authorize, sendOtp } from './authorization'
export { getAuthMethods } from './authMethods'
export { refreshToken } from './refreshToken'

export { getChannelsBrief } from './channels/index'
export { getChannelSources } from './channels/sources'
export { getChannelLanguages } from './channels/languages'
export { getChannelCategories } from './channels/categories'
export { getChannelCountries } from './channels/countries'
export { getChannelGenres } from './channels/genres'

export { getMoviesList } from './movies/index'
export { getMovieSources } from './movies/sources'
export { getMovie, getMoviesByIds } from './movies/movie'
export { getRelatedMovies } from './movies/related'
export { getTrailer } from './movies/trailer'
export { getMoviesLanguages } from './movies/languages'
export { getMoviesCategories } from './movies/categories'
export { getMoviesCountries } from './movies/countries'
export { getMoviesGenres } from './movies/genres'

export { getTvShowList } from './tvShow/index'
export { getTvShowSources } from './tvShow/sources'
export { getTvShow, getTvShowsByIds, getTvShowTrailer, getTvShowRelated } from './tvShow'
export { getTvShowLanguages, getTvShowCategories, getTvShowCountries, getTvShowGenres } from './tvShow/dictionaries'

export { getPersonalList, putPersonalList, removeItemFromPersonalList } from './synchronization'

export { getRadioChannels, getRadioChannelsBrief } from './radioChannel'
export { getRadioChannelCategories } from './radioChannel/categories'
export { getRadioChannelCountries } from './radioChannel/countries'
export { getRadioChannelGenres } from './radioChannel/genres'
export { getRadioChannelLanguages } from './radioChannel/languages'
export { getRadioChannelSources } from './radioChannel/sources'
