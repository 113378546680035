// @flow
import { propEq, reject, isEmpty } from 'ramda'

import { ENTITY_TYPE } from '../const'

export const compact: Function = reject((item) => isEmpty(item.items))

export const isLiveTV: (Object) => boolean = propEq('type', ENTITY_TYPE.LIVE_TV)
export const isRadio: (Object) => boolean = propEq('type', ENTITY_TYPE.RADIO)
export const isMovie: (Object) => boolean = propEq('type', ENTITY_TYPE.MOVIE)
export const isTVShow: (Object) => boolean = propEq('type', ENTITY_TYPE.TV_SHOW)
export const isPromo: (Object) => boolean = propEq('type', ENTITY_TYPE.PROMO)
