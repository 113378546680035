// @flow

import { SAVE_TOKEN_TYPE, REMOVE_TOKEN_TYPE } from '../../actions/token'
import type { TokenAction } from '../../actions/token'

export type TokenState = {
  +access?: string,
  +type?: string,
  +refresh?: string,
  +expires?: number,
}

const initialState = {}

const token = (state: TokenState = initialState, { type, token: newToken }: TokenAction): TokenState => {
  if (type === SAVE_TOKEN_TYPE) {
    // eslint-disable-next-line no-mixed-operators
    const expires = new Date().getTime() + newToken.expires_in * 1000
    return {
      access: newToken.access_token,
      type: newToken.token_type,
      refresh: newToken.refresh_token,
      expires,
    }
  }
  if (type === REMOVE_TOKEN_TYPE) {
    return initialState
  }

  return state
}

export { token }
