// @flow

import { createSelector } from 'reselect'
import { pathOr } from 'ramda'

import type { Address } from '@alphaott/common-types'

import { prepareServiceProviderAddress } from '../utils'
import type { CoreStore } from '../types'
import type { ServiceProviderState } from '../../reducers'

type Store = CoreStore

export const getServiceProvider = (state: Store): ServiceProviderState => state.serviceProvider
const getCountries = (state: Store) => state.countries.data

export const getServiceProviderSupportEmail = createSelector(
  [getServiceProvider],
  (serviceProvider: ServiceProviderState): string => pathOr('', ['data', 'supportEmail'], serviceProvider),
)

export const getServiceProviderWww = createSelector(
  [getServiceProvider],
  (serviceProvider: ServiceProviderState): string => pathOr('', ['data', 'www'], serviceProvider),
)

export const getServiceProviderPhone = createSelector(
  [getServiceProvider],
  (serviceProvider: ServiceProviderState): string => pathOr('', ['data', 'supportPhone'], serviceProvider),
)

export const getServiceProviderName = createSelector(
  [getServiceProvider],
  (serviceProvider: ServiceProviderState): string => pathOr('', ['data', 'shortName'], serviceProvider),
)

export const getServiceProviderTitle = createSelector(
  [getServiceProvider],
  (serviceProvider: ServiceProviderState): string => pathOr('', ['data', 'title'], serviceProvider),
)

export const getServiceProviderAddress = createSelector(
  [getServiceProvider, getCountries],
  (serviceProvider: ServiceProviderState, countries): Address =>
    prepareServiceProviderAddress(pathOr({}, ['data', 'address'], serviceProvider), countries),
)

export const getServiceProviderLoading = createSelector(
  [getServiceProvider],
  (serviceProvider: ServiceProviderState): boolean => pathOr(true, ['isLoading'], serviceProvider),
)
