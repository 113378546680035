// @flow

import React, { createContext, useReducer } from 'react'

import reducer, { INITIAL_STATE } from './reducer'

import type { PlayerStateType, PlayerActionType } from './types'

type PlayerProviderProps = $Exact<{
  children?: React$Node,
  initialState?: PlayerStateType,
}>

type PlayerContextType = [PlayerStateType, (PlayerActionType) => void]

export const PlayerContext = createContext<PlayerContextType>([INITIAL_STATE, () => {}])

export const PlayerProvider = ({ children, initialState = INITIAL_STATE }: PlayerProviderProps) => {
  const contextValue = useReducer<PlayerStateType, PlayerActionType>(reducer, initialState)

  return <PlayerContext.Provider value={contextValue}>{children}</PlayerContext.Provider>
}

export default PlayerProvider
