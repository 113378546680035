// @flow

import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { assocPath, equals, path, remove } from 'ramda'

import { updateSettings } from '@alphaott/app-core/models/actions/appCustomer'
import { getCustomerSettings } from '@alphaott/app-core/selectors'

export const useChangeTracksSettings = (redirect?: string) => {
  const dispatch = useDispatch()

  const settings = useSelector(getCustomerSettings)

  const handleChangeLanguage = useCallback(
    (settingsPath: string[], value: any) => {
      const preparedSettings = assocPath(settingsPath, value, settings)

      return dispatch(updateSettings(preparedSettings, redirect))
    },
    [redirect, settings, dispatch],
  )

  const handleRemoveLanguage = useCallback(
    (settingsPath: string[], optionIndex: number) => {
      const languageSettings = path(settingsPath, settings)
      const preparedValue = equals(optionIndex, 0) ? [] : remove(optionIndex, 1, languageSettings)
      const preparedSettings = assocPath(settingsPath, preparedValue, settings)

      return dispatch(updateSettings(preparedSettings, redirect))
    },
    [redirect, settings, dispatch],
  )

  return {
    onChangeLanguage: handleChangeLanguage,
    onRemoveLanguage: handleRemoveLanguage,
  }
}
