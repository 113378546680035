// @flow
import { pipe, cond, lt, equals, gt, always, isNil, not } from 'ramda'

import { isNotZero } from '@alphaott/common-utils/utils/help'

const getTimezone = (time: number): string =>
  cond([
    [lt(0), always(`+${time}`)],
    [equals(0), always('')],
    [gt(0), always(`${time}`)],
  ])(time)

export const getHoursFromMinutes = (minutes: number): number => Math.floor(minutes / 60)

export const prepareTimezone = pipe(getHoursFromMinutes, getTimezone)

export const isValidTimezone = (timezone: number): boolean =>
  isNotZero(timezone) && not(isNil(timezone))
