// @flow
import URI from 'urijs'
import { map, isNil, isEmpty, path, not } from 'ramda'

export const prepareTrailers = (trailers: any, domain: string) => {
  if (isNil(trailers) || isEmpty(trailers)) return []

  // eslint-disable-next-line complexity
  return map(trailer => {
    const imageUrl = path(['image', 'path'], trailer)
    if (not(imageUrl)) return trailer
    const uri = new URI(imageUrl)
    const url = uri && uri.is('absolute') ? imageUrl : `${domain}${imageUrl}`
    return {
      ...trailer,
      image: {
        ...trailer.image,
        path: url,
      },
    }
  }, trailers)
}
