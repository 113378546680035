// @flow

import { useMemo } from 'react'
import { includes, insertAll, pipe, reject, length } from 'ramda'

import type { Section } from '../types'

type Props = {
  addToStart: Array<Section>,
  addToEnd: Array<Section>,
  rejectedSections: Array<string>,
}

export const usePrepareSections = (
  sections: Array<Section>,
  { addToStart = [], addToEnd = [], rejectedSections = [] }: Props,
) =>
  useMemo(
    () =>
      pipe(
        insertAll(0, addToStart),
        reject((item) => includes(item.id, rejectedSections)),
        insertAll(length(sections), addToEnd),
      )(sections),
    [addToEnd, addToStart, rejectedSections, sections],
  )

export default usePrepareSections
