// @flow
import type { DRM } from '@alphaott/api-client/types/drm'

import { DRM_TYPES } from '../const'

const preparePlayReadyKeySystems = (drm: DRM) => {
  if (drm[DRM_TYPES.PLAYREADY]) {
    return {
      url: drm[DRM_TYPES.PLAYREADY].licenseUri,
      licenseHeaders: drm[DRM_TYPES.PLAYREADY].licenseHeaders,
    }
  }
  return {}
}

export default preparePlayReadyKeySystems
