// @flow
import { LARGE_VIEWPORT, MEDIUM_VIEWPORT, SMALL_VIEWPORT } from '@alphaott/app-config'
import { rgba } from 'polished'

import { colorPalette } from '../colorPalettes'
import type { ColorType } from '../colorPalettes'

export const kapangColors: ColorType = { ...colorPalette, primary: '#cccccc', secondary: '#FFFFFF' }

export const lightTheme = {
  colors: kapangColors,
  background: {
    primary: '#fafafa',
    secondary: '#fafafa',
    highlight: kapangColors.light,
    select: '#fafafa',
    footerTop: '#eee',
  },
  text: {
    primary: kapangColors.dark,
    secondary: kapangColors.grayText,
    disabled: kapangColors.dark,
    link: kapangColors.dark,
    hint: kapangColors.dark,
    highlight: kapangColors.secondary,
    gray: kapangColors.grayText,
    seeAll: 'rgba(52, 73, 94, 0.8)',
    primaryContrast: '#000000',
  },
  border: {
    primary: '#dddddd',
    gray: '#ddd',
  },
  filter: {
    primary: '#43484d',
    secondary: '#43484d',
    disabled: kapangColors.grayLabel,
    highlight: '#fff',
  },
  tabs: {
    bg: '#fafafa',
    containerBg: kapangColors.dark,
    color: kapangColors.dark,
    border: kapangColors.primary,
    scrollbarThumb: kapangColors.primary,
    programTitle: kapangColors.white,
  },
  programGuide: {
    programBox: {
      background: {
        main: 'rgba(221, 221, 221, 0.8)',
        hover: kapangColors.dark,
        selected: kapangColors.dark,
        progress: rgba(kapangColors.primary, 0.3),
        past: rgba(kapangColors.primary, 0.3),
        pastSelected: kapangColors.dark,
        pastHover: kapangColors.dark,
        empty: 'rgba(221, 221, 221, 0.3)',
        emptyProgress: rgba(kapangColors.primary, 0.1),
      },
      text: {
        empty: kapangColors.grayLabel,
      },
    },
    programGuidePictureOverlay: {
      horizontal:
        // eslint-disable-next-line max-len
        `linear-gradient(90deg, #fafafa 0%,${rgba('#fafafa', 0.97)} 13.03%,${rgba('#fafafa', 0.69)} 43.24%,${rgba(
          '#fafafa',
          0,
        )} 100%);`,
      vertical: `linear-gradient(0,#fafafa 0%,${rgba('#fafafa', 0.73)} 34.97%,${rgba('#fafafa', 0)} 100%);`,
    },
  },
  card: {
    title: kapangColors.dark,
    subTitle: kapangColors.grayText,
    bg: kapangColors.dark,
    number: kapangColors.grayBorder,
    boxShadow: 'rgba(221, 221, 221, 0.8)',
    loaderBackgroundImage: 'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
  },
  dimension: {},
  size: 'default',
  breakpoints: [`${SMALL_VIEWPORT}px`, `${MEDIUM_VIEWPORT}px`, `${LARGE_VIEWPORT}px`],
  signIn: {
    clipPath: false,
  },
}
