// @flow
import { pathOr, path, find, whereEq, pipe, map, trim, split, filter } from 'ramda'
import type { App } from '@alphaott/common-types'
import { createSelector } from 'reselect'

import { getApps } from './apps'

export const getRequestSupport = path(['company', 'requestSupport'])

export const getIosApp = createSelector(
  [getApps],
  (apps: App[]): ?App => find(whereEq({ platform: 'MOBILE', storeType: 'APPSTORE', enabled: true }), apps),
)

export const getAndroidMobileApp = createSelector(
  [getApps],
  (apps: App[]): ?App => find(whereEq({ platform: 'MOBILE', storeType: 'PLAYSTORE', enabled: true }), apps),
)

export const getAndroidTVApp = createSelector(
  [getApps],
  (apps: App[]): ?App => find(whereEq({ platform: 'STB', storeType: 'PLAYSTORE', enabled: true }), apps),
)

export const getAmazonFireTVApp = createSelector(
  [getApps],
  (apps: App[]): ?App => find(whereEq({ platform: 'MOBILE', storeType: 'AMAZON', enabled: true }), apps),
)

export const getRokuTVApp = createSelector(
  [getApps],
  (apps: App[]): ?App => find(whereEq({ platform: 'ROKU', storeType: 'ROKUSTORE', enabled: true }), apps),
)

export const getMobileApps = createSelector(
  [getIosApp, getAndroidMobileApp, getAndroidTVApp, getAmazonFireTVApp, getRokuTVApp],
  (iosApp, androidMobile, androidTV, amazonFireTV, rokuTV) => {
    const apps = {
      iosApp,
      androidMobile,
      androidTV,
      amazonFireTV,
      rokuTV,
    }

    // skip empty fields
    return filter(Boolean, apps)
  },
)

export const excludeProfileFields = pipe(
  pathOr('', ['options', 'ui', 'profile', 'form', 'exclude']),
  split(','),
  filter(Boolean),
  map(trim),
)

export const excludeBillingFields = pipe(
  pathOr('', ['options', 'ui', 'billing', 'form', 'exclude']),
  split(','),
  filter(Boolean),
  map(trim),
)

export const countryProfileForm = pipe(
  pathOr('', ['options', 'ui', 'profile', 'form', 'defaultCountry']),
  trim,
)

export const countryBillingForm = pipe(
  pathOr('', ['options', 'ui', 'billing', 'form', 'defaultCountry']),
  trim,
)

export const includeDemoUserNotification = pathOr(false, [
  'options',
  'web',
  'notifications',
  'demoUserAndNoSubscriptions',
])

export const getPreparedLinksToMobileApps = (appStoreIcon: any, playGoogleIcon: any) =>
  createSelector(
    [getIosApp, getAndroidMobileApp],
    (iosApp?: App, androidApp?: App): Object[] => {
      const storeLinks = []

      if (iosApp) {
        storeLinks.push({
          link: iosApp.url,
          image: appStoreIcon,
        })
      }

      if (androidApp) {
        storeLinks.push({
          link: androidApp.url,
          image: playGoogleIcon,
        })
      }

      return storeLinks
    },
  )
