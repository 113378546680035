// @flow

import typeToReducer from 'type-to-reducer'

import { FETCH_SERVICE_PROVIDER_TYPE } from '../../actions/appServiceProvider'

export type ServiceProviderState = {
  +isLoading: boolean,
  +isSuccess: boolean,
  +isError: boolean,
  +errors: any,
  +data: any,
}

const initialState: ServiceProviderState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errors: [],
  data: {},
}

const serviceProvider = typeToReducer(
  {
    [FETCH_SERVICE_PROVIDER_TYPE]: {
      LOADING: (state): ServiceProviderState => ({ ...state, isLoading: true }),

      SUCCESS: (state, action): ServiceProviderState => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        data: action.payload.data,
      }),

      ERROR: (state, action): ServiceProviderState => ({
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errors: action.payload.error,
      }),
    },
  },
  initialState,
)

export { serviceProvider }
