// @flow

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  changePlayerReady,
  changePlayerLoading,
  changePlayerPlay,
  changePlayerPause,
  changePlayerSeeking,
  changePlayerError,
  changePlayerCriticalError,
  clearPlayerErrors,
  resetPlayer,
} from '../models/actions'

export const useControls = () => {
  const dispatch = useDispatch()

  const handleReady = useCallback(
    (value) => {
      dispatch(changePlayerReady(value))
    },
    [dispatch],
  )

  const handleLoading = useCallback(
    (value) => {
      dispatch(changePlayerLoading(value))
    },
    [dispatch],
  )

  const handlePlay = useCallback(() => {
    dispatch(changePlayerPlay)
  }, [dispatch])

  const handlePause = useCallback(() => {
    dispatch(changePlayerPause)
  }, [dispatch])

  const handleSeeking = useCallback(
    (value) => {
      dispatch(changePlayerSeeking(value))
    },
    [dispatch],
  )

  const handleError = useCallback(
    (error) => {
      dispatch(changePlayerError(error))
    },
    [dispatch],
  )

  const handleCriticalError = useCallback(
    (error) => {
      dispatch(changePlayerCriticalError(error))
    },
    [dispatch],
  )

  const handleClearErrors = useCallback(() => {
    dispatch(clearPlayerErrors)
  }, [dispatch])

  const handleResetPlayer = useCallback(() => {
    dispatch(resetPlayer)
  }, [dispatch])

  return {
    onPlayerReady: handleReady,
    onLoading: handleLoading,
    onPlay: handlePlay,
    onPause: handlePause,
    onSeeking: handleSeeking,
    onError: handleError,
    onCriticalError: handleCriticalError,
    onClearErrors: handleClearErrors,
    onResetPlayer: handleResetPlayer,
  }
}

export default useControls
