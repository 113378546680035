// @flow
import { getCatchupProgramSources } from '@alphaott/api-client/catchup'
import { logoutRequestWrapper } from '@alphaott/app-main/utils'
import { prepareAcceptMediaStreamHeaders, prepareAcceptMediaStreamEncryptedHeaders } from '@alphaott/drm-web'

export const FETCH_CATCHUP_PROGRAM_SOURCES_TYPE = 'catchup/FETCH_CHANNEL_SOURCES'

const acceptMediaStream = prepareAcceptMediaStreamHeaders()
const acceptMediaStreamEncrypted = prepareAcceptMediaStreamEncryptedHeaders()

export const fetchCatchupProgramSources = (channelId: string, programId: string) => (
  dispatch: Function,
  getState: Function,
) =>
  dispatch({
    type: FETCH_CATCHUP_PROGRAM_SOURCES_TYPE,
    payload: logoutRequestWrapper(getCatchupProgramSources)(channelId, programId, {
      acceptMediaStream,
      acceptMediaStreamEncrypted,
    })(dispatch, getState),
    meta: {
      programId,
    },
  })

export default fetchCatchupProgramSources
