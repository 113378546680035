// @flow

import React, {
  memo,
  forwardRef,
  useRef,
  useEffect,
  useCallback,
  useState,
  useMemo,
  useImperativeHandle,
} from 'react'
import { debounce } from 'throttle-debounce'
import { path, not, equals, min } from 'ramda'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'
import { prepareTime } from '@alphaott/smart-tv-common'
import { BUTTON_KEY_NAMES } from '@alphaott/smart-tv-platforms'

import { Dot } from './Dot'
import { Container, ProgressLineContainer, ProgressLine } from './elements'
import { usePlayerContext } from '../../store'

const SEEK_RATIO = 1.2
const DEFAULT_SEEK_TIME = 10
const MAX_SEEK_SPEED = 5

type SeekBarProps = {
  className?: string,
  progress: number,
  duration: number,
  onSeek: (time: number) => void,
}

export const SeekBarPure = ({ className, progress, duration, onSeek }: SeekBarProps, ref) => {
  const { ref: focusableRef, focusKey } = useFocusable({
    trackChildren: true,
  })

  const [state] = usePlayerContext()

  const [isFocusedDot, setFocusedDot] = useState(false)
  const [ivVisibleDot, setVisibleDot] = useState(false)
  const [isDotSeeking, setDotSeeking] = useState(false)

  const seekBarRef = useRef(null)
  const dotRef = useRef(null)
  const offsetProgressRef = useRef(null)
  const progressLineWidthRef = useRef(null)

  const defaultSeekDistance = useMemo(
    () => (DEFAULT_SEEK_TIME / duration) * progressLineWidthRef.current,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offsetProgressRef.current, progressLineWidthRef.current, duration],
  )

  const [seekDistance, setSeekDistance] = useState(defaultSeekDistance)

  const prepareProgress = useCallback(
    (value) => Math.min(Math.max(value, 0), progressLineWidthRef.current),
    [],
  )

  const preparedProgress = useMemo(() => prepareProgress(progress), [progress, prepareProgress])

  const getDotOffset = useCallback(() => {
    const dotWidth = path(['current', 'clientWidth'], dotRef)
    return dotWidth / 2
  }, [])

  const calculateTimeFromSeekerPosition = useCallback(() => {
    if (not(dotRef.current)) return null
    const { left } = dotRef.current.getBoundingClientRect()
    const dotOffset = getDotOffset()
    const percent = (left + dotOffset - offsetProgressRef.current) / progressLineWidthRef.current
    return duration * percent
  }, [duration, getDotOffset])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSeekPlayer = useCallback(
    debounce(1000, () => {
      onSeek(calculateTimeFromSeekerPosition())
    }),
    [calculateTimeFromSeekerPosition, onSeek],
  )

  const handleSeekDot = useCallback(
    (value, distance = defaultSeekDistance) => {
      setDotSeeking(true)

      const { left: initialLeftValue } = dotRef.current.getBoundingClientRect()
      const dotOffset = getDotOffset()

      let left = initialLeftValue + dotOffset - offsetProgressRef.current

      if (equals('forward', value)) left += min(distance, defaultSeekDistance * MAX_SEEK_SPEED)
      if (equals('replay', value)) left -= min(distance, defaultSeekDistance * MAX_SEEK_SPEED)

      dotRef.current.style.left = `${prepareProgress(left)}px`

      handleSeekPlayer()
    },
    [defaultSeekDistance, getDotOffset, prepareProgress, handleSeekPlayer],
  )

  const handleReplay = useCallback((distance) => handleSeekDot('replay', distance), [handleSeekDot])

  const handleForward = useCallback(
    (distance) => handleSeekDot('forward', distance),
    [handleSeekDot],
  )

  useEffect(() => {
    progressLineWidthRef.current = path(['current', 'clientWidth'], seekBarRef)
    const { left } = seekBarRef.current.getBoundingClientRect()
    offsetProgressRef.current = left
  }, [seekBarRef])

  useImperativeHandle(ref, () => ({
    onReplay: handleReplay,
    onForward: handleForward,
    seekBar: seekBarRef.current,
  }))

  const handleKeyup = useCallback(() => {
    setSeekDistance(defaultSeekDistance)
  }, [defaultSeekDistance])

  const seekingCallback = useCallback(
    (callback) => {
      if (not(isFocusedDot)) return
      callback(seekDistance)
      setSeekDistance(seekDistance * SEEK_RATIO)
    },
    [isFocusedDot, seekDistance],
  )

  const handleArrowLeft = useCallback(() => {
    seekingCallback(handleReplay)
  }, [seekingCallback, handleReplay])

  const handleArrowRight = useCallback(() => {
    seekingCallback(handleForward)
  }, [seekingCallback, handleForward])

  useEffect(() => {
    document?.addEventListener('keyup', handleKeyup)
    document?.addEventListener(BUTTON_KEY_NAMES.ARROW_LEFT, handleArrowLeft)
    document?.addEventListener(BUTTON_KEY_NAMES.ARROW_RIGHT, handleArrowRight)
    return () => {
      document?.removeEventListener('keyup', handleKeyup)
      document?.removeEventListener(BUTTON_KEY_NAMES.ARROW_LEFT, handleArrowLeft)
      document?.removeEventListener(BUTTON_KEY_NAMES.ARROW_RIGHT, handleArrowRight)
    }
  }, [handleKeyup, handleArrowLeft, handleArrowRight])

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (!isDotSeeking) {
      dotRef.current.style.left = `${preparedProgress}px`
    }
  }, [isDotSeeking, preparedProgress])

  useEffect(() => {
    if (!state.seeking) {
      setTimeout(() => setDotSeeking(false), 800)
    }
  }, [state.seeking])

  useEffect(() => {
    const timeoutId = setTimeout(() => setVisibleDot(true), 0)
    return () => {
      setVisibleDot(false)
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <FocusContext.Provider value={focusKey}>
      <Container className={className} ref={focusableRef}>
        <ProgressLineContainer ref={seekBarRef}>
          <Dot
            ref={dotRef}
            isVisible={ivVisibleDot}
            isSeeking={isDotSeeking}
            isFocused={isFocusedDot}
            seekTime={prepareTime(calculateTimeFromSeekerPosition())}
            onFocusedDot={setFocusedDot}
          />
          <ProgressLine progress={preparedProgress} />
        </ProgressLineContainer>
      </Container>
    </FocusContext.Provider>
  )
}

export const SeekBarRef = memo(forwardRef(SeekBarPure))

export default SeekBarRef
