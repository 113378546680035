// @flow
import { makeGetRequest } from '../../utils'

import type { TVShowEpisode } from '../../types/tvshows'
import type { ResponseAPI } from '../../types/api'

export const getTvEpisode = (
  domain: string,
  showId: string,
  seasonId: string,
  episodeId: string,
): Promise<ResponseAPI<TVShowEpisode>> =>
  makeGetRequest(`${domain}/client/api/vod/tv-show/${showId}/season/${seasonId}/episode/${episodeId}`)
