// @flow
import { isNotEqual, usePrevious } from '@alphaott/common-utils'

type UseVersionValueReturn<V> = {
  isNew: boolean,
  value: V,
  prevValue: V,
}

export const useVersionValue = <V>(
  value: V | number | string | Object | Array,
): UseVersionValueReturn<V> => {
  const prevValue = usePrevious(value)
  const isNew = isNotEqual(value, prevValue)

  return {
    isNew,
    value,
    prevValue,
  }
}

export default useVersionValue
