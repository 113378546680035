// @flow
import { getTvShowSources, getTvShowTrailer } from '@alphaott/api-client'
import { logoutRequestWrapper } from '@alphaott/app-main/utils'
import { prepareAcceptMediaStreamHeaders, prepareAcceptMediaStreamEncryptedHeaders } from '@alphaott/drm-web'
import { getTvShowSeasonTrailer } from '@alphaott/api-client/tvShow/season/trailer'
import { getTvShowEpisodeTrailer } from '@alphaott/api-client/tvShow/episode/trailer'

export const FETCH_TVSHOW_EPISODE_SOURCE = 'tvshows/FETCH_TVSHOWS_EPISODE_SOURCE'
export const FETCH_TVSHOW_TRAILER_SOURCE = 'tvshows/FETCH_TVSHOW_TRAILER_SOURCE'
export const FETCH_TVSHOW_SEASON_TRAILER_SOURCE = 'tvshows/FETCH_TVSHOW_SEASON_TRAILER_SOURCE'
export const FETCH_TVSHOW_EPISODE_TRAILER_SOURCE = 'tvshows/FETCH_TVSHOW_EPISODE_TRAILER_SOURCE'
export const RESET_TVSHOW_EPISODE_SOURCES = 'tvshows/RESET_TVSHOW_EPISODE_SOURCES'

const acceptMediaStream = prepareAcceptMediaStreamHeaders()
const acceptMediaStreamEncrypted = prepareAcceptMediaStreamEncryptedHeaders()

export const resetTVShowEpisodeSources = {
  type: RESET_TVSHOW_EPISODE_SOURCES,
}

export const fetchTVShowSources = (showId: string, seasonId: string, episodeId: string) => (
  dispatch: Function,
  getState: Function,
) => {
  dispatch({
    type: FETCH_TVSHOW_EPISODE_SOURCE,
    payload: logoutRequestWrapper(getTvShowSources)(showId, seasonId, episodeId, {
      acceptMediaStream,
      acceptMediaStreamEncrypted,
    })(dispatch, getState),
  })
}

export const fetchTVShowTrailerSources = (showId: string, trailerId: string, trailerIdx: number) => (
  dispatch: Function,
  getState: Function,
) =>
  dispatch({
    type: FETCH_TVSHOW_TRAILER_SOURCE,
    payload: logoutRequestWrapper(getTvShowTrailer)(showId, trailerIdx, {
      acceptMediaStream,
      acceptMediaStreamEncrypted,
    })(dispatch, getState),
    meta: {
      trailerId,
    },
  })

export const fetchTVShowSeasonTrailerSources = (
  showId: string,
  seasonId: string,
  trailerId: string,
  trailerIdx: string,
) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_TVSHOW_SEASON_TRAILER_SOURCE,
    payload: logoutRequestWrapper(getTvShowSeasonTrailer)(showId, seasonId, trailerIdx, {
      acceptMediaStream,
      acceptMediaStreamEncrypted,
    })(dispatch, getState),
    meta: {
      trailerId,
    },
  })

export const fetchTVShowEpisodeTrailerSources = (
  showId: string,
  seasonId: string,
  episodeId: string,
  trailerId: string,
  trailerIdx: string,
) => (dispatch: Function, getState: Function) =>
  dispatch({
    type: FETCH_TVSHOW_EPISODE_TRAILER_SOURCE,
    payload: logoutRequestWrapper(getTvShowEpisodeTrailer)(showId, seasonId, episodeId, trailerIdx, {
      acceptMediaStream,
      acceptMediaStreamEncrypted,
    })(dispatch, getState),
    meta: {
      trailerId,
    },
  })
