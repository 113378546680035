// @flow

import { createSelector } from 'reselect'
import { pathOr, prop } from 'ramda'

import { getConfig } from '@alphaott/app-main/selectors'

import type { AppModalsData } from '../reducers'

type Store = any

export const getWebAppBaseUrl = createSelector([getConfig], (config: any) =>
  pathOr('', ['ui', 'web', 'baseUrl'], config),
)

const getAppModalsData = (state: Store): AppModalsData => state.appModals.data

export const getAppExitModalOpened = createSelector(
  [getAppModalsData],
  (appModalsData: AppModalsData): boolean => prop('isAppExitModalOpened', appModalsData),
)
