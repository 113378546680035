// @flow

import React, { memo, useCallback } from 'react'

import {
  Container,
  ImageBackground,
  Header,
  Content,
  CastInfo,
  MediaContainer,
} from './DetailInfo.style'

import type { CastInfoProps } from './CastInfo'

export type DetailInfoProps = {
  className?: any,
  children: any,
  logo: string,
  logoHeight: number,
  backgroundSrc: string,
  cast: CastInfoProps,
}

const DetailInfoPure = ({
  className,
  children,
  logo,
  logoHeight,
  backgroundSrc,
  cast,
  onScrollTo,
}: DetailInfoProps) => {
  const handleBecameFocused = useCallback(() => {
    if (onScrollTo) onScrollTo(0)
  }, [onScrollTo])

  return (
    <Container className={className}>
      <ImageBackground src={backgroundSrc} />
      <Header logoSrc={logo} logoHeight={logoHeight} withBackground={false} />
      <Content>
        <CastInfo {...cast} onFocus={handleBecameFocused} />
        <MediaContainer>{children}</MediaContainer>
      </Content>
    </Container>
  )
}

export const DetailInfo = memo<DetailInfoProps>(DetailInfoPure)

export default DetailInfo
