// @flow

import { useSelector } from 'react-redux'
import { equals } from 'ramda'

import { getVisibilityStatus } from '@alphaott/smart-tv-current-device/selectors'
import { usePrevious } from '@alphaott/common-utils'

export const VISIBLE = 'visible'
export const HIDDEN = 'hidden'

interface Status {
  visibilityStatus: string;
  isVisible: boolean;
  isHidden: boolean;
}

interface UseNetworkStatusResult extends Status {
  prevStatus?: Status;
}

export const useVisibilityStatus = (): UseNetworkStatusResult => {
  const visibilityStatus = useSelector(getVisibilityStatus)
  const isVisible = equals(visibilityStatus, VISIBLE)
  const isHidden = equals(visibilityStatus, HIDDEN)

  const currentStatus: Status = {
    visibilityStatus,
    isVisible,
    isHidden,
  }
  const prevStatus = usePrevious(currentStatus)

  return {
    ...currentStatus,
    prevStatus,
  }
}

export default useVisibilityStatus
