// @flow

import { useMemo } from 'react'

import { usePlayerContext } from '../../store'

export const useTracks = (
  videoId: string,
  activeTab: string,
  onChangeAudioTrack: (track: Object) => void,
  onChangeSubtitleTrack: (track: Object) => void,
) => {
  const [playerState] = usePlayerContext()

  const tracks = useMemo(
    () =>
      ({
        audio: playerState.audioTracks,
        subtitles: playerState.subtitleTracks,
      }[activeTab]),
    [playerState.audioTracks, playerState.subtitleTracks, activeTab],
  )

  const onSelectTrack = useMemo(
    () =>
      ({
        audio: onChangeAudioTrack,
        subtitles: onChangeSubtitleTrack,
      }[activeTab]),
    [onChangeAudioTrack, onChangeSubtitleTrack, activeTab],
  )

  return {
    tracks,
    onSelectTrack,
  }
}

export default useTracks
