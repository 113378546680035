// @flow
// $FlowFixMe
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePinCodeModal } from '../usePinCodeModal'
import { pinCodeHandler } from './pinCodeHandler'

export type useRestrictedContentProps = {|
  isEnabledParentalControl: boolean,
  pinCode: string,
  defaultUnlocked?: boolean,
|}

// eslint-disable-next-line complexity
export const useRestrictedContent = ({
  isEnabledParentalControl,
  pinCode,
  defaultUnlocked = false,
}: useRestrictedContentProps) => {
  const {
    isVisiblePinCodeModal,
    handleOpenPinCodeModal,
    handleClosePinCodeModal,
    pinCodeModalSize,
    currentPinCodeModalSize,
    currentPinCodeModal,
    handleAddCharPinCodeModal,
    handleInputPinCodeModal,
    handleDeleteCharPinCodeModal,
    titlePinCodeModal,
    descriptionPinCodeModal,
    errorMessagePinCodeModal,
    headerTitlePinCodeModal,
    handleChangeTitlePinCodeModal,
  } = usePinCodeModal()

  const { t } = useTranslation()
  const pinCodeModalMeta = {
    pinCodeHeaderTitle: headerTitlePinCodeModal || t('enter_pin'),
    pinCodeTitle: titlePinCodeModal || t('enter_your_pin_code'),
    pinCodeDescription: descriptionPinCodeModal || t('please_enter_your_current_pin_code_to_proceed'),
    errorMessage: errorMessagePinCodeModal,
  }

  const [isUnlocked, setUnlocked] = useState(defaultUnlocked)

  const handleUnlock = useCallback(() => {
    setUnlocked(true)
  }, [setUnlocked])

  const sensitiveCall = useCallback(
    (handler: Function) => {
      pinCodeHandler(
        () => {
          if (isEnabledParentalControl) {
            setUnlocked(true)
          }
          handler && handler()
        },
        isEnabledParentalControl,
        handleOpenPinCodeModal,
        pinCode,
        t('wrong_pin_code_please_try_again'),
        isUnlocked,
      )
    },

    [t, isEnabledParentalControl, handleOpenPinCodeModal, pinCode, isUnlocked],
  )

  const handleLock = useCallback(() => {
    setUnlocked(false)
  }, [setUnlocked])

  return {
    isUnlocked,
    handleRestrictedUnlock: sensitiveCall,
    handleUnlock,
    handleLock,
    sensitiveCall,
    isVisiblePinCodeModal,
    handleOpenPinCodeModal,
    handleClosePinCodeModal,
    pinCodeModalSize,
    currentPinCodeModalSize,
    currentPinCodeModal,
    handleAddCharPinCodeModal,
    handleInputPinCodeModal,
    handleDeleteCharPinCodeModal,
    titlePinCodeModal,
    descriptionPinCodeModal,
    errorMessagePinCodeModal,
    headerTitlePinCodeModal,
    handleChangeTitlePinCodeModal,
    pinCodeModalMeta,
  }
}
