// @flow

import { useState, useCallback } from 'react'

export const useModalController = (defaultValue: boolean = false) => {
  const [isOpen, setOpen] = useState(defaultValue)

  const toggle = useCallback(() => setOpen((prevValue) => !prevValue), [])

  const show = useCallback(() => setOpen(true), [])

  const hide = useCallback(() => setOpen(false), [])

  return {
    isOpen,
    toggle,
    show,
    hide,
  }
}

export default useModalController
