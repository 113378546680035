// @flow
import React from 'react'

import { HeaderContainer, HeaderTitle } from './ChannelsScrollView.style'

type Props = { heading: string }

const Header = ({ heading }: Props) => (
  <HeaderContainer>
    <HeaderTitle>{heading}</HeaderTitle>
  </HeaderContainer>
)

export const PureHeader = React.memo(Header)
export default PureHeader
