// @flow

import { map, path, defaultTo } from 'ramda'

import { ENTITY_TYPE } from '@alphaott/smart-tv-utils'

type Item = any

export const channelsToItems = (channels: Array<Item>) =>
  map(
    ({ title, logos, _id: id, description, number, offset, hasCatchup, pgRating, tags }) => ({
      id,
      title,
      src: path([0, 'path'], logos),
      description,
      number,
      offset,
      hasCatchup,
      type: ENTITY_TYPE.LIVE_TV,
      pgRating,
      tags,
    }),
    defaultTo([], channels),
  )
