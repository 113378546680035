// @flow

import styled from 'styled-components'

import TabBase from './Tab'

export const Container = styled.div`
  width: 88rem;
`

export const Tab = styled(TabBase)``
