// @flow

import {
  equals,
  find,
  findIndex,
  has,
  head,
  isEmpty,
  isNil,
  map,
  not,
  pipe,
  prop,
  propEq,
  propOr,
  reject,
  includes,
  cond,
  complement,
} from 'ramda'

import type { OptionT, TipType } from '@alphaott/common-types'

import { getParam } from '../urlSearchParams'
import { FILTER_PRIORITY } from '../filterChannels'

export const getValue = (id: number, options: Array<OptionT>) => propOr('', 'value')(options[id])

type FilterData = Array<Object>
type FiltersProps = {
  items: Array<any>,
  selected: Array<string>,
  enabledOptions?: Array<string>,
}

export const onDefault = (option: Array<OptionT>, search: string): number => {
  const value = head(getParam(search, 'sort'))
  const index = findIndex(propEq('value', value))(option)
  return not(equals(index, -1)) ? index : 0
}

const getName = cond([
  [has('title'), prop('title')],
  [has('name'), prop('name')],
  [has('commonName'), prop('commonName')],
])

const getTip = (item: string, filterData: Array<any>, name: string) => {
  const filterItem: any = find(propEq('_id', item))(filterData)
  if (filterItem) {
    return {
      id: `${name}${filterItem._id}`,
      type: FILTER_PRIORITY[name],
      description: getName(filterItem),
      query: name,
      param: item,
    }
  }
  return null
}

export const getTips = (params: Array<string>, filterData: FilterData, name: string): Array<TipType> => {
  const buildTips = map(item => getTip(item, filterData, name))
  // $FlowFixMe
  return pipe(
    buildTips,
    reject(isNil),
  )(params)
}

export const prepareTips = (search: string, filterData: FilterData, filterName: string): Array<TipType> => {
  const params = getParam(search, filterName)
  if (isEmpty(params) || isEmpty(filterData)) return []
  return getTips(params, filterData, filterName)
}

export const prepareOptions = ({ items, selected, enabledOptions }: FiltersProps) =>
  map(item => ({
    ...item,
    name: getName(item),
    selected: includes(item._id, selected),
    disabled: not(includes(item._id, enabledOptions)),
  }))(items)

export const prepareMovieOptions = ({ items, selected }: FiltersProps) =>
  map(item => ({
    ...item,
    name: getName(item),
    selected: includes(item._id, selected),
    disabled: false,
  }))(items)

export const isFull = complement(isEmpty)
