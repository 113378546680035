// @flow
import React, { useMemo } from 'react'
import { not, isNil, path } from 'ramda'
import { useSelector } from 'react-redux'
import { AnalyticsProvider } from 'use-analytics'

import { getAppAnalyticsName, getAppAnalyticsConfig } from '@alphaott/app-main/selectors'

import { createAnalyticsInstance } from '../utils'

import { ANALYTICS_PLUGINS } from '../const'

type Props = {
  children: any,
}

export const AppAnalyticsProvider = ({ children }: Props) => {
  const analyticsName = useSelector(getAppAnalyticsName)
  const analyticsConfig = useSelector(getAppAnalyticsConfig)

  const plugin = useMemo(() => {
    const currentPlugin = path([analyticsName], ANALYTICS_PLUGINS)

    return currentPlugin(analyticsConfig)
  }, [analyticsName, analyticsConfig])

  const instance = useMemo(() => (not(isNil(plugin)) ? createAnalyticsInstance(plugin) : null), [plugin])

  if (isNil(instance)) {
    return children
  }

  return <AnalyticsProvider instance={instance}>{children}</AnalyticsProvider>
}
