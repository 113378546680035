// @flow

import React, { memo } from 'react'
// import { useTranslation } from 'react-i18next'

import { Customer } from '@alphaott/api-client/types/customer'

import { useCustomerName } from '../../../hooks'
import {
  Container,
  AvatarContainer,
  Initials,
  Info,
  Name,
  Actions,
  // Notifications,
  // NotificationsIconsContainer,
  // Profiles,
  // Title,
  // CircleIcon,
} from './Profile.styles'
import { AccountButton } from '../AccountButton'
// import { BellIcon, ProfilesIcon } from '../../assets'
import { UserIcon } from '../../assets'

type ProfileProps = {
  customer: Customer,
  onAccount: Function,
}

const ProfilePure = ({ customer, onAccount }: ProfileProps) => {
  // const { t } = useTranslation()

  const { name, initials } = useCustomerName(customer)

  return (
    <Container>
      <AvatarContainer>{initials ? <Initials>{initials}</Initials> : <UserIcon />}</AvatarContainer>
      <Info>
        <Name>{name}</Name>
        {/* TODO: to complete after the implementation of the profiles */}
        <Actions>
          {/* <Notifications>
            <NotificationsIconsContainer>
              <BellIcon />
              <CircleIcon />
            </NotificationsIconsContainer>
            <Title>{t('mwstv_notifications')}</Title>
          </Notifications>
          <Profiles>
            <ProfilesIcon />
          </Profiles> */}
          <AccountButton onClick={onAccount} />
        </Actions>
      </Info>
    </Container>
  )
}

export const Profile = memo<ProfileProps>(ProfilePure)

export default Profile
