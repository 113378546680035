// @flow
import React, { memo, useRef, useCallback, useMemo, useEffect } from 'react'
import { addDays, subDays, format } from 'date-fns'
import { multiply, equals, findIndex, prop, propEq } from 'ramda'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'
import { scale } from '@alphaott/smart-tv-utils'
import { DATE_FORMAT } from '@alphaott/app-programs/const'

import {
  Container,
  ChevronLeftIcon,
  ChevronRightIcon,
  ListContainer,
  StyledList,
  DatePickerDivider,
} from './DatePicker.style'
import { DatePickerItem, DATE_PICKER_ITEM_WIDTH } from '../DatePickerItem'

type DatePickerProps = {
  focusKey?: string,
  focusable?: boolean,
  selectedDate: Date,
  isChannelSwitching: Function,
  daysAfter?: number,
  daysBefore?: number,
  onPressItem: Function,
  onFocusItem: Function,
}

const today = new Date()
const DIVIDER_WIDTH: number = 2

const getDaysArray = (todayDate, daysBefore, daysAfter) => {
  const start = subDays(todayDate, daysBefore)
  const end = addDays(todayDate, daysAfter)
  const arr = []
  for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    const date = new Date(dt)
    const item = {
      date: format(date, DATE_FORMAT),
      id: format(date, DATE_FORMAT),
    }
    arr.push(item)
  }
  return arr
}

const DatePickerPure = ({
  focusKey: parentFocusKey,
  focusable,
  selectedDate,
  isChannelSwitching,
  daysAfter = 7,
  daysBefore = 30,
  onPressItem,
  onFocusItem,
}: DatePickerProps) => {
  const { ref, focusKey } = useFocusable({
    focusKey: parentFocusKey,
    focusable,
    saveLastFocusedChild: false,
    preferredChildFocusKey: selectedDate,
  })
  const listRef = useRef(null)

  const items = useMemo(() => getDaysArray(today, daysBefore, daysAfter), [daysBefore, daysAfter])

  const SCALED_DATE_ITEM_WIDTH = useMemo(
    () => scale(window.innerWidth, DATE_PICKER_ITEM_WIDTH + DIVIDER_WIDTH),
    [],
  )

  const focusedItemIndex = useMemo(
    () => findIndex(propEq('date', selectedDate))(items),
    [items, selectedDate],
  )

  const initialScrollOffset = useMemo(
    () => multiply(SCALED_DATE_ITEM_WIDTH, focusedItemIndex),
    [SCALED_DATE_ITEM_WIDTH, focusedItemIndex],
  )

  const renderItem = useCallback(
    ({ item }) => {
      const date = prop('date', item)
      const onFocus = prop('onBecameFocused', item)
      const isSelected = equals(selectedDate, date)

      const handlePressItem = () => onPressItem(date)

      return (
        <DatePickerItem
          {...item}
          focusKey={date}
          isSelected={isSelected}
          onFocus={onFocus}
          onPress={handlePressItem}
        />
      )
    },
    [onPressItem, selectedDate],
  )

  const renderDivider = useCallback(
    ({ style }) => <DatePickerDivider style={{ ...style, height: '60rem' }} />,
    [],
  )

  useEffect(() => {
    if (isChannelSwitching) {
      listRef.current.scrollTo(initialScrollOffset)
    }
  }, [focusedItemIndex, initialScrollOffset, isChannelSwitching])

  return (
    <FocusContext.Provider value={focusKey}>
      <Container ref={ref}>
        <ChevronLeftIcon />
        <ListContainer>
          <StyledList
            ref={listRef}
            scrollStrategy="auto"
            initialScrollOffset={initialScrollOffset}
            items={items}
            dividerSize={DIVIDER_WIDTH}
            renderItem={renderItem}
            renderDivider={renderDivider}
            onFocusItem={onFocusItem}
          />
        </ListContainer>
        <ChevronRightIcon />
      </Container>
    </FocusContext.Provider>
  )
}

export const DatePicker = memo<DatePickerProps>(DatePickerPure)

export default DatePicker
