// @flow
import { map, path, prop, propOr, defaultTo, cond, either, equals, always, T, pathOr, pipe, sort, has } from 'ramda'

import type { Promo, Options, Action } from '@alphaott/api-client/types/promo'

import { getAppTheme } from '@alphaott/app-theme'
import { prepareLogos } from '../../prepareLogos'

export const prepareAction = (action: Action, themeName: string, themeVariation: string): Action => {
  const theme = getAppTheme(themeName, themeVariation)
  const buttonColor = path(['button', 'color'], action)
  const buttonBackgroundColor = path(['button', 'backgroundColor'], action)

  const text = pathOr('watch it now', ['button', 'text'], action)
  const color = defaultTo(theme.text.primaryContrast)(buttonColor)
  const bg = defaultTo(theme.colors.primary)(buttonBackgroundColor)
  return {
    ...action,
    button: {
      text,
      color,
      backgroundColor: bg,
    },
  }
}

const prepareDescriptionColor = description => propOr('white', 'color', description)

export const preparePromoOptions = (
  options: Options = {},
  isDescription: boolean,
  themeName: string,
  themeVariation: string,
): Options | {} => {
  const result = options

  const action = prop('action', options)
  if (action) {
    result.action = prepareAction(action, themeName, themeVariation)
  }

  if (isDescription) {
    result.description = { color: prepareDescriptionColor(options.description) }
  }

  return result
}

export const preparePromoType = (type: string) =>
  cond([
    [either(equals('NAVIGATE'), equals('navigate')), always('NAVIGATE')],
    [either(equals('TV_CHANNEL'), equals('tv.channel')), always('TV_CHANNEL')],
    [either(equals('VOD_MOVIE'), equals('vod.movie')), always('VOD_MOVIE')],
    [either(equals('VOD_TVSHOW'), equals('vod.tvshow')), always('VOD_TVSHOW')],
    [either(equals('VOD_SEASON'), equals('vod.season')), always('VOD_SEASON')],
    [either(equals('VOD_EPISODE'), equals('vod.episode')), always('VOD_EPISODE')],
    [either(equals('PROMO_DETAILS'), equals('promo.details')), always('PROMO_DETAILS')],
    [either(equals('PROMO_CLIP'), equals('promo.clip')), always('PROMO_CLIP')],
    [either(equals('RADIO_CHANNEL'), equals('radio.channel')), always('RADIO_CHANNEL')],
    [T, always(undefined)],
  ])(type)

export const preparePromos = (
  domain: string,
  promos: Array<Promo>,
  themeName: string,
  themeVariation: string,
): Array<Promo> =>
  pipe(
    map(promo => ({
      ...promo,
      images: prepareLogos(promo.images, domain),
      backgrounds: prepareLogos(promo.backgrounds, domain),
      options: preparePromoOptions(prop('options', promo), has('description', promo), themeName, themeVariation),
      type: preparePromoType(promo.type),
    })),
    sort((a, b) => a.order - b.order || Math.random() - 0.5),
  )(promos)
