// @flow

import { createSelector } from 'reselect'
import {
  map,
  path,
  prop,
  values,
  pipe,
  find,
  propEq,
  head,
  isNil,
  not,
  reject,
  isEmpty,
  pathOr,
  or,
  ifElse,
  always,
} from 'ramda'

import type {
  Movie,
  Movies,
  MovieMediaStream,
  MovieMediaStreams,
  TrailerMediaStream,
} from '@alphaott/api-client/types/movies'

import { prepareMovie } from '@alphaott/common-utils/utils/schemas/movies'
import { defaultToEmptyArray, defaultToEmptyObject, isInitialLoading, isTrue } from '@alphaott/common-utils/utils/help'

import type { PreparedMovie } from '@alphaott/common-utils/utils/schemas/movies'
import type {
  CategoriesNormalize,
  CountriesNormalize,
  GenresNormalize,
  LanguageNormalize,
} from '@alphaott/common-types/normalized'

import { getMoviesMaxRating, getLocationHash } from '@alphaott/app-main/selectors'

import type { MovieState } from '../reducers/movie'
import type { MoviesListState, FavoriteMoviesIdsState, FavoriteMoviesState, MovieLimitState } from '../reducers'
import type { RecentMovieState, MovieProgress } from '../reducers/recent'

import { isTrailerHash } from '../../utils'

import type { MoviesStore } from '../types'

export const getMoviesListState = (state: MoviesStore): MoviesListState => state.moviesList
export const isOpenMovieFilter = (state: MoviesStore): boolean => state.moviesList.isOpenFilter

export const getMoviesListData = (state: MoviesStore): Movies => state.moviesList.data
export const getMoviesListHasMore = (state: MoviesStore): boolean => state.moviesList.hasMore

export const getMovieState = (state: MoviesStore): MovieState => state.movie
export const getMovieData = (state: MoviesStore): Movie => state.movie.data
export const getRelatedMovie = (state: MoviesStore): Movies => state.movie.related.data
export const getRecentMovieState = (state: MoviesStore): RecentMovieState => state.movieRecent
export const getRecentMovieIsLoading = (state: MoviesStore): boolean => state.movieRecent.isLoading
export const getRecentMovieIsSuccess = (state: MoviesStore): boolean => state.movieRecent.isSuccess
export const getFavoriteState = (state: MoviesStore): FavoriteMoviesState => state.movieFavorites
export const getFavoriteStateIsLoading = (state: MoviesStore): boolean => state.movieFavorites.isLoading
export const getFavoriteIdsState = (state: MoviesStore): FavoriteMoviesIdsState => state.movieFavoriteIds
export const getFavoriteMoviesIds = (state: MoviesStore): string[] => state.movieFavoriteIds.data

export const getGenresEntities = (state: MoviesStore): GenresNormalize => state.movieGenres.entities
export const getCategoriesEntities = (state: MoviesStore): CategoriesNormalize => state.movieCategories.entities
export const getLanguagesEntities = (state: MoviesStore): LanguageNormalize => state.movieLanguages.entities
export const getCountriesEntities = (state: MoviesStore): CountriesNormalize => state.movieCountries.entities

export const getRecentProgress = (state: MoviesStore): Array<MovieProgress> => state.movieRecentProgress.data

export const getMovieLimits = (state: MoviesStore): MovieLimitState => state.movieLimits
export const getMovieLimitsError = (state: MoviesStore): any => state.movieLimits.errors

const getPopularMovies = (state: MoviesStore): Movies => state.moviePopular.data
export const isPopularMoviesLoading = (state: MoviesStore): boolean => isInitialLoading(state.moviePopular)

export const isFavoriteMovie = (movieId: string) =>
  createSelector(
    [getFavoriteMoviesIds],
    (favorite: FavoriteMoviesIdsState): boolean =>
      pipe(
        find(propEq('item', movieId)),
        isNil,
        not,
      )(favorite),
  )

export const getMovie = createSelector(
  [
    getMovieState,
    getGenresEntities,
    getCategoriesEntities,
    getLanguagesEntities,
    getCountriesEntities,
    getFavoriteIdsState,
    getMoviesMaxRating,
  ],
  (
    movie: MovieState,
    genres: GenresNormalize,
    categories: CategoriesNormalize,
    languages: LanguageNormalize,
    countries: CountriesNormalize,
    favorite: FavoriteMoviesIdsState,
    maxRating: number,
  ): PreparedMovie => prepareMovie(movie.data, genres, categories, languages, countries, favorite.data, maxRating),
)

export const getSources = createSelector(
  [getMovieState],
  (movie: MovieState): MovieMediaStreams => defaultToEmptyArray(path(['sources', 'data'], movie)),
)

export const getMediaStreamIsLoading = createSelector(
  [getMovieState],
  (movie: MovieState): boolean => defaultToEmptyArray(path(['sources', 'isLoading'], movie)),
)

// TODO: Get appropriate mediaStream type
export const getMediaStream = createSelector(
  [getSources],
  (movieMediaStreams: MovieMediaStreams): MovieMediaStream | {} =>
    pipe(
      head,
      defaultToEmptyObject,
    )(movieMediaStreams),
)

export const getTrailerMediaStream = createSelector(
  [getMovieState],
  (movie: MovieState): TrailerMediaStream | {} => defaultToEmptyObject(path(['trailer', 'data'], movie)),
)

export const isMovieLoading = createSelector(
  [getMovieState],
  (movie: MovieState): boolean => isInitialLoading(movie),
)

export const isMoviesEmpty = createSelector(
  [getMoviesListState],
  (movie: MoviesListState): boolean => isInitialLoading(movie),
)

export const getMovieError = createSelector(
  [getMovieState],
  (movie: MovieState): string | null => pathOr(false, ['sources', 'isError'], movie),
)

export const getMovieTrailerError = createSelector(
  [getMovieState],
  (movie: MovieState): string | null => pathOr(false, ['trailer', 'isError'], movie),
)

export const isMediaOrTrailerError = createSelector(
  [getMovieError, getMovieTrailerError],
  (isMovieError: boolean, isMovieTrailerError: boolean): boolean => or(isMovieError, isMovieTrailerError),
)

export const getMovieStreamError = createSelector(
  [getMovieState, isMediaOrTrailerError, getLocationHash],
  (movie: MovieState, isError: boolean, hash: string): string | null =>
    ifElse(
      isTrue,
      always(
        ifElse(
          isTrailerHash,
          always(pathOr('ERROR 404', ['trailer', 'errors', 'message'], movie)),
          always(pathOr('ERROR 404', ['sources', 'errors', 'message'], movie)),
        )(hash),
      ),
      always(null),
    )(isError),
)

export const getRelated = createSelector(
  [
    getRelatedMovie,
    getGenresEntities,
    getCategoriesEntities,
    getLanguagesEntities,
    getCountriesEntities,
    getFavoriteIdsState,
    getMoviesMaxRating,
    getRecentProgress,
  ],
  (
    movies: Movies,
    genres: GenresNormalize,
    categories: CategoriesNormalize,
    languages: LanguageNormalize,
    countries: CountriesNormalize,
    favorite: FavoriteMoviesIdsState,
    maxRating: number,
    recent: Array<MovieProgress>,
  ): Array<PreparedMovie> =>
    map(
      item =>
        prepareMovie(
          item,
          genres,
          categories,
          languages,
          countries,
          favorite.data,
          maxRating,
          defaultToEmptyObject(find(propEq('id', item._id))(recent)),
        ),
      movies,
    ),
)

export const getPopular = createSelector(
  [
    getPopularMovies,
    getGenresEntities,
    getCategoriesEntities,
    getLanguagesEntities,
    getCountriesEntities,
    getFavoriteIdsState,
    getMoviesMaxRating,
    getRecentProgress,
  ],
  (
    movies: Movies,
    genres: GenresNormalize,
    categories: CategoriesNormalize,
    languages: LanguageNormalize,
    countries: CountriesNormalize,
    favorite: FavoriteMoviesIdsState,
    maxRating: number,
    recent: Array<MovieProgress>,
  ): Array<PreparedMovie> =>
    map(
      item =>
        prepareMovie(
          item,
          genres,
          categories,
          languages,
          countries,
          favorite.data,
          maxRating,
          defaultToEmptyObject(find(propEq('id', item._id))(recent)),
        ),
      movies,
    ),
)

export const getMovies = createSelector(
  [
    getMoviesListData,
    getGenresEntities,
    getCategoriesEntities,
    getLanguagesEntities,
    getCountriesEntities,
    getFavoriteIdsState,
    getMoviesMaxRating,
    getRecentProgress,
  ],
  (
    movies: Movies,
    genres: GenresNormalize,
    categories: CategoriesNormalize,
    languages: LanguageNormalize,
    countries: CountriesNormalize,
    favorite: FavoriteMoviesIdsState,
    maxRating: number,
    recent: Array<MovieProgress>,
  ): Array<PreparedMovie> =>
    map(
      item =>
        prepareMovie(
          item,
          genres,
          categories,
          languages,
          countries,
          favorite.data,
          maxRating,
          defaultToEmptyObject(find(propEq('id', item._id))(recent)),
        ),
      movies,
    ),
)

export const getRecentMovies = createSelector(
  [
    getRecentMovieState,
    getGenresEntities,
    getCategoriesEntities,
    getLanguagesEntities,
    getCountriesEntities,
    getFavoriteIdsState,
    getMoviesMaxRating,
    getRecentProgress,
  ],
  (
    moviesState: RecentMovieState,
    genres: GenresNormalize,
    categories: CategoriesNormalize,
    languages: LanguageNormalize,
    countries: CountriesNormalize,
    favorite: FavoriteMoviesIdsState,
    maxRating: number,
    recent: Array<MovieProgress>,
  ): Array<PreparedMovie> =>
    reject(
      isEmpty,
      map(
        item =>
          prepareMovie(
            defaultToEmptyObject(find(propEq('_id', item.id))(moviesState.data)),
            genres,
            categories,
            languages,
            countries,
            favorite.data,
            maxRating,
            item,
          ),
        recent,
      ),
    ),
)

export const isRecentMoviesLoading = createSelector(
  [getRecentMovieState],
  (recent: RecentMovieState): boolean => isInitialLoading(recent),
)

export const getCategories = createSelector(
  [getCategoriesEntities],
  (categories: CategoriesNormalize): Array<string> => map(prop('title'), values(categories)),
)

export const getCountries = createSelector(
  [getCountriesEntities],
  (countries: CountriesNormalize): Array<string> => map(prop('commonName'), values(countries)),
)

export const getGenres = createSelector(
  [getGenresEntities],
  (genres: GenresNormalize): Array<string> => map(prop('title'), values(genres)),
)

export const getLanguages = createSelector(
  [getLanguagesEntities],
  (languages: LanguageNormalize): Array<string> => map(prop('name'), values(languages)),
)

export const getVideoProgress = (id: string) =>
  createSelector(
    [getRecentProgress],
    (recent: Array<MovieProgress>): number =>
      pipe(
        find(propEq('id', id)),
        defaultToEmptyObject,
      )(recent),
  )

export const getRecentIds = createSelector(
  [getRecentProgress],
  (recent: Array<MovieProgress>): Array<string> => map(prop('id'), recent),
)

export const isRecentMovie = (id: string) =>
  createSelector(
    [getRecentMovieState],
    (recent: RecentMovieState): boolean =>
      pipe(
        find(propEq('_id', id)),
        isNil,
        not,
      )(recent.data),
  )
