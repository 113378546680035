// @flow

import { equals } from 'ramda'

import { isOldPlatform } from '@alphaott/smart-tv-platforms'
import { VIDEO_PLAYERS } from '@alphaott/app-config'

export const isShakaPlayer = (videoPlayer: string): boolean =>
  equals(VIDEO_PLAYERS.SHAKA_PLAYER, videoPlayer)

export const isShakaPlayer3 = (videoPlayer: string): boolean =>
  equals(VIDEO_PLAYERS.SHAKA_PLAYER, videoPlayer) && isOldPlatform

export const isVideoJSPlayer = (videoPlayer: string): boolean =>
  equals(VIDEO_PLAYERS.VIDEO_JS, videoPlayer)
