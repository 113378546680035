// @flow
import { path } from 'ramda'

import { getMoviesGenres } from '@alphaott/api-client'
import { getPublicDomain, getCacheChannels, isCacheValid } from '@alphaott/app-main/selectors'

export const FETCH_MOVIE_GENRES_TYPE = 'movies_genres/FETCH_GENRES'

const fetchMovieGenresAction = (servicespec: string) => (dispatch: Function, getState: Function) => {
  const state = getState()
  const publicApi = getPublicDomain(state)
  const ttl = getCacheChannels(state)

  return dispatch({
    type: FETCH_MOVIE_GENRES_TYPE,
    payload: getMoviesGenres(publicApi, servicespec),
    meta: { ttl },
  })
}

// eslint-disable-next-line complexity
export const fetchMovieGenres = (servicespec: string, force: ?boolean = false) => (
  dispatch: Function,
  getState: Function,
) => {
  if (force) return dispatch(fetchMovieGenresAction(servicespec))

  const state = getState()
  const expires = path(['movieGenres', 'expires'], state)

  if (expires && isCacheValid(expires)) return null

  return dispatch(fetchMovieGenresAction(servicespec))
}
