// @flow
import { and, not } from 'ramda'
import { PLATFORMS } from '../const'

export const getDeviceType = () => {
  const ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('tizen') > -1) {
    return PLATFORMS.TIZEN
  }
  if (ua.indexOf('web0s') > -1) {
    return PLATFORMS.WEBOS
  }
  return PLATFORMS.WEB
}

const userAgentContains = (key) => {
  const userAgent = navigator.userAgent || ''
  return userAgent.includes(key)
}

export const isTizen: boolean = userAgentContains('Tizen')
export const isTizen2: boolean = userAgentContains('Tizen 2')
export const isTizen3: boolean = userAgentContains('Tizen 3')
export const isTizen4: boolean = userAgentContains('Tizen 4')

export const isWebOS: boolean = userAgentContains('Web0S')
// See: http://webostv.developer.lge.com/discover/specifications/web-engine/
export const isWebOS3: boolean = and(
  userAgentContains('Web0S'),
  userAgentContains('Chrome/38.0.2125.122 Safari/537.36'),
)

export const isWeb = and(not(isTizen), not(isWebOS))

export const isOldPlatform = isTizen2 || isTizen3 || isWebOS3
