// @flow
import { createSelector } from 'reselect'
import { prop } from 'ramda'

import { defaultToEmptyObject, defaultToEmptyString } from '@alphaott/common-utils/utils/help'
import type { Routing } from '@alphaott/common-types'

import type { MainStore } from '../types'

/**
 * Routing store
 */
export const getRouting = (state: MainStore): Routing => state.router

export const getRoutingLocation = createSelector(
  [getRouting],
  (routing: Routing): Object => defaultToEmptyObject(prop('location', routing)),
)

export const getRoutingSearch = createSelector(
  [getRoutingLocation],
  (location: Object): string => defaultToEmptyString(prop('search', location)),
)

export const getRoutingPathname = createSelector(
  [getRoutingLocation],
  (location: Object): string => defaultToEmptyString(prop('pathname', location)),
)

export const getLocationHash = createSelector(
  [getRoutingLocation],
  (location: Object): string => prop('hash', location),
)
