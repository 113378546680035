// @flow

import { useCallback } from 'react'
import { equals, inc, dec, path, prop, and, not, length, head } from 'ramda'

type onArrowPressProps = {
  setFocus: Function,
  direction: string,
  columnIndex: number,
  itemIndex: number,
  items: Array<Object>,
  itemsPerRow: number,
  withSideMenu: boolean,
}

export const useArrowPress = () => {
  const onArrowPress = useCallback(
    // eslint-disable-next-line complexity
    ({
      setFocus,
      direction,
      columnIndex,
      itemIndex,
      items,
      itemsPerRow,
      withSideMenu,
      listRef, // eslint-disable-next-line consistent-return
    }: onArrowPressProps) => {
      const isRightDirection = equals(direction, 'right')
      const isLastItemRow = equals(inc(columnIndex), itemsPerRow)
      const nextFocusKey = path([inc(itemIndex), 'id'], items)
      const isLastItemList = equals(itemIndex, dec(length(items)))
      const firstFocusKey = prop('id', head(items))

      const isLeftDirection = equals(direction, 'left')
      const isFirstItem = equals(columnIndex, 0)
      const prevFocusKey = prop('id', items[dec(itemIndex)])

      if (isRightDirection && isLastItemList && setFocus && listRef && firstFocusKey) {
        listRef.scrollTo({ scrollTop: 0 })
        setFocus(firstFocusKey)
        return false
      }

      if (and(and(isRightDirection, isLastItemRow), and(setFocus, nextFocusKey))) {
        setFocus(nextFocusKey)
        return false
      }

      if (and(and(isLeftDirection, isFirstItem), and(setFocus, prevFocusKey))) {
        if (not(withSideMenu)) {
          setFocus(prevFocusKey)
          return false
        }
      }
    },
    [],
  )

  return { onArrowPress }
}
