// @flow

export const CHANGE_PLAYER_STATE_TYPE = 'playerState/CHANGE_PLAYER_STATE_TYPE'
export const CLEAR_PLAYER_ERRORS_TYPE = 'playerState/CLEAR_PLAYER_ERRORS_TYPE'
export const RESET_PLAYER_STATE_TYPE = 'playerState/RESET_PLAYER_STATE_TYPE'

export const CHANGE_CONTENT_META_DATA_TYPE = 'playerProgress/CHANGE_CONTENT_META_DATA_TYPE'
export const CHANGE_PLAYER_PROGRESS_TYPE = 'playerProgress/CHANGE_PLAYER_PROGRESS_TYPE'

export const CHANGE_PLAYER_CONTROLS_STATE_TYPE = 'playerControls/CHANGE_PLAYER_CONTROLS_STATE_TYPE'

export const CHANGE_PLAYER_TRACKS_STATE_TYPE = 'playerTracks/CHANGE_PLAYER_TRACKS_STATE_TYPE'
export const RESET_PLAYER_TRACKS_STATE_TYPE = 'playerTracks/RESET_PLAYER_TRACKS_STATE_TYPE'
