// @flow

import qs from 'qs'
import {
  pipe,
  propOr,
  prop,
  cond,
  flatten,
  type,
  equals,
  reject,
  dissoc,
  append,
  always,
  isNil,
  T,
  uniq,
  either,
  isEmpty,
  test,
} from 'ramda'

export type UpdateSearchParams = {|
  url: string,
  oldParam: string,
  newParam: string,
  newValue: string,
|}

const toArray = (a: any): Array<any> => [a]

export const parse = (url: string): Object => qs.parse(url, { ignoreQueryPrefix: true })

export const stringify = (arg: any): string => qs.stringify(arg, { arrayFormat: 'repeat', addQueryPrefix: true })

export const isURL = test(/^(ftp|http|https):\/\/[^ "]+$/)

export const getParam = (url: string, query: string): Array<any> =>
  /**
   * parse =>
   *  { a: 'b', c: 'd' } || { a: ['b', 'c'] } || {}
   * propOr =>
   *  'b' || ['b', 'c'] || []
   * toArray =>
   *  ['b'] || [['b', 'c']] || [[]]
   * flatten =>
   *  ['b'] || ['b', 'c'] || []
   */
  pipe(
    propOr([], query),
    toArray,
    flatten,
  )(parse(url))

export const deleteParam = (url: string, param: string, value: string) => {
  /**
   * url = '?a=b&c=d' || '?a=b&a=c' || ''
   * params = { a: 'b', c: 'd' } || { a: ['b', 'c'] } || {}
   * values = 'b' || ['b', 'c'] || undefined
   */
  let params = parse(url)
  const values = prop(param, params)
  /**
   * cond =>
   * (values === ['b', 'c']) => return { ...param, { a: 'b' } }
   * (values === 'b' || undefined) => return { c: 'd' } || {}
   */

  params = cond([
    [
      pipe(
        type,
        equals('Array'),
      ),
      arg => ({ ...params, [param]: reject(equals(value), arg) }),
    ],
    [T, always(dissoc(param, params))],
  ])(values)

  return stringify(params)
}

export const addParam = (url: string, param: string, value: string): string => {
  if (either(isEmpty, isNil)(param) || either(isEmpty, isNil)(value)) return url
  /**
   * url = '?a=b&c=d' || '?a=b&a=c' || ''
   * params = { a: 'b', c: 'd' } || { a: ['b', 'c'] } || {}
   * values = 'b' || ['b', 'c'] || undefined
   */
  const params = parse(url)
  const values = prop(param, params)
  /**
   * cond =>
   * (values === undefined) => return { param : value }
   * (values === ['b', 'c']) => return { param : ['b', 'c', 'value'] }
   * (values === 'b') => return { param : ['b', 'value'] }
   */

  const extra = cond([
    [isNil, always({ [param]: value })],
    [
      pipe(
        type,
        equals('Array'),
      ),
      always({ [param]: uniq(append(value, values)) }),
    ],
    [
      pipe(
        type,
        equals('String'),
      ),
      always({ [param]: uniq(append(value, toArray(values))) }),
    ],
  ])(values)

  return stringify({ ...params, ...extra })
}
