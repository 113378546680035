// @flow
export const WELCOME_MODAL = 'app/WELCOME_MODAL'

export type WelcomeModalAction = {|
  type: 'app/WELCOME_MODAL',
  shouldOpenDemoWelcomeScreen: boolean,
|}

export const openDemoWelcomeScreen = (shouldOpenDemoWelcomeScreen: boolean = true): WelcomeModalAction => ({
  type: WELCOME_MODAL,
  shouldOpenDemoWelcomeScreen,
})
