// @flow
import React, { memo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Field, reduxForm } from 'redux-form'
import { pipe } from 'ramda'

import type { FormProps } from 'redux-form'

import { useFocusable, FocusContext } from '@alphaott/smart-tv-spatial-navigation'
import { CodeInput, SINGLE_CODE_INPUT_FOCUS_KEY } from '@alphaott/smart-tv-components'
import { BUTTON_KEY_NAMES } from '@alphaott/smart-tv-platforms'

import {
  PageContainer,
  Header,
  Title,
  Subtitle,
  MainContainer,
  Error,
  FormContent,
  StyledButton,
} from './EnterPinView.styles'

import { INIT_FORM_CONFIG, PIN_FIELD } from './constants'

type EnterPinViewProps = {
  className?: string,
  subTitle?: string,
  inputNumber?: number,
  errorMessage?: string,
  initialValues?: any,
  reset: Function,
  handleSubmit: Function,
  onSubmit: Function,
  onCancel: Function,
}

const EnterPinViewPure = ({
  className,
  subTitle,
  inputNumber = 4,
  errorMessage,
  initialValues,
  reset,
  handleSubmit,
  onSubmit,
  onCancel,
}: EnterPinViewProps & FormProps) => {
  const { ref, focusKey: modalFocusKey, setFocus } = useFocusable({ isFocusBoundary: true })
  const { t } = useTranslation()

  const handleSubmitForm = useCallback(
    (values) => {
      onSubmit({ ...initialValues, ...values })
      reset()
    },
    [initialValues, onSubmit, reset],
  )

  const handleCancel = useCallback(() => {
    onCancel((focusKey) => setFocus(focusKey))
  }, [setFocus, onCancel])

  const handleBackPress = useCallback(
    (event) => {
      handleCancel()
      event.stopPropagation()
    },
    [handleCancel],
  )

  useEffect(() => {
    if (setFocus) setFocus(`${SINGLE_CODE_INPUT_FOCUS_KEY}_0`)
  }, [setFocus, subTitle])

  useEffect(() => {
    window?.addEventListener(BUTTON_KEY_NAMES.BACK, handleBackPress, true)
    return () => window?.removeEventListener(BUTTON_KEY_NAMES.BACK, handleBackPress, true)
  }, [handleBackPress])

  return (
    <FocusContext.Provider value={modalFocusKey}>
      <PageContainer className={className} ref={ref}>
        <Header>
          <Title>{t('enter_pin')}</Title>

          {subTitle && <Subtitle>{subTitle}</Subtitle>}
        </Header>
        <MainContainer>
          {errorMessage && <Error title={errorMessage} />}

          <Form onSubmit={handleSubmit(handleSubmitForm)}>
            <FormContent>
              <StyledButton onClick={handleCancel}>{t('cancel')}</StyledButton>
              <Field name={PIN_FIELD} inputNumber={inputNumber} component={CodeInput} />
              <StyledButton onClick={handleSubmit(handleSubmitForm)} submitting>
                {t('done')}
              </StyledButton>
            </FormContent>
          </Form>
        </MainContainer>
      </PageContainer>
    </FocusContext.Provider>
  )
}

export const EnterPinView = pipe(
  memo,
  reduxForm({
    ...INIT_FORM_CONFIG,
    // Todo: Temporary solution, @Mikhail_Ivannikov will change this in future
    onChange: (_, dispatch, { error, clearSubmitErrors }) => {
      if (error) {
        dispatch(clearSubmitErrors(INIT_FORM_CONFIG.form))
      }
    },
  }),
)(EnterPinViewPure)

export default EnterPinView
