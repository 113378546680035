// @flow

import { createSelector } from 'reselect'
import { prop, propOr } from 'ramda'

import type { PlayerStore } from '../types'
import type { PlayerTracksState } from '../reducers'

export const getPlayerTracksState = (state: PlayerStore): PlayerTracksState => state.playerTracks

export const getAudioTracks = createSelector(
  [getPlayerTracksState],
  (playerTracks: PlayerTracksState): Object[] => propOr([], 'audioTracks', playerTracks),
)

export const getSubtitlesTracks = createSelector(
  [getPlayerTracksState],
  (playerTracks: PlayerTracksState): Object[] => propOr([], 'subtitlesTracks', playerTracks),
)

export const getCurrentAudioTrack = createSelector(
  [getPlayerTracksState],
  (playerTracks: PlayerTracksState): Object => prop('currentAudioTrack', playerTracks),
)

export const getCurrentSubtitleTrack = createSelector(
  [getPlayerTracksState],
  (playerTracks: PlayerTracksState): Object => prop('currentSubtitleTrack', playerTracks),
)
