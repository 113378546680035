// @flow
import { schema } from 'normalizr'
import { isEmpty, map, prop, reject } from 'ramda'

import type { CountriesNormalize } from '@alphaott/common-types/normalized'

import { defaultToEmptyString } from '../../help'

const idAttribute = '_id'

export const countriesSchema = new schema.Entity('countries', {}, { idAttribute })
export const countriesListSchema = new schema.Array(countriesSchema)

export const prepareCountriesById = (arrIds: Array<string>, arrValues: CountriesNormalize): Array<string> =>
  reject(isEmpty, map(id => defaultToEmptyString(prop('commonName', arrValues[id])))(arrIds))

export default countriesListSchema
