// @flow

import styled from 'styled-components'
import { getTextColorPrimary, getFontSize } from '@alphaott/smart-tv-themes'

export const Text = styled.span`
  color: ${getTextColorPrimary};
  font-size: ${getFontSize}rem;
`

export default Text
