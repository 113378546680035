// @flow

import { useCallback } from 'react'
import { pick } from 'ramda'

// ShakaPlayer error codes
// const BAD_HTTP_STATUS = 1001
// const HTTP_ERROR = 1002
// const TIMEOUT = 1003
const LOAD_INTERRUPTED = 7000

const IGNORED_ERROR_CODES = [LOAD_INTERRUPTED]
// const SENTRY_IGNORED_LIST_CODES = [BAD_HTTP_STATUS, HTTP_ERROR, TIMEOUT]
const ERROR_FIELDS = ['category', 'code', 'data', 'message', 'severity']

export const useShakaPlayerErrors = (source) => {
  const isIgnoredError = useCallback(
    (error: Object) => IGNORED_ERROR_CODES.includes(error.code),
    [],
  )

  // const isNeedToCaptureException = useCallback((error: Object) => {
  //   const isExcludedError = includes(prop('code', error), SENTRY_IGNORED_LIST_CODES)
  //
  //   // if (isExcludedError) {
  //   //   const isCriticalError = propEq('severity', SEVERITY.CRITICAL, error)
  //   //
  //   //   if (not(isCriticalError)) return false
  //   // }
  //
  //   return not(isExcludedError)
  // }, [])

  const handlePrepareSentryError = useCallback((error: Object) => pick(ERROR_FIELDS, error), [])

  const handlePrepareError = useCallback(
    (err: shaka.util.Error | Object) => {
      const error = err?.detail || err

      return {
        code: error?.code,
        message: error?.message,
        status: {
          code: error?.data?.[1],
          message: error?.data?.[2],
        },
        sentry: {
          ...handlePrepareSentryError(error),
          ...source,
          player: 'ShakaPlayer',
        },
      }
    },
    [source, handlePrepareSentryError],
  )

  return {
    isIgnoredError,
    onPrepareError: handlePrepareError,
  }
}

export default useShakaPlayerErrors
