import React, { forwardRef } from 'react'

import { scale } from '@alphaott/smart-tv-utils'

const padding = scale(window.innerWidth, 98)

// eslint-disable-next-line react/display-name
export const InnerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      height: style.height + padding,
    }}
    {...rest}
  />
))

export default InnerElementType
