// flow

import styled, { css } from 'styled-components'
import { getBackgroundColor } from '@alphaott/smart-tv-themes'

export const flexStyle = css`
  display: flex;
  flex: 1;
`

export const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  background-color: ${getBackgroundColor};
  ${({ isFlexBehavior }) => isFlexBehavior && flexStyle}
`

export const LoaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const FocusableContainer = styled.div`
  ${({ isFlexBehavior }) => isFlexBehavior && flexStyle}
`
