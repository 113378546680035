// @flow
import { LARGE_VIEWPORT, MEDIUM_VIEWPORT, SMALL_VIEWPORT } from '@alphaott/app-config'
import { rgba } from 'polished'

import { colorPalette } from '../colorPalettes'
import type { ColorType } from '../colorPalettes'

export const ittvColors: ColorType = {
  ...colorPalette,
  grayText: '#B0B0B0',
  grayLabel: '#717171',
  grayBorder: 'rgba(255, 255, 255, 0.38)',
  primary: '#F60030',
  secondary: '#F60030',
}

export const darkTheme = {
  colors: ittvColors,
  background: {
    primary: '#181818',
    secondary: '#262626',
    highlight: ittvColors.dark,
    select: '#2e2e2e',
    footerTop: '#333',
  },
  text: {
    primary: ittvColors.primaryGray,
    secondary: ittvColors.secondaryGray,
    disabled: ittvColors.grayText,
    link: ittvColors.secondaryGray,
    hint: ittvColors.white,
    highlight: ittvColors.secondary,
    gray: ittvColors.primaryGray,
    seeAll: ittvColors.secondaryGray,
    primaryContrast: '#ffffff',
  },
  border: {
    primary: ittvColors.primaryGray,
    gray: ittvColors.secondaryGray,
  },
  filter: {
    primary: ittvColors.primaryGray,
    secondary: ittvColors.secondaryGray,
    disabled: ittvColors.secondaryGray,
    highlight: '#111111',
  },
  tabs: {
    bg: '#181818',
    containerBg: ittvColors.dark,
    color: ittvColors.white,
    border: ittvColors.primary,
    scrollbarThumb: '#bdbdbd',
    programTitle: ittvColors.white,
  },
  programGuide: {
    programBox: {
      background: {
        main: '#2e2e2e',
        hover: ittvColors.dark,
        selected: ittvColors.dark,
        progress: rgba(ittvColors.primary, 0.3),
        past: rgba(ittvColors.primary, 0.3),
        pastSelected: ittvColors.dark,
        pastHover: ittvColors.dark,
        empty: '#555555',
        emptyProgress: rgba(ittvColors.primary, 0.1),
      },
      text: {
        empty: ittvColors.secondaryGray,
      },
    },
    programGuidePictureOverlay: {
      horizontal:
        // eslint-disable-next-line max-len
        `linear-gradient(90deg, #181818 0%,${rgba('#181818', 0.97)} 13.03%,${rgba('#181818', 0.69)} 43.24%,${rgba(
          '#181818',
          0,
        )} 100%);`,
      vertical: `linear-gradient(0, #181818 0%,${rgba('#181818', 0.73)} 34.97%,${rgba('#181818', 0)} 100%);`,
    },
  },
  card: {
    title: ittvColors.white,
    subTitle: ittvColors.secondaryGray,
    bg: ittvColors.grayText,
    number: '#DFDFDF',
    boxShadow: '#2e2e2e',
    loaderBackgroundImage: 'linear-gradient(to right, #222 0%, #111 20%, #222 40%, #222 100%)',
  },
  dimension: {
    signLogoSize: 160,
  },
  size: 'default',
  breakpoints: [`${SMALL_VIEWPORT}px`, `${MEDIUM_VIEWPORT}px`, `${LARGE_VIEWPORT}px`],
  signIn: {
    clipPath: false,
  },
}
